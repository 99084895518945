import Link from "../../elements/Link";

// Types
import { Link as LinkType, LinkDisplayStyle } from "@libema/content-sdk";
import { ButtonProps } from "@libema/design-system";

type BookingLinkProps = {
  link: LinkType;
  locale: string;
  buttonProps?: ButtonProps;
};

const BookingLink = ({ link, locale, buttonProps }: BookingLinkProps) => {
  const onClick = (e) => {
    e.stopPropagation();
    return link.displayStyle === LinkDisplayStyle.BUTTON_PHONE &&
      link.phoneNumber
      ? window.open(`tel:${link.phoneNumber}`)
      : null;
  };

  if (link == null) return null;

  return (
    <Link
      as={link.displayStyle}
      buttonProps={
        buttonProps || {
          colorType: "secondary",
          variant: "outlined",
          size: "small",
          className: "booking-button",
        }
      }
      onClick={onClick}
      href={link.url}
      target={link.target}
      locale={locale}
      phoneNumber={link.phoneNumber}
    >
      {link.label}
    </Link>
  );
};

export default BookingLink;
