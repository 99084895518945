import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import Jumbotron from 'reactstrap/lib/Jumbotron' // Imports like this reduces build size
import classNames from 'classnames'
import { backgroundImage } from '../../utilities/mediaUtilities'
import { openFullscreen } from '../../utilities/fullscreenUtils'

class JumbotronVideoBlock extends Component {
  state = {
    playing: this.props.properties.autoplay,
    volume: false,
    isBuffering: true,
    isMuted: true
  };

  player = null;

  handleFullscreen = () => {
    const elem = document.getElementsByClassName('embed-responsive-item')[0]
    openFullscreen(elem)
  }

  handlePlay = () => {
    this.setState({
      playing: !this.state.playing
    })
  }

  // Handle buffering that happens when the player switches to the next video in the playerlist
  handleBuffer = () => {
    if (!this.state.isBuffering) {
      this.setState({
        isBuffering: true
      })
    }
  }

  // Hide the overlay only when the player has been playing for half a second to 'hide' YouTube details.
  handleProgress = (played) => {
    const { isBuffering, playing } = this.state
    if (isBuffering && played.playedSeconds > 0.45 && playing) {
      this.setState({
        isBuffering: false
      })
    }
  }

  render () {
    const { media, properties } = this.props
    const { playing, isMuted, isBuffering } = this.state
    const mediaType = media ? media.content_type.split('/')[0] : ''
    const propStyles = []
    Object.keys(properties.styles || {}).map(key => propStyles.push(properties.styles[key]))

    const className = classNames(
      propStyles.join(' '),
      properties.template,
      properties.extra_classes,
      {
        'jumbotron-sm': properties.styles[0] === undefined,
        'has-img': media,
        'jumbotron-video': mediaType === 'video'
      }
    )

    let videoProps = {}

    const jumbotronProps = {
      id: properties.id,
      className
    }

    let image = null
    if (mediaType === 'image') {
      image = media || null
    }

    const jumbotronStyle = {}
    if (image && image.reference) {
      jumbotronStyle.backgroundImage = `${backgroundImage(image.reference, 'full_size')}`
    }

    if (properties.orientation) {
      jumbotronStyle.backgroundPosition = properties.orientation
    }

    if (mediaType === 'video') {
      videoProps = {
        url: '',
        playing: this.state.playing,
        volume: isMuted ? 0 : 0.5,
        muted: isMuted,
        loop: this.props.properties.loop
      }

      if (media.provider === 'youtube') {
        videoProps.url = `https://www.youtube.com/watch?v=${media.reference}`
        videoProps.youtube = {
          playerVars: {
            autoplay: this.props.properties.autoplay ? 1 : 0,
            playlist: media.reference,
            controls: 0,
            rel: 0,
            transparent: false
          }
        }
      } else {
        videoProps.url = `https://player.vimeo.com/video/${media.reference}`
        videoProps.vimeo = {
          playerOptions: {
            responsive: true,
            autoplay: this.props.properties.autoplay ? 1 : 0,
            playlist: media.reference,
            controls: 0,
            background: 1,
            transparent: 0
          }
        }
      }
    }

    return (
      <div>
        <Jumbotron {...jumbotronProps} style={{ position: 'relative' }}>
          <div
            className={this.props.classNames}
            id={`${properties.id ? properties.id : ''}`}
            style={this.props.jumbotronStyling}
          >
            <div className={`jumbotron-caption ${playing ? 'caption-fade' : 'caption-show'}`} dangerouslySetInnerHTML={{ __html: this.props.value }} />
          </div>
          <div className='embed-responsive embed-responsive-16by9'>
            {media.thumb && (
              <div
                className={`video-thumb in ${isBuffering && 'visible'}`}
                style={{ backgroundImage: `${backgroundImage(media.thumb.reference, 'lg_43')}` }}
              />
            )}
            <ReactPlayer
              ref={(player) => { this.player = player }}
              width='100%'
              height='auto'
              onBuffer={this.handleBuffer}
              onProgress={this.handleProgress}
              progressInterval={500}
              className='embed-responsive-item'
              {...videoProps}
            />
          </div>
          {!playing && !this.props.properties.autoplay && (
            <div className='big-positioned'>
              <span
                className='play-button'
                onClick={this.handlePlay}
              >
                <div>
                  <i className='material-icons'>play_arrow</i>
                </div>
              </span>
            </div>
          )}
          <div className='video-controls'>
            <div>
              {playing && (
                <span
                  className='pause-button'
                  onClick={(e) => {
                    e.stopPropagation()
                    this.handlePlay()
                  }}
                >
                  <i className='material-icons'>&#xE034;</i>
                </span>
              )}
              {!playing && this.props.properties.autoplay && (
                <span
                  className='play-button'
                  onClick={this.handlePlay}
                >
                  <i className='material-icons'>play_arrow</i>
                </span>
              )}
              {isMuted ? (
                <span
                  className='unmute-button'
                  onClick={() => this.setState({ isMuted: false })}
                >
                  <i className='material-icons'>&#xE050;</i>
                </span>
              ) : (
                <span
                  className='mute-button'
                  onClick={() => this.setState({ isMuted: true })}
                >
                  <i className='material-icons'>&#xE04F;</i>
                </span>
              )}
              <span
                className='fullscreen-button'
                onClick={this.handleFullscreen}
              >
                <i className='material-icons'>&#xE89F;</i>
              </span>
            </div>
          </div>
        </Jumbotron>
      </div>
    )
  }
}

JumbotronVideoBlock.propTypes = {
  block: PropTypes.object.isRequired,
  media: PropTypes.object,
  properties: PropTypes.object,
  value: PropTypes.string,
  classNames: PropTypes.string,
  jumbotronStyling: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default JumbotronVideoBlock
