import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import trans from 'counterpart'
import { PAYMENT_TERM_SHORT } from '../../../utilities/paymentUtils'
import { getPaymentTerm } from '../../../selectors/reservationSelectors'

const ListDiv = styled.div`
  margin-top: 1.5rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
`

const ListItem = styled.li`
  margin-top: 1.50rem;
`

function ReceiptListing (props) {
  const { paymentTerm } = props

  return (
    <ListDiv>
      {process.env.NEXT_PUBLIC_APP === 'beeksebergen' && (
        <ul className='list-checked'>
          <ListItem><span dangerouslySetInnerHTML={{ __html: trans('reservation_checklist.prices') }} /></ListItem>
          {paymentTerm !== PAYMENT_TERM_SHORT && <ListItem><span dangerouslySetInnerHTML={{ __html: trans('reservation_checklist.confirmation') }} /></ListItem>}
          <ListItem><span dangerouslySetInnerHTML={{ __html: trans('reservation_checklist.extras') }} /></ListItem>
          <ListItem><span dangerouslySetInnerHTML={{ __html: trans('reservation_checklist.cancellation') }} /></ListItem>
        </ul>
      )}
      {process.env.NEXT_PUBLIC_APP === 'dierenbos' && (
        <ul className='list-checked'>
          <ListItem><span dangerouslySetInnerHTML={{ __html: trans('reservation_checklist.prices_dierenbos') }} /></ListItem>
          <ListItem><span dangerouslySetInnerHTML={{ __html: trans('reservation_checklist.size_dierenbos') }} /></ListItem>
          <ListItem><span dangerouslySetInnerHTML={{ __html: trans('reservation_checklist.pond_dierenbos') }} /></ListItem>
          <ListItem><span dangerouslySetInnerHTML={{ __html: trans('reservation_checklist.cancellation') }} /></ListItem>
        </ul>
      )}
    </ListDiv>
  )
}

ReceiptListing.propTypes = {
  paymentTerm: PropTypes.string
}

const mapStateToProps = state => ({
  paymentTerm: getPaymentTerm(state)
})

export default connect(mapStateToProps)(ReceiptListing)
