// TODO: move component to design-system
import { FC } from "react";
import styled from "styled-components";
import {
  contrastColor,
  DEVICE_LG_UP,
  DEVICE_MD_UP,
} from "@libema/design-system";

// Icons
import PreferencesIcon from "../../../icons/PreferencesIcon";

type Props = {
  onClick: () => void;
  count: number;
};

const Badge = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: bold;
  background: ${({ theme }) => theme.color.secondary_main};
  color: ${({ theme }) => contrastColor(theme.color.secondary_main)};
  width: 26px;
  height: 26px;
  padding-top: 2px;
  letter-spacing: 0;
  word-spacing: 0;
  border-radius: 50%;
  margin: 0 12px 0 0;
`;

const FilterButtonStyled = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.color.grey_900};

  span {
    display: none;
    margin-right: ${({ theme }) => theme.margin.sm};
  }

  @media ${DEVICE_MD_UP} {
    span {
      display: inline-block;
    }
  }

  @media ${DEVICE_LG_UP} {
    display: none;
  }
`;

const FilterButton: FC<Props> = ({ onClick, count }) => (
  <FilterButtonStyled onClick={onClick}>
    <Badge>{count}</Badge>
    <PreferencesIcon />
  </FilterButtonStyled>
);

export default FilterButton;
