import styled from "styled-components";
import BaseForm from "../form/Form";
import Input from "../form/Input";
import FormGroup from "../form/FormGroup";
import Button from "../Button";
import { DEVICE_MD_UP } from "../../utils/deviceUtils";

export const Form = styled(BaseForm)`
  ${Input} {
    border-radius: 8px;
    font-size: 1rem;
    padding: ${({ theme }) => theme.padding.sm}
      ${({ theme }) => theme.padding.md};
  }

  ${FormGroup} {
    margin-bottom: ${({ theme }) => theme.padding.sm};
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${Button} {
    width: 100%;
  }

  @media ${DEVICE_MD_UP} {
    ${Button} {
      width: auto;
    }
  }
`;
