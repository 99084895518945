import { useState, useEffect } from "react";
import { captureException } from "@sentry/nextjs";
import styled from "styled-components";
import Image, { ImageLoaderProps } from "next/image";
import { SectionInstagram } from "@libema/content-sdk";

import { FiInstagram } from "react-icons/fi";

import {
  Section,
  DEVICE_LG_DOWN,
  DEVICE_LG_UP,
  H2,
  DEVICE_MD_DOWN,
  DEVICE_SM_DOWN,
} from "@libema/design-system";
import Link from "../elements/Link";

const FB_APP_ID = process.env.NEXT_PUBLIC_FB_APP_ID || "";
const FB_CLIENT_TOKEN = process.env.NEXT_PUBLIC_FB_CLIENT_TOKEN || "";
const ACCESS_TOKEN = `${FB_APP_ID}|${FB_CLIENT_TOKEN}`;

const Card = styled.div`
  position: relative;
  margin-bottom: 1.5rem;

  > a {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.75rem 1rem;
    text-decoration: none;
    color: white;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;

  @media ${DEVICE_LG_UP} {
    max-width: 1140px;
  }

  ${Card} {
    width: calc(25% - 1rem);

    @media ${DEVICE_LG_DOWN} {
      width: calc(50% - 1rem);
    }

    @media ${DEVICE_SM_DOWN} {
      width: 100%;
    }
  }
`;

const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Name = styled.span`
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: 1rem;
`;

const ImageContainer = styled.div`
  position: relative;
  flex: 0 0 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
`;

const Details = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4rem;
  z-index: 10;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: center;

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

type InstagramPost = {
  thumbnail_url: string;
  author_name: string;
  provider_name: string;
  provider_url: string;
};

const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?w=${width}&h=${width}&q=${
    quality || 90
  }&fit=fill&f=faces&fm=jpg`;
};

const InstagramCard = ({ url }) => {
  const [post, setPost] = useState<InstagramPost>();

  useEffect(() => {
    const fetchPost = async () => {
      const apiUrl = `https://graph.facebook.com/instagram_oembed?url=${encodeURIComponent(
        url
      )}&maxwidth=640&fields=thumbnail_url,author_name,provider_name,provider_url&access_token=${ACCESS_TOKEN}`;
      try {
        const response = await fetch(apiUrl);
        const post = await response.json();
        setPost(post);
      } catch (error) {
        captureException(error);
        console.error("Could not fetch instagram post", error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchPost();
  }, [url]);

  if (!post || !post.thumbnail_url) {
    return null;
  }

  return (
    <Card>
      <Link href={url}>
        <ImageContainer>
          <Image
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            quality={90}
            src={post?.thumbnail_url}
            alt={`Instagram post by ${post?.author_name}`}
            loader={imageLoader}
          />
        </ImageContainer>
        <Details>
          <FiInstagram />
          <Author>
            <Name>{post?.author_name}</Name>
          </Author>
        </Details>
      </Link>
    </Card>
  );
};

const StyledSection = styled(Section.Wrapper)`
  padding: 3rem 1.5rem 1.5rem 1.5rem;

  @media ${DEVICE_MD_DOWN} {
    padding: 3rem 0;
  }
`;

const Instagram = ({ title, urlList, backgroundColor }: SectionInstagram) => {
  return (
    <StyledSection backgroundColor={backgroundColor}>
      {title && (
        <Section.Header>
          <H2>{title}</H2>
        </Section.Header>
      )}
      <Container>
        {urlList.map((url, idx) => (
          <InstagramCard url={url} key={idx} />
        ))}
      </Container>
    </StyledSection>
  );
};

export default Instagram;
