import { FC } from "react";
import * as React from "react";
import NextLink from "next/link";
import styled from "styled-components";
import { darken } from "polished";

// Types
import {
  Image,
  SectionHero,
  SectionHeroDisplayStyle,
  Video,
  Link as LinkType,
} from "@libema/content-sdk";

// Components
import { FiChevronRight } from "react-icons/fi";
import { ImageLoaderProps } from "next/image";
import {
  DEVICE_LG_DOWN,
  DEVICE_LG_UP,
  DEVICE_MD_UP,
  DEVICE_SM_DOWN,
  DEVICE_XL,
  Hero,
  Link,
} from "@libema/design-system";
import SafeWebpImage from "../elements/SafeWebpImage";
import RichTextRenderer from "../renderer/RichTextRenderer";
import VideoPlayer from "../blocks/VideoPlayer/VideoPlayer";
import useSection from "../sectionUtils";
import { useRouter } from "next/router";

const VisibleWrapper = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  z-index: 190;
`;

export const CenterContent = styled(Hero.CenterContent)`
  h1,
  h2,
  h3 {
    color: white;
    text-shadow: -1px 0px 3px rgb(0 0 0 / 50%);
    font-size: 3.2rem;
    text-transform: uppercase;
    font-weight: 900;

    @media ${DEVICE_LG_DOWN} {
      font-size: 1.9rem;
    }
  }
`;

const BreakoutContent = styled.div`
  max-width: ${DEVICE_XL}px;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  height: 100%;
  text-align: center;

  h1,
  h2,
  h3 {
    margin: 200px 0 0 0;
    width: 300px;
    line-height: 1.8rem;
    padding: 2rem;
    background-color: ${({ theme }) => theme.color.secondary};
    color: white;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 900;
  }

  p {
    display: none;
  }

  @media ${DEVICE_LG_UP} {
    h1,
    h2,
    h3 {
      position: absolute;
      padding: 3rem;
      font-size: 1.8rem;
      line-height: 2rem;
      right: -470px;
      bottom: -130px;
    }
  }
`;

export const HeroLinks = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1001;
  position: relative;

  @media ${DEVICE_LG_UP} {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 15%;
    text-align: center;
  }

  a {
    padding: 1rem 3rem;
    color: white;
    font-size: 1.4rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    background-color: ${({ theme }) => theme.color.secondary};
    text-decoration: none;
    transition: background-color 300ms;

    &:hover {
      background-color: ${({ theme }) => darken(0.05, theme.color.secondary)};
    }
  }

  @media ${DEVICE_LG_DOWN} {
    margin: -2rem 0 -2rem 0;

    a:first-child {
      padding-top: 3rem;
    }
    a:last-child {
      padding-bottom: 3rem;
    }
  }

  @media ${DEVICE_LG_UP} {
    max-width: 1140px;
    margin: 0 auto;
    justify-content: space-around;
    flex-direction: row;

    a {
      padding: 3rem;
      margin: 1rem;
      font-size: 1.6rem;
    }
  }
`;

const Chevron = styled(FiChevronRight)`
  color: ${({ theme }) => theme.color.primary};
  position: absolute;
  margin-top: 0.6%;
`;

type Props = Omit<SectionHero, "id" | "type"> & {
  children?: React.ReactNode;
  className?: string;
};

type HeroWrapperProps = {
  displayStyle: SectionHeroDisplayStyle;
  isVideo: boolean;
  buttons?: LinkType[];
};

const HeroWrapper = styled(Hero.Wrapper)<HeroWrapperProps>`
  min-height: 160px;
  z-index: 19; // fix navbar overlap

  ${({ displayStyle, buttons }) =>
    displayStyle === SectionHeroDisplayStyle.LARGE &&
    buttons?.length &&
    `
      ${ContentWrapper} {
        @media ${DEVICE_LG_UP} {
          padding-bottom: 11%;
        }
      }
    `}

  ${({ displayStyle, isVideo }) =>
    displayStyle !== SectionHeroDisplayStyle.LARGE &&
    !isVideo &&
    `
      min-height: 300px;

      @media ${DEVICE_MD_UP} {
        min-height: 320px;
      }

      @media ${DEVICE_LG_UP} {
        margin-bottom: -1.8rem;
        z-index: 10;
      }

      ${VisibleWrapper} {
        flex: 1;
      }
    `}

  ${({ displayStyle, isVideo }) =>
    displayStyle === SectionHeroDisplayStyle.LARGE &&
    !isVideo &&
    `
      min-height: 300px;

        ${VisibleWrapper} {
          height: 450px;
        }

      ${HeroLinks} {
        bottom: 10%;
      }

      @media ${DEVICE_MD_UP} {
        ${CenterContent} {
          min-height: 300px;
        }
      }

      @media ${DEVICE_MD_UP} {
        min-height: 560px;
      }

      @media ${DEVICE_LG_UP} {
        min-height: 0;
        z-index: 10;
        margin-bottom: -1.8rem;
      }
    `}
`;

const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?w=${width}&h=${Math.ceil(width * 0.5)}&q=${
    quality || 85
  }&fit=fill&f=faces&fm=webp`;
};

const HeroSection: FC<Props> = ({
  media,
  videoThumbnail,
  content,
  heroDisplayStyle = SectionHeroDisplayStyle.SHORT,
  buttons,
  children,
  className,
  withDarkGradient,
}) => {
  const { locale = "nl" } = useRouter();
  const { isVideo } = useSection({ media });

  const TitleComponent =
    heroDisplayStyle === SectionHeroDisplayStyle.BREAKOUT
      ? BreakoutContent
      : CenterContent;

  const renderImage = (image: Image) => (
    <Hero.BackgroundImage withDarkGradient={withDarkGradient}>
      <SafeWebpImage
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        sizes={`${DEVICE_SM_DOWN} 300px, 100vw`}
        src={image.url}
        alt={image.title}
        quality={80}
        loader={imageLoader}
        placeholder="blur"
        priority={true}
      />
    </Hero.BackgroundImage>
  );

  const renderVideo = (video: Video, videoThumbnail?: Image) => (
    <VideoPlayer
      autoplay
      startMuted
      text={content}
      media={video}
      thumbnail={videoThumbnail}
      progressInterval={500}
      className="jumbotron embed-responsive-item"
    />
  );

  return (
    <HeroWrapper
      className={className}
      displayStyle={heroDisplayStyle}
      isVideo={isVideo}
      buttons={buttons}
    >
      <VisibleWrapper>
        {media !== null &&
          (isVideo
            ? renderVideo(media as Video, videoThumbnail)
            : renderImage(media as Image))}
        <ContentWrapper>
          <TitleComponent data-display-style={heroDisplayStyle}>
            {content && <RichTextRenderer text={content} />}
            {children}
          </TitleComponent>
        </ContentWrapper>
      </VisibleWrapper>
      {heroDisplayStyle === SectionHeroDisplayStyle.LARGE &&
        buttons?.length !== 0 && (
          <HeroLinks>
            {buttons?.map((button, idx) => (
              <NextLink href={button.url} key={idx} locale={locale} passHref>
                <Link>
                  {button.label}
                  <Chevron />
                </Link>
              </NextLink>
            ))}
          </HeroLinks>
        )}
    </HeroWrapper>
  );
};

export default HeroSection;
