export const SET_ENTITIES = 'entity/SET_ENTITIES'
export const UPDATE_ENTITY = 'entity/UPDATE_ENTITY'

export const setEntities = entities => ({
  type: SET_ENTITIES,
  payload: entities
})

export const updateEntity = (entity, id, propertyOrObject, value = null) => ({
  type: UPDATE_ENTITY,
  payload: {
    entity,
    id,
    propertyOrObject,
    value
  }
})
