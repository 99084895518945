import { createSelector } from "reselect";
import moment from "moment";
import entitySelectors from "./entitySelectors";
import {
  PAYMENT_TERM_LONG,
  PAYMENT_TERM_MEDIUM,
  PAYMENT_TERM_SHORT,
} from "../utilities/paymentUtils";

export const getReservationAdditions = (state) =>
  state.reservation.additions || [];
export const getCriteria = (state) => state.reservation.criteria;
const getReservedResources = (state) => state.reservation.reservedResources;
export const getReservationNumber = ({
  reservation: { reservationNumber },
}) => {
  // remove 3 trailing 0s to match reservation number in ReservationConfirmation block
  const nr = reservationNumber ? reservationNumber.toString() : "";
  return nr.endsWith("000") ? nr.substring(0, nr.length - 3) : nr;
};
export const getReservationId = (state) => state.reservation?.reservationId;
const getCountry = (state) => state.reservation?.criteria?.customer?.country;
const getCity = (state) => state.reservation?.criteria?.customer?.city;
const getEmail = (state) => state.reservation?.criteria?.customer?.email;
const getArrivalDate = (state) => state.reservation.criteria?.arrival_date;
const getLanguage = (state) => state.reservation.criteria.language;

export const getPaymentTerm = createSelector(getArrivalDate, (arrival) => {
  const now = moment();
  if (
    moment(arrival, "YYYY-MM-DD").subtract(
      process.env.NEXT_PUBLIC_PAYMENT_TERMS * 7,
      "days"
    ) < now
  ) {
    return PAYMENT_TERM_SHORT;
  } else if (moment(arrival, "YYYY-MM-DD").subtract(8 * 7, "days") < now) {
    return PAYMENT_TERM_MEDIUM;
  }
  return PAYMENT_TERM_LONG;
});

const getTotalPrice = (state) => {
  const billLine = state.reservation.customerBill.find(
    (billItem) => billItem.billLineType === 70
  );
  if (!billLine) {
    return 0;
  }

  return billLine.total;
};

const getDiscount = createSelector(getReservedResources, (reservedResources) =>
  reservedResources.reduce((discount, cur) => {
    if (cur.price < 0) {
      return discount + cur.price;
    }
    return discount;
  }, 0)
);

const getFromPrice = createSelector(
  getTotalPrice,
  getDiscount,
  (total, discount) => total + Math.abs(discount)
);

const getResortId = createSelector(
  getCriteria,
  entitySelectors.getAccommodationTypes,
  (criteria, accommodationTypes) => {
    const accommodationType = accommodationTypes[criteria.resource_id];
    if (accommodationType) {
      return accommodationType.resort.id;
    }
    if (criteria.resort_id) {
      return criteria.resort_id;
    }
  }
);

export default {
  getDiscount,
  getFromPrice,
  getReservedResources,
  getResortId,
  getTotalPrice,
  getReservationId,
  getReservationNumber,
  getCountry,
  getCity,
  getEmail,
  getArrivalDate,
  getLanguage,
};
