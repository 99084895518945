import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import counterpart from 'counterpart'
import axios from 'axios'
import isEqual from 'lodash.isequal'
import * as BlockMap from '../BlockMap'
import { getContents } from '../../modules/content/actions'
import { updateEntity } from '../../actions/entityActions'
import CollectionFilter from '../../modules/content/components/CollectionFilter'

const cancelToken = axios.CancelToken

class CollectionBlock extends Component {
  state = {
    page: 1,
    modal: false
  }

  /**
   * Loads the collection on an initial mount
   */
  componentDidMount = () => this.filter(this.props.filters);

  /**
   * Calls the filter method if the filters have changed
   */
  componentWillReceiveProps = (nextProps) => {
    if (!isEqual(nextProps.filters, this.props.filters)) {
      this.filter(nextProps.filters)
    }
  }

  /**
   * Transforms the passed filters to query params
   */
  transformToQueryparams = (filters = {}) => Object.keys(filters)
    .reduce((object, i) => {
      if (Array.isArray(filters[i])) {
        object[i] = Object.keys(filters[i]).filter(v => filters[i][v] === true)
      } else {
        object[i] = filters[i]
      }

      return object
    }, {});

  /**
   * Fetches and appends more results to the collection based on the current filters
   */
  handleLoadMore = () => {
    const filters = this.transformToQueryparams(this.props.filters)

    this.setState(prevState => ({
      page: prevState.page + 1
    }))

    this.props.loadMore({
      ...filters,
      order_by: this.props.properties.order_by || 'createdAt',
      direction: this.props.properties.order_direction || 'asc',
      limit: this.props.properties.limit || 10,
      page: this.state.page + 1,
      expr: this.props.properties.conditions
    }, this.props.collection)
  }

  /**
   * Fetches and appends all results to the collection based on the current filters
   */
  handleLoadAll = () => {
    const filters = this.transformToQueryparams(this.props.filters)

    this.props.loadCollection({
      ...filters,
      order_by: this.props.properties.order_by || 'createdAt',
      direction: this.props.properties.order_direction || 'asc',
      page: 1,
      limit: '999999',
      expr: this.props.properties.conditions
    }, this.props.collection)
  }

  toggleModal = () => this.setState(prevState => ({
    modal: !prevState.modal
  }));

  /**
   * Fetches and renders a new collection based on the passed filters
   */
  filter = (nextFilters) => {
    let filters = this.transformToQueryparams(nextFilters)

    this.setState({
      page: 1
    })

    filters = {
      ...filters,
      order_by: this.props.properties.order_by || 'createdAt',
      direction: this.props.properties.order_direction || 'asc',
      limit: this.props.properties.limit || 10,
      page: 1,
      expr: this.props.properties.conditions
    }

    // Cancel any potential ongoing requests
    if (this.cancellationToken) this.cancellationToken.cancel('Cancel request')
    this.cancellationToken = cancelToken.source()

    this.props.loadCollection(filters, this.cancellationToken, () => {
      // Reset the cancellationToken when done.
      this.cancellationToken = null
    })
  }

  isFiltered = () => {
    const filters = this.transformToQueryparams(this.props.filters)
    const values = Object.values(filters)
    let filtered = false
    values.forEach((value) => {
      if (value) {
        filtered = true
      }
    })

    return filtered
  }

  /**
   * Renders a single item based on its template.
   * This template should be a React Component registered in the BlockMap.
   */
  renderItem = (item, i) => {
    if (!item) return null

    const { styles, properties } = this.props
    // BC compatibility in case template is not defined on `styles` attribute yet
    const template = (styles && styles.template) ? styles.template : properties.template || 'CollectionItemDefault'
    if (!{}.hasOwnProperty.call(BlockMap, template)) {
      console.error(`Collection template ${template} is not registered`)
      return null
    }

    const element = BlockMap[template]

    return React.createElement(element, {
      content: item,
      itemKey: i,
      isFiltered: this.isFiltered()
    })
  }

  /**
   * Render the component
   */
  render = () => {
    const { id, collection, isFetching, totalResults, properties: { filters, filter_placement: filterPlacement, load_more: loadMore, template }, manageTags } = this.props

    return (
      <div {...manageTags}>
        {/* The collection filter above the results */}
        {(!filterPlacement || filterPlacement === 'top') && ( // eslint-disable-line camelcase
          <CollectionFilter form={`collection-filters-${id}`} filters={filters} />
        )}

        {/* The collection filters in a modal */}
        {filterPlacement === 'modal' && ( // eslint-disable-line camelcase
          <div>
            <div>
              <button type='button' className='btn btn-primary btn-toggle-modal mb-2' onClick={this.toggleModal}>
                {counterpart.translate('button.filter')}
              </button>
            </div>
            <Modal className='collection-filter-modal' isOpen={this.state.modal} toggle={this.toggleModal}>
              <ModalHeader toggle={this.toggleModal}>{counterpart.translate('title.filters')}</ModalHeader>
              <ModalBody>
                <CollectionFilter filters={filters} form={`collection-filters-${id}`} />
              </ModalBody>
              <ModalFooter>
                <button
                  type='button'
                  className='btn btn-primary btn-block'
                  onClick={this.toggleModal}
                >
                  {`${collection.length} ${counterpart.translate('button.results')}`}
                </button>
              </ModalFooter>
            </Modal>
          </div>
        )}

        {!isFetching && collection && collection.length < 1 && (
          <div className='alert alert-warning'>
            {counterpart.translate('alert.noResults')}
          </div>
        )}

        {/* The collection results */}
        <div className={`${template || ''}`}>
          {collection && collection.map((item, i) => (
            <div className='collection-item mb-3' key={item.id}>
              {this.renderItem(item, i)}
            </div>
          ))}
        </div>

        <div className='collection-navigation-buttons'>
          {/* Show a load-more button when available */}
          {loadMore && collection.length > 0 && (totalResults && totalResults > collection.length) && ( // eslint-disable-line camelcase
            <button type='button' className='btn btn-default btn-load-more' onClick={this.handleLoadMore}>
              {counterpart.translate('button.load_more')}
            </button>
          )}

          {/* Show a load-all button when available */}
          {loadMore && collection.length > 0 && (totalResults && totalResults > collection.length) && ( // eslint-disable-line camelcase
            <button type='button' className='btn btn-default btn-show-all' onClick={this.handleLoadAll}>
              {counterpart.translate('button.show_all')}
              <span className='total-results'>{`(${totalResults})`}</span>
            </button>
          )}
        </div>
      </div>
    )
  }
}

CollectionBlock.propTypes = {
  id: PropTypes.number,
  collection: PropTypes.array,
  filters: PropTypes.object,
  isFetching: PropTypes.bool,
  loadCollection: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  properties: PropTypes.object,
  styles: PropTypes.object,
  template: PropTypes.string,
  totalResults: PropTypes.number,
  manageTags: PropTypes.object
}

export default connect(
  (state, ownProps) => ({
    filters: getFormValues(`collection-filters-${ownProps.id}`)(state),
    collection: (state.entities.blocks[ownProps.id]) ? state.entities.blocks[ownProps.id].collection || [] : [],
    totalResults: (state.entities.blocks[ownProps.id]) ? state.entities.blocks[ownProps.id].totalResults || 0 : 0
  }),
  (dispatch, ownProps) => ({
    // Load the collection with the given filters
    loadCollection: (filters, cancellationToken, callback) => {
      dispatch(updateEntity('blocks', ownProps.id, { isFetching: true }))
      dispatch(getContents(
        filters,
        (response) => {
          if (callback) callback()
          // Update the current block on success
          dispatch(updateEntity('blocks', ownProps.id, {
            collection: response.results || response,
            totalResults: response.total_results || response.length,
            isFetching: false
          }))
        },
        () => {
          if (callback) callback()
        },
        cancellationToken
      ))
    },
    // Load more results
    loadMore: (filters, currentCollection) => {
      dispatch(updateEntity('blocks', ownProps.id, { isFetching: true }))
      dispatch(getContents(filters, (response) => {
        // Merge the new collection with the existing collection
        const collection = currentCollection.concat(response.results)
        // Update this block with the merged collection
        dispatch(updateEntity('blocks', ownProps.id, {
          collection,
          isFetching: false
        }))
      }))
    }
  })
)(CollectionBlock)
