import * as React from "react";
import styled from "styled-components";
import { ImageLoaderProps } from "next/image";
import { FormattedDate } from "react-intl";
import { useRouter } from "next/router";

// Types
import { Page, Article } from "@libema/content-sdk";

// Components
import {
  H1,
  H3,
  P,
  Container,
  Row,
  Col,
  Author,
  Translate,
  Breadcrumbs,
} from "@libema/design-system";

// Local Components
import Link from "../elements/Link";
import SafeWebpImage from "../elements/SafeWebpImage";

// Utils
import SectionsRenderer from "../renderer/SectionsRenderer";
import RichTextRenderer from "../renderer/RichTextRenderer";

const ArticleElement = styled.article`
  position: relative;
  background: white;
  margin: 3rem 0;

  ${H1} {
    font-size: 2rem;
    color: ${({ theme }) => theme.color.grey_900};
    line-height: 1.2;
    margin-bottom: 24px;
  }

  ${Breadcrumbs.Wrapper} {
    margin-bottom: 24px;
  }

  ${P} {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.grey_800};
  }
`;

const DateTime = styled(P)`
  font-size: 1rem;
  color: ${({ theme }) => theme.color.grey_400};
`;

const Media = styled.div`
  margin-bottom: 24px;
`;

const PublishedTitle = styled.h3`
  display: block;
  font-size: 1rem;
  color: ${({ theme }) => theme.color.grey_800};
  font-weight: lighter;
  margin-bottom: 8px;
`;

const Categories = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

const Category = styled.div`
  border: 2px solid ${({ theme }) => theme.color.grey_100};
  font-weight: bold;
  font-size: 0.875rem;
  margin-right: 4px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.color.secondary};
  padding: 4px 12px;
  border-radius: 8px;
`;

const Authors = styled.section`
  margin: 8px 0;
  display: flex;
  flex-wrap: wrap;

  ${Author} {
    margin-right: 24px;
    margin-bottom: 16px;
  }
`;

type Props = Page<Article>;

// TODO: reuse same image loader among components.
const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?w=${width}&h=${Math.ceil(width * 0.5)}&q=${
    quality || 85
  }&fit=fill&f=faces&fm=webp`;
};

const ActivityLayout: React.FC<Props> = ({
  topic: article,
  sections,
  parentPage,
}) => {
  const { title, description, media, publishedAt, authors, categories } =
    article;

  const { locale } = useRouter();

  return (
    <>
      <Container>
        <ArticleElement>
          <Media>
            <SafeWebpImage
              width={1100}
              height={500}
              objectFit="cover"
              objectPosition="center"
              src={media.url}
              alt={media.title}
              quality={90}
              loader={imageLoader}
              placeholder="blur"
            />
          </Media>
          {parentPage && (
            <Row>
              <Col>
                <Breadcrumbs.Wrapper>
                  <Breadcrumbs.Item>
                    <Link href={parentPage.slug} locale={locale}>
                      {parentPage.title}
                    </Link>
                    <Breadcrumbs.Divider>/</Breadcrumbs.Divider>
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item>{title}</Breadcrumbs.Item>
                </Breadcrumbs.Wrapper>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={8}>
              <H1>{title}</H1>
              <DateTime>
                <FormattedDate
                  value={new Date(publishedAt)}
                  year="numeric"
                  month="long"
                  day="numeric"
                  hour="numeric"
                  minute="numeric"
                />
              </DateTime>
              <RichTextRenderer text={description} />
            </Col>
            <Col md={4}>
              <PublishedTitle>
                <Translate id="page.article.published.in" />
              </PublishedTitle>
              <Categories>
                {categories.map(({ id, title }) => {
                  return <Category key={id}>{title}</Category>;
                })}
              </Categories>
              <Authors>
                {authors.map(({ id, firstName, lastName, image, jobTitle }) => {
                  return (
                    <Author.Wrapper key={id}>
                      <Author.Avatar>
                        <SafeWebpImage
                          width={48}
                          height={48}
                          objectFit="cover"
                          objectPosition="center"
                          src={image.url}
                          alt={image.title}
                          quality={90}
                          loader={imageLoader}
                          placeholder="blur"
                        />
                      </Author.Avatar>
                      <Author.Content>
                        <Author.Name>
                          {firstName} {lastName}
                        </Author.Name>
                        <Author.Subtitle>{jobTitle}</Author.Subtitle>
                      </Author.Content>
                    </Author.Wrapper>
                  );
                })}
              </Authors>
            </Col>
          </Row>
        </ArticleElement>
      </Container>

      <SectionsRenderer sections={sections} />
    </>
  );
};

export default ActivityLayout;
