import { FC } from "react";
import styled from "styled-components";

// Components
import Container from "../layout/Container";

// Constants
import { Z_INDEX } from "./constants";

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: ${Z_INDEX.content};
  text-align: center;
`;

type Props = {
  className?: string;
};

const CenterContent: FC<Props> = ({ children, className }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

export default CenterContent;
