import React, { useEffect } from "react";
import PropTypes from "prop-types";
import trans from "counterpart";
import { connect } from "react-redux";
import moment from "moment";
import Image from "../../media/components/Image";
import TravelCompanySummary from "../../booking/components/TravelCompanySummary";
import {
  reservationSelector,
  resourceInReservationSelector,
} from "../selectors";
import { DISPLAY_DATE_WITH_DAY } from "../../../constants/dates";
import reservationSelectors from "../../../selectors/reservationSelectors";
import StayInfoVoucherForm from "./StayInfoVoucherForm";
import { createReservationProposal } from "../actions";
import {
  ID_SAFARIRESORT,
  ID_SAFARIRESORT_HOTEL,
} from "../../booking/constants";
import { getWithExpiry } from "../../../utilities/localStorage";
import { getResortVariation } from "../../booking/utilities";
import { ResortLabel } from "@libema/design-system";

const StayInfoReceipt = ({
  reservation,
  resortId,
  resource,
  properties,
  manageTags,
  updateReserve,
}) => {
  const [showVoucherForm, setShowVoucherForm] = React.useState(false);
  const resortVariation = getResortVariation(resortId);

  useEffect(() => {
    const isExpired = !getWithExpiry("voucher");
    setShowVoucherForm(!isExpired);
  }, []);

  const handleUpdateInfo = ({ voucher }) => {
    return updateReserve(
      { voucher_code: voucher || undefined },
      { joinCriteria: true, throwError: true }
    );
  };

  return (
    <div className="stay-info-receipt" {...manageTags}>
      {resortVariation && <ResortLabel {...resortVariation} />}
      {resource && resource.images.length > 0 && (
        <Image
          file={resource.images[0].reference}
          filter="md_43"
          attr={{
            className: "card-img-top img-fluid",
            alt: resource.display_name,
          }}
        />
      )}
      {resource && (
        <h5 className="text-lato text-info mb-0 pt-1 px-1">
          {trans("label.your_booking")}
        </h5>
      )}
      <TravelCompanySummary properties={properties} />
      <ul className="list-unstyled mb-0 p-1">
        {reservation.arrivalDate && (
          <li className="row">
            <div className="col-4">{trans("stay.arrival")}</div>
            <div className="col-8 col-md-7 offset-md-1">
              {reservation.arrivalDate &&
                moment(reservation.arrivalDate).format(DISPLAY_DATE_WITH_DAY)}
            </div>
          </li>
        )}
        {reservation.departureDate && (
          <li className="row">
            <div className="col-4">{trans("stay.departure")}</div>
            <div className="col-8 col-md-7 offset-md-1">
              {reservation.departureDate &&
                moment(reservation.departureDate).format(DISPLAY_DATE_WITH_DAY)}
            </div>
          </li>
        )}
        {reservation.criteria && reservation.criteria.duration && (
          <li className="row">
            <div className="col-4">{trans("stay.label")}</div>
            <div className="col-8 col-md-7 offset-md-1">{`${
              reservation.criteria.duration
            } ${trans("stay.nights")}`}</div>
          </li>
        )}
      </ul>
      {showVoucherForm && (
        <>
          <h5 className="text-lato text-info mb-0 pt-1 px-1">
            {trans("label.voucher")}
          </h5>
          <StayInfoVoucherForm validateVoucher={handleUpdateInfo} />
        </>
      )}
    </div>
  );
};

const StayInfo = ({ reservation, resource, manageTags }) => {
  const isSafariResort = resource && resource.resort.id === ID_SAFARIRESORT;
  const isHotel = resource && resource.resort.id === ID_SAFARIRESORT_HOTEL;

  return (
    <div {...manageTags}>
      <h4 className="margin-bottom-1">{trans("stay.label")}</h4>
      <ul className="list-unstyled mb-0">
        {reservation.arrivalDate && (
          <li className="row">
            <div className="col-4 font-italic">{trans("stay.arrival")}</div>
            <div className="col-8">
              <strong style={{ display: "flex" }}>
                {reservation.arrivalDate &&
                  moment(reservation.arrivalDate).format("dddd D MMMM YYYY")}
              </strong>
              {isHotel && <span>{trans("stay.check_in_hotel")}</span>}
              {isSafariResort && <span>{trans("stay.check_in_resort")}</span>}
              {resource && !isSafariResort && !isHotel && (
                <span>
                  {[29, 30, 31, 32, 34, 82, 83, 518045, 2932045].indexOf(
                    parseInt(reservation.criteria.resource_id, 10)
                  ) !== -1
                    ? trans("stay.check_in_camping")
                    : trans("stay.check_in")}
                </span>
              )}
            </div>
          </li>
        )}
        {reservation.departureDate && (
          <li className="row">
            <div className="col-4 font-italic">{trans("stay.departure")}</div>
            <div className="col-8">
              <strong style={{ display: "flex" }}>
                {reservation.departureDate &&
                  moment(reservation.departureDate).format("dddd D MMMM YYYY")}
              </strong>
              {isHotel && <span>{trans("stay.check_out_hotel")}</span>}
              {!isHotel && (
                <span>
                  {[29, 30, 31, 32, 34, 82, 83, 518045, 2932045].includes(
                    parseInt(reservation.criteria.resource_id, 10)
                  )
                    ? trans("stay.check_out_camping")
                    : trans("stay.check_out")}
                </span>
              )}
            </div>
          </li>
        )}
        {reservation.criteria && reservation.criteria.duration && (
          <li className="row">
            <div className="col-4 font-italic">{trans("stay.label")}</div>
            <div className="col-8">
              <strong>{`${reservation.criteria.duration} ${trans(
                "stay.nights"
              )}`}</strong>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

StayInfo.propTypes = {
  reservation: PropTypes.object,
  resortId: PropTypes.number,
  resource: PropTypes.object,
};

StayInfoReceipt.propTypes = {
  properties: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // Might be of type array when empty
  reservation: PropTypes.object,
  resource: PropTypes.object,
  resortId: PropTypes.number,
};

const StayInfoWrapper = ({ properties, ...rest }) => (
  <>
    {properties.template === "receipt" && (
      <StayInfoReceipt properties={properties} {...rest} />
    )}
    {properties.template !== "receipt" && <StayInfo {...rest} />}
  </>
);

StayInfoWrapper.propTypes = {
  properties: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // Might be of type array when empty
  reservation: PropTypes.object,
  resource: PropTypes.object,
  resortId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  reservation: reservationSelector(state),
  resortId: reservationSelectors.getResortId(state),
  resource: resourceInReservationSelector(state),
  locale: state.preferences.locale,
});

const mapDispatchToProps = (dispatch) => ({
  updateReserve: (data, opts) =>
    dispatch(createReservationProposal(data, opts)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StayInfoWrapper);
