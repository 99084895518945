import React from 'react'
import * as common from './common'
import { blockregistry } from './blockregistry'

export function renderBlock (block, child) {
  const element = (typeof block === 'string') ? blockregistry.elements[block] : block

  return React.createElement(element, { key: child.id, ...child, block: child })
}

export function placeholder (entities, position, parentId, children = null) {

  if (parentId === null && children === null) return null

  // Make sure the blockregistry is initialized with the available blocks
  blockregistry.initialize()

  let blocks = children

  if (blocks === null && parentId !== null) {
    blocks = common.objectToArray(entities).filter(child => child.parentId === parentId)
  }

  const positioned = blocks.filter(b => b.position === parseInt(position, 10))
  const ordered = positioned.sort((a, b) => {
    if (a.sort !== b.sort) {
      return a.sort - b.sort
    }

    // If both the sort and position are equal, we should look at the parent sort
    // since blocks can be either part of the content or the template.
    if (a.sort === b.sort && a.position === b.position) {
      // If even the parent sorts are equal, the content blocks come first.
      if (a.sort_parent === b.sort_parent) {
        if (a.contentId && !b.contentId) {
          return -1
        }
        if (b.contentId && !a.contentId) {
          return 1
        }
      }
      return a.sort_parent - b.sort_parent
    }

    return 0
  })

  return ordered.map((child) => {
    if (blockregistry.elements === null) console.error('BlockRegistry not initialized')
    if (!{}.hasOwnProperty.call(blockregistry.elements, child.type)) {
      if (process.env.NODE_ENV !== 'production') console.warn(`Block ${child.type} is not registered`)
      return null
    }

    const element = blockregistry.elements[child.type]

    return renderBlock(element, child)
  })
}

export function findByType (content, entities, type) {
  return common.objectToArray(entities)
    .filter(b => b.type === type && b.contentId === content)
    .sort((a, b) => a.sort - b.sort)
}

export function findFormFields (content, entities) {
  const fieldBlocks = [
    'ChoiceFieldBlock',
    'DateFieldBlock',
    'EmailFieldBlock',
    'NumberFieldBlock',
    'RangeFieldBlock',
    'RichCheckItemBlock',
    'TextFieldBlock'
  ]
  return common.objectToArray(entities).filter(b => fieldBlocks.indexOf(b.type) !== -1 && b.contentId === content)
}

export function isLastFormSection (sections, id) {
  const i = sections.findIndex(s => s.id === id)

  return (sections.length === (i + 1))
}
