import React, { FC } from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
import Carousel from "react-multi-carousel";

// Types
import { Activity, SectionRelatedActivities } from "@libema/content-sdk";

// Components
import { H2, Section } from "@libema/design-system";

// Local Components
import Link from "../elements/Link";
import DetailsCard from "../blocks/DetailsCard/DetailsCard";

const responsive = {
  xxxl: {
    breakpoint: { max: 4000, min: 2200 },
    items: 6,
    slidesToSlide: 6,
    partialVisibilityGutter: 40,
  },
  xxl: {
    breakpoint: { max: 2200, min: 1820 },
    items: 5,
    slidesToSlide: 5,
    partialVisibilityGutter: 40,
  },
  xl: {
    breakpoint: { max: 1820, min: 1460 },
    items: 4,
    slidesToSlide: 4,
    partialVisibilityGutter: 40,
  },
  large: {
    breakpoint: { max: 1460, min: 1120 },
    items: 3,
    slidesToSlide: 3,
    partialVisibilityGutter: 40,
  },
  medium: {
    breakpoint: { max: 1120, min: 740 },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 0,
  },
  small: {
    breakpoint: { max: 740, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
};

const RelatedArticles = styled.div`
  & .react-multi-carousel-track {
    margin-bottom: 24px;
  }

  & .react-multi-carousel-dot button {
    border-radius: 0;
    background: ${({ theme }) => theme.color.secondary};
    border: 0;

    &:hover {
      background: ${({ theme }) => theme.color.primary};
    }
  }

  & .react-multi-carousel-item {
    padding: 0 12px;
    /* min-width: 320px; */
  }

  & .react-multi-carousel-dot--active button {
    background: ${({ theme }) => theme.color.primary};
  }

  & .react-multiple-carousel__arrow--left {
    left: calc(1% + 1px);
  }

  & .react-multiple-carousel__arrow--right {
    right: calc(1% + 1px);
  }
`;

const CTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;

const StyledActivityCard = styled.div`
  margin: 8px 24px;
  height: 100%;
  max-width: 360px;
  margin: 0 auto;
`;

const ActivityOverview: FC<SectionRelatedActivities> = ({
  title,
  activities,
  backgroundColor,
  callToAction,
}) => {
  const { locale } = useRouter();

  return (
    <Section.Wrapper backgroundColor={backgroundColor}>
      <Section.Container>
        {title && (
          <Section.Header>
            <H2>{title}</H2>
          </Section.Header>
        )}
      </Section.Container>
      <RelatedArticles>
        <Carousel responsive={responsive} showDots ssr>
          {activities?.map((activity: Activity) => (
            <StyledActivityCard key={activity.id}>
              <DetailsCard
                isVertical
                {...activity}
                locale={locale || "nl"}
                park={activity.location?.park}
                location={activity.location}
              />
            </StyledActivityCard>
          ))}
        </Carousel>
        {callToAction && (
          <CTAWrapper>
            <Link
              as={callToAction.displayStyle}
              href={callToAction.url}
              target={callToAction.target}
            >
              {callToAction.label}
            </Link>
          </CTAWrapper>
        )}
      </RelatedArticles>
    </Section.Wrapper>
  );
};

export default ActivityOverview;
