import { FC } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";

// Utils
import { contrastColor } from "../../utils/colorUtils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  & ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  & li {
    margin: 0 4px;
  }

  & a {
    text-decoration: none;
    cursor: pointer;
    padding: 4px 8px;
    background: ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => contrastColor(theme.color.secondary)};

    &:hover {
      background: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => contrastColor(theme.color.primary)};
    }
  }

  & .disabled a {
    color: ${({ theme }) => theme.color.grey_400} !important;
    cursor: not-allowed !important;
  }

  & .selected a {
    background: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => contrastColor(theme.color.primary)};
  }

  & .previous {
    margin-right: 12px;
  }

  & .next {
    margin-left: 12px;
  }

  & .previous a,
  & .next a {
    color: ${({ theme }) => theme.color.secondary};
    font-weight: bold;
    background: none !important;

    &:hover {
      text-decoration: underline;
    }
  }
`;

type Props = {
  pageCount: number;
  initialPage: number;
  previousLabel: string;
  nextLabel: string;
  className?: string;
  disableInitialCallback?: boolean;
  pageRangeDisplayed?: number;
  marginPagesDisplayed?: number;
  onPageChange: ({ selected }: { selected: number }) => void;
};

const Pagination: FC<Props> = ({
  pageCount,
  initialPage,
  previousLabel = "previous",
  nextLabel = "next",
  className,
  disableInitialCallback = false,
  pageRangeDisplayed = 5,
  marginPagesDisplayed = 5,
  onPageChange,
}) => {
  return (
    <Container className={className}>
      <ReactPaginate
        pageCount={pageCount}
        initialPage={initialPage}
        previousLabel={previousLabel}
        nextLabel={nextLabel}
        disableInitialCallback={disableInitialCallback}
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={marginPagesDisplayed}
        onPageChange={onPageChange}
      />
    </Container>
  );
};

export default Pagination;
