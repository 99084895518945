import { FC, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";
import styled from "styled-components";

// Types
import { SectionArticleOverview } from "@libema/content-sdk";

// Components
import {
  Row,
  Col,
  Section,
  H2,
  Loader,
  Pagination,
} from "@libema/design-system";

// Local Components
import ArticleCard from "../blocks/ArticleCard/ArticleCard";

const SectionWrapper = styled(Section.Wrapper)`
  header ${H2} {
    text-align: center;
    margin-bottom: 24px;
  }
`;

const CenteredRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArticleOverview: FC<SectionArticleOverview> = ({
  title,
  articles,
  backgroundColor,
  pageSize,
  totalArticles,
}) => {
  const [loading, setLoading] = useState(false);

  const router = useRouter();
  const { locale, query = {}, asPath } = router;
  const { slug } = query;

  const page =
    Array.isArray(slug) && asPath.indexOf("p/") > 0 ? slug.slice(-1)[0] : 0;

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const pageCount = Math.ceil(totalArticles / (pageSize || 1));

  useEffect(() => {
    setLoading(false);
  }, [asPath]);

  const onPageChange = async ({ selected }) => {
    const newIndex = Number(selected) + 1;
    const newPageIndex = String(newIndex);

    const isSamePage = Number(page) === newIndex;
    if (isSamePage) {
      return;
    }
    setLoading(true);

    if (asPath.indexOf("p/") > 0) {
      const newPage = asPath.replace(/\d/g, newPageIndex);
      await router.push(newPage, undefined, { scroll: false });
      return;
    }
    const newPage = `${asPath}/p/${newPageIndex}`;
    await router.push(newPage, undefined, { scroll: false });
  };

  return (
    <SectionWrapper backgroundColor={backgroundColor}>
      <Section.Container>
        {title && (
          <header>
            <H2>{title}</H2>
          </header>
        )}
        <Row>
          {articles?.map((article) => (
            <Col md={6} lg={4} key={article.id}>
              <ArticleCard {...article} locale={locale || "nl"} />
            </Col>
          ))}
        </Row>
        {loading && (
          <CenteredRow>
            <Loader />
          </CenteredRow>
        )}
        {pageCount > 1 && (
          <Pagination
            pageCount={pageCount}
            initialPage={Number(page)}
            previousLabel={t("article.overview.section.pagination.previous")}
            nextLabel={t("article.overview.section.pagination.next")}
            disableInitialCallback
            pageRangeDisplayed={5}
            marginPagesDisplayed={5}
            onPageChange={onPageChange}
          />
        )}
      </Section.Container>
    </SectionWrapper>
  );
};

export default ArticleOverview;
