const typographyHeader = {
  headerH1: {
    fontFamily: ["Anton"],
    fontSize: "3.5rem",
    lineHeight: "1.25",
    fontWeight: "400",
    textTransform: "uppercase",
  },
  headerH2: {
    fontFamily: ["Anton"],
    fontSize: "3rem",
    lineHeight: "1.25",
    fontWeight: "400",
    textTransform: "uppercase",
  },
  headerH3: {
    fontFamily: ["Anton"],
    fontSize: "2.5rem",
    lineHeight: "1.25",
    fontWeight: "400",
    textTransform: "uppercase",
  },
  headerH4: {
    fontFamily: ["Anton"],
    fontSize: "2rem",
    lineHeight: "1.25",
    fontWeight: "400",
    textTransform: "uppercase",
  },
  headerH5: {
    fontFamily: ["Anton"],
    fontSize: "1.5rem",
    lineHeight: "1.25",
    fontWeight: "400",
    textTransform: "uppercase",
  },
};

const typography = {
  fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  h1: {
    fontSize: "2.25rem",
    lineHeight: "1.1",
    fontWeight: "600",
  },
  h2: {
    fontSize: "2rem",
    lineHeight: "1.15",
    fontWeight: "600",
  },
  h3: {
    fontSize: "1.75rem",
    lineHeight: "1.3",
    fontWeight: "600",
  },
  h4: {
    fontSize: "1.5rem",
    lineHeight: "1.3",
    fontWeight: "600",
  },
  h5: {
    fontSize: "1.25rem",
    lineHeight: "1.3",
    fontWeight: "600",
  },
  subtitle1: {
    fontSize: "1.25rem",
    lineHeight: "1.3",
    fontWeight: "600",
  },
  subtitle2: {
    fontSize: "1rem",
    lineHeight: "1.3",
    fontWeight: "600",
  },
  intro: {
    fontSize: "1rem",
    lineHeight: "1.5",
    fontWeight: "600",
  },
  body1: {
    fontSize: "1rem",
    lineHeight: "1.5",
    fontWeight: "400",
  },
  body2: {
    fontSize: "0.875rem",
    lineHeight: "1.5",
    fontWeight: "400",
  },
  buttonLarge: {
    fontSize: "0.875rem",
    lineHeight: "1.25",
    fontWeight: "600",
  },
  buttonMedium: {
    fontSize: "0.875rem",
    lineHeight: "1.25",
    fontWeight: "600",
  },
  buttonSmall: {
    fontSize: "0.875rem",
    lineHeight: "1.25",
    fontWeight: "600",
  },
  caption: {
    fontSize: "0.75rem",
    lineHeight: "0.4",
    fontWeight: "300",
    fontStyle: "italic",
  },
  overline: {
    fontSize: "0.75rem",
    lineHeight: "1",
    fontWeight: "600",
  },
  avatarLetter: {
    fontSize: "1.25rem",
    lineHeight: "0.14",
    fontWeight: "300",
  },
  inputLabel: {
    fontSize: "0.75rem",
    lineHeight: "0.15",
    fontWeight: "400",
  },
  helperText: {
    fontSize: "0.75rem",
    lineHeight: "0.4",
    fontWeight: "400",
  },
  inputText: {
    fontSize: "1rem",
    lineHeight: "0.15",
    fontWeight: "400",
  },
  tooltip: {
    fontSize: "1rem",
    lineHeight: "1.5",
    fontWeight: "300",
  },
  ...typographyHeader,
};

export default typography;
