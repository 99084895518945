import React from 'react'
import PropTypes from 'prop-types'

const IFrameBlock = ({ block: { properties }, manageTags }) => (
  <iframe
    id={properties.id}
    frameBorder='0'
    width={properties.width}
    height={properties.height}
    src={properties.url}
    sandbox='allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation'
    className={`${properties.extra_classes ? properties.extra_classes : ''} ${(properties.styles || []).join(' ')}`}
    {...manageTags}
  />
)

IFrameBlock.propTypes = {
  block: PropTypes.object.isRequired,
  manageTags: PropTypes.object
}

export default IFrameBlock
