import * as t from './actionTypes'
import { get } from '../../utilities/api'

/**
 * Set a single content item
 */
export function setContent (content) {
  return {
    type: t.SET_CONTENT,
    content
  }
}

/**
 * Set an array of content items
 */
export function setContents (contents) {
  return {
    type: t.SET_CONTENTS,
    contents
  }
}

/**
 * Get a single content item by its ID or slug
 *
 * @param {int|string} idOrSlug
 */
export const getContent = idOrSlug => dispatch => get({ url: `/content/${idOrSlug}` })
  .then(({ data }) => dispatch(setContent(data)))
  .catch(() => {
    // Fetching content failed
  })

export const getRelatedContent = (filters, successCallback, errorCallback) => (dispatch) => {
  const query = Object.keys(filters).map((key) => {
    if (Array.isArray(filters[key])) {
      return filters[key].map(f => `${key}[]=${f}`).join('&')
    }
    return `${key}=${filters[key]}`
  }).join('&')

  return get({ url: `content/related?${query}` })
    .then(({ data }) => {
      dispatch(setContents(data.results || data))
      if (successCallback) {
        successCallback(data)
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error)
      }
    })
}

/**
 * Get a list of content items by an array of filters
 */
export const getContents = (filters, successCallback, errorCallback, cancellationToken) => (dispatch) => {
  const query = Object.keys(filters).map((key) => {
    if (Array.isArray(filters[key])) {
      return filters[key].map(f => `${key}[]=${f}`).join('&')
    }
    return `${key}=${filters[key]}`
  }).join('&')

  return get({ url: `contents?${query}` }, cancellationToken)
    .then(({ data }) => {
      dispatch(setContents(data.results || data))
      if (successCallback) {
        successCallback(data)
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error)
      }
    })
}
