import { FC } from "react";
import styled from "styled-components";

// Components
import { Loader } from "@libema/design-system";
import { useRouter } from "next/router";

// Providers
import useIsRouteLoading from "../../hooks/useIsRouteLoading";

type Props = {
  className?: string;
};

const Main = styled.main``;

const MainLoader = styled.div.attrs({ children: <Loader size="small" /> })`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #fffa;
`;

/**
 * Basic layout without header or footer.
 */
const PlainLayout: FC<Props> = ({ children, className }) => {
  const { isFallback } = useRouter();
  const isLoading = useIsRouteLoading();

  if (isFallback) return <MainLoader />;

  return (
    <>
      {isLoading && <MainLoader />}
      <Main className={className}>{children}</Main>
    </>
  );
};

export default PlainLayout;
