import styled from "styled-components";

export const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const Divider = styled.span`
  margin-left: 8px;
  color: ${({ theme }) => theme.color.grey_300};
`;

export const Item = styled.li`
  color: ${({ theme }) => theme.color.grey_700};
  margin: 0 8px 4px 0;
  font-size: 1rem;
  font-weight: bold;

  & a,
  & a:visited {
    color: ${({ theme }) => theme.color.grey_600};
    font-weight: normal;
  }

  & a:hover {
    color: ${({ theme }) => theme.color.secondary};
  }
`;

export default Wrapper;
