import cls from "classnames";
import styled from "styled-components";
import { DEVICE_MD_UP, DEVICE_LG_UP, Button } from "@libema/design-system";
import * as FilterProvider from "../../blocks/Filters/FilterProvider";

const FilterPanelStyled = styled.aside`
  flex-shrink: 0;
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  display: none;

  &.filterModalOpen {
    display: block;
    overflow-y: scroll;
  }

  @media ${DEVICE_LG_UP} {
    display: block;
    position: relative;
    width: 280px;
    z-index: 10;
    background: transparent;
  }

  & > div {
    padding: 24px;
  }

  ${Button} {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const FilterPanel = ({ children }) => {
  const { filterModalOpen } = FilterProvider.useFilters();

  return (
    <FilterPanelStyled
      className={cls({
        filterModalOpen,
      })}
    >
      {children}
    </FilterPanelStyled>
  );
};

export default FilterPanel;
