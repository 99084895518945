import * as React from "react";
import styled from "styled-components";
import Head from "next/head";
import { useIntl } from "react-intl";

// Icons
import { FaCheck } from "react-icons/fa";

// Types
import {
  Page as PageProps,
  LinkDisplayStyle,
  Locale,
} from "@libema/content-sdk";

// Components
import { H2, P, UnorderedList } from "@libema/design-system";
import MainLayout from "../components/layout/MainLayout";
import Link from "../components/elements/Link";

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
`;

const List = styled(UnorderedList)`
  list-style: none;
  padding: 0;

  & li {
    display: flex;
    align-items: center;

    & svg {
      fill: ${({ theme }) => theme.color.secondary};
      margin-right: 12px;
    }
  }
`;

const ErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1100px;
  padding: 40px 20px;

  ${P} {
    font-weight: bold;
  }

  ${UnorderedList} {
    margin-bottom: 24px;
  }
`;

type Props = PageProps & {
  availableLocales: Locale[];
};

export const Page: React.FC<Props> = ({ header, footer, availableLocales }) => {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  return (
    <>
      <Head>
        <title>{t("page.404.title")}</title>
        <meta name="description" content={t("page.404.meta.description")} />
      </Head>
      <MainLayout
        name="Klimrijk"
        header={header}
        footer={footer}
        availableLocales={availableLocales}
      >
        <Container>
          <ErrorBox>
            <P>{t("page.404.description")}</P>
            <H2>{t("page.404.subtitle")}</H2>
            <List>
              <li>
                <FaCheck /> {t("page.404.action.1")}
              </li>
            </List>
            <Link
              as={LinkDisplayStyle.BUTTON}
              href="/"
              buttonProps={{ size: "small", colorType: "secondary" }}
            >
              {t("page.404.button.home")}
            </Link>
          </ErrorBox>
        </Container>
      </MainLayout>
    </>
  );
};
