/**
 * DEPRECATED. Use `preferenceSelectors.js` instead.
 *
 * Using this file is deprecated. All logic should be moved to `preferenceSelectors.js`.
 */

import { createSelector } from 'reselect'
import { getPreference } from '../../selectors/preferenceSelectors'
import { getPropertyGroups, getSubjects as getSubjectsEntity } from '../../selectors/entitySelectors'

export const getPreferences = state => state.preferences
export const getProperties = state => state.preferences.properties
export const getPeriods = state => state.preferences.periods
export const getCapacities = state => state.preferences.capacities
export const getResources = state => state.preferences.resources
export const getArrival = state => state.preferences.arrival
export const getDeparture = state => state.preferences.departure
export const getSubjects = state => state.preferences.subjects

export const hasSubjects = createSelector(
  getPreferences,
  properties => Object.keys(properties.subjects).length > 0
)

export const hasPropertyGroups = createSelector(
  getPreferences,
  properties => Object.keys(properties.propertygroups).length > 0
)

/**
 * Returns the subjects as an array with the selected quantities
 */
export const getSubjectsWithQuantity = createSelector(
  getSubjectsEntity,
  getPreference('subjects'),
  (subjects, preference) => Object.values(subjects).map(subject => ({
    ...subject,
    quantity: parseInt(preference[subject.id], 10) || 0
  }))
)

/**
 * Gets the total amount of selected filters displayed in the filters dropdown
 */
export const numberOfFiltersSelected = createSelector(
  getPreference('kinds'),
  getPreference('properties'),
  getPreference('resorts'),
  (kinds = [], properties = [], resorts = []) => kinds.length + properties.length + resorts.length
)

// Temp solution to not knowing which groups are required / selected
const requiredFilterIds = [13, 14, 16, 17]

export const getSelectedPropertyGroups = createSelector(
  getPropertyGroups,
  groups => Object.values(groups).filter(group => requiredFilterIds.includes(group.id)).sort((a, b) => {
    if (a.priority > b.priority) {
      return -1
    }
    if (a.priority < b.priority) {
      return 1
    }
    return 0
  })
)

export const getSelectedPropertyGroupsPropertyIds = createSelector(
  getSelectedPropertyGroups,
  groups => groups.map(group => group.properties).reduce((acc, cur) => {
    acc.push(...cur)
    return acc
  }, [])
)

// Returns the properties that belong to the selected property groups.
export const getSelectedProperties = createSelector(
  getProperties,
  getSelectedPropertyGroupsPropertyIds,
  (properties, ids) => Object.values(properties).filter(property => ids.includes(property.id)).reduce((a, c) => {
    a[c.id] = c
    return a
  }, {})
)
