import { Activity } from "@libema/content-sdk";

// Components
import { Translate } from "@libema/design-system";
import Link from "../../elements/Link";

// Style
import { MoreInfoLink } from "./style";

type MoreInfoType = {
  detailPage: Activity["detailPage"];
  locale: string;
};

const MoreInfo = ({ detailPage, locale }: MoreInfoType) => {
  if (detailPage?.slug === null) return null;
  return (
    <MoreInfoLink>
      <Link href={detailPage?.slug || "/"} locale={locale}>
        <span>
          <Translate id="activity.more.link" />
        </span>
      </Link>
    </MoreInfoLink>
  );
};

export default MoreInfo;
