import BaseModel from "./BaseModel";
import TYPES from "../../../constants/types";

export type Filter = BaseModel<TYPES.Filter> & {
  title: string;
  filterType: string;
  attribute: string;
  sortType: FilterSortType;
  sortFunction: (a, b) => number;
};

export enum FilterSortType {
  ALPHABETICAL = "alphabetical",
  COUNT_DESC = "count desc",
  WEEKDAYS = "weekdays",
}
