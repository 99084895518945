import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Popover, PopoverBody } from 'reactstrap'
import { FacebookShareButton, GooglePlusShareButton, TwitterShareButton, TwitterIcon, FacebookIcon, GooglePlusIcon } from 'react-share'
import trans from 'counterpart'

class SocialShare extends Component {
  state = {
    popoverOpen: false
  };

  toggle = () => {
    this.setState(prevState => ({
      popoverOpen: !prevState.popoverOpen
    }))
  }

  render () {
    const { properties } = this.props
    const shareUrl = typeof window !== 'undefined' ? window.location.href : ''
    const encodeUrl = encodeURIComponent(shareUrl)
    const title = 'Beeksebergen'

    return (
      <div>
        <Button id='popOver' className='btn-icon-after' onClick={this.toggle}>
          {`${properties.buttonLabel || 'Deel dit bericht'}`}
          <i className='material-icons'>share</i>
        </Button>
        <Popover placement='top' isOpen={this.state.popoverOpen} popperClassName='popover-share' target='popOver' toggle={this.toggle}>
          {/* <PopoverTitle>Popover Title</PopoverTitle> */}
          <PopoverBody>
            <ul className='list-social list-inline'>
              <li>
                <FacebookShareButton url={shareUrl} title={title}>
                  <FacebookIcon size={36} />
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton url={shareUrl} title={title}>
                  <TwitterIcon size={36} />
                </TwitterShareButton>
              </li>
              <li>
                <GooglePlusShareButton url={shareUrl}>
                  <GooglePlusIcon size={36} />
                </GooglePlusShareButton>
              </li>
              <li>
                <div className='SocialMediaShareButton SocialMediaShareButton--mail'>
                  <a className='social-icon email' href={`mailto:?subject=${trans('share.mail.subject')}&body=${trans('share.mail.body')} ${encodeUrl}`}>
                    <i className='material-icons'>&#xE0E1;</i>
                  </a>
                </div>
              </li>
            </ul>
          </PopoverBody>
        </Popover>
      </div>
    )
  }
}

SocialShare.propTypes = {
  properties: PropTypes.object
}

export default SocialShare
