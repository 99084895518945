import { createSelector } from "reselect";
import { blocksByTypeSelector } from "../content/selectors";
import { resourceAdditionSelector, getObjects } from "../booking/selectors";
import { RESORT_RESORT, RESORT_HOLIDAYPARK, RESORT_HOTEL } from "./constants";
import { getSubjects, getProperties } from "../../selectors/entitySelectors";
import { getPreference } from "../../selectors/preferenceSelectors";
import subjectUtilities from "../../utilities/subjectUtilities";
import reservationSelectors from "../../selectors/reservationSelectors";
import { PreferenceType } from "../../constants/newyse";
import { ID_HOLIDAYPARK, ID_SAFARIRESORT, ID_SAFARIRESORT_HOTEL } from "../booking/constants";

export const propertyFilterCountSelector = (state) =>
  state.reservation.filters.properties;
export const reservationSelector = (state) => state.reservation;
const criteriaSelector = (state) => state.reservation.criteria;
const preferencesSelector = (state) => state.reservation.criteria.preferences;
export const resourceInReservationSelector = (state) =>
  state.entities.accommodationtypes[state.reservation.criteria.resource_id];

/**
 * Get the current resort
 *
 * @param {object} state
 */
export const getResort = (state) => {
  switch (reservationSelectors.getResortId(state)) {
    case ID_HOLIDAYPARK: {
      return RESORT_HOLIDAYPARK;
    }
    case ID_SAFARIRESORT: {
      return RESORT_RESORT;
    }
    case ID_SAFARIRESORT_HOTEL: {
      return RESORT_HOTEL;
    }
    default: {
      return null;
    }
  }
};

export const preferencesForReservationSelector = createSelector(
  resourceInReservationSelector,
  (resource) => (resource ? resource.properties : null)
);

export const propertyPreferenceSelector = createSelector(
  preferencesSelector,
  (preferences) => preferences.filter((p) => p.type === PreferenceType.AMENITY)
);

export const getObjectPreferences = createSelector(
  preferencesSelector,
  (preferences) => preferences.filter((p) => p.type === PreferenceType.UNIT)
);

/**
 * Returns an array of subject objects with their selected quantity
 */
export const subjectsInReservationSelector = createSelector(
  getSubjects,
  criteriaSelector,
  (subjects, criteria) => {
    if (!subjects || !Object.keys(subjects).length) return [];
    return criteria.subjects
      .map((s) => ({
        ...subjects[s.subject_id],
        quantity: s.quantity,
      }))
      .filter((s) => parseInt(s.quantity, 10) > 0);
  }
);

export const subjectsInReservationSelectorAsString = createSelector(
  subjectsInReservationSelector,
  /**
   * @param {{subject_id: string, quantity: string}[]} subjects
   */
  (subjects) => subjectUtilities.toQueryParam(subjects).join(",")
);

/**
 * Returns the total amount of subjects that are billable.
 * Pets and babies are excluded
 */
export const totalBillableSubjects = createSelector(
  subjectsInReservationSelector,
  (subjects) =>
    subjects.reduce(
      (a, b) =>
        a +
        (b.type === "person" && b.max_age !== 2 ? parseInt(b.quantity, 10) : 0),
      0
    )
);

/**
 * Get the categories from the available additions
 */
export const additionCategorySelector = createSelector(
  blocksByTypeSelector("AdditionBlock"),
  resourceAdditionSelector,
  (blocks, additions) => {
    const categories = {};
    blocks.forEach((b) => {
      const availableAdditions = b.resource_additions.filter(
        (a) =>
          additions.findIndex(
            (addition) => addition.additionResourceId === a.id
          ) !== -1
      );
      if (additions) {
        Object.keys(additions).forEach((key) => {
          availableAdditions.forEach((a) => {
            if (a.id.toString() === key) {
              a.price = additions[key].price;
            }
          });
        });
      }

      // If the block has no available additions, we skip to the next block
      if (availableAdditions.length < 1) {
        return;
      }

      // Loop through the blocks categories and map them to the array
      if (b.properties.categories) {
        b.properties.categories.forEach((cat) => {
          categories[cat.key] = cat.value;
        });
      }
    });
    return categories;
  }
);

/**
 * Price line selectors
 */

const isPartOfAllInPrice = (reservedResource) => {
  if (["ACCOMMODATIONTYPE", "SPECIAL"].indexOf(reservedResource.type) !== -1) {
    return true;
  }

  if (
    ["rk", "tb", "tbk", "kep", "schb", "skc", "skb", "schgb", "skv"].indexOf(
      reservedResource.code.toLowerCase()
    ) !== -1
  ) {
    return true;
  }

  return false;
};

const isPartOfAdditionalPrices = (reservedResource) => {
  if (isPartOfAllInPrice(reservedResource)) {
    return false;
  }

  // Avoid rendering
  // - Bedlinnen p.p.
  // - Toiletartikelen
  // - opgemaakte bedding bij aankomst
  if (
    [
      "bdlpp",
      "bdlppservic",
      "tp2p",
      "obbapp",
      "vlvlodgsf6",
      "voorldg8p",
      "voorgpr14",
    ].indexOf(reservedResource.code.toLowerCase()) !== -1
  ) {
    return false;
  }

  return true;
};

// Defines the sort of the priceline types
const sort = ["ACCOMMODATIONTYPE", "SPECIAL", "PRODUCTTYPE", "EXTRA"];

export const accoPriceLinesSelector = createSelector(
  reservationSelectors.getReservedResources,
  (reservedResources) =>
    reservedResources.filter(
      (r) => r.type === "ACCOMMODATIONTYPE" && r.imply === false
    )
);

export const additionPriceLinesSelector = createSelector(
  reservationSelectors.getReservedResources,
  (reservedResources) =>
    reservedResources
      .filter((r) => isPartOfAdditionalPrices(r))
      .sort((a, b) => sort.indexOf(a.type) - sort.indexOf(b.type))
);

/**
 * Get the object preference in the current reservation.
 *
 * Returns either null or an Object object.
 */
export const getObjectPreferenceInReservation = createSelector(
  getObjects,
  getObjectPreferences,
  (objects, objectPreferences) => {
    if (!objects || !objectPreferences) {
      return null;
    }

    return objectPreferences.map((op) => objects[op.id]).find(() => true);
  }
);

/**
 * Get the available objects for the current reservation
 */
export const getAvailableObjectsForReservation = createSelector(
  getObjects,
  reservationSelector,
  (allObjects, reservation) => {
    if (Object.keys(allObjects).length > 0 && reservation.unitIds.length > 0) {
      return reservation.unitIds.map((o) => allObjects[o]).filter((o) => o);
    }

    return [];
  }
);

export const getPropertiesWithPreferenceCost = createSelector(
  getProperties,
  (properties) =>
    Object.values(properties).reduce((acc, property) => {
      if (property.preference_costs) {
        acc.push(property.id);
      }
      return acc;
    }, [])
);

export const selectedPropertiesWithPreferenceCost = createSelector(
  getPropertiesWithPreferenceCost,
  getPreference("properties"),
  (properties, selectedProps) =>
    selectedProps.filter((prop) => properties.indexOf(prop) > -1)
);
