import React from 'react'
import PropTypes from 'prop-types'

const CarouselControls = props => (
  <div>
    <a
      className='left carousel-control carousel-control-prev'
      onClick={(e) => {
        e.preventDefault()
        return props.prev()
      }}
      role='button'
      data-slide='prev'
    >
      <span className='material-icons' aria-hidden='true'>&#xE314;</span>
      <span className='sr-only'>Previous</span>
    </a>
    <a
      className='right carousel-control carousel-control-next'
      onClick={(e) => {
        e.preventDefault()
        return props.next()
      }}
      role='button'
      data-slide='next'
    >
      <span className='material-icons' aria-hidden='true'>&#xE315;</span>
      <span className='sr-only'>Next</span>
    </a>
  </div>
)

CarouselControls.propTypes = {
  next: PropTypes.func,
  prev: PropTypes.func
}

export default CarouselControls
