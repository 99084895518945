import { useState, useEffect, useMemo } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const initialDimensions = useMemo(() => ({ width: 0, height: 0 }), []);
  const [windowDimensions, setWindowDimensions] = useState(initialDimensions);

  useEffect(() => {
    if (typeof window !== "undefined")
      setWindowDimensions(getWindowDimensions());

    function handleResize() {
      setWindowDimensions(window ? getWindowDimensions() : initialDimensions);
    }
    if (window) {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [initialDimensions]);

  return windowDimensions;
}
