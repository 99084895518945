import { gql } from "@apollo/client";

const fragment = gql`
  fragment FrequentlyAskedQuestion on FrequentlyAskedQuestion {
    sys {
      id
    }
    question
    answer {
      json
    }
    categories: categoriesCollection(limit: 5) {
      items {
        ... on Category {
          sys {
            id
          }
          title
        }
      }
    }
  }
`;

export default fragment;
