import { FC } from "react";
import styled from "styled-components";

// Types
import {
  CanonicalUrl,
  Footer as FooterInterface,
  Header as HeaderInterface,
  Locale,
} from "@libema/content-sdk";

// Components
import { Loader, NewsletterFormProps } from "@libema/design-system";
import { useRouter } from "next/router";

// Providers
import useIsRouteLoading from "../../hooks/useIsRouteLoading";

// Providers
import { useComponents } from "../../providers/ComponentProvider";

type Props = {
  name: string;
  canonicalUrls?: CanonicalUrl[];
  availableLocales: Locale[];
  footer: FooterInterface;
  header: HeaderInterface;
  postSubscriptions?: NewsletterFormProps["onSubmit"];
};

const Main = styled.main``;

const MainLoader = styled.div.attrs({ children: <Loader size="small" /> })`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #fffa;
`;

const MainLayout: FC<Props> = ({
  name,
  canonicalUrls,
  footer,
  header,
  availableLocales,
  children,
  postSubscriptions,
}) => {
  const { isFallback } = useRouter();
  const isLoading = useIsRouteLoading();
  const { customHeader: Header, customFooter: Footer } = useComponents();

  if (isFallback) return <MainLoader />;

  return (
    <>
      {isLoading && <MainLoader />}
      <Header
        {...header}
        canonicalUrls={canonicalUrls}
        availableLocales={availableLocales}
      />
      <Main>{children}</Main>
      <Footer
        {...footer}
        awards={footer.awards}
        menuItems={footer.menuItems}
        name={name}
        socialMedias={footer.socialMedias}
        onNewsletterSubscribe={postSubscriptions}
      />
    </>
  );
};

export default MainLayout;
