import React from 'react'
import PropTypes from 'prop-types'
import { groupValidateClasses, inputValidateClasses } from '../utilities'

/**
 * This field is used in the booking street. For radio buttons in the rest of the website, see `RadioButtonsField`.
 */
const RadioChoiceField = ({ name, input, meta: { touched, error }, options }) => (
  <div className={`input-group ${groupValidateClasses(touched, error)}`}>
    <div className='d-block btn-group paw-radio-group'>
      {options.map(o => (
        <label className='custom-control custom-radio' key={o.key}>
          <input
            {...input}
            type='radio'
            value={o.key}
            id={`${name}-${o.key}`}
            checked={input.value === o.key}
            onChange={event => input.onChange(event)}
            className={`custom-control-input ${inputValidateClasses(touched, error)}`}
          />
          <span className='custom-control-indicator' />
          <span className='custom-control-description'>{o.value}</span>
        </label>
      ))}
    </div>
    {touched && error && (
      <div className='col-xs-12'>
        <div className='form-control-feedback'>{error}</div>
      </div>
    )}
  </div>
)

RadioChoiceField.propTypes = {
  name: PropTypes.object,
  input: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object
}

export default RadioChoiceField
