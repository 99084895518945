import { FC } from "react";
import styled from "styled-components/macro";

// Types
import { Footer as FooterModel } from "@libema/content-sdk";

// Components
import NextLink from "next/link";
import {
  Container,
  Row,
  P,
  Col,
  Award,
  NewsletterForm,
  NewsletterFormProps,
  Translate,
  Footer,
  SocialIcon,
  H3,
  DEVICE_MD_UP,
  DEVICE_LG_UP,
} from "@libema/design-system";
import { SafeWebpImage } from "@libema/core";
import { ImageLoaderProps } from "next/image";

const StyledFooter = styled(Footer.Wrapper)`
  background: ${({ theme }) => theme.color.neutralLight};
  color: ${({ theme }) => theme.color.neutralDark};

  ${Col} {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const TopRow = styled(Row)`
  padding-top: 5rem;
  padding-bottom: ${({ theme }) => theme.padding.md};
  flex-wrap: wrap-reverse;

  @media ${DEVICE_LG_UP} {
    flex-wrap: wrap;
  }
`;

const BottomRow = styled(Row)`
  padding-top: ${({ theme }) => theme.padding.lg};
  padding-bottom: ${({ theme }) => theme.padding.lg};
`;

const LinksCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.padding.lg};
  padding-bottom: ${({ theme }) => theme.padding.lg};
  text-align: center;

  @media ${DEVICE_MD_UP} {
    text-align: left;
    flex-direction: row;
    justify-content: flex-start;
  }

  @media ${DEVICE_LG_UP} {
    padding-top: 0;
    padding-bottom: 0;
    justify-content: space-between;
  }

  ul {
    display: block;
    padding: 0;

    &:first-of-type {
      margin-bottom: ${({ theme }) => theme.padding.lg};
    }

    @media ${DEVICE_MD_UP} {
      margin: 0;
      flex-basis: 50%;
    }
  }
`;

const AwardsCol = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  @media ${DEVICE_MD_UP} {
    flex-direction: row;
    justify-content: flex-start;
  }

  ${Award.Wrapper} {
    padding: 0 16px;
  }
`;

const NewsLetterCol = styled(Col)`
  text-align: center;

  ${H3} {
    line-height: 1;
    margin-bottom: 8px;
  }

  ${P} {
    font-size: 1.1rem;
    color: ${({ theme }) => theme.color.grey_800};
    margin-bottom: 16px;
  }

  @media ${DEVICE_MD_UP} {
    text-align: left;
  }
`;

const DisclaimerCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  font-size: 1.15rem;
  text-align: center;

  @media ${DEVICE_MD_UP} {
    justify-content: flex-start;
    text-align: left;
  }
`;

const SocialsCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.15rem;
  margin-top: ${({ theme }) => theme.padding.lg};

  @media ${DEVICE_MD_UP} {
    flex-direction: row;
    margin-top: 0;
  }
`;

const AwardLabel = styled(P)`
  font-style: italic;
  margin-top: 24px;
  font-size: 1.2rem;
`;

const SocialLabel = styled(P)`
  margin: 0 ${({ theme }) => theme.padding.lg} 0 0;
`;

const StyledSocialIcon = styled(SocialIcon)`
  && {
    border: none;
    width: 50px;
  }

  && svg {
    fill: ${({ theme }) => theme.color.secondary};
  }
`;

type Props = {
  awards: FooterModel["awards"];
  menuItems?: FooterModel["menuItems"];
  name: string;
  onNewsletterSubscribe: NewsletterFormProps["onSubmit"];
  socialMedias?: FooterModel["socialMedias"];
};

const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?fm=webp`;
};

const PageFooter: FC<Props> = ({
  awards,
  menuItems,
  socialMedias,
  onNewsletterSubscribe,
  name,
}) => (
  <StyledFooter>
    <Container>
      <TopRow>
        <AwardsCol md={12} lg={4}>
          {awards.map(({ id, label, image }) => (
            <Award.Wrapper key={id}>
              <Award.ImageContainer>
                <SafeWebpImage
                  layout="fill"
                  objectFit="contain"
                  quality={85}
                  src={image.url}
                  alt={label}
                  loader={imageLoader}
                />
              </Award.ImageContainer>
              {label != null && <AwardLabel>{label}</AwardLabel>}
            </Award.Wrapper>
          ))}
        </AwardsCol>
        <LinksCol md={12} lg={4}>
          {menuItems?.map((menuItem) => (
            <ul key={menuItem.id}>
              {menuItem.links.map((link) => (
                <li key={link.id}>
                  <NextLink href={link.url}>
                    <Footer.Link>{link.label}</Footer.Link>
                  </NextLink>
                </li>
              ))}
            </ul>
          ))}
        </LinksCol>
        <NewsLetterCol md={12} lg={4}>
          <H3>
            <Translate id="footer.newsletter.title" />
          </H3>
          <P>
            <Translate id="footer.newsletter.description" />
          </P>
          <NewsletterForm.Form onSubmit={onNewsletterSubscribe} />
        </NewsLetterCol>
      </TopRow>
      <BottomRow>
        <DisclaimerCol md={6}>
          <Translate id="footer.title" values={{ account: name }} />
        </DisclaimerCol>
        <SocialsCol md={6}>
          <SocialLabel>
            <Translate id="footer.title.social" />
          </SocialLabel>
          <Footer.SocialMedia>
            {socialMedias?.map((socialMedia) => (
              <StyledSocialIcon
                key={socialMedia.id}
                platform={socialMedia.platform}
                href={socialMedia.url}
                label={`${name} op ${socialMedia.platform}`}
              />
            ))}
          </Footer.SocialMedia>
        </SocialsCol>
      </BottomRow>
    </Container>
  </StyledFooter>
);

export default PageFooter;
