import { FC } from "react";
import styled from "styled-components";

// Types
import { SectionHero } from "@libema/content-sdk";
import { H1, H2, H3 } from "@libema/design-system";

// Components
import { Sections } from "@libema/core";

const { Hero } = Sections;

const StyledHero = styled(Hero)`
  padding-top: 0 !important; // override legacy styles
  
  ${H1}, ${H2}, ${H3} {
    font-size: 48px;
    font-family: Anton;
    line-height: 1.25;
    font-weight: 400;
    text-shadow: none;
  }
`;

const CustomHero: FC<SectionHero> = ({ ...props }) => {
  return <StyledHero {...props} withDarkGradient />;
};

export default CustomHero;
