import { changeSectionUrl } from '../modules/content/utilities'
import { isLastFormSection } from '../utilities/blocks'
import { sectionsSelector, currentContentSelector } from '../modules/content/selectors'

export const CLEAR_SUBMIT_SECTION = 'CLEAR_SUBMIT_SECTION'
export const EMIT_BUTTON_CLICK = 'EMIT_BUTTON_CLICK'
export const EMIT_LINK_CLICK = 'EMIT_LINK_CLICK'
export const FETCH_SOCIAL_DATA = 'FETCH_SOCIAL_DATA'
export const HIDE_BLOCK = 'HIDE_BLOCK'
export const HIDE_BLOCKS = 'HIDE_BLOCKS'
export const NEXT_SECTION = 'NEXT_SECTION'
export const POST_LOAD_CONTENT = 'POST_LOAD_CONTENT'
export const POST_SUBMIT_SECTION = 'POST_SUBMIT_SECTION'
export const PREV_SECTION = 'PREV_SECTION'
export const SET_BLOCKS = 'SET_BLOCKS'
export const SET_CURRENT_SECTION = 'SET_CURRENT_SECTION'
export const SET_FACEBOOK_DATA = 'SET_FACEBOOK_DATA'
export const SET_INITIAL_FORMUI_STATE = 'SET_INITIAL_FORMUI_STATE'
export const SET_LINKEDIN_DATA = 'SET_LINKEDIN_DATA'
export const SHOW_BLOCK = 'SHOW_BLOCK'

export function setBlocks (content, response) {
  return {
    type: SET_BLOCKS,
    content,
    response
  }
}

export function clearSubmitSection (content) {
  return {
    type: CLEAR_SUBMIT_SECTION,
    content
  }
}

/**
 * Switches the current section of the passed content item
 *
 * @param {number} content
 * @param {{ id: number }} section
 */
export const setCurrentSection = (content, section) => {
  return (dispatch, getState) => {
    const sections = sectionsSelector(getState())
    const idx = sections.findIndex(s => s.id === section.id)

    dispatch({
      type: SET_CURRENT_SECTION,
      content,
      currentSection: sections[idx].id
    })

    changeSectionUrl(idx + 1)
  }
}

/**
 * Initialize the form section state
 *
 * @param {number} content
 * @param {array} sections
 * @param {object} current
 */
export function setInitialFormUIState (content, sections, current) {
  return (dispatch, getState) => {
    const currentContent = getState().content[content]
    // Avoid setting initial sections when they're already present (e.g. loaded from localStorage)
    if (currentContent && currentContent.currentSection) {
      const idx = sections.findIndex(s => s.id === currentContent.currentSection)
      // Only avoid setting initial sections when stored section was the last section
      if (idx && idx > 0 && idx !== (sections.length - 1)) {
        changeSectionUrl(idx + 1)
        return
      }
    }

    dispatch({
      type: SET_INITIAL_FORMUI_STATE,
      content,
      sections,
      currentSection: current
    })
  }
}

export function postSubmitSection (form, content, entities, sectionId) {
  return (dispatch, getState) => {
    const state = getState()
    const sections = sectionsSelector(state)

    const idx = sections.findIndex(s => s.id === sectionId)

    dispatch({
      type: POST_SUBMIT_SECTION,
      content,
      section: sections[idx].id,
      next: (sections[idx + 1]) ? sections[idx + 1].id : sections[idx].id,
      form
    })

    if (!isLastFormSection(sections, sectionId)) {
      changeSectionUrl(idx + 2)
    }
  }
}

export function nextSection (content) {
  return {
    type: NEXT_SECTION,
    content
  }
}

export function previousSection (content) {
  return (dispatch, getState) => {
    const state = getState()
    const sections = sectionsSelector(state)
    const currentContent = currentContentSelector(state)
    const idx = sections.findIndex(s => s.id === currentContent.currentSection)

    dispatch({
      type: SET_CURRENT_SECTION,
      content,
      currentSection: sections[idx - 1].id
    })

    changeSectionUrl(idx)
  }
}

export function hideBlock (content, hiddenBlock) {
  return {
    type: HIDE_BLOCK,
    content,
    hiddenBlock
  }
}

export function showBlock (content, block) {
  return {
    type: SHOW_BLOCK,
    content,
    block
  }
}

export function hideBlocks (content, hiddenBlocks) {
  return {
    type: HIDE_BLOCKS,
    content,
    hiddenBlocks
  }
}

export function fetchSocialData () {
  return {
    type: FETCH_SOCIAL_DATA
  }
}

export function setFacebookData (userData) {
  return {
    type: SET_FACEBOOK_DATA,
    userData
  }
}

export function setLinkedInData (userData) {
  return {
    type: SET_LINKEDIN_DATA,
    userData
  }
}

export function emitButtonClick (block) {
  return {
    type: EMIT_BUTTON_CLICK,
    block,
    meta: {
      eventType: 'click',
      systemEvent: true
    }
  }
}

export function emitLinkClick (block, targetId) {
  return {
    type: EMIT_LINK_CLICK,
    block,
    node: {
      id: targetId,
      type: 'link'
    },
    meta: {
      eventType: 'click',
      systemEvent: true
    }
  }
}

export function postLoadContent (content) {
  return {
    type: POST_LOAD_CONTENT,
    content
  }
}
