import { Entry } from "contentful";
import { normalizeAsset, normalizeLink } from "./normalizer";
import TYPES from "../../../constants/types";
import Activity from "../outputTypes/Activity";
import Category from "../outputTypes/Category";
import TargetAudience from "../outputTypes/TargetAudience";

const normalizeCategory = (category: any): Category => {
  return {
    id: category.sys.id,
    title: category.fields.title,
    type: TYPES.Category,
    // TODO: Check
    activityIds: [],
  };
};

const normalizeTargetAudience = (targetAudience: any): TargetAudience => {
  return {
    id: targetAudience.sys.id,
    title: targetAudience.fields.title,
    type: TYPES.TargetAudience,
  };
};

const normalizePriceTable = (priceTable) => {
  if (!priceTable) {
    return undefined;
  }

  const { prices, conditions } = priceTable.fields;
  return {
    prices: prices?.map((price) => price.fields) || [],
    conditions:
      conditions?.map(({ fields: { icon, condition } }) => ({
        icon,
        text: {
          json: {
            ...condition,
          },
        },
      })) || [],
  };
};

const normalizeActivity = (entry: Entry<any>): Activity => {
  const normalizedMainImage = normalizeAsset(entry.fields?.mainImage);
  const normalizedImages = entry.fields?.images?.map(normalizeAsset);
  const normalizedLink = entry.fields?.cta
    ? normalizeLink(entry.fields.cta)
    : undefined;

  const normalizedCategories = entry.fields?.categories?.map(normalizeCategory);

  const normalizedTargetAudience = entry.fields?.targetAudience?.map(
    normalizeTargetAudience
  );

  const normalizedActivity: Activity = {
    id: entry.sys.id,
    type: TYPES.Activity,
    title: entry.fields.title,
    subtitle: entry.fields.subtitle,
    mainImage: normalizedMainImage,
    images: normalizedImages || null,
    description: {
      json: entry.fields.description || null,
    },
    longDescription: entry.fields.longDescription && {
      json: entry.fields.longDescription,
    },
    highlightLabel: entry.fields.highlightLabel,
    cardDetails: entry.fields.cardDetails,
    minAge: entry.fields.minAge,
    minHeight: entry.fields.minHeight,
    minPersons: entry.fields.minPersons,
    lowestPrice: entry.fields.lowestPrice,
    lowestPriceRegular: entry.fields.lowestPriceRegular,
    priceUnit: entry.fields.priceUnit,
    priceTable: normalizePriceTable(entry.fields?.priceTable),
    duration: entry.fields.duration,
    location: {
      name: entry.fields.location?.fields.name,
      park: {
        name: entry.fields.location?.fields.park?.fields.name,
      },
    },
    dayPeriod: entry.fields.dayPeriod,
    weekdays: entry.fields.weekdays,
    startingTime: entry.fields.startingTime,
    scheduleItems: entry.fields.scheduleItems,
    cta: normalizedLink,
    detailPage: entry.fields.detailPage && {
      slug: entry.fields.detailPage?.fields?.slug,
    },
    categories: normalizedCategories,
    targetAudience: normalizedTargetAudience,
    systemTags: entry.metadata.tags?.map((tag) => tag.sys.id),
  };
  return normalizedActivity;
};

export default normalizeActivity;
