import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import trans from "counterpart";
import { useModal } from "mui-modal-provider";

// Components
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import InfoIcon from "@mui/icons-material/Info";
import { Dot } from "@libema/design-system";
import DatePicker from "./DatePicker";

// Internal Components
import { getArrival, getDeparture } from "../../../preferences/selectors";
import { getFilter } from "../../../../selectors/filterSelectors";
import { setArrivalPreference } from "../../../../actions/preferenceActions";
import { INTERNAL_DATE } from "../../../../constants/dates";
import { isMonthBlocked } from "./pickerUtils";
import { getPreference } from "../../../../selectors/preferenceSelectors";
import { PREFERENCE_KINDS } from "../../../../constants/preferences";
import { APP_BEEKSEBERGEN } from "../../../../constants/apps";
import DatesInfoDialogContent from "../DatesInfoDialog";
import useChangeoverDaysFilter from "./useChangeoverDaysFilter";
import { isBeekseBergen } from "../../../../utilities/common";

const ArrivalDatePicker: FC<any> = ({
  arrival,
  onChange,
  onShowFlexibleBookingDaysChange,
  end,
  setArrivalDate,
  toggle,
  availableDates,
  showLegend,
  showInfoDialog,
  changeoverDays,
  changeoverExceptionDates = [],
  showFlexibleBookingDays,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [monthBlocked, setMonthBlocked] = useState(false);

  const availableDatesFiltered = useChangeoverDaysFilter({
    availableDates,
    showFlexibleBookingDays,
    showLegend,
    changeoverDays,
    changeoverExceptionDates,
  });

  const picker = useRef<HTMLDivElement>(null);
  const { showModal } = useModal();
  const openModal = () => {
    const modal = showModal(DatesInfoDialogContent, {
      getId: () => modal.id,
    });
  };

  const toggleDropdown = useCallback(() => setDropdown(!dropdown), [dropdown]);

  const handleClickOutside = useCallback(
    (event) => {
      const { current } = picker;
      if (current && dropdown && !current.contains(event.target)) {
        toggleDropdown();
      }
    },
    [dropdown, toggleDropdown]
  );

  const getFutureReservationsRoute = () => {
    if (!isBeekseBergen) {
      return trans("routes.reserve_dierenbos");
    }

    return trans("routes.reserve");
  };

  const getReminderRoute = () => {
    if (!isBeekseBergen) {
      return trans("routes.reminder_dierenbos");
    }

    return trans("routes.reminder");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div
      className={`arrival-date form-dropdown form-arrival dropdown ${
        dropdown ? "show" : ""
      } `}
      ref={picker}
    >
      <div className="dropdown-toggle" onClick={toggleDropdown}>
        <div className="dropdown-filter">
          <div className="dropdown-heading">{trans("label.arrival_date")}</div>
          <div className="dropdown-value">
            {moment(arrival).format("DD-MM-YYYY")}
          </div>
        </div>
      </div>

      {dropdown && (
        <div className={`dropdown-menu${monthBlocked ? " future-date" : ""}`}>
          <DatePicker
            availableDates={availableDatesFiltered}
            start={arrival}
            end={end}
            date={arrival}
            onDateClick={(date) => {
              setArrivalDate(date);
              onChange(date);
              if (toggle) toggle("openDeparture", true);
              setDropdown(false);
            }}
            onMonthChange={(date) => {
              if (isMonthBlocked(date, isBeekseBergen)) {
                return setMonthBlocked(true);
              }
              return setMonthBlocked(false);
            }}
            renderWeekHeaderElement={(weekday) => {
              const weekdayNumber = moment(weekday, "dddd").weekday();
              const showRegularChangeoverDays = changeoverDays?.includes(
                weekdayNumber.toString()
              );
              if (!showLegend) return weekday;
              return (
                <Stack>
                  <Box justifyContent="center">{weekday}</Box>
                  <Stack direction="row" sx={{ justifyContent: "center" }}>
                    {showRegularChangeoverDays && (
                      <Dot color="accentPrimary.main" sx={{ mx: 0.5 }} />
                    )}
                    {showFlexibleBookingDays && <Dot sx={{ mx: 0.5 }} />}
                  </Stack>
                </Stack>
              );
            }}
          />
          <div className="future-booking">
            <div className="mb-3">
              <p>
                {trans(
                  `label.future_booking${isBeekseBergen ? "" : "_dierenbos"}`
                )}
              </p>
              <a className="btn btn-mail" href={getFutureReservationsRoute()}>
                {trans("label.future_reservation")}
              </a>
            </div>
            <div>
              <p>
                {trans(
                  `label.future_booking_reminder${
                    isBeekseBergen ? "" : "_dierenbos"
                  }`
                )}
              </p>
              <a className="btn btn-mail" href={getReminderRoute()}>
                {trans("label.book_reminder")}
              </a>
            </div>
          </div>
          {showLegend && (
            <Stack mt={2}>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <Dot
                    sx={{
                      margin: "0 10px 0 0",
                    }}
                  />
                  <Typography
                    variant="body2"
                    color={
                      showFlexibleBookingDays ? "text.primary" : "text.disabled"
                    }
                  >
                    {trans("label.datepicker_toggle_hotel_camping")}
                  </Typography>
                </Stack>
                <Switch
                  checked={showFlexibleBookingDays}
                  size="small"
                  onChange={(e) =>
                    onShowFlexibleBookingDaysChange(e.target.checked)
                  }
                />
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <Dot
                    sx={{
                      margin: "0 10px 0 0",
                    }}
                    color="accentPrimary.main"
                  />
                  <Typography variant="body2" color={"text.primary"}>
                    {trans("label.datepicker_toggle_arrivals")}
                  </Typography>
                </Stack>
                <Switch checked={true} size="small" disabled />
              </Stack>
            </Stack>
          )}
          {showInfoDialog && (
            <Stack sx={{ mt: 2 }}>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    color="text_disabled"
                    variant="body2"
                    sx={{ textDecoration: "underline" }}
                  >
                    {trans("label.date_info_dialog_link")}
                  </Typography>
                  <InfoIcon color="secondary" />
                </Stack>
              </Link>
            </Stack>
          )}
        </div>
      )}
    </div>
  );
};

ArrivalDatePicker.defaultProps = {
  onChange: () => null,
  onShowFlexibleBookingDaysChange: () => null,
  showLegend: false,
  showInfoDialog: false,
  showFlexibleBookingDays: false,
  changeoverDays: [],
};

ArrivalDatePicker.propTypes = {
  arrival: PropTypes.string,
  onChange: PropTypes.func,
  end: PropTypes.string,
  setArrivalDate: PropTypes.func,
  availableDates: PropTypes.array,
  showLegend: PropTypes.bool,
  showInfoDialog: PropTypes.bool,
  changeoverDays: PropTypes.arrayOf(PropTypes.string),
  changeoverExceptionDates: PropTypes.arrayOf(PropTypes.string),
  showFlexibleBookingDays: PropTypes.bool,
  onShowFlexibleBookingDaysChange: PropTypes.func,
  toggle: PropTypes.func,
};

const mapStateToProps = (state) => ({
  kindsPreference: getPreference(PREFERENCE_KINDS)(state),
  arrival: getArrival(state),
  availableDates: getFilter("arrivals")(state),
  end: getDeparture(state),
});

const mapDispatchToProps = (dispatch) => ({
  setArrivalDate: (date) =>
    dispatch(setArrivalPreference(date.format(INTERNAL_DATE))),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArrivalDatePicker);
