import React from "react";
import { FieldProps } from "formik";
import styled from "styled-components/macro";
import { lighten } from "polished";

// See https://www.w3schools.com/howto/howto_css_custom_checkbox.asp

export type RadioMarkProps = {
  disabled?: boolean;
  checked?: boolean;
};

const RadioMark = styled.span<RadioMarkProps>`
  display: block;
  position: relative;
  height: 25px;
  width: 25px;
  background-color: ${({ disabled, theme }) =>
    disabled !== true ? "#fff" : theme.color.neutralLight};
  border-radius: 50%;
  border: 1px solid
    ${({ checked, disabled, theme }) =>
      disabled
        ? lighten(0.4, theme.color.dark)
        : checked
        ? theme.color.primary
        : theme.color.dark};

  :after {
    top: 4px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    content: "";
    position: absolute;
    display: none;
  }
`;

const RadioWrapper = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 1.2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ ${RadioMark}:after {
    display: block;
    background: ${({ theme }) => theme.color.primary};
  }
`;

type RadioProps = {
  id?: string;
  checked: boolean;
  label?: string;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  value: string;
};

const Radio: React.FC<RadioProps> = ({
  checked,
  disabled,
  onChange,
  value,
  ...restProps
}) => (
  <RadioWrapper
    onClick={() => {
      if (typeof disabled === "undefined" || !disabled) {
        onChange(!checked);
      }
    }}
  >
    <input
      {...restProps}
      type="radio"
      checked={checked}
      disabled={disabled}
      onChange={() => {
        // Do nothing, since this element is actually hidden.
        // The change is handled by the `onClick` on the wrapping element
      }}
      value={value}
    />
    <RadioMark disabled={disabled} checked={checked} />
  </RadioWrapper>
);

type RadioFieldProps = FieldProps & {
  onChange?: (value: string) => void;
  value: string;
};

const RadioField: React.FC<RadioFieldProps> = ({
  field,
  form: { setFieldValue },
  value,
  onChange,
  ...restProps
}) => {
  const handleChange = () => {
    setFieldValue(field.name, value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Radio
      {...restProps}
      value={field.value}
      checked={field.value === value}
      onChange={handleChange}
    />
  );
};

export default RadioField;
