import { InputHTMLAttributes } from "react";
import * as React from "react";
import styled from "styled-components/macro";

type CheckMarkProps = {
  error?: boolean;
};

const SIZE = 16;

const CheckMark = styled.span<CheckMarkProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${SIZE}px;
  width: ${SIZE}px;
  background: ${({ theme }) => theme.color.grey_500};
  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    width: ${SIZE * 0.5}px;
    height: ${SIZE * 0.5}px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.secondary};
  }
`;

const RadioWrapper = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: ${SIZE}px;
  width: ${SIZE}px;

  /* Hide the browser's default radio button */
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover ~ ${CheckMark} {
    background-color: ${({ theme }) => theme.color.grey_400};
  }

  & input:checked ~ ${CheckMark}:after {
    display: block;
  }
`;

type Props = {
  id?: string;
  type?: string;
  name?: string;
  disabled?: boolean;
  checked?: boolean;
  error?: boolean;
  onChange: (value: boolean) => void;
};

const Radio: React.FC<Props> = ({ checked, error, onChange, ...restProps }) => (
  <RadioWrapper>
    <input
      {...restProps}
      type="radio"
      checked={checked}
      onChange={() => onChange(!checked)}
    />
    <CheckMark error={error} />
  </RadioWrapper>
);

export default Radio;
