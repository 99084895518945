import { FC } from "react";
import trans from "counterpart";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useModal } from "mui-modal-provider";
import CloseIcon from "@mui/icons-material/Close";

type Props = DialogProps & {
  getId: () => string;
};

const TermsConditions = () => {
  const translations = (part: string) =>
    trans(`label.date_info_dialog_terms_conditions_${part}`);

  return (
    <Typography variant="body2">
      {translations("part1")}
      <Link
        target="_blank"
        href="/overnachten/annuleringsvoorwaarden"
        color="text.primary"
      >
        {translations("part2")}
      </Link>
      {translations("part3")}
    </Typography>
  );
};

const DatesInfoDialog: FC<Props> = ({ getId, ...props }) => {
  const { hideModal } = useModal();
  return (
    <Dialog maxWidth="md" {...props}>
      <DialogTitle
        sx={{
          padding: 1,
          paddingY: {
            sm: 2,
          },
          paddingX: {
            sm: 3,
          },
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h5">
            {trans("label.date_info_dialog_title")}
          </Typography>
          <IconButton onClick={() => hideModal(getId())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 1,
          paddingY: {
            sm: 2,
          },
          paddingX: {
            sm: 3,
          },
        }}
      >
        <Stack
          spacing={2}
          sx={{
            ".MuiGrid-container": {
              alignItems: "start",
            },
            ".MuiGrid-item": {
              padding: "3px",
              wordBreak: "break-word",
            },
          }}
        >
          <Typography variant="body2">
            {trans("label.date_info_dialog_text1")}
          </Typography>
          <Typography variant="body1">
            {trans("label.date_info_dialog_text2")}
          </Typography>
          <Typography variant="body2">
            {trans("label.date_info_dialog_text3")}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list1_item1_key")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list1_item1_value")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list1_item2_key")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list1_item2_value")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list1_item3_key")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list1_item3_value")}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body1">
            {trans("label.date_info_dialog_text4")}
          </Typography>
          <Typography variant="body2">
            {trans("label.date_info_dialog_text5")}
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list2_item1_key")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list2_item1_value")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list2_item2_key")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list2_item2_value")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list2_item3_key")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list2_item3_value")}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body1">
            {trans("label.date_info_dialog_text6")}
          </Typography>
          <Typography variant="body2">
            {trans("label.date_info_dialog_text7")}
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list3_item1_key")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list3_item1_value")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list3_item2_key")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list3_item2_value")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list3_item3_key")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {trans("label.date_info_dialog_list3_item3_value")}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2">
            {trans("label.date_info_dialog_text8")}
          </Typography>
          <TermsConditions />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DatesInfoDialog;
