import styled from "styled-components";
import { Icon } from "@iconify/react";
import { Col, DEVICE_MD_UP, Row, Translate } from "@libema/design-system";
import RichTextRenderer from "../../renderer/RichTextRenderer";
import { LeftColumn, RightColumn } from "./EventSchedule";
import { ImageLoaderProps } from "next/image";
import SafeWebpImage from "../../elements/SafeWebpImage";
import { RiCake2Line, RiMapPinLine } from "react-icons/ri";

const OpenTime = styled.div`
  font-weight: 600;
  padding-top: 0.6rem;
`;

const Title = styled.div`
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 8px;
`;

const Description = styled.div`
  line-height: 1.5rem;
  margin-bottom: 8px;
`;

export const DetailCardWrapper = styled(Col)`
  padding: ${({ theme }) => theme.padding.md};
`;

export const DetailCard = styled.div`
  background-color: ${({ theme }) => theme.color.neutralLight};
  padding: ${({ theme }) => theme.padding.md};
  border-radius: 2px;
  margin-bottom: 1rem;
`;

export const StyledActivityEventCard = styled(Row)`
  display: flex;
  font-size: 1rem;

  > ${Col} {
    display: flex;
    padding-right: 25px;
  }

  &:last-of-type {
    ${DetailCard} {
      margin-bottom: 0;
    }
  }
`;

const Detail = styled.div`
  display: flex;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 0.5rem;
    width: 24px;
    height: 24px;
  }
`;

const Separator = styled.div`
  width: 2px;
  background-color: ${({ theme }) => theme.color.grey_light_100};
  margin-right: 0.7rem;
`;

const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?w=${width}&h=${Math.ceil(width * 0.5)}&q=${
    quality || 85
  }&fit=fill&f=faces&fm=webp`;
};

const ActivityEventCard = ({ event }) => {
  const { openTime, closeTime, title, description, location, ageGroup, image } =
    event;
  return (
    <StyledActivityEventCard>
      <Col col={12}>
        {(openTime || closeTime) && (
          <>
            <LeftColumn>
              <OpenTime>{openTime}</OpenTime>
              <div>{closeTime}</div>
            </LeftColumn>
            <Separator />
          </>
        )}

        {image && (
          <LeftColumn>
            <SafeWebpImage
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              quality={90}
              src={image.url}
              alt={image.description}
              title={image.title}
              loader={imageLoader}
            />
          </LeftColumn>
        )}
        <RightColumn>
          <DetailCard>
            <Title>{title}</Title>
            <Description>
              <RichTextRenderer text={description}></RichTextRenderer>
            </Description>
            {location && (
              <Detail>
                <RiMapPinLine />
                {[location.park.name, location.name].join(", ")}
              </Detail>
            )}
            {ageGroup && (
              <Detail>
                {ageGroup.icon && <RiCake2Line />}
                {ageGroup.label}
              </Detail>
            )}
          </DetailCard>
        </RightColumn>
      </Col>
    </StyledActivityEventCard>
  );
};

export default ActivityEventCard;
