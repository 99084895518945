import { connectSearchBox } from "react-instantsearch-dom";
import { useIntl } from "react-intl";
import debounce from "lodash/debounce";
import { useCallback } from "react";

const SearchBoxConnected = ({ refine, query }) => {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const debouncedSearch = useCallback(
    debounce((e) => refine(e.target.value), 500),
    []
  );

  const handleChange = (e) => {
    e.persist();
    debouncedSearch(e);
  };

  return (
    <form action=" " role="search">
      <label htmlFor="algolia_search"></label>
      <input
        id="algolia_search"
        placeholder={t("filter.searchbox.placeholder")}
        type="search"
        defaultValue={query}
        onChange={handleChange}
      />
    </form>
  );
};

export default connectSearchBox(SearchBoxConnected);
