import { useMemo } from "react";
import { Activity, CardDetails, Program, TYPES } from "@libema/content-sdk";

// Components
import { Translate, P } from "@libema/design-system";

// Icons
import { RiCake2Line, RiMapPinLine } from "react-icons/ri";
import { HiOutlineClock } from "react-icons/hi";
import { FiShoppingBag, FiCalendar } from "react-icons/fi";
import { GiBodyHeight } from "react-icons/gi";

// Style
import { List, ListItem } from "./style";

type DetailListProps = {
  cardDetails: CardDetails;
  minPersons?: Activity["minPersons"];
  duration?: Activity["duration"] | Program["duration"];
  minAge?: Activity["minAge"] | Program["minAge"];
  minHeight?: Activity["minHeight"];
  location?: Activity["location"] | Program["location"];
  weekdays?: Activity["weekdays"];
  startingTime?: Activity["startingTime"];
  park?: Activity["park"];
  price?: Program["price"];
  className?: string;
};

function isLocationObject(
  location: Activity["location"] | Program["location"]
): location is Activity["location"] {
  if (location !== undefined && typeof location === "object") {
    return ((location as Activity["location"]) || {}).name !== undefined;
  } else {
    return false;
  }
}

const DetailList = ({
  cardDetails = [],
  duration,
  minAge,
  minHeight,
  location,
  park,
  startingTime,
  price,
  minPersons,
}: DetailListProps) => {
  const DetailItems = useMemo(() => {
    return {
      Location:
        location instanceof Array &&
        Array.isArray(location) &&
        location.length > 0 ? (
          <ListItem key="location" className="location">
            <RiMapPinLine />
            <P>
              {location
                .map((loc) => `${loc.park?.name || ""} ${loc.name || ""}`)
                .join("; ")}
            </P>
          </ListItem>
        ) : location && isLocationObject(location) ? (
          <ListItem key="location" className="location">
            <RiMapPinLine />
            <P>
              {location.park?.name}, {location.name}
            </P>
          </ListItem>
        ) : null,
      MinAge:
        minAge !== null ? (
          <ListItem key="minAge" className="minAge">
            <RiCake2Line />
            <P>
              <Translate id="activity.min.age" values={{ minAge }} />
            </P>
          </ListItem>
        ) : null,
      MinHeight:
        minHeight !== null ? (
          <ListItem key="minHeight" className="minHeight">
            <GiBodyHeight />
            <P>
              <Translate id="activity.min.height" values={{ minHeight }} />
            </P>
          </ListItem>
        ) : null,
      StartingTime:
        startingTime !== null ? (
          <ListItem key="startingTime" className="startingTime">
            <HiOutlineClock />
            <P>{startingTime}</P>
          </ListItem>
        ) : null,
      Duration:
        duration !== null ? (
          <ListItem key="duration" className="duration">
            <FiCalendar />
            <P>
              {isNaN(duration as number) ? (
                duration
              ) : (
                <Translate
                  id="activity.duration"
                  values={{ count: duration }}
                />
              )}
            </P>
          </ListItem>
        ) : null,
      Price:
        price !== null ? (
          <ListItem key="price" className="price">
            <FiShoppingBag />
            <P>{price}</P>
          </ListItem>
        ) : null,
      MinPersons:
        minPersons !== null ? (
          <ListItem key="price" className="price">
            <FiShoppingBag />
            <P>
              <Translate
                id="activity.min.persons"
                values={{ count: minPersons }}
              />
            </P>
          </ListItem>
        ) : null,
    };
  }, [
    park,
    location,
    minAge,
    minHeight,
    startingTime,
    duration,
    price,
    minPersons,
  ]);

  return cardDetails?.length ? (
    <List>{cardDetails.map((detail) => DetailItems[detail])}</List>
  ) : null;
};

export default DetailList;
