import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Collapse } from 'reactstrap'
import RefineFiltersProperty from './RefineFiltersProperty'
import { isMobile } from '../../../../utilities/deviceUtilities'
import { getUserAgent } from '../../../../selectors/environmentSelectors'

class RefineFiltersPropertyHolder extends Component {
  state = {
    collapsed: true
  }

  toggleSubmenu = () => this.setState(prevState => ({
    collapsed: !prevState.collapsed
  }))

  render = () => {
    const { isMobileAgent, filterableProperties, propertyGroup, index, toggleCheckbox, isMobile } = this.props

    return (
      <div key={propertyGroup.id} className='card col-lg-3 col-md-4 mb-3'>
        <h3 className='h6 mb-3 f-l-0' role='tab' id={`#heading-${index}`}>
          <a
            type='button'
            data-toggle='collapse'
            data-parent='#accordion-refine'
            aria-expanded='false'
            aria-controls={`collapse-${index}`}
            className={`${(isMobileAgent || isMobile) && this.state.collapsed ? 'collapsed' : ''}`}
            style={{ width: '100%', display: 'flex' }}
            onClick={this.toggleSubmenu}
          >
            {propertyGroup.name}
          </a>
        </h3>
        <Collapse isOpen={isMobileAgent || isMobile ? !this.state.collapsed : true}>
          <div className='inner'>
            {propertyGroup.properties.filter(propertyId => filterableProperties.includes(propertyId)).map(propertyId => (
              <RefineFiltersProperty
                key={propertyId}
                propertyId={propertyId}
                toggleCheckbox={toggleCheckbox}
              />
            ))}
          </div>
        </Collapse>
      </div>
    )
  }
}

RefineFiltersPropertyHolder.propTypes = {
  filterableProperties: PropTypes.array,
  isMobileAgent: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  propertyGroup: PropTypes.object,
  index: PropTypes.number,
  toggleCheckbox: PropTypes.func
}

const mapState = state => ({
  isMobileAgent: isMobile(getUserAgent(state))
})

export default connect(mapState)(RefineFiltersPropertyHolder)
