import { captureException } from "@sentry/nextjs";
import axios from "axios";

export const postSubscriptions = async (data) => {
  try {
    const response = await axios.post("/api/newsletter", data);
    return response.data;
  } catch (error) {
    captureException(error);
    throw error;
  }
};
