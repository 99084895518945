import { fetchWithGraphQL } from "./api";
import { ApiError } from "../../../errors";

// Types
import Page from "../outputTypes/Page";

// GraphQl
import fetchPageContentQuery from "./queries/fetchPageContent";
import fetchPageStructureQuery from "./queries/fetchPageStructure";

// Utils
import normalizePage from "./normalizers/page";
import normalizeTopic from "./normalizers/topic";
import normalizeCanonicalUrls from "./normalizers/canonicalUrls";
import normalizeSections from "./normalizers/sections";

/**
 * First, we have to get the structure of the page. So that we know which GraphQl section queries
 * we must include in the subsequent call. Otherwise, the initial call consists of queries
 * for every section. This causes a "query body size limit exceeded" exception. Subsequently,
 * we fetch the data only containing the subqueries for the sections available on the requested page.
 */
const fetchPageStructure = async (slug, locale, preview) => {
  const query = fetchPageStructureQuery();
  const { data } = await fetchWithGraphQL({
    query,
    variables: { slug, locale, skipSections: 0, preview },
    preview,
  });

  // Redirect found on contentful
  if ((data.redirects.items?.length ?? 0) !== 0) {
    const redirectResponse = data.redirects.items[0];
    const redirect = {
      from: redirectResponse.from,
      to: redirectResponse.to,
    };
    throw new ApiError("Redirect", redirect, 301);
  }

  // Page not found on contentful
  if ((data.page?.items?.length ?? 0) === 0) {
    throw new ApiError("Page not found", {}, 404);
  }

  return {
    canonicalUrls: data.canonicalUrls.items,
    topicName: data.page.items[0].topic?.type,
    sectionKeys: [
      ...new Set(
        data.page.items[0].sections?.items.map((section) => section.type)
      ),
    ],
  };
};

/**
 * Build a dynamic page query with the following topic/sections. This
 * will fetch the actual page data.
 */
const fetchPageContent = async ({
  slug,
  locale,
  topicName,
  sectionKeys,
  variables,
  preview,
}) => {
  const query = fetchPageContentQuery({ topicName, sectionKeys });
  const { data } = await fetchWithGraphQL({
    query,
    variables: { slug, locale, skipSections: 0, preview, ...variables },
    preview,
  });

  return data;
};

const getPaginationParams = (queryParams) => {
  const pageSize = 25;
  const pageNumber = Number(queryParams?.page || 1);
  const skip = (pageNumber - 1) * pageSize;
  const limit = pageSize;

  return {
    skip,
    limit,
  };
};

const fetchPage = async (
  slug: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams: any,
  locale: string,
  locales: string[],
  preview = false
): Promise<Page> => {
  try {
    delete queryParams.slug;

    const { topicName, sectionKeys, canonicalUrls } = await fetchPageStructure(
      slug,
      locale,
      preview
    );
    const data = await fetchPageContent({
      slug,
      locale,
      topicName,
      sectionKeys,
      variables: getPaginationParams(queryParams),
      preview,
    });

    const pageData = {
      ...data.page.items[0],
      sections: data.page.items[0].sections.items,
      canonicalUrls,
    };

    const normalizedSections = await normalizeSections(
      pageData.sections,
      pageData
    );

    const page: Page = {
      ...normalizePage(pageData),
      sections: normalizedSections,
      canonicalUrls: normalizeCanonicalUrls(pageData.canonicalUrls, locales),
      topic: normalizeTopic(pageData.topic),
    };

    return page;
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    }

    const { message } = error;
    const statusCode = error.networkError?.statusCode || -1;
    const details = {
      network: error?.networkError?.result || "",
      graphql: error?.graphQLErrors || "",
    };

    // TODO: Fix sentry
    // Just show it in vercel because sentry is not picking it
    // up right now
    console.error(JSON.stringify(details, null, 4));
    console.error(error);

    throw new ApiError(message, details, statusCode);
  }
};

export default fetchPage;
