import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import trans from "counterpart";
import moment from "moment";
import { CarouselItem } from "reactstrap";
import { createMoreInfoUrl } from "../../actions";
import {
  hasAdults,
  getPreference,
} from "../../../../selectors/preferenceSelectors";
import { getBookingUrl } from "../../../../utilities/preferenceUtilities";
import Carousel from "../../../../components/Carousel";
import CarouselPlaceholder from "./CarouselPlaceholder";
import Popover from "./Popover";
import PriceTagPanic from "./PriceTagPanic";
import PriceTagAvailable from "./PriceTagAvailable";
import AddToCompareResources from "../../../compare/components/AddToCompareResources";
import PriceTagUnavailable from "./PriceTagUnavailable";
import Image from "../../../media/components/Image";
import {
  PREFERENCE_ARRIVAL,
  PREFERENCE_DEPARTURE,
  PREFERENCE_SPECIAL,
  PREFERENCE_SUBJECTS,
  PREFERENCE_VOUCHER,
} from "../../../../constants/preferences";
import { DISPLAY_DATE } from "../../../../constants/dates";
import { selectedPropertiesWithPreferenceCost } from "../../../reservation/selectors";
import { APP_BEEKSEBERGEN } from "../../../../constants/apps";
import Link from "../../../../components/typography/Link";
import BookButton from "./BookButton";
import {
  ID_HOLIDAYPARK,
  ID_SAFARIRESORT,
  ID_SAFARIRESORT_HOTEL,
} from "../../constants";
import { ResortLabel } from "@libema/design-system";

const classnames = (parts) => parts.filter((p) => !!p).join(" ");
const isIE11 =
  typeof navigator !== "undefined"
    ? navigator.appVersion.indexOf("Trident/") > -1 ||
      navigator.userAgent.indexOf("Edge") > -1
    : false;

class AccommodationResult extends Component {
  getVisibleProperties = () =>
    this.props.accommodationType.properties.filter((p) => p.visible_on_index);

  getMoreInfoRoute = () => {
    const {
      result,
      accommodationType: { slug },
      subjectPreference,
      arrivalPreference,
      departurePreference,
      voucher,
      special,
    } = this.props;

    const moreInfoUrlObject = {
      arrival: arrivalPreference,
      subjects: Object.keys(subjectPreference || {}).map(
        (subject) => `${subject}:${subjectPreference[subject]}`
      ),
    };

    if (departurePreference) {
      moreInfoUrlObject.departure = departurePreference;
    }

    if (voucher) {
      moreInfoUrlObject.voucher_code = voucher;
      if (special) moreInfoUrlObject.special = special;
    } else if (result.special_code) {
      moreInfoUrlObject.special = result.special_code;
    }

    return `/${slug}?${createMoreInfoUrl(moreInfoUrlObject)}`;
  };

  getBookingRoute = () => {
    const { result, subjectPreference, selectedPreferences, voucher, special } =
      this.props;

    const additionalParams = {};
    if (voucher) {
      additionalParams.voucher_code = voucher;
      if (special) additionalParams.special = special;
    }

    return getBookingUrl(
      result,
      subjectPreference,
      selectedPreferences,
      additionalParams
    );
  };

  formatName = (name) => name.toLowerCase().split(" ").join("-");

  isAvailable = () => {
    const { isUnavailable, result } = this.props;
    return result.arrival_date && result.quantity > 0 && isUnavailable !== true;
  };

  render() {
    const {
      hasAdults,
      result,
      accommodationType,
      unavailableMessage,
      display,
      panic,
    } = this.props;
    if (!accommodationType) return null;

    const isSafariResort = ID_SAFARIRESORT === accommodationType.resort.id;
    const isLakeResort = ID_HOLIDAYPARK === accommodationType.resort.id;
    const isSafariHotel = ID_SAFARIRESORT_HOTEL === accommodationType.resort.id;

    return (
      <div className="result accommodation-result" id={`result-${result.id}`}>
        <div className="result-inner">
          <div
            className={classnames([
              "row",
              !result.arrival_date && !panic && "row-disabled",
            ])}
          >
            <div className="result-left">
              <div
                id={`carousel${result.id}`}
                className="carousel slide"
                data-ride="carousel"
                data-interval="false"
              >
                {accommodationType.label === 1 && <div className="label-new" />}
                {accommodationType.label === 2 && (
                  <div className="label-restyled" />
                )}
                {accommodationType.images &&
                  accommodationType.images.length > 0 && (
                    <Carousel>
                      {accommodationType.images.map((image) => (
                        <CarouselItem key={image.id}>
                          <Image
                            file={image.reference}
                            filter="thumbnail_square"
                            attr={{
                              className: "img-fluid",
                              alt: image.alt,
                            }}
                          />
                        </CarouselItem>
                      ))}
                    </Carousel>
                  )}

                {(!accommodationType.images ||
                  accommodationType.images.length === 0) && (
                  <CarouselPlaceholder />
                )}

                {/* Resort highlight */}
                {isSafariResort && (
                  <ResortLabel
                    label={trans("resort.safari_resort")}
                    variant="safariResort"
                  />
                )}
                {isLakeResort && (
                  <ResortLabel
                    label={trans("resort.lake_resort")}
                    variant="lakeResort"
                  />
                )}
                {isSafariHotel && (
                  <ResortLabel
                    label={trans("resort.safari_hotel")}
                    variant="safariHotel"
                  />
                )}
              </div>
            </div>

            <div className="result-right">
              <div className="row">
                <div className="details">
                  <div className="details-inner">
                    <h2>
                      <Link to={`/${accommodationType.slug}`}>
                        {accommodationType.display_name ||
                          accommodationType.name}
                      </Link>
                    </h2>
                    {display !== "grid" && (
                      <div
                        className="extra"
                        dangerouslySetInnerHTML={{
                          __html: accommodationType.all_in_description,
                        }}
                      />
                    )}

                    <div className="date-holder">
                      {result.arrival_date && (
                        <div className="date-none">
                          <span className="d-block">
                            {moment(result.arrival_date).format(DISPLAY_DATE)}
                          </span>
                          <span>{`${result.nights} ${
                            result.nights > 1
                              ? trans("stay.nights")
                              : trans("stay.night")
                          }, ${result.nights + 1} ${trans("stay.days")}`}</span>
                        </div>
                      )}
                    </div>
                    {this.getVisibleProperties().length > 0 && (
                      <ul className="properties list-unstyled clearfix">
                        {this.getVisibleProperties().map((property) => (
                          <li
                            key={property.id}
                            className={`property property-${property.property.id}`}
                          >
                            {property.property.icon && (
                              <i className="material-icons">
                                {property.property.icon}
                              </i>
                            )}
                            {`${property.value ? property.value : ""} ${
                              property.property.display_name ||
                              property.property.name
                            }`}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>

                {display === "grid" && (
                  <>
                    <div className="pricing">
                      <div className="clearfix">
                        <div className="date-holder">
                          {result.arrival_date && (
                            <div className="date-none">
                              <span className="d-block">
                                {moment(result.arrival_date).format(
                                  DISPLAY_DATE
                                )}
                              </span>
                              <span>{`${result.nights} ${
                                result.nights > 1
                                  ? trans("stay.nights")
                                  : trans("stay.night")
                              }, ${result.nights + 1} ${trans(
                                "stay.days"
                              )}`}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="extra"
                      dangerouslySetInnerHTML={{
                        __html: accommodationType.all_in_description,
                      }}
                    />
                  </>
                )}
                {display !== "grid" && (
                  <div className="pricing-list">
                    <div className="clearfix">
                      <div className="top-holder">
                        <div className="date-holder">
                          {result.arrival_date && (
                            <div className="date-none">
                              <span className="d-block">
                                {moment(result.arrival_date).format(
                                  DISPLAY_DATE
                                )}
                              </span>
                              <span>{`${result.nights} ${
                                result.nights > 1
                                  ? trans("stay.nights")
                                  : trans("stay.night")
                              }, ${result.nights + 1} ${trans(
                                "stay.days"
                              )}`}</span>
                            </div>
                          )}
                        </div>
                        {result.quantity <= 3 && (
                          <span className="availability d-block">
                            {trans("newyse.accommodation.almost_soldout")}
                          </span>
                        )}
                      </div>
                      <div className="bottom-holder">
                        <div className="details-included small p-2">
                          <Popover
                            id={accommodationType.id}
                            description={trans("stay.including")}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  accommodationType.all_in_device_description,
                              }}
                            />
                          </Popover>
                        </div>
                        <div className="price-holder">
                          {result.arrival_date && (
                            <div className="price-tag compact">
                              <span className="price">{`${result.all_in_price},-`}</span>
                              {result.all_in_price < result.from_price && (
                                <s>{`${result.from_price},-`}</s>
                              )}
                              {result.special_name && (
                                <p
                                  className="text-discount"
                                  style={{ wordBreak: "break-word" }}
                                >
                                  {result.special_name}
                                </p>
                              )}
                            </div>
                          )}
                          {!result.arrival_date && (
                            <div className="price-tag compact">
                              <span>
                                {trans("newyse.accommodation.unavailable")}
                                <a
                                  // href={getMoreInfoRoute(result)}
                                  style={{ textDecoration: "underline" }}
                                  dangerouslySetInnerHTML={{
                                    __html: trans("newyse.accommodation.check"),
                                  }}
                                />
                                {` ${trans(
                                  "newyse.accommodation.unavailable2"
                                )}`}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {display === "grid" && (
                <div className="price-holder">
                  <div className="details-included small p-2">
                    <Popover
                      id={accommodationType.id}
                      description={trans("stay.including")}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: accommodationType.all_in_device_description,
                        }}
                      />
                    </Popover>
                  </div>
                  {this.isAvailable() && <PriceTagAvailable {...result} />}
                  {!this.isAvailable() && !panic && (
                    <PriceTagUnavailable
                      message={unavailableMessage}
                      moreInfoLink={this.getMoreInfoRoute()}
                    />
                  )}
                  {panic && <PriceTagPanic {...result} />}
                </div>
              )}
              <div className="row btn-row">
                {display !== "grid" && (
                  <div className="col-md-4">
                    <AddToCompareResources id={result.resource_id} />
                  </div>
                )}
                <div
                  className={
                    display === "grid" ? "col-md-12" : "col-md-8 text-md-right"
                  }
                >
                  <Link
                    to={this.getMoreInfoRoute()}
                    className="btn btn-acco btn-transparent-ghost"
                  >
                    {trans("button.more_info")}
                  </Link>
                  <BookButton
                    arrivalDate={result.arrival_date}
                    disabled={!this.isAvailable() || !hasAdults}
                    panic={panic}
                    to={this.getBookingRoute()}
                    toFallback={trans("routes.booking_url_fallback")}
                    className="ml-2"
                    accommodationKindId={result.kind_id}
                  />
                  {display === "grid" && (
                    <div>
                      <AddToCompareResources id={result.resource_id} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccommodationResult.propTypes = {
  accommodationType: PropTypes.object,
  arrivalPreference: PropTypes.string,
  departurePreference: PropTypes.string,
  display: PropTypes.string,
  hasAdults: PropTypes.bool,
  panic: PropTypes.bool,
  // Determines if it's allowed to book this accommodation.
  // E.g. this is false in a pre-event fase.
  isBookable: PropTypes.bool,
  // Determines if an accommodation is available.
  // During event sales the reservations go faster than the sync can handle so it's
  // required to manually mark an accommodation as unavailable without affecting the sync.
  isUnavailable: PropTypes.bool,
  result: PropTypes.object,
  selectedPreferences: PropTypes.array,
  subjectPreference: PropTypes.object,
  unavailableMessage: PropTypes.string,
  voucher: PropTypes.string,
  special: PropTypes.string,
};

const mapStateToProps = (state) => ({
  display: state.preferences.display,
  arrivalPreference: getPreference(PREFERENCE_ARRIVAL)(state),
  subjectPreference: getPreference(PREFERENCE_SUBJECTS)(state),
  departurePreference: getPreference(PREFERENCE_DEPARTURE)(state),
  selectedPreferences: selectedPropertiesWithPreferenceCost(state),
  hasAdults: hasAdults(state),
  voucher: getPreference(PREFERENCE_VOUCHER)(state),
  special: getPreference(PREFERENCE_SPECIAL)(state),
});

export default connect(mapStateToProps)(AccommodationResult);
