// Device sizes based on Bootstrap (see https://getbootstrap.com/docs/4.0/layout/grid/#variables)
export const DEVICE_XS = 0;
export const DEVICE_SM = 576;
export const DEVICE_MD = 768;
export const DEVICE_LG = 992;
export const DEVICE_XL = 1200;

export const DEVICE_XS_UP = `(min-width: ${DEVICE_XS}px)`;
export const DEVICE_SM_UP = `(min-width: ${DEVICE_SM}px)`;
export const DEVICE_SM_DOWN = `(max-width: ${DEVICE_SM - 1}px)`;
export const DEVICE_MD_UP = `(min-width: ${DEVICE_MD}px)`;
export const DEVICE_MD_DOWN = `(max-width: ${DEVICE_MD - 1}px)`;
export const DEVICE_LG_UP = `(min-width: ${DEVICE_LG}px)`;
export const DEVICE_LG_DOWN = `(max-width: ${DEVICE_LG - 1}px)`;
export const DEVICE_XL_UP = `(min-width: ${DEVICE_XL}px)`;
export const DEVICE_XL_DOWN = `(max-width: ${DEVICE_XL - 1}px)`;

export const isDesktop = (): boolean => window.innerWidth >= DEVICE_XL;

export const isTouchDevice = (): boolean => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};
