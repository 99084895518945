import React, { } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import HelpText from '../../../components/HelpText'
import { getAddress } from '../actions'
import { groupValidateClasses, inputValidateClasses } from '../../form/utilities'

class SelectField extends React.Component {
  // Define the context types to make sure the component receives them
  static contextTypes = {
    _reduxForm: PropTypes.object
  };

  static propTypes = {
    helpText: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    name: PropTypes.string,
    // Options can be passed as an key,value object,
    // or passed as an array with objects that have a key and a value property
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    placeholder: PropTypes.string,
    sortOptions: PropTypes.func
  };

  /**
   * Transform the options to an array with objects that have both a key and a value property
   */
  getOptions = () => {
    const { options } = this.props

    if (Array.isArray(options) && options.length && options[0] && options[0].key) {
      return options
    }

    return Object.keys(options).map(key => ({
      key,
      value: options[key]
    }))
  }

  render = () => {
    const { input, name, placeholder, helpText, meta: { touched, error } } = this.props

    return (
      <div className={groupValidateClasses(touched, error)}>
        <div className='col-12'>
          <select
            id={name}
            name={name}
            {...input}
            className={`object-select ${inputValidateClasses(touched, error)}`}
            onChange={(event) => {
              input.onChange(event)
              if (input.name === 'country') {
                this.props.getAddress({ country: event.target.value })
              }
            }}
          >
            {placeholder && <option value=''>{placeholder}</option>}
            {this.getOptions().map(opt => <option value={opt.key} key={opt.key}>{opt.value}</option>)}
          </select>
          {touched && error && <div className='form-control-feedback'>{error}</div>}
          <HelpText value={helpText} />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  triggerInitialChange: (form, field, value) => dispatch(change(form, field, value)),
  getAddress: data => dispatch(getAddress(data))
})

SelectField.propTypes = {
  triggerInitialChange: PropTypes.func,
  getAddress: PropTypes.func
}

export default connect(null, mapDispatchToProps)(SelectField)
