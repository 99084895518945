import { useState, useEffect } from "react";
import Image from "next/image";

const SafeWebpImage = (props) => {
  const { src, ...rest } = props;
  const { placeholder, loader } = rest;

  const [imgSrc, setImgSrc] = useState(src);
  const fallbackSrc = src.replace("fm=webp", "fm=jpg") || "";
  const blurUrl =
    (placeholder === "blur" &&
      typeof src === "string" &&
      loader &&
      loader({ src, width: 100, quality: 10 })) ||
    undefined;

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <Image
      {...rest}
      src={imgSrc}
      onError={() => {
        setImgSrc(fallbackSrc);
      }}
      blurDataURL={blurUrl}
    />
  );
};

export default SafeWebpImage;
