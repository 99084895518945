import styled from "styled-components";
import moment from "moment";
import { useRouter } from "next/router";

// Icons
import { MdTimelapse } from "react-icons/md";
import { RiCake2Line, RiMapPinLine } from "react-icons/ri";
import { HiOutlineClock } from "react-icons/hi";
import { FiUsers, FiCalendar } from "react-icons/fi";
import { GiBodyHeight } from "react-icons/gi";

// Components
import {
  Row,
  Col,
  UnorderedList,
  Section,
  DEVICE_LG_UP,
  Translate,
  H2,
  H3,
  P,
} from "@libema/design-system";

// Local Components
import RichTextRenderer from "../../renderer/RichTextRenderer";
import RichText from "../RichText";
import { BookingLink, Pricing } from "../DetailsCard";
import { StyledPricing } from "../DetailsCard/style";
import t from "../../../hooks/useMessage";

const List = styled(UnorderedList)`
  list-style: none;
  padding-left: 0;
  font-size: 1rem;
  line-height: 1rem;
`;

const ListItem = styled.li`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.sm};

  svg:first-of-type {
    margin-right: ${({ theme }) => theme.padding.md};
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const StyledSection = styled(Section.Wrapper)`
  ${RichText} {
    padding-right: 1.25rem;

    ${H2} {
      margin-bottom: 1.25rem;
    }

    ${H3} {
      color: black;
    }

    ${P} {
      text-align: justify;
      color: black;
    }
  }

  ${ListItem} {
    margin-bottom: 1rem;
  }

  ${StyledPricing} {
    align-items: flex-start;
    margin-top: 1rem;
    padding: 0;

    @media ${DEVICE_LG_UP} {
      margin-top: 0;

      label {
        text-align: left;
      }
    }
  }
`;

const DetailsColumn = styled(Col).attrs({
  md: 6,
})`
  padding-top: 0.5rem;
`;

/**
 * Map dutch weekdays to week day index
 */
const WEEKDAYS_INDEX = {
  zondag: 0,
  maandag: 1,
  dinsdag: 2,
  woensdag: 3,
  donderdag: 4,
  vrijdag: 5,
  zaterdag: 6,
};

const formatWeekDays = (
  weekdays: string[],
  dayFormat = "dd"
): string | null => {
  if (!weekdays) {
    return null;
  }
  if (weekdays.length === 7) {
    return t("activity.open_all_week");
  }

  const weekdaysFormatted = weekdays
    .map((day) => {
      const dayIndex = WEEKDAYS_INDEX[day.toLowerCase()];
      const dayOfWeekShort = moment().day(dayIndex).format(dayFormat);
      return dayOfWeekShort;
    })
    .join(", ");

  return t("activity.open", { weekdays: weekdaysFormatted });
};

const ActivityDetail = ({ activity, backgroundColor, dayFormat }) => {
  const { locale = "nl" } = useRouter();

  const {
    cta,
    description,
    minPersons,
    duration,
    minAge,
    minHeight,
    lowestPrice,
    lowestPriceRegular,
    priceUnit,
    location,
    weekdays,
    startingTime,
    priceTable,
  } = activity;

  const pricingProps = {
    lowestPrice,
    lowestPriceRegular,
    priceUnit,
    priceTable,
  };

  const openingsDays = formatWeekDays(weekdays, dayFormat);

  return (
    <StyledSection backgroundColor={backgroundColor}>
      <Section.Container>
        <Row>
          <Col md={6}>
            <RichText backgroundColor={backgroundColor}>
              <RichTextRenderer text={description} />
            </RichText>
          </Col>
          <DetailsColumn>
            <Row>
              <Col lg={6}>
                <List>
                  {location && (
                    <ListItem>
                      <RiMapPinLine />
                      {[location.park.name, location.name].join(", ")}
                    </ListItem>
                  )}
                  {minAge != null && (
                    <ListItem>
                      <RiCake2Line />
                      <Translate id="activity.min.age" values={{ minAge }} />
                    </ListItem>
                  )}
                  {startingTime != null && (
                    <ListItem>
                      <HiOutlineClock />
                      {startingTime}
                    </ListItem>
                  )}
                  {minPersons != null && (
                    <ListItem>
                      <FiUsers />
                      <Translate
                        id="activity.min.persons"
                        values={{ count: minPersons }}
                      />
                    </ListItem>
                  )}
                  {minHeight != null && (
                    <ListItem>
                      <GiBodyHeight />
                      <Translate
                        id="activity.min.height"
                        values={{ minHeight }}
                      />
                    </ListItem>
                  )}
                  {duration != null && (
                    <ListItem>
                      <MdTimelapse />
                      <Translate
                        id="activity.duration"
                        values={{ count: duration }}
                      />
                    </ListItem>
                  )}
                  {weekdays?.length && (
                    <ListItem>
                      <FiCalendar />
                      {openingsDays}
                    </ListItem>
                  )}
                </List>
              </Col>
              <Col lg={6}>
                <Pricing {...pricingProps} />
                {cta !== null && (
                  <BookingLink
                    link={cta}
                    locale={locale}
                    buttonProps={{
                      colorType: "primary",
                      variant: "contained",
                      size: "small",
                    }}
                  />
                )}
              </Col>
            </Row>
          </DetailsColumn>
        </Row>
      </Section.Container>
    </StyledSection>
  );
};

export default ActivityDetail;
