import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import trans from 'counterpart'
import analyticsUtils from '../../../utilities/analyticsUtils'
import reservationSelectors from '../../../selectors/reservationSelectors'

class PaymentForm extends Component {
  componentDidMount = () => {
    analyticsUtils.handleReservationPaymentView()
  }

  handleSubmit = () => {
    this.element.children[this.props.name].submit()
  }

  render = () => {
    const { form, name, totalPrice } = this.props

    if (!form) return null

    const price = (name === 'ogoneWhole') ? totalPrice : totalPrice / 2

    return (
      <div className='mb-2'>
        <button
          type='button'
          onClick={this.handleSubmit}
          className={`btn btn-lg btn-block font-weight-bold${(name === 'ogoneWhole') ? ' btn-primary-color' : ' btn-outline-black'}`}
        >
          {`${trans('pricing.payment.label')} € ${price.toFixed(2)} ${trans('pricing.payment.pay')}`}
        </button>
        <div ref={(el) => { this.element = el }} dangerouslySetInnerHTML={{ __html: form }} />
      </div>
    )
  }
}

PaymentForm.propTypes = {
  form: PropTypes.string,
  name: PropTypes.string,
  totalPrice: PropTypes.number
}

const mapStateToProps = (state, ownProps) => ({
  form: state.reservation.payment_forms[ownProps.name],
  totalPrice: reservationSelectors.getTotalPrice(state)
})

export default connect(
  mapStateToProps
)(PaymentForm)
