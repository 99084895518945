import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PaymentHeader from './PaymentHeader'

const HeaderContent = styled('div')`
  text-align: center;
  h2 {
    color: white;
  }
  p {
    color: white;
    font-size: 2rem;
  }
`

function PaymentHeaderBlock ({ properties }) {
  const { header_text } = properties
  return (
    <PaymentHeader>
      <HeaderContent className='paymentheader' dangerouslySetInnerHTML={{ __html: header_text }} />
    </PaymentHeader>
  )
}

PaymentHeaderBlock.propTypes = {
  header_text: PropTypes.string
}

export default PaymentHeaderBlock
