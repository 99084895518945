import { FC } from "react";
import styled from "styled-components";
import { Box, Stack, Typography } from "@mui/material";
import Image from "next/image";

type Props = {
  variant: "safariResort" | "lakeResort" | "safariHotel";
  label: string;
};

const ResortLabelStyled = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 48px;
  .MuiTypography-root {
    line-height: 0.9375rem;
    margin-top: 2px;
    flex: 1.4;
  }
  // icon wrapper
  > div > span {
    min-width: 30px;
    flex: 1;
  }
`;

const variants = {
  safariResort: {
    bgColor: "#e15a5e",
    icon: "/images/icon-safari-resort.svg",
  },
  lakeResort: {
    bgColor: "#ef8257",
    icon: "/images/icon-lake-resort.svg",
  },
  safariHotel: {
    bgColor: "#8b966e",
    icon: "/images/icon-safari-hotel.svg",
  },
};

const ResortLabel: FC<Props> = ({ label, variant }) => {
  return (
    <ResortLabelStyled bgcolor={variants[variant].bgColor} p={1}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Image
          src={variants[variant].icon}
          layout="fixed"
          width="30"
          height="30"
        />
        <Typography
          variant="overline"
          component="div"
          color="brand.contrastText"
        >
          {label}
        </Typography>
      </Stack>
    </ResortLabelStyled>
  );
};

export default ResortLabel;
