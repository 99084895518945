import styled from "styled-components/macro";
import { darken } from "polished";

export default styled.div`
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  border-bottom: 2px solid
    ${({ theme }) => darken(0.1, theme.color.neutralLight)};
  margin-bottom: 1rem;
`;
