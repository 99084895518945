import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import qs from "qs";

const DEBOUNCE_TIME = 700;
const createURL = (state): string => `?${qs.stringify(state)}`;

const searchStateToUrl = (searchState): string =>
  searchState ? `${window.location.pathname}${createURL(searchState)}` : "";

const urlToSearchState = (asPath: string): Record<string, string> => {
  const queryIndex: number = asPath.lastIndexOf("?");
  if (queryIndex > -1)
    return qs.parse(asPath.slice(queryIndex + 1)) as Record<string, string>;
  return {};
};

const useUrlRouting = () => {
  const { asPath, push } = useRouter();

  const [searchState, setSearchState] = useState<Record<string, string>>({});
  const debouncedSetStateRef = useRef<any>(null);

  const onSearchStateChange = useCallback(
    (updatedSearchState) => {
      clearTimeout(debouncedSetStateRef.current);

      debouncedSetStateRef.current = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        push(searchStateToUrl(updatedSearchState), undefined, {
          shallow: true,
        });
      }, DEBOUNCE_TIME);

      setSearchState(updatedSearchState);
    },
    [debouncedSetStateRef, push]
  );

  useEffect(() => {
    setSearchState(urlToSearchState(asPath));
  }, [asPath]);

  return useMemo(
    () => [searchState, onSearchStateChange, createURL],
    [searchState, onSearchStateChange]
  );
};

export default useUrlRouting;
