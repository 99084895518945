import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import trans from 'counterpart'
import { addResourceToCompare, setCompareProperty } from '../actions'
import { MAX_COMPARE } from '../constants'

const AddToCompareResources = ({ addToCompare, compareListIds, setHasMaxCompare, setHasIdInList, id }) => {
  const addToCompareResources = () => {
    if (compareListIds.length === MAX_COMPARE) {
      setHasMaxCompare(true)
      setTimeout(() => {
        setHasMaxCompare(false)
      }, 3000)
      return
    }
    if (compareListIds.indexOf(id) > -1) {
      return setHasIdInList()
    }
    return addToCompare()
  }

  return (
    <button type='button' className='btn btn-link btn-compare hidden-sm-down' onClick={addToCompareResources}>
      {trans('label.compare_add')}
    </button>
  )
}

AddToCompareResources.propTypes = {
  addToCompare: PropTypes.func,
  compareListIds: PropTypes.array,
  id: PropTypes.number,
  setHasMaxCompare: PropTypes.func,
  setHasIdInList: PropTypes.func
}

const mapStateToProps = state => ({
  compareListIds: state.compare.compareListIds
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  addToCompare: () => dispatch(addResourceToCompare(ownProps.id)),
  setHasMaxCompare: bool => dispatch(setCompareProperty('hasMaxCompare', bool)),
  setHasIdInList: () => dispatch(setCompareProperty('hasIdInList', true))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddToCompareResources)
