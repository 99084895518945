import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Collection from '../Collection'
import { getRelatedContent } from '../../modules/content/actions'
import { updateEntity } from '../../actions/entityActions'

class RelatedCollectionBlock extends React.Component {
  componentDidMount = () => this.props.loadCollection();

  render () {
    const { collection, properties: { template } } = this.props
    return <Collection collection={collection} template={template} manageTags={this.props.manageTags} />
  }
}

RelatedCollectionBlock.propTypes = {
  collection: PropTypes.array,
  loadCollection: PropTypes.func.isRequired,
  properties: PropTypes.object,
  manageTags: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  collection: (state.entities.blocks[ownProps.id]) ? state.entities.blocks[ownProps.id].collection || [] : []
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadCollection: () => dispatch(getRelatedContent({
    attributes: ownProps.properties.related_attributes,
    content: ownProps.contentId,
    limit: ownProps.properties.limit,
    order_by: ownProps.properties.order_by || 'createdAt',
    direction: ownProps.properties.order_direction || 'asc'
  }, (response) => {
    // Update the current block on success
    dispatch(updateEntity('blocks', ownProps.id, {
      collection: response.results
    }))
  }))
})

export default connect(mapStateToProps, mapDispatchToProps)(RelatedCollectionBlock)
