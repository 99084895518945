import { FC, useState } from "react";
import styled, { css } from "styled-components";
import { useRouter } from "next/router";
import { useCookies } from "react-cookie";

// Icons
// TODO: check tree shaking for icon packs.
import { RiArrowDropDownLine } from "react-icons/ri";

// Components
import { DEVICE_LG_UP, Translate, NL, GB } from "@libema/design-system";

// Types
import { CanonicalUrl, Locale } from "@libema/content-sdk";

const Container = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 32px;
  margin-bottom: 8px;

  @media ${DEVICE_LG_UP} {
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & .flag {
    width: 25px;
  }

  & .arrow {
    height: 1.25rem;
  }
`;

const ListContainer = styled.div`
  position: absolute;
  right: 0;
  border-radius: 4px;
  background: white;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.22);
  padding: 12px 8px;
  z-index: 11;
`;

const List = styled.ul``;

const Option = styled.li<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 4px 16px;
  font-size: 1.1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.grey_600};

  &:hover {
    background: ${({ theme }) => theme.color.grey_100};
    color: ${({ theme }) => theme.color.grey_900};
  }

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.color.grey_100};
      color: ${({ theme }) => theme.color.secondary};
    `}

  & .flag {
    width: 25px;
    margin-right: 16px;
  }
`;

type Props = {
  locales: Locale[];
  canonicalUrls?: CanonicalUrl[];
};

const FLAGS = {
  en: <GB title="English" className="flag" />,
  nl: <NL title="Nederlands" className="flag" />,
};

/**
 * Get page url for language
 */
const getPage = ({ canonicalUrls, locale, defaultLocale }): string | null => {
  const canonicalUrl: CanonicalUrl = canonicalUrls?.find(
    (canUrl) => canUrl.locale === locale
  );

  if (!canonicalUrl) {
    return null;
  }

  const newUrl =
    locale === defaultLocale
      ? canonicalUrl?.url
      : `/${locale as string}${canonicalUrl?.url ?? "/"}`;

  return newUrl;
};

const LanguageSwitcher: FC<Props> = ({ locales, canonicalUrls }) => {
  const [cookie, setCookie] = useCookies(["NEXT_LOCALE"]);
  const router = useRouter();
  const { locale, defaultLocale } = router;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    locale || defaultLocale || "nl"
  );

  // Listeners
  const onToggleDropdown = () => setIsOpen(!isOpen);

  const onLanguageSelected = (locale) => async () => {
    setSelectedOption(locale);
    setIsOpen(false);

    const url = getPage({ canonicalUrls, locale, defaultLocale });
    if (!url) {
      return;
    }

    await router.push(url, url, { locale });

    if (cookie.NEXT_LOCALE !== locale) {
      setCookie("NEXT_LOCALE", locale, { path: "/" });
    }
  };

  return (
    <Container
      aria-haspopup="listbox"
      aria-expanded={isOpen ? "true" : "false"}
    >
      <Header onClick={onToggleDropdown}>
        {FLAGS[selectedOption]} <RiArrowDropDownLine className="arrow" />
      </Header>
      {isOpen && (
        <ListContainer>
          <List>
            {locales.map(({ code, name }) => {
              return (
                <Option
                  key={code}
                  onClick={onLanguageSelected(code)}
                  role="option"
                  active={locale === code}
                  aria-selected={locale === code ? "true" : "false"}
                >
                  {FLAGS[code]}
                  <Translate id={`language-switcher.${code}`} />
                </Option>
              );
            })}
          </List>
        </ListContainer>
      )}
    </Container>
  );
};

export default LanguageSwitcher;
