/**
 * Sets the selected id to the opposite of what it currently is. If toggle is passed, it is set to that value instead.
 * @param {*} entity
 * @param {*} id
 * @param {*} toggle
 */
export const toggleSelected = (entity, id, toggle) =>
  entity.map((entry) => {
    if (entry.id === id) {
      entry.selected = toggle === undefined ? !entry.selected : toggle;
    }
    return entry;
  });

export const deselectAll = (entity) =>
  entity.map((entry) => {
    entry.selected = false;
    return entry;
  });

/**
 * Variable names taken from api.service.ts. Revisit later.
 * @param {*} preferences
 * @param {*} state
 */
export const buildParams = (preferences) => {
  const params = {
    sort: preferences.sort,
    direction: preferences.direction,
  };

  if (preferences.alerts) params.alerts = 1;
  if (preferences.allowRefetch) params.allow_refetch = preferences.allowRefetch;
  if (preferences.alternativePeriods) params.alternative_periods = 1;
  if (preferences.alternatives) params.alternatives = 1;
  if (preferences.arrival) params.arrival = preferences.arrival;
  if (preferences.departure) params.departure = preferences.departure;
  if (preferences.fixed) params.fixed = 1;
  if (preferences.groupBy) params.group_by = preferences.groupBy;
  if (preferences.kinds && preferences.kinds.length > 0)
    params.kinds = preferences.kinds;
  if (preferences.limit) params.limit = preferences.limit;
  if (preferences.priceFrom) params.price_from = preferences.priceFrom;
  if (preferences.priceTo) params.price_to = preferences.priceTo;
  if (preferences.properties && preferences.properties.length > 0)
    params.properties = preferences.properties;
  if (preferences.range) params.range = preferences.range;
  if (preferences.rangeStart) params.range_start = preferences.rangeStart;
  if (preferences.resource) params.resource = preferences.resource;
  if (preferences.resorts) params.resorts = preferences.resorts;
  if (preferences.resources) params.resources = preferences.resources;
  if (preferences.secretdeal) params.secretdeal = preferences.secretdeal;
  if (preferences.show_with_open_specials)
    params.show_with_open_specials = preferences.show_with_open_specials;
  if (preferences.special) params.special = preferences.special;
  if (preferences.unavailable) params.unavailable = 1;
  if (preferences.voucher) params.voucher_code = preferences.voucher;
  if (preferences.subjects) {
    params.subjects = Object.keys(preferences.subjects)
      .filter((i) => preferences.subjects[i] > 0)
      .map((i) => `${i}-${preferences.subjects[i]}`);
  }

  // Avoid passing kinds to query if its already limited to a resource
  if (preferences.resource && preferences.kinds) delete params.kinds;

  return params;
};
