import styled from "styled-components/macro";

type PProps = {
  centered?: boolean;
};

export default styled.p<PProps>`
  text-align: ${({ centered }) => (centered ? "center" : "inherit")};
  margin-bottom: 1rem;
`;
