import * as BlockMap from '../components/BlockMap'
import abstractBlock from '../components/AbstractBlock'

class BlockRegistry {
  constructor () {
    this.elements = null
  }

  initialize () {
    if (this.elements !== null) return

    this.elements = {}

    Object.keys(BlockMap).forEach((name) => {
      this.elements[name] = abstractBlock(BlockMap[name]); // eslint-disable-line 
    })
  }

  has (key) {
    return key in this.elements
  }

  get (key) {
    return this.elements[key]
  }
}

export const blockregistry = new BlockRegistry()
