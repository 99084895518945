import React, { Component } from "react";
import trans from "counterpart";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import qs from "query-string";
import SubjectPicker from "./pickers/SubjectPicker";
import ArrivalDatePicker from "./pickers/ArrivalDatePicker";
import DepartureDatePicker from "./pickers/DepartureDatePicker";
import RefineFiltersPicker from "./pickers/RefineFiltersPicker";
import { fetchSubjects, fetchPropertyGroups } from "../actions";
import { updateQueryString } from "../../../actions/preferenceActions";
import {
  getPreference,
  getQueryString,
} from "../../../selectors/preferenceSelectors";
import Link from "../../../components/typography/Link";
import {
  PREFERENCE_ARRIVAL,
  PREFERENCE_DEPARTURE,
  PREFERENCE_SUBJECTS,
  PREFERENCE_KINDS,
} from "../../../constants/preferences";
import { APP_BEEKSEBERGEN } from "../../../constants/apps";
import { ACCOMMODATIONS_WITH_FLEXIBLE_BOOKING_DATES } from "../../../constants/filters";
import { isBeekseBergen } from "../../../utilities/common";

const changeoverDays =
  process.env.NEXT_PUBLIC_PARK_CHANGEOVER_DAYS?.split(",") || [];
const changeoverExceptionDates =
  process.env.NEXT_PUBLIC_PARK_CHANGEOVER_EXCEPTION_DATES?.split(",") || [];

class Filters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      showFlexibleBookingDays: false,
    };

    this.departurePicker = React.createRef();
    this.refineFilters = React.createRef();
  }

  componentDidMount = () => {
    const { initialize } = this.props;

    return initialize().then(() => this.setState({ isLoading: false }));
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.kindsPreference !== prevProps.kindsPreference) {
      this.setState({
        showFlexibleBookingDays: this.hasFlexibleBookingDates(),
      });
    }
  };

  hasFlexibleBookingDates = () => {
    const { kindsPreference } = this.props;
    const hasFlexibleChangeoverDays = kindsPreference.some((kind) =>
      ACCOMMODATIONS_WITH_FLEXIBLE_BOOKING_DATES.includes(kind)
    );

    return hasFlexibleChangeoverDays;
  };

  toggle = (type, bool) => {
    if (type === "openDeparture") {
      if (bool) {
        this.departurePicker.current.open();
      } else {
        this.departurePicker.current.close();
      }
    } else if (type === "openFilters") {
      if (bool) {
        this.refineFilters.current.open();
      } else {
        this.refineFilters.current.close();
      }
    }
  };

  handlePropertyChange = (property, value) => {
    if (property === "departure") this.departurePicker.current.close();
    if (["kinds", "resorts", "properties"].includes(property))
      this.refineFilters.current.open();

    let newValue = value;
    if (property === "arrival" || property === "departure")
      newValue = value.format("YYYY-MM-DD");
    this.props.updateQueryString(property, newValue);

    this.props.onChange(property, value);
  };

  getSearchAndBookRoute = () => {
    const { subjectPreference, arrivalPreference, departurePreference, focus } =
      this.props;
    let prefix = trans("routes.search_book");
    if (process.env.NEXT_PUBLIC_APP === "dierenbos") {
      prefix = trans("routes.search_book_dierenbos");
    }

    const params = {
      arrival: arrivalPreference,
      subjects: Object.keys(subjectPreference || {}).map(
        (subject) => `${subject}-${subjectPreference[subject]}`
      ),
    };

    if (departurePreference) {
      params.departure = departurePreference;
    }
    if (focus.kinds && focus.kinds.length) {
      params.kinds = focus.kinds;
    }

    return `${prefix}?${qs.stringify(params, { arrayFormat: "bracket" })}`;
  };

  handleArrivalChange = (date) => this.handlePropertyChange("arrival", date);

  handleFlexibleBookingDaysChange = (checked) => {
    this.setState({ showFlexibleBookingDays: checked });
  };

  handleDepartureChange = (date) =>
    this.handlePropertyChange("departure", date);

  handleSubjectsChange = (subjects) =>
    this.handlePropertyChange("subjects", subjects);

  render = () => {
    const {
      columnClass,
      filterableProperties,
      template,
      results,
      showAdvancedFilters,
      petsAllowed,
      showDatePickerLegend,
    } = this.props;
    const { showFlexibleBookingDays } = this.state;

    const showLegend =
      isBeekseBergen && this.hasFlexibleBookingDates() && showDatePickerLegend;

    return (
      <>
        <div className={`${columnClass} column-company`}>
          <SubjectPicker
            onChange={this.handleSubjectsChange}
            petsAllowed={petsAllowed}
          />
        </div>
        <div className={`${columnClass} column-arrival`}>
          <ArrivalDatePicker
            showLegend={showLegend}
            showInfoDialog={isBeekseBergen}
            changeoverDays={changeoverDays}
            changeoverExceptionDates={changeoverExceptionDates}
            showFlexibleBookingDays={showFlexibleBookingDays}
            onShowFlexibleBookingDaysChange={
              this.handleFlexibleBookingDaysChange
            }
            toggle={this.toggle}
            onChange={this.handleArrivalChange}
          />
        </div>
        <div className={`${columnClass} column-departure`}>
          <DepartureDatePicker
            ref={this.departurePicker}
            showInfoDialog={isBeekseBergen}
            toggle={showAdvancedFilters ? this.toggle : null}
            showFlexibleBookingDays={showFlexibleBookingDays}
            onShowFlexibleBookingDaysChange={
              this.handleFlexibleBookingDaysChange
            }
            showLegend={showLegend}
            changeoverDays={changeoverDays}
            changeoverExceptionDates={changeoverExceptionDates}
            onChange={this.handleDepartureChange}
            loading={this.state.isLoading}
          />
        </div>
        {template === "search_form" && results && !showAdvancedFilters && (
          <div className="col-md-3 p-2 column-button">
            <Link
              to={this.getSearchAndBookRoute()}
              className="btn btn-primary-color btn-block"
            >
              <span className="show-view d-block text-center">
                {trans("refine.view")}
              </span>
              <span className="amount">
                {`${results.filter((r) => r.arrival_date).length} `}
                {trans("label.holiday")}
                {results.length > 1 && <span>s</span>}
              </span>
            </Link>
          </div>
        )}
        {showAdvancedFilters && (
          <div className="col-md-3 px-0">
            <RefineFiltersPicker
              ref={this.refineFilters}
              filterableProperties={filterableProperties}
              onChange={this.handlePropertyChange}
            />
          </div>
        )}
      </>
    );
  };
}

Filters.defaultProps = {
  columnClass: "col-md-3 px-0",
  onChange: () => {},
  petsAllowed: true,
  showDatePickerLegend: false,
};

Filters.propTypes = {
  arrivalPreference: PropTypes.string,
  departurePreference: PropTypes.string,
  columnClass: PropTypes.string,
  filterableProperties: PropTypes.array,
  focus: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  focusPriority: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  initialize: PropTypes.func,
  template: PropTypes.string,
  results: PropTypes.array,
  onChange: PropTypes.func,
  showAdvancedFilters: PropTypes.bool,
  subjectPreference: PropTypes.object,
  params: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // eslint-disable-line react/no-unused-prop-types
  petsAllowed: PropTypes.bool,
  updateQueryString: PropTypes.func.isRequired,
  showDatePickerLegend: PropTypes.bool,
};

const mapState = (state, ownProps) => ({
  kindsPreference: getPreference(PREFERENCE_KINDS)(state),
  arrivalPreference: getPreference(PREFERENCE_ARRIVAL)(state),
  subjectPreference: getPreference(PREFERENCE_SUBJECTS)(state),
  departurePreference: getPreference(PREFERENCE_DEPARTURE)(state),
  params: getQueryString(state, ownProps.focus, ownProps.focusPriority),
});

const mapDispatch = (dispatch) => ({
  initialize: () =>
    Promise.all([dispatch(fetchSubjects()), dispatch(fetchPropertyGroups())]),
  updateQueryString: (params) => dispatch(updateQueryString(params)),
});

export default connect(mapState, mapDispatch)(Filters);
