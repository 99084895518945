import React, { Component, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";
import styled from "styled-components";
import { convertTableToMobile, replaceStateVariables } from "../utilities";
import { getReservationNumber } from "../../../selectors/reservationSelectors";
import { deviceUtils } from "@libema/design-system";

const RichTextStyled = styled.div`
  /* p:has(> iframe[src*='youtube.com']) */
  &.with-video-iframe,
  .with-video-iframe {
    overflow: hidden;
    position: relative;
    width: 100%;

    &::after {
      padding-top: 56.25%; /* 16:9 */
      display: block;
      content: "";
    }

    &.4x3::after {
      padding-top: 75%; /* 4:3 */
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .mobile-table {
    display: none;
    margin-top: 16px;
    margin-bottom: 16px;

    &--item {
      padding: 16px;
      border-bottom: 1px solid #eae1c8;

      &:first-of-type {
        border-top: 1px solid #eae1c8;
      }

      &:nth-of-type(odd) {
        background-color: #fbf9f4;
      }
    }
  }

  @media ${deviceUtils.DEVICE_MD_DOWN} {
    .no-mobile {
      display: none;
    }

    .mobile-table {
      display: block;
    }
  }
`;

// Source: https://github.com/christo-pr/dangerously-set-html-content/blob/master/src/index.js
const DangerouslySetHtmlContent = ({ html, ...rest }) => {
  const divRef = useRef(null);

  useEffect(() => {
    if (!html) return;

    const slotHtml = document.createRange().createContextualFragment(html);
    divRef.current.innerHTML = "";
    divRef.current.appendChild(slotHtml);
  }, [html]);

  return <div {...rest} ref={divRef}></div>;
};

class RichText extends Component {
  ref = React.createRef();

  static propTypes = {
    value: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    updatedValue: PropTypes.string,
    reservationNumber: PropTypes.string,
  };

  componentDidMount() {
    const richTextElement = this.ref.current;

    if (richTextElement) {
      const videoIframeParent = richTextElement.querySelector(
        'iframe[src*="youtube.com"]'
      )?.parentElement;
      const allTablesNode = richTextElement.querySelectorAll("table");

      if (videoIframeParent && videoIframeParent.childElementCount === 1) {
        videoIframeParent.classList.add("with-video-iframe");
      }

      if (allTablesNode.length > 0) {
        Array.from(allTablesNode).forEach(convertTableToMobile);
      }
    }
  }

  /**
   * Make sure this component is only updated when the value changed
   *
   * @param {object} nextProps
   */
  shouldComponentUpdate = (nextProps) => {
    if (
      !isEqual(nextProps.updatedValue, this.props.updatedValue) ||
      !isEqual(nextProps.reservationNumber, this.props.reservationNumber)
    ) {
      return true;
    }

    return false;
  };

  decodeHtml = (html) => {
    // TODO: Validate & decode on backend
    if (typeof document !== "undefined") {
      const txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }

    return html;
  };

  render() {
    const { updatedValue, reservationNumber, ...restProps } = this.props;

    let html = this.decodeHtml(updatedValue);
    // This is here for backwards compatibility. Some img sources are still defined as relative paths,
    // while they should now be mapped to the api.
    html = html.replace(
      /"\/media\/cache\/resolve/g,
      `"${process.env.NEXT_PUBLIC_API_BASE_URL}/media/cache/resolve`
    );
    html = html.replace("%reservation_number%", reservationNumber);

    return (
      <RichTextStyled
        ref={this.ref}
        {...restProps}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }
}

export default connect((state, ownProps) => ({
  // Replace variables defined in the value with the state var
  updatedValue: replaceStateVariables(ownProps.value, state),
  reservationNumber: getReservationNumber(state),
}))(RichText);
