import { useEffect, useState } from "react";
import { isDesktop } from "../utils/deviceUtils";

type WindowSize = {
  width: number;
  height: number;
  isDesktop: boolean;
};

function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
    isDesktop: false,
  });

  useEffect(() => {
    function handleResize() {
      const { innerWidth: width, innerHeight: height } = window;

      setWindowSize({
        width,
        height,
        isDesktop: isDesktop(),
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;
