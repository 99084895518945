import { FC } from "react";
import * as React from "react";
import styled from "styled-components";
import cls from "classnames";

// Components
import CountLabel from "./CountLabel";
import P from "../../typography/P";

// Utils
import { contrastColor } from "../../../utils/colorUtils";

const ListItem = styled.li`
  display: flex;
  flex: 1;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px;

  ${CountLabel} {
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;

  ${P} {
    color: ${({ theme }) => theme.color.text_light};
    margin-bottom: 0;
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: normal;
  }
`;

type Props = {
  className?: string;
  active?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  onClick: (e) => void;
};

const FilterRadioItem: FC<Props> = ({
  className,
  active,
  children,
  onClick,
}) => {
  return (
    <ListItem
      className={cls({
        className,
        active,
      })}
      onClick={onClick}
    >
      {children}
    </ListItem>
  );
};

export default FilterRadioItem;
