import { createSelector } from 'reselect'
import { getAccommodationTypes, getProperties, getPropertyGroups } from '../../selectors/entitySelectors'

const getCompareIds = state => state.compare.compareListIds || []

export const getAccommodationsById = createSelector(
  getAccommodationTypes,
  getCompareIds,
  (accommodations, ids) => ids
    .map(id => accommodations[id])
    .filter(a => a)
    .reduce((mappedById, acco) => ({
      ...mappedById,
      [acco.id]: acco
    }), {})
)

export const getComparablePropertyGroups = (accommodationType, propertyIds) => createSelector(
  getProperties,
  getPropertyGroups,
  (properties, propertyGroups) => propertyIds.map(id => properties[id])
    .reduce((groups, property) => {
      if (!property) return groups
      if (groups[property.groupId]) {
        groups[property.groupId].properties.push(property)
      } else {
        groups[property.groupId] = {
          ...propertyGroups[property.groupId],
          properties: [property]
        }
      }
      return groups
    }, {})
)
