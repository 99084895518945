import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setCurrentSection } from "../../reservation/actions";
import {
  isFirstBlockOfType,
  getBlocksOfType,
} from "../../../selectors/entitySelectors";
import analyticsUtils from "../../../utilities/analyticsUtils";

class BookingSection extends React.Component {
  componentDidMount = () => {
    if (this.props.isFirstSection) {
      analyticsUtils.handleSectionView(this.props.block);
    }
  };

  handleSectionClick = () => {
    if (!this.isActive()) {
      this.props.onSetCurrentSection(this.props.sections, this.props.block);
    }
  };

  isActive = () => {
    const { id, isFirstSection, current } = this.props;

    if (current && current.id === id) return true;
    if (!current && isFirstSection) return true;
    return false;
  };

  render = () => {
    const { placeholder, properties, id, sections, manageTags } = this.props;

    const sectionNumber = sections.findIndex((s) => s.id === id) + 1;

    return (
      <div {...manageTags}>
        <div
          className="booking-step"
          id={`section${id}`}
          onClick={this.handleSectionClick}
          role="button"
          tabIndex={-1}
        >
          <div
            className={`section-header row mx-0 ${
              this.isActive() ? "bg-main" : "bg-unselected text-white"
            }`}
          >
            <span className="lighten p-2 h1 my-0">{sectionNumber}</span>
            <h2 className="p-2 h1 my-0">{properties.title}</h2>
            <div className="arrow float-right p-2">
              {this.isActive() && (
                <i className="material-icons md-36">keyboard_arrow_up</i>
              )}
              {!this.isActive() && (
                <i className="material-icons md-36">keyboard_arrow_down</i>
              )}
            </div>
          </div>
        </div>
        <div className={`booking-section ${this.isActive() ? "active" : ""}`}>
          {this.isActive() && (
            <div className="booking-section-padding">{placeholder(0)}</div>
          )}
        </div>
      </div>
    );
  };
}

BookingSection.propTypes = {
  onSetCurrentSection: PropTypes.func.isRequired,
  placeholder: PropTypes.func.isRequired,
  id: PropTypes.number,
  isFirstSection: PropTypes.bool,
  block: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  sections: PropTypes.array,
  current: PropTypes.object,
};

const mapStateToProps = (state, { block }) => ({
  isFirstSection: isFirstBlockOfType(block.id, "BookingSection")(state),
  sections: getBlocksOfType("BookingSection")(state),
  current: state.reservation.sections.current,
  previous: state.reservation.sections.previous,
  next: state.reservation.sections.next,
});

const mapDispatchToProps = (dispatch) => ({
  onSetCurrentSection: (sections, current) =>
    dispatch(setCurrentSection(sections, current)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingSection);
