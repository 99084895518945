import { gql } from "@apollo/client";

// Data
import link from "../data/link";
import asset from "../data/asset";
import activity from "../data/activity";

const query = gql`
  fragment RelatedActivities on SectionRelatedActivities {
    type: __typename
    sys {
      id
    }
    title
    backgroundColor
    callToAction {
      ...Link
    }
    categoryFilterCollection(limit: 5) {
      items {
        ... on Category {
          title
          linkedFrom {
            activities: activityCollection(limit: 5) {
              items {
                title
                ...Activity
              }
            }
          }
        }
      }
    }
  }

  ${link}
  ${asset}
  ${activity}
`;

export default query;
