import { gql } from "@apollo/client";

import formField from "../data/formField";

const fragment = gql`
  fragment Form on SectionForm {
    sys {
      id
    }
    title
    submitButtonText
    formType: type
    destination
    recaptcha
    successText {
      json
    }
    errorText {
      json
    }
    fields: fieldsCollection(limit: 15) {
      items {
        ...FormField
      }
    }
    backgroundColor
  }
  ${formField}
`;

export default fragment;
