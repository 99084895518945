import {
  useCallback,
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import trans from "counterpart";
import moment from "moment";
import { useModal } from "mui-modal-provider";

// Components
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Switch from "@mui/material/Switch";
import InfoIcon from "@mui/icons-material/Info";
import { Dot } from "@libema/design-system";

// Internal Components
import DatePicker from "./DatePicker";
import { getArrival, getDeparture } from "../../../preferences/selectors";
import { getFilter } from "../../../../selectors/filterSelectors";
import { setPreference } from "../../../../actions/preferenceActions";
import { INTERNAL_DATE } from "../../../../constants/dates";
import { isMonthBlocked } from "./pickerUtils";
import { APP_BEEKSEBERGEN } from "../../../../constants/apps";
import DatesInfoDialogContent from "../DatesInfoDialog";
import useChangeoverDaysFilter from "./useChangeoverDaysFilter";
import { isBeekseBergen } from "../../../../utilities/common";

type Props = {
  arrival: string;
  availableDates: string[];
  date: string;
  loading: boolean;
  setDepartureDate: (date: string) => void;
  toggle: (property: string, checked: boolean) => void;
  onChange: (date: string) => void;
  showInfoDialog: boolean;
  showLegend: boolean;
  showFlexibleBookingDays: boolean;
  onShowFlexibleBookingDaysChange: (checked: boolean) => void;
  changeoverDays?: string[];
  changeoverExceptionDates?: string[];
};

type RefType = {
  open: () => void;
  close: () => void;
};

const DepartureDatePicker = forwardRef<RefType, Props>(
  (
    {
      toggle,
      loading,
      changeoverDays,
      changeoverExceptionDates,
      showInfoDialog = false,
      showLegend = false,
      showFlexibleBookingDays = false,
      onChange = () => null,
      onShowFlexibleBookingDaysChange = () => null,
    },
    ref
  ) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    const arrival = useSelector(getArrival);
    const availableDates = useSelector(getFilter("departures"));
    const date = useSelector(getDeparture);

    const dispatch = useDispatch();
    const setDepartureDate = (departureDate) =>
      dispatch(setPreference("departure", departureDate.format(INTERNAL_DATE)));

    const [dropdown, setDropdown] = useState(false);
    const [monthBlocked, setMonthBlocked] = useState(false);
    const { showModal } = useModal();
    const openModal = () => {
      const modal = showModal(DatesInfoDialogContent, {
        getId: () => modal.id,
      });
    };

    const availableDatesFiltered = useChangeoverDaysFilter({
      availableDates,
      showFlexibleBookingDays,
      showLegend,
      changeoverDays,
      changeoverExceptionDates,
    });

    // This component is used as a ref, to use this function outside of this component
    const open = useCallback(() => setDropdown(true), [setDropdown]);

    // This component is used as a ref, to use this function outside of this component
    const close = useCallback(() => setDropdown(false), [setDropdown]);

    const toggleDropdown = useCallback(
      () => setDropdown(!dropdown),
      [dropdown, setDropdown]
    );

    const handleClickOutside = useCallback(
      (event) => {
        const { current } = dropdownRef;
        if (current && dropdown && !current.contains(event.target)) {
          close();
        }
      },
      [dropdown, dropdownRef, close]
    );

    const futureReservationRoute = !isBeekseBergen
      ? trans("routes.reserve_dierenbos")
      : trans("routes.reserve");

    const reminderRoute = !isBeekseBergen
      ? trans("routes.reminder_dierenbos")
      : trans("routes.reminder");

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [handleClickOutside]);

    useImperativeHandle(ref, () => ({
      open,
      close,
    }));

    return (
      <div
        className={`departure-date form-dropdown dropdown ${
          dropdown && !loading ? "show" : ""
        }`}
        ref={dropdownRef}
      >
        <div className="dropdown-toggle" onClick={toggleDropdown}>
          <div className="dropdown-filter">
            <div className="dropdown-heading">
              {trans("label.departure_date")}
            </div>
            <div className="dropdown-value">
              {date
                ? moment(date).format("DD-MM-YYYY")
                : trans("label.select_date")}
            </div>
          </div>
        </div>
        {dropdown && (
          <div className={`dropdown-menu${monthBlocked ? " future-date" : ""}`}>
            <div className={loading ? "loading-dropdown loading-active" : ""}>
              {loading && (
                <DatePicker
                  start={moment().format(INTERNAL_DATE)}
                  end={date}
                  date={moment()}
                />
              )}
              {!loading && (
                <DatePicker
                  start={arrival || moment().format(INTERNAL_DATE)}
                  end={date}
                  date={date}
                  focus={arrival || moment().format(INTERNAL_DATE)}
                  availableDates={availableDatesFiltered}
                  onDateClick={(date) => {
                    setDepartureDate(date);
                    if (toggle) toggle("openFilters", true);
                    onChange(date);
                    setDropdown(false);
                  }}
                  onMonthChange={(date) => {
                    if (isMonthBlocked(date, isBeekseBergen)) {
                      return setMonthBlocked(true);
                    }

                    return setMonthBlocked(false);
                  }}
                  renderWeekHeaderElement={(weekday) => {
                    const weekdayNumber = moment(weekday, "dddd").weekday();
                    const showRegularChangeoverDots = changeoverDays?.includes(
                      weekdayNumber.toString()
                    );
                    if (!showLegend) return weekday;
                    return (
                      <Stack>
                        <Box justifyContent="center">{weekday}</Box>
                        <Stack
                          direction="row"
                          sx={{ justifyContent: "center" }}
                        >
                          {showRegularChangeoverDots && (
                            <Dot color="accentPrimary.main" sx={{ mx: 0.5 }} />
                          )}
                          {showFlexibleBookingDays && <Dot sx={{ mx: 0.5 }} />}
                        </Stack>
                      </Stack>
                    );
                  }}
                />
              )}
            </div>
            <div className="future-booking">
              <div className="mb-3">
                <p>
                  {trans(
                    `label.future_booking${isBeekseBergen ? "" : "_dierenbos"}`
                  )}
                </p>
                <a className="btn btn-mail" href={futureReservationRoute}>
                  {trans("label.future_reservation")}
                </a>
              </div>
              <div>
                <p>
                  {trans(
                    `label.future_booking_reminder${
                      isBeekseBergen ? "" : "_dierenbos"
                    }`
                  )}
                </p>
                <a className="btn btn-mail" href={reminderRoute}>
                  {trans("label.book_reminder")}
                </a>
              </div>
            </div>
            {showLegend && (
              <Stack mt={2}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems="center">
                    <Dot
                      sx={{
                        margin: "0 10px 0 0",
                      }}
                    />
                    <Typography
                      variant="body2"
                      color={
                        showFlexibleBookingDays
                          ? "text.primary"
                          : "text.disabled"
                      }
                    >
                      {trans("label.datepicker_toggle_hotel_camping")}
                    </Typography>
                  </Stack>
                  <Switch
                    checked={showFlexibleBookingDays}
                    size="small"
                    onChange={(e) =>
                      onShowFlexibleBookingDaysChange(e.target.checked)
                    }
                  />
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems="center">
                    <Dot
                      sx={{
                        margin: "0 10px 0 0",
                      }}
                      color="accentPrimary.main"
                    />
                    <Typography variant="body2" color={"text.primary"}>
                      {trans("label.datepicker_toggle_arrivals")}
                    </Typography>
                  </Stack>
                  <Switch checked={true} size="small" disabled />
                </Stack>
              </Stack>
            )}
            {showInfoDialog && (
              <Stack sx={{ mt: 2 }}>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    openModal();
                  }}
                >
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      color="text_disabled"
                      variant="body2"
                      sx={{ textDecoration: "underline" }}
                    >
                      {trans("label.date_info_dialog_link")}
                    </Typography>
                    <InfoIcon color="secondary" />
                  </Stack>
                </Link>
              </Stack>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default DepartureDatePicker;
