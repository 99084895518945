import PropTypes from "prop-types";

const CheckField = ({ input, meta: { touched, error }, block, manageTags }) => {
  return (
    <div className="form-check" {...manageTags}>
      <label className="custom-control custom-checkbox">
        <input
          type="checkbox"
          id={block.id}
          name={block.name}
          value={block.title}
          onChange={(event) => input.onChange(event)}
          checked={!!input.value}
          className="custom-control-input"
        />
        <span className="custom-control-indicator" />
        {touched && error && (
          <div className="form-check has-danger text-danger form-control-feedback">
            {error}
          </div>
        )}
        <span
          dangerouslySetInnerHTML={{ __html: block.value }}
          className="custom-control-description"
        />
      </label>
    </div>
  );
};

CheckField.propTypes = {
  input: PropTypes.object,
  block: PropTypes.object,
  name: PropTypes.string,
  meta: PropTypes.object,
  manageTags: PropTypes.object,
};

export default CheckField;
