import { captureException } from "@sentry/nextjs";
import axios from "axios";

import { get, post } from "./";

export const getForm = (id) =>
  get({
    url: `/forms/${id}`,
  });

export const postFormPost = (id, data) =>
  post({
    url: `/forms/${id}/posts`,
    data,
  });

export const postMaileonForm = async (data) => {
  try {
    const response = await axios.post("/api/transactions", data);
    return response.data;
  } catch (error) {
    captureException(error);
    throw error;
  }
};
