const LeftArrow = (props) => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 12L7.41 10.59L2.83 6L7.41 1.41L6 0L3.8147e-06 6L6 12Z"
      fill="#BFBFBF"
    />
  </svg>
);

export default LeftArrow;
