import styled from "styled-components/macro";

export const Link = styled.a`
  color: ${({ theme }) => theme.textColor.default};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default Link;
