import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const PriceTableContentCell = styled.div`
  cursor: ${props => (props.className === 'content-cell-empty' ? 'default' : 'pointer')}
`

const PriceTableSpan = styled.span`
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-weight: 400;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`

const PriceTableCell = (props) => {
  const classNames = [!props.data ? 'content-empty-cell' : 'content-cell']
  if (props.active) classNames.push('active')
  if (props.activeColumn) classNames.push('active-column')
  return (
    <PriceTableContentCell className={classNames.join(' ')} onClick={props.onClick}>
      {!props.data && (
        <PriceTableSpan>-</PriceTableSpan>
      )}
      {props.data && props.data.from_price > props.data.all_in_price && (
        <s>
          &euro;
          {` ${props.data.from_price},-`}
        </s>
      )}
      {props.data && (
        <PriceTableSpan>
          &euro;
          {` ${props.data.all_in_price},-`}
        </PriceTableSpan>
      )}
    </PriceTableContentCell>
  )
}

PriceTableCell.propTypes = {
  active: PropTypes.bool,
  activeColumn: PropTypes.bool,
  data: PropTypes.object,
  onClick: PropTypes.func.isRequired
}

export default PriceTableCell
