import { gql } from "@apollo/client";

// Data models
import article from "../data/article";

const fragment = gql`
  fragment ArticleTopic on Article {
    ...Article
  }

  ${article}
`;

export default fragment;
