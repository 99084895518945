import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import hoistStatics from 'hoist-non-react-statics'
import * as utils from '../utilities/blocks'
import * as common from '../utilities/common'
import { manageModeSelector, manageTypeSelector } from '../selectors/environmentSelectors'

const getDisplayName = WrappedComponent => WrappedComponent.displayName || WrappedComponent.name || 'Component'

const abstractBlock = (WrappedBlock) => {
  class Block extends Component {
    render = () => {
      const placeholder = position => (
        utils.placeholder(this.props.entities, position, this.props.id || null, this.props.children || null, this.props.manageMode, this.props.manageType)
      )
      const cplaceholder = (entities, position, parentId, children, mode = 'public') => (
        utils.placeholder(entities, position, parentId, children, mode)
      )

      if (this.props.isHidden || (this.props.active === false && this.props.manageMode !== 'manage')) {
        return null
      }

      return (
        <WrappedBlock
          {...this.props}
          {...this.state}
          {...this.props.block}
          placeholder={placeholder}
          cplaceholder={cplaceholder}
        />
      )
    }
  }

  Block.propTypes = {
    block: PropTypes.object,
    id: PropTypes.number,
    entities: PropTypes.object,
    children: PropTypes.array,
    isHidden: PropTypes.bool,
    manageMode: PropTypes.string,
    manageType: PropTypes.string
  }

  Block.displayName = `HOC(${getDisplayName(WrappedBlock)})`

  const mapStateToProps = (state, ownProps) => {
    let block = state.entities.blocks[ownProps.id]
    let children = common.objectToArray(state.entities.blocks).filter(child => child.parentId === ownProps.id)

    if (typeof block === 'undefined') {
      block = ownProps.block // eslint-disable-line prefer-destructuring
      children = ownProps.children // eslint-disable-line prefer-destructuring
    }

    const manageMode = manageModeSelector(state)
    const manageType = manageTypeSelector(state)
    let manageTags = null

    // Only show manage tags on the content blocks when editting a content item or on template blocks when editting a template
    if (manageMode === 'manage' && (
        (manageType === 'content' && block.contentId) ||
        (manageType === 'template' && block.templateId)
       )
    ) {
      manageTags = {
        key: block.id,
        'data-pm-block-manage': 'true',
        'data-pm-block-id': block.id,
        'data-pm-block-owner-id': block.contentId || block.templateId,
        'data-pm-block-type': 'default',
        'data-pm-block-hidden': !block.active,
        'data-pm-tool': '{}'
      }
    }

    return {
      ...block,
      block,
      entities: state.entities.blocks,
      children,
      isHidden: (block && block.contentId && state.content[block.contentId] && state.content[block.contentId].hiddenBlocks && state.content[block.contentId].hiddenBlocks.indexOf(ownProps.id) >= 0),
      manageMode: manageMode,
      manageTags
    }
  }

  return hoistStatics(connect(mapStateToProps, undefined, undefined, { forwardRef: true })(Block), WrappedBlock)
}

export default abstractBlock
