import {
  FC,
  createContext,
  useContext,
  cloneElement,
  useState,
  useEffect,
  ReactElement,
  useLayoutEffect,
} from "react";
import { DEVICE_MD_UP } from "@libema/design-system";


type ContextType = {
  filterModalOpen: boolean;
  openFilterModal: () => void;
  closeFilterModal: () => void;
};

const Context = createContext<ContextType>({
  filterModalOpen: false,
  openFilterModal: () => null,
  closeFilterModal: () => null,
});

export const useFilters = () => {
  if (Context == null) {
    throw new Error("Wrap useComponents inside a <FilterProvider> element");
  }
  return useContext(Context);
};

export const Provider = ({ ...props }) => {
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);

  const openFilterModal = () => {
    setFilterModalOpen(true);
  };

  const closeFilterModal = () => {
    setFilterModalOpen(false);
  };

  // Hide body scroll
  useEffect(() => {
    if (filterModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [filterModalOpen]);

  // Enable body scroll if screen is larger than DEVICE_MD_UP
  useLayoutEffect(() => {
    const windowResize = () => {
      const deviceUp = window.matchMedia(DEVICE_MD_UP);
      if (deviceUp.matches) {
        setFilterModalOpen(false);
      }
    };
    window.addEventListener("resize", windowResize);

    return () => window.removeEventListener("resize", windowResize);
  }, [setFilterModalOpen]);

  return (
    <Context.Provider
      value={{
        filterModalOpen,
        openFilterModal,
        closeFilterModal,
      }}
      {...props}
    />
  );
};
