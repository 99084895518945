import React from 'react'
import styled from 'styled-components'

const Icon = styled.i.attrs(() => ({
  className: 'material-icons'
}))`
  position: relative;
  color: #e84d0e;
  font-size: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  width: 100%;
`

export const Clear = props => <Icon {...props}>clear</Icon>
