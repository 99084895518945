import React from 'react'
import PropTypes from 'prop-types'
import TabsContext from '../contexts/TabsContext'

class Tabs extends React.Component {
  state = {
    activeTab: 0
  }

  componentDidMount = () => {
    const { children, entities } = this.props

    let hash = window.location.hash
    if (hash) {
      // TODO: Verify if this works after changing to Nextjs
      hash = hash.substr(1, hash.length)
      const current = children.map(child => entities[child]).find(entity => entity.id === parseInt(hash, 10))
      if (current) {
        this.setState({ activeTab: current.position - 1 })
      }
    }
  }

  setActiveTab = (index) => {
    const { activeTab } = this.state
    if (activeTab !== index) {
      this.setState({
        activeTab: index
      })
    }
  }

  findTabEntityByPosition = (tabKey) => {
    const { children, entities } = this.props
    return children.map(child => entities[child]).find(child => child.position === parseInt(tabKey, 10))
  }

  getSortedTabs = () => Object.values(this.props.properties.tabs).sort((a, b) => b.sort - a.sort);

  render = () => {
    const { properties, placeholder, manageTags } = this.props
    const { activeTab } = this.state

    // managetags?
    return (
      <div className={`row mx-0 ${properties.template === 'tabs-vertical' ? 'tabs-vertical' : 'tabs-horizontal'}`} {...manageTags}>
        <ul className={`nav nav-tabs ${properties.template === 'tabs-vertical' ? 'nav-stacked' : ''}`}>
          {this.getSortedTabs().map((tab, index) => {
            // Note that the tab.key is the position of the tab in the navigation.
            const tabEntity = this.findTabEntityByPosition(tab.key)
            return (
              <li key={tab.key} className='nav-item' role='presentation'>
                {tab.parameters && (
                  <a
                    aria-controls={tabEntity.id}
                    className='nav-link'
                    role='tab'
                    data-toggle='tab'
                    data-hash={tab.parameters}
                    id={tabEntity.id}
                  >
                    {tab.label}
                  </a>
                )}
                {!tab.parameters && (
                  <a
                    aria-controls={tabEntity.id}
                    onClick={() => this.setActiveTab(index)}
                    className={`nav-link ${activeTab === index ? 'active' : ''}`}
                    role='tab'
                    data-toggle='tab'
                    id={tabEntity.id}
                  >
                    {tab.label}
                  </a>
                )}
              </li>
            )
          })}
        </ul>
        <div className='tab-content'>
          {this.getSortedTabs().map((tab, index) => (
            <TabsContext.Provider key={tab.key} value={{ myIndex: index, activeTab }}>
              <div key={tab.key} role='tabpanel' className={`tab-pane ${activeTab === index ? 'active' : ''}`}>
                {placeholder(tab.key)}
              </div>
            </TabsContext.Provider>
          ))}
        </div>
      </div>
    )
  }
}

Tabs.propTypes = {
  entities: PropTypes.object,
  children: PropTypes.array,
  properties: PropTypes.object,
  placeholder: PropTypes.func
}

export default Tabs
