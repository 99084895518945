import React, { useState } from "react";
import styled from "styled-components";

const noop = () => {};

export enum AlertType {
  INFO = "info",
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
}

export type AlertProps = {
  type?: AlertType;
  canBeClosed?: boolean;
  isVisible?: boolean;
  onClose?: Function;
  id?: HTMLDivElement["id"];
  className?: HTMLDivElement["className"];
  key?: React.Key;
  style?: React.CSSProperties;
};

const AlertWrapper = styled.div<{ type?: AlertType }>`
  background-color: ${({ type, theme }) =>
    theme.backgroundColor[type || AlertType.INFO]};
  padding: ${({ theme }) => theme.padding.md};
  position: relative;
`;

const CloseButton = styled.button`
  float: right;
  font-size: 22px;
  line-height: 1;
  background: none;
  border: none;
`;

const Alert: React.FC<AlertProps> = ({
  children,
  type,
  onClose = noop,
  canBeClosed = false,
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) {
    return null;
  }

  const handleAlertClose = () => {
    setIsVisible(false);
    onClose?.();
  };

  return (
    <AlertWrapper role="alert" type={type} {...rest}>
      {canBeClosed && (
        <CloseButton aria-label="Close" onClick={handleAlertClose}>
          <span aria-hidden="true">&times;</span>
        </CloseButton>
      )}
      {children}
    </AlertWrapper>
  );
};

export default Alert;
