import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import trans from 'counterpart'
import DateField from '../../../components/form/fields/DateField'

const DateFieldBlock = ({ block }) => (
  <Field
    name={block.name}
    component={DateField}
    type='date'
    block={block}
    placeholder={trans('form.placeholder.birth_date')}
  />
)

DateFieldBlock.propTypes = {
  block: PropTypes.object
}

export default DateFieldBlock
