import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import P from "../typography/P";
import Translate from "../typography/Translate";
import H3 from "../typography/H3";
import CheckIcon from "../icons/CheckIcon";

const Body = styled.div`
  display: flex;
  position: relative;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListItem = styled.div`
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.margin.md};
`;

const Summary = styled.div`
  border-top: 2px solid ${({ theme }) => darken(0.1, theme.color.neutralLight)};
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: flex-start;
  padding-top: 0.3rem;
`;

export const MoreInfoButton = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  height: auto;
  background: transparent;
  color: ${({ theme }) => theme.color.neutralMedium};
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.font.default};
  font-weight: bold;
`;

const Thumbnail = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 15px;
  flex: 0 1 auto;
`;

const Title = styled(H3)`
  margin: 0;
  margin-right: 5px;
  font-size: 1.2rem;
`;

export const RightTop = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.padding.sm};
  right: 0;
`;

export type AdditionGroupProps = {
  isChecked?: boolean;
  title: string;
  description?: string;
  image?: string;
  priceLabel?: string;
  onMoreInfoClick: () => void;
};

export const AdditionGroup = ({
  isChecked,
  title,
  description,
  image,
  priceLabel,
  onMoreInfoClick,
}) => (
  <ListItem>
    <Body>
      <Thumbnail src={image} />
      <Summary>
        <Title>{title}</Title>
        <P>{description}</P>
      </Summary>
      {isChecked && (
        <RightTop>
          <CheckIcon />
        </RightTop>
      )}
    </Body>
    <Footer>
      <span>{priceLabel}</span>
      <MoreInfoButton onClick={onMoreInfoClick}>
        <Translate id="button.more_info" />
        &nbsp;&rsaquo;
      </MoreInfoButton>
    </Footer>
  </ListItem>
);

export default AdditionGroup;
