import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import trans from 'counterpart'
import uniqBy from 'lodash.uniqby'
import Image from '../../media/components/Image'
import CheckButtonField from '../../form/components/CheckButtonField'
import { totalBillableSubjects } from '../../reservation/selectors'

/**
 * @deprecated Use the AdditionOverviewBlock instead
 */
const AdditionForm = ({ addition: { properties, resourceAdditions, media }, billableSubjects, manageTags }) => {
  const resourceAdditionGroups = resourceAdditions.filter(r => r.group).map(r => r.group)
  const uniqGroups = uniqBy(resourceAdditionGroups, uniq => uniq.id)

  if (!uniqGroups || uniqGroups.length === 0) {
    return (
      <div {...manageTags}>
        <div className='row mx-0 mb-2'>
          <div className='col-sm-4 pl-md-3 pl-sm-3 px-xs-0 mb-md-0 mb-2 pr-sm-15'>
            {media && (
              <Image
                file={media.reference}
                filter='md_11'
                attr={{ alt: media.name, className: 'img-fluid' }}
              />
            )}
          </div>
          <div className='col-sm-8 pl-xs-0 pr-3 px-md-2 px-sm-3 px-xs-2'>
            <div dangerouslySetInnerHTML={{ __html: properties.long_description }} />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            {Object.keys(resourceAdditions).map(k => (
              <div key={k} className='form-group row mx-0 px-md-3 px-sm-3 px-xs-2 py-2'>
                <div className='col-7 col-xs-12 col-md-6 px-0'>
                  <h5>{resourceAdditions[k].name}</h5>
                  <p className='text-small'>{resourceAdditions[k].description}</p>
                  <p>{resourceAdditions[k].price_label}</p>
                </div>
                <div className='col-1 col-xs-3 col-sm-2 col-md-2 offset-md-1 pl-0'>
                  {(properties.amount === 'flex') && (
                    <Field name={`addition-${resourceAdditions[k].id}`} component='select' className='form-control mb-1 addition-select'>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(quantity => (
                        <option key={quantity} value={quantity}>{quantity}</option>
                      ))}
                    </Field>
                  )}
                  {properties.amount !== 'flex' && (
                    <div style={{ marginLeft: '-10px' }}>
                      <Field
                        booking
                        name={`addition-${resourceAdditions[k].id}`}
                        component={CheckButtonField}
                        actualValue={properties.amount}
                        label={trans('button.add')}
                        checkedLabel={trans('button.remove')}
                      />
                    </div>
                  )}
                </div>
                <div className='col-2 col-xs-5 col-md-3 px-0'>
                  <div className='text-small mb-1' style={{ float: 'right' }}>
                    <div><strong>{resourceAdditions[k].short_name}</strong></div>
                    {resourceAdditions[k].price > 0 && (
                      <div>
                        {`€ ${resourceAdditions[k].price ? resourceAdditions[k].price.toFixed(2).replace('.', ',') : 0}`}
                      </div>
                    )}
                    {!resourceAdditions[k].price && (
                      <div>{properties.label}</div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div {...manageTags}>
      <div className='row mx-0 mb-2'>
        <div className='col-sm-4 pl-md-3 pl-sm-3 px-xs-0 mb-md-0 mb-2 pr-sm-15'>
          {media && (
            <Image
              file={media.reference}
              filter='md_11'
              attr={{ alt: media.name, className: 'img-fluid' }}
            />
          )}
        </div>
        <div className='col-sm-8 pl-xs-0 pr-3 px-md-2 px-sm-3 px-xs-2'>
          <div dangerouslySetInnerHTML={{ __html: properties.long_description }} />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12'>
          {uniqGroups.map(group => (
            <div key={group.id} className='form-group row mx-0 px-md-3 px-sm-3 px-xs-2 py-2'>
              <div className='col-7 col-xs-12 col-md-6 px-0'>
                <h5>{group.name}</h5>
                <p className='text-small'>{group.description}</p>
              </div>
              <div className='col-3 col-xs-12 col-md-6 pl-0'>
                {resourceAdditions
                  .filter(addition => addition.group.id === group.id)
                  .map(addition => (
                    <div key={addition.id} className='row'>
                      <div className={`col-${properties.amount === 'flex' ? 4 : 5} offset-md-1`}>
                        {(properties.amount === 'flex') && (
                          <Field name={`addition-${addition.id}`} component='select' className='form-control mb-1 addition-select'>
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(quantity => (
                              <option key={quantity} value={quantity}>{quantity}</option>
                            ))}
                          </Field>
                        )}
                        {(properties.amount !== 'flex') && (
                          <Field
                            booking
                            name={`addition-${addition.id}`}
                            component={CheckButtonField}
                            actualValue={(properties.amount === 'total') ? billableSubjects.toString() : properties.amount.toString()}
                            label={trans('button.add')}
                            checkedLabel={trans('button.remove')}
                          />
                        )}
                      </div>
                      <div className={`col-${properties.amount === 'flex' ? 7 : 6}`}>
                        <div className='text-small mb-1'>
                          <div><strong>{addition.short_name}</strong></div>
                          {addition.price > 0 && (
                            <div>{`€ ${addition.price && addition.price.toFixed(2).replace('.', ',')}`}</div>
                          )}
                          {!addition.price && (
                            <div>{properties.label}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  billableSubjects: totalBillableSubjects(state)
})

AdditionForm.propTypes = {
  addition: PropTypes.object,
  billableSubjects: PropTypes.number
}

export default connect(mapStateToProps)(AdditionForm)
