import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import CheckField from "./CheckField";
import GeneralTermsCheckbox from "./GeneralTermsCheckbox";

const CheckboxMapper = (props) => {
  const {
    block,
    meta: { form },
  } = props;
  const isGeneralTerms = block.name === "GeneralTerms" && form === "customer";
  const Checkbox = isGeneralTerms ? GeneralTermsCheckbox : CheckField;
  return <Checkbox {...props} />;
};

const RichCheckItemBlock = ({ block }) => {
  return <Field name={block.name} component={CheckboxMapper} block={block} />;
};

RichCheckItemBlock.propTypes = {
  block: PropTypes.object,
};

export default RichCheckItemBlock;
