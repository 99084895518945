import { gql } from "@apollo/client";

// Data
import link from "../data/link";
import asset from "../data/asset";
import activityEvent from "../data/activityEvent";
import activityEventSeparator from "../data/activityEventSeparator";

const query = gql`
  fragment SectionEvents on SectionEvents {
    type: __typename
    sys {
      id
    }
    topic {
      name
      title
      activityEventSchedule {
        sys {
          id
        }
        title
        activityEventCollection(limit: 50) {
          total
          items {
            type: __typename
            ...ActivityEvent
            ...ActivityEventSeparator
          }
        }
      }
    }
    text {
      json
    }
  }

  ${link}
  ${asset}
  ${activityEvent}
  ${activityEventSeparator}
`;

export default query;
