/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { FieldInputProps, FieldProps, FormikProps } from "formik";
import Input from "../Input";

export type TextFieldProps = {
  placeholder?: string;
  successStateAfterSubmit?: boolean;
  type: string;
} & FieldProps;

/**
 * This determines if the field should be marked as "success".
 * If the successStateAfterSubmit is passed, only mark as success after the form is submitted
 */
const isSuccess = (
  form: FormikProps<any>,
  field: FieldInputProps<any>,
  successStateAfterSubmit = false
): boolean => {
  const isValid =
    typeof form.touched[field.name] !== "undefined" &&
    typeof form.errors[field.name] === "undefined";
  if (successStateAfterSubmit) {
    return isValid && form.submitCount > 0 && !form.isSubmitting;
  }

  return isValid;
};

export const TextField: React.FC<TextFieldProps> = ({
  field,
  placeholder,
  type,
  form,
  successStateAfterSubmit,
}) => (
  <Input
    name={field.name}
    autoComplete="off"
    onBlur={field.onBlur}
    onChange={field.onChange}
    placeholder={placeholder}
    type={type || "text"}
    value={field.value}
    error={
      typeof form.touched[field.name] !== "undefined" &&
      typeof form.errors[field.name] !== "undefined"
    }
    success={isSuccess(form, field, successStateAfterSubmit)}
  />
);

export default TextField;
