import { gql } from "@apollo/client";

// Data
import frequentlyAskedQuestion from "../data/frequentlyAskedQuestion";
import asset from "../data/asset";
import link from "../data/link";

const query = gql`
  fragment Faq on SectionFaq {
    type: __typename
    sys {
      id
    }
    title
    description {
      json
    }
    backgroundColor
    questions: itemsCollection(limit: 80) {
      items {
        ...FrequentlyAskedQuestion
      }
    }
  }

  ${frequentlyAskedQuestion}
  ${asset}
  ${link}
  # Data
`;

export default query;
