import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import trans from "counterpart";

import {
  resourceInReservationSelector,
  reservationSelector,
} from "../selectors";
import { getResorts } from "../../../selectors/entitySelectors";

const AccommodationInfo = (props) => {
  const { reservation, accommodation, manageTags } = props;
  if (!accommodation) return null;

  const resortName = reservation.resort || accommodation.resort.name;

  const accommodationName = accommodation.display_name
    ? accommodation.display_name
    : accommodation.name;

  return (
    <div className="row" {...manageTags}>
      <div className="col-xs-12">
        <div className="mb-2">
          <h1>
            <span>{`${accommodationName} `}</span>
            <span className="small">
              {`${trans("label.at")} ${resortName}`}
            </span>
          </h1>
        </div>
      </div>
    </div>
  );
};

AccommodationInfo.propTypes = {
  reservation: PropTypes.object,
  accommodation: PropTypes.object,
};

const mapStateToProps = (state) => ({
  accommodation: resourceInReservationSelector(state),
  resorts: getResorts(state),
  reservation: reservationSelector(state),
});

export default connect(mapStateToProps)(AccommodationInfo);
