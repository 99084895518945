import styled from "styled-components";

export const Media = styled.div`
  position: relative;
  height: 170px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.neutralLight};

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const Content = styled.div`
  position: relative;
  background: white;
  padding: ${({ theme }) => theme.padding.md};
  flex: 1;

  & > h1,
  & > h2,
  & > h3 {
    margin-bottom: 0.1rem;
  }
`;

export const ActionArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  user-select: text;
  overflow: hidden;

  & a {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  ${Media} img {
    transform: scale(1);
    transition: transform ease-in-out 0.2s;
  }

  &:hover {
    text-decoration: none;

    ${Media} img {
      transform: scale(1.1);
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: ${({ theme }) => theme.padding.md};
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
`;
