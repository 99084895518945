import trans from "counterpart";
import moment from "moment";
import { INTERNAL_DATE } from "../../constants/dates";
import { KIND_CAMPING, KIND_HOTEL } from "../../constants/filters";
import {
  ID_HOLIDAYPARK,
  ID_SAFARIRESORT,
  ID_SAFARIRESORT_HOTEL,
} from "./constants";

export const calculateDepartureDate = (arrival, duration) => {
  const date = new Date(arrival);
  date.setDate(date.getDate() + parseInt(duration, 10));
  // Add leading zeros if neccessary
  const pad = (n) => (n < 10 ? `0${n}` : n);
  return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(
    date.getUTCDate()
  )}`;
};

export const getNights = (arrival, departure) =>
  moment(departure).diff(moment(arrival), "days");

/**
 * @param {Object} results Object of results
 * @param {Array} availableNights Array of numbers where a number is the number of nights a stay spans
 */
export const findAvailableNight = (results, availableNights) => {
  if (results[availableNights[0]]) return availableNights[0];
  availableNights.shift();
  if (availableNights.length > 0)
    return findAvailableNight(results, availableNights);
  return null;
};

export const findNextDate = (arrivals, currentDate) => {
  const currentIndex = arrivals.findIndex((date) => date === currentDate);
  const hasNextIndex = currentIndex + 1 < arrivals.length;
  if (hasNextIndex) {
    return arrivals[currentIndex + 1];
  }
  return false;
};

export const calculateNextValidDate = (arrivals, nextDate) => {
  if (!nextDate || !arrivals) return null;
  // Reverse the arrival dates so that we traverse the future dates first
  const arrivalsDesc = arrivals.reverse();
  // Find the closest availabe date
  const closestArrivalDate = arrivalsDesc.reduce(
    (closestDate, arrival) => {
      const arrivalDate = moment(arrival);
      const compareDate = moment(nextDate);
      const differenceInDays = moment
        .duration(arrivalDate.diff(compareDate))
        .abs()
        .asDays();

      // New closest date found
      if (differenceInDays < closestDate.difference) {
        return {
          arrival,
          difference: differenceInDays,
        };
      }

      return closestDate;
    },
    { arrivalDate: nextDate, difference: Number.POSITIVE_INFINITY }
  );

  return closestArrivalDate.arrival;
};

export const getResortVariation = (resort) => {
  switch (resort) {
    case ID_SAFARIRESORT:
      return {
        variant: "safariResort",
        label: trans("resort.safari_resort"),
      };
    case ID_HOLIDAYPARK:
      return {
        variant: "lakeResort",
        label: trans("resort.lake_resort"),
      };
    case ID_SAFARIRESORT_HOTEL:
      return {
        variant: "safariHotel",
        label: trans("resort.safari_hotel"),
      };
    default: {
      return null;
    }
  }
};

/**
 * @returns minimum same day booking hours. For example
 * campingspots can be booked until 22:00.
 */
const getMaximumBookableHour = (accommdoationKindId) => {
  switch (accommdoationKindId) {
    case KIND_CAMPING:
      return 19;
    case KIND_HOTEL:
      return 14;
    default:
      return 12;
  }
};

/**
 * Check if it is still possible to book accommodation for Today. This could differ per accommodation type
 * and the time the booking is made.
 *
 * For some accommodations it's possible to make a booking until 22:00h.
 */
export const isBookableOnArrivalDate = (
  now,
  arrivalDate,
  accommdoationKindId
) => {
  const bookableUntilHour = getMaximumBookableHour(accommdoationKindId);
  const isArivalDateToday = now.format(INTERNAL_DATE) === arrivalDate;
  const isBeforeMaximumBookableHour = now.hours() < bookableUntilHour;

  if (!isArivalDateToday) return true;
  return isBeforeMaximumBookableHour;
};
