import { gql } from "@apollo/client";

const fragment = gql`
  fragment Iframe on SectionIframe {
    sys {
      id
    }
    title
    url
    width
    height
  }
`;

export default fragment;
