import React from "react";

const NavContext = React.createContext({
  active: null,
  setActive: () => {},
  mobileMenuIsOpen: false,
  closeNavbar: () => {},
});

/**
 * A simple higher order component that maps the context data onto the the component's props.
 *
 * @param {any} Component
 */
export const withActiveLink = (Component) => (props) =>
  (
    <NavContext.Consumer>
      {({ active, setActive, mobileMenuIsOpen, closeNavbar }) => (
        <Component
          activeLink={active}
          setActiveLink={setActive}
          mobileMenuIsOpen={mobileMenuIsOpen}
          closeNavbar={closeNavbar}
          {...props}
        />
      )}
    </NavContext.Consumer>
  );

export default NavContext;
