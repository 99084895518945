import React, { Component } from 'react'
import PropTypes from 'prop-types'
import trans from 'counterpart'
import { connect } from 'react-redux'
import { emitLinkClick } from '../../redux/actions'
import RichText from '../../modules/content/components/RichText'
import { initializeMap } from '../../legacy/interactive-map'

class HtmlBlock extends Component {
  state = {
    collapsed: true
  }

  componentDidMount = () => {
    const { value } = this.props
    if (this.html) this.html.addEventListener('click', this.handleClick)

    // Note; Really dirty hack to initialize the legacy jquery map on mount
    if (typeof window !== 'undefined' && value && value.includes('map-2017.jpg')) {
      initializeMap()
    }
  }

  componentWillUnmount = () => {
    if (this.html) this.html.removeEventListener('click', this.handleClick)
  }

  toggleCollapse = () => this.setState(prevState => ({ collapsed: !prevState.collapsed }));

  /**
   * Necessary to emit click events through redux for showing modals and the likes :)
   */
  handleClick = (e) => {
    if (e && e.target.nodeName === 'A' && (e.target.getAttribute('href') === undefined || e.target.getAttribute('href') === '#')) {
      e.preventDefault()
      this.props.emitLinkClick(this.props.block, e.target.getAttribute('id'))
    }
  }

  render = () => {
    const { properties, id, manageTags, value } = this.props

    if (properties && properties.template === 'collapse') {
      return (
        <>
          <div
            id={`paragraph-collapse-${id}`}
            className={`paragraph paragraph-sm ${properties.extra_classes} ${this.state.collapsed ? '' : 'in'}`}
            {...manageTags}
          >
            <RichText value={value} />
          </div>
          <button
            type='button'
            className={`btn btn-link btn-more ${this.state.collapsed ? 'collapsed' : ''}`}
            onClick={this.toggleCollapse}
            // TODO: remove this inline style and fix the way it's done in production
            style={{ width: '100%' }}
          >
            <span className='material-icons'>&#xE315;</span>
            {/* TODO: Fix font-weight and size here (see prod) */}
            <span className='read-more'>{trans('label.read_more')}</span>
            <span className='read-less'>{trans('label.read_less')}</span>
          </button>
        </>
      )
    }

    return (
      <div
        ref={(c) => { this.html = c }}
        // NOTE: It seems like this ID is not added to the props of the div while rendered on
        // the server side.
        id={properties.id}
        className={properties.extra_classes}
        {...manageTags}
      >
        <RichText value={value} />
      </div>
    )
  }
}

HtmlBlock.propTypes = {
  block: PropTypes.object,
  emitLinkClick: PropTypes.func.isRequired,
  id: PropTypes.number,
  manageTags: PropTypes.object,
  properties: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.string
}

export default connect(
  null,
  dispatch => ({
    emitLinkClick: (block, targetId) => dispatch(emitLinkClick(block, targetId))
  })
)(HtmlBlock)
