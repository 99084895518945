import { getWithExpiry } from "./localStorage";

const deleteVoucherCode = () => {
  localStorage.removeItem("voucher");
};

const clearExpiredStorage = () => {
  let eachitem, eachkey;

  for (let i = 0; i < localStorage.length; i++) {
    eachitem = localStorage.getItem(localStorage.key(i));
    eachkey = localStorage.key(i);

    if (eachitem.includes("expiry")) {
      // get will remove expired keys
      getWithExpiry(eachkey);
    }
  }
};

export default {
  deleteVoucherCode,
  clearExpiredStorage,
};
