import React, { Component } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'
import trans from 'counterpart'
import { setSubjectQuantity } from '../../../preferences/actions'
import { getSubjectsWithQuantity } from '../../../preferences/selectors'
import { hasAdults } from '../../../../selectors/preferenceSelectors'

const MAX_SUBJECTS = {
  pet: 1
}
class SubjectPicker extends Component {
  state = {
    dropdown: false
  }

  debounced = debounce((newSubjects, subjectId, quantity) => {
    return this.props.onChange({ ...newSubjects, [subjectId]: quantity })
  }, 500, { trailing: true })

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.picker && this.state.dropdown && !this.picker.contains(event.target)) {
      this.toggle()
    }
  }

  toggle = () =>
    this.setState((prevState) => ({
      dropdown: !prevState.dropdown
    }))

  changeNumberOfSubjects = (subjectId, operator) => {
    const { subjects, changeQuantity } = this.props

    const subject = subjects.find((s) => s.id === subjectId)
    if (operator === 'plus') {
      subject.quantity += 1
    } else if (subject.quantity !== 0) {
      subject.quantity -= 1
    } else {
      return ''
    }

    changeQuantity(subjectId, subject.quantity)

    // Map objects to preference format
    const newSubjects = subjects.reduce(
      (newSubjects, subject) => ({
        ...newSubjects,
        [subject.id]: subject.quantity
      }),
      {}
    )

    return this.debounced(newSubjects, subjectId, subject.quantity)
  }

  capitalize = (str) => str.charAt(0).toUpperCase() + str.substring(1)

  getLabel = () => {
    const { subjects } = this.props

    const selectedSubjects = subjects.filter((subject) => subject.quantity > 0)
    if (selectedSubjects.length === 0) {
      return trans('label.travel_company_edit')
    }
    const label = selectedSubjects
      .sort((a, b) => {
        if (a.type === 'pet') return 1
        if (a.min_age > b.min_age) return -1
        if (a.min_age < b.min_age) return 1
        return 0
      })
      .map((subject, index) => {
        const isPlural = subject.quantity > 1
        return `${index === 0 ? '' : ' '}${subject.quantity} ${
          isPlural ? this.capitalize(subject.plural_name || subject.singular_name) : this.capitalize(subject.singular_name || subject.singular_name)
        }`
      })
      .join()

    if (label.length > 25) {
      return `${label.substring(0, 24)}...`
    }

    return label
  }

  render = () => {
    const { petsAllowed, subjects } = this.props

    return (
      <div
        className={`travel-company form-dropdown form-subjects dropdown ${this.state.dropdown ? 'show' : ''}`}
        ref={(ref) => {
          this.picker = ref
        }}
      >
        <div className='dropdown-toggle' onClick={this.toggle}>
          <div className='dropdown-filter'>
            <div className='dropdown-heading'>{trans('label.travel_company')}</div>
            <div className='dropdown-value'>
              <span className='text-company'>{this.getLabel()}</span>
            </div>
          </div>
        </div>
        {this.state.dropdown && subjects.length === 0 && (
          <ul className='dropdown-menu dropdown-subject dropdown-travel-company mb-4' aria-labelledby='form_pax'>
            Loading
          </ul>
        )}
        {this.state.dropdown && subjects.length > 0 && (
          <ul className='dropdown-menu dropdown-subject dropdown-travel-company mb-4' aria-labelledby='form_pax'>
            <li className='dropdown-label'>{trans('travel_company.heading')}</li>
            <li role='separator' className='divider' />
            {!this.props.hasAdults && <div className='alert alert-warning'>{trans('travel_company.minimum')}</div>}
            {subjects
              .filter((subject) => subject.type !== 'pet' || petsAllowed)
              .map((subject) => (
                <li key={subject.id} className='dropdown-input'>
                  <span className='dropdown-input-label'>{subject.name}</span>
                  <div className='input-group'>
                    <span className='input-group-btn'>
                      <button type='button' className='btn btn-default' onClick={() => this.changeNumberOfSubjects(subject.id, 'minus')} disabled={subject.quantity === 0}>
                        <span>-</span>
                      </button>
                    </span>
                    <input className='form-control form-pax-input' type='text' min='0' max='10' readOnly='readOnly' style={{ backgroundColor: '#fff' }} value={subject.quantity} />
                    <span className='input-group-btn'>
                      <button type='button' className='btn btn-default' onClick={() => this.changeNumberOfSubjects(subject.id, 'plus')} disabled={subject.quantity === MAX_SUBJECTS[subject.type]}>
                        <span>+</span>
                      </button>
                    </span>
                  </div>
                </li>
              ))}
          </ul>
        )}
      </div>
    )
  }
}

SubjectPicker.defaultProps = {
  onChange: () => {},
  petsAllowed: true
}

SubjectPicker.propTypes = {
  changeQuantity: PropTypes.func,
  onChange: PropTypes.func,
  petsAllowed: PropTypes.bool,
  subjects: PropTypes.array,
  hasAdults: PropTypes.bool
}

const mapStateToProps = (state) => ({
  subjects: getSubjectsWithQuantity(state),
  hasAdults: hasAdults(state)
})

const mapDispatchToProps = (dispatch) => ({
  changeQuantity: (id, quantity) => dispatch(setSubjectQuantity(id, quantity))
})

export default connect(mapStateToProps, mapDispatchToProps)(SubjectPicker)
