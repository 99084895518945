import { FC } from "react";
import * as React from "react";
import styled from "styled-components";

const Fieldset = styled.fieldset``;

export const Legend = styled.legend`
  font-size: 1.25rem;
  line-height: 1;
  margin-bottom: 12px;
  font-weight: 500;
`;

type Props = {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
};

const FilterGroup: FC<Props> = ({ className, children }) => {
  return <Fieldset className={className}>{children}</Fieldset>;
};

export default FilterGroup;
