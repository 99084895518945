import React from "react";
import trans from "counterpart";
import { connect } from "react-redux";
import { postMaileonForm } from "../../utilities/api/formApi";

class YourzineShoppingBasketBlock extends React.Component {
  state = {
    email: "",
    success: null,
    error: null,
    locale: "nl",
  };

  formatData = (email, url) => {
    const resort = this.getResort(url);

    const data = {
      type: "5",
      "import-contact-email": email,
      "import-contact-permission": "1",
      "content-Label": process.env.NEXT_PUBLIC_MAILEON_LABEL,
      "content-Resort": resort,
      "content-Url": url,
      "content-Language": this.props.locale,
    };

    return data;
  };

  getResort = (url) => {
    const urlParams = new URLSearchParams(url);
    const resort = urlParams.get("resort") || "";
    return resort;
  };

  handleSubmit = async () => {
    const { email } = this.state;
    this.setState({ error: null });

    if (email.match("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$")) {
      const data = this.formatData(email, window.location.href);
      try {
        const response = await postMaileonForm(data);
        this.setState({ success: true });
        return response;
      } catch (error) {
        this.setState({ error: "save_booking_failure" });
      }
    } else {
      this.setState({ error: "email_invalid" });
    }
  };

  render() {
    const { email, error, success } = this.state;

    return (
      <div className="save-booking">
        <div className="h5 text-info text-lato mb-1">
          {trans("label.save_booking_title")}
        </div>
        <div className="body">{trans("label.save_booking_body")}</div>
        <input
          type="email"
          className="form-control input"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          value={email}
          onChange={(e) => this.setState({ email: e.target.value })}
        />
        <button
          type="button"
          onClick={this.handleSubmit}
          className={`"btn btn-green" ${
            success === null ? "btn btn-green more" : "btn btn-green less"
          }`}
        >
          {trans("label.save_booking_button")}
        </button>
        {success && (
          <div className="alert alert-success">
            {trans("label.save_booking_success")}
          </div>
        )}
        {error !== null && (
          <div className="alert alert-danger">{trans(`error.${error}`)}</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.preferences.locale,
});

export default connect(mapStateToProps)(YourzineShoppingBasketBlock);
