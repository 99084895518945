import {
  fetchPrograms,
  fetchLocales,
  algoliaClient,
} from "@libema/content-sdk";

const syncPrograms = async (brand: string, locale: string): Promise<any> => {
  const programs = await fetchPrograms(locale);

  const indexName = `${brand}_program_${locale}`;
  const algoliaIndex = algoliaClient.initIndex(indexName);

  const programObjects = programs.map((program) => ({
    ...program,
    objectID: program.id,
  }));

  return await algoliaIndex.saveObjects(programObjects);
};

// TODO Protect with basic http auth
export const handler = (brand: string) => async (req, res) => {
  try {
    const result = {};
    const locales = await fetchLocales();

    for (const locale of locales) {
      result[locale.code] = await syncPrograms(brand, locale.code);
    }

    return res.status(200).json(result);
  } catch (error) {
    return res.status(500).json({ message: error.message });
  }
};
