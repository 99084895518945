import styled from "styled-components/macro";

// Components
import DefaultLink from "../typography/Link";

export const Link = styled(DefaultLink)`
  display: block;
  font-weight: 600;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.color.grey_800};
  margin-bottom: 8px;
  padding: 0;
`;

export const SocialMedia = styled.section`
  display: flex;
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 0 8px !important;

  // overwrite inline styles of SafeWebpImage
  > span {
    position: static !important;
    width: 100%;

    img {
      position: static !important;
      height: auto !important;
    }
  }
`;

const Footer = styled.footer``;

export default Footer;
