import React from 'react'
import { connect } from 'react-redux'
import trans from 'counterpart'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import Scrollchor from 'react-scrollchor'
import ComparableResources from './ComparableResources'
import { fetchAccommodationTypes, fetchPropertyGroups } from '../../booking/actions'
import { getPreferences } from '../../preferences/selectors'
import { returnResourcesAfterRemoval, resetCompareList, mapResourcesToState } from '../actions'
import { MAX_COMPARE } from '../constants'
import { getAccommodationsById } from '../selectors'
import { getAccommodationTypes } from '../../../selectors/entitySelectors'
import AccommodationType from './AccommodationType'
import { fetchOffers } from '../../../actions/preferenceActions'
import { APP_DIERENBOS } from '../../../constants/apps'

const isIE11 = typeof navigator !== 'undefined' ? (navigator.appVersion.indexOf('Trident/') > -1 || navigator.userAgent.indexOf('Edge') > -1) : false

/**
 * TO DO:
 * There is still an issue with calls using the date of today and not the closest day with offers.
 * Does work if there is a hardcoded date for now.
 */
const CompareResourceBlock = ({
  block,
  // TODO: Keep compare id's in react state
  compareListIds,
  fetchOffers,
  getResourceIdsFromUrl,
  hasMaxCompare,
  initialize,
  removeFromCompareList,
  resetCompareList,
  selectedAccommodations
}) => {
  const router = useRouter()
  const [results, setResults] = React.useState({})
  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    initialize()

    // Clean up the compare data on unmount
    return () => resetCompareList()
  }, [])

  React.useEffect(() => {
    if (router.query) {
      const resourcesToCompare = typeof router.query['resources[]'] !== 'undefined'
        ? Array.isArray(router.query['resources[]']) ? router.query['resources[]'] : [router.query['resources[]']]
        : []
      getResourceIdsFromUrl(resourcesToCompare)
      fetchOffers({ resources: resourcesToCompare })
        .then((data) => {
          if (!data) return null
          setResults(data.results.reduce((a, c) => ({ ...a, [c.resource_id]: c }), {}))
          setInitialized(true)
        })
    }
  }, [router])

  const removeFromCompareResources = (id) => {
    const newResources = removeFromCompareList(id)
    router.push({
      pathname: `/${router.query.slug.join('/')}`,
      query: { 'resources[]': newResources }
    }, undefined, { shallow: true })
  }

  if (!initialized) return <div>Loading...</div>

  return (
    <div>
      <div id='compared-resources' className={`row compare-resources ${isIE11 ? 'isIE' : ''}`}>
        <div className='col px-0 loading-block loading-ts transparent'>
          <div className='results grid grid-compare container mt-4'>
            <div className='row'>
              {Object.values(selectedAccommodations).map(accommodationType => (
                <AccommodationType
                  key={accommodationType.id}
                  accommodationType={accommodationType}
                  result={results[accommodationType.id]}
                  remove={removeFromCompareResources}
                  properties={block.properties.properties}
                />
              ))}
              {compareListIds.length < MAX_COMPARE && (
                <article className='result card card-white card-add thumbnail-add'>
                  <div className='result-left'>
                    <h4 className={`${process.env.NEXT_PUBLIC_APP === APP_DIERENBOS && 'text-black'} mb-3`}>{trans('label.compare_more')}</h4>
                    <div className='card-block bg-beige-light'>
                      <Scrollchor to='comparable-resources'>
                        <span className='btn-add'>
                          {trans('label.add_accommodation')}
                          <br />
                          <span className='material-icons'>add_box</span>
                        </span>
                      </Scrollchor>
                    </div>
                  </div>
                </article>
              )}
            </div>
          </div>
        </div>
      </div>
      <ComparableResources />
      {hasMaxCompare && (
        <div className='alert alert-warning alert-compare'>
          <div className='container'>
            {`${trans('newyse.compare.max')}.`}
          </div>
        </div>
      )}
    </div>
  )
}

CompareResourceBlock.propTypes = {
  block: PropTypes.object,
  compareListIds: PropTypes.array,
  fetchOffers: PropTypes.func,
  getResourceIdsFromUrl: PropTypes.func,
  hasMaxCompare: PropTypes.bool,
  initialize: PropTypes.func,
  removeFromCompareList: PropTypes.func,
  resetCompareList: PropTypes.func,
  selectedAccommodations: PropTypes.object,
}

const mapStateToProps = state => ({
  accommodationTypes: getAccommodationTypes(state),
  compareListIds: state.compare.compareListIds,
  hasMaxCompare: state.compare.hasMaxCompare,
  hasIdInList: state.compare.hasIdInList,
  preferences: getPreferences(state),
  selectedAccommodations: getAccommodationsById(state)
})

const mapDispatchToProps = dispatch => ({
  fetchOffers: (params) => dispatch(fetchOffers(params)),
  initialize: () => Promise.all([
    dispatch(fetchAccommodationTypes()),
    dispatch(fetchPropertyGroups())
  ]),
  removeFromCompareList: id => dispatch(returnResourcesAfterRemoval(id)),
  resetCompareList: () => dispatch(resetCompareList()),
  getResourceIdsFromUrl: resources => dispatch(mapResourcesToState(resources))
})

export default connect(mapStateToProps, mapDispatchToProps)(CompareResourceBlock)
