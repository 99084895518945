import React from 'react'
import PropTypes from 'prop-types'
import { Popover as BootstrapPopover, PopoverBody, PopoverHeader } from 'reactstrap'

class Popover extends React.Component {
  state = {
    showPopover: false
  }

  handleButtonClick = () => this.setState(prevState => ({ showPopover: !prevState.showPopover }))

  handleMouseEnter = () => {
    if (this.props.hover) this.setState({ showPopover: true })
  }

  handleMouseLeave = () => {
    if (this.props.hover) this.setState({ showPopover: false })
  }

  render = () => (
    <>
      <span
        id={`popover-${this.props.id}`}
        className='material-icons md-18'
        onMouseLeave={this.handleMouseLeave}
        onMouseEnter={this.handleMouseEnter}
        onClick={this.handleButtonClick}
        role='button'
      >
        {this.props.icon || 'help'}
      </span>
      {this.props.description && (
        <span
          onClick={this.handleButtonClick}
          onMouseLeave={this.handleMouseLeave}
          onMouseEnter={this.handleMouseEnter}
        >
          {this.props.description}
        </span>
      )}
      <BootstrapPopover
        placement='top'
        isOpen={this.state.showPopover}
        toggle={() => this.handleButtonClick()}
        target={`popover-${this.props.id}`}
        trigger='legacy'
      >
        <PopoverHeader>
          <div className='popover-close' onClick={this.handleButtonClick}>
            <i className='material-icons'>&#xE5CD;</i>
          </div>
        </PopoverHeader>
        <PopoverBody>
          {this.props.children}
        </PopoverBody>
      </BootstrapPopover>
    </>
  );
}

Popover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.string,
  hover: PropTypes.bool,
  icon: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Popover
