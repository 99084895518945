import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Link from "../typography/Link";
import { getContent } from "../../modules/content/actions";
import Image from "../../modules/media/components/Image";
import { shouldRender } from "../../utilities/blockUtilities";
import RichText from "../../modules/content/components/RichText";

class CardBlock extends Component {
  componentDidMount = () => {
    if (
      this.props.properties &&
      this.props.properties.content &&
      !this.props.content
    ) {
      this.props.loadContent(this.props.properties.content);
    }
  };

  getClientWidth = () =>
    typeof document !== "undefined"
      ? document.documentElement.clientWidth
      : 768;

  render = () => {
    const {
      block,
      content,
      header,
      value,
      media,
      properties,
      sort,
      children,
      placeholder,
      manageTags,
    } = this.props;

    if (shouldRender(block) === false) {
      return null;
    }

    // BC fixes due to duplicate Card Blocks...
    const style = properties.styles ? properties.styles.join(" ") : "";
    // const imagePreset = properties.preset ? properties.preset : '';

    const vpWidth = Math.max(
      this.getClientWidth(),
      typeof window !== "undefined" ? window.innerWidth : 0
    );

    let title;
    let body;
    let image;
    let cta;
    let link;
    let slug;
    let imgSrc;
    let imgSrcSm;
    let imgSrc2x;
    let btnColor;

    if (properties.preset && properties.preset.indexOf("card-pano") > -1) {
      imgSrcSm = "md_41";
      imgSrc = "lg_41";
      imgSrc2x = "lg_41";
    } else if (
      properties.preset &&
      properties.preset.indexOf("card-portrait") > -1
    ) {
      imgSrcSm = "sm_43";
      imgSrc = "md_43";
      imgSrc2x = "md_43";
    } else if (properties.preset && properties.preset === "card-landscape") {
      imgSrcSm = "sm_42";
      imgSrc = "md_42";
      imgSrc2x = "lg_42";
      // We need to check if window is defined here, otherwise we get incorrect sizes on server-side rendered pages.
    } else if (typeof window !== "undefined" && vpWidth <= 768) {
      imgSrcSm = "sm_43";
      imgSrc = "md_43";
      imgSrc2x = "md_43";
    } else {
      imgSrcSm = "sm_11";
      imgSrc = "md_11";
      imgSrc2x = "lg_11";
    }

    if (
      properties.styles &&
      ["yellow", "beige-sand", "taupe-light", "white"].indexOf(
        properties.styles[0]
      ) !== -1
    ) {
      btnColor = "btn-black";
    } else {
      btnColor = "btn-white";
    }

    if (properties.content) {
      if (content) {
        // Apparently API endpoints differ in the way the short title is returned. short_title is the desired way.
        title = content.short_title || content.shortTitle;
        body = content.attributes.list_description;
        cta = content.attributes.call_to_action
          ? content.attributes.call_to_action
          : "";
        link = content.attributes.button_text || "";
        slug = content.slug ? content.slug : "";

        if (
          content.attributes.list_image &&
          content.attributes.list_image.length > 0
        ) {
          ({
            attributes: {
              list_image: [image],
            },
          } = content);
        } else if (content.medias) {
          ({
            medias: [image],
          } = content);
        }
      }
    } else {
      // BC fix due to duplicate Card Blocks...
      title = header || properties.title;
      body = value || properties.body;
      if (media) {
        image = media;
      }
    }

    return (
      <div
        className={`card card-${style || "default"} carousel-item ${
          properties.extra_classes ? properties.extra_classes : ""
        } ${sort === 0 ? "active" : ""}`}
        {...manageTags}
      >
        {properties.preset !== "card-auto" && (
          <div className="card-media">
            {image && image.reference && (
              <Image
                file={image.reference}
                filter={[imgSrcSm, imgSrc, imgSrc2x]}
                attr={{
                  className: "img-fluid",
                  alt: image.name,
                }}
              />
            )}
          </div>
        )}

        {(properties.preset === "card-auto" ||
          properties.preset === "card-arrangement") &&
        children.length > 0 ? (
          <div className="card-block">
            <div>{placeholder(0)}</div>
          </div>
        ) : (
          <div className="card-block">
            {properties.content ? (
              <span className="card-header">
                <h4 dangerouslySetInnerHTML={{ __html: title }} />
              </span>
            ) : (
              <span
                className="card-header"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            <RichText className="card-text" value={body} />
            {cta && (
              <Link className={`btn ${btnColor}`} to={`/${slug}`}>
                {cta}
              </Link>
            )}
            {link && (
              <Link className={`btn ${btnColor}`} to={`/${slug}`}>
                {link}
              </Link>
            )}
          </div>
        )}
      </div>
    );
  };
}

CardBlock.propTypes = {
  block: PropTypes.object.isRequired,
  children: PropTypes.any,
  content: PropTypes.object,
  header: PropTypes.string,
  loadContent: PropTypes.func,
  media: PropTypes.object,
  placeholder: PropTypes.func,
  properties: PropTypes.object,
  sort: PropTypes.number,
  value: PropTypes.string,
};

export default connect(
  (state, ownProps) => ({
    content:
      ownProps.properties &&
      ownProps.properties.content &&
      state.content[ownProps.properties.content]
        ? state.content[ownProps.properties.content]
        : null,
  }),
  (dispatch) => ({
    loadContent: (id) => dispatch(getContent(id)),
  })
)(CardBlock);
