import { FC } from "react";
import styled from "styled-components";
import { Button, DEVICE_LG_UP, Translate } from "@libema/design-system";

type Props = {
  count: number;
  onClick: () => void;
};

const StyledCloseButton = styled(Button)`
  display: block;
  width: 100%;

  @media ${DEVICE_LG_UP} {
    display: none;
  }
`;

const FilterModalCloseButton: FC<Props> = ({ count, onClick }) => (
  <StyledCloseButton size="small" type="button" onClick={onClick}>
    <Translate id="filter.buttons.close_modal" values={{ count }} />
  </StyledCloseButton>
);

export default FilterModalCloseButton;
