import * as React from "react";
import styled from "styled-components";

// Components
import { H1, P, Translate } from "@libema/design-system";
import { LinkDisplayStyle } from "@libema/content-sdk";

// Local Components
import Link from "../components/elements/Link";

const ErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  ${H1} {
    margin: 24px 0 0 0;
  }
`;

export const Page: React.FC = () => {
  return (
    <ErrorBox>
      <H1>
        <Translate id="page.500.title" />
      </H1>
      <P>
        <Translate id="page.500.description" />
      </P>
      <Link
        as={LinkDisplayStyle.BUTTON}
        href="/"
        buttonProps={{ size: "small", colorType: "secondary" }}
      >
        <Translate id="page.500.button" />
      </Link>
    </ErrorBox>
  );
};
