import TYPES from "../../../../constants/types";
import normalizeModelTypename from "../../../../utils/normalizeModelTypename";
import { normalizeMultipleLocations } from "../../sdk/normalizer";

// Utils
import {
  normalizeAsset,
  normalizeLink,
  normalizeRichText,
  normalizePlan,
  normalizeLabeledIcon,
} from "./normalize";

const normalizePriceTable = (priceTable) => {
  if (!priceTable) {
    return null;
  }

  const { pricesCollection, conditionsCollection } = priceTable;

  return {
    prices: pricesCollection?.items.map((price) => price) || [],
    conditions:
      conditionsCollection?.items.map(({ icon, condition }) => ({
        icon,
        text: condition,
      })) || [],
  };
};

const topicNormalizers = {
  [TYPES.Program]: ({ programMinAge, ...topic }) => ({
    ...topic,
    location: normalizeMultipleLocations(topic.location),
    introduction: normalizeRichText(topic.introduction),
    mainImage: normalizeAsset(topic.mainImage),
    gallery: topic.gallery.items.map(normalizeAsset) || [],
    planning: topic.planning.items.map(normalizePlan),
    minAge: normalizeLabeledIcon(programMinAge),
    activityTypes: topic.activityTypes.items.map(normalizeLabeledIcon),
  }),
  [TYPES.Activity]: (topic) => ({
    ...topic,
    cta: topic.cta && normalizeLink(topic.cta),
    images: topic.images.items.map(normalizeAsset) || [],
    description: normalizeRichText(topic.description),
    longDescription: normalizeRichText(topic.longDescription),
    categories: topic.categories.items.map((category) => {
      return {
        ...category,
        id: category.sys.id,
      };
    }),
    priceTable: normalizePriceTable(topic.priceTable) || null,
  }),
  [TYPES.Article]: (topic) => ({
    ...topic,
    publishedAt: topic.sys.publishedAt,
    authors: topic.authors.items.map((author) => {
      return {
        ...author,
        id: author.sys.id,
      };
    }),
    categories: topic.categories.items.map((category) => {
      return {
        ...category,
        id: category.sys.id,
      };
    }),
  }),
};

const normalizeTopic = (topic) => {
  if (!topic) {
    return null;
  }

  const { sys, type: defaultType } = topic;
  const type = normalizeModelTypename(defaultType);

  if (!topicNormalizers[type]) {
    throw new Error(`Topic "${type}" is unknown`);
  }

  return {
    ...topic,
    ...topicNormalizers[type](topic),
    id: sys?.id,
    type,
  };
};

export default normalizeTopic;
