// eslint-disable-next-line
// @ts-nocheck
import { useFormikContext } from "formik";
import React from "react";
import Translate from "../../typography/Translate";
import Checkbox from "../Checkbox";
import FormGroup from "../FormGroup";
import Label from "../Label";

export type CheckAllFieldProps = {
  name: string;
  values: string[];
};

const CheckAllField: React.FC<CheckAllFieldProps> = ({ name, values }) => {
  const { setFieldValue, values: formikValues } = useFormikContext();

  // Access the nested value in the formikValues object by a string in dot-notation
  const fieldValues = name
    .split(".")
    .reduce((p, c) => (p && p[c]) || null, formikValues) as string[];

  const handleChange = () => {
    // If the lengths equal, we assume that all fields are selected
    if (fieldValues?.length === values.length) {
      setFieldValue(name, []);
    } else {
      setFieldValue(name, values);
    }
  };

  return (
    <FormGroup inline>
      <Checkbox
        id={`${name}-all`}
        onChange={handleChange}
        checked={fieldValues?.length === values.length}
      />
      <Label htmlFor={`${name}-all`}>
        <Translate id="button.select_all" />
      </Label>
    </FormGroup>
  );
};

export default CheckAllField;
