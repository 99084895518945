import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import TextField from './TextField'

const TextFieldBlock = ({ block, block: { properties } }) => (
  <Field
    name={block.name}
    component={TextField}
    placeholder={properties.label}
    helpText={properties.helpText}
  />
)

TextFieldBlock.propTypes = {
  block: PropTypes.object
}

export default TextFieldBlock
