import { normalize, schema } from "normalizr";
import * as actions from "../redux/actions";
import * as common from "../utilities/common";
import { get } from "../utilities/api";
import {
  manageModeSelector,
  manageTypeSelector,
} from "../selectors/environmentSelectors";

export const blockSchema = new schema.Entity("blocks");
blockSchema.define({
  children: [blockSchema],
});
export const blockSchemaChildren = blockSchema;

export function postLoadContent(response) {
  return (dispatch) => {
    const normalized = normalize(response, { blocks: [blockSchema] });

    dispatch(actions.setBlocks(response.id, normalized));
    const formSections = common
      .objectToArray(normalized.entities.blocks)
      .filter((b) => b.type === "FormSectionBlock")
      .sort((a, b) => a.sort - b.sort);

    if (formSections && formSections.length) {
      dispatch(
        actions.setInitialFormUIState(
          response.id,
          formSections,
          formSections[0].id
        )
      );
    }

    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
    dispatch(actions.postLoadContent(response.id));
  };
}

export const getBlocks =
  (content, cb, preview = false) =>
  (dispatch, getState) => {
    let version = "";
    if (manageModeSelector(getState()) === "manage" || preview) {
      // In manage mode, add a version number to retrieve the draft page
      version = `?_version=${new Date().getTime()}`;
    }

    // Manage type can be either `template` or `content`
    let manageType = manageTypeSelector(getState()) || "content";
    if (manageType === "template") manageType = "/templates";

    return get({ url: `${manageType}/${content}${version}` })
      .then(({ data }) => {
        dispatch(postLoadContent(data));
        if (cb) cb(data);
        return data;
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.slug === "404"
        ) {
          dispatch(postLoadContent(error.response.data));

          if (cb) cb(error.response.data);
        }

        throw error;
      });
  };

export const getHeaderFooterBlocks = (locale) => (dispatch) => {
  return get({ url: `content/${locale === "nl" ? "index" : locale}` })
    .then(({ data }) => {
      dispatch(postLoadContent(data));
      return data;
    })
    .catch((error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.slug === "404"
      ) {
        dispatch(postLoadContent(error.response.data));
      }

      throw error;
    });
};
