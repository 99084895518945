import styled from "styled-components/macro";
import { HTMLProps } from "react";

export type LabelProps = {
  noMargin?: boolean;
  required?: boolean;
} & HTMLProps<HTMLLabelElement>;

export default styled.label<LabelProps>`
  display: inline-flex;
  align-items: center;
  margin-bottom: ${({ noMargin }) => (noMargin === true ? "0" : ".5rem")};
  :after {
    content: ${({ required }) => (required ? '" *"' : "")};
  }
`;
