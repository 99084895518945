import React from 'react'
import { connect } from 'react-redux'
import { ModalCloseButton, AdditionGroup, AdditionGroupHighlighted, Modal } from '@libema/design-system-legacy'
import AdditionGroupForm from './AdditionGroupForm'
import { getSrc } from '../../utilities/mediaUtilities'
import { getAdditionGroupHasQuantity } from '../../selectors/additionSelectors'

const AdditionGroupContainer = ({ additionGroup, hasQuantity }) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const toggleMoreInfoModal = () => setModalOpen(!modalOpen)

  return (
    <>
      {additionGroup.highlighted && (
        <AdditionGroupHighlighted
          id={additionGroup.id}
          isChecked={hasQuantity}
          title={additionGroup.short_description}
          image={additionGroup.media && getSrc(additionGroup.media.reference, 'md_42')}
          priceLabel={additionGroup.price_label}
          marketingGroups={additionGroup.marketingGroup}
          onMoreInfoClick={toggleMoreInfoModal}
        />
      )}
      {!additionGroup.highlighted && (
        <AdditionGroup
          id={additionGroup.id}
          isChecked={hasQuantity}
          title={additionGroup.name}
          description={additionGroup.short_description}
          image={additionGroup.media && getSrc(additionGroup.media.reference, 'sm_11')}
          priceLabel={additionGroup.price_label}
          marketingGroups={additionGroup.marketingGroup}
          onMoreInfoClick={toggleMoreInfoModal}
        />
      )}
      {modalOpen && (
        <Modal isOpen afterClose={toggleMoreInfoModal}>
          <ModalCloseButton />
          <AdditionGroupForm id={additionGroup.id} onSubmitted={toggleMoreInfoModal} />
        </Modal>
      )}
    </>
  )
}

const mapState = (state, { additionGroup }) => ({
  hasQuantity: getAdditionGroupHasQuantity(state, additionGroup.id)
})

export default connect(mapState)(AdditionGroupContainer)
