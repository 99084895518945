import React from 'react'
import PropTypes from 'prop-types'
import { Modal as ReactStrapModal } from 'reactstrap'

const Modal = ({ children, className, isOpen, toggle }) => (
  <ReactStrapModal
    isOpen={isOpen}
    toggle={toggle}
    className={`modal-cs ${className}`}
  >
    <button type='button' className='btn btn-absolute btn-square text-center text-white' onClick={toggle}>
      <span className='material-icons'>close</span>
    </button>
    {children}
  </ReactStrapModal>
)

Modal.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
}

export default Modal
