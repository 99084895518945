import React, { } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import HelpText from '../../../components/HelpText'
import { groupValidateClasses, inputValidateClasses } from '../utilities'

const TextareaField = (props) => {
  const { input, label, placeholder, required, helpText, meta: { touched, error } } = props

  const className = classnames(label ? 'col-lg-8' : 'col-lg-12', {
    'has-help-text': !!helpText
  })

  return (
    <div className={`${groupValidateClasses(touched, error)} row`}>
      {label && (
        <label className={`control-label col-lg-4${required ? ' required' : ''}`}>{label}</label>
      )}
      <div className={className}>
        <textarea
          id={input.name}
          {...input}
          className={inputValidateClasses(touched, error)}
          placeholder={placeholder}
          rows={3}
        />
        {touched && error && <div className='form-control-feedback'>{error}</div>}
        <HelpText value={helpText} />
      </div>
    </div>
  )
}

TextareaField.propTypes = {
  helpText: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool
}

export default TextareaField
