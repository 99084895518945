export default {
  // Buttons
  "button.add": "Hinzufügen",
  "button.apply": "Anwenden",
  "button.automatic_redirect":
    "Bitte hier klicken, wenn Sie nicht klarna weitergeleitet werden.",
  "button.checkout_next": "Bestellung fortsetzen",
  "button.disclaimer": "Disclaimer",
  "button.download_tickets": "Tickets herunterladen",
  "button.modify": "Ändern",
  "button.more_info": "Mehr Info",
  "button.order_edit": "Bestellung ändern",
  "button.order_new": "Neue Bestellung",
  "button.park_regulations": "Park-Richtlinien",
  "button.payment_submit": "Abschließen",
  "button.privacy_statement": "Datenschutzerklärung",
  "button.remove": "Entfernen",
  "button.select_all": "Alle wählen",
  "button.select_days":
    "{count, plural, =0 {Tage auswählen} =1 {1 Tag ausgewählt} other {# Tage ausgewählt}}",
  "button.to_products": "Zu den Tickets",
  "button.voucher_redeem": "Kontrollieren",
  "button.voucher_additional": "Noch ein Code",

  // Errors
  "error.checkout_invalid":
    "Ihre Bestellung ist noch nicht vollständig. Ändern Sie Ihre Bestellung und bestellen Sie erneut.",
  "error.max_tickets_reached":
    "Sie haben die maximale Anzahl von Tickets pro Person erreicht.",
  "error.max_timeframe_reached":
    "Die maximale Anzahl von Tickets für dieses Zeitfenster ist erreicht.",
  "error.order_confirmation_failed":
    "Bei der Auftragsbestätigung ist ein Fehler aufgetreten",
  "error.paymentmethods_fetch_failed":
    "Beim Abrufen der Zahlungsmethoden ist ein Fehler aufgetreten.",
  "error.products_fetch_failed":
    "Beim Abrufen der Produkte ist ein Fehler aufgetreten. Versuchen Sie es erneut oder kontaktieren Sie uns unter {phoneNumber}",
  "error.voucher_already_exists":
    "Sie haben diesen Gutscheincode schon eingegeben",
  "error.voucher_redeem_failed": "Leider ist dieser Gutscheincode ungültig",
  "error.voucher_validation_failed":
    "Bei der Validierung Ihres Gutscheincodes ist ein Fehler aufgetreten",
  "error.voucher_maximum_vouchers_reached":
    "Sie haben die maximale Anzahl von Gutscheincodes erreicht",

  // Forms
  "form.customer.subtitle":
    "Wir benötigen die folgenden Informationen, um Ihre Tickets digital zu versenden",
  "form.customer.title": "Persönliche Informationen",

  // Form help
  "help.email":
    "Ihre Tickets werden an diese E-Mail-Adresse gesendet. Nicht erhalten, überprüfen Sie Ihre Junk-Mail.",
  "help.phone":
    "Nachdem die Richtlinien des RIVM (Dutch National Institute for Public Health) verschärft sind, fragen wir nach Ihrer Telefonnummer, um eine mögliche Quellen- und Kontaktuntersuchung durchzuführen. Es versteht sich von selbst, dass die Daten nicht für andere Zwecke verwendet werden.",

  // Form labels
  "label.agree_with": "Sie müssen einverstanden sein mit",
  "label.almost_sold_out": "Fast voll",
  "label.and": "und",
  "label.choose_quantity": "Menge wählen",
  "label.closed": "Geschlossen",
  "label.email": "E-Mail-Adresse",
  "label.email_verify": "E-Mail-Adresse (überprüfung)",
  "label.fields_required": "* Diese Felder sind Pflichtfelder",
  "label.first_name": "Vorname",
  "label.last_name": "Familienname",
  "label.legend": "Legende",
  "label.newsletter": "Ich möchte den Newsletter erhalten",
  "label.no": "Nein",
  "label.phone": "Telefonnummer",
  "label.park_regulations": "Park-Richtlinien",
  "label.privacy_policy": "Datenschutz",
  "label.quantity": "Menge",
  "label.select_days": "Tage auswählen",
  "label.selected_day": "Ausgewählter Tag",
  "label.sold_out": "Voll",
  "label.terms": "allgemeine Geschäftsbedingungen",
  "label.timeframe_from_util": "Von {from} & bis {until} Stunde",
  "label.today": "Heute",
  "label.voucher_code": "Gutscheincode",
  "label.yes": "Ja",

  "placeholder.voucher": "Ihr Gutscheincode",

  // Routes
  "route.checkout": "/de/checkout",
  "route.payment_failed_aquazoo": "/de-fehlermeldung",
  "route.payment_failed_beeksebergen": "/de/fehlermeldung",
  "route.payment_failed_dierenrijk": "/de-fehlermeldung",
  "route.payment_failed_speelland": "/de/fehlermeldung",
  "route.payment_failed_zooparc": "/de-fehlermeldung",
  "route.payment_success_aquazoo": "/de-vielen-dank-fur-ihre-bestellung",
  "route.payment_success_beeksebergen": "/de/vielen-dank-fur-ihre-bestellung",
  "route.payment_success_dierenrijk": "/de-vielen-dank-fur-ihre-bestellung",
  "route.payment_success_speelland": "/de/vielen-dank-fur-ihre-bestellung",
  "route.payment_success_zooparc": "/de-vielen-dank-fur-ihre-bestellung",

  // Titles
  "title.404": "Seite nicht gefunden",
  "title.404_subtitle":
    "Diese Seite existiert nicht (mehr), oder es gibt einen Tippfehler in der Adresse. Prüfen Sie, ob die Adresse richtig geschrieben ist",
  "title.additional_products": "Extras",
  "title.arrival_date": "Wählen Sie Ihr Ankunftsdatum",
  "title.checkout_redirect": "Zahlung verifizieren",
  "title.checkout_redirect_subtitle":
    "Wir verifizieren Ihre Zahlung und leiten Sie gleich auf die Website weiter.",
  "title.free": "Kostenlos",
  "title.libema_footer": "Für die schönste Zeit des Jahres, {account} von",
  "title.order_number":
    "Ihre Bestellnummer: <strong>#{orderNumber}</strong>. Verwenden Sie diese Nummer, wenn Sie mit uns Kontakt aufnehmen.",
  "title.order_verification_cancelled": "Ups, das dauert zu lange.",
  "title.order_verification_cancelled_subtitle":
    "Ihre Zahlung wurde empfangen, aber Ihre Bestellung wurde noch nicht bearbeitet. Dies dauert länger als gewöhnlich. Wenn Sie innerhalb weniger Minuten keine E-Mail erhalten, kontaktieren Sie uns bitte unter +31 (0)88-9000360.",
  "title.payment_failed": "Ups ... das hat nicht funktioniert!",
  "title.payment_failed_subtitle":
    "Leider hat etwas nicht funktioniert. Versuchen Sie es noch einmal! Brauchen Sie Hilfe? Rufen Sie uns an unter +31 (0)88-9000360 (Mo - Fr bis 18.00 Uhr, Sa - So bis 17.00 Uhr).",
  "title.payment_method": "Wählen Sie eine Zahlungsmethode",
  "title.payment_method_subtitle":
    "Um Ihre Buchung endgültig abzuschließen, müssen Sie nur noch bezahlen. Wählen Sie eine der Zahlungsmethoden.",
  "title.selected_date": "Verfügbare Tickets am",
  "title.shopping_cart": "Ihr Warenkorb",
  "title.tickets": "Tickets bestellen",
  "title.tickets_subtitle_aviodrome":
    "Im Aviodrome vergeht die Zeit wie im Fluge! Kinder bis zum Alter von 3 Jahren 'fliegen' kostenlos ein. <br />Älter als 3 Jahre? Dann profitieren Sie von unserem Online-Rabatt.",
  "title.tickets_subtitle_aquazoo":
    "Sind Sie bereit für einen spritzigen Tag im AquaZoo? Kinder bis zu 3 Jahren können den Park kostenlos erkunden. <br />Älter als 3 Jahre? Dann profitieren unsere Gäste von unserem Online-Rabatt.",
  "title.tickets_subtitle_beeksebergen":
    "Sind Sie bereit, auf Safari zu gehen? Kinder bis zu 3 Jahren erhalten freien Eintritt. <br />Älter als 3 Jahre? Dann profitieren unsere Gäste von unserem Online-Rabatt.",
  "title.tickets_subtitle_dierenrijk":
    "Erleben Sie tierischen Spaß im Dierenrijk. Kinder bis zu 3 Jahren sind kostenlos dabei. <br />Älter als 3 Jahre? Dann profitieren unsere Gäste von unserem Online-Rabatt.",
  "title.tickets_subtitle_speelland":
    "Haben Ihre Kleinen Lust auf einen ganzen Tag voller Spielspaß? Kinder bis zu 3 Jahren erhalten freien Eintritt. Sind sie älter als 3 Jahre? Dann profitieren unsere Gäste von unserem Online-Rabatt.",
  "title.tickets_subtitle_zooparc":
    "Sind Sie bereit, eine Expedition zu unternehmen? Kinder bis zu 3 Jahren können den Park kostenlos erkunden. <br />Älter als 3 Jahre? Dann profitieren unsere Gäste von unserem Online-Rabatt.",
  "title.timeframes": "Wählen Sie eine Ankunftszeit",
  "title.timeframes_subtitle":
    "Aufgrund der aktuellen Coronavirus-Lage arbeiten wir mit Zeitfenstern. Damit sollen Warteschlangen verhindert werden. Wählen Sie hier Ihre gewünschte Ankunftszeit.",
  "title.total": "Summe",
  "title.select_dates": "Tage auswählen",
  "title.unfinished_order":
    "Es ist leider nicht möglich, mehrere Bestellungen gleichzeitig aufzugeben. Schließen Sie zuerst die andere Bestellung ab oder klicken Sie auf 'Neue Bestellung'. Die andere Bestellung wird dann storniert.",
  "title.vouchers": "Tickets mit Gutscheincodes",
  "title.vouchers_subtitle":
    "Wenn Sie Gutscheincodes erhalten haben, tragen Sie diese nachstehend bitte ein",
  "title.your_order": "Ihre Bestellung",
  "title.your_order_subtitle":
    "Beinage geschafft! Aber noch nicht ganz… Überprüfen Sie Ihre Bestellung, vervollständigen Sie Ihre Bestellung und erhalten Sie diese direkt in Ihre Mailbox.",

  // Form validators
  "validator.email_invalid": "Ungültige E-Mail-Adresse",
  "validator.email_mismatch": "Die E-Mail-Adressen stimmen nicht überein",
  "validator.email_required": "Das E-Mail-Feld ist ein Pflichtfeld",
  "validator.first_name_required": "Das Vorname-Feld ist ein Pflichtfeld",
  "validator.last_name_required": "Das Nachname-Feld ist ein Pflichtfeld",
  "validator.min_addition_dates": "Wählen Sie zumindest ein Datum aus.",
  "validator.min_addition_quantity":
    "Dieses Extra kann nur bei einer Mindestanzahl von {minQuantity} gebucht werden.",
  "validator.payment_method_required": "Wählen Sie bitte eine Zahlungsmethode",
  "validator.privacy_required": "Sie müssen der Datenschutzerklärung zustimmen",
  "validator.products_required": "Sie haben keine Produkte ausgewählt",
  "validator.required": "Das ist ein Pflichtfeld",
  "validator.terms_required":
    "Sie müssen den allgemeinen Geschäftsbedingungen zustimmen",

  // Warnings
  "warning.no_products": "Keine Produkte ausgewählt",
  "warning.no_timeframes":
    "Leider gibt es an diesem Tag keine Verfügbarkeit mehr. Suchen Sie sich gleich einen anderen Tag aus.",
  "warning.old_browser":
    'Sie verwenden einen <strong>veralteten</strong> Browser. Bitte <a href="https://browsehappy.com/">Aktualisieren Sie Ihren Browser</a>, um Nutzung und Sicherheit zu verbessern.',
};
