import styled from "styled-components/macro";

type H2Props = {
  centered?: boolean;
};

export default styled.h2<H2Props>`
  font-size: 1.7rem;
  text-align: ${({ centered }) => (centered ? "center" : "inherit")};
  margin-bottom: 1rem;
`;
