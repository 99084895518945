// Components
import { PriceTable } from "@libema/content-sdk";
import { Currency, Translate } from "@libema/design-system";
import InfoTooltip from "../InfoTooltip";
import PriceTableContent from "./PriceTableContent";

// Style
import { StyledPricing, CurrencyWrapper } from "./style";

type Props = {
  priceTable?: PriceTable;
  lowestPrice: number;
  lowestPriceRegular: number;
  priceUnit?: string;
  showSymbol?: boolean;
  showDecimalSeparator?: boolean;
};

const Pricing = ({
  lowestPrice,
  lowestPriceRegular,
  priceUnit,
  showSymbol = true,
  showDecimalSeparator = true,
  priceTable,
}: Props) => {
  const isFree = !lowestPrice;

  return (
    <StyledPricing>
      {priceTable && (
        <InfoTooltip>
          <PriceTableContent priceTable={priceTable} />
        </InfoTooltip>
      )}
      {isFree ? (
        <b>
          <Translate id="activity.price.free.lowercase" />
        </b>
      ) : (
        <>
          <CurrencyWrapper>
            <span>
              <Translate id="activity.price.from" />
            </span>
            {lowestPriceRegular && (
              <Currency
                currency="EUR"
                size="small"
                showSymbol={showSymbol}
                showDecimalSeparator={showDecimalSeparator}
                price={lowestPriceRegular}
                strikeThrough={true}
              />
            )}
            <Currency
              currency="EUR"
              showSymbol={showSymbol}
              showDecimalSeparator={showDecimalSeparator}
              price={lowestPrice}
            />
          </CurrencyWrapper>
          {priceUnit && <label>{priceUnit}</label>}
        </>
      )}
    </StyledPricing>
  );
};

export default Pricing;
