import styled from "styled-components";

// Components
import { Card, Row, H2 } from "@libema/design-system";

export const TitleHeader = styled(H2)`
  line-height: unset !important;
  font-size: 1.6rem;
`;

export const StyledCard = styled(Card.Wrapper)`
  font-size: 1rem;
  padding-bottom: 24px;

  & h2 {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: 600;
    line-height: 1.5 !important;
  }

  ${Row} {
    margin-top: ${({ theme }) => theme.padding.md};
  }

  li,
  p {
    color: #6f6c67;
  }

  &:hover ${TitleHeader} {
    text-decoration: underline;
  }
`;

export const Tag = styled.p`
  color: ${({ theme }) => theme.color.secondary} !important;
  font-weight: bold;
  margin: 0 4px 8px 0;
`;

export const Tags = styled.section`
  display: flex;
  flex-wrap: wrap;

  p:last-of-type .divider {
    display: none;
  }
`;

export const DateLabel = styled.span`
  margin: 0 0 0 8px;
`;
