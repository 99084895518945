import { ImageLoaderProps } from "next/image";

// Components
import { Card, H2, P } from "@libema/design-system";
import Link from "../../elements/Link";
import SafeWebpImage from "../../elements/SafeWebpImage";
import { BookingLink, DetailList, MoreInfo, Pricing } from "./";

// Style
import {
  Header,
  StyledCardHorizontal,
  StyledCardVertical,
  HighlightLabel,
  ActionsWrapper,
} from "./style";

// TODO: reuse same image loader among components.
const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?w=${width}&h=${Math.ceil(width * 0.5)}&q=${
    quality || 85
  }&fit=fill&f=faces&fm=webp`;
};

const DetailsCard = (props) => {
  const {
    title,
    subtitle,
    minAge,
    minHeight,
    minPersons,
    highlightLabel,
    lowestPrice,
    lowestPriceRegular,
    priceUnit,
    cta,
    duration,
    location,
    mainImage,
    detailPage,
    locale,
    park,
    weekdays,
    startingTime,
    isVertical,
    price,
    className,
    cardDetails,
    cardName,
    priceTable,
  } = props;

  const StyledCard = isVertical ? StyledCardVertical : StyledCardHorizontal;

  const pricingProps = {
    lowestPrice,
    lowestPriceRegular,
    priceUnit,
  };

  const renderActions = () => {
    switch (cardName) {
      case "Activity":
        return (
          <>
            <Pricing {...pricingProps} />
            <ActionsWrapper>
              {cta !== null && <BookingLink link={cta} locale={locale} />}
              <MoreInfo detailPage={detailPage} locale={locale} />
            </ActionsWrapper>
          </>
        );
      case "Program":
        return cta !== null ? <BookingLink link={cta} locale={locale} /> : null;
      default:
        return null;
    }
  };

  return (
    <StyledCard className={className}>
      <Card.ActionArea>
        <Link href={detailPage?.slug || "#"} locale={locale}>
          <Card.Media>
            <SafeWebpImage
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              src={mainImage.url}
              alt={mainImage.title}
              quality={90}
              loader={imageLoader}
              placeholder="blur"
            />
            {highlightLabel && (
              <HighlightLabel>{highlightLabel}</HighlightLabel>
            )}
          </Card.Media>
          <Card.Content>
            <Header>
              <H2 title={title}>{title}</H2>
              <P>{subtitle}</P>
            </Header>
            <DetailList
              minPersons={minPersons}
              duration={duration}
              minAge={minAge}
              minHeight={minHeight}
              weekdays={weekdays}
              park={park}
              location={location}
              startingTime={startingTime}
              price={price}
              cardDetails={cardDetails}
            />
          </Card.Content>
          <Card.Actions>{renderActions()}</Card.Actions>
        </Link>
      </Card.ActionArea>
    </StyledCard>
  );
};

export default DetailsCard;
