import React, { Component } from "react";
import { connect } from "react-redux";
import throttle from "lodash.throttle";
import PropTypes from "prop-types";
import { withRouter } from "next/router";
import trans from "counterpart";
import NavContext from "../contexts/NavContext";
import { getCurrentContent } from "../selectors/contentSelectors";
import { getAlternateLangUrls } from "../selectors/contentfulSelectors";
import { APP_DIERENBOS, APP_BEEKSEBERGEN } from "../constants/apps";
import { getMediaUrl } from "../utilities/mediaUtilities";
import Link from "./typography/Link";

// const NavbarBackground = styled.div`
//   background-color: #fff;
//   height: 91px;
//   width: 100%;
//   position: absolute;
//   top: 47px;

//   @media (max-width: 991px) {
//     display: none;
//   }
// `;

class NavBarBlock extends Component {
  state = {
    activeLink: null,
    collapse: false,
    menuCollapse: false,
    showLangDropdown: false,
    search: "",
    stickyNav: false,
  };

  constructor(props) {
    super(props);
    this.searchInputRef = null;
    this.navbarRef = React.createRef();
  }

  componentDidMount = () => {
    if (!this.props.isOnAccommodationPage) {
      this.throttled = throttle(this.scrollEventHandler, 150, {
        leading: true,
      });
      window.addEventListener("scroll", this.throttled);
    }
  };

  componentDidUpdate(prevProps) {
    const { stickyNav } = this.state;

    if (this.state.collapse) {
      this.searchInputRef.focus();
    }

    if (this.props.isOnAccommodationPage && stickyNav) {
      this.setState({ stickyNav: false });
    }
    if (prevProps.isOnAccommodationPage && !this.props.isOnAccommodationPage) {
      window.addEventListener("scroll", this.throttled);
    }
    if (!prevProps.isOnAccommodationPage && this.props.isOnAccommodationPage) {
      window.removeEventListener("scroll", this.throttled);
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.throttled);
  };

  setActiveLink = (id) => this.setState({ activeLink: id });

  scrollEventHandler = () => {
    const { stickyNav } = this.state;
    const distanceFromTop = (this.navbarRef?.current?.offsetTop || 0) + 50;

    if (stickyNav && window.pageYOffset < distanceFromTop) {
      this.setState({ stickyNav: false });
    }
    if (!stickyNav && window.pageYOffset >= distanceFromTop) {
      this.setState({ stickyNav: true });
    }
  };

  /**
   * Handle search input field value
   */
  handleSearchChange = (evt) => this.setState({ search: evt.target.value });

  /**
   * Display / hide optional languages
   */
  toggleLanguageDropdown = () =>
    this.setState((prevState) => ({
      showLangDropdown: !prevState.showLangDropdown,
    }));

  /**
   * Display / hide search input field
   */
  toggleCollapse = () =>
    this.setState((prevState) => ({
      collapse: !prevState.collapse,
      search: prevState.collapse ? prevState.search : "",
    }));

  /**
   * Don't send the form in case no search query is given
   * @param {*} event
   */
  handleSearch = (event) => {
    this.setState({ showLangDropdown: false });
    if (this.state.search === "") {
      event.preventDefault();
    }
  };

  setSearchRef = (searchInput) => {
    this.searchInputRef = searchInput;
  };

  getAlternateLangUrl = (locale) => {
    const { currentContent, alternateLangUrls } = this.props;

    // First, check legacy CMS
    if (
      currentContent.translation_group &&
      currentContent.translation_group[locale]
    ) {
      return currentContent.translation_group[locale];
    }

    // Second, check new Contentful CMS
    if (
      alternateLangUrls &&
      alternateLangUrls[locale] &&
      alternateLangUrls[locale].url
    ) {
      return alternateLangUrls[locale].url;
    }

    // Otherwise redirect back to home page of selected language.
    return "/";
  };

  /**
   * Display / hide menu for mobile view toggling hamburger
   */
  toggleMenu = () =>
    this.setState((prevState) => ({ menuCollapse: !prevState.menuCollapse }));

  /**
   * Checks if the passed slug is part of the current page' path
   */
  isActive = (slug) => {
    if (this.props.currentPath.startsWith(slug)) {
      return true;
    }
    return false;
  };

  render = () => {
    const { currentContent, placeholder, manageTags } = this.props;
    const { menuCollapse, stickyNav } = this.state;

    if (!currentContent) {
      return null;
    }

    return (
      <nav
        ref={this.navbarRef}
        className={`navbar navbar-toggleable-md main-navigation ${
          stickyNav ? "no-sub affix" : "has-sub affix-top"
        } ${menuCollapse ? "collapse" : ""}`}
        {...manageTags}
      >
        {stickyNav === false && process.env.NEXT_PUBLIC_APP === "dierenbos" && (
          <div className="navbar-background" />
        )}
        <div className="container">
          <div className="navbar-header">
            <button
              className={`navbar-toggler navbar-toggler-right ${
                menuCollapse ? "" : "collapsed"
              }`}
              type="button"
              onClick={this.toggleMenu}
              data-toggle="collapse"
              data-target="#navbar-dropdown-3692"
              aria-controls="navbar-dropdown-3692"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <strong className="list-inline-item align-middle text-white">
                Menu
              </strong>
              <div className="list-inline-item align-middle mr-0">
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </div>
            </button>
            <Link to={trans("routes.home")} className="navbar-brand">
              {process.env.NEXT_PUBLIC_APP === APP_BEEKSEBERGEN ? (
                <React.Fragment>
                  <img
                    src={
                      stickyNav
                        ? getMediaUrl("/img/logo-sticky.svg")
                        : getMediaUrl("/img/logo.svg")
                    }
                    width="135"
                    className="navbar-brand-image logo-desktop"
                    alt="Beekse Bergen"
                    title="Beekse Bergen"
                  />
                  <img
                    src={getMediaUrl("/img/logo-sticky.svg")}
                    width="135"
                    className="navbar-brand-image logo-mobile"
                    alt="Beekse Bergen"
                    title="Beekse Bergen"
                  />
                </React.Fragment>
              ) : null}
              {process.env.NEXT_PUBLIC_APP === APP_DIERENBOS && !stickyNav ? (
                <img
                  src={getMediaUrl("/img/logo.svg")}
                  width="135"
                  className="navbar-brand-image logo-desktop"
                  alt="Beekse Bergen"
                  title="Beekse Bergen"
                />
              ) : null}
            </Link>
          </div>

          <div
            id="navbar-dropdown-3692"
            className={`navbar-collapse ${
              this.state.menuCollapse ? "" : "collapse"
            }`}
          >
            <div className="navbar-container">
              <div className="navbar-top">
                <div className="container">
                  <ul className="nav navbar-nav" onClick={this.toggleMenu}>
                    {placeholder(0)}
                  </ul>
                  <div className="nav navbar-right">
                    <div
                      className={`dropdown language-dropdown ${
                        this.state.showLangDropdown ? "show" : ""
                      }`}
                    >
                      <button
                        className="btn btn-lang dropup"
                        type="button"
                        id="language"
                        onClick={this.toggleLanguageDropdown}
                      >
                        <span className={`lang lang-${this.props.language}`}>
                          <i className="material-icons md-18">
                            arrow_drop_down
                          </i>
                        </span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            locale="nl"
                            to={this.getAlternateLangUrl("nl")}
                            className={`lang lang-nl ${
                              this.props.language === "nl" ? "hidden-xs-up" : ""
                            }`}
                            rel="alternate"
                          >
                            &nbsp;
                          </Link>
                        </li>
                        {process.env.NEXT_PUBLIC_APP === APP_BEEKSEBERGEN && (
                          <>
                            <li>
                              <Link
                                locale="en"
                                to={this.getAlternateLangUrl("en")}
                                className={`lang lang-en ${
                                  this.props.language === "en"
                                    ? "hidden-xs-up"
                                    : ""
                                }`}
                                rel="alternate"
                              >
                                &nbsp;
                              </Link>
                            </li>
                            <li>
                              <Link
                                locale="fr"
                                to={this.getAlternateLangUrl("fr")}
                                className={`lang lang-fr ${
                                  this.props.language === "fr"
                                    ? "hidden-xs-up"
                                    : ""
                                }`}
                                rel="alternate"
                              >
                                &nbsp;
                              </Link>
                            </li>
                          </>
                        )}
                        <li>
                          <Link
                            locale="de"
                            to={this.getAlternateLangUrl("de")}
                            className={`lang lang-de ${
                              this.props.language === "de" ? "hidden-xs-up" : ""
                            }`}
                            rel="alternate"
                          >
                            &nbsp;
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <form
                      className="navbar-form pull-right"
                      method="get"
                      action={trans("routes.search")}
                      onSubmit={this.handleSearch}
                    >
                      <div
                        className={`form-group ${
                          this.state.collapse ? "set-width" : ""
                        } `}
                      >
                        <input
                          type="text"
                          id="search"
                          name="search"
                          className="form-control"
                          placeholder={trans("form.placeholder.search")}
                          autoComplete="off"
                          value={this.state.search}
                          onChange={this.handleSearchChange}
                          ref={this.setSearchRef}
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-default"
                        onClick={this.toggleCollapse}
                      >
                        <i className="material-icons">&#xE8B6;</i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="navbar-main">
                <NavContext.Provider
                  value={{
                    active: this.state.activeLink,
                    setActive: this.setActiveLink,
                    mobileMenuIsOpen: this.state.menuCollapse,
                    closeNavbar: this.toggleMenu,
                  }}
                >
                  <ul className="nav navbar-nav">{placeholder(1)}</ul>
                </NavContext.Provider>
                {process.env.NEXT_PUBLIC_APP === APP_BEEKSEBERGEN && (
                  <ul className="nav navbar-nav navbar-right navbar-cta">
                    <li>
                      <button
                        onClick={() => {
                          this.props.router.push({
                            pathname: trans("routes.tickets"),
                          });
                          this.toggleMenu();
                        }}
                        className="btn btn-primary-color btn-tickets"
                      >
                        Tickets
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  };
}

NavBarBlock.propTypes = {
  currentContent: PropTypes.object,
  currentPath: PropTypes.string,
  language: PropTypes.string,
  placeholder: PropTypes.func.isRequired,
  isOnAccommodationPage: PropTypes.bool,
};

const getAccommodationsRoute = () => {
  if (process.env.NEXT_PUBLIC_APP === "dierenbos") {
    return trans("routes.accommodations_dierenbos");
  }

  return trans("routes.accommodations");
};

const mapState = (state, ownProps) => ({
  currentContent: getCurrentContent(state),
  language: state.preferences.locale,
  isOnAccommodationPage:
    ownProps.router.asPath.indexOf(getAccommodationsRoute()) > -1,
  alternateLangUrls: getAlternateLangUrls(state),
});

export default withRouter(connect(mapState)(NavBarBlock));
