import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { backgroundImage } from '../utilities/mediaUtilities'

const SectionBlock = (props) => {
  const { header, footer, properties, media, placeholder } = props

  const extraClasses = properties.extra_classes
  let styles = []
  if (Array.isArray(properties.styles)) {
    ({ styles } = properties)
  } else {
    styles = properties.styles ? Object.values(properties.styles) : []
  }
  styles = styles.join(' ')

  const className = classNames(
    { 'section-lg': true },
    styles,
    properties.margin,
    properties.padding,
    extraClasses
  )

  let sectionStyle = {}
  // Background images in sections are used in multiple ways. In case a `masked-bg` class is passed, the
  // images is defined in a separate div (e.g. on acco pages). If this class is not passed, the image is defined
  // as the backgroundImage on the section itself
  if ((!extraClasses || !extraClasses.includes('masked-bg')) && media && media.reference) {
    sectionStyle = {
      backgroundImage: backgroundImage(media.reference, 'full_size'),
      backgroundSize: '100% 416px',
      backgroundRepeat: 'no-repeat'
    }
  }

  return (
    <section id={properties.id} className={className} style={sectionStyle} {...props.manageTags}>
      {extraClasses && extraClasses.includes('masked-bg') && media && media.reference && (
        <div className='image-bg' style={{ backgroundImage: backgroundImage(media.reference, 'jumbotron') }} />
      )}
      {header && (
        <div className='section-heading section-header' dangerouslySetInnerHTML={{ __html: header }} />
      )}
      <div className={`section-content container${properties.container_size ? `-${properties.container_size}` : ''}`}>
        {placeholder(0)}
      </div>
      {footer && (
        <div className='section-footer' dangerouslySetInnerHTML={{ __html: footer }} />
      )}
    </section>
  )
}

SectionBlock.propTypes = {
  footer: PropTypes.string,
  header: PropTypes.string,
  manageTags: PropTypes.object,
  media: PropTypes.object,
  placeholder: PropTypes.func.isRequired,
  properties: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default SectionBlock
