// types
import normalizeModelTypename from "../../../../utils/normalizeModelTypename";
import TYPES from "../../../../constants/types";
import { Section } from "../../outputTypes/BaseSection";
import SectionHero from "../../outputTypes/SectionHero";
import SectionGallery from "../../outputTypes/SectionGallery";
import SectionTeaser from "../../outputTypes/SectionTeaser";
import SectionLead from "../../outputTypes/SectionLead";
import SectionMosaic from "../../outputTypes/SectionMosaic";
import SectionActivityOverview from "../../outputTypes/SectionActivityOverview";
import SectionRelatedActivities from "../../outputTypes/SectionRelatedActivities";
import SectionFAQ from "../../outputTypes/SectionFAQ";
import SectionArticleOverview from "../../outputTypes/SectionArticleOverview";
import SectionProgramOverview from "../../outputTypes/SectionProgramOverview";
import SectionRelatedPrograms from "../../outputTypes/SectionRelatedPrograms";
import SectionForm from "../../outputTypes/SectionForm";

// Utils
import normalizeEvents from "./sections/normalizeEvents";
import { normalizeAsset, normalizeLink, normalizeRichText } from "./normalize";

const sectionNormalizers = {
  [TYPES.SectionActivityOverview]: (section): SectionActivityOverview => {
    const { activities, filters } = section;

    const normalizeActivity = ({ sys, ...activity }) => ({
      id: sys.id,
      ...activity,
      cta: (activity.cta && normalizeLink(activity.cta)) || null,
    });

    return {
      ...section,
      activities: activities.items.map(normalizeActivity),
      filters: filters?.items,
    };
  },
  [TYPES.SectionProgramOverview]: (section): SectionProgramOverview => {
    const normalizeProgram = ({ sys, ...program }) => ({
      id: sys.id,
      ...program,
      cta: (program.cta && normalizeLink(program.cta)) || null,
    });

    return {
      ...section,
      programs: section.programs?.items.map(normalizeProgram),
      filters: section.filters?.items,
    };
  },
  [TYPES.SectionArticleOverview]: (section): SectionArticleOverview => {
    const { articles } = section;

    return {
      ...section,
      totalArticles: articles.total,
      articles: articles.items.map((article) => {
        return {
          ...article,
          categories: article.categories.items.map((category) => ({
            id: category.sys.id,
            title: category.title,
          })),
          authors: article.authors.items.map((author) => ({
            id: author.sys.id,
            ...author,
          })),
        };
      }),
    };
  },
  [TYPES.SectionFAQ]: (section): SectionFAQ => {
    const { questions } = section;

    const questionsNormalized = questions?.items?.map((question) => {
      const categories = question?.categories?.items?.map((category) => {
        return {
          ...category,
          id: question.sys.id,
        };
      });

      return {
        ...question,
        id: question.sys.id,
        categories,
      };
    });

    return {
      ...section,
      questions: questionsNormalized ?? [],
    };
  },
  [TYPES.SectionMosaic]: (section): SectionMosaic => {
    const { visualImages, splitImage } = section;

    const normalizedVisualImage = visualImages?.items?.map(normalizeAsset);
    const normalizedSplitImage = normalizeAsset(splitImage);

    return {
      ...section,
      visualImages: normalizedVisualImage,
      splitImage: normalizedSplitImage,
      textDescription: normalizeRichText(section.textDescription),
      splitDescription: normalizeRichText(section.splitDescription),
      visualDescription: normalizeRichText(section.visualDescription),
    };
  },
  [TYPES.SectionGallery]: (section): SectionGallery => {
    const { images } = section;

    const normalizedImages = images?.items?.map(normalizeAsset);

    return {
      ...section,
      images: normalizedImages,
      description: normalizeRichText(section.description),
    };
  },
  [TYPES.SectionHero]: (section): SectionHero => {
    const { media, videoThumbnail, buttons } = section;

    const normalizedMedia = normalizeAsset(media);
    const normalizedButtons = buttons?.items?.map(normalizeLink);
    const normalizedThumbnail = videoThumbnail
      ? normalizeAsset(videoThumbnail)
      : null;

    return {
      ...section,
      media: normalizedMedia,
      videoThumbnail: normalizedThumbnail,
      buttons: normalizedButtons,
    };
  },
  [TYPES.SectionLead]: (section): SectionLead => {
    return {
      ...section,
      description: normalizeRichText(section.description),
    };
  },
  [TYPES.SectionTeaser]: (section): SectionTeaser => {
    return {
      ...section,
      description: normalizeRichText(section.description),
      descriptionSecondary: normalizeRichText(section.descriptionSecondary),
    };
  },
  [TYPES.SectionForm]: (section): SectionForm => {
    return {
      ...section,
      fields: section.fields.items,
    };
  },
  [TYPES.SectionEvents]: normalizeEvents,
  [TYPES.SectionRelatedActivities]: (
    section,
    pageData
  ): SectionRelatedActivities => {
    let activities = section.categoryFilterCollection.items.reduce(
      (activities, item) => {
        const newActivities = item.linkedFrom.activities.items.map(
          (activity) => ({
            id: activity.sys.id,
            ...activity,
            cta: activity.cta && normalizeLink(activity.cta),
            description: normalizeRichText(activity.description),
            descriptionSecondary: normalizeRichText(activity.description),
          })
        );

        return [...activities, ...newActivities];
      },
      []
    );

    // Filter out current activity
    const activityId = pageData.topic?.sys.id || -1;
    activities = activities.filter(
      (activity) => activity.sys.id !== activityId
    );

    return {
      ...section,
      activities,
      callToAction: section.callToAction
        ? normalizeLink(section.callToAction)
        : null,
    };
  },
  [TYPES.SectionRelatedPrograms]: (
    section,
    pageData
  ): SectionRelatedPrograms => {
    let programs = section.categoryFilterCollection.items.reduce(
      (programs, item) => {
        const newPrograms = item.linkedFrom.programs.items.map((program) => ({
          id: program.sys.id,
          ...program,
          cta: normalizeLink(program.cta),
          duration: program.programDuration,
          location: program.location.items,
        }));

        return [...programs, ...newPrograms];
      },
      []
    );

    // Filter out current program
    const programId = pageData.topic?.sys.id || -1;
    programs = programs.filter((program) => program.sys.id !== programId);

    return {
      ...section,
      programs,
      callToAction: section.callToAction
        ? normalizeLink(section.callToAction)
        : null,
    };
  },
  default: (section) => {
    return section;
  },
};

const normalizeSections = async (
  sections,
  pageData = null
): Promise<Section[]> => {
  if (!Array.isArray(sections)) {
    return [];
  }

  return await Promise.all(
    sections.map(async (section) => {
      const { sys } = section;
      const type = normalizeModelTypename(section.type);

      // Get appropriate normalizer
      const sectionNormalizer =
        sectionNormalizers[type] !== undefined
          ? sectionNormalizers[type]
          : sectionNormalizers.default;
      const normalizedSection = await sectionNormalizer(section, pageData);

      const newSection = {
        ...normalizedSection,
        id: sys?.id || null,
        type,
      };

      delete newSection.__typename;
      delete newSection.sys;

      return newSection;
    })
  );
};

export default normalizeSections;
