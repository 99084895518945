import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import trans from "counterpart";
import {
  ID_HOLIDAYPARK,
  ID_SAFARIRESORT,
  ID_SAFARIRESORT_HOTEL,
} from "../constants";
import AccommodationResult from "./search-and-book/AccommodationResult";
import BookingSearchFilterBar from "./search-and-book/BookingSearchFilterBar";
import CompareResourcesBar from "../../compare/components/CompareResourcesBar";
import {
  setPreference,
  updateQueryString,
  fetchOffers,
  fetchArrivalsAndDepartures,
} from "../../../actions/preferenceActions";
import { fetchAccommodationTypes } from "../actions";
import {
  getSubjects,
  getAccommodationTypes,
  getAccommodationPopularity,
} from "../../../selectors/entitySelectors";
import { getFilter } from "../../../selectors/filterSelectors";
import { handleFocusOnMount } from "../../../utilities/preferenceUtilities";
import { APP_BEEKSEBERGEN } from "../../../constants/apps";
import { getInitialParams } from "../../../selectors/preferenceSelectors";
import Loader from "../../../components/loaders/Loader";
import VoucherForm from "../../../components/form/VoucherForm";
import {
  PREFERENCE_DIRECTION,
  PREFERENCE_SORT,
} from "../../../constants/preferences";
import QueryString from "qs";
import { withRouter } from "next/router";
import voucherUtils from "../../../utilities/voucherUtils";

const sortMapping = {
  popularity: { sort: "popularity", direction: "desc" },
  price_asc: { sort: "price", direction: "asc" },
  price_desc: { sort: "price", direction: "desc" },
};

class BookingSearchBlock extends Component {
  state = {
    alerts: [],
    hasError: false,
    results: [],
    isFetching: false,
    initialFetch: true,
    voucherIsValid: false,
    serverError: null,
    panic: false,
  };

  focus = {};

  componentDidMount = () => {
    // Skip period & arrival from focus for now
    const { period, ...focus } = this.props.properties.focus;

    if (!this.props.properties.focus_priority) {
      handleFocusOnMount(focus, {
        kinds: this.props.currentKinds,
        subjects: this.props.currentSubjects,
      });
    }

    // Temporarily fix to store certain specific focus properties that have to be reused on each call.
    if (this.props.properties.focus_priority) {
      const { resources, special, resorts } = this.props.properties.focus;
      if (resources && resources.length > 0) this.focus.resources = resources;
      if (special) this.focus.special = special;
      if (resorts) this.focus.resorts = resorts;
    }

    this.props
      .fetchAccommodationTypes()
      .then(() => this.search(this.props.initialParams))
      .then(() => {
        this.props.updateQueryString();
        this.setState({ initialFetch: false });
      });
  };

  getSortKey = () => {
    const { direction, sort } = this.props;

    return Object.keys(sortMapping).find(
      (key) =>
        sortMapping[key].sort === sort &&
        sortMapping[key].direction === direction
    );
  };

  sortBy = (type) =>
    type === "popularity" ? this.sortByPopularity : this.sortByPrice;

  search = (params = {}, catchErrors = true) => {
    const {
      properties: { voucher_required: voucherRequired, focus },
    } = this.props;
    const ignoreFields = [];
    const queryVoucher = QueryString.parse(this.props.router.asPath).voucher;

    if (!voucherRequired && !queryVoucher) {
      ignoreFields.push("voucher_code");
    }

    const searchParams = {
      alerts: 1,
      allowRefetch: 1,
      unavailable: 1,
      groupBy: "resource",
      ...params,
      subjects: focus.subjects ?? [],
      special: focus.special ?? "",
    };

    this.setState({ isFetching: true });
    const fetchOffersPromise = this.props
      .fetchOffers(searchParams, ignoreFields)
      .then((data) => {
        if (!data) return null;

        if (data.params) {
          const {
            params: { sort: dataSort, direction: dataDirection },
          } = data;
          data.results = this.sortBy(dataSort)(data.results, dataDirection);
        }

        const voucherIsValid = voucherRequired && params.voucher_code != null;

        this.setState({
          alerts: data.alerts || [],
          hasError: false,
          serverError: null,
          results: data.results,
          isFetching: false,
          panic: data.panic || false,
          voucherIsValid,
        });

        return data;
      })
      .catch((err) => {
        // This error catching is here for backwards compatibility.
        // We want the error to be thrown in the VoucherForm, so we can actually display an error message there.
        // TODO: Handle search errors correctly. Catch the error code and display a matching error, instead of just setting `hasError`
        // to `true`
        if (catchErrors) {
          this.setState({
            hasError: true,
            serverError: err?.response?.data,
            isFetching: false,
          });
        } else {
          this.setState({ isFetching: false });
          throw err;
        }
      });

    this.props.fetchArrivalsAndDepartures(params);

    return fetchOffersPromise;
  };

  sortByPrice = (results, direction) => {
    let sorter;
    if (direction === "desc") {
      sorter = (a, b) => {
        if (!a.arrival_date) return 1;
        if (a.all_in_price > b.all_in_price) return -1;
        if (a.all_in_price < b.all_in_price) return 1;
        return 0;
      };
    } else {
      sorter = (a, b) => {
        if (!a.arrival_date) return 1;
        if (a.all_in_price < b.all_in_price) return -1;
        if (a.all_in_price > b.all_in_price) return 1;
        return 0;
      };
    }
    return results.sort(sorter);
  };

  sortByPopularity = (results) => {
    const { accommodationPopularity } = this.props;
    if (accommodationPopularity) {
      results.sort((a, b) => {
        // Place all unavailable results at the bottom.
        if (!a.arrival_date) return 1;
        if (
          accommodationPopularity[a.resource_id] >
          accommodationPopularity[b.resource_id]
        )
          return -1;
        if (
          accommodationPopularity[b.resource_id] >
          accommodationPopularity[a.resource_id]
        )
          return 1;
        return 0;
      });
    }
    return results;
  };

  handleSortChange = (event) => {
    const { setDirection, setSort, sort } = this.props;
    const sortObject = sortMapping[event.target.value];
    if (event.target.value !== sort) {
      setDirection(sortObject.direction);
      setSort(sortObject.sort);
      this.search({ ...sortObject });
    }

    this.props.updateQueryString("sort", sortObject.sort);
  };

  setVoucherIsValid = (valid) => {
    this.setState({ voucherIsValid: valid });
    if (!valid) voucherUtils.deleteVoucherCode();
  };

  render() {
    const {
      resorts,
      properties: { focus, properties, voucher_required: voucherRequired },
      accommodationtypes,
      display,
      setDisplay,
      manageTags,
    } = this.props;
    const {
      alerts,
      hasError,
      serverError,
      results,
      isFetching,
      initialFetch,
      panic,
      voucherIsValid,
    } = this.state;

    return (
      <div {...manageTags}>
        {voucherRequired && (
          <VoucherForm
            validateVoucher={this.search}
            voucherIsValid={voucherIsValid}
            serverError={serverError}
            isFetching={isFetching}
            setVoucherIsValid={this.setVoucherIsValid}
          />
        )}
        <BookingSearchFilterBar
          showAdvancedFilters
          focus={focus}
          onChange={(property, value) => {
            if (property === "kinds") return this.search({ [property]: value });
            return this.search();
          }}
          filterableProperties={properties}
        />
        <Loader
          isShown={initialFetch}
          label={trans("label.loading_holidays")}
        />
        {!initialFetch && (
          <div id="filtered-results" className="row pt-5 text-center">
            <div className="collection-filter">
              <h2>{`${results.filter((r) => r.arrival_date).length} ${trans(
                "title.available_accommodations"
              )}`}</h2>
              {resorts && process.env.NEXT_PUBLIC_APP === APP_BEEKSEBERGEN && (
                <p className="lead">{`${resorts[ID_HOLIDAYPARK] || 0} ${trans(
                  "title.available_on_lakeresort"
                )}, ${resorts[ID_SAFARIRESORT] || 0} ${trans(
                  "title.available_on_resort"
                )}, ${resorts[ID_SAFARIRESORT_HOTEL] || 0} ${trans(
                  "title.available_at_hotel"
                )}`}</p>
              )}
            </div>
          </div>
        )}
        {!initialFetch && (
          <div className="sorting-row mt-3">
            <div className="row">
              <div className="select-holder">
                <div className="custom-select pull-left">
                  <select
                    onChange={this.handleSortChange}
                    value={this.getSortKey()}
                    className="form-control ng-valid ng-touched ng-dirty"
                  >
                    {Object.keys(sortMapping).map((sortKey) => (
                      <option key={sortKey} value={sortKey}>
                        {trans(`sort.${sortKey}`)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="view-holder hidden-sm-down">
                <button
                  type="button"
                  className={`btn btn-beige-outline ${
                    display === "grid" ? "btn-beige" : ""
                  }`}
                  onClick={() => setDisplay("grid")}
                >
                  <i className="material-icons">&#xE8F0;</i>
                </button>
                <button
                  type="button"
                  className={`btn btn-beige-outline ${
                    display !== "grid" ? "btn-beige" : ""
                  } ml-2`}
                  onClick={() => setDisplay("list")}
                >
                  <i className="material-icons">&#xE896;</i>
                </button>
              </div>
            </div>
          </div>
        )}
        {!initialFetch && (
          <div className="row">
            <section className={`col-12 results ${display} mb-5`}>
              {alerts.map((alert) => (
                <div
                  key={alert.name}
                  className={`alert alert-${alert.priority}`}
                  dangerouslySetInnerHTML={{ __html: alert.content }}
                />
              ))}

              <div
                className={`loading-block transparent ${
                  isFetching ? "loading-active" : ""
                }`}
              >
                {!initialFetch && results.length < 1 && !hasError && (
                  <div className="alert alert-info">
                    {trans("error.newyse.not_available")}
                  </div>
                )}
                {hasError && (
                  <div className="alert alert-danger">
                    {trans("error.newyse.general")}
                  </div>
                )}
                <div className="row row-cs">
                  {results &&
                    results.map((result) => (
                      <AccommodationResult
                        key={result.resource_id}
                        result={result}
                        accommodationType={
                          accommodationtypes[result.resource_id]
                        }
                        panic={panic}
                      />
                    ))}
                </div>
              </div>
            </section>
          </div>
        )}
        <CompareResourcesBar />
      </div>
    );
  }
}

BookingSearchBlock.propTypes = {
  accommodationPopularity: PropTypes.object,
  accommodationtypes: PropTypes.object,
  currentKinds: PropTypes.array,
  currentSubjects: PropTypes.object,
  direction: PropTypes.string,
  display: PropTypes.string,
  fetchAccommodationTypes: PropTypes.func.isRequired,
  fetchArrivalsAndDepartures: PropTypes.func.isRequired,
  fetchOffers: PropTypes.func.isRequired,
  initialParams: PropTypes.object,
  properties: PropTypes.object,
  resorts: PropTypes.object,
  setDirection: PropTypes.func,
  setDisplay: PropTypes.func,
  setSort: PropTypes.func,
  sort: PropTypes.string,
  updateQueryString: PropTypes.func.isRequired,
  voucher: PropTypes.object,
};

const mapState = (state, ownProps) => ({
  accommodationPopularity: getAccommodationPopularity(state),
  currentKinds: state.preferences.kinds,
  currentSubjects: state.preferences.subjects,
  direction: state.preferences.direction || "asc",
  display: state.preferences.display || "grid",
  initialParams: getInitialParams(
    state,
    ownProps.properties.focus,
    ownProps.properties.focus_priority
  ),
  subjects: getSubjects(state),
  accommodationtypes: getAccommodationTypes(state),
  resorts: getFilter("resorts")(state),
  sort: state.preferences.sort || "popularity",
  voucher: state.preferences.voucher || {},
});

const mapDispatch = (dispatch) => ({
  fetchArrivalsAndDepartures: (focus) =>
    dispatch(fetchArrivalsAndDepartures(focus)),
  fetchOffers: (focus, ignoreFields) =>
    dispatch(fetchOffers(focus, ignoreFields)),
  fetchAccommodationTypes: () => dispatch(fetchAccommodationTypes()),
  setDirection: (value) => dispatch(setPreference(PREFERENCE_DIRECTION, value)),
  setSort: (value) => dispatch(setPreference(PREFERENCE_SORT, value)),
  setDisplay: (value) => dispatch(setPreference("display", value)),
  updateQueryString: (prop, val) => dispatch(updateQueryString(prop, val)),
});

export default withRouter(connect(mapState, mapDispatch)(BookingSearchBlock));
