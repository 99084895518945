import React from 'react'
import PropTypes from 'prop-types'
import trans from 'counterpart'

const PriceTagPanic = props => (
  <div className='price-tag compact'>
    <span className='price'>
      {trans('label.from_price', { price: props.from_price ? `${props.from_price},-` : '...,-' })}
    </span>
  </div>
)

PriceTagPanic.propTypes = {
  from_price: PropTypes.number
}

export default PriceTagPanic
