import { useEffect } from "react";

const VirtualPageViewBlock = ({ properties }) => {
  useEffect(() => {
    window.dataLayer.push({
      event: "virtual_page_view",
      pageUrl: `${properties.virtual_page_url}${window.location.search}`,
      pageTitle: `${properties.virtual_page_title}`,
    });
  }, []);
  return null;
};
export default VirtualPageViewBlock;
