import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { groupValidateClasses } from "../utilities";
import HelpText from "../../../components/HelpText";

const CheckListField = ({
  input,
  label,
  required,
  helpText,
  meta: { touched, error },
  options,
}) => (
  <div className={groupValidateClasses(touched, error)}>
    {label && (
      <label className={`control-label col-lg-4${required ? " required" : ""}`}>
        {label}
      </label>
    )}
    <div
      className={classnames(label ? "col-lg-8" : "col-lg-12", {
        "has-help-text": !!helpText,
      })}
    >
      {options.map((option) => (
        <div className="checkbox" key={option.key}>
          <label className={required ? " required" : ""}>
            <input
              type="checkbox"
              value={option.key}
              name={`${input.name}-${option.key}`}
              id={`${input.name}-${option.key}`}
              checked={input.value.indexOf(option.key) !== -1}
              onChange={(event) => {
                const newValue = [...input.value];
                if (event.target.checked) {
                  newValue.push(option.key);
                } else {
                  newValue.splice(newValue.indexOf(option.key), 1);
                }
                input.onChange(newValue);
              }}
            />
            {option.value}
          </label>
        </div>
      ))}
      <HelpText value={helpText} />
      {touched && error && <div className="form-control-feedback">{error}</div>}
    </div>
  </div>
);

CheckListField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  helpText: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.array,
  required: PropTypes.bool,
};

export default CheckListField;
