import { gql } from "@apollo/client";

import asset from "./asset";
import link from "./link";

const fragment = gql`
  fragment Article on Article {
    sys {
      id
      publishedAt
    }
    title
    media {
      ...Asset
    }
    description {
      json
      links {
        entries {
          inline {
            ...Link
          }
          block {
            ...Link
          }
        }
        assets {
          block {
            ...Asset
          }
        }
      }
    }
    detailPage {
      slug
    }
    categories: categoriesCollection(limit: 5) {
      items {
        ... on Category {
          sys {
            id
          }
          title
        }
      }
    }
    authors: authorsCollection(limit: 5) {
      items {
        sys {
          id
        }
        firstName
        lastName
        jobTitle
        image {
          ...Asset
        }
      }
    }
  }
  ${asset}
  ${link}
`;

export default fragment;
