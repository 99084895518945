import BaseSection from "./BaseSection";
import RichText from "./RichText";
import Image from "./Image";
import TYPES from "../../../constants/types";

export enum SectionMosaicDisplayStyle {
  DEFAULT = "DEFAULT",
  MIRROR = "MIRROR",
}

type SectionMosaic = BaseSection<TYPES.SectionMosaic> & {
  mosaicTitle?: string;
  textDescription?: RichText;
  splitDescription?: RichText;
  splitImage?: Image;
  visualDescription?: RichText;
  visualImages?: Image[];
  mosaicDisplayStyle: SectionMosaicDisplayStyle;
};

export default SectionMosaic;
