import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getContent } from '../modules/content/actions'
import Gallery from './Gallery'

class GalleryBlock extends Component {
  componentDidMount = () => {
    if (this.props.contentId) {
      // Fetching the content is disabled, since we should already have the content
      // available. The function below somehow destroys passes an incorrect page
      // structure to the `content` reducer, which breaks the page.
      // this.props.loadContent(this.props.contentId);
    }
  }

  render () {
    // Dirty hack to support gallery blocks used within CardGroupBlocks. We look for the parent block
    // and see if its of type dataviewblock.
    // An example of this can be found on `/overnachten/aanbiedingen/dubbele-december-deal`.
    if (this.props.parentBlock && this.props.parentBlock.discr === 'dataviewblock') {
      return (
        <div className='card-wrap'>
          {/* {content && content.attributes.icon && content.attributes.icon.length > 0 && (
            <ul className="animal-highlights">
              {content.attributes.icon.map(icon => (
                <li className={icon.name} key={icon.name} />
              ))}
            </ul>
              )} */}
          <Gallery {...this.props} largeSize='md_43' thumbSize='sm_43' />
        </div>
      )
    }

    return <Gallery {...this.props} />
  }
}

GalleryBlock.propTypes = {
  block: PropTypes.object.isRequired,
  content: PropTypes.object,
  contentId: PropTypes.number,
  discr: PropTypes.string,
  parentBlock: PropTypes.object
}

const mapState = (state, ownProps) => ({
  content: (ownProps.contentId && state.content[ownProps.contentId]) ? state.content[ownProps.contentId] : null,
  parentBlock: state.entities.blocks[ownProps.parentId]
})

const mapDispatch = dispatch => ({
  loadContent: id => dispatch(getContent(id))
})

export default connect(mapState, mapDispatch)(GalleryBlock)
