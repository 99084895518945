import React from "react";
import styled from "styled-components";

const Large = styled("span")`
  font-size: 1.5rem;
`;

const Small = styled("span")`
  font-size: 1.1rem;
  vertical-align: top;
`;

type SplitValue = {
  round: string;
  decimal: string;
};

const splitValue = (value = 0): SplitValue => {
  const stringValue = value.toFixed(2);
  const split = stringValue.split(".");
  return {
    round: `${split[0]},`,
    decimal: split[1],
  };
};

type PricetagProps = {
  prefix?: string;
  value: number;
};

const Pricetag: React.FC<PricetagProps> = ({ prefix, value }) => {
  const price = splitValue(value);
  return (
    <>
      {prefix ? <Large>{prefix}</Large> : null}
      <Large>{price.round}</Large>
      <Small>{price.decimal}</Small>
    </>
  );
};

export default Pricetag;
