import React from "react";
import TabItem from "./TabItem";
import TabLink from "./TabLink";

export type TabProps = {
  active?: boolean;
  name: string | number;
  onClick: (name: string | number) => void;
};

const Tab: React.FC<TabProps> = ({ children, active, name, onClick }) => {
  const handleClick = React.useCallback(() => {
    onClick(name);
  }, [name, onClick]);

  return (
    <TabItem>
      <TabLink active={active} onClick={handleClick}>
        {children}
      </TabLink>
    </TabItem>
  );
};

export default Tab;
