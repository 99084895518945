import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { CarouselItem } from 'reactstrap'
import { backgroundImage } from '../../utilities/mediaUtilities'

class CarouselSlideBlock extends Component {
  state = {
    playing: false
  }

  playPause = () => this.setState(prevState => ({
    playing: !prevState.playing
  }));

  render = () => {
    const { media, value, manageTags, ...restProps } = this.props
    const mediaType = media && media.content_type.split('/')[0]
    const { playing } = this.state

    return (
      <CarouselItem {...restProps} {...manageTags}>
        {mediaType === 'video' ? (
          <div className='video-container'>
            <ReactPlayer
              ref={(player) => { this.player = player }}
              playing={playing}
              url={media.original}
              height='auto'
              onPlay={() => this.setState({ playing: true })}
              onPause={() => this.setState({ playing: false })}
              onClick={this.playPause}
            />
            <div className='video-controls' onClick={this.playPause}>
              {playing ? (
                <span className='pause-button'><i className='material-icons'>pause</i></span>
              ) : (
                <span className='play-button'><i className='material-icons'>play_arrow</i></span>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{ background: `${backgroundImage(media.reference, 'lg_42', 'center')}`, height: '500px', width: '100%', backgroundSize: 'cover' }}
          />
        )}
        <div className='caption' dangerouslySetInnerHTML={{ __html: value }} />
      </CarouselItem>
    )
  }
}

CarouselSlideBlock.propTypes = {
  in: PropTypes.bool,
  index: PropTypes.number,
  manageTags: PropTypes.object,
  media: PropTypes.object,
  value: PropTypes.string
}

export default CarouselSlideBlock
