import { connectStats } from "react-instantsearch-dom";
import * as FilterProvider from "../../blocks/Filters/FilterProvider";
import FilterModalCloseButton from "./FilterModalCloseButton";

const FilterModalCloseButtonConnected = ({ nbHits }) => {
  const { closeFilterModal } = FilterProvider.useFilters();
  return <FilterModalCloseButton onClick={closeFilterModal} count={nbHits} />;
};

export default connectStats(FilterModalCloseButtonConnected);
