import { gql } from "@apollo/client";
import activityEvent from "../fragments/data/activityEvent";
import activityEventSeparator from "../fragments/data/activityEventSeparator";

const query = gql`
  query fetchEvents($id: String!, $skip: Int!) {
    activityEventSchedule(id: $id) {
      sys {
        id
        publishedAt
      }
      activityEventCollection(limit: 50, skip: $skip) {
        items {
          type: __typename
          ...ActivityEvent
          ...ActivityEventSeparator
        }
      }
    }
  }

  ${activityEvent}
  ${activityEventSeparator}
`;

export default query;
