import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import moment, { Moment } from "moment";
import { ActivityEventNormalized } from "@libema/content-sdk";

const SHOW_DAYS = 7;

const now = moment();

const useEvents = () => {
  const [selectedDay, setSelectedDay] = useState(now);
  const [currentWeek, setCurrentWeek] = useState([now]);
  const [events, setEvents] = useState<ActivityEventNormalized[]>([]);
  const [parks, setParks] = useState<string[]>([]);
  const [selectedPark, setSelectedPark] = useState<string>();
  const { locale = "nl" } = useRouter();

  const onSelectPark = useCallback(
    (park) => {
      if (park === selectedPark) return setSelectedPark(undefined);
      setSelectedPark(park);
    },
    [selectedPark]
  );

  const getWeek = useCallback(
    (startDay: Moment = now.locale(locale)) => {
      if (startDay.isSameOrAfter(now)) {
        const days: Moment[] = [];
        for (let i = 0; i < SHOW_DAYS; i++) {
          const currentDay = startDay.clone().add(i, "day");
          days.push(currentDay);
        }
        setCurrentWeek(days);
        return true;
      }
      return false;
    },
    [locale]
  );

  const startDate: Moment = currentWeek[0];
  const endDate: Moment = currentWeek[currentWeek.length - 1];
  const startMonth = startDate.format("MMM").replace(".", "");
  const startDay = startDate.format("DD");
  const endMonth = endDate.format("MMM").replace(".", "");
  const endDay = endDate.format("DD");

  // Sort events by openTime (contentful order should still apply in case of same openTime)
  const sortEvents = (
    allEvents: ActivityEventNormalized[]
  ): ActivityEventNormalized[] =>
    allEvents.sort((a, b) =>
      moment(a.openTime, "HH:mm").diff(moment(b.openTime, "HH:mm"))
    );

  const isExceptionDay = useCallback(
    ({ exceptions }: ActivityEventNormalized): boolean => {
      if (exceptions) {
        return exceptions.some(
          (exception: string): boolean =>
            selectedDay.format("DD-MM-YYYY") === exception
        );
      }
      return false;
    },
    [selectedDay]
  );

  const isActive = useCallback(
    (
      { startDate, endDate }: ActivityEventNormalized,
      today?: Moment
    ): boolean => {
      const inDay = today || selectedDay;
      const notStarted = startDate && moment(startDate).isAfter(inDay);
      const hasEnded = endDate && moment(endDate).isBefore(inDay);

      return !(notStarted || hasEnded);
    },
    [selectedDay]
  );

  const recurrenceExists = (
    day: Moment,
    weekRecurrentEvents: ActivityEventNormalized[]
  ) => {
    const filteredEvents = weekRecurrentEvents?.filter(
      (event: ActivityEventNormalized) =>
        isActive(event, day) &&
        !event.exceptions?.includes(day.format("DD-MM-YYYY"))
    );
    return filteredEvents?.length;
  };

  const filterEvents = useCallback(
    (recurrent, special) => {
      if (recurrent && special) {
        // Filter events of selectedDay: by weekday (recurrent) and dayOfYear (specialDate)
        let selectedDayEvents: ActivityEventNormalized[] = [];
        const weekday = moment(selectedDay)
          .locale("en")
          .format("dddd")
          .toLowerCase();
        const dayOfYear = selectedDay.dayOfYear();
        const allParks: string[] = [];

        if (recurrent[weekday])
          // Filter exception dates (isExceptionDay())
          recurrent[weekday].forEach((event: ActivityEventNormalized) => {
            return (
              isActive(event) &&
              !isExceptionDay(event) &&
              selectedDayEvents.push({ ...event, weekday })
            );
          });
        if (special[dayOfYear])
          special[dayOfYear].forEach((event: ActivityEventNormalized) =>
            selectedDayEvents.push(event)
          );

        // Get unique parks for filtering
        selectedDayEvents.forEach((event: ActivityEventNormalized) => {
          if (event.park && !allParks.includes(event.park))
            allParks.push(event.park);
        });

        if (selectedPark && !allParks.includes(selectedPark || ""))
          setSelectedPark(undefined);

        // Filter by selected park
        if (selectedPark)
          selectedDayEvents = selectedDayEvents.filter(
            (event) => event.park === selectedPark
          );

        // Sort events by openTime
        const sortedEvents = sortEvents(selectedDayEvents);

        setParks(allParks);
        setEvents(sortedEvents);
      }
    },
    [selectedDay, selectedPark, isActive, isExceptionDay]
  );

  useEffect(() => {
    // Get first week
    getWeek();
  }, [getWeek]);

  return {
    selectedDay,
    setSelectedDay,
    selectedPark,
    onSelectPark,
    filterEvents,
    events,
    parks,
    getWeek,
    currentWeek,
    startDate,
    endDate,
    recurrenceExists,
    header: {
      startMonth,
      startDay,
      endDay,
      endMonth,
    },
  };
};

export default useEvents;
