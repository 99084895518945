import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getComparablePropertyGroups } from '../selectors'

const PropertyGroups = ({ accommodationType, propertyGroups }) => (
  <ul className='list-groups list-unstyled'>
    {Object.values(propertyGroups).sort((a, b) => a.name.localeCompare(b.name)).map(propertyGroup => (
      <li key={propertyGroup.id}>
        <h3>{propertyGroup.name}</h3>
        <ul className='list-facilities list-unstyled'>
          {propertyGroup.properties.sort((a, b) => a.display_name.localeCompare(b.display_name)).map((property) => {
            const propertyValue = accommodationType.properties.find(p => p.property.id === property.id)
            return (
              <li key={property.id}>
                {property.display_name}
                {!propertyValue && <span className='material-icons red'>&#xE5CD;</span>}
                {propertyValue && !propertyValue.value && <span className='material-icons'>&#xE5CA;</span>}
                {propertyValue && propertyValue.value && <span>{propertyValue.value}</span>}
              </li>
            )
          })}
        </ul>
      </li>
    ))}
  </ul>
)

PropertyGroups.propTypes = {
  accommodationType: PropTypes.object,
  properties: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
  propertyGroups: PropTypes.object
}

const mapState = (state, ownProps) => ({
  propertyGroups: getComparablePropertyGroups(ownProps.accommodationType, ownProps.properties)(state)
})

export default connect(mapState)(PropertyGroups)
