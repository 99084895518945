type RgbaColor = {
  r: number;
  g: number;
  b: number;
};

const BLACK = "#000000";
const WHITE = "#FFFFFF";

const hexToRgb = (hex: string): RgbaColor | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

/***
 * Get a contrast black or white color.
 */
export const contrastColor = (color: string): string => {
  if (color === "transparent" || color === undefined) return BLACK;
  const rgb = hexToRgb(color);
  if (rgb === null) return BLACK;

  const brightness = Math.round(
    (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
  );
  return brightness > 125 ? BLACK : WHITE;
};
