import moment from 'moment'
import trans from 'counterpart'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import EventsBlock from './EventsBlock'
import DatePicker from '../../../components/DatePicker'
import TabsContext from '../../../contexts/TabsContext'
import { getDates } from '../actions'

class CalendarBlock extends React.Component {
  state = {
    hasInitialized: false,
    selectedDate: moment()
  }

  componentDidMount = () => {
    const { loadDates } = this.props
    const { selectedDate } = this.state
    return loadDates(selectedDate)
      .then(() => this.setState({
        hasInitialized: true
      }))
  }

  onDateChange = date => this.setState({
    selectedDate: date
  })

  /**
   * Returns only the events that are of type `legenda`.
   */
  getLegends (dates) {
    if (Object.keys(dates).length > 0) {
      return dates[Object.keys(dates)[0]].events.filter(event => event.type === 'legenda')
    }

    return []
  }

  render = () => {
    const { dates, properties: { resort } } = this.props
    const { hasInitialized, selectedDate } = this.state

    if (!hasInitialized) return null

    return (
      <TabsContext.Consumer>
        {({ myIndex, activeTab }) => {
          if (myIndex !== activeTab) return null
          return (
            <div className='row daypicker-container'>
              <div className='col-12 col-lg-8 pr-lg-0'>
                {Object.keys(dates).length > 0 && (
                  <DatePicker
                    onDateChange={this.onDateChange}
                    onFocusChange={() => {}}
                    focused
                    dates={dates}
                    date={selectedDate}
                  />
                )}
                <div className='legend'>
                  <h4 className='mb-4'>{trans('calendar.legenda')}</h4>
                  <div className='row mx-0 legend-row'>
                    <div className='col-md-4 col-6 mb-2'>
                      <span className='legend-block CalendarLegend--legend-selected' />
                      <span className='legend-name'>{trans('calendar.selected_day')}</span>
                    </div>
                    <div className='col-md-4 col-6 mb-2'>
                      <span className='legend-block CalendarLegend--legend-today' />
                      <span className='legend-name'>{trans('calendar.today')}</span>
                    </div>
                    {this.getLegends(dates).map((legenda, i) => (
                      <div className='col-md-4 col-6 mb-2' key={legenda.name}>
                        <span className={`legend-block CalendarLegend--legend-${i}`} />
                        <span className='legend-name'>{legenda.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-4 pl-lg-0'>
                <EventsBlock
                  id={resort}
                  selectedDate={selectedDate}
                />
              </div>
            </div>
          )
        }}
      </TabsContext.Consumer>
    )
  }
}

CalendarBlock.propTypes = {
  dates: PropTypes.object,
  loadDates: PropTypes.func,
  properties: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  dates: state.calendar[ownProps.properties.resort] ? state.calendar[ownProps.properties.resort].dates : {}
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadDates: date => dispatch(getDates(date, ownProps.properties.resort))
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarBlock)
