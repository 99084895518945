import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getSrc } from "../../../utilities/mediaUtilities";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";

/**
 * An image component that renders an `img` element.
 * It first tries to load the resolved image by it's size. If it's not available,
 * it falls back to the url that resolves the image.
 *
 * Usage:
 * <Image
 *   file="filename.jpg"
 *   filter="md_43"
 *   className="card-img-top img-fluid"
 *   alt="Some alt text"
 * />
 *
 * In case you need to pass a srcset, simply pass an array with three names as filter:
 * <Image
 *   file="filename.jpg"
 *   filter={['sm_43', 'md_43', 'lg_43']}
 *   className="card-img-top img-fluid"
 *   alt="Some alt text"
 * />
 */

const StyledPicture = styled.picture`
  // In case there is a missing image, if alt text is too big,
  // this will prevent it to break page layout
  img {
    word-break: break-all;
  }
`;
class Image extends Component {
  state = {
    error: false,
    disabled: false,
  };

  handleError = () => {
    this.setState((prevState) => ({
      error: true,
      disabled: prevState.error,
    }));
  };

  render() {
    // Disable the element when both the cached and the resolve url failed.
    if (this.state.disabled) return null;

    const { file, filter } = this.props;
    const { error } = this.state;
    const attributes = { ...this.props, ...this.props.attr };
    delete attributes.file;
    delete attributes.filter;
    delete attributes.attr;

    if (filter instanceof Array) {
      return (
        <StyledPicture>
          <source
            data-srcset={`${getSrc(file, filter[1], error)} 1x, ${getSrc(
              file,
              filter[2],
              error
            )} 2x`}
            media="(min-width: 768px)"
          />
          <img
            data-src={`${getSrc(file, filter[0], error)}`}
            // The Img component does not support srcSet, so we stick to the old solution for now
            data-srcset={`${getSrc(file, filter[0], error)} 1x, ${getSrc(
              file,
              filter[1],
              error
            )} 2x`}
            onError={this.handleError}
            {...attributes}
            className={`${attributes.className} lazyload`}
          />
        </StyledPicture>
      );
    }

    return (
      <img
        data-src={getSrc(file, filter, error)}
        {...attributes}
        className={`${attributes.className} lazyload`}
      />
    );
  }
}

Image.propTypes = {
  attr: PropTypes.object,
  file: PropTypes.string,
  filter: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Image;
