import styled from "styled-components";
import { H1, H2, H3, P } from "@libema/design-system";
import { SectionBackgroundColor } from "@libema/content-sdk";

const textColor = (backgroundColor, theme) => {
  if (!backgroundColor) {
    return theme.color.secondary_main;
  }

  const colors = {
    WHITE: theme.color.secondary_main,
    GREY_100: theme.color.secondary_main,
    GREY_200: theme.color.secondary_main,
    GREY_300: theme.color.secondary_main,
    SECONDARY_MAIN: theme.color.secondary_contrast_text,
    SECONDARY_LIGHT: theme.color.secondary_contrast_text,
  };

  return colors[backgroundColor];
};

export type RichTextProps = {
  backgroundColor?: SectionBackgroundColor;
};

const RichText = styled.div<RichTextProps>`
  ${H1},
  ${H2},
  ${H3},
  ${P},
  p {
    color: ${({ backgroundColor, theme }) => textColor(backgroundColor, theme)};
  }
`;

export default RichText;
