import update from 'immutability-helper'
import * as actionTypes from './actionTypes'

const initialState = {
  compareListIds: [],
  hasIdInList: false,
  hasMaxCompare: false
}

const compare = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COMPARE_PROPERTY: {
      return {
        ...state,
        [action.property]: action.payload
      }
    }
    case actionTypes.ADD_TO_COMPARE: {
      return update(state, {
        compareListIds: {
          $push: [action.payload]
        }
      })
    }
    case actionTypes.REMOVE_FROM_COMPARE: {
      const index = state.compareListIds.indexOf(parseInt(action.payload, 10))
      return update(state, {
        compareListIds: {
          $splice: [[index, 1]]
        }
      })
    }
    case actionTypes.RESET_COMPARE: {
      return initialState
    }
    default:
      return state
  }
}

export default compare
