import { gql } from "@apollo/client";

const query = gql`
  query fetchSitemapUrls($locale: String!) {
    pageCollection(locale: $locale, where: { slug_exists: true }) {
      items {
        sys {
          id
          publishedAt
        }
        slug
        changeFrequency
        priority
      }
    }
  }
`;

export default query;
