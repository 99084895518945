import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import HelpText from '../../../components/HelpText'
import { groupValidateClasses, inputValidateClasses } from '../utilities'
import { getAddress } from '../../reservation/actions'

const NumberField = (props) => {
  const { input, block, meta: { touched, error } } = props
  const properties = block.properties || {}

  return (
    <div className={groupValidateClasses(touched, error)}>
      <div className='col-lg-12'>
        <input
          type='number'
          {...input}
          className={inputValidateClasses(touched, error)}
          id={block.name}
          name={block.name}
          placeholder={properties.label}
          onChange={(event) => {
            input.onChange(event)
            if (block.name === 'housenumber') {
              props.getAddress({ streetnumber: event.target.value })
            }
          }}
          readOnly={properties.formula}
        />
        {touched && error && <div className='form-control-feedback'>{error}</div>}
        <HelpText value={properties.helpText} />
      </div>
    </div>
  )
}

NumberField.propTypes = {
  input: PropTypes.object,
  block: PropTypes.object,
  meta: PropTypes.object,
  getAddress: PropTypes.func
}

const mapStateToProps = dispatch => ({
  getAddress: data => dispatch(getAddress(data))
})

const ConnectedNumberField = connect(
  null,
  mapStateToProps
)(NumberField)

const NumberFieldBlock = ({ block }) => <Field name={block.name} component={ConnectedNumberField} block={block} />

NumberFieldBlock.propTypes = {
  block: PropTypes.object
}

export default NumberFieldBlock
