import { useRef, useState } from "react";
import { captureException } from "@sentry/nextjs";
import screenfull from "screenfull";
import {
  MdPlayArrow,
  MdPause,
  MdVolumeUp,
  MdVolumeOff,
  MdFullscreen,
} from "react-icons/md";
import {
  BigPlayButton,
  StyledJumbotron,
  StyledReactPlayer,
  VideoControls,
  VideoPlayerWrapper,
  VideoThumbnail,
} from "./styles";
import { Image, RichText, Video } from "@libema/content-sdk";

type VideoPlayerProps = {
  autoplay?: boolean;
  startMuted?: boolean;
  media: Video;
  thumbnail?: Image;
  text?: RichText;
  progressInterval?: number;
  className?: string;
};

function VideoPlayer({
  autoplay = false,
  startMuted = false,
  media,
  thumbnail,
  text,
  progressInterval,
  ...rest
}: VideoPlayerProps) {
  const [playing, setPlaying] = useState(autoplay);
  const [isBuffering, setIsBuffering] = useState(false);
  const [isMuted, setIsMuted] = useState(startMuted);
  const videoRef = useRef(null);

  const handleFullscreen = () => {
    const elem = videoRef?.current;

    if (elem && screenfull.isEnabled) {
      screenfull.request(elem).catch((err) => captureException(err));
    }
  };

  const handlePlay = () => setPlaying(!playing);

  const handleBuffer = () => {
    if (!isBuffering) setIsBuffering(true);
  };

  const handleProgress = () => {
    if (isBuffering && playing) {
      setIsBuffering(false);
    }
  };

  const videoProps = {
    url: media.url,
    playing,
    controls: false,
    muted: isMuted,
    volume: isMuted ? 0 : 0.5,
    loop: true,
  };

  const thumbnailUrl: string = thumbnail ? thumbnail.url : "";

  return (
    <StyledJumbotron className="has-img">
      <VideoPlayerWrapper ref={videoRef}>
        {thumbnailUrl && (
          <VideoThumbnail
            className={`video-thumb in ${isBuffering ? "visible" : ""}`}
            style={{
              backgroundImage: `url(${thumbnailUrl})`,
            }}
          />
        )}
        <StyledReactPlayer
          width="100%"
          height="100%"
          onBuffer={handleBuffer}
          onProgress={handleProgress}
          {...rest}
          {...videoProps}
        />
      </VideoPlayerWrapper>
      {!playing && (
        <BigPlayButton>
          <span onClick={handlePlay}>
            <div>
              <MdPlayArrow />
            </div>
          </span>
        </BigPlayButton>
      )}

      <VideoControls>
        <div>
          {playing && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                handlePlay();
              }}
            >
              <MdPause />
            </span>
          )}
          {!playing && (
            <span onClick={handlePlay}>
              <MdPlayArrow />
            </span>
          )}
          {isMuted ? (
            <span onClick={() => setIsMuted(false)}>
              <MdVolumeOff />
            </span>
          ) : (
            <span onClick={() => setIsMuted(true)}>
              <MdVolumeUp />
            </span>
          )}
          <span onClick={handleFullscreen}>
            <MdFullscreen />
          </span>
        </div>
      </VideoControls>
    </StyledJumbotron>
  );
}

export default VideoPlayer;
