import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "next/router";
import Scrollchor from "react-scrollchor";
import trans from "counterpart";
import * as Sentry from "@sentry/nextjs";
import { Alert, AlertType } from "@libema/design-system";
import styled from "styled-components";
import { confirmReservation, nextSection } from "../../reservation/actions";
import { getBlocksOfType } from "../../../selectors/entitySelectors";
import reservationSelectors, {
  getPaymentTerm,
} from "../../../selectors/reservationSelectors";
import {
  PAYMENT_TERM_SHORT,
  PAYMENT_TERM_MEDIUM,
  PAYMENT_TERM_LONG,
} from "../../../utilities/paymentUtils";

const AlertContainer = styled.div`
  margin: 1rem 0;
`;

class BookingSectionSubmit extends Component {
  handleConfirm = () =>
    this.props
      .onConfirm()
      .then((result) => {
        if (!result) return false;
        return this.props.router.push(
          `${trans("routes.payment_request")}?resort=${this.props.resortId}`
        );
      })
      .catch((err) => {
        Sentry.captureException(err);
      });

  next = () => this.props.onNextSection(this.props.sections);

  render = () => {
    const { current, hasReservationError, paymentTerm } = this.props;
    const isNextButtonDisabled = this.props.isConfirming || hasReservationError;
    const isSubmitButtonDisabled = isNextButtonDisabled || this.props.confirmed;

    return (
      <>
        {current && current.name === "personal_details_section" ? (
          <>
            <AlertContainer>
              {paymentTerm === PAYMENT_TERM_SHORT && (
                <Alert type={AlertType.INFO}>
                  {trans("label.shortarrival_payment_choices", {
                    weeks: process.env.NEXT_PUBLIC_PAYMENT_TERMS,
                  })}
                </Alert>
              )}
              {paymentTerm === PAYMENT_TERM_MEDIUM && (
                <Alert type={AlertType.INFO}>
                  {trans("label.mediumarrival_payment_choices")}
                </Alert>
              )}
              {paymentTerm === PAYMENT_TERM_LONG && (
                <Alert type={AlertType.INFO}>
                  {trans("label.longarrival_payment_choices")}
                </Alert>
              )}
            </AlertContainer>
            <button
              className="btn btn-lg btn-primary-color btn-reset-lh text-heading-font"
              type="button"
              onClick={this.handleConfirm}
              disabled={isSubmitButtonDisabled}
            >
              {paymentTerm === PAYMENT_TERM_SHORT
                ? trans(
                    "button.reservation_confirmation_and_pay_less_than_6_weeks"
                  )
                : trans(
                    "button.reservation_confirmation_and_pay_more_than_6_weeks"
                  )}
              <svg
                className={`spinner ml-1 ${
                  isSubmitButtonDisabled ? "visible" : ""
                }`}
                width="20px"
                height="20px"
                viewBox="0 0 66 66"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="path"
                  fill="none"
                  strokeWidth="15"
                  strokeLinecap="round"
                  cx="33"
                  cy="33"
                  r="30"
                />
              </svg>
            </button>
          </>
        ) : (
          <Scrollchor to="#booking" beforeAnimate={this.next}>
            <button
              className="btn btn-lg btn-reset-lh text-heading-font btn-main"
              type="button"
              disabled={isNextButtonDisabled}
            >
              {this.props.properties.buttonLabel}
            </button>
          </Scrollchor>
        )}
      </>
    );
  };
}

BookingSectionSubmit.propTypes = {
  current: PropTypes.object,
  hasReservationError: PropTypes.bool.isRequired,
  confirmed: PropTypes.bool,
  isConfirming: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onNextSection: PropTypes.func.isRequired,
  paymentTerm: PropTypes.string,
  properties: PropTypes.object.isRequired,
  resortId: PropTypes.number,
  router: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  confirmed: state.reservation.confirmed,
  hasReservationError: state.reservation?.errors?.length > 0,
  isConfirming: state.reservation.isConfirming,
  current: state.reservation.sections.current,
  resortId: reservationSelectors.getResortId(state),
  sections: getBlocksOfType("BookingSection")(state),
  paymentTerm: getPaymentTerm(state),
});

const mapDispatchToProps = (dispatch) => ({
  onConfirm: () => dispatch(confirmReservation()),
  onNextSection: (sections) => dispatch(nextSection(sections)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BookingSectionSubmit));
