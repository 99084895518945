import React from "react";
import PropTypes from "prop-types";
import qs from "query-string";
import trans from "counterpart";
import { isWebChatAvailable } from "../../utilities/api/chatApi";

class ChatBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChat: false,
      params: {
        im_name: "",
        LastName: "",
        Mail: "",
        im_subject: "",
      },
      errors: [],
      connect: false,
      chatIsAvailable: false,
    };
  }

  componentDidMount = () => {
    this.isChatAvailable();
    this.timer = this.isChatAvailableRecursive();
  };

  componentDidUpdate = (prevProps, prevState) => {
    // If user has chat opened, but is not in the queue / in a conversation. Close window if all agents leave.
    if (prevState.chatIsAvailable && !this.state.chatIsAvailable) {
      this.setState({ showChat: false });
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  };

  isChatAvailable = (withRecursion) =>
    isWebChatAvailable().then((next) => {
      const { connect } = this.state;
      this.setState({ chatIsAvailable: next.available });
      if (!connect && next.successful && withRecursion) {
        return this.isChatAvailableRecursive();
      }
      // If successful = false, stop recursion.
      // If user is connecting, stop recursion.
      return null;
    });

  isChatAvailableRecursive = () =>
    setTimeout(() => this.isChatAvailable(true), 30000);

  getChatUrl = () => `${this.props.value}?${qs.stringify(this.state.params)}`;

  handleClick = () => {
    const { params } = this.state;
    const errors = [];
    if (params.im_name.length <= 1) {
      errors.push("im_name");
    }
    if (params.LastName.length <= 1) {
      errors.push("LastName");
    }
    if (
      params.Mail.indexOf("@") === -1 ||
      params.Mail.indexOf(".") === -1 ||
      params.Mail.length <= 6
    ) {
      errors.push("Mail");
    }
    if (params.im_subject.length <= 5) {
      errors.push("im_subject");
    }
    if (errors.length > 0) {
      return this.setState({ errors });
    }
    return this.setState((prevState) => ({
      ...prevState,
      errors,
      connect: true,
    }));
  };

  displayChat = () => {
    this.setState({
      showChat: true,
    });
  };

  closeChat = () => {
    this.setState({
      showChat: false,
      connect: false,
    });
  };

  handleChange = (property, value) => {
    this.setState((prevState) => ({
      params: {
        ...prevState.params,
        [property]: value,
      },
    }));
  };

  render() {
    const { showChat, connect, params, errors, chatIsAvailable } = this.state;

    if (!chatIsAvailable) return null;
    return (
      <div id="webchat" className="hidden-sm-down">
        {showChat && !connect && (
          <form
            className="chat-window with-padding"
            onKeyPress={(e) => {
              if (e.charCode === 13) {
                e.preventDefault();
                this.handleClick();
              }
            }}
          >
            <button
              type="button"
              className="close-button"
              onClick={this.closeChat}
            >
              <i className="material-icons">close</i>
            </button>
            <div>
              <div className="form-group">
                <label htmlFor="nameInput">{trans("chat.name")}</label>
                <input
                  id="nameInput"
                  className={`form-control ${
                    errors && errors.includes("im_name") ? "error" : ""
                  }`}
                  onChange={(e) => this.handleChange("im_name", e.target.value)}
                  value={params.name}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastnameInput">{trans("chat.lastname")}</label>
                <input
                  id="lastnameInput"
                  className={`form-control ${
                    errors && errors.includes("LastName") ? "error" : ""
                  }`}
                  onChange={(e) =>
                    this.handleChange("LastName", e.target.value)
                  }
                  value={params.lastname}
                />
              </div>
              <div className="form-group">
                <label htmlFor="emailInput">{trans("chat.email")}</label>
                <input
                  id="emailInput"
                  className={`form-control ${
                    errors && errors.includes("Mail") ? "error" : ""
                  }`}
                  onChange={(e) => this.handleChange("Mail", e.target.value)}
                  value={params.email}
                />
              </div>
              <div className="form-group">
                <label htmlFor="questionInput">{trans("chat.question")}</label>
                <input
                  id="questionInput"
                  className={`form-control ${
                    errors && errors.includes("im_subject") ? "error" : ""
                  }`}
                  onChange={(e) =>
                    this.handleChange("im_subject", e.target.value)
                  }
                  value={params.question}
                />
              </div>
              <button
                type="button"
                className="connect-button"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleClick();
                }}
              >
                {trans("chat.connect")}
              </button>
            </div>
          </form>
        )}
        {showChat && connect && (
          <div>
            <button
              type="button"
              className="close-button"
              onClick={this.closeChat}
            >
              <i className="material-icons">close</i>
            </button>
            <iframe
              title="webchat"
              className="chat-window"
              src={this.getChatUrl()}
            />
          </div>
        )}
        {!showChat && (
          <button
            type="button"
            className="chat-button d-flex"
            onClick={this.displayChat}
          >
            <i className="material-icons">chat</i>
            <p>Chat met customer service</p>
          </button>
        )}
      </div>
    );
  }
}

ChatBlock.propTypes = {
  value: PropTypes.string,
};

export default ChatBlock;
