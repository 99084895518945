import { calculateDepartureDate } from "../booking/utilities";
import subjectUtilities from "../../utilities/subjectUtilities";
import { PreferenceType } from "../../constants/newyse";

export const updatePropertyCounts = (originalProps = {}, newProps) => {
  Object.keys(originalProps).forEach((id) => {
    if (newProps[id]) {
      originalProps[id].count = newProps[id];
    } else {
      originalProps[id].count = 0;
    }
  });

  return originalProps;
};

export const createSubject = (id, quantity) => ({
  subject_id: id,
  quantity,
});

export const updateSubjects = (newSubjects, subjects) => {
  Object.keys(newSubjects).forEach((k) => {
    let subject = k.split("-");
    subject = createSubject(subject[1], newSubjects[k]);
    const index = subjects.findIndex(
      (a) => a.subject_id === subject.subject_id
    );

    if (index !== -1) {
      if (parseInt(subject.quantity, 10) < 1) {
        subjects.splice(index, 1);
      } else {
        subjects[index] = subject;
      }
    } else if (parseInt(subject.quantity, 10) > 0) {
      subjects.push(subject);
    }
  });

  return subjects;
};

export const createAddition = (id, quantity, reservationCriteria) => ({
  resource_id: id,
  start_date: reservationCriteria.arrival_date,
  end_date: calculateDepartureDate(
    reservationCriteria.arrival_date,
    reservationCriteria.duration
  ),
  quantity,
});

/**
 * @deprecated Use the AdditionOverviewBlock instead
 */
export const updateAdditions = (newAddition, additions) => {
  const data = [...additions];
  const index = data.findIndex(
    (a) => a.resource_id === newAddition.resource_id
  );
  if (index !== -1) {
    if (parseInt(newAddition.quantity, 10) < 1) {
      data.splice(index, 1);
    } else {
      data[index] = newAddition;
    }
  } else {
    data.push(newAddition);
  }

  return data;
};

export const createPreference = (id, type) => ({
  id,
  type,
});

/**
 * Updates the preferences
 *
 * @param {string} id
 * @param {string} type        Can either be 'AMENITY' or 'UNIT'
 * @param {string} payload
 * @param {object} preferences
 */
export const updatePreferences = (id, type, payload, preferences) => {
  // Object and Property preferences are handled differently, since we can have
  // only one ObjectPreference and multiple Property preferences
  switch (type) {
    case PreferenceType.UNIT: {
      // If we update an object preference, we first remove all object preferences from
      // the array, since only one object preference is allowed
      preferences = preferences.filter((a) => a.type !== PreferenceType.UNIT);
      if (payload && payload !== "0") {
        // If the payload is possitive, we add the preference
        preferences.push(createPreference(parseInt(payload, 10), type));
      }

      break;
    }
    default: {
      if (payload) {
        // If the payload is positive, we should add the preference
        preferences.push(createPreference(parseInt(id, 10), type));
      } else {
        // If the payload is not positive, we find the index of the added preference
        // so we can remove it.
        const index = preferences.findIndex(
          (a) => a.id === parseInt(id, 10) && a.type === type
        );
        if (index !== -1) {
          preferences.splice(index, 1);
        }
      }
      break;
    }
  }

  return preferences;
};

/**
 * Transforms the request params to a reservation object
 *
 * @param {object} params
 */
export const paramsToCriteria = (params) => {
  const subjects = [];

  if (params.properties && !Array.isArray(params.subjects)) {
    params.properties = [params.properties];
  }

  if (params.subjects) {
    if (!Array.isArray(params.subjects)) {
      params.subjects = [params.subjects];
    }

    params.subjects.forEach((s) => {
      const split = s.split(":");
      subjects.push(createSubject(split[0], split[1]));
    });
  }

  const preferences = [];
  if (params.preferences) {
    if (!Array.isArray(params.preferences)) {
      params.preferences.forEach((p) => {
        preferences.push(createPreference(p, PreferenceType.AMENITY));
      });
    } else {
      preferences.push(
        createPreference(params.preferences, PreferenceType.AMENITY)
      );
    }
  }

  if (params.object) {
    preferences.push(createPreference(params.object, PreferenceType.UNIT));
  }

  return {
    resort_id: params.resort,
    arrival_date: params.arrival,
    duration: params.duration,
    resource_id: params.resource,
    special_code: params.special ? params.special : null,
    preferences,
    subjects,
    customer: params.customer,
    voucher_code: params.voucher_code,
    ...(params.rate_type_id && { rate_type_id: params.rate_type_id }),
  };
};

// Transforms the reservation reducer to request params
export const reservationToParams = (reservation) => {
  const params = {};
  Object.keys(reservation).forEach((k) => {
    if (
      [
        "resort_id",
        "arrival_date",
        "resource_id",
        "special_code",
        "subjects",
        "duration",
        "additions",
        "preferences",
        "voucher_code",
        "rate_type_id",
      ].indexOf(k) === -1
    ) {
      return;
    }

    params[k] =
      reservation[k] && reservation[k].length > 0 ? reservation[k] : undefined;
  });

  return params;
};

export const criteriaToOfferParams = (criteria) => {
  const params = {
    arrival: criteria.arrival_date,
    nights: criteria.duration,
    resource: criteria.resource_id,
    subjects: subjectUtilities.toQueryParam(criteria.subjects),
    resortId: criteria.resort_id,
  };

  if (criteria.special_code) {
    params.special = criteria.special_code;
  }

  if (criteria.preferences.length) {
    const propertyPreferences = criteria.preferences.filter(
      (p) => p.type === PreferenceType.AMENITY
    );
    if (propertyPreferences.length) {
      params.properties = propertyPreferences.map((p) => p.id);
    }

    const objectPreference = criteria.preferences.find(
      (p) => p.type === PreferenceType.UNIT
    );
    if (objectPreference) {
      params.object = objectPreference.id;
    }
  }

  return params;
};
