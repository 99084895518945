import React from 'react'
import PropTypes from 'prop-types'

const HelpText = ({ value }) => value ? <small className='help-block'>{value}</small> : null

HelpText.propTypes = {
  value: PropTypes.string
}

export default HelpText
