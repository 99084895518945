import update from 'immutability-helper'
import { defaults } from '../utilities/preferenceUtilities'
import { CLEAR_REFINE_PREFERENCES, MERGE_PREFERENCES, SET_ARRIVAL_PREFERENCE, SET_PREFERENCE } from '../actions/preferenceActions'
import { PREFERENCE_PROPERTIES, PREFERENCE_RESORTS, PREFERENCE_KINDS } from '../constants/preferences'

/**
 * Preferences reducer
 *
 * This reducer is meant to keep track of user preferences and user preferences only.
 * For example, if a user selected an arrival date, we store that date here.
 *
 * Any metadata related to these preferences should be stored in anywhere else.
 */
const preferenceReducer = (state = defaults, action) => {
  switch (action.type) {
    case CLEAR_REFINE_PREFERENCES: {
      return update(state, {
        [PREFERENCE_PROPERTIES]: { $set: defaults[PREFERENCE_PROPERTIES] },
        [PREFERENCE_RESORTS]: { $set: defaults[PREFERENCE_RESORTS] },
        [PREFERENCE_KINDS]: { $set: defaults[PREFERENCE_KINDS] }
      })
    }
    case MERGE_PREFERENCES: {
      return update(state, {
        $merge: action.payload
      })
    }
    case SET_ARRIVAL_PREFERENCE: {
      // When the arrival date is changed, we reset the departure date
      // to avoid conflicts with impossible stays
      return update(state, {
        arrival: { $set: action.payload },
        departure: { $set: null }
      })
    }
    case SET_PREFERENCE: {
      return update(state, {
        [action.payload.name]: {
          $set: action.payload.value
        }
      })
    }
    default: {
      return state
    }
  }
}

export default preferenceReducer
