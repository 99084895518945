import update from 'immutability-helper'
import { SET_FILTER } from '../actions/filterActions'
import { FILTER_ARRIVALS, FILTER_DEPARTURES, FILTER_KINDS, FILTER_PROPERTIES, FILTER_RESORTS } from '../constants/filters'

const initialState = {
  [FILTER_ARRIVALS]: [],
  [FILTER_DEPARTURES]: [],
  [FILTER_KINDS]: {},
  [FILTER_PROPERTIES]: {},
  [FILTER_RESORTS]: {}
}

/**
 * Filter reducer
 *
 * This reducer is meant to keep track of available filters based on the users' search parameters.
 * For example, it stores the possible arrival- and departure dates. It also stores the possible properties and their counts
 */
const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER: {
      return update(state, {
        [action.payload.name]: {
          $set: action.payload.value
        }
      })
    }
    default: {
      return state
    }
  }
}

export default filterReducer
