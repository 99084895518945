import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { Form, Button, Row, Col } from "@libema/design-system";
import { Formik, Field } from "formik";
import styled from "styled-components";
import trans from "counterpart";
import { connect } from "react-redux";
import qs from "query-string";
import { setVoucher } from "../../actions/preferenceActions";
import { updateVoucherCriteria } from "../../modules/reservation/actions";

// Icons
import { Clear } from "../icons/Clear";

const initialValues = { voucher: "" };

const Section = styled.section`
  /* TODO: This font-size should be defined in the design system, but this will result in a breaking change */
  input {
    font-size: 1.2rem;
  }
  margin-bottom: 6rem;
`;

const ClearButton = styled(Button)`
  white-space: nowrap;

  .material-icons {
    color: white;
    font-size: 30px;
  }
`;

const SubmitButton = styled(Button)`
  white-space: nowrap;
`;

const VoucherForm = ({
  validateVoucher,
  updateVoucherCriteria,
  voucherIsValid,
  setVoucherIsValid,
  voucherCode,
  setVoucher,
  serverError,
  isFetching,
}) => {
  const router = useRouter();
  const voucherForm = useRef();
  const hasMounted = useRef(false);

  const onClearVoucher = () => {
    const { resetForm } = voucherForm.current;
    resetForm({ values: { voucher: "" } });
    handleSubmit({ voucher: "" }, voucherForm.current);
  };

  const handleSubmit = ({ voucher }, { setFieldError }) => {
    return validateVoucher({ voucher }, false)
      .then(() => {
        const [pathname] = router.asPath.split("?");

        if (voucher) {
          setVoucherIsValid(true);
        } else {
          setVoucherIsValid(false);
        }

        setVoucher(voucher);
        updateVoucherCriteria(voucher);

        let query = { ...router.query, voucher };

        if (query.slug) {
          delete query.slug;
        }

        const queryString = qs.stringify(query, { skipEmptyString: true });
        router.replace(`${pathname}?${queryString}`, undefined, {
          shallow: true,
        });
      })
      .catch((err) => {
        setVoucherIsValid(false);
        setFieldError(
          "voucher",
          trans(`error.api.${err.response.data.code.toLowerCase()}`, {
            fallback: trans("error.api.unexpected"),
          })
        );
      });
  };

  useEffect(() => {
    let voucher = voucherCode;

    if (voucherForm.current) {
      const { values, setFieldValue } = voucherForm.current;
      const isStartingWithoutVoucher = !hasMounted.current && !voucherCode;
      hasMounted.current = true;

      if (isStartingWithoutVoucher) {
        voucher = qs.parse(router.asPath).voucher;
        if (voucher) {
          handleSubmit({ voucher }, voucherForm.current);
        }
      }

      if (voucher !== values.voucher) {
        setFieldValue("voucher", voucher);
      }
    }

    // Every time we land on a page with this component, voucher expiry should reset the ttl
    setVoucher(voucher);
  }, [voucherCode]);

  useEffect(() => {
    if (voucherCode && isFetching) {
      return;
    }
    if (serverError) {
      setVoucherIsValid(false);
    }
  }, [voucherCode, isFetching, serverError]);

  return (
    <Section className="section p-5 bg-beige">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={voucherForm}
      >
        {({ isSubmitting, errors }) => (
          <Form.Form>
            <h2 style={{ textAlign: "center" }}>
              {trans("title.voucher_form")}
            </h2>
            <p style={{ textAlign: "center" }}>
              {trans("title.voucher_form_subtitle_1")}
              <br />
              {trans("title.voucher_form_subtitle_2")}
            </p>
            <Row>
              <Col md={6} mdOffset={2}>
                <Field
                  name="voucher"
                  component={Form.Formik.TextField}
                  isValid={!isSubmitting && voucherIsValid}
                />
              </Col>
              <Col md={2}>
                {(voucherIsValid || errors.voucher || serverError) && (
                  <ClearButton onClick={onClearVoucher}>
                    <Clear />
                  </ClearButton>
                )}
                {!voucherIsValid && !errors.voucher && !serverError && (
                  <SubmitButton type="submit" disabled={isSubmitting}>
                    {trans("button.add")}
                  </SubmitButton>
                )}
              </Col>
            </Row>
            {errors.voucher && (
              <Row>
                <Col mdOffset={2}>
                  <div className="form-control-feedback text-danger">
                    {errors.voucher}
                  </div>
                </Col>
              </Row>
            )}
            {!errors.voucher && serverError && (
              <Row>
                <Col mdOffset={2}>
                  <div className="form-control-feedback text-danger">
                    {trans(`error.api.${serverError.code.toLowerCase()}`, {
                      fallback: trans("error.api.unexpected"),
                    })}
                  </div>
                </Col>
              </Row>
            )}
          </Form.Form>
        )}
      </Formik>
    </Section>
  );
};
VoucherForm.propTypes = {
  validateVoucher: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  serverError: PropTypes.shape({
    code: PropTypes.string,
  }),
};

const mapState = (state, ownProps) => ({
  voucherCode: state.preferences.voucher,
});

const mapDispatch = (dispatch) => ({
  setVoucher: (value) => dispatch(setVoucher(value)),
  updateVoucherCriteria: (voucher) => dispatch(updateVoucherCriteria(voucher)),
});

export default connect(mapState, mapDispatch)(VoucherForm);
