import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'reactstrap'
import trans from 'counterpart'

class CompareResourceDetailsPopover extends Component {
  state = {
    popoverOpen: false
  }

  toggle = () => {
    this.setState(prevState => ({
      popoverOpen: !prevState.popoverOpen
    }))
  }

  render = () => {
    const { accommodationType } = this.props

    return (
      <div className='details-included d-block mb-1 text-right small'>
        <div className='inclusive-stay-wrapper' id={`popoverOpen-${accommodationType.id}`} onClick={this.toggle}>
          <div className='inclusive-stay-absolute-position'>
            {`${trans('stay.including')} `}
            <span className='material-icons md-18'>help</span>
          </div>
        </div>
        <Popover popperClassName='popover popover-modal' placement='top' target={`popoverOpen-${accommodationType.id}`} isOpen={this.state.popoverOpen} toggle={this.toggle}>
          <div className='popover-content' dangerouslySetInnerHTML={{ __html: accommodationType.all_in_description }} />
          <div className='popover-close' onClick={this.toggle}>
            <i className='material-icons'>&#xE5CD;</i>
          </div>
        </Popover>
      </div>
    )
  }
}

CompareResourceDetailsPopover.propTypes = {
  accommodationType: PropTypes.object
}

export default CompareResourceDetailsPopover
