import {
  SET_ALTERNATE_LANG_URLS,
  CLEAR_ALTERNATE_LANG_URLS,
} from "../actions/contentfulActions";
import { CONTENTFUL_ALTERNATE_URLS } from "../constants/contentful";

const initialState = {
  [CONTENTFUL_ALTERNATE_URLS]: {},
};

/**
 * Contentful reducer
 *
 * This reducer is meant as a bridge between the old cms and the new Contentful cms.
 */
const contentfulReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALTERNATE_LANG_URLS: {
      // Normalize by locale
      const alternatePages = action.payload.alternateLangUrls.reduce(
        (pages, page) => ({
          ...pages,
          [page.locale]: page,
        }),
        {}
      );
      return {
        ...state,
        [CONTENTFUL_ALTERNATE_URLS]: alternatePages,
      };
    }
    case CLEAR_ALTERNATE_LANG_URLS: {
      return { ...state, [CONTENTFUL_ALTERNATE_URLS]: {} };
    }
    default: {
      return state;
    }
  }
};

export default contentfulReducer;
