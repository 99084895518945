import * as React from "react";
import styled from "styled-components";

// Types
import { SectionLead, SectionLeadDisplayStyle } from "@libema/content-sdk";

// Components
import {
  Section,
  DEVICE_LG_DOWN,
  DEVICE_LG_UP,
  H3,
  H2,
  H1,
} from "@libema/design-system";
import RichTextRenderer from "../renderer/RichTextRenderer";

type SectionWrapperProps = {
  displayStyle: SectionLeadDisplayStyle;
};

const SectionHeader = styled(Section.Header)`
  ${H2} {
    padding: 0 ${({ theme }) => theme.padding.md};
  }
`;

const Heading = styled.div``;

const RichText = styled.div``;

const SectionWrapper = styled(Section.Wrapper)<SectionWrapperProps>`
  text-align: center;

  ${({ displayStyle }) =>
    displayStyle !== SectionLeadDisplayStyle.DEFAULT &&
    `
      ${Container} {
        align-items: center;
        flex-direction: row;

        @media ${DEVICE_LG_DOWN} {
          flex-direction: column;
        }
      }

      ${Heading}, ${RichText} {
        flex: 0 0 50%;
        padding: 0 2rem;

        @media ${DEVICE_LG_DOWN} {
          flex: 0 0 100%;
        }
      }

      ${RichText} {
        @media ${DEVICE_LG_DOWN} {
          margin-top: 1rem;
        }
      }
    `}

  ${({ displayStyle }) =>
    displayStyle === SectionLeadDisplayStyle.HORIZONTAL_MIRROR &&
    `
      ${Container} {
        flex-direction: row-reverse;

        @media ${DEVICE_LG_DOWN} {
          flex-direction: column;
        }
      }
    `}

  ${({ displayStyle }) =>
    displayStyle === SectionLeadDisplayStyle.DEFAULT &&
    `
    ${Container} {
      align-items: center;
      flex-direction: column;

      @media ${DEVICE_LG_UP} {
        max-width: 840px;
      }
    }

    ${Heading}, ${RichText} {
      padding: 0 1rem;
    }

    ${RichText} {
      margin-top: 1rem;
    }
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;

  @media ${DEVICE_LG_UP} {
    max-width: 1140px;
  }

  @media ${DEVICE_LG_DOWN} {
    flex-direction: column;
    margin: 0;
  }
`;

const LeadSection: React.FC<SectionLead> = ({
  title,
  heading,
  description,
  headingElement,
  displayStyle,
  backgroundColor,
}) => {
  let HeadingComponent = H1;

  switch (headingElement) {
    case "H2":
      HeadingComponent = H2;
      break;
    case "H3":
      HeadingComponent = H3;
      break;
  }

  return (
    <SectionWrapper
      displayStyle={displayStyle}
      backgroundColor={backgroundColor}
    >
      {title && (
        <SectionHeader>
          <H2>{title}</H2>
        </SectionHeader>
      )}
      <Container>
        {heading && (
          <Heading>
            <HeadingComponent>{heading}</HeadingComponent>
          </Heading>
        )}
        <RichText>
          <RichTextRenderer text={description} />
        </RichText>
      </Container>
    </SectionWrapper>
  );
};

export default LeadSection;
