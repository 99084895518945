import React from 'react'
import styled from 'styled-components'
import { Row, Col, deviceUtils } from '@libema/design-system'

const HeaderWrapper = styled.div`
  background: ${({ theme }) => theme.color.neutralMedium};
  padding: 2rem 1rem;
  @media ${deviceUtils.DEVICE_MD_UP} {
    padding: 0;
  }
`

const ImageCol = styled(Col)`
  padding: 0;
`

const ImageWrapper = styled('div')`
  height: 354px;
  width: 96%;
  overflow: hidden;
  margin-top: -69px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding-bottom: 0px !important;
  height: 100%;
  color: white;
`

const ConfirmationHeader = ({ children }) => (
  <HeaderContainer>
    {/* <HeaderContent className='paymentheader' dangerouslySetInnerHTML={{ __html: text }} /> */}
    {children}
  </HeaderContainer>
)

const ReservationImage = () => (
  <ImageWrapper>
    {process.env.NEXT_PUBLIC_APP === 'beeksebergen' && <img src='/img/elephant.png' alt='Beekse Bergen Olifant' />}
    {process.env.NEXT_PUBLIC_APP === 'dierenbos' && <img src='/img/eekhoorn.png' alt='Dierenbos Eekhoorn' />}
  </ImageWrapper>
)

const PaymentHeader = ({ children }) => {
  React.useEffect(() => {
    // Quick fix to scroll to top of page when payment and payment confirmation pages loads.
    // See https://github.com/vercel/next.js/issues/3249
    window.scrollTo(0, 0)
  }, [])
  return (
    <HeaderWrapper>
      <Row>
        <Col lg={8}>
          <ConfirmationHeader>
            {children}
          </ConfirmationHeader>
        </Col>
        <ImageCol lg={4} hiddenLgDown>
          <ReservationImage />
        </ImageCol>
      </Row>
    </HeaderWrapper>
  )
}

export default PaymentHeader
