import BaseModel from "./BaseModel";
import TYPES from "../../../constants/types";

export enum LinkDisplayStyle {
  LINK = "LINK",
  BUTTON = "BUTTON",
  BUTTON_PHONE = "BUTTON PHONE",
}

export type CustomLink = {
  label: string;
  url: string;
};

type Link = BaseModel<TYPES.Link> & {
  label: string;
  url: string;
  displayStyle: LinkDisplayStyle;
  target: string;
  phoneNumber?: string;
};

export default Link;
