import { createSelector } from 'reselect'
import { getContent } from './environmentSelectors'

export const getAllContent = state => state.content

export const getCurrentContent = createSelector(
  getContent,
  getAllContent,
  (id, allContent) => allContent[id]
)
