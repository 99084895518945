import styled from "styled-components/macro";

/**
 * This is a simple wrapper that styles data provided by a rich text editor.
 */
export default styled.div`
  text-align: left;

  ul {
    list-style: disc;
    padding-left: 20px;
  }
  ol {
    list-style: decimal;
    padding-left: 20px;
  }

  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-left {
    text-align: left;
  }
`;
