import counterpart from 'counterpart'
import moment from 'moment'
import * as blocks from './blocks'

const isNotHidden = (fieldName, hiddenBlocks) => {
  if (!hiddenBlocks) {
    return true
  }

  const hidden = hiddenBlocks.filter(b => b.name === fieldName)
  return (hidden.length < 1)
}

const validate = (values, props) => {
  const errors = {}

  const fields = blocks.findFormFields(props.block.contentId, props.entities)

  fields.forEach((field) => {
    const fieldName = field.name
    const dateField = moment.isMoment(values[fieldName])
    const validDate = dateField ? values[fieldName].isValid() : ''

    if (field.properties.required && !values[fieldName] && isNotHidden(fieldName, props.hiddenBlocks)) {
      errors[fieldName] = counterpart.translate('form.field_mandatory')
    }

    if (field.properties.required && dateField && !validDate && isNotHidden(fieldName, props.hiddenBlocks)) {
      errors[fieldName] = counterpart.translate('form.datefield_invalid')
    }

    if (!field.properties.validation) {
      return
    }

    field.properties.validation.forEach((validation) => {
      switch (validation.type) {
        case 'min-length': {
          if (values[fieldName] && values[fieldName].length < validation.value) {
            errors[fieldName] = validation.message
          }
          break
        }
        case 'gte': {
          if (values[fieldName] && values[fieldName] < validation.value) {
            errors[fieldName] = validation.message
          }
          break
        }
        case 'lte': {
          if (values[fieldName] && parseInt(values[fieldName], 10) > validation.value) {
            errors[fieldName] = validation.message
          }
          break
        }
        case 'regex': {
          const regex = new RegExp(validation.value, 'i')
          if (!regex.test(values[fieldName])) {
            errors[fieldName] = validation.message
          }
          break
        }
        default: {
          break
        }
      }
    })
  })

  return errors
}

export default validate
