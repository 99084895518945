import { gql } from "@apollo/client";

const fragment = gql`
  fragment Asset on Asset {
    sys {
      id
    }
    url
    title
    description
    contentType
  }
`;

export default fragment;
