const isLocale = (slug: string, locale: string): boolean =>
  slug === locale ||
  slug === `/${locale}` ||
  slug.startsWith(`${locale}/`) ||
  slug.startsWith(`/${locale}/`)

export const getLocaleFromSlug = (slug: string): string => {
  if (isLocale(slug, 'en')) return 'en'
  if (isLocale(slug, 'de')) return 'de'
  if (isLocale(slug, 'fr')) return 'fr'

  return 'nl'
}
