export function getIdOrSlugFromPath (path) {
  let slug = path.toString()
  if (slug) {
    slug = slug.replace(/app_dev.php\//, '')
    if (slug.indexOf('admin/contenteditor/view/content') !== -1) {
      slug = slug.replace(/admin\/contenteditor\/view\/content\//, '')
    } else if (slug.indexOf('admin/contenteditor/view/template') !== -1) {
      slug = slug.replace(/admin\/contenteditor\/view\/template\//, '')
    }
  }

  return slug || 'index'
}

export function getModeFromPath (path) {
  if (path.toString().indexOf('admin/contenteditor/view/content') !== -1 || path.toString().indexOf('admin/contenteditor/view/template') !== -1) {
    return 'manage'
  }

  return 'public'
}

/**
 * Replaces variables defined in string values to the corresponding state var.
 *
 * A variable can be defined in a string like `some text before { form.formname.values.fieldname } some text after`.
 *
 * @param {string} value
 * @param {object} state
 */
export function replaceStateVariables (value, state) {
  if (!value || typeof value !== 'string' || value.indexOf("<script") > -1) {
    return value || ''
  }

  return value.replace(/{(.*)}/g, (match, variable) => {
    const updated = variable.trim().split('.').reduce((o, i) => o[i], state)
    if (updated && (typeof value === 'string' || typeof value === 'number')) {
      return updated
    }
    return ''
  })
}

/**
 * Change the url for a section change
 *
 * @param {number} section
 */
export function changeSectionUrl () {
  // browserHistory.push(`${window.location.pathname}?section=${section}`);
  window.scrollTo(0, 0)
}

/**
 * Convert table to mobile list to be used in the RichText block.
 * @param {HTMLTableElement} table
 */
export function convertTableToMobile (table) {
  const { tHead, tBodies } = table

  // Make sure that only the basic case is handled by this.
  if (tHead?.childElementCount !== 1 || tBodies.length !== 1) {
    return null
  }

  const mobileTable = document.createElement('div')
  mobileTable.className = 'mobile-table'

  const titles = Array.from(tHead.querySelectorAll('th')).map(th => th.innerText.trim())
  const lines = Array.from(tBodies[0].children).map(tr => Array.from(tr.children).map(td => td.textContent?.trim()))

  const createItemBlock = (row) => {
    const item = document.createElement('div')
    item.className = 'mobile-table--item'

    row.forEach((col, idx) => {
      const title = titles[idx]
      const itemLine = document.createElement('div')

      if (title?.length > 0) {
        itemLine.innerHTML = `<strong>${title}</strong>: `
      }

      itemLine.append(col)
      item.append(itemLine)
    })

    return item
  }

  lines
    .map(createItemBlock)
    .forEach(itemBlock => mobileTable.append(itemBlock))

  table.parentNode.insertBefore(mobileTable, table)
  table.classList.add('no-mobile')
};
