import styled from "styled-components/macro";

export default styled.button`
  border: 0;
  background-color: transparent;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.textColor.default};
  text-decoration: underline;

  &:hover {
    background-color: transparent;
  }
`;
