import React, { useMemo, ReactElement } from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import { Icon } from "@iconify/react";
import { Col, DEVICE_LG_DOWN } from "@libema/design-system";
import RichText from "./RichText";

type InfoTooltipProps = {
  children: ReactElement;
};

const StyledInfoTooltip = styled(Popup)`
  &-arrow {
    color: ${({ theme }) => theme.color.neutralLight} !important;
  }

  &-content {
    width: auto !important;
    padding: 1.25rem !important;
    color: ${({ theme }) => theme.color.text_light};
    background-color: ${({ theme }) => theme.color.neutralLight} !important;

    .price-table-item {
      padding: 0.625rem 0;
      border-bottom: 1px solid ${({ theme }) => theme.color.text_light};
      font-size: 1rem;
    }

    .price-table-condition {
      margin-top: 1rem;

      ${Col} {
        display: flex;
        padding-left: 0;

        ${RichText} {
          > p {
            font-size: 1rem;
            margin-bottom: 0;
          }
        }

        &:first-of-type {
          justify-content: flex-end;
        }

        &:last-of-type {
          line-height: normal;
        }
      }

      .check {
        color: ${({ theme }) => theme.color.success};
      }
      .cross,
      .exclamation {
        color: ${({ theme }) => theme.color.error};
      }
    }
  }
`;

const InfoIcon = styled.div`
  align-self: flex-end;
  width: 24px;
  height: 24px;

  @media ${DEVICE_LG_DOWN} {
    margin-right: 1rem;
  }

  svg {
    position: absolute;
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.color.secondary};
  }
`;

const InfoTooltip = ({ children }: InfoTooltipProps) => {
  const Icons = useMemo(
    () => ({
      Check: "akar-icons:check",
      Cross: "akar-icons:cross",
      Exclamation: "ant-design:exclamation-outlined",
    }),
    []
  );

  return (
    <StyledInfoTooltip
      trigger={(open) => (
        <InfoIcon>
          <Icon icon="bi:info-circle-fill" />
        </InfoIcon>
      )}
      on={["click"]}
      position={["right top", "left top"]}
      arrow={false}
      offsetX={30}
      closeOnDocumentClick
    >
      {React.cloneElement(children, { icons: Icons })}
    </StyledInfoTooltip>
  );
};

export default InfoTooltip;
