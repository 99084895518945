import { APP_BEEKSEBERGEN } from "../constants/apps"

/* global navigator */
export function objectToArray (object) {
  return Object.keys(object).map(key => object[key])
}

export const buildQuerystring = params => Object.keys(params).map((key) => {
  if (Array.isArray(params[key])) {
    return params[key].map(f => `${key}[]=${f}`).join('&')
  }
  return `${key}=${params[key]}`
}).join('&')

export const classnames = parts => parts.filter(p => !!p).join(' ')
export const isIE11 = typeof navigator !== 'undefined' ? (navigator.appVersion.indexOf('Trident/') > -1 || navigator.userAgent.indexOf('Edge') > -1) : false
export const isIE = typeof navigator !== 'undefined' && navigator.appVersion.indexOf('Trident/') > -1

// https://stackoverflow.com/questions/7944460/detect-safari-browser#comment58303132_31732310
export const isSafari = typeof navigator !== 'undefined' && navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1

export const isBeekseBergen = process.env.NEXT_PUBLIC_APP === APP_BEEKSEBERGEN;
