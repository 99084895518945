import merge from 'lodash.merge'
import * as t from './actionTypes'

const initialState = {
  dates: {}
}

const dates = (state = initialState, action) => {
  switch (action.type) {
    case t.SET_DATES: {
      return merge({}, state, action.dates)
    }
    default: {
      return state
    }
  }
}

const calendar = (state = {}, action) => {
  switch (action.type) {
    case t.SET_DATES: {
      return Object.assign({}, state, {
        [action.resort]: dates(state[action.resort], action)
      })
    }
    default: {
      return state
    }
  }
}

export default calendar
