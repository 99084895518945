import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { Form } from "@libema/design-system";
import { Form as FormLegacy, Button, Col } from "@libema/design-system-legacy";
import { Formik, Field } from "formik";
import styled from "styled-components";
import trans from "counterpart";
import qs from "query-string";
import { connect } from "react-redux";
import { setVoucher } from "../../../actions/preferenceActions";
import { ChevronRight } from "../../../components/icons/Chevron";
import { Clear } from "../../../components/icons/Clear";
import { updateVoucherCriteria } from "../actions";
import voucherUtils from "../../../utilities/voucherUtils";

const initialValues = { voucher: "" };

const Wrapper = styled.div``;

const StyledForm = styled(FormLegacy)`
  display: grid;
  grid-template-columns: auto 70px;
  margin-top: 8px;
  margin-bottom: 16px;

  input {
    padding-top: 4px;
    padding-bottom: 4px;
    min-height: 40px;
  }

  button {
    padding: 4px 8px;
    .material-icons {
      color: #fff;
    }
  }

  .form-control {
    margin-top: 4px;
  }

  .form-control-feedback.text-danger {
    grid-column: 1;
  }
`;

const StayInfoVoucherForm = ({
  validateVoucher,
  serverError,
  isFetching,
  updateVoucherCriteria,
  voucherCode,
  setVoucher,
}) => {
  const router = useRouter();
  const voucherForm = useRef();
  const hasMounted = useRef(false);
  const [voucherIsValid, setVoucherIsValid] = React.useState(false);

  const onClearVoucher = () => {
    const { resetForm } = voucherForm.current;

    resetForm({ values: { voucher: "" } });
    handleSubmit({ voucher: "" }, voucherForm.current);
  };

  const handleSubmit = ({ voucher }, { setFieldError, setFieldValue }) =>
    validateVoucher({ voucher })
      .then(() => {
        const [pathname] = router.asPath.split("?");
        if (voucher) {
          setVoucherIsValid(true);
        } else {
          setVoucherIsValid(false);
          voucherUtils.deleteVoucherCode();
        }
        updateVoucherCriteria(voucher);

        setFieldValue("voucher", voucher);
        setVoucher(voucher);

        setFieldValue("voucher", voucher);
        setVoucher({ voucherCode: voucher });

        let query = { ...router.query, voucher_code: voucher };

        if (query.slug) {
          delete query.slug;
        }

        const queryString = qs.stringify(query, { skipEmptyString: true });
        router.replace(`${pathname}?${queryString}`, undefined, {
          shallow: true,
        });
      })
      .catch((err) => {
        setVoucherIsValid(false);
        setFieldError(
          "voucher",
          trans(`error.api.${err.response.data?.code.toLowerCase()}`, {
            fallback: trans("error.api.unexpected"),
          })
        );
      });

  useEffect(() => {
    let voucher = voucherCode;

    if (voucherForm.current) {
      const { values, setFieldValue } = voucherForm.current;
      const isStartingWithoutVoucher = !hasMounted.current && !voucherCode;

      if (isStartingWithoutVoucher) {
        hasMounted.current = true;
        voucher = qs.parse(router.asPath).voucher;
        if (voucher) {
          handleSubmit({ voucher }, voucherForm.current);
        }
      }

      if (voucher !== values.voucher) {
        setFieldValue("voucher", voucher);
        setVoucher(voucher);
      }
    }
  }, [voucherCode]);

  useEffect(() => {
    if (voucherCode && !isFetching && serverError == null) {
      setVoucherIsValid(true);
    }
  }, [voucherCode, isFetching, serverError]);

  return (
    <Wrapper>
      <Col col={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          innerRef={voucherForm}
        >
          {({ isSubmitting, errors }) => (
            <StyledForm>
              <Field
                name="voucher"
                component={Form.Formik.TextField}
                isValid={!isSubmitting && voucherIsValid}
                placeholder={trans("placeholder.voucher")}
              />
              {!voucherIsValid && !errors.voucher && !serverError && (
                <Button type="submit" disabled={isSubmitting}>
                  <ChevronRight />
                </Button>
              )}
              {(voucherIsValid || errors.voucher || serverError) && (
                <Button onClick={onClearVoucher}>
                  <Clear />
                </Button>
              )}
              {errors.voucher && (
                <div className="form-control-feedback text-danger">
                  {errors.voucher}
                </div>
              )}
            </StyledForm>
          )}
        </Formik>
      </Col>
    </Wrapper>
  );
};

StayInfoVoucherForm.propTypes = {
  validateVoucher: PropTypes.func.isRequired,
  serverError: PropTypes.arrayOf(PropTypes.string),
  isFetching: PropTypes.bool,
};

const mapState = (state) => ({
  voucherCode: state.preferences.voucher ?? "",
  serverError: state.reservation?.errors,
  isFetching: state.reservation?.isFetching,
});

const mapDispatch = (dispatch) => ({
  setVoucher: (value) => dispatch(setVoucher(value)),
  updateVoucherCriteria: (voucher) => dispatch(updateVoucherCriteria(voucher)),
});

export default connect(mapState, mapDispatch)(StayInfoVoucherForm);
