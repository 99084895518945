import styled from "styled-components";
import P from "../typography/P";
import H1 from "../typography/H1";
import H2 from "../typography/H2";
import H3 from "../typography/H3";
import Button from "../Button";
import { DEVICE_LG_UP, DEVICE_MD_DOWN } from "../../utils/deviceUtils";

export const Content = styled.div`
  padding: ${({ theme }) => theme.padding.lg};
  color: white;

  & > ${H1}, & > ${H2}, & > ${H3} {
    font-size: 1.7rem;
    color: white;
    margin-bottom: ${({ theme }) => theme.margin.sm};
  }

  & > ${P} {
    color: white;
    margin-bottom: ${({ theme }) => theme.margin.md};
  }

  & > ${Button} {
    color: white;
    border-color: white;
  }
`;

export const ImageOnly = styled.div`
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  & span:first-child {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;

    img {
      position: relative !important;
      width: 100% !important;
      height: 100% !important;
    }
  }

  & > ${H2} {
    color: white;
    font-size: 1.7rem;
    font-weight: bold;
  }

  ${Content} {
    position: absolute;
    top: 0;
  }

  & > button {
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);

    @media ${DEVICE_MD_DOWN} {
      transform: scale(0.6);
      top: calc(65% - 50px);
    }
  }
`;

export const Visual = ({ src, alt }) => {
  return <img src={src} alt={alt} />;
};

export const TextOnly = styled.div`
  background-color: ${({ theme }) => theme.color.secondary};
  min-height: 200px;
`;

export const TextImage = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.neutralMedium};
  min-height: 200px;
  align-items: stretch;

  ${Content} {
    flex: 0 0 50%;
    overflow: hidden;
  }
`;

export const ImageWrapper = styled.div`
  flex: 0 0 50%;
  position: relative;

  & > span {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;

    img {
      width: 100% !important;
      height: 100% !important;
      position: absolute !important;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

type WrapperProps = {
  mirror?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;

  ${({ mirror }) =>
    !mirror &&
    `
    flex-direction: column;
  `}

  ${({ mirror }) =>
    mirror &&
    `
    flex-direction: column-reverse;
  `}

  @media ${DEVICE_LG_UP} {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 50% 50%;

    ${({ mirror }) =>
      !mirror &&
      `
      ${ImageOnly} {
        grid-column: 2;
        grid-row: 1 / 3;
      }

      ${TextOnly} {
        grid-column: 1;
        grid-row: 1;
      }

      ${TextImage} {
        grid-column: 1;
        grid-row: 2;
      }
    `}

    ${({ mirror }) =>
      mirror &&
      `
      ${ImageOnly} {
        grid-column: 1;
        grid-row: 1 / 3;
      }

      ${TextOnly} {
        grid-column: 2;
        grid-row: 1;
      }

      ${TextImage} {
        grid-column: 2;
        grid-row: 2;
        flex-direction: row-reverse;
      }
    `}
  }
`;

export default Wrapper;
