import React, { HTMLProps } from "react";
import styled from "styled-components/macro";
import { DEVICE_LG_UP, DEVICE_MD_UP } from "../utils/deviceUtils";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1050;
  display: flex;
  align-items: baseline;
`;

export const ModalContainer = styled.div`
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  z-index: 10;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  background: #fff;
  @media ${DEVICE_MD_UP} {
    width: 70%;
  }
  @media ${DEVICE_LG_UP} {
    width: 40%;
  }
`;

export const ModalBody = styled.div`
  padding: ${({ theme }) => theme.padding.lg};
`;

export const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background: rgba(0, 0, 0, 0.5);
`;

export const Window = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  /* We add extra bottom padding here on mobile to workaround an issue in Safari where the bottom bar is rendered over the view */
  padding: 1rem 1rem 120px 1rem;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  z-index: 1;
  @media ${DEVICE_MD_UP} {
    padding: 6rem 0;
  }
`;

export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
  </svg>
);

export const ModalCloseButtonWrapper = styled.button<
  HTMLProps<HTMLButtonElement>
>`
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: ${({ theme }) => theme.color.neutralDark};
  width: 2rem;
  height: 2rem;
  cursor: pointer;

  @media ${DEVICE_MD_UP} {
    width: 3rem;
    height: 3rem;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.5rem;
    height: 1.5rem;
    fill: white;
  }
`;

export const ModalCloseButton: React.FC<HTMLProps<HTMLButtonElement>> = ({
  onClick,
}) => (
  <ModalCloseButtonWrapper onClick={onClick}>
    <CloseIcon />
  </ModalCloseButtonWrapper>
);

type ModalProps = {
  isOpen: boolean;
  afterClose?: () => void;
  disableOnBackgroundClick?: boolean;
};

class Modal extends React.Component<ModalProps> {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.open();
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.open();
    } else if (!isOpen && prevProps.isOpen) {
      this.close();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }

  open = () => {
    this.setState({ isOpen: true });
    document.body.classList.add("modal-open");
  };

  close = () => {
    const { afterClose } = this.props;
    document.body.classList.remove("modal-open");
    this.setState({ isOpen: false });
    afterClose && afterClose();
  };

  handleDismiss = (event) => {
    if (event.target === event.currentTarget) {
      this.close();
    }
  };

  render() {
    const { children, disableOnBackgroundClick } = this.props;
    const { isOpen } = this.state;

    if (!isOpen) {
      return null;
    }

    const childrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child) && child.type === ModalCloseButton) {
        return React.cloneElement(child, {
          ...child.props,
          onClick: this.close,
        });
      }
      return child;
    });

    return (
      <ModalWrapper>
        <Window
          onClick={!disableOnBackgroundClick ? this.handleDismiss : undefined}
        >
          <ModalContainer>{childrenWithProps}</ModalContainer>
        </Window>
        <ModalBackground onClick={this.close} />
      </ModalWrapper>
    );
  }
}

export default Modal;
