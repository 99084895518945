import React, { InputHTMLAttributes } from "react";
import styled from "styled-components/macro";

type CheckMarkProps = {
  error?: boolean;
};

const CheckMark = styled.span<CheckMarkProps>`
  display: block;
  position: relative;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid
    ${({ theme, error }) => (error ? theme.color.error : theme.color.dark)};

  :after {
    content: "";
    position: absolute;
    display: none;
    left: 8px;
    top: 5px;
    width: 7px;
    height: 12px;
    border: solid ${({ theme }) => theme.color.dark};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const CheckboxWrapper = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 1.1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* On mouse-over, add a grey background color */
  :hover input ~ ${CheckMark} {
    background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ ${CheckMark} {
    background-color: #fff;
  }

  /* Show the checkmark when checked */
  input:checked ~ ${CheckMark}:after {
    display: block;
  }
`;

type CheckboxProps = {
  error?: boolean;
  onChange: (value: boolean) => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">;

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  error,
  onChange,
  ...restProps
}) => (
  <CheckboxWrapper onClick={() => onChange(!checked)}>
    <input
      {...restProps}
      type="checkbox"
      checked={checked}
      onChange={() => {
        // Do nothing, since this element is actually hidden.
        // The change is handled by the `onClick` on the wrapping element
      }}
    />
    <CheckMark error={error} />
  </CheckboxWrapper>
);

export default Checkbox;
