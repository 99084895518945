import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { darken } from "polished";
import { connect } from "react-redux";
import { withRouter } from "next/router";
import trans from "counterpart";
import { Col } from "reactstrap";
import styled, { css } from "styled-components";
import { ButtonOrange } from "../../../components/Button";
import {
  additionPriceLinesSelector,
  accoPriceLinesSelector,
  resourceInReservationSelector,
  reservationSelector,
} from "../selectors";
import { handleReservationReceiptSubmit, removeAddition } from "../actions";
import ReceiptContext from "../../../contexts/ReceiptContext";
import { getPreference } from "../../../selectors/preferenceSelectors";
import reservationSelectors from "../../../selectors/reservationSelectors";
import Loader from "../../../components/loaders/Loader";
import {
  ID_SAFARIRESORT,
  ID_SAFARIRESORT_HOTEL,
} from "../../booking/constants";

const MobileButtonWrapper = styled(Col)`
  background: #fff;
  padding: 10px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 2px;
  right: 12px;
  display: inline-flex;
  width: 18px;
  height: 18px;
  background-color: #dd350b;
  color: white;
  padding: 0;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: 8px;

  &:hover {
    background-color: ${darken(0.1, "#DD350B")};
  }

  &:focus {
    border: 2px solid #0b66dd;
  }

  & .material-icons {
    font-size: 12px;
  }
`;

const Name = styled.span``;
const Price = styled.span``;
const TotalPrice = styled.span``;
const Lines = styled.ul``;
const AdditionalLines = styled.div.attrs({
  className: "mb-0 pl-1 pr-1 pb-1 pt-0",
})``;

const StyledReceipt = styled.div`
  ${({ hasRemovable }) =>
    hasRemovable &&
    css`
      ${Price},
      ${TotalPrice},
    ${AdditionalLines} > ul {
        padding-right: 24px !important;
      }
    `}
`;

const canRemoveAdditionFromReceipt = (reservedResource) =>
  reservedResource.type === "PRODUCTTYPE" &&
  !reservedResource.imply &&
  process.env.NEXT_PUBLIC_APP !== "beeksebergen";

class ReservationReceipt extends Component {
  state = {
    disabled: false,
  };

  getTotalPriceLabel = () => {
    const { totalPrice } = this.props;
    if (!totalPrice) {
      return "Geen prijs gevonden.";
    }
    return this.addZeros(totalPrice.toFixed(2).replace(".", ","));
  };

  getPrice(line) {
    return this.addZeros(line.total.toFixed(2).replace(".", ","));
  }

  handleOnSubmit = (toggle) => {
    const { arrival, router, resortId } = this.props;
    this.setState({ disabled: true });
    this.props
      .handleSubmit()
      .then((result) => {
        this.setState({ disabled: false });
        if (!result) {
          return toggle();
        }
        return router.push(
          `${trans("routes.payment_request")}?resort=${resortId}`
        );
      })
      .catch(() => {
        this.setState({ disabled: false });
        return toggle();
      });
  };

  addZeros = (total) => {
    if (total.substring(total.indexOf(","), total.length).length < 2) {
      total += "0";
      return this.addZeros(total);
    }
    return total;
  };

  showQuantity(line) {
    if (line.quantity <= 1) return false;
    if (line.name.split(" ").join("").toLowerCase() === "lokaleheffingen") {
      return false;
    }
    return true;
  }

  isKamerVlinder(resource) {
    if (typeof resource !== "undefined" && resource.id === 68) {
      return true;
    }
    return false;
  }

  isCampingSpot(resource) {
    if (typeof resource !== "undefined" && resource.kind === 5) {
      return true;
    }
    return false;
  }

  removeAddition(addition) {
    const { handleRemoveAddition } = this.props;
    handleRemoveAddition(addition.resourceId);
  }

  render() {
    const {
      accoPriceLines,
      fromPrice,
      properties,
      reservation,
      resource,
      resortId,
      totalPrice,
      arrival,
      hasRemovable,
    } = this.props;

    const isSafariResort = [ID_SAFARIRESORT, ID_SAFARIRESORT_HOTEL].includes(
      resortId
    );
    const isHotel = resortId === ID_SAFARIRESORT_HOTEL;

    return (
      <ReceiptContext.Consumer>
        {(values) => (
          <StyledReceipt className="row" hasRemovable={hasRemovable}>
            <div className="col-sm-12">
              <Loader
                isShown={reservation.isFetching}
                label={trans("status.loading_reservation")}
              />
              {!reservation.isFetching && reservation.customerBill.length > 0 && (
                <>
                  <div className="bg-receipt">
                    <Lines className="list-unstyled mb-0 pt-1 pr-1 pl-1 pb-0">
                      {accoPriceLines.map((reservedResource) => (
                        <li className="row" key={reservedResource.resourceId}>
                          <div className="col-5">
                            <h5 className="text-lato text-info">
                              {reservedResource.name}
                            </h5>
                          </div>
                          <div className="col-3 text-right px-0">
                            {fromPrice > totalPrice && (
                              <del className="text-muted font-italic">
                                {fromPrice.toFixed(2).replace(".", ",")}
                              </del>
                            )}
                          </div>
                          <div className="col-4 text-right pl-0">
                            <TotalPrice>{this.getTotalPriceLabel()}</TotalPrice>
                          </div>
                        </li>
                      ))}
                      <li className="mt-1">
                        <div className="text-black mb-0">
                          <p>
                            <strong>{trans("pricing.all_in_help")}</strong>
                          </p>
                        </div>
                      </li>
                      {reservation.customerBill
                        .filter((line) => line.billLineType === 10)
                        .map((line) => {
                          const reservedAddition =
                            reservation.reservedResources.find(
                              (resource) =>
                                resource.resourceId === line.resourceId
                            );
                          return (
                            <li className="row" key={line.resourceId}>
                              <div className="col-9">
                                <Name>{line.name}</Name>
                              </div>
                              <div className="col-3 text-right pl-0">
                                <Price>{this.getPrice(line)}</Price>
                                {canRemoveAdditionFromReceipt(
                                  reservedAddition
                                ) && (
                                  <RemoveButton
                                    onClick={() => this.removeAddition(line)}
                                  >
                                    <span className="material-icons">
                                      close
                                    </span>
                                  </RemoveButton>
                                )}
                              </div>
                            </li>
                          );
                        })}
                    </Lines>
                    <div className={`locale-${trans("locale")}`}>
                      {isSafariResort &&
                        !this.isCampingSpot(resource) &&
                        properties.reservation_resort_info && (
                          <>
                            {isHotel && (
                              <div className="pl-1 pr-1">
                                <ul class="list-included mb-0">
                                  <li>{trans("receipt.breakfast_buffet")}</li>
                                </ul>
                              </div>
                            )}
                            <AdditionalLines
                              dangerouslySetInnerHTML={{
                                __html: properties.reservation_resort_info,
                              }}
                            />
                          </>
                        )}
                      {!isSafariResort &&
                        !this.isKamerVlinder(resource) &&
                        !this.isCampingSpot(resource) &&
                        properties.reservation_vakantiepark_info && (
                          <>
                            <span>vakantiepark</span>
                            <AdditionalLines
                              dangerouslySetInnerHTML={{
                                __html:
                                  properties.reservation_vakantiepark_info,
                              }}
                            />
                          </>
                        )}
                      {this.isCampingSpot(resource) &&
                        properties.reservation_camping_info && (
                          <AdditionalLines
                            dangerouslySetInnerHTML={{
                              __html: properties.reservation_camping_info,
                            }}
                          />
                        )}
                      {this.isKamerVlinder(resource) &&
                        properties.reservation_kamer_vlinder_info && (
                          <AdditionalLines
                            dangerouslySetInnerHTML={{
                              __html: properties.reservation_kamer_vlinder_info,
                            }}
                          />
                        )}
                    </div>

                    <div className="row mx-0 total">
                      <div className="col-6 px-0 p-1">
                        {trans("pricing.total_amount")}
                      </div>
                      <div className="col-6 px-0">
                        <div className="p-1 text-right pricetag float-right">
                          <span className="h3">
                            {this.getTotalPriceLabel()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <MobileButtonWrapper xs="12">
                    <ButtonOrange
                      disabled={this.state.disabled}
                      onClick={() => this.handleOnSubmit(values.toggle)}
                    >
                      {moment(arrival, "YYYY-MM-DD").subtract(
                        process.env.NEXT_PUBLIC_PAYMENT_TERMS * 7,
                        "days"
                      ) < moment() &&
                        trans("button.reservation_confirmation_and_pay")}
                      {moment(arrival, "YYYY-MM-DD").subtract(
                        process.env.NEXT_PUBLIC_PAYMENT_TERMS * 7,
                        "days"
                      ) >= moment() &&
                        (this.props.properties.buttonLabel || "Reserveren")}
                    </ButtonOrange>
                  </MobileButtonWrapper>
                </>
              )}
            </div>
          </StyledReceipt>
        )}
      </ReceiptContext.Consumer>
    );
  }
}

ReservationReceipt.propTypes = {
  accoPriceLines: PropTypes.array,
  arrival: PropTypes.string,
  fromPrice: PropTypes.number,
  router: PropTypes.object,
  handleSubmit: PropTypes.func,
  properties: PropTypes.object,
  reservation: PropTypes.object,
  resortId: PropTypes.number,
  resource: PropTypes.object,
  totalPrice: PropTypes.number,
  handleRemoveAddition: PropTypes.func,
  hasRemovable: PropTypes.bool,
};

ReservationReceipt.defaultProps = {
  handleRemoveAddition: () => {},
};

const mapStateToProps = (state) => {
  const reservation = reservationSelector(state);
  const hasRemovable = reservation?.reservedResources?.some(
    canRemoveAdditionFromReceipt
  );

  return {
    reservation,
    hasRemovable,
    arrival: getPreference("arrival")(state),
    resource: resourceInReservationSelector(state),
    additionPriceLines: additionPriceLinesSelector(state),
    accoPriceLines: accoPriceLinesSelector(state),
    resortId: reservationSelectors.getResortId(state),
    totalPrice: reservationSelectors.getTotalPrice(state),
    fromPrice: reservationSelectors.getFromPrice(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSubmit: () => dispatch(handleReservationReceiptSubmit()),
  handleRemoveAddition: (id) => dispatch(removeAddition(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReservationReceipt));
