import BaseModel from "./BaseModel";
import Image from "./Image";
import RichText from "./RichText";
import Link from "./Link";
import TYPES from "../../../constants/types";
import Video from "./Video";

export enum SectionHeroDisplayStyle {
  SHORT = "SHORT",
  LARGE = "LARGE",
  BREAKOUT = "BREAKOUT",
}

type SectionHero = BaseModel<TYPES.SectionHero> & {
  media: Image | Video;
  content?: RichText;
  buttons?: Link[];
  heroDisplayStyle?: SectionHeroDisplayStyle;
  children?: React.ReactNode;
  withDarkGradient?: boolean;
  videoThumbnail?: Image;
};

export default SectionHero;
