import React from 'react'
import PropTypes from 'prop-types'
import qs from 'query-string'
import { withRouter } from 'next/router'
import { connect } from 'react-redux'
import trans from 'counterpart'
import { reduxForm, Field } from 'redux-form'
import { submitSubjects } from '../../reservation/actions'
import { subjectFormFieldSelector } from '../selectors'
import { getSubjects } from '../../../selectors/entitySelectors'

const handleIncrement = (input) => {
  const value = !input.value ? 0 : parseInt(input.value, 10)
  if (value === 20) {
    return null
  }
  return input.onChange(value + 1)
}

const handleDecrement = (input) => {
  const value = !input.value ? 0 : parseInt(input.value, 10)
  if (value === 0) {
    return null
  }
  return input.onChange(value - 1)
}

const NumericField = ({ input, key, label, meta: { touched, error } }) => (
  <div className={`form-group row mb-1 ${touched && error && 'has-danger'}`} key={key}>
    <label className='label col-7' style={{ margin: 0, lineHeight: '40px' }}>{label}</label>
    <div className='col-5'>
      <div tabIndex='-1' className='rw-numberpicker rw-widget'>
        <span className='rw-select'>
          <button
            tabIndex='-1'
            title='increment value'
            type='button'
            aria-label='increment value'
            className='rw-btn'
            onClick={() => handleIncrement(input)}
          >
            <span className='rw-i rw-i-caret-up' />
          </button>
          <button
            tabIndex='-1'
            title='decrement value'
            type='button'
            aria-label='decrement value'
            className='rw-btn'
            onClick={() => handleDecrement(input)}
          >
            <span className='rw-i rw-i-caret-down' />
          </button>
        </span>
        <input
          value={!input.value ? 0 : parseInt(input.value, 10)}
          aria-valuemin='0'
          aria-valuenow={!input.value ? 0 : parseInt(input.value, 10)}
          aria-valuemax='20'
          className='rw-input'
          type='text'
          name={input.name}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
          onChange={input.onChange}
          role='spinbutton'
        />
      </div>
    </div>
    {error && <div className='form-control-feedback'>{error}</div>}
  </div>
)

NumericField.propTypes = {
  input: PropTypes.object,
  key: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.object
}

class TravelCompanyForm extends React.Component {
  onSubmit = (values, dispatch) => {
    const { router, toggle, clearSubmitErrors, error } = this.props
    const subjects = Object.entries(values)
      .reduce((acc, [subject, quantity]) => {
        if (parseInt(quantity, 10) > 0) {
          acc.push(`${subject.substr(8, subject.length)}:${quantity}`)
        }
        return acc
      }, [])
    submitSubjects(values, dispatch, error, clearSubmitErrors)

    const queryString = qs.stringify({ ...router.query, subjects })
    const [pathname] = router.asPath.split('?')
    router.replace(`${pathname}?${queryString}`, undefined, { shallow: true })

    return toggle()
  }

  render () {
    const { subjects, submitting, handleSubmit, error } = this.props

    return (
      <form onSubmit={handleSubmit(this.onSubmit)} className='mb-0 mt-5'>
        {error && <div className='alert alert-danger'>{error}</div>}
        {Object.keys(subjects).map(item => (
          <Field
            component={NumericField}
            key={subjects[item].name}
            name={`subject-${item}`}
            label={subjects[item].name}
          />
        ))}
        <div className='clearfix mx-0 mb-md-4'>
          <button type='submit' disabled={submitting} className='btn btn-primary btn-lg btn-reset-lh h3 float-right mt-1'>
            <strong>{trans('button.edit')}</strong>
          </button>
        </div>
      </form>
    )
  }
}

TravelCompanyForm.propTypes = {
  clearSubmitErrors: PropTypes.func,
  subjects: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  toggle: PropTypes.func,
  router: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  subjects: getSubjects(state),
  initialValues: subjectFormFieldSelector(state)
})

export default connect(
  mapStateToProps
)(withRouter(reduxForm({
  form: 'travelCompany',
  destroyOnUnmount: false,
  enableReinitialize: true
})(TravelCompanyForm)))
