import React from 'react'
import PropTypes from 'prop-types'
import Filters from '../Filters'

const BookingSearchFilterBar = ({ filterableProperties, focus, onChange }) => (
  <div className='row' id='filters'>
    <div className='col-12'>
      <div className='filters'>
        <div className='tab-content'>
          <div className='tab-pane fade show active row' id='filters' role='tabpanel'>
            <Filters
              filterableProperties={filterableProperties}
              template='bookingsearchblock'
              showDatePickerLegend
              showAdvancedFilters
              focus={focus}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

BookingSearchFilterBar.propTypes = {
  filterableProperties: PropTypes.array,
  focus: PropTypes.object,
  onChange: PropTypes.func.isRequired
}

export default BookingSearchFilterBar
