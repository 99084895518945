import { normalize } from 'normalizr'
import qs from 'query-string'
import { get } from '../../utilities/api'
import { setEntities } from '../../actions/entityActions'

import {
  accommodationtypeSchema,
  countrySchema,
  objectSchema,
  resortSchema,
  subjectSchema,
  propertyGroupSchema,
  additionSchema
} from './schemas'
import { getAccommodationTypes } from '../../utilities/api/accommodationTypeApi'

export const fetchAccommodationType = id => (dispatch, getState) => get({
  url: `/accommodationtypes/${id}`,
  params: {
    locale: getState().preferences.locale
  }
})
  .then(({ data }) => {
    const normalized = normalize(data, accommodationtypeSchema)
    return dispatch(setEntities(normalized.entities))
  })

let fetchAccommodationTypesPromise = null
export const fetchAccommodationTypes = () => (dispatch, getState) => {
  if (fetchAccommodationTypesPromise) return fetchAccommodationTypesPromise

  fetchAccommodationTypesPromise = getAccommodationTypes({ locale: getState().preferences.locale })
    .then(({ data }) => {
      const normalized = normalize(data, [accommodationtypeSchema])
      dispatch(setEntities(normalized.entities))
      fetchAccommodationTypesPromise = null
      return data
    })

  return fetchAccommodationTypesPromise
}

export const fetchPropertyGroups = () => (dispatch, getState) => get({
  url: '/propertygroups',
  params: { locale: getState().preferences.locale }
})
  .then(({ data }) => {
    const normalized = normalize(data, [propertyGroupSchema])
    dispatch(setEntities(normalized.entities))
  })

export const fetchSubjects = () => (dispatch, getState) => get({
  url: '/subjects',
  params: { locale: getState().preferences.locale }
})
  .then(({ data }) => {
    const normalized = normalize(data, [subjectSchema])
    return dispatch(setEntities(normalized.entities))
  })

export const getCountries = () => (dispatch, getState) => get({
  url: '/countries',
  params: { locale: getState().preferences.locale }
})
  .then(({ data }) => {
    const normalized = normalize(data, [countrySchema])
    dispatch(setEntities(normalized.entities))
  })

/**
 * @param {{ resource: number, locale: string }} criteria
 */
export const getObjects = (criteria) => dispatch => get({ url: '/v2/objects', params: criteria })
  .then(({ data }) => {
    const normalized = normalize(data, [objectSchema])
    dispatch(setEntities(normalized.entities))
  })

export const getResorts = () => dispatch => get({ url: '/resorts' })
  .then(({ data }) => {
    const normalized = normalize(data, [resortSchema])
    dispatch(setEntities(normalized))
  })

export const fetchAdditions = () => (dispatch, getState) => get({
  url: '/v2/resourceadditions',
  params: {
    locale: getState().preferences.locale
  }
})
  .then(({ data }) => {
    const normalized = normalize(data, [additionSchema])
    return dispatch(setEntities(normalized.entities))
  })

export const createMoreInfoUrl = moreInfoUrlObject => qs.stringify(moreInfoUrlObject, { arrayFormat: 'bracket' })
