import React, { FC } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

type Props<V extends Record<string, any> = Record<string, React.ReactNode>> = {
  values?: V;
  tagName?: React.ElementType<any>;
  // children?: (
  //   ...nodes: React.ReactNodeArray | React.ReactElement
  // ) => React.ReactNode | React.ReactElement;
  children?: any;
} & MessageDescriptor;

/**
 * When using google translate or another plugin that needs to manipulate the DOM,
 * we need to wrap simple texts or fragments into valid elements that won't be removed from the DOM.
 */
const Translate: FC<Props> = (props) => {
  return (
    <span>
      <FormattedMessage {...props} />
    </span>
  );
};

export default Translate;
