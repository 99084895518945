const TWO_DAYS = 86400000 * 2;

/**
 * Store data with a certain expiration time
 *
 * @param {string} key
 * @param {object} value
 * @param {number} ttl in milliseconds
 */
export const setWithExpiry = (key, value, ttl = TWO_DAYS) => {
  if (!value) return null;
  try {
    const now = new Date();
    const expiry = now.getTime() + ttl;

    const item = {
      value,
      expiry,
    };
    localStorage.setItem(key, JSON.stringify(item));
    return value;
  } catch {}
};

/**
 * Get data and validate expiry time
 *
 * @param {string} key
 */
export const getWithExpiry = (key) => {
  try {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const isExpired = new Date().getTime() > item.expiry;

    // Check whether the items has been expired
    if (isExpired) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  } catch {
    return null;
  }
};
