import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import abstractBlock from '../../../components/AbstractBlock'
import SelectField from '../../form/components/SelectField'

const SelectCountry = ({ countries }) => <Field name='country' component={SelectField} options={countries} />

SelectCountry.propTypes = {
  countries: PropTypes.array
}

const mapStateToProps = state => ({
  countries: state.entities.countries ? Object.keys(state.entities.countries).reduce((a, b) => {
    a[state.entities.countries[b].code] = state.entities.countries[b].name
    return a
  }, []) : []
})

export default connect(mapStateToProps)(abstractBlock(SelectCountry))
