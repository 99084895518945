import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import reservationSelectors from "../../../selectors/reservationSelectors";
import analyticsUtils from "../../../utilities/analyticsUtils";

class TrackBlock extends Component {
  static propTypes = {
    price: PropTypes.number,
    reservation: PropTypes.object,
  };
  componentDidMount() {
    const { price, reservation } = this.props;
    window.dataLayer = window.dataLayer || [];
    if (reservation && reservation.offer && price !== null) {
      const checkoutEvent = {
        event: "begin_checkout",
        ecommerce: {
          currency: "EUR",
          value: `${parseFloat(price)}`,
          coupun: `${
            reservation.criteria.special_code == null
              ? "no special code"
              : reservation.criteria.special_code
          }`,
        },
        items: [
          {
            item_id: `${reservation.offer.object_name}`,
            item_name: `${reservation.offer.kind_name}`,
            //affilitiation: we ware missing the affiliation, quite a big one I think
            price: `${parseFloat(price)}`,
            category: `${
              reservation.criteria.special_code == null
                ? "no special code"
                : reservation.criteria.special_code
            }`,
            quantity: 1,
          },
        ],
      };

      window.dataLayer.push(checkoutEvent);
    }
  }

  componentDidUpdate(prevProps) {
    const { reservation } = this.props;

    const isFetching = reservation?.isFetching;
    const previousIsFetching = prevProps?.reservation?.isFetching;

    const isFetchingChanged =
      isFetching != null && isFetching !== previousIsFetching;

    if (isFetchingChanged) {
      this.trackAccommodationView();
    }
  }

  trackAccommodationView() {
    const { price, reservation } = this.props;

    if (reservation != null && !reservation.isFetching && price !== null) {
      analyticsUtils.handleAccommodationView({
        id: reservation.criteria?.resource_id,
        price,
        startDate: reservation.arrivalDate,
        endDate: reservation.departureDate,
      });
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  price: reservationSelectors.getTotalPrice(state),
  reservation: state.reservation,
});

export default connect(mapStateToProps)(TrackBlock);
