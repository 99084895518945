import styled from "styled-components";

import H3 from "../typography/H3";
import P from "../typography/P";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Name = styled(H3)`
  color: ${({ theme }) => theme.color.secondary};
  margin: 0;
  font-size: 1.25rem;
  line-height: 1;
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.color.grey_800};
  font-size: 1rem;
  margin: 0;
`;

export const Avatar = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 12px;

  & img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default Wrapper;
