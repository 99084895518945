import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getReservationNumber } from '../../../selectors/reservationSelectors'

const ReservationConfirmation = ({ reservationNumber, properties: { content } }) => (
  <div
    dangerouslySetInnerHTML={{ __html: content.replace('%reservation_number%', reservationNumber) }}
  />
)

ReservationConfirmation.propTypes = {
  properties: PropTypes.object,
  reservationNumber: PropTypes.string
}

const mapState = state => ({
  reservationNumber: getReservationNumber(state)
})

export default connect(mapState)(ReservationConfirmation)
