import update from "immutability-helper";
import * as t from "./actionTypes";

const initialState = {
  isConfirming: false,
  isFetching: true,
  // initialFetch has to be completed before objects can be fetched.
  initialFetch: false,
  errors: null,
  confirmed: false,
  criteria: {
    language: null,
    resource_id: null,
    arrival_date: null,
    duration: null,
    special_code: null,
    rate_type_id: null,
    preferences: [],
    subjects: [],
    additions: [],
  },
  customer: null,
  reservationId: null,
  reservationNumber: null,
  status: null,
  customerBill: [],
  reservedResources: [],
  unitIds: [],
  sections: {
    current: null,
    previous: null,
    next: null,
  },
  offer: null,
  // TODO: determine if this is the right place to keep filters
  filters: {
    addition_category: null,
    properties: {},
  },
  // Defined a default form for testing purposes. This is being overwritten by a reservationConfirmation
  payment_forms: [],
  // payment_forms: {
  //   ogoneWhole: '<form method="post" action="https://secure.ogone.com/ncol/test/orderstandard_utf8.asp" id="ogoneWhole" name="ogoneWhole">        <input type="hidden" name="PSPID" value="opifer2"  /><input type="hidden" name="ORDERID" value="00517001742000"  /><input type="hidden" name="AMOUNT" value="44310"  /><input type="hidden" name="CURRENCY" value="EUR"  /><input type="hidden" name="EMAIL" value="hello@rickvanlaarhoven.nl"  /><input type="hidden" name="LANGUAGE" value="nl_NL"  /><input type="hidden" name="ACCEPTURL" value="http://vagrant.dev/app_dev.php/booking/payment-confirmation"  /><input type="hidden" name="CANCELURL" value="http://vagrant.dev/app_dev.php/booking/confirmation"  /><input type="hidden" name="PARAMPLUS" value="distributionchannelId=4041&amp;employeeId=4081&amp;concernId=7"  />   <input type="hidden" name="SHASIGN" value="932D1964712FA9DAF7F20B76D270C30683B3052E" /></form>'
  // },
};

/**
 * This functions creates a separate addition object for each date in a given addition (if available)
 */
const spreadAdditions = (additions) =>
  additions.reduce((spreaded, addition) => {
    // If the addition has no dates, we just add it to the spreaded collection directly
    if (typeof addition.dates === "undefined") {
      return [...spreaded, addition];
    }

    // If the addition does have dates, we add a new addition for each date
    return [
      ...spreaded,
      ...addition.dates.map((date) => ({
        start_date: date,
        end_date: date,
        resource_id: addition.resource_id,
        quantity: addition.quantity,
      })),
    ];
  }, []);

const criteria = (state, action) => {
  switch (action.type) {
    case t.SET_RESERVATION_CRITERIA: {
      return {
        ...state,
        ...action.criteria,
      };
    }
    case t.REMOVE_ADDITION: {
      const { additionId } = action;

      return {
        ...state,
        additions: state.additions.filter(
          (addition) => addition.resource_id !== additionId
        ),
      };
    }
    case "UPDATE_ADDITION_CRITERIA": {
      const stateAdditions = [...state.additions];
      // Add the resource_id to the addition objects
      const mappedAdditions = Object.keys(action.payload.additions).map(
        (additionKey) => ({
          ...action.payload.additions[additionKey],
          resource_id: parseInt(additionKey.split("-")[1], 10),
        })
      );

      // Spread out the additions to get a separate object for each addition date
      const newAdditions = spreadAdditions(mappedAdditions);

      newAdditions.forEach((newAddition) => {
        // First we find the index of the new addition in the existing state additions
        // to see if it was already added before.
        const index = stateAdditions.findIndex(
          (currentAddition) =>
            currentAddition &&
            currentAddition.resource_id === newAddition.resource_id &&
            currentAddition.start_date === newAddition.start_date
        );

        // Check the index to see if the new addition already exists
        if (index >= 0) {
          // Item already exists, so we update the existing item
          if (newAddition.quantity > 0) {
            // If the quantity is greater than 0, we replace the existing addition
            stateAdditions[index] = { ...newAddition };
          } else {
            // If quantity is not greater than 0, we remove it from the array
            delete stateAdditions[index];
          }
        } else if (newAddition.quantity > 0) {
          // Add new item
          stateAdditions.push(newAddition);
        }
      });
      // If there are stateAdditions with dates that are not part of the new additions, remove them
      // only if the resource_ids are the same
      // if resource_ids are different do not remove
      const finalAdditions = stateAdditions.filter((stateAddition, i) => {
        const index = newAdditions.findIndex(
          (currentAddition) =>
            currentAddition.resource_id === stateAddition.resource_id &&
            currentAddition.start_date === stateAddition.start_date
        );
        if (index === -1 && stateAddition.start_date) {
          const indexTwo = newAdditions.findIndex(
            (currentAddition) =>
              currentAddition.resource_id === stateAddition.resource_id
          );
          if (indexTwo === -1) {
            return true;
          }
          return false;
        }
        return true;
      });
      return {
        ...state,
        additions: finalAdditions,
      };
    }
    default: {
      return state;
    }
  }
};

const filters = (state, action) => {
  switch (action.type) {
    case t.SET_FILTERS: {
      return {
        ...state,
        ...action.filters,
      };
    }
    case t.UPDATE_PROPERTY_COUNTS: {
      return Object.assign({}, state, {
        properties: action.properties,
      });
    }
    case t.SET_ADDITION_CATEGORY: {
      return {
        ...state,
        addition_category: action.category,
      };
    }
    default: {
      return state;
    }
  }
};

const sections = (state, action) => {
  switch (action.type) {
    case t.SET_INITIAL_SECTIONS: {
      return {
        ...state,
        current: action.current,
        next: action.next,
      };
    }
    case t.SET_NEXT_SECTION: {
      const bookingSections = action.sections;
      const idx = bookingSections.findIndex((s) => s.id === state.current.id);
      return {
        ...state,
        previous: state.current,
        current: state.next,
        next: bookingSections[idx + 2],
      };
    }
    case t.SET_CURRENT_SECTION: {
      const bookingSections = action.sections;
      const idx = bookingSections.findIndex((s) => s.id === action.current.id);

      return {
        ...state,
        previous: bookingSections[idx - 1] ? bookingSections[idx - 1] : null,
        current: action.current,
        next: bookingSections[idx + 1] ? bookingSections[idx + 1] : null,
      };
    }
    case t.PREVIOUS_SECTION: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};

const reservation = (state = initialState, action) => {
  switch (action.type) {
    case t.RESERVATION_INITIAL_FETCH: {
      return {
        ...state,
        initialFetch: action.payload,
        errors: null,
      };
    }
    case t.SET_OFFER: {
      return {
        ...state,
        offer: action.offer,
      };
    }
    case t.CLEAR_RESERVATION: {
      return { ...initialState };
    }
    case t.RESERVATION_PROPOSAL_START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case t.RESERVATION_RESTORE_SESSION:
    case t.RESERVATION_PROPOSAL_SUCCESS: {
      return {
        ...state,
        ...action.reservation,
        errors: null,
        isFetching: false,
      };
    }
    case t.RESERVATION_API_ERROR: {
      return {
        ...state,
        isFetching: false,
        isConfirming: false,
        errors: action.errors,
      };
    }
    case t.RESERVATION_CONFIRMATION_START: {
      return {
        ...state,
        isConfirming: true,
      };
    }
    case t.UPDATE_VOUCHER_CRITERIA: {
      return {
        ...state,
        criteria: {
          ...state.criteria,
          voucher_code: action.payload,
        },
      };
    }
    case t.RESERVATION_CONFIRMATION_SUCCESS: {
      return {
        ...state,
        ...action.reservation,
        errors: null,
        confirmed: true,
        criteria: {
          ...state.criteria,
          ...action.criteria,
        },
        isFetching: false,
        isConfirming: false,
      };
    }
    // leave
    case t.RESERVATION_CONFIRMATION_FAILURE: {
      return {
        ...state,
        isConfirming: false,
        errors: action.errors,
      };
    }
    case t.REMOVE_ADDITION:
    case "UPDATE_ADDITION_CRITERIA":
    case t.SET_RESERVATION_CRITERIA: {
      return Object.assign({}, state, {
        criteria: criteria(state.criteria, action),
      });
    }
    case t.SET_CUSTOMER: {
      return Object.assign({}, state, {
        customer: action.customer,
      });
    }
    case t.SET_ADDITION_CATEGORY:
    case t.UPDATE_PROPERTY_COUNTS:
    case t.SET_FILTERS: {
      return Object.assign({}, state, {
        filters: filters(state.filters, action),
      });
    }
    case t.SET_INITIAL_SECTIONS:
    case t.SET_CURRENT_SECTION:
    case t.SET_NEXT_SECTION:
    case t.PREVIOUS_SECTION: {
      return Object.assign({}, state, {
        sections: sections(state.sections, action),
      });
    }
    case t.SET_SECTION:
      if (
        state.sections.current &&
        state.sections.current.id === action.payload.current.id
      ) {
        return state;
      }
      return update(state, {
        sections: {
          previous: {
            $set: action.payload.previous,
          },
          current: {
            $set: action.payload.current,
          },
          next: {
            $set: action.payload.next,
          },
        },
      });
    default: {
      return state;
    }
  }
};

export default reservation;
