// eslint-disable-next-line
// @ts-nocheck
import React from "react";
import styled from "styled-components";
import BaseIcon from "@fortawesome/fontawesome-free/svgs/solid/info-circle.svg";
import Tooltip from "../tooltip/Tooltip";

const Icon = styled(BaseIcon)`
  fill: ${({ theme }) => theme.color.neutralDark};
`;

const IconWrapper = styled.div`
  position: relative;
  cursor: help;
  height: 1.5rem;
  width: 1.5rem;
`;

export type InfoIconProps = {
  content: string;
  placement: "top" | "bottom" | "right" | "left";
};

const InfoIcon: React.FC<InfoIconProps> = ({ content, placement }) => (
  <Tooltip content={content} placement={placement}>
    <IconWrapper>
      <Icon />
    </IconWrapper>
  </Tooltip>
);

export default InfoIcon;
