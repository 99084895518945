import React from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash.throttle'

class PositionedImageBlock extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isMobile: false
    }
    this.throttled = throttle(this.resizeHandler, 150, { leading: true })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.throttled)
    this.resizeHandler()
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.throttled)
  }

  resizeHandler = () => {
    const { isMobile } = this.state
    if (!isMobile && window.innerWidth < 800) {
      this.setState({ isMobile: true })
    }
    if (isMobile && window.innerWidth >= 800) {
      this.setState({ isMobile: false })
    }
  }

  render () {
    const { isMobile } = this.state
    const { properties, manageTags, media } = this.props

    const image = (media && media.original) ? (<img className='positioned-image' src={media.original} />) : null

    const style = {
      paddingTop: isMobile ? properties['padding-mobile'] : properties.padding
    }
    const classNames = `box ${properties.position} ${isMobile ? 'mobile' : 'desktop'}`
    return (
      <div {...manageTags} style={style} className={classNames}>
        {(properties && properties.link)
          ? (<a className='positioned-link' href={properties.link}>{image}</a>)
          : image}
      </div>
    )
  }
}

PositionedImageBlock.propTypes = {
  properties: PropTypes.object,
  manageTags: PropTypes.object,
  media: PropTypes.object
}

export default PositionedImageBlock
