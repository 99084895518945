const nl = {
  /**
   *  ==================================================================
   *  404 Page
   *  ==================================================================
   */
  "page.404.title": "Pagina niet gevonden | Klimrijk",
  "page.404.meta.description": "Pagina niet gevonden",
  "page.404.description":
    "Deze pagina bestaat niet (meer), of er zit een typefout in het adres.",
  "page.404.subtitle": "Wat te doen?",
  "page.404.action.1": "Controleer of het adres juist is gespeld",
  "page.404.button.home": "Terug naar de Homepagina",

  /**
   *  ==================================================================
   *  500 Page
   *  ==================================================================
   */
  "page.500.title": "Ooops! Er lijkt is iets fout te gaan.",
  "page.500.description":
    "Wij zullen dit zo spoedig mogelijk proberen op te lossen.",
  "page.500.button": "Terug naar de Homepagina",

  /**
   *  ==================================================================
   *  Article
   *  ==================================================================
   */
  "page.article.published.in": "Gepubliceerd in",

  /**
   *  ==================================================================
   *  NewsLetter Subscribe form
   *  ==================================================================
   */
  "newsletter.placeholder.email": "Jouw e-mailadres",
  "newsletter.button.subscribe": "Aanmelden",
  "newsletter.success":
    "Bedankt voor je aanmelding. Ter bevestiging hebben we jou een verificatie e-mail gestuurd.",
  "newsletter.validator.email_invalid": "Ongeldig e-mail adres",
  "newsletter.validator.required": "Dit veld is verplicht",

  /**
   *  ==================================================================
   *  Activity
   *  ==================================================================
   */
  "activity.duration":
    "{count, plural, one {Duur # minuut} other {Duur # minuten}}",
  "activity.min.age": "{minAge}+ jaar",
  "activity.min.height": "Minimaal {minHeight}m",
  "activity.min.persons":
    "{count, plural, one {Vanaf # persoon} other {Vanaf # personen}}",
  "activity.more.link": "Meer informatie",
  "activity.price.from": "v.a.",
  "activity.price.adult": "Prijs volwassene",
  "activity.price.child": "Prijs kind",
  "activity.price.free.lowercase": "gratis",

  "activity.filter.button": "Keuzehulp",
  "activity.filter.age": "Leeftijd",
  "activity.filter.duration": "Duur",

  "activity.filter.age.4.8": "4 - 8 jaar",
  "activity.filter.age.9.12": "9 - 12 jaar",
  "activity.filter.age.13.16": "13 - 16 jaar",
  "activity.filter.age.min.16": "Vanaf 16 jaar",
  "activity.filter.duration.max.20": "< 20 min",
  "activity.filter.duration.20.40": "20 - 40 min",
  "activity.filter.duration.41.60": "41 - 60 min",
  "activity.filter.duration.min.60": "Vanaf 60 min",
  "activity.sort.relevance": "Aanbevolen",
  "activity.sort.price_asc": "Prijs oplopend",
  "activity.sort.price_desc": "Prijs aflopend",
  "activity.open": "Open: {weekdays}",
  "activity.open_all_week": "Hele week geopend",

  /**
   *  ==================================================================
   *  Filters
   *  ==================================================================
   */
  "filter.title": "Filteren",
  "filter.buttons.clear": "Selectie wissen",
  "filter.buttons.close_modal": "Bekijk {count} resultaten",
  "filter.error.not_supported": "Filter {type} niet ondersteund",
  "filter.searchbox.placeholder": "Zoeken...",
  "filter.loadbutton.load_more": "Laad meer",
  // Weekday filters
  "filter.maandag": "Maandag",
  "filter.dinsdag": "Dinsdag",
  "filter.woensdag": "Woensdag",
  "filter.donderdag": "Donderdag",
  "filter.vrijdag": "Vrijdag",
  "filter.zaterdag": "Zaterdag",
  "filter.zondag": "Zondag",

  /**
   * ==================================================================
   * Program
   * =================================================================
   */
  "program.share-button": "Deel programma",

  /**
   *  ==================================================================
   *  Language Switcher
   *  ==================================================================
   */
  "language-switcher.nl": "Nederlands",
  "language-switcher.en": "English",

  /**
   *  ==================================================================
   *  Footer
   *  ==================================================================
   */
  "footer.title":
    "De mooiste tijd beleef je bij Klimrijk, onderdeel van Libéma",
  "footer.title.social": "Volg ons op Social Media:",
  "footer.newsletter.title": "Blijf op (de) hoogte",
  "footer.newsletter.description":
    "Ontvang het laatste nieuws en de beste acties in onze nieuwsbrief.",

  /**
   *  ==================================================================
   *  FormSection
   *  ==================================================================
   */
  "formsection.button.send": "Versturen",

  /**
   *  ==================================================================
   *  ArticleOverviewSection
   *  ==================================================================
   */
  "article.overview.section.pagination.previous": "Vorige",
  "article.overview.section.pagination.next": "Volgende",

  /**
   *  ==================================================================
   *  Eventschedule
   *  ==================================================================
   */
  "event.schedule.header.time": "Tijd",
  "event.schedule.header.activities": "Activiteiten",
};

export default nl;
