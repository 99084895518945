/* global dataLayer */
import * as Sentry from "@sentry/nextjs";
import trans from "counterpart";
import debounce from "lodash.debounce";
import { SubmissionError, change, submit } from "redux-form";
import { PreferenceType } from "../../constants/newyse";
import { getAccommodationTypes } from "../../selectors/entitySelectors";
import { manageModeSelector } from "../../selectors/environmentSelectors";
import reservationSelectors from "../../selectors/reservationSelectors";
import additionUtils from "../../utilities/additionUtils";
import { get } from "../../utilities/api";
import {
  postPayment,
  postReservationConfirmation,
  postReservationProposal,
} from "../../utilities/api/reservationApi";
import * as common from "../../utilities/common";
import {
  fetchAccommodationType,
  fetchSubjects,
  getCountries,
  getResorts,
} from "../booking/actions";
import {
  ID_DIERENBOS,
  ID_HOLIDAYPARK,
  ID_SAFARIRESORT,
  ID_SAFARIRESORT_HOTEL,
} from "../booking/constants";
import { getSections } from "../content/selectors";
import * as t from "./actionTypes";
import {
  criteriaToOfferParams,
  paramsToCriteria,
  reservationToParams,
  updateSubjects,
} from "./utilities";

export const setSection = ({ previous, current, next }) => ({
  type: t.SET_SECTION,
  payload: {
    previous,
    current,
    next,
  },
});

export function setCustomer(customer) {
  return {
    type: t.SET_CUSTOMER,
    customer,
  };
}

export function setFilters(filters) {
  return {
    type: t.SET_FILTERS,
    filters,
  };
}

export function setOffer(offer) {
  return {
    type: t.SET_OFFER,
    offer,
  };
}

export function updatePropertyCounts(properties) {
  return {
    type: t.UPDATE_PROPERTY_COUNTS,
    properties,
  };
}

export function setAdditionCategory(category) {
  return {
    type: t.SET_ADDITION_CATEGORY,
    category,
  };
}

export const customerAddressError = () => ({ type: t.CUSTOMER_ADDRESS_ERROR });
export const clearCustomerAddressError = () => ({
  type: t.CLEAR_CUSTOMER_ADDRESS_ERROR,
});

const NO_CRITERIA_PARAMETERS =
  "No criteria parameters were given for this call";

/**
 * Fetch the address with the given postcode data
 *
 * @param {object} criteria
 */
const fetchAddress = debounce((criteria, dispatch, getState) => {
  const state = getState();

  if (!state.form || !state.form.customer || !state.form.customer.values) {
    return Promise.resolve();
  }

  const customer = state.form.customer.values;

  const params = {
    country: customer.country,
    postcode: customer.zipcode,
    streetnumber: customer.housenumber,
    ...criteria,
  };

  if (
    !params.country ||
    !params.postcode ||
    !params.streetnumber ||
    params.country === "" ||
    params.postcode === "" ||
    params.streetnumber === ""
  ) {
    return Promise.resolve();
  }

  if (["NL", "BE"].indexOf(params.country) === -1) {
    return Promise.resolve();
  }

  // Clear any address errors, since we're about to fetch again
  if (state.reservation.customer && state.reservation.customer.addressError) {
    dispatch(setCustomer({ addressError: false }));
  }

  const queryParams = {
    streetnumber: params.streetnumber,
    postcode: params.postcode,
    country: params.country,
  };

  if (params.streetnumber_suffix) {
    queryParams.extension = params.streetnumber_suffix;
  }

  return (
    get({
      url: "/customers/address",
      params: queryParams,
      baseURL: `${process.env.NEXT_PUBLIC_API_BASE_URL}/api`,
    })
      // If successful, populate the address and city fields
      .then(({ data }) => {
        if (data.street) {
          dispatch(change("customer", "address1", data.street));
          dispatch(change("customer", "city", data.city));
        }
        return Promise.resolve();
      })
      // If no address was found,
      .catch(() => dispatch(setCustomer({ addressError: true })))
  );
}, 300);

export const getAddress = (criteria) => (dispatch, getState) =>
  fetchAddress(criteria, dispatch, getState);

export const getAvailableProperties =
  (criteria = null) =>
  (dispatch, getState) => {
    if (!criteria) {
      criteria = getState().reservation.criteria; // eslint-disable-line prefer-destructuring
    }
    const params = criteriaToOfferParams(criteria);

    return get({ url: "/offers/properties", params }).then(({ data }) =>
      dispatch(updatePropertyCounts(data.properties))
    );
  };

export const reservationFailure = (error) => {
  let errors;
  if (error?.response?.data) {
    // Avoid generic error message for invalid voucher codes
    if (error.response.data.code !== "VOUCHER_NOT_FOUND") {
      errors = [
        trans(`error.api.${error.response.data.code.toLowerCase()}`, {
          fallback: trans("error.api.unexpected"),
        }),
      ];
    }
  } else {
    if (process.env.NODE_ENV !== "production") {
      console.error(error);
    }
    if (error?.message !== NO_CRITERIA_PARAMETERS) {
      Sentry.captureException(error);
      errors = [trans("error.unknown_reservation_error")];
    } else {
      errors = [trans("error.no_reservation_criteria_error")];
    }
  }
  return {
    type: t.RESERVATION_API_ERROR,
    errors,
  };
};

export function reservationRestoreSession(reservation) {
  return {
    type: t.RESERVATION_RESTORE_SESSION,
    reservation,
  };
}

export function reservationProposalSuccess(reservation) {
  return {
    type: t.RESERVATION_PROPOSAL_SUCCESS,
    reservation,
  };
}

export function setReservationCriteria(criteria) {
  return {
    type: t.SET_RESERVATION_CRITERIA,
    criteria,
  };
}

export function reservationConfirmationSuccess(reservation, criteria) {
  return {
    type: t.RESERVATION_CONFIRMATION_SUCCESS,
    reservation,
    criteria,
  };
}

// keep due to used elsewhere?
export function reservationConfirmationFailure(errors) {
  return {
    type: t.RESERVATION_CONFIRMATION_FAILURE,
    errors,
  };
}

export function startReservationProposal() {
  return {
    type: t.RESERVATION_PROPOSAL_START,
  };
}

export function startReservationConfirmation() {
  return {
    type: t.RESERVATION_CONFIRMATION_START,
  };
}

// TODO: Remove this temporary fix for attraction pass see https://opifer.atlassian.net/browse/LIBSVD-1088
const calculateAttractionPassQuantity = (subjects) => {
  return (
    subjects?.reduce((totalQuantity, subject) => {
      // Ignore babies and pets they can enter for free.
      // Id: 6 = babies
      // Id: 11 = pets
      if (Number(subject.subject_id) === 6 || Number(subject.subject_id) === 11)
        return totalQuantity;
      return totalQuantity + Number(subject.quantity);
    }, 0) || 0
  );
};

/**
 * @param {object} data The data can be passed to override the existing reservation criteria
 * @param {object} opts
 * @param {boolean=} opts.throwError This can be used to catch the proposal error down the line to render specific error messages
 * @param {boolean=} opts.joinCriteria
 */
export const createReservationProposal =
  (data = null, opts = {}) =>
  (dispatch, getState) => {
    dispatch(startReservationProposal());
    if (manageModeSelector(getState()) === "manage") {
      dispatch(
        reservationProposalSuccess({
          customerBill: [
            { resourceId: 4, billLineType: 10, name: "Jungalow", total: 123 },
            { billLineType: 70, name: "Totaal", total: 123 },
          ],
          reservedResources: [
            {
              code: "JUN",
              completed: false,
              end_date: "2017-08-07T0::0+0200",
              has_price_override: false,
              name: "Jungalow",
              object_id: 2033,
              price: 715,
              quantity: 1,
              removable: false,
              resourceId: 4,
              start_date: "2017-08-04T00:00:00+0200",
              type: "ACCOMMODATIONTYPE",
            },
            {
              code: "RK",
              completed: false,
              end_date: "2017-08-07T00:00:00+0200",
              has_price_override: false,
              name: "Reserveringskosten",
              price: 25,
              quantity: 1,
              removable: false,
              resourceId: 42,
              start_date: "2017-08-04T00:00:00+0200",
              type: "EXTRA",
            },
          ],
          resort_id: 21,
        })
      );
      return Promise.resolve();
    }

    const state = getState();

    let criteria;
    if (data !== null) {
      const criteriaFromState = opts.joinCriteria
        ? reservationToParams(state.reservation.criteria)
        : {};

      criteria = { ...criteriaFromState, ...data };
    } else {
      criteria = reservationToParams(state.reservation.criteria);
    }

    if (criteria.additions) {
      // TODO: Remove this temporary fix for attraction pass see https://opifer.atlassian.net/browse/LIBSVD-1088
      const attractionPassIndex = criteria.additions.findIndex(
        (reservationAddition) =>
          state.entities.additions[reservationAddition.resource_id].code ===
          "excard"
      );
      if (attractionPassIndex >= 0) {
        criteria.additions[attractionPassIndex].quantity =
          calculateAttractionPassQuantity(criteria.subjects);
      }
      criteria.additions = additionUtils.setActualAdditionQuantity(
        state,
        criteria.additions
      );
    }

    Object.keys(criteria).forEach((i) => {
      if (criteria[i] === null || criteria[i] === undefined) {
        delete criteria[i];
      }
    });

    // If a request is made with no criteria (empty parameters), a 500 will be triggered on the server
    // We prevent this by first checking whether the criteria object is not undefined.
    if (criteria && Object.keys(criteria).length > 0) {
      criteria.language = trans("locale");
      // Dirty workaround created a ticket to solve it later
      //
      // Scenario: The AH special code requires a voucher code otherwise it should
      // not be possible to book the accommodation.
      //
      // This constraint has been configured in Maxxton due to limited
      // time we decided with Libema to do it hardcoded for AH and
      // find a more sustainable solution in the future.
      //
      // Note: Maxxton will check this constraint while completing the order
      // this is only to display the right price when there is no voucher
      if (criteria.special_code === "ahkorting" && !criteria.voucher_code) {
        delete criteria.special_code;
      }

      return postReservationProposal(criteria)
        .then(({ data }) => {
          dispatch(reservationProposalSuccess(data));
          dispatch({ type: t.RESERVATION_INITIAL_FETCH, payload: true });
        })
        .catch((error) => {
          dispatch(reservationFailure(error));
          if (opts.throwError) {
            throw error;
          }
        });
    } else {
      const noCriteriaError = new Error(NO_CRITERIA_PARAMETERS);
      dispatch(reservationFailure(noCriteriaError));
      if (opts.throwError) {
        throw noCriteriaError;
      }
    }
  };

export const updateAdditionCriteria = (values) => (dispatch) => {
  dispatch({ type: "UPDATE_ADDITION_CRITERIA", payload: values });
  dispatch(createReservationProposal());
};

export const updateVoucherCriteria = (voucher_code) => (dispatch) => {
  dispatch({ type: t.UPDATE_VOUCHER_CRITERIA, payload: voucher_code });
};

// Above is disabled for now. Needs testing first
const getReservedResourcePrice = (resource) => {
  if (
    resource.type === "ACCOMMODATIONTYPE" ||
    resource.price < 0 ||
    resource.code === "extra"
  ) {
    return parseFloat(resource.price);
  }

  return 0;
};

const pushReservationToDataLayer = (
  reservation,
  discount,
  resortId,
  totalPrice,
  kindId
) => {
  if (!dataLayer) {
    return Sentry.captureException(new Error("GA DataLayer is not available"));
  }

  let affiliateTotal = parseFloat(
    reservation.reservedResources.find((r) => r.type === "ACCOMMODATIONTYPE")
      .price
  );
  if (
    [ID_SAFARIRESORT, ID_SAFARIRESORT_HOTEL, ID_HOLIDAYPARK].includes(resortId)
  ) {
    const extraPersonsPrice = reservation.reservedResources.find(
      (r) => r.code === "extra"
    );
    if (extraPersonsPrice) {
      affiliateTotal = affiliateTotal + parseFloat(extraPersonsPrice.price);
    }
  }

  let affiliation = "";
  switch (resortId) {
    case ID_SAFARIRESORT:
      affiliation = "Safari Resort Beekse Bergen";
      break;
    case ID_HOLIDAYPARK:
      affiliation =
        kindId === 5
          ? "Lake Resort Kamperen Beekse Bergen"
          : "Lake Resort Beekse Bergen";
      break;
    case ID_DIERENBOS:
      affiliation =
        kindId === 5
          ? "Vakantiepark Kamperen Dierenbos"
          : "Vakantiepark Dierenbos";
      break;
  }

  let reservationNumber = reservation.reservationNumber
    ? reservation.reservationNumber.toString()
    : "";
  reservationNumber = reservationNumber.endsWith("000")
    ? reservationNumber.substring(0, reservationNumber.length - 3)
    : reservationNumber;

  const purchaseEvent = {
    event: "purchase",
    ecommerce: {
      transaction_id: `${reservationNumber}`,
      value: `${parseFloat(totalPrice)}`,
      AffiliateTotal: `${affiliateTotal + parseFloat(discount)}`,
      currency: "EUR",
      coupon: `${
        reservation.criteria.special_code == null
          ? "no special code"
          : reservation.criteria.special_code
      }`,

      items: reservation.reservedResources.map((resource) => ({
        item_id: `${resource.code}`,
        item_name: `${resource.name}`,
        affiliation,
        coupon: `${
          reservation.criteria.special_code == null
            ? "no special code"
            : reservation.criteria.special_code
        }`,
        discount,
        item_category: `${resource.type}`,
        price: `${getReservedResourcePrice(resource)}`,
        quantity: parseInt(resource.quantity, 10),
      })),
    },
  };

  dataLayer.push(purchaseEvent);
  return null;
};

export const confirmReservation =
  (data = null, onErrorCallback) =>
  (dispatch, getState) => {
    dispatch(startReservationConfirmation());
    const state = getState();
    let criteria;
    if (data === null) {
      // If no data is provided, get it from the current state
      criteria = reservationToParams(state.reservation.criteria);
    } else {
      criteria = { ...data };
    }

    if (criteria.additions) {
      criteria.additions = criteria.additions.filter(
        (a) => parseInt(a.quantity, 10) !== 0
      );
    }

    Object.keys(criteria).forEach((i) => {
      if (criteria[i] === null || criteria[i] === undefined) {
        delete criteria[i];
      }
    });

    criteria.language = trans("locale");

    if (!state.form.customer || state.form.customer.syncErrors) {
      // Dispatch the remote submit to trigger field errors
      dispatch(submit("customer"));
      dispatch(
        reservationConfirmationFailure({
          customer: trans("action.create_customer_error"),
        })
      );
      if (onErrorCallback) {
        return onErrorCallback();
      }
      return Promise.resolve(false);
    }

    criteria.customer = state.form.customer.values;

    // TODO: Remove
    // Dirty workaround created a ticket to solve it later
    //
    // Scenario: The AH special code requires a voucher code otherwise it should
    // not be possible to book the accommodation.
    //
    // This constraint has been configured in Maxxton due to limited
    // time we decided with Libema to do it hardcoded for AH and
    // find a more sustainable solution in the future.
    //
    // Note: Maxxton will check this constraint while completing the order
    // this is only to display the right price when there is no voucher

    if (criteria.special_code === "ahkorting" && !criteria.voucher_code) {
      delete criteria.special_code;
    }

    return postReservationConfirmation(criteria)
      .then((reservationResponse) => {
        dispatch(
          reservationConfirmationSuccess(reservationResponse.data, criteria)
        );

        const state = getState();

        // we get the kindId from entities and pass to google analytics script to determine affiliation (used along with ResortId)
        const resourceId = state.reservation.criteria.resource_id;
        const accommodationtypes = getAccommodationTypes(state);
        const kindId = accommodationtypes?.[resourceId]?.kind;

        // we get the getState because reservationResponse does not include the criteria
        pushReservationToDataLayer(
          state.reservation,
          reservationSelectors.getDiscount(state),
          reservationSelectors.getResortId(state),
          reservationSelectors.getTotalPrice(state),
          kindId
        );
        return reservationResponse.data;
      })
      .catch((error) => {
        dispatch(reservationFailure(error));
        if (onErrorCallback) {
          return onErrorCallback();
        }
        return false;
      });
  };

export function setInitialSections(current, next) {
  return {
    type: t.SET_INITIAL_SECTIONS,
    current,
    next,
  };
}

export const initializeSections = () => (dispatch, getState) => {
  const bookingSections = common
    .objectToArray(getState().entities.blocks)
    .filter((b) => b.type === "BookingSection")
    .sort((a, b) => a.sort - b.sort);

  if (bookingSections.length > 0) {
    dispatch(
      setInitialSections(bookingSections[0] || null, bookingSections[1] || null)
    );
  }
};

export const setCurrentSection = (sections, current) => ({
  type: t.SET_CURRENT_SECTION,
  sections,
  current,
});

export const setNextSection = (sections) => ({
  type: t.SET_NEXT_SECTION,
  sections,
});

export const nextSection = (sections) => (dispatch, getState) => {
  if (
    sections[sections.length - 1].id ===
    getState().reservation.sections.current.id
  ) {
    dispatch(confirmReservation());
  } else {
    dispatch(setNextSection(sections));
  }
};

export function previousSection(sections) {
  return {
    type: t.PREVIOUS_SECTION,
    sections,
  };
}

export function addAddition(additionId, quantity) {
  return {
    type: t.ADD_ADDITION,
    additionId,
    quantity,
  };
}

export const removeAddition = (additionId) => (dispatch) => {
  dispatch({ type: t.REMOVE_ADDITION, additionId });
  dispatch(createReservationProposal());
};

export const initializeReservation = (params) => (dispatch, getState) => {
  const state = getState();
  // Clear the reservation, since we might have received a reservation from the local storage
  dispatch({ type: t.CLEAR_RESERVATION });
  dispatch({ type: t.RESERVATION_INITIAL_FETCH, payload: false });

  if (params.resource) {
    dispatch(fetchAccommodationType(params.resource));
  }
  dispatch(fetchSubjects());

  const bookingSections = common
    .objectToArray(state.entities.blocks)
    .filter((b) => b.type === "BookingSection")
    .sort((a, b) => a.sort - b.sort);
  if (bookingSections.length) {
    dispatch(setInitialSections(bookingSections[0], bookingSections[1]));
  }

  const criteria = paramsToCriteria(params);

  if (state.preferences.properties) {
    criteria.preferences = state.preferences.properties.map((property) => ({
      type: PreferenceType.AMENITY,
      id: property.toString(),
    }));
  }

  dispatch(setReservationCriteria(criteria));

  dispatch(createReservationProposal(null));
  dispatch(getAvailableProperties(criteria));

  dispatch(getCountries());
};

export const handleSubjectChange = (values) => (dispatch, getState) => {
  const criteria = getState().reservation.criteria;
  criteria.subjects = updateSubjects(values, criteria.subjects);

  dispatch(setReservationCriteria(criteria));
  dispatch(createReservationProposal(criteria));
};

export function submitSubjects(values, dispatch, error, clearSubmitErrors) {
  if (
    parseInt(values["subject-9"], 10) === 0 &&
    parseInt(values["subject-10"], 10) === 0
  ) {
    throw new SubmissionError({
      "subject-9": trans("action.submit_subjects_error"),
      "subject-10": trans("action.submit_subjects_error"),
      _error: trans("action.submit_subjects_error"),
    });
  }
  if (error) {
    clearSubmitErrors();
  }
  return dispatch(handleSubjectChange(values));
}

export const handleReservationReceiptSubmit = () => (dispatch, getState) =>
  dispatch(
    confirmReservation(
      null,
      () =>
        new Promise((resolve, reject) => {
          // If there were errors: open personal information section.
          const sections = getSections(getState());
          const current = sections.find(
            (section) => section.name === "personal_details_section"
          );
          dispatch(setCurrentSection(sections, current));
          return resolve();
        })
    )
  );

export const handlePayment = (data) => (dispatch, getState) => {
  if (!data || !data.reservation_id) {
    return Promise.reject(
      new Error("Order information missing while creating a payment")
    );
  }

  return postPayment(data)
    .then((response) => response.data)
    .catch((error) => {
      Sentry.captureException(error);
      if (process.env.NODE_ENV !== "production") {
        console.error(error);
      }

      throw error;
    });
};
