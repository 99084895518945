import { createSelector } from 'reselect'
import { objectToArray } from '../../utilities/common'

export const blocksSelector = state => state.entities.blocks
export const hiddenBlockIdsSelector = content => state => state.content[content].hiddenBlocks

export const blocksByTypeSelector = type => createSelector(
  blocksSelector,
  blocks => objectToArray(blocks).filter(b => b.type === type).sort((a, b) => a.sort - b.sort)
)

export const hiddenBlocksSelector = content => createSelector(
  blocksSelector,
  hiddenBlockIdsSelector(content),
  (blocks, blockIds) => blockIds.map(id => blocks[id])
)

const environmentSelector = state => state.environment

const allContentSelector = state => state.content
export const contentSelector = content => state => state.content[content]

export const currentContentSelector = createSelector(
  environmentSelector,
  allContentSelector,
  (environment, allContent) => allContent[environment.content]
)

export const currentContentBlocksSelector = createSelector(
  blocksSelector,
  currentContentSelector,
  (blocks, currentContent) => Object.keys(blocks).filter(k => currentContent.blocks.indexOf(parseInt(k, 10)) !== -1).map(k => blocks[k])
)

export const sectionsSelector = createSelector(
  currentContentBlocksSelector,
  blocks => blocks.filter(b => b.type === 'FormSectionBlock').sort((a, b) => a.sort - b.sort)
)

export const getSections = createSelector(
  blocksSelector,
  blocks => Object.values(blocks).filter(block => block.type === 'BookingSection')
)

export const currentSectionSelector = createSelector(
  blocksSelector,
  currentContentSelector,
  (blocks, content) => blocks[content.currentSection]
)

export const nextSectionSelector = createSelector(
  currentContentSelector,
  sectionsSelector,
  (content, sections) => {
    const idx = sections.findIndex(s => s.id === content.currentSection)

    return sections[idx + 1]
  }
)

export const prevSectionSelector = createSelector(
  currentContentSelector,
  sectionsSelector,
  (content, sections) => {
    const idx = sections.findIndex(s => s.id === content.currentSection)

    return sections[idx - 1]
  }
)
