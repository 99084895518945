import React, { ReactElement } from "react";
import styled from "styled-components";
import Tippy from "@tippyjs/react";

type TooltipProps = {
  content: string;
  placement: "top" | "bottom" | "right" | "left";
};

const StyledTooltip = styled.div`
  background-color: white;
  box-shadow: ${({ theme }) => theme.color.neutralLight};
  border: 1px solid ${({ theme }) => theme.color.neutralDark};
  color: ${({ theme }) => theme.textColor.default};
  font-size: 1rem;
  padding: 0.75rem 1rem;
  text-align: left;

  p:last-child {
    margin-bottom: 0;
  }
`;

const Tooltip: React.FC<TooltipProps> = ({ placement, children, content }) => (
  <Tippy
    placement={placement}
    content={<StyledTooltip>{content}</StyledTooltip>}
  >
    {children as ReactElement}
  </Tippy>
);

export default Tooltip;
