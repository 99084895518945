import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { getAttributeOptions } from '../../cms/actions'

class CollectionFilterChecklist extends Component {
  componentDidMount = () => this.props.loadOptions(this.props.name)

  render = () => {
    const { displayName, options } = this.props

    return (
      <div className='collection-filter-checklist'>
        <label className='collection-filter-category'>{displayName}</label>
        {Object.keys(options).map(i => (
          <div className='form-check' key={i}>
            <label className='form-check-label'>
              <Field name={`options[${options[i].id}]`} component='input' type='checkbox' className='form-check-input' />
              {options[i].display_name}
            </label>
          </div>
        ))}
      </div>
    )
  }
}

CollectionFilterChecklist.propTypes = {
  displayName: PropTypes.string,
  loadOptions: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.object
}

const mapStateToProps = state => ({
  options: state.entities.options
})

export default connect(
  mapStateToProps,
  dispatch => ({
    loadOptions: (name) => {
      switch (name.split('.')[0]) {
        case 'attribute': {
          dispatch(getAttributeOptions(name.split('.')[1]))
          break
        }
        default: {
          break
        }
      }
    }
  })
)(CollectionFilterChecklist)
