import { schema } from 'normalizr'

export const contentSchema = new schema.Entity('content')

export const accommodationkindSchema = new schema.Entity('kinds')

export const accommodationtypeSchema = new schema.Entity('accommodationtypes', {
  kind: accommodationkindSchema
})

export const additionGroupSchema = new schema.Entity('additionGroups')

export const additionSchema = new schema.Entity('additions', {
  group: additionGroupSchema
})

export const blockSchema = new schema.Entity('blocks')
blockSchema.define({
  children: [blockSchema]
})

export const countrySchema = new schema.Entity('countries')
export const objectSchema = new schema.Entity('objects')
export const redirectSchema = new schema.Entity('redirects', {}, { idAttribute: 'origin' })
export const resortSchema = new schema.Entity('resorts')
export const subjectSchema = new schema.Entity('subjects')
export const propertySchema = new schema.Entity('properties', {}, {
  // This maps the groupId onto the property
  processStrategy: (value, parent) => ({ ...value, groupId: parent ? parent.id : null })
})
export const propertyGroupSchema = new schema.Entity('propertygroups', {
  properties: [propertySchema]
})

const preferencePropertyGroupSchema = new schema.Entity('propertygroups', {
  properties: [propertySchema]
}, { idAttribute: 'id' })

export { preferencePropertyGroupSchema }
