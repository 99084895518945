import React from 'react'
import PropTypes from 'prop-types'
import Carousel from '../Carousel'

const CarouselBlock = ({ placeholder, manageTags }) => (
  <Carousel
    className='carousel-block'
    showIndicators
    {...manageTags}
  >
    {placeholder(0)}
  </Carousel>
)

CarouselBlock.propTypes = {
  placeholder: PropTypes.func.isRequired,
  manageTags: PropTypes.object
}

export default CarouselBlock
