import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import trans from 'counterpart'
import { setAdditionCategory } from '../actions'
import { additionCategorySelector } from '../selectors'

const AdditionCategories = ({ onSetCategory, activeCategory, categories, manageTags }) => (
  <ul className='nav nav-pills margin-bottom-1' {...manageTags}>
    <li className='nav-item'>
      <span
        role='button'
        className={`nav-link${(!activeCategory) ? ' active' : ''}`}
        onClick={() => onSetCategory(null)}
        onKeyDown={() => onSetCategory(null)}
        style={{ cursor: 'pointer' }}
        tabIndex={0}
      >
        {trans('label.all')}
      </span>
    </li>
    {Object.keys(categories).map(k => (
      <li className='nav-item' key={k}>
        <span
          className={`nav-link${(activeCategory === k) ? ' active' : ''}`}
          onClick={() => onSetCategory(k)}
          onKeyDown={() => onSetCategory(k)}
          style={{ cursor: 'pointer' }}
          role='button'
          tabIndex={0}
        >
          {categories[k]}
        </span>
      </li>
    ))}
  </ul>
)

const mapStateToProps = state => ({
  activeCategory: state.reservation.filters.addition_category,
  categories: additionCategorySelector(state)
})

const mapDispatchToProps = dispatch => ({
  onSetCategory: category => dispatch(setAdditionCategory(category))
})

AdditionCategories.propTypes = {
  categories: PropTypes.object,
  activeCategory: PropTypes.string,
  onSetCategory: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(AdditionCategories)
