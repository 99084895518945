// types
import Page from "../../outputTypes/Page";

const normalizePage = (page): Page => {
  const { sys } = page;
  const type = page.__typename;

  delete page.sys;
  delete page.__typename;

  return {
    id: sys?.id,
    type,
    ...page,
  };
};

export default normalizePage;
