import { FC, useState } from "react";

// Components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

// Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

// Hooks
import useCancelReservation from "./useCancelReservation";

type Props = {
  currentPage: string;
  pages: string[];
  children?: React.ReactNode | React.ReactNode[];
  canShow: boolean;
  onCancelReservation: (reservationId: string) => Promise<any>;
  reservationId: string;
  reservationNumber: string;
  translations: any;
  policyLink: string;
};

/**
 * Display a warning dialog before navigating back to the previous
 * url.
 */
const CancelReservationModal: FC<Props> = ({
  currentPage = "",
  pages,
  canShow = true,
  reservationId,
  reservationNumber,
  onCancelReservation,
  translations,
  policyLink,
}) => {
  const { showModal, setShowModal, setConfirmed, dialogError, setDialogError } =
    useCancelReservation({
      currentPage,
      pages,
      canShow,
    });

  // State
  const [loading, setLoading] = useState(false);

  const onStay = () => {
    setShowModal(false);
    setDialogError(false);
    setConfirmed(false);
  };

  const onCancel = async () => {
    try {
      setLoading(true);
      setDialogError(false);
      return await onCancelReservation(reservationId).then(() =>
        setConfirmed(true)
      );
    } catch (error) {
      setDialogError(true);
      setLoading(false);
    }
  };

  const ErrorBox = () => {
    if (!dialogError) return null;
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        {translations.errors.cancelBookingFailure}
      </Alert>
    );
  };

  return (
    <Dialog open={showModal} maxWidth="md">
      <Backdrop
        sx={{ color: "white", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          {translations.title}
          <IconButton onClick={onStay}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body1">{translations.subtitle}</Typography>
          <Typography variant="body1">
            {translations.reservationNumber}: <b>{reservationNumber}</b>
          </Typography>
          <Stack spacing={1} alignItems="flex-start">
            <Button
              variant="contained"
              size="large"
              onClick={onStay}
              sx={{
                textTransform: "none",
                fontWeight: 600,
              }}
            >
              <Typography variant="body1" color="white">
                {translations.actions.continuePayment}
              </Typography>
            </Button>
            <FormHelperText sx={{ lineHeight: "normal" }}>
              {translations.actions.continuePaymentHelper}
            </FormHelperText>
          </Stack>
          <Stack spacing={1} alignItems="flex-start">
            <Button
              size="large"
              color="error"
              onClick={async () => await onCancel()}
              sx={{
                textTransform: "none",
                fontWeight: 600,
              }}
            >
              <ArrowBackIcon />
              <Typography variant="body1" color="error">
                {translations.actions.cancelReservation}
              </Typography>
            </Button>
            <FormHelperText sx={{ lineHeight: "normal" }}>
              {translations.actions.cancelReservationHelper}
            </FormHelperText>
          </Stack>
          <Typography variant="body2">
            {translations.cancelPolicy.text1}
            <Link target="_blank" href={policyLink} color="text.primary">
              {translations.cancelPolicy.text2}
            </Link>
            {translations.cancelPolicy.text3}
          </Typography>
        </Stack>
        <ErrorBox />
      </DialogContent>
    </Dialog>
  );
};

export default CancelReservationModal;
