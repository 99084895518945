import { useState, FC } from "react";
import { ImageLoaderProps } from "next/image";
import styled from "styled-components";

// Types
import { Image as ImageType } from "@libema/content-sdk";

// Components
import {
  DEVICE_LG_UP,
  DEVICE_MD_UP,
  Gallery as LibGallery,
  Photoviewer,
} from "@libema/design-system";
import SafeWebpImage from "../elements/SafeWebpImage";

type Props = {
  images: ImageType[];
  stripPosition?: LibGallery.GalleryProps["stripPosition"];
};

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  padding-top: 66%;
`;

const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?w=${width}&h=${Math.ceil(width * 0.66)}&q=${
    quality || 90
  }&fit=fill&f=faces&fm=webp`;
};

export const AlternativeImageComponent = ({ src, alt }) => {
  return (
    <ImageContainer>
      <SafeWebpImage
        layout="fill"
        objectFit="cover"
        sizes={`${DEVICE_LG_UP} 555px, ${DEVICE_MD_UP} 700px, 400px`}
        quality={90}
        src={src}
        alt={alt}
        loader={imageLoader}
      />
    </ImageContainer>
  );
};

const imageLoaderPhotoViewer = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?q=${quality || 90}&fm=webp`;
};

export const Gallery: FC<Props> = ({ images, stripPosition }) => {
  const [viewerOpen, setViewerOpen] = useState(null);

  const openViewer = (index) => setViewerOpen(index);
  const closeViewer = () => setViewerOpen(null);

  const galleryImages = images?.map((image) => ({
    src: image.url,
    alt: image.title,
    youtubeId: image.youtubeId,
  }));

  return (
    <>
      {galleryImages && (
        <LibGallery.Gallery
          images={galleryImages}
          onClick={openViewer}
          imageComponent={AlternativeImageComponent}
          stripPosition={stripPosition}
        />
      )}
      {viewerOpen !== null && (
        <Photoviewer.Container
          images={galleryImages}
          onClose={closeViewer}
          startAt={viewerOpen || undefined}
        >
          <Photoviewer.Header>
            <Photoviewer.ButtonClose />
            {galleryImages.length > 1 && <Photoviewer.Index />}
          </Photoviewer.Header>
          <Photoviewer.ButtonPrevious />
          <Photoviewer.Photo>
            <SafeWebpImage
              layout="fill"
              objectFit="contain"
              objectPosition="center"
              src=""
              loader={imageLoaderPhotoViewer}
            />
          </Photoviewer.Photo>
          <Photoviewer.ButtonNext />
        </Photoviewer.Container>
      )}
    </>
  );
};

export default Gallery;
