import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "next/router";
import { updateEntity } from "../actions/entityActions";
import { withActiveLink } from "../contexts/NavContext";
import { isMobile } from "../utilities/deviceUtilities";
import { getUserAgent } from "../selectors/environmentSelectors";
import Link from "./typography/Link";

class NavLinkBlock extends Component {
  componentDidMount = () => {
    const [pathname] = this.props.router.asPath.split("?");
    if (pathname.indexOf(this.props.value) > -1) {
      this.props.setActiveLink(this.props.block.id);
    }
  };

  handleMouseEnter = () => {
    if (!this.props.isMobile) {
      this.props.setActiveLink(this.props.block.id);
    }
  };

  toggleSubMenu = () => {
    const { isMobile, activeLink, block } = this.props;
    if (isMobile) {
      if (block.id === activeLink) {
        this.props.setActiveLink(null);
      } else {
        this.props.setActiveLink(block.id);
      }
    }
  };

  isLinkActived = (item) => {
    const { entities, router } = this.props;
    const [pathname] = router.asPath.split("?");
    return entities[item].value === pathname.substr(1, pathname.length);
  };

  render = () => {
    const { activeLink, block, entities, mobileMenuIsOpen, closeNavbar } =
      this.props;

    return (
      <li
        className={`nav-item ${block.id === activeLink ? "activated" : ""} ${
          block.children.length > 0 ? "dropdown" : ""
        } ${block.children.length === 0 ? "no-dropdown" : ""} `}
        onMouseEnter={this.handleMouseEnter}
        onClick={this.toggleSubMenu}
      >
        <div role="button" onClick={this.toggleSubMenu}>
          {mobileMenuIsOpen && block.id !== activeLink && (
            <div className="nav-link nav-mobile">
              {block.display_name ? block.display_name : block.name}
            </div>
          )}
          {(!mobileMenuIsOpen ||
            (mobileMenuIsOpen && block.id === activeLink)) && (
            <Link className="nav-link nav-mobile" to={block.value}>
              {block.display_name ? block.display_name : block.name}
            </Link>
          )}
        </div>
        {block.children.length > 0 && (
          <>
            <button className="navbar-toggler" type="button" />
            <div className="dropdown-menu">
              <div className="container">
                {block.children.map((item) => (
                  <span onClick={closeNavbar}>
                    <Link
                      className={`dropdown-item ${
                        this.isLinkActived(item) ? "activated" : ""
                      }`}
                      key={item}
                      to={entities[item].value}
                    >
                      {entities[item].display_name
                        ? entities[item].display_name
                        : entities[item].name}
                    </Link>
                  </span>
                ))}
              </div>
            </div>
          </>
        )}
      </li>
    );
  };
}

NavLinkBlock.propTypes = {
  activeLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  block: PropTypes.object.isRequired,
  closeNavbar: PropTypes.func,
  mobileMenuIsOpen: PropTypes.bool,
  entities: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
  router: PropTypes.object,
  setActiveLink: PropTypes.func.isRequired,
  value: PropTypes.string,
};

const mapState = (state) => ({
  // TODO: Do not pass the complete block array here, since this will cause unneccesary overhead.
  entities: state.entities.blocks,
  isMobile: isMobile(getUserAgent(state)),
});

export default connect(mapState)(withRouter(withActiveLink(NavLinkBlock)));
