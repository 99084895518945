import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setPreferences } from '../modules/preferences/actions'
import { getPreferences } from '../modules/preferences/selectors'
import Filters from '../modules/booking/components/Filters'
import { fetchOffers, fetchArrivalsAndDepartures } from '../actions/preferenceActions'

class SearchForm extends Component {
  state = {
    results: []
  }

  componentDidMount = () => {
    const { focus, preferences, setSubjects, setKinds } = this.props
    if (focus && focus.subjects && !preferences.subjects) {
      setSubjects(focus.subjects)
    }

    if (focus && focus.kinds.length > 0) {
      setKinds(focus.kinds)
    }

    this.handleFilterChange(this.props.focus)
  }

  handleFilterChange = (params) => {
    this.props.fetchArrivalsAndDepartures(params)

    return this.props.fetchOffers(params)
      .then((data) => {
        if (!data) {
          return null
        }

        return this.setState({ results: data.results })
      })
  }

  render = () => (
    <div className='loading-block loading-pr transparent'>
      <div className='container'>
        <div id='filters' className='row row-pb'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='filters'>
                  <div className='tab-content row'>
                    <Filters
                      template='search_form'
                      focus={this.props.focus}
                      focusPriority={this.props.focusPriority}
                      results={this.state.results}
                      onChange={this.handleFilterChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SearchForm.propTypes = {
  fetchArrivalsAndDepartures: PropTypes.func,
  fetchOffers: PropTypes.func.isRequired,
  focus: PropTypes.object,
  focusPriority: PropTypes.bool,
  preferences: PropTypes.object,
  setSubjects: PropTypes.func,
  setKinds: PropTypes.func
}

const mapStateToProps = state => ({
  preferences: getPreferences(state)
})

const mapDispatchToProps = dispatch => ({
  fetchArrivalsAndDepartures: focus => dispatch(fetchArrivalsAndDepartures(focus)),
  fetchOffers: params => dispatch(fetchOffers(params)),
  setSubjects: subjects => dispatch(setPreferences(subjects, 'subjects')),
  setKinds: kinds => dispatch(setPreferences(kinds, 'kinds'))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm)
