import styled from "styled-components";
import {
  Col,
  DEVICE_MD_UP,
  DEVICE_LG_UP,
  Section,
  FilterGroup,
} from "@libema/design-system";

/*
TODO: https://github.com/jstack-eu/libema/pull/231

Move everything related to Filtering/Algolia to separate components inside core/components/blocks/... what do you think @jbarradas?
Only keep SectionComponents inside the sections directory. Also, move the styled section somewhere else.

- MainPanel
- FilterHeader
- FilterFieldset
- StyledSearchBox
- StyledSort
*/

export const StyledSection = styled(Section.Wrapper)`
  & .wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }

  @media ${DEVICE_MD_UP} {
    & .wrapper {
      flex-direction: row;
    }
  }
`;

export const MainPanel = styled(Col)`
  flex: 1;

  & .ais-Hits,
  & .ais-Hits-list {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  @media ${DEVICE_LG_UP} {
    > *,
    > header {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const FilterHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.padding.sm} 0`};

  & .ais-SortBy-select {
    border: none;
    font-family: ${({ theme }) => theme.font.default};
  }

  & .search {
    display: flex;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.margin.lg};
  }

  & .options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${DEVICE_LG_UP} {
    flex-direction: row;
  }
`;

export const FilterFieldset = styled(FilterGroup.Fieldset)`
  margin-bottom: ${({ theme }) => theme.margin.lg};
`;

export const StyledSearchBox = styled.div`
  flex: 1;

  @media ${DEVICE_MD_UP} {
    flex: 0.7;
  }

  form {
    position: relative;
    display: flex;
    align-items: center;
  }

  .ais-SearchBox-submit {
    position: absolute;
    right: 12px;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .ais-SearchBox-submitIcon {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.color.text_color_muted};
  }

  & .ais-SearchBox-reset {
    position: absolute;
    right: 12px;
    background: white;
    padding: 0 6px;
  }

  & .ais-SearchBox-input {
    background: white;
    border-radius: 0;
    padding: ${({ theme }) => `${theme.padding.sm} ${theme.padding.md}`};
    border: 2px solid ${({ theme }) => theme.color.text_color_muted};
    border-radius: 0;
    flex: 1;
    font-family: ${({ theme }) => theme.font.default};

    &::placeholder {
      color: ${({ theme }) => theme.color.grey_800};
    }

    @media ${DEVICE_MD_UP} {
      min-width: 300px;
    }
  }
`;

export const StyledSort = styled.div`
  & .ais-SortBy-select {
    background: transparent;
    font-size: 1rem;
  }
`;
