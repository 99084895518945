const accounts = {
  aviodrome: {
    name: "aviodrome",
    title: "Aviodrome",
    emoji: "🛩",
    theme: {
      color_neutral_dark: "#454c9a",
      color_neutral_medium: "#454c9a",
      color_neutral_light: "#eeeff1",
      color_primary: "#e7431a",
      color_secondary: "#ffcb00",
      font_default:
        'Trebuchet MS, -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      text_color_default: "#1b2140",
      text_color_muted: "#777777",
    },
  },
  aquazoo: {
    name: "aquazoo",
    title: "Aquazoo",
    emoji: "🐠",
    theme: {
      color_neutral_dark: "#5d7261",
      color_neutral_medium: "#5d7261",
      color_primary: "#1c355e",
      color_tertiary: "#cf8a00",
      color_secondary: "#cf8a00",
      font_default: "Source Sans Pro,sans-serif",
    },
  },
  beeksebergen: {
    name: "beeksebergen",
    title: "Beekse Bergen",
    emoji: "🦁",
    theme: {
      colors: {
        color_neutral_dark: "#a48a76", // Dark Sand
        color_neutral_medium: "#ac9681",
        color_neutral_light: "#f6f1e6", // Sand
        color_primary: "#e84d0e", // Orange
        color_secondary: "#596831", // Green
        color_tertiary: "#fcce4c", // Yellow
        color_info: "#f6f1e6", // sandy
        font_default: '"sofia-pro", "Century Gothic", sans-serif',
        text_color_default: "#292b2c", // Dark (Blackish)
        text_color_muted: "#AEA9A7",
        text_color_info: "#292b2c", // same as default
        text_light: "#4B4847", // same as default
        focus: "#e84d0e",
        grey_100: "#fbf9f4",
        grey_200: "#f6f1e6",
        grey_300: "#eae1c8",
        grey_400: "#c3af8b",
        grey_500: "#a38a77",
        grey_600: "#95928C",
        grey_700: "#a48a77",
        grey_800: "#a48a77",
        grey_900: "#292B2C",
        primary_light: "#FFDA5E",
        primary_main: "#FDC300",
        primary_dark: "#CF9500",
        secondary_light: "#588642",
        secondary_main: "#596831",
        secondary_dark: "#123600",
      },
    },
  },
  dierenbos: {
    name: "dierenbos",
    title: "Dierenbos",
    emoji: "🦉",
    theme: {
      color_neutral_dark: "#a48a76", // Dark Sand
      color_neutral_medium: "#596831",
      color_neutral_light: "#f6f1e6", // Sand
      color_primary: "#e84d0e", // Orange
      color_secondary: "#fcce4c", // Yellow
      color_info: "#fcce4c", // sandy
      font_default: '"sofia-pro", "Century Gothic", sans-serif',
      text_color_default: "#292b2c", // Dark (Blackish)
      text_color_muted: "#5c6738", // Green
      text_color_info: "#292b2c", // same as default
    },
  },
  dierenrijk: {
    name: "dierenrijk",
    title: "Dierenrijk",
    emoji: "🐪",
    theme: {
      color_neutral_dark: "#512d6d",
      color_neutral_medium: "#512d6d",
      color_neutral_light: "#fff0de",
      color_primary: "#ef3340",
      color_secondary: "#ffcd00",
      font_default: "montserrat, sans-serif",
      text_color_default: "#512d6d",
      text_color_muted: "#5c6738",
    },
  },
  klimrijk: {
    name: "klimrijk",
    title: "Klimrijk",
    emoji: "🧗",
    theme: {
      colors: {
        grey_100: "#F5F3F1",
        grey_200: "#EBE8E4",
        grey_300: "#E3DFD9",
        grey_400: "#D1CFCA",
        grey_500: "#B8B6B2",
        grey_600: "#95928c",
        primary_light: "#FFDA5E",
        primary_main: "#FDC300",
        primary_dark: "#CF9500",
        text_color_muted: "#E3DFD9",
        secondary_light: "#588642",
        secondary_main: "#235809",
        secondary_dark: "#123600",
      },
      button: {
        radius: "8px",
        textColor: {
          grey_600: "#fff",
        },
      },
      color_neutral_dark: "#5c5852",
      color_neutral_medium: "#95928c",
      color_neutral_light: "#e3dfd9",
      color_primary: "#fdc300",
      color_secondary: "#235809", // Or #2c5b1a??
      // color_tertiary: '',
      font_default: "'Source Sans Pro', sans-serif",
      text_color_default: "#45423d",
    },
  },
  speelland: {
    name: "speelland",
    title: "Speelland",
    emoji: "🎠",
    theme: {
      color_neutral_dark: "#a48a76", // Dark Sand
      color_neutral_medium: "#596831",
      color_neutral_light: "#f6f1e6", // Sand
      color_primary: "#e84d0e", // Orange
      color_secondary: "#fcce4c", // Yellow
      font_default: '"sofia-pro", "Century Gothic", sans-serif',
      text_color_default: "#292b2c", // Dark (Blackish)
      text_color_muted: "#5c6738", // Green
    },
  },
  zooparc: {
    name: "zooparc",
    title: "Zooparc",
    emoji: "🦘",
    theme: {
      color_neutral_dark: "#513d38",
      color_neutral_medium: "#985a39",
      color_neutral_light: "#fef2e0",
      color_primary: "#92c045",
      color_secondary: "#ee5933",
      font_default:
        '-apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      text_color_default: "#222",
      text_color_muted: "#74633c",
    },
  },
};

export default accounts;
