import React from "react";
import { ErrorMessage as FormikErrorMessage } from "formik";
import styled from "styled-components/macro";
import { AlertType } from "../alerts/Alert";

const ErrorComponent = styled.small`
  color: ${({ theme }) => theme.color.error};
`;

type ErrorMessageProps = {
  alert?: boolean;
  alertType?: AlertType;
  name: string;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ name }) => {
  return <FormikErrorMessage name={name} component={ErrorComponent} />;
};

export default ErrorMessage;
