import React from 'react'
import PropTypes from 'prop-types'

const TicketBar = props => (
  <div className='ticket-bar container'>
    <div className='row'>
      <div className='col-sm-12 col-lg-10 offset-lg-1'>
        <h3 className='mb-2 text-center text-white'>
          {/* to do ? */}
        </h3>
        <div className='bg-beige p-3'>
          <div className='row'>
            <div className={`${props.enable_green_button_title ? 'col-sm-6' : 'col-sm-9'}`}>
              <h4 className='ticket-title'>
                {props.title}
              </h4>
              <p className='mb-0'>
                {props.sub_title}
              </p>
            </div>
            {props.enable_green_button_title && (
              <div className='col-sm-3 col-lg-3 pl-lg-0 pl-xl-0'>
                <a
                  href={`${props.cta_green_button_link}`}
                  className='btn btn-ticket btn-block btn-green text-lg-left text-xs-center'
                >
                  {props.cta_green_button_title}
                  <span>
                    {props.cta_green_button_sub_title}
                  </span>
                </a>
              </div>
            )}
            <div className='col-sm-3 col-lg-3 pl-lg-0 pl-xl-0'>
              <a
                href={`${props.cta_button_link}`}
                className='btn btn-ticket btn-block btn-primary-color text-lg-left text-xs-center'
              >
                {props.cta_button_title}
                <span>
                  {props.cta_button_sub_title}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default TicketBar

TicketBar.propTypes = {
  enable_green_button_title: PropTypes.bool,
  title: PropTypes.string,
  sub_title: PropTypes.string,
  cta_green_button_link: PropTypes.string,
  cta_green_button_title: PropTypes.string,
  cta_green_button_sub_title: PropTypes.string,
  cta_button_link: PropTypes.string,
  cta_button_title: PropTypes.string,
  cta_button_sub_title: PropTypes.string
}
