const de = {
  /**
   *  ==================================================================
   *  404 Page
   *  ==================================================================
   */
  "page.404.title": "Seite nicht gefunden | Klimrijk",
  "page.404.meta.description": "Seite nicht gefunden",
  "page.404.description":
    "Diese Seite existiert nicht (mehr), oder es gibt einen Tippfehler in der Adresse.",
  "page.404.subtitle": "Was ist zu tun?",
  "page.404.action.1": "Überprüfen Sie, ob die Adresse richtig geschrieben ist",
  "page.404.button.home": "Zurück zur Startseite",

  /**
   *  ==================================================================
   *  500 Page
   *  ==================================================================
   */
  "page.500.title": "Hoppla! Etwas scheint schief zu laufen.",
  "page.500.description":
    "Wir werden versuchen, dies so schnell wie möglich zu beheben.",
  "page.500.button": "Zurück zur Startseite",

  /**
   *  ==================================================================
   *  Article
   *  ==================================================================
   */
  "page.article.published.in": "Veröffentlicht in",

  /**
   *  ==================================================================
   *  NewsLetter Subscribe form
   *  ==================================================================
   */
  "newsletter.placeholder.email": "Ihre E-Mail-Adresse",
  "newsletter.button.subscribe": "Um sich anzumelden",
  "newsletter.success":
    "Danke für die Registrierung. Wir haben Ihnen zur Bestätigung eine Bestätigungs-E-Mail gesendet.",
  "newsletter.validator.email_invalid": "Ungültige E-Mail-Adresse",
  "newsletter.validator.required": "Dieses Feld wird benötigt",

  /**
   *  ==================================================================
   *  Activity
   *  ==================================================================
   */
  "activity.duration":
    "{count, plural, one {Dauer # Minute} other {Duar # Minuten}}",
  "activity.min.age": "{minAge}+ Jahr",
  "activity.min.height": "Mindestens {minHeight}m",
  "activity.min.persons":
    "{count, plural, one {Von # Person} other {Von # Personen}}",
  "activity.more.link": "Mehr Informationen",
  "activity.price.from": "von",
  "activity.price.adult": "Preis Erwachsener",
  "activity.price.child": "Preis Kind",
  "activity.price.free.lowercase": "kostenlos",

  "activity.filter.button": "Entscheidungshilfe",
  "activity.filter.age": "Alter",
  "activity.filter.duration": "Dauer",

  "activity.filter.age.4.8": "4 - 8 Jahre",
  "activity.filter.age.9.12": "9 - 12 Jahre",
  "activity.filter.age.13.16": "13 - 16 Jahre",
  "activity.filter.age.min.16": "Von 16 Jahre",
  "activity.filter.duration.max.20": "< 20 min",
  "activity.filter.duration.20.40": "20 - 40 min",
  "activity.filter.duration.41.60": "41 - 60 min",
  "activity.filter.duration.min.60": "Von 60 min",
  "activity.sort.relevance": "Empfohlen",
  "activity.sort.price_asc": "Preis aufsteigend",
  "activity.sort.price_desc": "Preis absteigend",
  "activity.open": "Offen: {weekdays}",
  "activity.open_all_week": "Die ganze Woche geöffnet",
  /**
   *  ==================================================================
   *  Filters
   *  ==================================================================
   */
  "filter.title": "Filter",
  "filter.buttons.clear": "Auswahl löschen",
  "filter.buttons.close_modal": "{count} Ergebnisse anzeigen",
  "filter.error.not_supported": "Filter {type} wird nicht unterstützt",
  "filter.searchbox.placeholder": "Suchen...",
  "filter.loadbutton.load_more": "Mehr laden",
  // Weekday filters
  "filter.maandag": "Montag",
  "filter.dinsdag": "Dienstag",
  "filter.woensdag": "Mittwoch",
  "filter.donderdag": "Donnerstag",
  "filter.vrijdag": "Freitag",
  "filter.zaterdag": "Samstag",
  "filter.zondag": "Sonntag",

  /**
   * ==================================================================
   * Program
   * =================================================================
   */
  "program.share-button": "Programm teilen",

  /**
   *  ==================================================================
   *  Language Switcher
   *  ==================================================================
   */
  "language-switcher.nl": "Nederlands",
  "language-switcher.en": "English",

  /**
   *  ==================================================================
   *  Footer
   *  ==================================================================
   */
  "footer.title":
    "Sie werden die beste Zeit in Klimrijk, einem Teil von Libéma ., erleben",
  "footer.title.social": "Folgen Sie uns auf Social Media:",
  "footer.newsletter.title": "Bleib informiert",
  "footer.newsletter.description": "Erhalten Sie Neuigkeiten und Aktionen",

  /**
   *  ==================================================================
   *  FormSection
   *  ==================================================================
   */
  "formsection.button.send": "Senden",

  /**
   *  ==================================================================
   *  ArticleOverviewSection
   *  ==================================================================
   */
  "article.overview.section.pagination.previous": "Vorherige",
  "article.overview.section.pagination.next": "Nächster",

  /**
   *  ==================================================================
   *  Eventschedule
   *  ==================================================================
   */
  "event.schedule.header.time": "Zeit",
  "event.schedule.header.activities": "Aktivitäten",
};
export default de;
