// ⚠️ This must match Contentful Model ID
enum TYPES {
  Page = "page",
  Link = "link",
  Award = "award",
  SocialMedia = "socialMedia",
  MenuItem = "menuItem",
  PageFooter = "pageFooter",
  PageHeader = "page-header",
  Category = "category",
  TargetAudience = "targetAudience",
  InlineScriptBlock = "inlinescriptblock",
  Locale = "locale",
  ProgramPlanning = "programPlanning",
  LabeledIcon = "labeledIcon",
  ProgramItemSeparator = "programItemSeparator",
  ProgramItemExtra = "programItemExtra",
  ArticleCategory = "articleCategory",
  Author = "author",
  HighlightedActivity = "highlightedActivity",
  ProgramCategory = "programCategory",
  ActivityEvent = "ActivityEvent",
  ActivityEventSeparator = "ActivityEventSeparator",

  // Topics
  Activity = "activity",
  Article = "article",
  Program = "program",

  // Sections
  SectionActivityOverview = "sectionActivityOverview",
  SectionRelatedActivities = "sectionRelatedActivities",
  SectionRelatedPrograms = "sectionRelatedPrograms",
  SectionArticleOverview = "sectionArticleOverview",
  SectionActivityDetail = "sectionActivityDetail",
  SectionEvents = "sectionEvents",
  SectionProgramOverview = "sectionProgramOverview",
  SectionHero = "sectionHero",
  SectionGallery = "sectionGallery",
  SectionLead = "sectionLead",
  SectionTeaser = "sectionTeaser",
  SectionMosaic = "sectionMosaic",
  SectionFAQ = "sectionFaq",
  SectionInstagram = "sectionInstagram",
  SectionOpeningHours = "sectionOpeningHours",
  SectionForm = "sectionForm",
  SectionIframe = "sectionIframe",
  Filter = "Filter",

  // Cards Details
  CardDetails = "CardDetails",
  Park = "Park",
  Location = "Location",
  MinAge = "MinAge",
  MinHeight = "MinHeight",
  StartingTime = "StartingTime",
  Duration = "Duration",
  Price = "Price",
}

export default TYPES;
