import { IoMdMoon } from "react-icons/io";

import Link from "../../elements/Link";
import RichText from "../RichText";
import RichTextRenderer from "../../renderer/RichTextRenderer";
import { ProgramItemSeparatorContainer } from "./style";

const dayPartIcons = {
  night: IoMdMoon,
};

const ProgramItemSeparator = (item) => {
  const dayPart = item.dayPart.toLowerCase();
  const IconComponent = dayPartIcons[dayPart];
  const icon = IconComponent ? <IconComponent /> : null;

  return (
    <ProgramItemSeparatorContainer variant={dayPart}>
      {icon}
      <RichText>
        {item.description && <RichTextRenderer text={item.description} />}
        {item.ctaButton != null && (
          <Link
            as={item.ctaButton.displayStyle}
            href={item.ctaButton.url}
            target={item.ctaButton.target}
            buttonProps={{ size: "small" }}
          >
            {item.ctaButton.label}
          </Link>
        )}
      </RichText>
    </ProgramItemSeparatorContainer>
  );
};

export default ProgramItemSeparator;
