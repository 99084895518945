import moment from 'moment'
import { normalize, schema } from 'normalizr'
import { get } from '../../utilities/api'
import * as t from './actionTypes'

const dateSchema = new schema.Entity('dates', {}, { idAttribute: 'date' })

export function setDates (resort, dates) {
  return {
    type: t.SET_DATES,
    resort,
    dates
  }
}

export const getDates = (selectedDate, resort) => (dispatch, getState) => {
  const state = getState()
  const firstOfTheMonth = moment(selectedDate).date(1).format('YYYY-MM-DD')

  if (state.calendar[resort] && Object.keys(state.calendar[resort].dates).indexOf(firstOfTheMonth) !== -1) {
    // Avoid executing the API call when required. Resolve a promise to allow then-chaining.
    return Promise.resolve()
  }
  const date = moment(selectedDate)
  return get({
    url: '/calendar',
    params: {
      year: date.year(),
      month: date.month() + 1,
      resort,
      locale: getState().preferences.locale
    }
  })
    .then(({ data }) => {
      const normalized = normalize(data, [dateSchema])
      if (normalized.entities.dates && Object.keys(normalized.entities.dates).length) {
        return dispatch(setDates(resort, normalized.entities))
      }

      return dispatch(setDates(resort, {}))
    })
}
