// eslint-disable-next-line
// @ts-nocheck
import React, { useState } from "react";
import { Field } from "formik";
import styled from "styled-components";
import BaseCalendarIcon from "@fortawesome/fontawesome-free/svgs/regular/calendar-alt.svg";
import { default as Modal, ModalCloseButton } from "../Modal";
import AdditionDateField from "./AdditionDateField";
import H3 from "../typography/H3";
import P from "../typography/P";
import Button from "../buttons/Button";
import FormGroup from "../form/FormGroup";
import ErrorMessage from "../form/ErrorMessage";
import Translate from "../typography/Translate";
import SelectField from "../form/fields/SelectField";
import RadioField from "../form/fields/RadioField";
import Label from "../form/Label";
import Pricetag from "../typography/Pricetag";
import Row from "../layout/Row";
import Col from "../layout/Col";
import InfoIcon from "../icons/InfoIcon";
import CheckAllField from "../form/fields/CheckAllField";

export const AdditionGroupFormItemContainer = styled.div`
  padding: 1.5rem;
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  flex: 1 0 auto;
`;

const Summary = styled.div`
  margin-bottom: 1rem;
`;

const Title = styled(H3)`
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  padding-right: 24px;
`;

const DatesTitle = styled(H3)`
  /* Dirty hack because beeksebergen uses a "#booking-app h3" style that overrides the design system h3 styles */
  margin-bottom: ${({ theme }) => theme.margin.md} !important;
`;

const AdditionDateFieldContainer = styled.div`
  padding: 1.5rem;
`;

const Price = styled.div`
  flex: 4 1 auto;
  text-align: right;
`;

const CalendarIcon = styled(BaseCalendarIcon)`
  fill: ${({ theme }) => theme.color.dark};
  position: relative;
  top: 0.15em;
  width: 1em;
`;

const SelectDateButton = styled(Button)`
  font-weight: normal;
  background: white;
  color: ${({ theme }) => theme.color.dark};
  border: 1px solid ${({ theme }) => theme.color.neutralMedium};

  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.color.neutralMedium};

    ${CalendarIcon} {
      fill: white;
    }
  }
`;

const RadioLabel = styled(Label)`
  margin: 0.5rem 1rem 0.5rem 0.5rem;
  font-weight: 700;
`;

const AdditionFormGroup = styled(FormGroup)`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;

  ${Label} {
    margin-bottom: 8px;
    font-weight: bold;
  }
`;

const AdditionFormGroupRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;

  & .dropdown {
    width: auto;
    min-width: 80px;
  }
`;

const BooleanOption = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const AdditionRadioFormGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Quantity = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  > * {
    flex: 1 1 auto;
  }
`;

const RightCorner = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.padding.sm};
`;

type AdditionGroupFormItemProps = {
  additionId: number;
  dates?: string[];
  selectedDates?: string[];
  description: string;
  image: string;
  name: string;
  price: number;
  quantity: number;
  perSubject: boolean;
  shortDescription?: string;
  stockType: "DAY" | "BOOLEAN" | "NORMAL";
};

const AdditionGroupFormItem: React.FC<AdditionGroupFormItemProps> = ({
  additionId,
  dates,
  selectedDates,
  description,
  image,
  name,
  price,
  quantity,
  shortDescription,
  stockType,
  perSubject,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleDateModal = () => setModalOpen(!modalOpen);

  const options = Array.from(Array(quantity + 1).keys()).map((amount) => ({
    value: amount > 0 ? amount : null,
    label: amount,
  }));

  const hasImage = image != null;

  return (
    <AdditionGroupFormItemContainer>
      <Row>
        {hasImage && (
          <Col lg={3} hiddenMdDown>
            <Thumbnail src={image} />
          </Col>
        )}
        <Col lg={hasImage ? 9 : 12}>
          <RightCorner>
            {description && <InfoIcon content={description} placement="top" />}
          </RightCorner>
          <Summary>
            <Title>{name}</Title>
            {shortDescription && <P>{shortDescription}</P>}
          </Summary>
          <div>
            {stockType === "BOOLEAN" && (
              <BooleanOption>
                <AdditionRadioFormGroup>
                  <Field
                    name={`additions.addition-${additionId}.quantity`}
                    value={0}
                    id={`additions.addition-${additionId}.quantity`}
                    component={RadioField}
                    size="small"
                  />
                  <RadioLabel
                    htmlFor={`additions.addition-${additionId}.quantity`}
                  >
                    <Translate id="label.no" />
                  </RadioLabel>
                  <Field
                    name={`additions.addition-${additionId}.quantity`}
                    value={1}
                    id={`additions.addition-${additionId}.quantity`}
                    component={RadioField}
                    size="small"
                  />
                  <RadioLabel
                    htmlFor={`additions.addition-${additionId}.quantity`}
                  >
                    <Translate id="label.yes" />
                  </RadioLabel>
                </AdditionRadioFormGroup>
                <Price>
                  <Pricetag value={price} />
                </Price>
              </BooleanOption>
            )}
            {stockType !== "BOOLEAN" && !perSubject && (
              <Quantity>
                <AdditionFormGroup inline>
                  <Label>
                    <Translate id="label.choose_quantity" />
                  </Label>

                  <AdditionFormGroupRow>
                    <Field
                      className="dropdown"
                      name={`additions.addition-${additionId}.quantity`}
                      options={options}
                      component={SelectField}
                      placeholder={<Translate id="label.quantity" />}
                      size="small"
                    />
                    <Price>
                      <Pricetag value={price} />
                    </Price>
                  </AdditionFormGroupRow>
                </AdditionFormGroup>
              </Quantity>
            )}
            <ErrorMessage name={`additions.addition-${additionId}.quantity`} />
            {stockType === "DAY" && dates && (
              <AdditionFormGroup>
                <Label>
                  <Translate id="label.select_days" />
                </Label>
                <AdditionFormGroupRow>
                  <SelectDateButton
                    type="button"
                    onClick={toggleDateModal}
                    size="small"
                  >
                    <CalendarIcon />
                    &nbsp;
                    <Translate
                      id="button.select_days"
                      values={{ count: selectedDates?.length ?? 0 }}
                    />
                  </SelectDateButton>
                  {perSubject && (
                    <Price>
                      <Pricetag value={price} />
                    </Price>
                  )}
                </AdditionFormGroupRow>
                <div>
                  <ErrorMessage
                    name={`additions.addition-${additionId}.dates`}
                  />
                </div>
              </AdditionFormGroup>
            )}
          </div>
        </Col>
      </Row>
      {modalOpen && (
        <Modal isOpen>
          <ModalCloseButton />
          <AdditionDateFieldContainer>
            <DatesTitle>
              <Translate id="title.select_dates" />
            </DatesTitle>
            <AdditionDateField
              name={`additions.addition-${additionId}.dates`}
              dates={dates}
            />
            <CheckAllField
              name={`additions.addition-${additionId}.dates`}
              values={dates}
            />
            <Button type="button" size="small" onClick={toggleDateModal}>
              <Translate id="button.apply" />
            </Button>
          </AdditionDateFieldContainer>
        </Modal>
      )}
    </AdditionGroupFormItemContainer>
  );
};

export default AdditionGroupFormItem;
