export default {
  // Buttons
  "button.subscribe": "Anmeldung",

  // Errors
  "error.general":
    "Es ist ein Fehler aufgetreten. Versuchen Sie es später noch einmal.",

  // Form labels
  "placeholder.email": "Ihre E-Mail-Adresse",

  // Titles
  "title.newsletter_form": "Abenteuer in der Mailbox?",
  "title.libema_footer": "Für die schönste Zeit des Jahres, {account} von",

  "success.newsletter_subscription":
    "Vielen Dank für Ihre Registrierung. Zur Bestätigung haben wir Ihnen eine Verifikations-E-Mail gesandt.",

  // Form validators
  "validator.email_invalid": "Ungültige E-Mail-Adresse",
  "validator.required": "Das ist ein Pflichtfeld",
};
