// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ThemeProvider } from "@mui/material/styles";
import { RewriteFrames } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";
import counterpart from "counterpart";
import moment from "moment";
import ModalProvider from "mui-modal-provider";
import { AppProps } from "next/app";
import getConfig from "next/config";
import Head from "next/head";
import { useRouter } from "next/router";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { ThemeProvider as LegacyThemeProvider } from "styled-components";
import { wrapper } from "../redux/store";

import { ComponentProvider, getTranslations } from "@libema/core";
import {
  translations as designSystemTranslations,
  getTheme,
  legacyGetTheme,
  themeSelector,
} from "@libema/design-system";
import { translations as designSystemLegacyTranslations } from "@libema/design-system-legacy";
import { beeksebergen } from "../theme/theme";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import * as Sections from "../components/sections";

import * as customLocales from "../locales";

// TODO: move to library
import "react-multi-carousel/lib/styles.css";
import "reactjs-popup/dist/index.css";

/*
 * Nextjs 11 is excluding momentjs locales by default now. We have to
 * manually import the required ones.
 */
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/nl";

// TODO: remove legacy stylesheet and migrate all components to new core & design-system
require(`../styles/base-${process.env.NEXT_PUBLIC_APP || "beeksebergen"}.scss`);

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_ENV,
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    whitelistUrls: [
      /((www|staging|testing)\.)?(beeksebergen|dierenbos)\.nl/,
      /.*\-jstack\-eu.vercel.app/,
    ],
    ignoreErrors: ["Non-Error promise rejection captured"],
    beforeSend: function (event) {
      // Drop all events if query string includes `fbclid` string
      // Sentry flags this, probably facebook crawlers
      if (location.search.indexOf("fbclid") !== -1) return null;
      // Otherwise just let it though
      return event;
    },
  });
}

// TODO Merge translations from different sources manually.
const locales = getTranslations({
  nl: {
    ...designSystemTranslations.nl,
    ...designSystemLegacyTranslations.nl,
    ...customLocales.nl,
  },
  fr: {
    ...designSystemTranslations.fr,
    ...designSystemLegacyTranslations.fr,
    ...customLocales.fr,
  },
  de: {
    ...designSystemTranslations.de,
    ...designSystemLegacyTranslations.de,
    ...customLocales.de,
  },
  en: {
    ...designSystemTranslations.en,
    ...designSystemLegacyTranslations.en,
    ...customLocales.en,
  },
});

counterpart.registerTranslations("nl", locales.nl);
counterpart.registerTranslations("en", locales.en);
counterpart.registerTranslations("de", locales.de);
counterpart.registerTranslations("fr", locales.fr);

// Version bump: 1
function CustomApp({ Component, pageProps }: AppProps) {
  const { locale = "nl", defaultLocale } = useRouter();
  const messages = locales[locale] || {};
  moment.locale(locale);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={messages}
    >
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://images.ctfassets.net" />
      </Head>
      <ComponentProvider
        customSections={Sections}
        customHeader={Header}
        customFooter={Footer}
      >
        <ThemeProvider theme={getTheme(beeksebergen)}>
          <LegacyThemeProvider
            theme={legacyGetTheme(
              themeSelector(process.env.NEXT_PUBLIC_APP || "beeksebergen")
            )}
          >
            <ModalProvider>
              <Component {...pageProps} />
            </ModalProvider>
          </LegacyThemeProvider>
        </ThemeProvider>
      </ComponentProvider>
    </IntlProvider>
  );
}

const mapStateToProps = (state) => ({
  locale: state.preferences.locale,
});

export default wrapper.withRedux(connect(mapStateToProps)(CustomApp));
