import styled from "styled-components";
import Jumbotron from "reactstrap/lib/Jumbotron";
import ReactPlayer from "react-player";
import {
  DEVICE_LG_UP,
  DEVICE_SM_UP,
  DEVICE_XL_UP,
} from "@libema/design-system";

// TODO: Move this component to design-system library
// TODO: set multiple z-index values in a _variables file

export const StyledJumbotron = styled(Jumbotron)`
  position: relative;

  &.jumbotron {
    overflow: hidden;
  }
`;

export const VideoPlayerWrapper = styled.div`
  max-height: 450px; // Height of this Jumbotron is being set here, so we are able to vertically center the video
  display: flex;
  align-items: center;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
  display: flex;
`;
export const BigPlayButton = styled.div`
  display: none;

  @media ${DEVICE_SM_UP} {
    position: absolute;
    top: 0;
    z-index: 1001;
    height: 100%;
    width: 100%;
    pointer-events: none;

    > span {
      display: flex;
      height: 100%;
      div {
        opacity: 0.4;
        border: 15px solid white;
        border-radius: 100px;
        height: 200px;
        width: 200px;
        margin: auto;
        pointer-events: initial;
        display: flex;
        svg {
          transform: scale(8);
          cursor: pointer;
          color: ${({ theme }) => theme.color.white};
          margin: auto;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;

export const VideoControls = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  z-index: 1000;

  div {
    position: absolute;
    top: 50%;
    left: 1.5rem;
    bottom: auto;
    transform: translate(0%, -50%);

    @media ${DEVICE_XL_UP} {
      left: 1.5rem;
    }
  }

  span {
    width: 40px;
    height: 40px;
    display: block;
    padding-top: 7px;
    margin-bottom: 0.75rem;
    border: 4px solid ${({ theme }) => theme.color.white};
    border-radius: 50px;
    text-align: center;
    opacity: 0.4;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    @media ${DEVICE_LG_UP} {
      padding-top: 5px;
    }

    svg {
      position: relative;
      font-size: 1.2rem;
      color: ${({ theme }) => theme.color.white};
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const VideoThumbnail = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 190;
  transition: all 0.3s ease-in-out;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  @media ${DEVICE_LG_UP} {
    background-position-y: center;
  }

  &.in {
    opacity: 0;
    transition: opacity 0.75s ease-in-out;
    -moz-transition: opacity 0.75s ease-in-out;
    -webkit-transition: opacity 0.75s ease-in-out;
    &.visible {
      opacity: 1;
      transition: opacity 0.05s ease-in-out;
      -moz-transition: opacity 0.05s ease-in-out;
      -webkit-transition: opacity 0.05s ease-in-out;
    }
  }
`;
