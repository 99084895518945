export class ApiError extends Error {
  constructor(
    message: string,
    public details: unknown,
    public statusCode: number
  ) {
    super(message);
    this.name = "ApiError";
  }
}
