import moment, { Moment } from "moment";
import styled from "styled-components";
import classNames from "classnames";
import useEvents from "./useEvents";
import LeftArrow from "../../../icons/LeftArrow";
import RightArrow from "../../../icons/RightArrow";

const StyledWeekRow = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  font-family: ${({ theme }) => theme.font.default};

  > div {
    display: flex;
    align-items: center;
    align-self: center;
    color: ${({ theme }) => theme.color.grey_light_100};
    font-weight: 700;
    margin-bottom: 1.88rem;

    button {
      display: flex;
      color: ${({ theme }) => theme.color.grey_light_100};
      font-weight: 700;
      margin: 0 1rem;
    }
  }

  ul {
    display: flex;
    justify-content: center;
    margin-bottom: 1.88rem;

    li {
      flex: 1;
      text-align: center;

      button {
        text-transform: capitalize;
        font-size: 1rem;
        font-weight: 700;
        font-family: ${({ theme }) => theme.font.default};
        padding: 3px 6px;

        > div {
          padding: 3px 0;
        }
      }

      &.active {
        border: 2px solid ${({ theme }) => theme.color.grey_900};
      }

      &.disabled {
        div {
          color: ${({ theme }) => theme.color.grey_400};
        }
      }
    }
  }
`;

const WeekRow = ({
  selectedDay,
  setSelectedDay,
  specialDates,
  recurrentEvents,
}) => {
  const {
    getWeek,
    currentWeek,
    startDate,
    recurrenceExists,
    header: { startMonth, startDay, endDay, endMonth },
  } = useEvents();

  const isSameDay = (day: Moment): boolean =>
    selectedDay.dayOfYear() === day.dayOfYear();

  const isDisabled = (day: Moment): boolean => {
    const currentDay = moment(day).locale("en");
    if (!currentDay) return true;

    const currentWeekday = currentDay.format("dddd").toLowerCase();

    const recurrences = recurrentEvents
      ? recurrenceExists(currentDay, recurrentEvents[currentWeekday])
      : false;
    const specialDateExists = specialDates
      ? specialDates[currentDay.dayOfYear()]
      : false;

    if (recurrences || specialDateExists) return false;
    return true;
  };

  const isActive = (day: Moment): boolean => !isDisabled(day) && isSameDay(day);

  const onDayClick = (day: Moment): void => {
    const isEmpty = isDisabled(day);
    if (!isSameDay(day) && !isEmpty) setSelectedDay(day);
  };

  const prevNextWeek = (nextWeek = false) => {
    const newStartDate = nextWeek
      ? moment(startDate).add(7, "d")
      : moment(startDate).subtract(7, "d");
    const changedWeek = () => getWeek(newStartDate);
    if (changedWeek()) setSelectedDay(newStartDate);
  };

  return (
    <StyledWeekRow>
      <div>
        <button onClick={() => prevNextWeek()}>
          <LeftArrow />
        </button>
        <span>{`${startMonth} ${startDay} - ${endDay} ${endMonth}`}</span>
        <button onClick={() => prevNextWeek(true)}>
          <RightArrow />
        </button>
      </div>
      <ul>
        {currentWeek.map((day) => (
          <li
            key={day.format("YYYY-MM-DD")}
            className={classNames({
              active: isActive(day),
              disabled: isDisabled(day),
            })}
          >
            <button onClick={() => onDayClick(day)}>
              <div>{day.format("dd.")}</div>
              <div>{day.format("DD")}</div>
            </button>
          </li>
        ))}
      </ul>
    </StyledWeekRow>
  );
};

export default WeekRow;
