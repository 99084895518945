import { ImageLoaderProps } from "next/image";
import { FormattedDate } from "react-intl";

// Types
import { Article } from "@libema/content-sdk";

// Components
import { Card } from "@libema/design-system";

// Local Components
import Link from "../../elements/Link";
import SafeWebpImage from "../../elements/SafeWebpImage";

// Style
import { StyledCard, TitleHeader, Tags, Tag, DateLabel } from "./style";

type Props = Article & {
  locale: string;
  className?: string;
};

// TODO: reuse same image loader among components.
const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?w=${width}&h=${Math.ceil(width * 0.5)}&q=${
    quality || 85
  }&fit=fill&f=faces&fm=webp`;
};

const ArticleCard = ({
  title,
  media,
  publishedAt,
  detailPage,
  categories,
  locale,
  className,
}: Props) => {
  return (
    <StyledCard className={className}>
      <Card.ActionArea>
        <Link href={detailPage?.slug || "/"} locale={locale}>
          <Card.Media>
            <SafeWebpImage
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              src={media.url}
              alt={media.title}
              quality={90}
              loader={imageLoader}
              placeholder="blur"
            />
          </Card.Media>
          <Card.Content>
            <Tags>
              {categories.map(({ id, title }) => {
                return (
                  <Tag className="tag" key={id}>
                    {title}
                    <span className="divider">,</span>
                  </Tag>
                );
              })}
              <DateLabel>
                <FormattedDate
                  value={publishedAt}
                  year="numeric"
                  month="long"
                  day="numeric"
                />
              </DateLabel>
            </Tags>

            <TitleHeader title={title}>{title}</TitleHeader>
          </Card.Content>
        </Link>
      </Card.ActionArea>
    </StyledCard>
  );
};

export default ArticleCard;
