import { Button } from "@libema/design-system";
import styled from "styled-components";

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  ${Button} {
    margin-right: 0.25rem;
  }
`;

const ParksRow = ({ parks, selectedPark, onSelect }) => (
  <Filters>
    {parks.map((park: string) => (
      <Button
        key={park}
        variant={selectedPark === park ? "contained" : "outlined"}
        size="small"
        colorType="secondary"
        onClick={() => onSelect(park)}
      >
        {park}
      </Button>
    ))}
  </Filters>
);

export default ParksRow;
