import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import trans from 'counterpart'
import ObjectPreference from './ObjectPreference'
import PropertyPreference from './PropertyPreference'
import {
  preferencesForReservationSelector,
  propertyFilterCountSelector,
  resourceInReservationSelector,
  subjectsInReservationSelectorAsString
} from '../selectors'
import { getAvailableProperties } from '../actions'

const getPropertyCount = (counts, propertyId) => {
  if (!counts) {
    return 0
  }

  return counts[propertyId]
}

class PreferencesForm extends React.Component {
  componentDidUpdate = (prevProps) => {
    if (prevProps.subjectsInCriteria && prevProps.subjectsInCriteria !== this.props.subjectsInCriteria) {
      this.props.fetchAvailableProperties()
    }
  }

  render = () => {
    const { preferences, counts, resource } = this.props
    const paidPreferences = (preferences) ? preferences.filter(p => p.has_preference_costs || p.property.preference_costs) : []
    const preferenceCosts = (resource && resource.kind.id === 5) ? '9,95' : trans('preferences.costs')

    return (
      <div className='mt-3'>
        {process.env.NEXT_PUBLIC_APP === 'beeksebergen' && (
          <>
            <h5 className='mt-0 mb-1 text-info text-lato'>
              {trans('preferences.label')}
              {/* <InfoPopover content={properties.preference_costs_info} /> */}
            </h5>
            <p
              className='mb-2'
              dangerouslySetInnerHTML={{
                __html: (resource && resource.preferences_text) ? resource.preferences_text : `${trans('preferences.location')} € ${preferenceCosts}.`
              }}
            />
          </>
        )}
        {process.env.NEXT_PUBLIC_APP === 'dierenbos' && resource && resource.preferences_text && (
          <>
            <h5 className='mt-0 mb-1 text-info text-lato'>
              {trans('preferences.label')}
              {/* <InfoPopover content={properties.preference_costs_info} /> */}
            </h5>
            <p
              className='mb-2'
              dangerouslySetInnerHTML={{
                __html: resource.preferences_text
              }}
            />
          </>
        )}

        <div className='row'>
          {paidPreferences.map(preference => (
            <PropertyPreference
              {...preference.property}
              count={getPropertyCount(counts, preference.property.id)}
              key={preference.property.id}
            />
          ))}
          <ObjectPreference />
        </div>
      </div>
    )
  }
}

PreferencesForm.propTypes = {
  fetchAvailableProperties: PropTypes.func,
  preferences: PropTypes.array,
  counts: PropTypes.object,
  resource: PropTypes.object,
  subjectsInCriteria: PropTypes.string
}

const mapState = state => ({
  preferences: preferencesForReservationSelector(state),
  counts: propertyFilterCountSelector(state),
  resource: resourceInReservationSelector(state),
  subjectsInCriteria: subjectsInReservationSelectorAsString(state)
})

const mapDispatch = dispatch => ({
  fetchAvailableProperties: () => dispatch(getAvailableProperties())
})

export default connect(
  mapState,
  mapDispatch
)(PreferencesForm)
