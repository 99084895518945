import React from 'react'
import PropTypes from 'prop-types'
import BreedingIcon from '../assets/icons/broeden.svg'
import EepIcon from '../assets/icons/eep.svg'
import IucnIcon from '../assets/icons/iucn.svg'
import Iucn2Icon from '../assets/icons/iucn-2.svg'

const svgIcons = ['broeden', 'eep', 'iucn', 'iucn2']

const AnimalProperty = ({ properties }) => {
  return (
    <div className='animal-property'>
      <div className='row'>
        <div className='col-2 col-md-3'>
          {svgIcons.includes(properties.icon) && (
            <>
              {properties.icon === 'broeden' && <BreedingIcon />}
              {properties.icon === 'eep' && <EepIcon />}
              {properties.icon === 'iucn' && <IucnIcon />}
              {properties.icon === 'iucn2' && <Iucn2Icon />}
            </>
          )}
          {!svgIcons.includes(properties.icon) && (
            <span className={`icon icon-${properties.icon}`} />
          )}
        </div>
        <div className='col-10 col-md-9'>
          <div className='property-name'>{properties.title}</div>
          <div className='property-description' dangerouslySetInnerHTML={{ __html: properties.description }} />
        </div>
      </div>
    </div>
  )
}

AnimalProperty.propTypes = {
  properties: PropTypes.object
}

export default AnimalProperty
