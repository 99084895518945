export const FILTER_ARRIVALS = "arrivals";
export const FILTER_DEPARTURES = "departures";
export const FILTER_KINDS = "kinds";
export const FILTER_PROPERTIES = "properties";
export const FILTER_RESORTS = "resorts";

export const KIND_HOTEL = 18021;
export const KIND_CAMPING = 5;

export const ACCOMMODATIONS_WITH_FLEXIBLE_BOOKING_DATES = [
  KIND_CAMPING,
  KIND_HOTEL,
];
