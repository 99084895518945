import React from 'react'
import { withRouter } from 'next/router'
import PropTypes from 'prop-types'
import { Popover, PopoverBody } from 'reactstrap'
import { FacebookShareButton, GooglePlusShareButton, TwitterShareButton, FacebookIcon, GooglePlusIcon, TwitterIcon } from 'react-share'
import trans from 'counterpart'

class SocialShareReservation extends React.Component {
  state = {
    popoverOpen: false
  }

  toggle = () => this.setState(prevState => ({
    popoverOpen: !prevState.popoverOpen
  }))

  render = () => {
    const { router } = this.props
    const shareUrl = router.asPath
    const encodeUrl = encodeURIComponent(shareUrl)

    return (
      <div className='row social-share' data-toggle='tooltip' data-content='Tooltip' id='share' onClick={this.toggle}>
        <div className='col-xs-11 px-0'>
          <div>
            <div>
              <p className='font-weight-bold mb-1'>{trans('share.label')}</p>
            </div>
          </div>
        </div>
        <div className='col-xs-1 px-0'>
          <span>
            <i className='material-icons text-orange' style={{ marginLeft: 5 }}>&#xE80D;</i>
            <Popover placement='top' isOpen={this.state.popoverOpen} popperClassName='popover-share' target='share' toggle={this.toggle}>
              <PopoverBody>
                <ul className='list-social list-inline'>
                  <li>
                    <FacebookShareButton url={shareUrl} quote='Beekse Bergen'>
                      <FacebookIcon size={36} />
                    </FacebookShareButton>
                  </li>
                  <li>
                    <TwitterShareButton url={shareUrl} quote='Beekse Bergen'>
                      <TwitterIcon size={36} />
                    </TwitterShareButton>
                  </li>
                  <li>
                    <GooglePlusShareButton url={shareUrl}>
                      <GooglePlusIcon size={36} />
                    </GooglePlusShareButton>
                  </li>
                  <li>
                    <div className='SocialMediaShareButton SocialMediaShareButton--mail'>
                      <a className='social-icon email' href={`mailto:?subject=${trans('share.mail.subject')}&body=${trans('share.mail.body')} ${encodeUrl}`}>
                        <i className='material-icons'>&#xE0E1;</i>
                      </a>
                    </div>
                  </li>
                </ul>
              </PopoverBody>
            </Popover>
          </span>
        </div>
      </div>
    )
  }
}

SocialShareReservation.propTypes = {
  router: PropTypes.object.isRequired
}

export default withRouter(SocialShareReservation)
