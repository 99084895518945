import React from "react";
import { withRouter } from "next/router";
import PropTypes from "prop-types";
import qs from "query-string";
import { getBlock } from "../../utilities/api/blockApi";
import { postNewsletterSubscription } from "../../utilities/api/newsletterApi";

import Loader from "../loaders/Loader";

/**
 * NOTE:
 * This component is a complete hack to support the original way Selligent works.
 * We hook into the form submit and manually trigger our own submit to allow submitting with Ajax instead of a page change.
 */

/**
 * NOTE:
 * Can be removed after 31-12 after the contract with Selligent has been terminated
 */
class SelligentBlock extends React.Component {
  state = {
    error: false,
    form: null,
    loading: true,
    completed: false,
  };

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  componentDidUpdate = () => {
    const { router, id } = this.props;
    const { completed } = this.state;
    const params = router.query;

    if (router.isReady && !completed) {
      getBlock(id, params)
        .then((response) => {
          this.setState({
            form: response.data.body,
            completed: true,
          });
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .then(() => {
          this.setState({
            loading: false,
          });
        });
    }

    if (this.formRef.current) {
      // Find the form element in the ref
      const form = this.formRef.current.querySelector("form");
      if (!form) {
        return;
      }
      form.addEventListener("submit", (e) => {
        e.preventDefault();

        // Retrieve the ID parameter of the original action attribute
        const querystring = e.target.action.split("?");
        const { ID } = qs.parse(querystring[1]);

        const data = new FormData(form);

        // Trigger our own subscription endpoint with the original ID and formdata
        postNewsletterSubscription(ID, data)
          .then((response) => {
            this.setState({
              form: response.data.message,
            });
          })
          .catch(() => {
            this.setState({ error: true });
          });
      });
    }
  };

  render = () => {
    const { error, form, loading } = this.state;
    if (error) {
      return (
        <div className="alert alert-danger">
          Failed to load the newsletter subscription form
        </div>
      );
    }

    if (loading) return <Loader isShown label="Laden" />;
    if (!form) return null;

    return (
      <div
        className="selligent"
        dangerouslySetInnerHTML={{ __html: form }}
        ref={this.formRef}
      />
    );
  };
}

SelligentBlock.propTypes = {
  id: PropTypes.number,
  router: PropTypes.object,
};

export default withRouter(SelligentBlock);
