import { Asset } from "contentful";
import TYPES from "../../../constants/types";
import Link, { LinkDisplayStyle } from "../outputTypes/Link";
import Image from "../outputTypes/Image";

import * as Contentful from "./types";

export const getHighlightedFromMenuItem = (menuItem: Contentful.MenuItem) => {
  if (!menuItem.fields.highlightedImage || !menuItem.fields.highlightedLink) {
    return null;
  }

  const image = normalizeAsset(menuItem.fields.highlightedImage);
  const link = normalizeLink(menuItem.fields.highlightedLink);

  return { image, link, description: menuItem.fields.highlightedDescription };
};

export const linkTargetMapper = {
  "Open in new window": "_blank",
  "Open in same window": "_self",
  "Open in parent": "_parent",
  "Open in full body": "_top",
};

export const normalizeLink = (link: Contentful.Link): Link => {
  const { url, content, target, label, displayStyle } = link.fields;
  const linkUrl = url || content?.fields?.slug;
  const linkTarget = target ? linkTargetMapper[target] : "_self";

  return {
    id: link.sys.id,
    type: TYPES.Link,
    label: label,
    url: linkUrl || "#missing-url",
    displayStyle: displayStyle || LinkDisplayStyle.LINK,
    target: linkTarget,
    phoneNumber: link.fields.phoneNumber || "",
  };
};

export const normalizeAssetUrl = (url: string): string =>
  url.substr(0, 2) === "//" ? "https:" + url : url;

const YOUTUBE_REGEX = /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;

export const normalizeAsset = (asset: Asset): Image => {
  const youtubeUrls = asset.fields.description?.match(YOUTUBE_REGEX);

  return {
    id: asset.sys.id,
    title: asset.fields.title,
    url: normalizeAssetUrl(asset.fields.file.url),
    description: asset.fields?.description || "",
    ...(youtubeUrls?.length && { youtubeId: youtubeUrls[1] }),
  };
};

export const normalizeMultipleLocations = (locations: any) =>
  locations.items.map((location) => location.name);
