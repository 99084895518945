import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactSwipe from 'react-swipe'
import { Modal, ModalBody } from 'reactstrap'
import trans from 'counterpart'
import Image from '../modules/media/components/Image'

class Gallery extends Component {
  state = {
    modal: false,
    clickedIndex: 0
  }

  next = () => this.reactSwipe.swipe.next();

  prev = () => this.reactSwipe.swipe.prev();

  toggle = (index) => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      clickedIndex: index
    }))

    setTimeout(() => {
      let event
      if (typeof Event === 'function') {
        event = new Event('resize')
      } else {
        // Fallback for IE11. See https://stackoverflow.com/questions/27176983/dispatchevent-not-working-in-ie11
        event = document.createEvent('Event')
        event.initEvent('resize', true, true)
      }
      window.dispatchEvent(event)
    }, 0)
  }

  render () {
    const { gallery, largeSize, thumbSize, title, manageTags } = this.props
    const limit = 3

    if (!gallery) return null
    return (
      <>
        <div id='fotos' className={`gallery clearfix ${gallery.length > 1 ? '' : 'gallery-single'}`} {...manageTags}>
          {(gallery || []).map((item, i) => i < 1 && (
            <div className='gallery-image' key={item.id} onClick={() => this.toggle(i)}>
              <Image
                file={item.thumb ? item.thumb.reference : item.reference}
                filter={largeSize}
                attr={{
                  className: 'img-fluid img-responsive',
                  alt: item.alt
                }}
              />
              {item.content_type.split('/')[0] === 'video' && <i className='material-icons'>&#xE039;</i>}
            </div>
          ))}
          {gallery.length > 1 && (
            <div className='gallery-thumbs row mx-0'>
              {(gallery || []).map((item, i) => i > 0 && i < (limit + 1) && (
                <div className='gallery-item' key={item.id} onClick={() => this.toggle(i)}>
                  <div className='inner'>
                    <Image
                      file={item.thumb ? item.thumb.reference : item.reference}
                      filter={thumbSize}
                      attr={{
                        className: 'img-fluid image',
                        alt: item.alt
                      }}
                    />

                    {gallery.length > (limit + 1) && i === limit && (
                      <div className='count'>
                        <span className='count-text'>
                          {`+ ${gallery.length - (limit + 1)}`}
                          {/* eslint-disable-next-line react/no-unescaped-entities */}
                          <span className='hidden-sm-down'> {trans('label.photos')}</span>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <Modal
          isOpen={this.state.modal}
          toggle={() => this.toggle(0)}
          className='modal-lg'
          zIndex={1200}
          modalClassName='centeredModal'
        >
          <ModalBody className='p-0'>
            <button type='button' className='btn btn-close' onClick={() => this.toggle(0)}>
              <span className='material-icons'>close</span>
            </button>
            <ReactSwipe
              ref={(c) => { this.reactSwipe = c }}
              className='carousel'
              swipeOptions={{ continuous: false, startSlide: this.state.clickedIndex }}
            >
              {(gallery || []).map(item => (
                <div key={item.id}>
                  <Image
                    file={item.thumb ? item.thumb.reference : item.reference}
                    filter='lg_43'
                    attr={{
                      className: 'd-block img-fluid',
                      alt: title
                    }}
                  />
                </div>
              ))}
            </ReactSwipe>
            <a className={`${gallery.length > 1 ? 'carousel-control-prev slick-prev slick-arrow' : ''}`} onClick={this.prev} role='button' data-slide='prev'>
              <span className='sr-only'>Previous</span>
            </a>
            <a className={`${gallery.length > 1 ? 'carousel-control-next slick-next slick-arrow' : ''}`} onClick={this.next} role='button' data-slide='next'>
              <span className='sr-only'>Next</span>
            </a>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

Gallery.defaultProps = {
  largeSize: 'thumbnail_lg',
  thumbSize: 'thumbnail_sm'
}

Gallery.propTypes = {
  largeSize: PropTypes.string,
  thumbSize: PropTypes.string,
  gallery: PropTypes.array,
  title: PropTypes.string,
  id: PropTypes.number,
  manageTags: PropTypes.object
}

export default Gallery
