// eslint-disable-next-line
// @ts-nocheck
import { DefaultTheme, ThemedStyledProps } from "styled-components/macro";
import accounts from "../accounts";
import { lighten } from "polished";

type BackgroundColors = {
  info: string;
  error: string;
  success: string;
  warning: string;
};

export type CustomTheme = {
  button_border_radius?: string;
  color_neutral_dark?: string;
  color_neutral_medium?: string;
  color_neutral_light?: string;
  color_primary?: string;
  color_secondary?: string;
  color_info?: string;
  // color_tertiary?: string
  font_default?: string;
  text_color_default?: string;
  text_color_muted?: string;
  text_color_info?: string;
};

export type Theme = DefaultTheme & {
  backgroundColor: BackgroundColors;
};

export type ThemeProps<P> = ThemedStyledProps<P, Theme>;

export const themeSelector = (theme: string): CustomTheme => {
  return accounts[theme].theme || {};
};

export const getTheme = (customTheme: CustomTheme = {}): Theme => {
  const basicColors = {
    info: "#284ea7",
    error: "#b31d2b",
    success: "#28a745",
    warning: "#ffc107",
  };

  return {
    button: {
      borderRadius: customTheme.button_border_radius || "none",
    },
    color: {
      dark: "#292b2c",
      neutralDark: customTheme.color_neutral_dark || "#a48a76", // Dark Sand
      neutralMedium: customTheme.color_neutral_medium || "#a08b79",
      neutralLight: customTheme.color_neutral_light || "#f6f1e6", // Sand
      muted: "#8A976F",
      primary: customTheme.color_primary || "#e84d0e", // Orange
      secondary: customTheme.color_secondary || "#fcce4c", // Yellow
      info: customTheme.color_info || basicColors.info,
      error: basicColors.error,
      success: basicColors.success,
      warning: basicColors.warning,
    },
    backgroundColor: {
      info: customTheme.color_info || lighten(0.5, basicColors.info),
      error: lighten(0.4, basicColors.error),
      success: lighten(0.4, basicColors.success),
      warning: lighten(0.4, basicColors.warning),
    },
    font: {
      default:
        customTheme.font_default || 'sofia-pro, "Century Gothic", sans-serif',
    },
    textColor: {
      default: customTheme.text_color_default || "#292b2c", // Dark (Blackish)
      muted: customTheme.text_color_muted || "#5c6738", // Green
      info: customTheme.text_color_info || basicColors.info,
      error: basicColors.error,
      success: basicColors.success,
      warning: basicColors.warning,
    },
    margin: {
      sm: "0.5rem",
      md: "1rem",
      lg: "2rem",
    },
    padding: {
      sm: "0.5rem",
      md: "1rem",
      lg: "2rem",
    },
  };
};
