import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { emitButtonClick } from '../../redux/actions'

const ButtonBlock = (props) => {
  const { block, onButtonClick, manageTags } = props

  return (
    <a
      id={block.id}
      href={block.properties.url}
      target={block.properties.target}
      className={`btn ${(block.properties.styles || []).join(' ')} ${block.properties.extra_classes ? block.properties.extra_classes : ''}`}
      onClick={() => onButtonClick(block)}
      {...manageTags}
    >
      {block.value}
    </a>
  )
}

ButtonBlock.propTypes = {
  block: PropTypes.object.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  manageTags: PropTypes.object
}

const mapDispatchToProps = dispatch => ({
  onButtonClick: block => dispatch(emitButtonClick(block))
})

export default connect(
  null,
  mapDispatchToProps
)(ButtonBlock)
