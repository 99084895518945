import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Collapse } from "reactstrap";
import dynamic from "next/dynamic";
import trans from "counterpart";
import SelectField from "./SelectField";
import { RESORT_RESORT, RESORT_HOLIDAYPARK, RESORT_HOTEL } from "../constants";
import {
  getObjectPreferenceInReservation,
  getAvailableObjectsForReservation,
  getResort,
} from "../selectors";
import { getObjects } from "../../booking/actions";
import { criteriaSelector } from "../../booking/selectors";
import { getInitialPropertyPreferences } from "../../../selectors/preferenceSelectors";
import { APP_DIERENBOS } from "../../../constants/apps";
import { PreferenceType } from "../../../constants/newyse";

// Only load the resort map on the client side
const ResortMap = dynamic(() => import("../../../components/maps/ResortMap"), {
  ssr: false,
});

class ObjectPreference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMap: true,
    };
  }

  /**
   * Fetch all objects for the given resource
   */
  componentDidMount() {
    const {
      criteria: { resource_id },
    } = this.props;

    this.props.fetchObjects({
      locale: trans("locale"),
      resource: resource_id,
    });
  }

  /**
   * Transform the objects array to a key => value object so we can use it as options
   * in the select field
   */
  getSelectOptions = () =>
    [{ key: "0", value: trans("preferences.no_preference") }].concat(
      this.props.objects
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        .map((obj) => ({
          key: obj.id || obj.key,
          value: obj.name,
        }))
    );

  /**
   * Get the URL for a google maps tile
   *
   * @param {object} coord
   * @param {number} zoom
   */
  getTileUrl = (coord, zoom) => {
    const { resort } = this.props;

    const locale = trans("locale");

    switch (resort) {
      case RESORT_RESORT: {
        if (
          zoom === 16 &&
          (coord.x < 33700 ||
            coord.x > 33702 ||
            coord.y < 21786 ||
            coord.y > 21788)
        ) {
          return null;
        }

        if (
          zoom === 17 &&
          (coord.x < 67400 ||
            coord.x > 67405 ||
            coord.y < 43573 ||
            coord.y > 43577)
        ) {
          return null;
        }

        if (
          zoom === 18 &&
          (coord.x < 134801 ||
            coord.x > 134811 ||
            coord.y < 87146 ||
            coord.y > 87155)
        ) {
          return null;
        }

        return `https://${process.env.NEXT_PUBLIC_MEDIA_HOST}/map/v3/resort_${locale}/${zoom}/${coord.x}/${coord.y}.png`;
      }
      case RESORT_HOLIDAYPARK: {
        if (
          zoom === 16 &&
          (coord.x < 33699 ||
            coord.x > 33703 ||
            coord.y < 21786 ||
            coord.y > 21789)
        ) {
          return null;
        }

        if (
          zoom === 17 &&
          (coord.x < 67399 ||
            coord.x > 67407 ||
            coord.y < 43572 ||
            coord.y > 43578)
        ) {
          return null;
        }

        if (
          zoom === 18 &&
          (coord.x < 134799 ||
            coord.x > 134815 ||
            coord.y < 87145 ||
            coord.y > 87156)
        ) {
          return null;
        }

        return `https://${process.env.NEXT_PUBLIC_MEDIA_HOST}/map/v3/holidaypark_${locale}/${zoom}/${coord.x}/${coord.y}.png`;
      }
      default: {
        return null;
      }
    }
  };

  /**
   * Get the center of the map on initialisation
   */
  getDefaultCenter() {
    const { resort } = this.props;

    switch (resort) {
      case RESORT_RESORT: {
        return { lat: 51.523175, lng: 5.12868 };
      }
      default: {
        return { lat: 51.523311, lng: 5.129992 };
      }
    }
  }

  /**
   * Get the initial zoom level
   */
  getDefaultZoom() {
    const { resort } = this.props;

    switch (resort) {
      case RESORT_HOLIDAYPARK: {
        return 16;
      }
      default: {
        return 17;
      }
    }
  }

  /**
   * Get the translated resort name
   */
  getResortName = () => {
    if (process.env.NEXT_PUBLIC_APP === APP_DIERENBOS) {
      return trans("resort.dierenbos");
    }

    const { resort } = this.props;

    switch (resort) {
      case RESORT_RESORT: {
        return trans("resort.safari_resort");
      }
      case RESORT_HOLIDAYPARK: {
        return trans("resort.lake_resort");
      }
      case RESORT_HOTEL: {
        return trans("resort.safari_hotel");
      }
      default: {
        return null;
      }
    }
  };

  /**
   * Get the boundaries of the map
   * Users should not be able to scroll outside this area
   */
  getBoundaries(maps) {
    const { resort } = this.props;

    switch (resort) {
      case RESORT_RESORT: {
        // MapTiler geolocation bounds: 5.11859755 51.52237506 5.13249730 51.52861033
        return new maps.LatLngBounds(
          new maps.LatLng(51.519475, 5.121313),
          new maps.LatLng(51.526874, 5.136046)
        );
      }
      default: {
        // MapTiler geolocation bounds; 5.11962747 51.51867718 5.14035755 51.52794562
        return new maps.LatLngBounds(
          new maps.LatLng(51.518677, 5.119627),
          new maps.LatLng(51.527946, 5.140357)
        );
      }
    }
  }

  /**
   * Render the object preference selector and the map
   */
  render() {
    const {
      objectInReservation,
      resort,
      hasFetchedInitialReservation,
      criteria,
    } = this.props;

    // Only start loading the component when we know on what resort the user is trying make the reservation
    if (
      (!resort && process.env.NEXT_PUBLIC_APP !== APP_DIERENBOS) ||
      !hasFetchedInitialReservation
    ) {
      return null;
    }

    return (
      <div className="col-12">
        {criteria.resource_id !== "3002044" && resort !== RESORT_HOTEL && (
          <div className="row">
            <div className="col-6">
              <strong>
                {objectInReservation
                  ? trans("preferences.object_selected", {
                      object: objectInReservation.name,
                    })
                  : trans("preferences.select_object")}
              </strong>
            </div>
            <div className="col-6">
              <Field
                name={`preference-${PreferenceType.UNIT}`}
                component={SelectField}
                options={this.getSelectOptions()}
              />
            </div>
          </div>
        )}
        {criteria.resource_id !== "3002044" && (
          <div className="booking-map pb-3">
            <a
              className="booking-map-btn d-block"
              onClick={() =>
                this.setState((prevState) => ({ showMap: !prevState.showMap }))
              }
            >
              <strong className="title-booking-map text-white">
                {trans("preferences.show_map", {
                  resort: this.getResortName(),
                })}
              </strong>
              <div className="arrow float-right text-white">
                <i className="material-icons md-36 float-right">
                  {this.state.showMap
                    ? "keyboard_arrow_up"
                    : "keyboard_arrow_down"}
                </i>
              </div>
            </a>
            <Collapse isOpen={this.state.showMap}>
              <div className="object-preference-map">
                <ResortMap resort={this.props.resort} />
              </div>
            </Collapse>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  resort: getResort(state),
  objects: getAvailableObjectsForReservation(state),
  objectInReservation: getObjectPreferenceInReservation(state),
  initialValues: getInitialPropertyPreferences(state),
  hasFetchedInitialReservation: state.reservation.initialFetch,
  criteria: criteriaSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchObjects: (params) => dispatch(getObjects(params)),
});

ObjectPreference.propTypes = {
  criteria: PropTypes.shape({
    resource_id: PropTypes.string,
  }),
  fetchObjects: PropTypes.func,
  hasFetchedInitialReservation: PropTypes.bool,
  objects: PropTypes.array,
  objectInReservation: PropTypes.object,
  resort: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "preferences",
    destroyOnUnmount: false,
  })(ObjectPreference)
);
