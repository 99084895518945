import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import MaskedInput from './MaskedInput'

class DateInput extends Component {
  state = {
    focused: false
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.initialFocus !== this.props.initialFocus) {
      this.setState({ focused: this.props.initialFocus })
    }
  }

  /**
   * Checks if the given date is in the right format and is a valid date
   * If so, it transforms the date to the right format
   */
  transformDate = (event) => {
    // const { transform } = this.props;
    if (event.target.value) {
      const date = moment(event.target.value, 'DD-MM-YYYY', true)

      if (date.isValid()) {
        // Allow transforming the date before passing to the onChange callback
        // if (transform) transform(date);

        return date.format('YYYY-MM-DD')
      }
    }

    return null
  }

  /**
   * Checks if the given value is a valid internal date.
   * If so, it transforms the date to the correct display format.
   * If not, it just returns the actual value so the users sees what he's actually typing
   */
  handleValue = () => {
    const { value } = this.props
    if (value) {
      const date = moment(value, 'YYYY-MM-DD', true)
      if (date.isValid()) return date.format('DD-MM-YYYY')
    }

    return value
  }

  handleChange = (event) => {
    const { onChange } = this.props

    const date = this.transformDate(event)
    if (date) onChange(date)
    if (!event.target.value) onChange(null)
  }

  handleBlur = (event) => {
    const { onBlur } = this.props

    const date = this.transformDate(event)
    if (date) onBlur(date)
    if (!event.target.value) onBlur(null)
  }

  render = () => {
    const {
      className,
      disabled,
      error,
      options,
      placeholder,
      touched
    } = this.props

    return (
      <MaskedInput
        className={className}
        value={this.handleValue()}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        autoFocus={this.state.focused}
        placeholder={placeholder}
        disabled={disabled}
        options={{
          date: true,
          delimiter: '-',
          datePattern: ['d', 'm', 'Y'],
          ...options
        }}
        // Workaround for https://github.com/styled-components/styled-components/issues/1198
        touched={touched ? 1 : 0}
        error={error}
      />
    )
  }
}

DateInput.defaultProps = {
  disabled: false,
  onBlur: () => {},
  onChange: () => {}
}

DateInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  initialFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.object,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.string
}

export default DateInput
