import styled from "styled-components";

type Size = "small" | "medium" | "large";
type SizeMap = {
  [K in Size]: number;
};

type Props = {
  size?: Size;
  lineWidth?: number;
};

const sizes: SizeMap = {
  small: 48,
  medium: 72,
  large: 120,
};

export const Loader = styled.div<Props>`
  border: ${(props) =>
    `${props.lineWidth || 6}px solid ${props.theme.color.grey_100}`};
  border-top: ${(props) =>
    `${props.lineWidth || 6}px solid ${props.theme.color.secondary}`};
  border-radius: 50%;
  width: ${(props) => `${sizes[props.size || "small"]}px`};
  height: ${(props) => `${sizes[props.size || "small"]}px`};
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Loader;
