import { gql } from "@apollo/client";

// Data
import link from "../data/link";
import asset from "../data/asset";

const query = gql`
  fragment RelatedPrograms on SectionRelatedPrograms {
    type: __typename
    sys {
      id
    }
    title
    backgroundColor
    callToAction {
      ...Link
    }
    categoryFilterCollection(limit: 5) {
      items {
        title
        linkedFrom {
          programs: programCollection(limit: 5) {
            items {
              sys {
                id
              }
              heading
              title
              subtitle
              introduction {
                json
              }
              mainImage {
                ...Asset
              }
              cta {
                ...Link
              }
              cardDetails
              gallery: galleryCollection(limit: 10) {
                items {
                  sys {
                    id
                  }
                  url
                  title
                  description
                }
              }
              programDuration
              price
              cardDetails
              weekDays
              programMinAge: minAge {
                sys {
                  id
                }
                label
                icon
              }
              location: locationCollection(limit: 5) {
                items {
                  sys {
                    id
                  }
                  name
                  park {
                    sys {
                      id
                    }
                    name
                  }
                }
              }
              detailPage {
                slug
              }
            }
          }
        }
      }
    }
  }

  ${link}
  ${asset}
`;

export default query;
