import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import trans from 'counterpart'
import { Row, Col } from '@libema/design-system'
import { Tab, TabGroup, AdditionEssential } from '@libema/design-system-legacy'
import { getCriteria } from '../../selectors/reservationSelectors'
import { getProposalAdditionGroups, getEssentialAdditions, getMarketingGroups, getAdditionGroupHasQuantity } from '../../selectors/additionSelectors'
import { fetchAdditions } from '../../modules/booking/actions'
import AdditionGroup from './AdditionGroup'
import { getSrc } from '../../utilities/mediaUtilities'
import { updateAdditionCriteria } from '../../modules/reservation/actions'

const NEXT_PUBLIC_MAX_ADDITION_QUANTITY = process.env.NEXT_PUBLIC_MAX_ADDITION_QUANTITY;

const getQuantity = (addition) => {
  // Maxxton will handle the quantity calculation
  if (addition.perSubject) return 1;
  if (addition.addonType === "insurances") return 1;
  // TODO: Remove this temporary fix for attraction pass see https://opifer.atlassian.net/browse/LIBSVD-1088
  if (addition.code === "excard") return 1;
  // TODO: I don't think this is necessary because we only use true/false values for essentials. Cleanup ticket:
  // https://opifer.atlassian.net/browse/LIBSVD-1088
  return parseInt(NEXT_PUBLIC_MAX_ADDITION_QUANTITY, 10) || 1;
};

const addSelectedToGroups = (getAdditions, state) => getAdditions(state)
  .map((group) => ({ ...group, selected: getAdditionGroupHasQuantity(state, group.id) }))

const AdditionOverviewBlock = ({ fetchAdditions, additionGroups, essentialAdditions, marketingGroups, handleAdditionChange }) => {
  const [activeTab, setActiveTab] = React.useState('all')

  useEffect(() => {
    fetchAdditions()
  }, [])

  const filteredGroups = Object.values(additionGroups)
    // Filter only groups that match the active tab
    .filter((additionGroup) => {
      // Filter only groups set as active in the CMS
      if (!additionGroup.active) {
        return false;
      }
      // If the active tab equals 'all', we skip the filter directly
      if (activeTab === 'all') {
        return true
      }

      return additionGroup.marketingGroups.includes(activeTab)
    })

  const defaultGroups = filteredGroups.filter((additionGroup) => !additionGroup.highlighted)
  const highlightedGroups = filteredGroups.filter((additionGroup) => additionGroup.highlighted)

  // only display a max of 3 tabs
  const limitMarkekingGroups = marketingGroups.slice(0, 2)

  const myRef = useRef(null)
  const handleGroupClick = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })


  return (
    <div>
      {essentialAdditions.map((addition) => (
        <AdditionEssential
          key={addition.id}
          additionId={addition.id}
          title={addition.name}
          description={addition.description}
          shortDescription={addition.shortDescription}
          image={addition.media && getSrc(addition.media.reference, 'md_32')}
          priceLabel={addition.price_label}
          quantity={getQuantity(addition)}
          onSubmit={(values) => {
            handleAdditionChange({
              additions: {
                [`addition-${addition.id}`]: {
                  resource_id: addition.id,
                  quantity: values.quantity
                }
              }
            })
          }}
        />
      ))}
      <TabGroup>
        <Tab name='all' onClick={() => setActiveTab('all')} active={activeTab === 'all'}>
          {trans('label.all')}
        </Tab>
        {limitMarkekingGroups.map((group) => (
          <Tab key={group} name={group} onClick={setActiveTab} active={activeTab === group}>
            {group}
          </Tab>
        ))}
      </TabGroup>
      {highlightedGroups.map((highlightedGroup) => (
        <AdditionGroup key={highlightedGroup.id} additionGroup={highlightedGroup} />
      ))}
      <Row ref={myRef}>
        {defaultGroups.map((additionGroup) => (
          <Col key={additionGroup.id} lg={6}>
            <AdditionGroup additionGroup={additionGroup} />
          </Col>
        ))}
      </Row>
    </div>
  )
}

const mapState = (state) => ({
  additionGroups: addSelectedToGroups(getProposalAdditionGroups, state),
  essentialAdditions: getEssentialAdditions(state),
  marketingGroups: getMarketingGroups(state),
  reservationCriteria: getCriteria(state)
})

const mapDispatch = (dispatch) => ({
  handleAdditionChange: (values) => dispatch(updateAdditionCriteria(values)),
  fetchAdditions: () => dispatch(fetchAdditions())
})

export default connect(mapState, mapDispatch)(AdditionOverviewBlock)
