import { FC } from "react";

// Components
import { P, Form, FilterOptions, FilterRadioItem } from "@libema/design-system";

type FilterRadioItemProps = {
  label: string;
  value: any;
  count: number;
  isChecked: boolean;
};

type Props = {
  items: FilterRadioItemProps[];
  onClick: (value: any) => any;
};

const {
  Fields: { Radio },
} = Form;

const FilterRadio: FC<Props> = ({ items, onClick }) => (
  <FilterOptions.List>
    {items?.map((item) => {
      const { label, value, count, isChecked } = item;
      return (
        <FilterRadioItem.ListItem
          key={value}
          onClick={onClick(value)}
          active={isChecked}
        >
          <FilterRadioItem.Content>
            <P>
              {label}{" "}
              <FilterRadioItem.CountLabel>({count})</FilterRadioItem.CountLabel>
            </P>
          </FilterRadioItem.Content>
          <Radio checked={isChecked} onChange={() => value} />
        </FilterRadioItem.ListItem>
      );
    })}
  </FilterOptions.List>
);

export default FilterRadio;
