import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import trans from 'counterpart'
import Popover from './Popover'
import { getProperty } from '../../../../selectors/entitySelectors'
import { getFilter } from '../../../../selectors/filterSelectors'

class RefineFiltersProperty extends React.Component {
  state = {
    showPopover: false
  }

  onChange = () => {
    const { isSelected, hasPets, toggleCheckbox, property } = this.props

    const hasPopoverOnSelect = ['huw'].findIndex(code => property && code === property.code) !== -1

    if (!hasPets && hasPopoverOnSelect && !isSelected) {
      this.setState({ showPopover: true })
    }

    return toggleCheckbox('properties', property.id, !isSelected)
  }

  closePopover = () => this.setState({ showPopover: false });

  hasAvailability = () => this.props.propertyCounts[this.props.propertyId] > 0;

  render = () => {
    const { isSelected, property, propertyId, propertyCounts } = this.props

    if (!property) return null

    return (
      <div key={property.id} className={`form-check ${!this.hasAvailability() ? 'disabled' : ''}`}>
        {this.state.showPopover && (
          <div
            className='modal fade modal-warning show'
            aria-labelledby={property.id}
            role='dialog'
            tabIndex='-1'
            id={`pet-filter-modal-${property.id}`}
          >
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button onClick={this.closePopover} type='button' className='btn btn-close' aria-label='Close'>
                    <span className='material-icons'>close</span>
                  </button>
                  <span className='fa fa-exclamation-triangle' />
                  <h4 className='modal-title m-l-md' id='pet-subject-required-modal'>{trans('refine.modal.title')}</h4>
                </div>
                <div className='modal-body m-t-md'>
                  <p>
                    {trans('refine.modal.chosen')}
                    :
                    <b>
                      {trans('refine.modal.pets_allowed')}
                      .
                    </b>
                    <br />
                    {trans('refine.modal.no_pets')}
                  </p>
                  <p>
                    {trans('refine.modal.add_pets')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <span className='d-flex'>
          <input
            name={property.id}
            id={property.id}
            type='checkbox'
            className='custom-control-input'
            checked={isSelected}
            onChange={this.onChange}
            disabled={!this.hasAvailability()}
          />
          <span className='custom-control-indicator' />
          <span className='custom-control-description'>
            <label htmlFor={property.id}>
              {property.display_name ? property.display_name : property.name}
              {(this.hasAvailability()) ? ` (${propertyCounts[propertyId]}) ` : null}
            </label>
            <div className='information-indicator' />
            {property.preference_costs && property.preference_costs_description && (
              <>
                &nbsp;
                <Popover id={`property-${property.id}`} icon='info' hover>
                  <div dangerouslySetInnerHTML={{ __html: property.preference_costs_description }} />
                </Popover>
              </>
            )}
          </span>
        </span>
      </div>
    )
  }
}

RefineFiltersProperty.propTypes = {
  hasPets: PropTypes.bool,
  isSelected: PropTypes.bool,
  toggleCheckbox: PropTypes.func,
  property: PropTypes.object,
  propertyCounts: PropTypes.object,
  propertyId: PropTypes.number
}

const mapStateToProps = (state, props) => ({
  hasPets: state.preferences.subjects['11'] > 0,
  isSelected: state.preferences.properties.includes(props.propertyId),
  property: getProperty(props.propertyId)(state),
  propertyCounts: getFilter('properties')(state)
})

export default connect(mapStateToProps)(RefineFiltersProperty)
