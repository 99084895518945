import { SET_MODE, SET_CONTENT } from '../modules/environment/actionTypes'

export const initialState = {
  manageMode: 'public',
  manageType: 'content',
  content: null,
  userAgent: null
}

const environment = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODE: {
      if (state.manageMode !== 'manage') {
        return {
          ...state,
          manageMode: action.manageMode,
          manageType: action.manageType
        }
      }
      return state
    }
    case SET_CONTENT: {
      return {
        ...state,
        content: action.content
      }
    }
    default: {
      return state
    }
  }
}

export default environment
