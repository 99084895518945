// TODO: turn into react hook
import React, { useCallback } from "react";
import { Image, Video } from "@libema/content-sdk";
import algoliasearch from "algoliasearch/lite";

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_DISPLAY_MODE = "Grid";

const brand = process.env.NEXT_PUBLIC_ALGOLIA_BRAND ?? "";

export const getActivityIndexName = (locale: string) =>
  `${brand}_activity_${locale}`;
export const getProgramIndexName = (locale: string) =>
  `${brand}_program_${locale}`;

const searchClient = (algoliaAppId, algoliaSearchApiKey) =>
  algoliasearch(algoliaAppId, algoliaSearchApiKey);

export const getSearchClient = () => {
  if (
    !process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ||
    !process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ||
    !process.env.NEXT_PUBLIC_ALGOLIA_BRAND
  ) {
    throw new Error(
      "Missing env variables NEXT_PUBLIC_ALGOLIA_APP_ID or NEXT_PUBLIC_ALGOLIA_BRAND"
    );
  }

  return searchClient(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
  );
};

type useSectionProps = {
  media?: Image | Video;
};

const useSection = ({ media }: useSectionProps) => {
  const isVideo: boolean = media?.contentType?.startsWith("video") || false;

  return {
    isVideo,
  };
};

export default useSection;
