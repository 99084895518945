import styled from "styled-components";

import { Col, DEVICE_SM_UP, H1, Row, Translate } from "@libema/design-system";
import { TYPES } from "@libema/content-sdk";

import ActivityEventCard, {
  DetailCardWrapper,
  StyledActivityEventCard,
} from "./ActivityEventCard";
import ActivityEventSeparator from "./ActivityEventSeparator";

const EventComponent = ({ event }) => {
  switch (event.type) {
    case TYPES.ActivityEvent:
      return <ActivityEventCard event={event} />;
    case TYPES.ActivityEventSeparator:
      return <ActivityEventSeparator />;
    default:
      return null;
  }
};

const Title = styled(H1)`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.5rem;
`;
const Header = styled(Row)`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.color.grey_light_300};
  margin-bottom: 1rem;

  ${Col} {
    display: flex;

    div:last-child {
      margin-left: 2rem;
    }
  }
`;

const StyledEventSchedule = styled.div`
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;

  ${StyledActivityEventCard} {
    &:first-child {
      ${DetailCardWrapper} {
        padding-top: 0;
      }
    }
    &:last-child {
      ${DetailCardWrapper} {
        padding-bottom: 0;
      }
    }
  }
`;

export const LeftColumn = styled.div`
  flex: 0.2;
  @media ${DEVICE_SM_UP} {
    flex: 0.15;
  }
`;
export const RightColumn = styled.div`
  flex: 0.8;
  @media ${DEVICE_SM_UP} {
    flex: 0.85;
  }
`;

const EventSchedule = ({ title, events }) => {
  return (
    <StyledEventSchedule>
      <Title>{title}</Title>
      <Header>
        <Col col={12}>
          <LeftColumn>
            <Translate id="event.schedule.header.time" />
          </LeftColumn>
          <RightColumn>
            <Translate id="event.schedule.header.activities" />
          </RightColumn>
        </Col>
      </Header>
      {events.map((event) => (
        <EventComponent event={event} key={event.id} />
      ))}
    </StyledEventSchedule>
  );
};

export default EventSchedule;
