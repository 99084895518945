const palette = {
  text: {
    primary: "#292B2C",
    secondary: "rgba(41, 43, 44, 0.68)",
    disabled: "rgba(41, 43, 44, 0.38)",
  },
  brand: {
    main: "#596831",
    light: "#8B966F",
    dark: "#3E4822",
    backgroundContrast: "#F5F1E6",
    contrast: "#596831",
    contrastLight: "#8B966F",
    contrastText: "#FFFFFF",
    footer: "#C5B6A7",
    contrastFooter: "#FFFFFF",
  },
  primary: {
    main: "#596831",
    light: "#8B966F",
    dark: "#3E4822",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#E84D0E",
    light: "#EF8357",
    dark: "#A23509",
    contrastText: "#FFFFFF",
  },
  accentPrimary: {
    main: "#FBBA00",
    light: "#FCCF4D",
    dark: "#C38A00",
    contrastText: "#000000",
  },
  accentSecondary: {
    main: "#F39200",
    light: "#F7B34D",
    dark: "#BA6400",
    contrastText: "#FFFFFF",
  },
  backgroundPrimary: {
    main: "#7D583C",
    light: "#A48A77",
    dark: "#573D2A",
    contrastText: "#FFFFFF",
  },
  backgroundSecondary: {
    main: "#AB9681",
    light: "#77695A",
    dark: "#C5B6A7",
    contrastText: "#000000",
  },
};

export default palette;
