import styled from "styled-components/macro";
import { DEVICE_LG_UP } from "../../../utils/deviceUtils";
import Button from "../../Button";

const Wrapper = styled.div`
  display: none;

  @media ${DEVICE_LG_UP} {
    display: block;
  }

  img {
    max-width: 100%;
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    margin-bottom: 16px;
  }
`;

const SubMenuHighlightedItem = (props) => {
  return <Wrapper {...props} />;
};

export default SubMenuHighlightedItem;
