import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import InputLabel from '../../modules/form/components/InputLabel'
import ReservationError from '../../modules/reservation/components/ReservationError'
import AnimalProperty from '../AnimalProperty'

/* eslint-disable camelcase */
const DataviewBlock = ({ properties, data_view }) => (
  <>
    {data_view.name === 'InputLabel' && <InputLabel properties={properties} />}
    {data_view.name === 'ReservationError' && <ReservationError />}
    {data_view.name === 'AnimalProperty' && <AnimalProperty properties={properties} />}
  </>
)

DataviewBlock.propTypes = {
  data_view: PropTypes.object,
  properties: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default DataviewBlock
