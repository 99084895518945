import { useCallback, useState, useEffect } from "react";
import { useRouter } from "next/router";

type UseIsRouteLoading = () => boolean;

const useIsRouteLoading: UseIsRouteLoading = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { events } = useRouter();

  const onRouteChangeStart = useCallback(() => setIsLoading(true), []);
  const onRouteChangeDone = useCallback(() => setIsLoading(false), []);

  useEffect(() => {
    events.on("routeChangeStart", onRouteChangeStart);
    events.on("routeChangeComplete", onRouteChangeDone);
    events.on("routeChangeError", onRouteChangeDone);

    return () => {
      events.off("routeChangeStart", onRouteChangeStart);
      events.off("routeChangeComplete", onRouteChangeDone);
      events.off("routeChangeError", onRouteChangeDone);
    };
  }, [onRouteChangeStart, onRouteChangeDone, events]);

  return isLoading;
};

export default useIsRouteLoading;
