import React from "react";
import styled from "styled-components/macro";

export const successIcon =
  "data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%238b966f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E";
export const errorIcon =
  "data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d41217' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E";

type InputProps = {
  error?: boolean;
  success?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
};

export default styled.input<InputProps>`
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-family: ${({ theme }) => theme.font.default};
  border: 1px solid
    ${({ theme, error, success }) =>
      error
        ? theme.color.error
        : success
        ? theme.color.success
        : theme.color.dark};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  ${({ success, error }) =>
    (error || success) &&
    `
    background-image: url("${success ? successIcon : errorIcon}") !important;
    background-repeat: no-repeat !important;
    background-position: right .75rem center !important;
    background-size: 1rem;
  `}

  :focus {
    border-color: ${({ theme }) => theme.color.primary};
  }
  :disabled {
    background-color: #fff;
    cursor: not-allowed;
  }
`;
