import styled, { css } from "styled-components";
import {
  Button,
  DEVICE_SM_UP,
  DEVICE_XL_UP,
  H1,
  H3,
  P,
  Section,
  ShareButton,
} from "@libema/design-system";

import BlockRichText from "../RichText";
import { LeftColumn } from "../Events/EventSchedule";
import {
  StyledActivityEventCard,
  DetailCard,
} from "../Events/ActivityEventCard";

/* =======================
 * Program Detail
 * ======================= */

export const StyledSection = styled(Section.Wrapper).attrs({
  backgroundColor: Section.BackgroundColor.GREY_100,
})`
  & h2:not(:first-child) {
    margin-top: 24px;
  }
`;

export const PlanningListContainer = styled(Section.Container)`
  border-top: 2px solid ${({ theme }) => theme.color.grey_300};
  padding: 1.5rem 0.5rem;
  max-width: none;
`;

export const Heading = styled.div`
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 0;
`;

export const RichText = styled(BlockRichText)`
  font-size: 1.125rem;
  display: none;

  a:not(${Button}) {
    color: ${({ theme }) => theme.color.primary};
  }

  @media ${DEVICE_SM_UP} {
    display: block;
  }
`;

export const GalleryWrapper = styled.div`
  padding-left: 0.5rem;
`;

export const ProgramDetailWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  display: flex;
  flex-direction: column-reverse;

  @media ${DEVICE_XL_UP} {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
  }
`;

export const ProgramSummary = styled(Section.Header)`
  background: ${({ theme }) => theme.color.grey_100};
  box-shadow: 0px -18px 20px rgba(0, 0, 0, 0.06);
  margin-top: -140px;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 24px;
  position: relative;
  text-align: left;
  z-index: 10;

  ${H1},
  ${Heading} {
    color: ${({ theme }) => theme.color.grey_900};
  }

  ${BlockRichText} {
    color: ${({ theme }) => theme.color.grey_700};
  }

  ${H1} {
    text-align: left;
    text-transform: inherit;
    font-size: 1.75rem;
    line-height: 1.15;
    font-weight: 600;
  }

  @media ${DEVICE_XL_UP} {
    position: sticky;
    top: 4rem;
    margin: 0;
    margin-left: 1.75rem;
    padding: 0;
    padding-right: 0.5rem;
    box-shadow: none;

    ${Heading} {
      font-size: 1.125rem;
    }

    ${H1} {
      font-size: 2.25rem;
    }
  }
`;

export const StyledShareButton = styled(ShareButton)`
  text-transform: uppercase;
  font-size: 0.875rem;
  padding: 0.75rem 2.75rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  @media ${DEVICE_SM_UP} {
    width: auto;
  }
`;

export const MobileShareButton = styled(StyledShareButton)`
  @media ${DEVICE_XL_UP} {
    display: none;
  }
`;

export const SummaryInfo = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.color.text_light};
  line-height: 1.875;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 400;

  p {
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.625rem;
      transform: scale(1.25);
      margin-top: 3px;
    }
  }

  @media ${DEVICE_XL_UP} {
    font-size: 1.125rem;
  }
`;

/* =======================
 * Planning
 * ======================= */

export const PlanningContainer = styled.div`
  margin-top: 16px;
  &:first-of-type {
    margin-top: 24px;
  }

  ${StyledActivityEventCard} {
    margin-bottom: 1rem;

    ${LeftColumn} {
      position: relative;
    }

    ${DetailCard} {
      margin-bottom: 0;
    }
  }
`;

export const PlanningHeader = styled.div`
  margin-bottom: 1rem;

  ${H3} {
    font-size: 1.125rem;
    font-weight: 600;
    text-transform: uppercase;

    @media ${DEVICE_XL_UP} {
      font-size: 1.75rem;
      text-transform: none;
      font-weight: 400;
    }
  }

  ${P} {
    font-size: 0.875rem;
    line-height: 1.428;
  }

  ${H3}, ${P} {
    margin-bottom: 0;
  }
`;

/* =======================
 * Program Item Separator
 * ======================= */

const variants = {
  night: css`
    color: #142877;
    background: linear-gradient(
      180deg,
      rgba(224, 226, 231, 0) 0%,
      #e0e2e7 50%,
      rgba(224, 226, 231, 0) 100%
    );

    ${BlockRichText} {
      a {
        color: #142877;
      }
    }

    @media ${DEVICE_XL_UP} {
      background: linear-gradient(
        180deg,
        rgba(207, 214, 233, 0.65) 0%,
        #d5ddf1 100%,
        rgba(212, 219, 235, 0.53) 50%
      );
    }
  `,
};

export const ProgramItemSeparatorContainer = styled.div<{ variant: "night" }>`
  display: grid;
  grid-template-columns: 25px auto;
  grid-gap: 1rem;
  align-items: center;
  padding: 28px;
  background: linear-gradient(
    180deg,
    rgba(224, 226, 231, 0) 0%,
    #0002 50%,
    rgba(224, 226, 231, 0) 100%
  );
  ${({ variant }) => variants[variant]}

  /*
   * Counter balance margin of the PlanningListContainer on ProgramDetail
   */
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  &:not(:last-of-type) {
    margin-bottom: 0.875rem;
  }

  svg {
    width: 32px;
    height: 32px;
    fill: white;
    filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.1));
  }

  h4 {
    font-size: 1rem;
    font-weight: bold;
  }

  ${BlockRichText} {
    font-size: 1rem;

    ${P}:last-of-type {
      line-height: 1;
      margin-bottom: 0;
    }

    a {
      font-size: 0.875rem;
    }

    @media ${DEVICE_XL_UP} {
      font-size: 1.125rem;
      text-align: center;
    }
  }

  @media ${DEVICE_XL_UP} {
    margin-left: 0;
    margin-right: 0;

    svg {
      width: 42px;
      height: 42px;
    }
  }
`;
