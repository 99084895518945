import { createSelector } from "reselect";
import qs from "query-string";
import moment from "moment";
import { PREFERENCE_SUBJECTS } from "../constants/preferences";
import { buildParams } from "../modules/preferences/utilities";
import { getInitialArrivalDay } from "../utilities/dateUtilities";

const getPreferences = (state) => state.preferences;
export const getPreference = (preference) => (state) =>
  state.preferences[preference];

export const hasAdults = createSelector(
  getPreference(PREFERENCE_SUBJECTS),
  (subjects) =>
    Object.entries(subjects)
      .filter(([, value]) => value > 0)
      .some(([subject]) => [9, 10].includes(parseInt(subject, 10)))
);

export const getQueryString = (state, focus = {}, focusPriority = null) => {
  let params;
  if (typeof focusPriority !== "undefined" && focusPriority === true) {
    params = { ...state.preferences, ...clearEmptyFocus(focus) };
  } else {
    params = { ...clearEmptyFocus(focus), ...state.preferences };
  }

  return qs.stringify(buildParams(params), { arrayFormat: "bracket" });
};

export const getInitialPropertyPreferences = createSelector(
  getPreference("properties"),
  (properties) =>
    properties.reduce(
      (acc, property) => ({
        ...acc,
        [`preference-property-${property}`]: true,
      }),
      {}
    )
);

export const getInitialParams = (state, focus, focusPriority) => {
  let params;
  if (typeof focusPriority !== "undefined" && focusPriority === true) {
    params = {
      ...clearEmptyFocus(state.preferences),
      ...clearEmptyFocus(focus),
    };
  } else {
    params = {
      ...clearEmptyFocus(focus),
      ...clearEmptyFocus(state.preferences),
    };
  }

  let queryParams = {};
  if (typeof window !== "undefined") {
    queryParams = qs.parse(window.location.search, { arrayFormat: "bracket" });
  }

  // If the arrival date is in the past, replace it with the first friday
  const today = moment();
  if (params.arrival && moment(params.arrival) < today) {
    params.arrival = getInitialArrivalDay();
  }

  return buildParams({ ...params, ...queryParams });
};

/**
 * We need to remove focus params that are null or which have an empty array is value
 * to avoid overwriting the state preferences
 */
export const clearEmptyFocus = (focus) =>
  Object.keys(focus).reduce((clearedFocus, key) => {
    if (focus[key] == null) {
      return clearedFocus;
    }

    if (Array.isArray(focus[key]) && focus[key].length < 1) {
      return clearedFocus;
    }

    clearedFocus[key] = focus[key];
    return clearedFocus;
  }, {});

export const getParams = (focus) =>
  createSelector(getPreferences, (preferences) => ({
    ...preferences,
    ...clearEmptyFocus(focus),
  }));
