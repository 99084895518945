import { ReactNode } from "react";
import * as React from "react";
import styled from "styled-components";
import { ReactComponent as BaseIconClose } from "../../assets/Icons/Close.svg";
import { ReactComponent as BaseIconPrevious } from "@fortawesome/fontawesome-free/svgs/solid/arrow-left.svg";
import { ReactComponent as BaseIconNext } from "@fortawesome/fontawesome-free/svgs/solid/arrow-right.svg";
import { DEVICE_MD_DOWN } from "../../utils/deviceUtils";
import YouTube from "react-youtube";

const IconClose = styled(BaseIconClose)`
  fill: white;
  width: 24px;
  height: 24px;
`;

const IconPrevious = styled(BaseIconPrevious)`
  fill: white;
  width: 24px;
  height: 24px;
`;

const IconNext = styled(BaseIconNext)`
  fill: white;
  width: 24px;
  height: 24px;
`;

const Image = ({ src, alt }) => {
  return <img src={src} alt={alt} />;
};

const Container = styled.div`
  background: black;
  z-index: 1055 !important;
  position: fixed !important;
  inset: 0px !important;
  max-height: 100vh !important;
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 10vmin;
  z-index: 1001;
`;

const IndexStyle = styled.div`
  position: absolute;
  top: calc(50% - 0.65rem);
  left: calc(50% - 50px);
  color: white;
  font-size: 1.2rem;
  width: 100px;
  height: 1.3rem;
  text-align: center;
`;

const ImageViewer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10vmin 5rem 10vmin 5rem;

  @media ${DEVICE_MD_DOWN} {
    padding: 10vmin 0rem 10vmin 0rem;
  }

  > * {
    width: 100%;
    height: 100%;
    position: relative !important;
  }

  // New nextjs image is using a span as wrapper.
  > span {
    width: 100% !important;
    height: 100% !important;
    position: relative !important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    position: relative !important;
  }
`;

const YoutubeViewer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10vmin 5rem 10vmin 5rem;

  @media ${DEVICE_MD_DOWN} {
    padding: 10vmin 0rem 10vmin 0rem;
  }

  > * {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative !important;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const ButtonCloseStyle = styled.button`
  position: absolute;
  padding: 0.5rem;
  top: calc(50% - 0.65rem);
  left: 1rem;
  z-index: 1002;
  background-color: transparent;
`;

const IconButton = styled.button`
  position: absolute;
  padding: 0.5rem;
  background-color: transparent;

  > * {
    fill: rgba(255, 255, 255, 0.8);
  }

  &:hover * {
    fill: rgba(255, 255, 255, 1);
  }

  &:active * {
    fill: ${({ theme }) => theme.color.primary};
  }

  &:disabled * {
    fill: rgba(255, 255, 255, 0.4);
  }
`;

const ButtonPreviousStyle = styled(IconButton)`
  top: 50%;
  left: 1rem;
  z-index: 1010;
`;

const ButtonNextStyle = styled(IconButton)`
  top: 50%;
  right: 1rem;
  z-index: 1011;
`;

export enum PhotoviewerImageType {
  YOUTUBE = "YOUTUBE",
  IMAGE = "IMAGE",
}

export type PhotoviewerImage = {
  src: string;
  alt: string;
  youtubeId?: string;
};

export type ImageComponentProps = {
  src: string;
  alt: string;
};

export type PhotoviewerContextType = {
  slides: PhotoviewerImage[];
  pointer: number;
  setSlides: React.Dispatch<React.SetStateAction<PhotoviewerImage[]>>;
  setPointer: React.Dispatch<React.SetStateAction<number>>;
  onClose?: () => void;
};

export const PhotoviewerContext = React.createContext<PhotoviewerContextType>({
  slides: [],
  pointer: 0,
  setSlides: () => {
    throw new Error("PhotoviewerContext not found.");
  },
  setPointer: () => {
    throw new Error("PhotoviewerContext not found.");
  },
  onClose: () => {
    throw new Error("PhotoviewerContext not found.");
  },
});

export const usePhotoviewerContext = () => {
  const context = React.useContext(PhotoviewerContext);
  if (!context) {
    throw new Error(
      `Photoviewer components cannot be rendered outside the Photoviewer component`
    );
  }
  return context;
};

export const ButtonClose = () => {
  const { onClose } = usePhotoviewerContext();

  if (!onClose) {
    return null;
  }

  return (
    <ButtonCloseStyle onClick={onClose}>
      <IconClose />
    </ButtonCloseStyle>
  );
};

export const Index: React.FC = () => {
  const { pointer, slides } = usePhotoviewerContext();

  return (
    <IndexStyle>
      {pointer + 1}/{slides.length}
    </IndexStyle>
  );
};

export const ButtonPrevious = () => {
  const { pointer, setPointer } = usePhotoviewerContext();

  const previousSlide = () => setPointer((value) => value - 1);

  return (
    <ButtonPreviousStyle onClick={previousSlide} disabled={pointer === 0}>
      <IconPrevious />
    </ButtonPreviousStyle>
  );
};

export const ButtonNext = () => {
  const { pointer, setPointer, slides } = usePhotoviewerContext();

  const nextSlide = () => setPointer((value) => value + 1);

  return (
    <ButtonNextStyle
      onClick={nextSlide}
      disabled={pointer === slides.length - 1}
    >
      <IconNext />
    </ButtonNextStyle>
  );
};

export const Photo: React.FC = ({ children: child }) => {
  const { slides, pointer, setPointer } = usePhotoviewerContext();
  const slide = slides[pointer] || {};

  React.useEffect(() => {
    const keyPress = (event) => {
      if (event.key === "ArrowLeft") {
        setPointer((value) => (value > 0 ? value - 1 : value));
      } else if (event.key === "ArrowRight") {
        setPointer((value) => (value + 1 < slides.length ? value + 1 : value));
      }
    };

    window.addEventListener("keydown", keyPress);
    return () => {
      window.removeEventListener("keydown", keyPress);
    };
  }, [slides, setPointer]);

  if (slide?.youtubeId) {
    return (
      <YoutubeViewer>
        <YouTube videoId={slide.youtubeId} />
      </YoutubeViewer>
    );
  }

  let viewerChild: ReactNode = null;

  if (child) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    viewerChild = React.cloneElement(child as React.ReactElement<any>, {
      src: slide.src,
      alt: slide.alt,
    });
  } else {
    viewerChild = <Image {...slide} />;
  }

  return <ImageViewer>{slides.length && viewerChild}</ImageViewer>;
};

export type PhotoviewerProps = {
  images: PhotoviewerImage[];
  startAt?: number;
  onClose?: () => void;
  initialIndex?: number;
};

export const Photoviewer: React.FC<PhotoviewerProps> = ({
  images,
  startAt,
  onClose,
  children,
  initialIndex = 0,
}) => {
  const [slides, setSlides] = React.useState<PhotoviewerImage[]>([]);
  const [pointer, setPointer] = React.useState(initialIndex);

  React.useEffect(() => {
    setSlides(images);
    if (startAt) {
      setPointer(startAt);
    }
    document.body.style.setProperty("overflow", "hidden");
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, [images, startAt]);

  const value = React.useMemo(
    () => ({ slides, setSlides, pointer, setPointer, onClose }),
    [slides, pointer, onClose]
  );
  return (
    <PhotoviewerContext.Provider value={value}>
      <Container>{children}</Container>
    </PhotoviewerContext.Provider>
  );
};

export default Photoviewer;
