const fr = {
  locale: "fr",
  /**
   *  ==================================================================
   *  Contentful
   *  ==================================================================
   */

  /**
   *  ==================================================================
   *  404 Page
   *  ==================================================================
   */
  "page.404.title": "Page non trouvée | Beeksebergen",

  /**
   *  ==================================================================
   *  Footer
   *  ==================================================================
   */
  "footer.title":
    "Vous vivrez le meilleur moment à Beeksebergen, une partie de Libéma",

  /**
   *  ==================================================================
   *  Old CMS
   *  ==================================================================
   */
  action: {
    submit_subjects_error: "Min. 1 adulte",
    create_customer_error:
      "Le formulaire de données personnelles contient des erreurs",
  },
  alert: {
    noResults: "Aucun résultat n'a été trouvé",
    brochure_request_error:
      "Er is een fout opgetreden tijdens uw aanvraag. Probeer het later nog eens. Onze excuses voor het ongemak.",
    brochure_request_successful:
      "Uw brochure aanvraag is successvol ontvangen. U ontvangt uw brochure binnen enkele dagen per post.",
    brochure_form_error:
      "Er is een fout opgetreden tijdens uw aanvraag. Alle velden met een * zijn verplicht.",
    availabillity_failure:
      "Une erreur est survenue lors du processus de contrôle de la disponibilité. Veuillez essayer de nouveau ultérieurement ou nous contacter au +31 (0)88-9000360.",
    close: "Fermer",
    error: "Une erreur est survenue. Veuillez réessayer plus tard.",
    successful: "Bedankt! Wij hebben het formulier ontvangen.",
    newsletter_subscription_successful:
      "Bedankt voor je aanmelding. We hebben je een e-mail gestuurd. Klik op de link in deze e-mail om jouw aanmelding te bevestigen.",
    newsletter_subscription_unsuccessful:
      "Oeps, er is iets misgegaan. Probeer het op een later moment nog eens.",
    newsletter_subscription_conflict:
      "Oeps, je bent al aangemeld. Mocht je de nieuwsbrief niet ontvangen, bekijk de spambox dan eens. Zie je hem ook hier niet? Neem dan contact met ons op.",
  },
  button: {
    filter: "Filtre",
    load_more: "Téléchargez plus d'informations",
    more_info: "Information",
    results: "Résultats",
    show_all: "Tout montrer",
    request_brochure: "Demande de brochure",
    submit: "Envoyer",
    close: "Fermer",
    add: "Ajouter",
    remove: "Retirer",
    edit: "Modifier",
    reservation_new: "Nouvelle réservation",
    reservation_confirmation_and_pay: "Confirmer et payer immédiatement",
    reservation_confirmation_and_pay_less_than_6_weeks: "Continuer",
    reservation_confirmation_and_pay_more_than_6_weeks: "Confirmer et payer",
    voucher_clear: "bon d'échange clair",
    complete: "Compléter",
  },
  form: {
    datefield_invalid: "La date de naissance est invalide",
    fields_required: "Ces champs est obligatoire",
    field_mandatory: "Ce champ est obligatoire",
    field_email_invalid: "Adresse courriel est invalide",
    field_email_2_error: "Les adresses courriel ne correspondent pas",
    placeholder: {
      search: "Chercher",
      birth_date: "jj-mm-aaaa",
    },
    required: {
      fields: "Ces champs sont obligatoires.",
    },
    send: "Envoyer",
    termsConditions: {
      text1: "J'accepte les ",
      text2: "termes et conditions",
      link2:
        "https://www.beeksebergen.nl/fr/reglement-du-parc/reglement-sejourner",
      text3: ", les ",
      text4: "termes et conditions de Recron",
      recronUrl: {
        dierenbos:
          "https://dierenbos.s3.eu-west-1.amazonaws.com/originals/948--recron-voorwaarden-vakantieverblijven--%%2528groen%%2529---nederlands-%%25284%%2529.pdf",
        safari_resort:
          "https://beeksebergen.s3.eu-west-1.amazonaws.com/originals/948--recron-voorwaarden-vakantieverblijven--%%2528groen%%2529---nederlands-%%25284%%2529.pdf",
        lake_resort:
          "https://beeksebergen.s3.eu-west-1.amazonaws.com/originals/948--recron-voorwaarden-vakantieverblijven--%%2528groen%%2529---nederlands-%%25284%%2529.pdf",
        safari_hotel:
          "https://beeksebergen.s3.eu-west-1.amazonaws.com/originals/948--recron-voorwaarden-vakantieverblijven--%%2528groen%%2529---nederlands-%%25284%%2529.pdf",
      },
      text5: " et les ",
      text6: "règlements du parc",
      text7: " de ",
      text8:
        " Exploitatie B.V. se réserve le droit de modifier ces termes et conditions et le règlement du parc.",
      parkreglementUrl: {
        safari_resort:
          "https://www.beeksebergen.nl/fr/reglement-du-parc/safari-resort-beekse-bergen",
        lake_resort:
          "https://www.beeksebergen.nl/fr/reglement-du-parc/lake-resort-beekse-bergen",
        safari_hotel:
          "https://www.beeksebergen.nl/fr/reglement-du-parc/safari-hotel-beekse-bergen",
      },
    },
  },
  label: {
    all: "tout",
    at: "à",
    by: "par",
    compare_add: "+ Ajouter à la comparaison",
    edit: "Modifier",
    from_price: "à partir de %(price)s",
    no: "Non",
    persons: "Personnes",
    preferences: "Préférences",
    read_more: "Lire plus",
    travel_company: "Composition du groupe",
    travel_company_edit: "Modifier la composition du groupe",
    select_date: "Sélectionnez la date",
    share: "Partager cette réservation avec d'autres",
    specifications: "Caractéristiques",
    yes: "oui",
    voucher: "Bon",
    your_booking: "Votre réservation",
    accommodation_type_content: "Page",
    all_lastminutes: "Découvrez toutes les offres de dernière minute.",
    available: "Disponible",
    on: "le",
    period_from: "%period% à partir de",
    refine_selection: "Affiner la sélection",
    show_as: "Consulter sous",
    title: "Titre",
    inclusive_stay: "Inclus dans votre séjour",
    mister: "M.",
    miss: "Mme",
    family: "Famille",
    future_booking: "Effectuez d'ores et déjà une pré-réservation pour 2026.",
    future_booking_reminder:
      "Recevez un e-mail dès que les réservations pour 2026 sont ouvertes.",
    future_reservation: "Pré-réservation",
    book_reminder: "Recevez un rappel",
    first_name: "Prénom",
    middle_name: "Particule",
    last_name: "Nom",
    address: "Adresse",
    housenumber: "Numéro",
    housenumber_suffix: "Complément d'adresse",
    zipcode: "Code postal",
    city: "Ville/localité",
    country: "Pays",
    email: "E-mail",
    phone: "Téléphone",
    email_optin:
      "Oui, je souhaite être informé(e) par e-mail. Vous recevrez au maximum une fois toutes les deux semaines un bulletin d'information accompagné d'offres spéciales et de promotions diverses.",
    brochure: "Brochure",
    help: "Assistance",
    new: "Nouveau",
    choose_map: "Sélectionner un emplacement sur la carte.",
    compare: "Comparer",
    week_later: "Une semaine plus tard",
    week_earlier: "Une semaine plus tôt",
    add: "Ajouter",
    book: "Réserver",
    direct: "Directement",
    not_available_in_period:
      "Cet hébergement n'est pas disponible à la date sélectionnée.",
    safari_resort_opening:
      "Le Safari Resort ouvrira ses portes à partir d'avril 2018.",
    nights: "Nuitées",
    night: "nuit",
    days: "Journées",
    book_now: "Réserver",
    read_less: "En savoir moins",
    reservation_payment_cancel:
      "Vous êtes sur le point d'annuler votre réservation. Êtes-vous certain de vouloir confirmer cette annulation ? Vous pouvez également compléter votre réservation ultérieurement.",
    more: "plus",
    compare_more: "Comparer davantage d'options",
    add_accommodation: "Ajouter un hébergement",
    choose_accommodation_to_compare:
      "Choisissez les hébergements que vous souhaitez comparer.",
    choose_quantity: "Choisir la quantité",
    arrival: "Arrivée",
    departure: "Départ",
    discover_more: "En savoir plus",
    tickets: "Tickets",
    search_book: "Recherche et réservation",
    today: "Aujourd'hui",
    tomorrow: "Demain",
    closed: "Fermé",
    loading_holidays: "Rechercher des vacances",
    compare_type: "Comparer avec un autre type",
    photos: "photos",
    description: "description",
    facilities: "équipements",
    select_accommodation: "Sélectionnez un type d'hébergement",
    full: "complet",
    view: "consulter",
    filters_active: "filtres actifs",
    holiday: "Vacances",
    is_winner: "Est-ce un gagnant?",
    newsletter: "Inscrivez-vous à la newsletter?",
    terms: "Acceptez les termes?",
    image: "Photos",
    arrival_date: "date d’arrivée",
    departure_date: "date de départ",
    default_payment_choices:
      "Une fois la réservation confirmée, vous pouvez effectuer votre règlement en ligne, en une ou plusieurs fois. En cas de réservation tardive (avec une date d'arrivée dans les 10 jours ou moins), la totalité du montant du séjour doit être réglée au moment de la réservation.",
    // shortarrival_payment_choices: 'Attention: en cliquant sur « Confirmer et payer immédiatement », vous contractez une obligation de paiement. Comme vos vacances auront lieu dans les %(weeks)s semaines qui viennent, vous devez, après avoir confirmé, payer immédiatement en ligne la totalité du montant.',
    shortarrival_payment_choices:
      "Attention : en cliquant sur « Continuer », vous contractez une obligation de paiement. Vos vacances étant prévues dans les prochaines %(weeks)s semaines, vous devrez payer la totalité du montant en ligne directement après les avoir confirmées.",
    mediumarrival_payment_choices:
      "Une fois la réservation confirmée, vous pourrez immédiatement effectuer votre règlement en ligne.",
    longarrival_payment_choices:
      "Une fois la réservation confirmée, vous pouvez effectuer votre règlement en ligne, en une ou deux fois.",
    save_booking_body:
      "Saisissez votre adresse e-mail pour recevoir le lien qui vous permettra de finaliser plus tard votre réservation.",
    save_booking_button: "Envoyez-moi le lien",
    save_booking_success:
      "Merci, nous vous envoyons directement le lien par e-mail.",
    save_booking_title:
      "Vous préférez finaliser votre réservation à un moment ultérieur?",
    select_stay: "Sélectionnez une nuitée",
    special: "n/a",
    datepicker_toggle_hotel_camping:
      "Jours de transition flexibles (emplacements de camping, Rooms & Suites)",
    datepicker_toggle_arrivals:
      "Jours de transition fixes (maisons de vacances, tentes glamping)",
    date_info_dialog_link:
      "Plus d'informations sur les jours d'arrivée et de départ.",
    date_info_dialog_title: "Jours de départ et d’arrivée",
    date_info_dialog_text1:
      "Nous prenons en compte les différents jours de départ et d’arrivée par parc et par type d’hébergement.",
    date_info_dialog_text2: "Appartements et tentes glamping",
    date_info_dialog_text3:
      "Pour nos appartements et tentes glamping, des jours de départ et d’arrivée sont fixés, ainsi qu’une durée de séjour minimale.",
    date_info_dialog_list1_item1_key: "Jours d’arrivée:",
    date_info_dialog_list1_item1_value: "Lundi, mercredi, vendredi",
    date_info_dialog_list1_item2_key: "Durée minimum de séjour:",
    date_info_dialog_list1_item2_value:
      "2 nuitées en milieu de semaine, 3 nuitées pendant le weekend",
    date_info_dialog_list1_item3_key: "Durée maximale de séjour:",
    date_info_dialog_list1_item3_value: "21 nuitées*",
    date_info_dialog_text4: "Chambres & suites",
    date_info_dialog_text5:
      "Pour nos chambres & suites, les jours d’arrivée et de départ sont flexibles.",
    date_info_dialog_list2_item1_key: "Jours d’arrivée:",
    date_info_dialog_list2_item1_value:
      "Tous les jours de la semaine, à l'exception des périodes spéciales (haute saison, jours fériés et événements musicaux)",
    date_info_dialog_list2_item2_key: "Durée minimum de séjour:",
    date_info_dialog_list2_item2_value:
      "Valide le samedi uniquement (2 nuitées) et lors de périodes exceptionnelles (fériés et festivals)",
    date_info_dialog_list2_item3_key: "Durée maximale de séjour:",
    date_info_dialog_list2_item3_value: "14 nuitées*",
    date_info_dialog_text6: "Emplacements de camping",
    date_info_dialog_text7:
      "Les emplacements de camping sont saisonniers et sont uniquement disponibles entre avril et octobre. Pendant la saison où le camping est ouvert, les emplacements de camping sont ouverts à la réservation tous les jours.",
    date_info_dialog_list3_item1_key: "Jours d’arrivée:",
    date_info_dialog_list3_item1_value:
      "Tous les jours de la semaine, à l'exception des périodes spéciales (haute saison, jours fériés et événements musicaux)",
    date_info_dialog_list3_item2_key: "Durée minimum de séjour:",
    date_info_dialog_list3_item2_value:
      "Non applicable, sauf lors de périodes exceptionnelles (fériés et festivals)",
    date_info_dialog_list3_item3_key: "Durée maximale de séjour:",
    date_info_dialog_list3_item3_value: "21 nuitées*",
    date_info_dialog_text8:
      "* Vous souhaitez séjourner dans l’un de nos parcs plus longtemps que la durée maximale mentionée plus haut ? N'hésitez pas à contacter notre Centre de Contact via le +31889000360.",
    date_info_dialog_terms_conditions_part1: "Les ",
    date_info_dialog_terms_conditions_part2: "conditions d’annulation",
    date_info_dialog_terms_conditions_part3: " sont disponibles ici.",
  },
  calendar: {
    legenda: "Légende",
    selected_day: "Jour choisi",
    today: "Aujourdhui",
    opening_hours_park: "Heures d’ouverture Safaripark",
    opening_hours_play: "Heures d’ouverture Speelland",
    closed: "Fermé",
    open_from: "Ouvrir à partir de",
    open_till: "Ouvert jusquà",
    opening_hours: "Heures d’ouverture",
  },
  nav: {
    faq: "Foire aux questions",
    help: "Besoin d'aide? Appelez-nous au +31 (0)88-9000360",
    opening_hours:
      "(lun-ven jusqu'à 18h00, sam jusqu'à 17h00, dim jusqu'à 16h00)",
  },
  preferences: {
    costs:
      "22,50  (45,00  en une seule fois, directement au niveau de la plaine des lions)",
    label: "Préférences",
    location:
      "Vous avez une préférence concernant le thème ou l'emplacement de votre hébergement/emplacement de camping ?Faites votre choix ci-dessous et payez une seule fois seulement",
    no_preference: "Sélectionner",
    object_selected: "Vous avez sélectionné %(object)s",
    select_object: "Choisissez votre préférence spécifique ici",
    show_map: "Afficher la carte de la %(resort)s",
  },
  pricing: {
    additional_fees: "Coûts supplémentaires",
    all_in_help: "Explication du prix tout compris",
    discount: "Votre réduction",
    payment: {
      label: "maintenant",
      pay: "payer",
    },
    total: "Prix total",
    total_amount: "Montant total",
    total_person: "Total pp.",
    pay_total: "Payer en une fois",
    pay_partially:
      "Payez 30%% maintenant et le reste du montant après avoir reçu la confirmation finale:",
  },
  receipt: {
    inclusive: "Inclus",
    breakfast_buffet: "Buffet de petit déjeuner",
  },
  resort: {
    safari_resort: "Safari Resort",
    safari_hotel: "Safari Hotel",
    lake_resort: "Lake Resort",
    dierenbos: "Vakantiepark Dierenbos",
    bb: "Beekse Bergen",
    ec: "Event Center",
    r: "Resort",
    sl: "Speelland",
    sp: "Safaripark",
    vp_bb: "Beekse Bergen",
  },
  share: {
    label: "Partager cette réservation avec dautres",
    mail: {
      body: "J'ai trouvé quelque chose qui pourrait vous intéresser.",
      subject: "Regardez ce que j'ai trouvé sur le site Web de Beekse Bergen !",
    },
    accommodation: "Partager cet hébergement.",
    compare: "Comparer plus de prix",
  },
  stay: {
    arrival: "Arrivée",
    days: "jours",
    departure: "Départ",
    check_in: "Check in à partir de 15h00",
    check_in_resort: "Check in à partir de 16h00",
    check_in_camping: "Check in à partir de 13h00",
    check_out: "Check out jusquà 10h00",
    check_out_camping: "Check out jusquà 11h00",
    label: "La durée du séjour",
    night: "Nuit",
    nights: "Nuits",
    access_sp_sl: "Accès au Parc animalier et à Speelland",
    access_sl: "Toegang Speelland",
    attraction_pass: "AttractiePas",
    basic_rent: "Location de base",
    book_now: "Réserver directement",
    bedding: "Linge de lit(s) et préparation des lits",
    cleaning: "Ménage de fin de séjour",
    included: "Comprend",
    including: "Inclus dans votre séjour",
    // label: 'Séjour',
    local_charges: "Taxes locales",
    package: {
      towel: "Kit Serviettes de toilette",
      welcome: "Kit de bienvenue",
    },
    reservation_fee: "Frais de réservation",
    camp: {
      basic_rent: "Location de base",
    },
  },
  status: {
    loading_reservation: "Charger votre réservation",
  },
  title: {
    // available_accommodations: '{0} Aucun hébergement disponible|{1} 1 type d\'hébergement disponible|[2,Inf] %count% type d\'hébergement(s) disponible(s).',
    available_accommodations: "type d'hébergement(s) disponible(s).",
    available_on_lakeresort: "le Lake Resort",
    available_on_resort: "le Safari Resort",
    available_at_hotel: "le Safari Hotel",
    filters: "Filtres",
    lastminutes: "les Offres de dernière minute",
    competition_entry: "Fotocompetitie inzending",
    payment_method: "Choisissez votre mode de paiement",
    payment_method_subtitle: "Sélectionnez l'un des modes de paiement.",
    reservation_number: "Numéro de réservation",
    reservation_title_term_short: "Presque là",
    reservation_subtitle_term_short:
      "Pour finaliser votre réservation, il ne vous reste plus qu'à payer.",
    reservation_title_term_medium: "Presque là",
    reservation_subtitle_term_medium:
      "Pour finaliser votre réservation, il ne vous reste plus qu'à payer.",
    reservation_title_term_long: "Votre réservation a été envoyée avec succès",
    reservation_subtitle_term_long:
      "Vous pouvez désormais régler votre réservation directement en ligne.",
    voucher_form: "Consultez votre offre personnelle",
    voucher_form_subtitle_1:
      "Avez-vous reçu un code de réduction de notre part?",
    voucher_form_subtitle_2: "Saisissez-le ici pour utiliser votre offre.",
  },
  accommodation: {
    no_results:
      "Aucun type d'hébergement n'a été sélectionné dans cette section.",
  },
  brochure: {
    brochure: "Ik wil een brochure aanvragen van",
    initials: "Voorletters",
    middle_name: "Tussenvoegsel",
    last_name: "Achternaam",
    address: "Straat en Huisnummer",
    street: "Straatnaam",
    housenumber: "Huisnummer",
    zipcode: "Postcode",
    city: "Plaats",
    country: "Land",
    email: "E-mailadres",
    phone: "Telefoonnummer",
    title: "Aanhef",
  },
  error: {
    api: {
      accommodation_not_available:
        "L'option sélectionnée n'est pas possible avec cette réservation. Veuillez vérifier votre sélection.",
      unexpected:
        "Cette réservation n'est plus disponible ou n'est pas possible pour votre groupe de voyage.",
    },
    form: {
      preference_not_available:
        "Ce numéro n'est plus disponible. Veuillez en sélectionner un autre.",
    },
    email_invalid: "Adresse e-mail invalide",
    help: "Besoin d'aide? Contactez-nous par téléphone au 088-9000360.",
    newyse: {
      general:
        "Une erreur est survenue lors du processus de contrôle de la disponibilité. Veuillez essayer de nouveau ultérieurement ou nous contacter au +31 (0)88-9000360.",
      not_available: "Cet hébergement n'est pas disponible à la date",
    },
    customer_service: "Cette réservation n'est pas disponible",
    save_booking_failure:
      "Une erreur s’est produite lors de l’envoi de l’e-mail.",
    unknown_payment_error:
      "Une erreur inconnue s'est produite lors du paiement de la réservation.",
    internal: {
      title: "Oups... Une erreur s'est produite !",
      message:
        "Une erreur s'est malheureusement produite ! Merci de bien vouloir réessayer plus tard ou de prendre contact avec nos services.<br /><br />Pour toute question concernant un séjour au Lake Resort, au Safari Resort ou au Safari Hotel, vous pouvez nous contacter par téléphone au <strong>+31 (0)88-9000360</strong> ou par e-mail à l'adresse : <strong>Info@libemafunfactory.nl</strong>. Des questions sur l'entrée au Safaripark ? Appelez le <strong>+31 (0)088-9000321</strong> ou envoyez un e-mail à <strong>gastenservicesafaripark@beeksebergen.nl</strong>.",
      email: "info@libemafunfactory.nl",
      phone: "Tél. +31 (0)88-9000360",
      phoneVal: "+310889000360",
    },
  },
  gallery: {
    images: "Photos",
  },
  modals: {
    error: {
      reservation_unavailable: "Votre réservation n'est pas disponible.",
      meet_requirements:
        "La composition du groupe de personnes ne répond pas aux conditions de la réservation. Veuillez modifier la composition du groupe.",
      try_again:
        "Une erreur est survenue lors du processus de réservation. Veuillez essayer de nouveau ultérieurement ou nous contacter au +31 (0)88-9000360.",
    },
    info: {
      travel_company: {
        title: "La composition du groupe a été modifiée.",
        body: "Les animaux de compagnie étant interdits dans ce type d'hébergement, nous avons supprimé l'animal de compagnie mentionné dans votre groupe.",
      },
    },
  },
  placeholder: {
    title: "Civilité",
    first_name: "Prénom ou initiales",
    initials: "Initiales",
    middle_name: "Particule",
    last_name: "Nom",
    address: "Nom de la rue",
    address_and_number: "Rue et numéro",
    housenumber: "Numéro",
    housenumber_suffix: "Par exemple 2H ou App. 3.",
    zipcode: "Code postal",
    city: "Ville/localité",
    country: "Pays",
    email: "Adresse e-mail",
    phone: "Indicatif inclus, par ex. +316123456789",
    voucher: "Entrez votre code de bon ici",
  },
  newyse: {
    accommodation: {
      almost_soldout: "Disponibilité limitée",
      availability_resort:
        "Wist je dat op Safari Resort ook woensdag mogelijk is als aankomstdag?",
      book: "Réserver",
      booking_offline_warning:
        "Étant donné que la date d’arrivée est aujourd’hui même, la réservation est uniquement possible par téléphone, au numéro +31 (0)88 - 9000 360.",
      more_info: "Information",
      resort_unavailable:
        "Le Safari Resort ouvrira ses portes à partir d'avril 2018.",
      unavailable: "Non disponible.",
      unavailable2: "la première date d'arrivée disponible.",
      check: "Regardez",
      via_more_info: "via plus d'infos.",
      no_results:
        "Aucun type d'hébergement n'a été sélectionné dans cette section.",
    },
    compare: {
      duplicate: "Cet hébergement a déjà été sélectionné.",
      label: "Comparer",
      max: "Vous avez atteint le nombre maximum d'hébergements pouvant être comparés.",
      start: "Lancer la comparaison.",
    },
    last_minute_special: "Last minute",
    no_results:
      "Aucun résultat n'a été trouvé. Veuillez modifier vos critères de recherche.",
    resort: {
      holidaypark: "Lake Beekse Bergen",
      resort: "Safari Resort Beekse Bergen",
    },
    stay: {
      days: "{1}1 jour|[2,Inf]%count% jours",
      nights: "{1}1 nuit|[2,Inf]%count% nuits",
    },
  },
  refine: {
    accommodation: "Les hébergements",
    camping: "Camping",
    park: "Parc",
    stay: "Séjour",
    view: "Consulter",
    result: "Résultat",
    results: "Résultats",
    empty_filters: "Supprimer les filtres",
    close: "Fermer",
    apply: "Appliquer",
    modal: {
      title: "Attention",
      chosen: "Vous avez sélectionné",
      pets_allowed: "animaux de compagnie autorisés",
      no_pets: "Aucun animal de compagnie n'a été inclus dans votre groupe.",
      add_pets:
        "Si vous avez l'intention d'emmener un animal de compagnie avec vous, nous vous prions de bien vouloir l'ajouter à votre groupe.",
    },
  },
  routes: {
    anchor: {
      photos: "photos",
      description: "description",
      facilities: "equipements",
    },
    accommodations: "/sejourner/hebergement/",
    booking_url: "/fr/reservation",
    booking_url_fallback: "/fr/reservation-probleme-forme",
    compare: "/fr/sejourner/hebergement/comparer",
    lake_resort: "/fr/lake-resort/",
    home: "/fr",
    last_minute: "/fr/sejourner/offres/last-minute",
    newsletter: "/fr/bulletin-dinformation",
    payment_request: "/fr/reservation/paiement",
    reminder: "/fr/sejourner/reminder-prix-2026",
    search_book: "/fr/sejourner/recherchez-et-reservez",
    search: "/fr/chercher",
    reserve: "/fr/sejourner/reservation-2026",
    tickets: "/fr/tickets",
  },
  search: {
    placeholder: "Chercher",
    no_results:
      "Nous n'avons malheureusement pas trouvé de résultat concernant",
    try_again: "Lancez une nouvelle recherche.",
  },
  sort: {
    best_match: "Convient très bien",
    popularity: "Popularité",
    price_asc: "Prix (ordre croissant)",
    price_desc: "Prix (ordre décroissant)",
  },
  subscribe: {
    caption: "Remarque: vous vous inscrivez à une newsletter néerlandaise",
    newsletter_bb: "Bulletin d'information Beekse Bergen",
    placeholder: "Votre adresse e-mail",
    sign_up: "Enregistrement",
  },
  ticket: {
    label_sp: "",
    online_discount: "avec réduction",
    last_minute_discount: "30 % de réduction",
    order: "Tickets,",
    subtitle: "Découvrez notre parc à un prix encore plus avantageux.",
    title: "Visite en journée ou suivie d'une nuit dans le parc?",
    receive: "Je souhaite recevoir",
    last_minute: "Last minutes",
  },
  travel_company: {
    heading:
      "Combien de personnes vous accompagnent ? La présence d'au moins un adulte est requise.",
    baby: "Enfant en bas âge (jusqu'à 3 ans)",
    child: "Enfant jusqu'à 12 ans",
    adult: "Adulte",
    senior: "Plus de 55 ans",
    pet: "Animal de compagnie",
    minimum: "Min. 1 adulte",
  },
  weather: {
    today: "Aujourd'hui",
    tomorrow: "Demain",
    closed: "Fermé",
  },
  kind: {
    camping: "Camping",
  },
  reservation_checklist: {
    prices: "<strong>Prix tout compris</strong> et sans surprises",
    confirmation:
      "Vous recevrez immédiatement une <strong> confirmation préliminaire </strong>",
    extras:
      "Oublier quelque chose? Plus tard, vous pourrez toujours réserver des <strong> extras </strong>",
    cancellation:
      "Annulation gratuite dans les 24 heures suivant la réservation",
    prices_dierenbos: "<strong>Prix tout compris</strong> et sans surprises",
    size_dierenbos: "2400 m² terrain de jeux",
    pond_dierenbos: "Bassin de baignade naturel",
  },
  cancelReservation: {
    title: "Paiement",
    subtitle:
      "Votre réservation sera confirmée uniquement après paiement, vous êtes cependant sur le point d’abandonner cette réservation. Souhaitez-vous continuer?",
    reservationNumber: "Numéro de réservation",
    cancelPolicy: {
      text1: "Les ",
      text2: "conditions d’annulation",
      text3: " sont disponibles ici.",
    },
    actions: {
      continuePayment: "Vers le paiement",
      continuePaymentHelper:
        "Vous restez sur la page de paiement et pouvez valider ici le paiement.",
      exitReservation: "Payer plus tard",
      exitReservationHelper:
        "Vous retournez à la page d’accueil. Votre réservation est en cours et vous recevrez un e-mail avec les instructions pour payer plus tard.",
      cancelReservation: "Retour à l’étape précédente",
      cancelReservationHelper:
        "Votre réservation actuelle a été annulée. Vous retournez à l’étape précédente, où vous pourrez vérifier, modifier et confirmer à nouveau vos données de réservation.",
    },
    errors: {
      cancelBookingFailure:
        "Il est (temporairement) impossible d’effectuer de réservation avec vos paramètres. Essayez d’abord d’actualiser la page. Besoin d’aide? Contactez-nous par téléphone au 088-9000360.",
    },
  },
};

export default fr;
