import { useMemo, FC } from "react";
import styled from "styled-components";
import currencyjs from "currency.js";

const fontSizes = {
  small: "1rem",
  medium: "1.2rem",
  large: "1.25rem",
  xl: "1.7rem",
};

type Size = keyof typeof fontSizes;
type DecimalType = "default" | "superscript";

type Props = {
  price: number;
  decimalType?: DecimalType;
  currency?: "EUR" | "USD";
  size?: Size;
  className?: string;
  strikeThrough?: boolean;
  showSymbol?: boolean;
  showDecimalSeparator?: boolean;
};

const fontSize = ({ size = "medium" }: Partial<Props>) => {
  return fontSizes[size];
};

const Price = styled.span<Partial<Props>>`
  font-size: ${fontSize};
  font-weight: ${({ strikeThrough }) => (strikeThrough ? "normal" : "bold")};
  text-decoration: ${({ strikeThrough }) =>
    strikeThrough ? "line-through" : "none"};
`;

const Sup = styled.sup`
  vertical-align: super;
  font-size: 0.75rem;
`;

const Currencies = {
  USD: {
    decimal: ".",
    separator: ",",
    symbol: "$",
  },
  EUR: {
    decimal: ",",
    separator: ".",
    symbol: "€",
  },
};

const PriceFormatters = {
  default: ({ formattedPrice }) => formattedPrice.format(),
  superscript: ({
    formattedPrice,
    decimal,
    integersWithSymbol,
    showDecimalSeparator,
  }) => (
    <>
      {integersWithSymbol}
      {showDecimalSeparator ? decimal : null}
      {formattedPrice.cents() > 0 ? (
        <Sup>{formattedPrice.cents()}</Sup>
      ) : showDecimalSeparator ? (
        "-"
      ) : null}
    </>
  ),
};

const Currency: FC<Props> = ({
  price,
  currency = "EUR",
  size = "medium",
  decimalType = "superscript",
  strikeThrough = false,
  showSymbol = true,
  showDecimalSeparator = true,
  className,
}) => {
  const [formattedPrice, currencyInfo, integersWithSymbol] = useMemo(() => {
    // Get the right currency config
    const currencyInfo = Currencies[currency];
    const formattedPrice = currencyjs(price, currencyInfo);

    // Split the integer and decimal parts. So that we can convert
    // 00 into ,-
    const { decimal } = currencyInfo;
    const [integersWithSymbol] = formattedPrice
      .format({ symbol: showSymbol ? currencyInfo.symbol : "" })
      .split(decimal);

    return [formattedPrice, currencyInfo, integersWithSymbol];
  }, [price, currency, showSymbol]);

  const priceProps = {
    integersWithSymbol,
    decimal: currencyInfo.decimal,
    formattedPrice,
    showDecimalSeparator,
  };
  const PriceFormatter = PriceFormatters[decimalType || "superscript"];
  return (
    <Price size={size} className={className} strikeThrough={strikeThrough}>
      {formattedPrice.dollars() > 0 ? (
        <PriceFormatter {...priceProps} />
      ) : (
        formattedPrice.format()
      )}
    </Price>
  );
};

export default Currency;
