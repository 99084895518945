import React from "react";
import { FieldProps } from "formik";
import Radio from "../Radio";

type RadioFieldProps = {
  onChange?: (value: string) => void;
  value: string;
} & FieldProps;

const RadioField: React.FC<RadioFieldProps> = ({
  field,
  form: { setFieldValue },
  value,
  onChange,
  ...restProps
}) => {
  const handleChange = () => {
    setFieldValue(field.name, value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Radio
      {...restProps}
      value={field.value}
      checked={field.value === value}
      onChange={handleChange}
    />
  );
};

export default RadioField;
