import React, { FC, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import {
  InstantSearch,
  SearchBox,
  SortBy,
  Configure,
} from "react-instantsearch-dom";

// Types
import {
  Filter,
  FilterSortType,
  SectionActivityOverview,
} from "@libema/content-sdk";

// Components
import { Col, FilterGroup, P, H1 } from "@libema/design-system";

import RichTextRenderer from "../renderer/RichTextRenderer";
import DetailsCard from "../blocks/DetailsCard";
import {
  ClearRefinementsConnected,
  FilterButtonConnected,
  FilterModalCloseButtonConnected,
  FilterPanel,
  Hits,
  SearchBoxConnected,
} from "../blocks";
import * as FilterProvider from "../blocks/Filters/FilterProvider";
import * as Filters from "../blocks/Filters";
import * as Styles from "./style";
import {
  getActivityIndexName,
  DEFAULT_DISPLAY_MODE,
  DEFAULT_PAGE_SIZE,
  getSearchClient,
} from "../sectionUtils";
import moment from "moment";
import useUrlRouting from "../../hooks/useUrlRouting";

const searchClient = getSearchClient();

const ActivityOverview: FC<SectionActivityOverview> = ({
  filters,
  activities,
  backgroundColor,
  pageSize = DEFAULT_PAGE_SIZE,
  title,
  subtitle,
  description,
  displayMode,
}) => {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const [_displayMode] = useState(displayMode ?? DEFAULT_DISPLAY_MODE);
  const { locale = "nl", query } = useRouter();
  const activityIndexName = getActivityIndexName(locale);

  // TODO: disabled for now since it's breaking the search box
  const [searchState, onSearchStateChange, createURL] = useUrlRouting();

  // Only show activities added to the activities list on Contentful. If none provided,
  // show all activities from algolia.
  const activityIdFilter = useMemo(() => {
    if (activities?.length === 0) return null;
    return activities.map(({ id }) => `id:${id}`).join(" OR ");
  }, [activities]);

  const sortByWeekdays = (a, b) => {
    const weekdays = [0, 1, 2, 3, 4, 5, 6].map((day) =>
      moment().locale(locale).weekday(day).format("dddd")
    );
    return (
      weekdays.indexOf(a.label.toLowerCase()) -
      weekdays.indexOf(b.label.toLowerCase())
    );
  };
  const sortByAlphabeticalOrder = (a, b) => a.label.localeCompare(b.label);
  const sortByCountDesc = (a, b) => {
    if (a.count === b.count) {
      return a.label.localeCompare(b.label);
    }

    return b.count - a.count;
  };

  const SORT_FUNCTIONS = {
    [FilterSortType.WEEKDAYS]: sortByWeekdays,
    [FilterSortType.ALPHABETICAL]: sortByAlphabeticalOrder,
    [FilterSortType.COUNT_DESC]: sortByCountDesc,
  };

  return (
    <FilterProvider.Provider>
      <Styles.StyledSection backgroundColor={backgroundColor}>
        <div className="wrapper">
          <InstantSearch
            indexName={activityIndexName}
            searchClient={searchClient}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
            createURL={createURL}
          >
            {activityIdFilter != null ? (
              <Configure
                hitsPerPage={pageSize || DEFAULT_PAGE_SIZE}
                filters={activityIdFilter}
              />
            ) : (
              <Configure hitsPerPage={DEFAULT_PAGE_SIZE} />
            )}

            <FilterPanel>
              <div>
                {filters?.map((filter) => {
                  const { id, title, filterType } = filter;
                  const Filter = Filters[filterType] as React.FC<Filter> | null;

                  if (!Filter) return <p>Filter {filterType} not supported</p>;

                  return (
                    <Styles.FilterFieldset key={id}>
                      <FilterGroup.Legend>{title}</FilterGroup.Legend>
                      <Filter
                        {...filter}
                        sortFunction={
                          SORT_FUNCTIONS[
                            filter.sortType || FilterSortType.COUNT_DESC
                          ]
                        }
                      />
                    </Styles.FilterFieldset>
                  );
                })}
                <ClearRefinementsConnected />
                <FilterModalCloseButtonConnected />
              </div>
            </FilterPanel>

            <Styles.MainPanel>
              <H1>{title}</H1>
              {subtitle && <P>{subtitle}</P>}
              <RichTextRenderer text={description} />

              <Styles.FilterHeader>
                <div className="search">
                  <Styles.StyledSearchBox>
                    <SearchBoxConnected query={query.query} />
                  </Styles.StyledSearchBox>
                </div>
                <div className="options">
                  <Styles.StyledSort>
                    <SortBy
                      defaultRefinement={activityIndexName}
                      items={[
                        {
                          value: activityIndexName,
                          label: t("activity.sort.relevance"),
                        },
                        {
                          value: `${activityIndexName}_lowestPrice_asc`,
                          label: t("activity.sort.price_asc"),
                        },
                        {
                          value: `${activityIndexName}_lowestPrice_desc`,
                          label: t("activity.sort.price_desc"),
                        },
                      ]}
                    />
                  </Styles.StyledSort>
                  <FilterButtonConnected />
                </div>
              </Styles.FilterHeader>
              <Hits displayMode={_displayMode}>
                {({ hits, isGridView }) => {
                  let sortedActivities = hits;

                  // sort activities in the same order they are defined in the CMS
                  if (activityIdFilter && sortedActivities.length > 0) {
                    sortedActivities = activities
                      .map((activity) => hits.find((a) => a.id === activity.id))
                      .filter((a) => a);
                  }

                  return sortedActivities.map((activity) => (
                    <Col
                      key={activity.id}
                      sm={12}
                      md={isGridView ? 6 : 12}
                      xl={isGridView ? 4 : 12}
                    >
                      <DetailsCard
                        isVertical={isGridView}
                        {...activity}
                        locale={locale}
                        park={activity.location?.park}
                        location={activity.location}
                        cardName="Activity"
                      />
                    </Col>
                  ));
                }}
              </Hits>
            </Styles.MainPanel>
          </InstantSearch>
        </div>
      </Styles.StyledSection>
    </FilterProvider.Provider>
  );
};

export default ActivityOverview;
