export default {
  // Buttons
  "button.subscribe": "Subscribe",

  // Errors
  "error.general": "An error occurred. Please try again.",

  // Form labels
  "placeholder.email": "Your E-mail address",

  // Titles
  "title.newsletter_form": "An adventure in your mailbox?",
  "title.libema_footer":
    "You will have a great time at {account}, which is part of",

  "success.newsletter_subscription":
    "Thank you for registering. We have sent you a verification email to confirm.",

  // Form validators
  "validator.email_invalid": "Invalid email address",
  "validator.required": "This field is mandatory",
};
