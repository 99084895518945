import { FC, useEffect, useState } from "react";
import { H1, H2, useWindowSize } from "@libema/design-system";
import { FiShare } from "react-icons/fi";

// Types
import { Program, TYPES } from "@libema/content-sdk";

// Local components
import RichTextRenderer from "../../renderer/RichTextRenderer";
import Gallery from "../Gallery";
import useMessage from "../../../hooks/useMessage";
import DetailsList from "../DetailsCard/DetailList";
import Planning from "./Planning";
import {
  Heading,
  PlanningListContainer,
  ProgramDetailWrapper,
  ProgramSummary,
  StyledSection,
  RichText,
  GalleryWrapper,
  StyledShareButton,
  MobileShareButton,
  SummaryInfo,
} from "./style";

type Props = {
  program: Program & {
    programDuration?: string;
  };
};

const ProgramDetail: FC<Props> = ({ program }) => {
  const {
    title,
    subtitle,
    heading,
    gallery,
    introduction,
    planning,
    location,
    price,
    programDuration,
    activityTypes,
  } = program;
  const trans = {
    shareButton: useMessage("program.share-button"),
  };

  const [locationHref, setLocationHref] = useState<string | null>(null);

  useEffect(() => {
    setLocationHref(window.location.href);
  }, [locationHref]);

  const { isDesktop } = useWindowSize();
  const showGallery = Boolean(isDesktop && gallery.length);

  return (
    <StyledSection>
      <ProgramDetailWrapper>
        <div>
          {showGallery && (
            <GalleryWrapper>
              <Gallery images={gallery} stripPosition="right" />
            </GalleryWrapper>
          )}
          <PlanningListContainer>
            {subtitle && <H2>{subtitle}</H2>}
            {planning.map((plan) => (
              <Planning key={plan.id} {...plan} />
            ))}
          </PlanningListContainer>
          {locationHref && (
            <MobileShareButton
              url={locationHref}
              buttonProps={{
                variant: "outlined",
                colorType: "dark",
              }}
            >
              {trans.shareButton}
              <FiShare />
            </MobileShareButton>
          )}
        </div>
        <div>
          <ProgramSummary>
            <p>
              {activityTypes
                ?.map(({ label }) => label.toUpperCase())
                .join(" - ")}
            </p>
            <H1>{title}</H1>
            {heading && <Heading>{heading}</Heading>}
            <SummaryInfo>
              <DetailsList
                cardDetails={[TYPES.Location, TYPES.Duration, TYPES.Price]}
                location={location}
                duration={programDuration}
                price={price}
              />
            </SummaryInfo>

            {locationHref && (
              <StyledShareButton
                url={locationHref}
                buttonProps={{
                  variant: "outlined",
                  colorType: "dark",
                }}
              >
                {trans.shareButton}
                <FiShare />
              </StyledShareButton>
            )}
            {introduction && (
              <RichText>
                <RichTextRenderer text={introduction} />
              </RichText>
            )}
          </ProgramSummary>
        </div>
      </ProgramDetailWrapper>
    </StyledSection>
  );
};

export default ProgramDetail;
