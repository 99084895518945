import React from "react";
import styled from "styled-components";
import Translate from "../typography/Translate";
import H3 from "../typography/H3";
import CheckIcon from "../icons/CheckIcon";

const AdditionGroupHighlightContainer = styled.div`
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.margin.lg};
  background-color: ${({ theme }) => theme.color.muted};
  color: white;
  padding: 0;
`;

const Body = styled.div`
  padding: 15px;
  position: relative;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MoreInfoButton = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  height: auto;
  background: transparent;
  border: solid 2px white;
  color: white;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.font.default};
  font-weight: bold;
  padding: 5px 10px;
`;

const Image = styled.img`
  width: 100%;
  margin-right: 15px;
  flex: 0 1 auto;
`;

const HighLightCheckMark = styled(CheckIcon)`
  fill: white;
`;

const RightTop = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.padding.md};
  right: ${({ theme }) => theme.padding.md};
`;
export type AdditionGroupHighlightedProps = {
  isChecked?: boolean;
  title: string;
  image: string;
  priceLabel: string;
  onMoreInfoClick: () => void;
};

export const AdditionGroupHighlighted: React.FC<AdditionGroupHighlightedProps> =
  ({ isChecked, title, image, priceLabel, onMoreInfoClick }) => (
    <AdditionGroupHighlightContainer>
      <Image src={image} />
      <Body>
        <H3>{title}</H3>
        {isChecked && (
          <RightTop>
            <HighLightCheckMark />
          </RightTop>
        )}
        <Footer>
          <span>{priceLabel}</span>
          <MoreInfoButton onClick={onMoreInfoClick}>
            <Translate id="button.more_info" />
            &nbsp;&rsaquo;
          </MoreInfoButton>
        </Footer>
      </Body>
    </AdditionGroupHighlightContainer>
  );

export default AdditionGroupHighlighted;
