import React from "react";
import { connect } from "react-redux";
import reservationSelectors from "../../../selectors/reservationSelectors";
import { ID_SAFARIRESORT_HOTEL } from "../../booking/constants";

const AvailabilityAlert = (props) => {
  if (props.resortId === ID_SAFARIRESORT_HOTEL) {
    return null;
  }

  return (
    (!props.quantity || props.quantity < 8) && (
      <div className="alert alert-warning" {...props.manageTags}>
        {props.properties.message}
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  resortId: reservationSelectors.getResortId(state),
  quantity:
    (state.reservation.offer && state.reservation.offer.quantity) ||
    (state.reservation.offer &&
      state.reservation.offer.price &&
      state.reservation.offer.price.quantity) ||
    0,
});

export default connect(mapStateToProps)(AvailabilityAlert);
