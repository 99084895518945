import styled from "styled-components";

export const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 0;
  text-transform: none;
  font-size: 1rem;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  font-family: ${(props) => props.theme.font.default} !important;
  @media (max-width: 991px) {
    font-size: 1.125rem !important;
  }
  @media (max-width: 767px) {
    font-size: 1rem !important;
  }
`;

export const ButtonOrange = styled(Button)`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.color.primary_disabled
      : props.theme.color.primary};
  border-color: ${(props) => props.theme.color.primary};
  color: #fff;
  text-decoration: none;
  width: 100%;
  font-weight: 700;
  font-size: 1rem;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
`;
