import React from "react";
import styled from "styled-components/macro";
import { DayPickerSingleDateController } from "react-dates";
import "react-dates/initialize";
import { darken, lighten } from "polished";
import moment from "moment";
import Col from "./layout/Col";
import Row from "./layout/Row";
import { DEVICE_LG_UP } from "../utils/deviceUtils";
import Translate from "./typography/Translate";

export const DAY_SIZE = 80;
export const DAY_SIZE_MOBILE = 46;

export const getCalendarDaySize = (device?: string): number =>
  device === "desktop" ? DAY_SIZE : DAY_SIZE_MOBILE;

type DatePickerStylesProps = {
  device?: string;
  height?: number;
};

const DatePickerStyles = styled.div<DatePickerStylesProps>`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.margin.lg};

  .PresetDateRangePicker_panel {
    padding: 0 22px 11px;
  }
  .PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
  }
  .PresetDateRangePicker_button:active {
    outline: 0;
  }
  .PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699;
  }
  .SingleDatePickerInput {
    display: inline-block;
    background-color: #fff;
  }
  .SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb;
  }
  .SingleDatePickerInput__rtl {
    direction: rtl;
  }
  .SingleDatePickerInput__disabled {
    background-color: #f2f2f2;
  }
  .SingleDatePickerInput__block {
    display: block;
  }
  .SingleDatePickerInput__showClearDate {
    padding-right: 30px;
  }
  .SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .SingleDatePickerInput_clearDate__default:focus,
  .SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%;
  }
  .SingleDatePickerInput_clearDate__small {
    padding: 6px;
  }
  .SingleDatePickerInput_clearDate__hide {
    visibility: hidden;
  }
  .SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle;
  }
  .SingleDatePickerInput_clearDate_svg__small {
    height: 9px;
  }
  .SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px;
  }
  .SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle;
  }
  .SingleDatePicker {
    position: relative;
    display: inline-block;
  }
  .SingleDatePicker__block {
    display: block;
  }
  .SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute;
  }
  .SingleDatePicker_picker__rtl {
    direction: rtl;
  }
  .SingleDatePicker_picker__directionLeft {
    left: 0;
  }
  .SingleDatePicker_picker__directionRight {
    right: 0;
  }
  .SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff;
  }
  .SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2;
  }
  .SingleDatePicker_closeButton:focus,
  .SingleDatePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none;
  }
  .SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd;
  }
  .DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px;
  }
  .DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0;
  }
  .DayPickerKeyboardShortcuts_show {
    width: 33px;
    height: 26px;
    position: absolute;
    z-index: 2;
  }
  .DayPickerKeyboardShortcuts_show::before {
    content: "";
    display: block;
    position: absolute;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #008489;
  }
  .DayPickerKeyboardShortcuts_show__topRight {
    top: 0;
    right: 0;
  }
  .DayPickerKeyboardShortcuts_show__topRight::before {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0;
  }
  .DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid #008489;
  }
  .DayPickerKeyboardShortcuts_show__topLeft {
    top: 0;
    left: 0;
  }
  .DayPickerKeyboardShortcuts_show__topLeft::before {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0;
  }
  .DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid #008489;
  }
  .DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute;
  }
  .DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px;
  }
  .DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: 5px;
  }
  .DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: 5px;
  }
  .DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
    text-align: left;
  }
  .DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
  .DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px;
  }
  .DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2;
  }
  .DayPickerKeyboardShortcuts_close:active {
    outline: 0;
  }
  .DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd;
  }
  .DayPickerKeyboardShortcuts_closeSvg:focus,
  .DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a;
  }
  .CalendarDay {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
  }
  .CalendarDay:active {
    outline: 0;
  }
  .CalendarDay__defaultCursor {
    cursor: default;
  }
  .CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff;
  }
  .CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px solid #e4e7e7;
    color: inherit;
  }
  .CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit;
  }
  .CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848;
  }
  .CalendarDay__outside:hover {
    border: 0;
  }
  .CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd;
  }
  .CalendarDay__blocked_minimum_nights:active,
  .CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd;
  }
  .CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848;
  }
  .CalendarDay__highlighted_calendar:active,
  .CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848;
  }
  .CalendarDay__selected_span {
    background: #66e2da;
    border: 1px double #33dacd;
    color: #fff;
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px double #33dacd;
    color: #fff;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #00a699;
    border: 1px double #00a699;
    color: #fff;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 1px double #80e8e0;
    color: #007a87;
  }
  .CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 1px double #80e8e0;
    color: #007a87;
  }
  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a;
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd;
  }
  .CalendarDay__hovered_start_first_possible_end {
    background: #eceeee;
    border: 1px double #eceeee;
  }
  .CalendarDay__hovered_start_blocked_min_nights {
    background: #eceeee;
    border: 1px double #e4e7e7;
  }
  .CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  .CalendarMonth_verticalSpacing {
    border-collapse: separate;
  }
  .CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial;
  }
  .CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px;
  }
  .CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0;
  }
  .CalendarMonthGrid__animating {
    z-index: 1;
  }
  .CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px;
  }
  .CalendarMonthGrid__vertical,
  .CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
  }
  .CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%;
  }
  .CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }
  .CalendarMonthGrid_month__hidden {
    visibility: hidden;
  }
  .DayPickerNavigation {
    position: relative;
    z-index: 2;
  }
  .DayPickerNavigation__horizontal {
    height: 0;
  }
  .DayPickerNavigation__verticalScrollable_prevNav {
    z-index: 1;
  }
  .DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0;
  }
  .DayPickerNavigation__verticalScrollableDefault {
    position: relative;
  }
  .DayPickerNavigation__bottom {
    height: auto;
  }
  .DayPickerNavigation__bottomDefault {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575;
  }
  .DayPickerNavigation_button__default:focus,
  .DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4;
  }
  .DayPickerNavigation_button__default:active {
    background: #f2f2f2;
  }
  .DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid #f2f2f2;
  }
  .DayPickerNavigation_button__disabled:focus,
  .DayPickerNavigation_button__disabled:hover {
    border: 1px solid #f2f2f2;
  }
  .DayPickerNavigation_button__disabled:active {
    background: 0 0;
  }
  .DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: 0.78;
    border-radius: 3px;
    padding: 6px 9px;
  }
  .DayPickerNavigation_bottomButton__horizontalDefault {
    position: static;
    margin: -10px 22px 30px;
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px;
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px;
  }
  .DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%;
  }
  .DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0;
  }
  .DayPickerNavigation_nextButton__verticalScrollableDefault,
  .DayPickerNavigation_prevButton__verticalScrollableDefault {
    width: 100%;
  }
  .DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block;
  }
  .DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848;
  }
  .DayPickerNavigation_svg__disabled {
    fill: #f2f2f2;
  }
  .DayPicker {
    background: #fff;
    position: relative;
    text-align: left;
  }
  .DayPicker__horizontal {
    background: #fff;
  }
  .DayPicker__verticalScrollable {
    height: 100%;
  }
  .DayPicker__hidden {
    visibility: hidden;
  }
  .DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    border-radius: 3px;
  }
  .DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .DayPicker_portal__vertical {
    position: initial;
  }
  .DayPicker_focusRegion {
    outline: 0;
  }
  .DayPicker_calendarInfo__horizontal,
  .DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top;
  }
  .DayPicker_weekHeaders {
    position: relative;
  }
  .DayPicker_weekHeaders__horizontal {
    margin-left: 9px;
  }
  .DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left;
  }
  .DayPicker_weekHeader__vertical {
    left: 50%;
  }
  .DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
  }
  .DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center;
  }
  .DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
  }
  .DayPicker_transitionContainer__horizontal {
    -webkit-transition: height 0.2s ease-in-out;
    -moz-transition: height 0.2s ease-in-out;
    transition: height 0.2s ease-in-out;
  }
  .DayPicker_transitionContainer__vertical {
    width: 100%;
  }
  .DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll;
  }
  .DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 130px;
    vertical-align: middle;
  }
  .DateInput__small {
    width: 97px;
  }
  .DateInput__block {
    width: 100%;
  }
  .DateInput__disabled {
    background: #f2f2f2;
    color: #dbdbdb;
  }
  .DateInput_input {
    font-weight: 200;
    font-size: 19px;
    line-height: 24px;
    color: #484848;
    background-color: #fff;
    width: 100%;
    padding: 11px 11px 9px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0;
  }
  .DateInput_input__small {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.2px;
    padding: 7px 7px 5px;
  }
  .DateInput_input__regular {
    font-weight: auto;
  }
  .DateInput_input__readOnly {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid #008489;
    border-left: 0;
  }
  .DateInput_input__disabled {
    background: #f2f2f2;
    font-style: italic;
  }
  .DateInput_screenReaderMessage {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .DateInput_fang {
    position: absolute;
    width: 20px;
    height: 10px;
    left: 22px;
    z-index: 2;
  }
  .DateInput_fangShape {
    fill: #fff;
  }
  .DateInput_fangStroke {
    stroke: #dbdbdb;
    fill: transparent;
  }
  .DateRangePickerInput {
    background-color: #fff;
    display: inline-block;
  }
  .DateRangePickerInput__disabled {
    background: #f2f2f2;
  }
  .DateRangePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb;
  }
  .DateRangePickerInput__rtl {
    direction: rtl;
  }
  .DateRangePickerInput__block {
    display: block;
  }
  .DateRangePickerInput__showClearDates {
    padding-right: 30px;
  }
  .DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
  }
  .DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px;
  }
  .DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .DateRangePickerInput_clearDates__small {
    padding: 6px;
  }
  .DateRangePickerInput_clearDates_default:focus,
  .DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%;
  }
  .DateRangePickerInput_clearDates__hide {
    visibility: hidden;
  }
  .DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle;
  }
  .DateRangePickerInput_clearDates_svg__small {
    height: 9px;
  }
  .DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px;
  }
  .DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle;
  }
  .DateRangePicker {
    position: relative;
    display: inline-block;
  }
  .DateRangePicker__block {
    display: block;
  }
  .DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute;
  }
  .DateRangePicker_picker__rtl {
    direction: rtl;
  }
  .DateRangePicker_picker__directionLeft {
    left: 0;
  }
  .DateRangePicker_picker__directionRight {
    right: 0;
  }
  .DateRangePicker_picker__portal {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .DateRangePicker_picker__fullScreenPortal {
    background-color: #fff;
  }
  .DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2;
  }
  .DateRangePicker_closeButton:focus,
  .DateRangePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none;
  }
  .DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd;
  }

  @media ${DEVICE_LG_UP} {
    margin-bottom: 0;
  }

  .DayPicker_weekHeader {
    top: 49px;
    width: calc(100% - 9px) !important;
  }

  .DayPicker {
    box-shadow: none;
  }

  .CalendarDay,
  .DayPicker_weekHeader_li {
    color: ${({ theme }) => theme.textColor.default};
    width: 14.285714286% !important;
    font-size: 1rem;
  }

  .DayPicker_weekHeader_li {
    text-transform: capitalize;
    font-weight: bold;
  }

  .DayPickerNavigation_button {
    border: none;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #fff;
    background-color: ${({ theme }) => theme.color.neutralMedium};
    :hover {
      background-color: ${({ theme }) =>
        darken(0.1, theme.color.neutralMedium)};
    }
  }
  .DayPickerNavigation_svg__horizontal {
    fill: #fff;
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    top: 0;
    left: 10px;
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    top: 0;
    right: 10px;
  }

  .CalendarDay__default {
    border: 5px solid #fff;
    background-color: ${({ theme }) => theme.color.neutralLight};
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    line-height: ${({ device }) => getCalendarDaySize(device)}px;
    &:hover {
      background-color: ${({ theme }) => darken(0.1, theme.color.neutralLight)};
      border: 5px solid #fff;
    }
  }

  .CalendarDay__today {
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.color.neutralMedium};
    &:hover {
      box-shadow: inset 0 0 0 3px
        ${({ theme }) => darken(0.1, theme.color.neutralMedium)};
    }
  }

  .CalendarDay__selected {
    background-color: #fff;
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.textColor.default};
    &:hover {
      background-color: #fff;
      box-shadow: inset 0 0 0 3px
        ${({ theme }) => darken(0.1, theme.color.primary)};
      color: ${({ theme }) => theme.textColor.default};
    }
  }

  .CalendarDay__blocked_calendar {
    cursor: default;
    background: repeating-linear-gradient(
      45deg,
      #fff,
      #fff 8px,
      ${({ theme }) => theme.color.neutralLight} 8px,
      ${({ theme }) => theme.color.neutralLight} 16px
    );
    &:hover {
      background: repeating-linear-gradient(
        45deg,
        #fff,
        #fff 8px,
        ${({ theme }) => theme.color.neutralLight} 8px,
        ${({ theme }) => theme.color.neutralLight} 16px
      );
    }
  }

  .CalendarDay__highlighted_calendar {
    cursor: pointer;
    background: repeating-linear-gradient(
      45deg,
      #fff,
      #fff 8px,
      ${({ theme }) => lighten(0.5, theme.color.error)} 8px,
      ${({ theme }) => lighten(0.5, theme.color.error)} 16px
    );
    &:hover {
      background: repeating-linear-gradient(
        45deg,
        #fff,
        #fff 8px,
        ${({ theme }) => lighten(0.5, theme.color.error)} 8px,
        ${({ theme }) => lighten(0.5, theme.color.error)} 16px
      );
    }
  }

  .CalendarMonth_caption {
    color: ${({ theme }) => theme.textColor.default};
    text-transform: uppercase;
    padding-top: 0px;
    margin-bottom: 10px;
  }

  .DayPicker,
  .DayPicker > div,
  .DayPicker > div > div,
  .DayPicker_transitionContainer,
  .CalendarMonth_table {
    width: 100% !important;
  }

  .DayPicker_transitionContainer {
    height: ${({ height }) => `${height}px !important` || "auto"};
  }

  .CalendarMonthGrid {
    width: 1000% !important;
  }

  .CalendarMonthGrid_month__horizontal {
    width: calc(10% - 18px) !important;
  }
`;

export type AvailableDates = {
  [date: string]: boolean;
};

export type DatePickerProps = {
  availableDates: AvailableDates;
  device?: string;
  initialDate?: moment.Moment;
  isDayHighlighted?: (day: moment.Moment) => boolean;
  onDateChange: (date: moment.Moment | null) => void;
  onMonthChange?: (date: moment.Moment, direction: "next" | "prev") => void;
};

type DatePickerState = {
  date: moment.Moment | null;
  height?: number;
};

const LegendBox = styled.div`
  border-width: 1px !important;
  display: inline-block;
  width: 25px;
  height: 25px;
  @media ${DEVICE_LG_UP} {
    margin-right: ${({ theme }) => theme.margin.sm};
  }
`;

const LegendLabel = styled.label`
  display: block;
  text-align: center;
  @media ${DEVICE_LG_UP} {
    display: inline-block;
    text-align: left;
    margin-right: ${({ theme }) => theme.margin.lg};
  }
`;

const Legend = styled.div`
  margin: ${({ theme }) => theme.margin.md} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
`;

const LegendItem = styled.div`
  width: 30%;
  font-size: 0.8rem;
  @media ${DEVICE_LG_UP} {
    width: auto;
  }
`;

const LegendHeader = styled.div`
  display: none;
  @media ${DEVICE_LG_UP} {
    display: block;
  }
`;

const CalendarCol = styled(Col)`
  padding: 0;
`;

class DatePicker extends React.PureComponent<DatePickerProps, DatePickerState> {
  constructor(props: DatePickerProps) {
    super(props);

    this.state = {
      date: props.initialDate || null,
    };
  }

  componentDidMount = () => {
    if (this.state.date) {
      this.resizeDatePicker(this.state.date);
    }
  };

  isDayBlocked = (day: moment.Moment) => {
    const { availableDates } = this.props;
    const match =
      (availableDates && availableDates[day.format("YYYY-MM-DD")]) || false;

    if (match) {
      return false;
    }

    return true;
  };

  // Source: https://stackoverflow.com/questions/58312017/get-the-number-of-weeks-rows-in-a-month-from-moment-js
  calculateRowsInMonth = (date: moment.Moment): number => {
    const startOfMonth = date.startOf("month");
    const firstWeekDays = 7 - startOfMonth.isoWeekday() + 1;

    let rows = 1;

    // Subtract first week
    let rest = date.daysInMonth() - firstWeekDays;

    // Middle rows
    const middleRows = Math.floor(rest / 7);

    rows = rows + middleRows;
    rest = rest - middleRows * 7;

    // Last row?
    if (rest > 0) {
      rows = rows + 1;
    }
    return rows;
  };

  resizeDatePicker = (date: moment.Moment): void => {
    // Manually calculate the calendar height, since react-dates forces an incorrect height
    // after styling changes.
    // See https://github.com/airbnb/react-dates/issues/1641
    const newHeight =
      getCalendarDaySize(this.props.device) * this.calculateRowsInMonth(date) +
      100;
    this.setState({ height: newHeight });
  };

  handleMonthChange = (date: moment.Moment) => {
    this.resizeDatePicker(date);
  };

  handleNextMonth = (date: moment.Moment) => {
    this.handleMonthChange(date);
    if (typeof this.props.onMonthChange !== "undefined") {
      this.props.onMonthChange(date, "next");
    }
  };

  handlePrevMonth = (date: moment.Moment) => {
    this.handleMonthChange(date);
    if (typeof this.props.onMonthChange !== "undefined") {
      this.props.onMonthChange(date, "prev");
    }
  };

  render() {
    return (
      <DatePickerStyles device={this.props.device} height={this.state.height}>
        <Row>
          <CalendarCol>
            <DayPickerSingleDateController
              date={this.state.date}
              daySize={getCalendarDaySize(this.props.device)}
              firstDayOfWeek={1}
              focused={true}
              horizontalMonthPadding={0}
              hideKeyboardShortcutsPanel={true}
              isDayBlocked={this.isDayBlocked}
              isDayHighlighted={this.props.isDayHighlighted}
              numberOfMonths={1}
              onDateChange={(date: moment.Moment | null) =>
                this.setState({ date }, () => this.props.onDateChange(date))
              }
              onFocusChange={() => {
                // Do nothing?
              }}
              onNextMonthClick={this.handleNextMonth}
              onPrevMonthClick={this.handlePrevMonth}
              initialVisibleMonth={null}
            />
          </CalendarCol>
          <Col col={12}>
            <Legend>
              <LegendHeader>
                <LegendLabel>
                  <strong>
                    <Translate id="label.legend" />
                  </strong>
                </LegendLabel>
              </LegendHeader>
              <LegendItem>
                <LegendBox className="CalendarDay__default CalendarDay__today" />
                <LegendLabel>
                  <Translate id="label.today" />
                </LegendLabel>
              </LegendItem>
              <LegendItem>
                <LegendBox className="CalendarDay__default CalendarDay__selected" />
                <LegendLabel>
                  <Translate id="label.selected_day" />
                </LegendLabel>
              </LegendItem>
              <LegendItem>
                <LegendBox className="CalendarDay__default CalendarDay__highlighted_calendar" />
                <LegendLabel>
                  <Translate id="label.sold_out" />
                </LegendLabel>
              </LegendItem>
              <LegendItem>
                <LegendBox className="CalendarDay__default CalendarDay__blocked_calendar" />
                <LegendLabel>
                  <Translate id="label.closed" />
                </LegendLabel>
              </LegendItem>
            </Legend>
          </Col>
        </Row>
      </DatePickerStyles>
    );
  }
}

export default DatePicker;
