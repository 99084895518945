import { gql } from "@apollo/client";

import asset from "../data/asset";
import link from "../data/link";

const fragment = gql`
  fragment Mosaic on SectionMosaic {
    type: __typename
    sys {
      id
    }
    mosaicTitle: title
    textDescription {
      json
      links {
        entries {
          inline {
            ...Link
          }
          block {
            ...Link
          }
        }
        assets {
          block {
            ...Asset
          }
        }
      }
    }
    splitDescription {
      json
      links {
        entries {
          inline {
            ...Link
          }
          block {
            ...Link
          }
        }
        assets {
          block {
            ...Asset
          }
        }
      }
    }
    visualDescription {
      json
      links {
        entries {
          inline {
            ...Link
          }
          block {
            ...Link
          }
        }
        assets {
          block {
            ...Asset
          }
        }
      }
    }
    splitImage {
      sys {
        id
      }
      url
      title
    }
    visualImages: visualImagesCollection(limit: 10) {
      items {
        sys {
          id
        }
        url
        title
        description
      }
    }
    mosaicDisplayStyle: displayStyle
    backgroundColor
  }

  ${link}
  ${asset}
`;

export default fragment;
