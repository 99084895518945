import { FC, useRef, useEffect } from "react";

type Props = {
  action: string;
  params: Record<string, string | number>;
};

const AutoSubmitForm: FC<Props> = ({ action, params }) => {
  const formRef = useRef<HTMLFormElement>(null);
  useEffect(() => {
    if (formRef.current == null) return;
    formRef.current.submit();
  }, []);

  return (
    <form ref={formRef} method="POST" action={action}>
      {Object.keys(params).map((name) => (
        <input type="hidden" name={name} value={params[name]}></input>
      ))}
    </form>
  );
};

export default AutoSubmitForm;
