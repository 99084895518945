import { FC, HTMLAttributes } from "react";
import Container from "../../layout/Container";
import styled from "styled-components/macro";
import { DEVICE_LG_DOWN, DEVICE_LG_UP } from "../../../utils/deviceUtils";

const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.neutralDark};
`;

const StyledNav = styled.nav`
  @media ${DEVICE_LG_UP} {
    padding-top: 8px;
  }
`;

const List = styled(Container).attrs({ as: "ul" })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  @media ${DEVICE_LG_DOWN} {
    max-width: 100%;
    flex-direction: column;
    padding: 0;
  }

  @media ${DEVICE_LG_UP} {
    text-align: center;
  }
`;

const MainMenu: FC<HTMLAttributes<HTMLElement>> = ({ children, ...props }) => {
  return (
    <Wrapper {...props}>
      <StyledNav>
        <List>{children}</List>
      </StyledNav>
    </Wrapper>
  );
};

export default MainMenu;
