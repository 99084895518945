import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { groupValidateClasses } from '../utilities'
import HelpText from '../../../components/HelpText'

const RadioButtonsField = ({ input, label, required, helpText, meta: { touched, error }, options }) => (
  <div className={groupValidateClasses(touched, error)}>
    {label && (
      <label className={`control-label col-lg-4${required ? ' required' : ''}`}>{label}</label>
    )}
    <div className={classnames(label ? 'col-lg-8' : 'col-lg-12', {
      'has-help-text': !!helpText
    })}
    >
      {options.map(o => (
        <div className='radio' key={o.key}>
          <label className={required ? ' required' : ''}>
            <input
              {...input}
              type='radio'
              value={o.key}
              id={`${input.name}-${o.key}`}
              checked={input.value === o.key}
              onChange={event => input.onChange(event)}
            />
            {o.value}
          </label>
        </div>
      ))}
      <HelpText value={helpText} />
      {touched && error && <div className='form-control-feedback'>{error}</div>}
    </div>
  </div>
)

RadioButtonsField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  helpText: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.array,
  required: PropTypes.bool
}

export default RadioButtonsField
