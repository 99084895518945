import React from 'react'
import PropTypes from 'prop-types'
import trans from 'counterpart'
import moment from 'moment'
import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController'
import DatePickerNav from './DatePickerNav'

class DatePicker extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      hasSixWeeks: false,
      initialized: false
    }

    this.specialDateIndex = Object.values(props.dates).reduce((acc, next) => {
      const hasSpecialEvents = next.events && next.events.some(event => event.type === trans('label.special') && event.open)
      if (hasSpecialEvents) {
        acc.push(next.date)
      }
      return acc
    }, [])
  }

  componentDidMount = () => {
    this.handleMonthChange(moment())
    this.setState({
      initialized: true
    })
  }

  /**
   * Months with 31 days where the first day of the month is either on saturday or sunday
   * have six weeks to display instead of 5. Months with 30 days only have six weeks if the
   * sunday is the first day of the month.
   */
  handleMonthChange = (date) => {
    let hasSixWeeks = false
    const firstDayOfTheMonth = moment(date).startOf('month')
    if (firstDayOfTheMonth.daysInMonth() === 31 && (firstDayOfTheMonth.isoWeekday() === 6 || firstDayOfTheMonth.isoWeekday() === 7)) {
      hasSixWeeks = true
    }

    if (firstDayOfTheMonth.daysInMonth() === 30 && firstDayOfTheMonth.isoWeekday() === 7) {
      hasSixWeeks = true
    }

    this.setState({
      hasSixWeeks
    })
  }

  isDayBlocked = (day) => {
    const { dates } = this.props
    const date = day.format('YYYY-MM-DD')
    if (!dates[date]) return true
    if (day.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) return true
    return dates[date].closed
  }

  renderDay = day => (
    <div className={`td-wrapper ${this.specialDateIndex.indexOf(moment(day).format('YYYY-MM-DD')) > -1 ? 'special' : ''}`}>
      <div className='td-inner'>
        <div>{moment(day).format('D')}</div>
      </div>
    </div>
  )

  render = () => {
    const { hasSixWeeks, initialized } = this.state
    if (!initialized) return null
    const { className, leftNav, rightNav, onDateChange, date } = this.props

    return (
      <div className={`${className || 'date-picker-large'} ${!className && hasSixWeeks ? 'additional-row' : ''}`}>
        <DayPickerSingleDateController
          date={date}
          renderDayContents={this.renderDay}
          isDayBlocked={this.isDayBlocked}
          enableOutsideDays
          daySize={50}
          numberOfMonths={1}
          onDateChange={onDateChange}
          onPrevMonthClick={this.handleMonthChange}
          onNextMonthClick={this.handleMonthChange}
          transitionDuration={0}
          navNext={<DatePickerNav value={rightNav || 'keyboard_arrow_right'} />}
          navPrev={<DatePickerNav value={leftNav || 'keyboard_arrow_left'} />}
        />
      </div>
    )
  }
}

DatePicker.propTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dates: PropTypes.object,
  leftNav: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  rightNav: PropTypes.string
}

export default DatePicker
