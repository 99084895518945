import styled from "styled-components";

// Components
import {
  Card,
  UnorderedList,
  Row,
  P,
  H2,
  contrastColor,
  DEVICE_MD_UP,
  DEVICE_MD_DOWN,
} from "@libema/design-system";

import Link from "../../elements/Link";

export const Header = styled.header`
  margin-bottom: 8px;

  ${H2} {
    line-height: 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0 0 1rem;
    color: ${({ theme }) => theme.color.grey_900};
    text-overflow: ellipsis;
  }

  ${P} {
    margin-bottom: 1.25rem;
    font-size: 1rem;
    line-height: 1rem;
  }
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  font-size: 1rem;
  margin-bottom: 0.65rem;

  &:last-child {
    margin-bottom: 0;
  }

  & svg:first-of-type {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.color.text_light};
    margin-right: 1rem;
  }

  ${P} {
    margin: 0;
    color: ${({ theme }) => theme.color.text_light};
    font-weight: 400;
    line-height: 1.2;
  }
`;

export const List = styled(UnorderedList)`
  padding-left: 0;
`;

export const MoreInfoLink = styled.div`
  & a {
    color: ${({ theme }) => theme.color.secondary};
    flex: none;
    text-align: center;
    text-decoration: underline;
    font-family: ${({ theme }) => theme.font.default};
  }
`;

export const StyledBookingLink = styled(Link)`
  & a {
    flex: none;
  }
`;

export const CurrencyWrapper = styled.div`
  font-weight: bold;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledPricing = styled.div`
  color: #e84d0e;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 0.5rem;
  width: 100%;
  margin-bottom: 1.25rem;

  span {
    margin-right: 8px;
  }

  label {
    display: block;
    font-size: 1rem;
    margin-bottom: 0;
    color: ${({ theme }) => theme.textColor.muted};
    text-align: right;
  }

  ${CurrencyWrapper} {
    justify-content: flex-end;
  }
`;

export const StyledCard = styled(Card.Wrapper)`
  font-size: 1rem;

  ${Row} {
    margin-top: ${({ theme }) => theme.padding.md};
  }

  ${Card.Content} .ais-Highlight-highlighted {
    color: ${({ theme }) => contrastColor(theme.color.secondary_main)};
    font-weight: bold;
    font-style: normal;
    background: ${({ theme }) => theme.color.secondary_main};
    padding: 0px 2px;
  }

  ${Card.Actions} {
    a {
      flex: unset;
      margin-bottom: 1.25rem;
    }
  }

  & .booking-button {
    border-width: 2px;
    padding: 8px 24px;
    letter-spacing: 0.5px;
    font-size: 1rem;
    line-height: 1;
    min-width: 150px;
  }
`;

export const StyledCardVertical = styled(StyledCard)`
  border: 1px solid ${({ theme }) => theme.color.grey_200};

  ${Card.Content} {
    padding: 1rem 1rem 0.5rem;

    ${List} {
      margin: 0;
    }
  }

  ${Card.Actions} {
    padding: 0.5rem 1rem 1rem;
    flex-direction: column;
    align-items: flex-end;

    ${StyledPricing} {
      width: 50%;
      align-items: flex-start;
    }

    ${ActionsWrapper} {
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;

      > a {
        width: 55%;
        margin-left: 0.9rem;
        margin-bottom: 0;
      }

      ${MoreInfoLink} {
        a {
          margin-bottom: 0;
        }

        span {
          white-space: nowrap;
          @media ${DEVICE_MD_UP} {
            white-space: normal;
          }
        }
      }
    }
  }
`;

export const StyledCardHorizontal = styled(StyledCard)`
  padding: ${({ theme }) => theme.padding.md} 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_200};

  ${Card.ActionArea} {
    & > a {
      flex-direction: row;
    }

    ${Card.Content} {
      padding-left: 2.5rem;
    }

    @media ${DEVICE_MD_DOWN} {
      ${Card.Content} {
        padding: 0.75rem 0.75rem 0.75rem 1.5rem;
      }
      ${H2} {
        font-size: 1rem;
        margin: 0;
      }
      ${P} {
        font-size: 0.875rem;
        margin: 0;
      }
      ${List} {
        margin: 0;
      }
      ${ListItem} {
        margin: 0;

        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }

  ${Card.Media} {
    flex-shrink: 0;
    width: 130px;
    height: 130px;

    @media ${DEVICE_MD_UP} {
      width: 200px;
      height: 200px;
    }
  }

  ${Card.Actions} {
    display: none;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    ${MoreInfoLink} {
      align-self: center;
    }

    @media ${DEVICE_MD_UP} {
      display: flex;
    }
  }

  & .booking-button {
    display: none;
    @media ${DEVICE_MD_UP} {
      display: block;
    }
  }
`;

export const HighlightLabel = styled.span`
  font-size: 1rem;
  font-weight: 700;
  background-color: ${({ theme }) => theme.color.tertiary};
  position: absolute;
  padding: 10px;
  min-width: 50%;
  text-align: center;
`;
