import "./design-system-legacy.module.scss";

/* eslint-disable-next-line */
export interface DesignSystemLegacyProps {}

export function DesignSystemLegacy(props: DesignSystemLegacyProps) {
  return (
    <div>
      <h1>Welcome to DesignSystemLegacy!</h1>
    </div>
  );
}

export default DesignSystemLegacy;
