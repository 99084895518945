import React from 'react'

const CarouselPlaceholder = () => (
  <div className='carousel-inner' role='listbox'>
    <div className='item carousel-item active'>
      <img src='//placehold.it/800x800' className='img-fluid' alt='' />
    </div>
  </div>
)

export default CarouselPlaceholder
