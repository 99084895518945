import { useState } from "react";
import styled from "styled-components";

// Types
import { SectionMosaic, SectionMosaicDisplayStyle } from "@libema/content-sdk";

// Components
import Image, { ImageLoaderProps } from "next/image";
import {
  Mosaic,
  H2,
  PlayButton,
  Section,
  DEVICE_SM_UP,
  Photoviewer,
  DEVICE_MD_UP,
} from "@libema/design-system";
import RichTextRenderer from "../renderer/RichTextRenderer";

const StyledSection = styled(Section.Wrapper)`
  padding: 3rem 0 0 0;

  @media ${DEVICE_SM_UP} {
    padding: 3rem 0;
  }

  ${Section.Container} {
    padding: 0;
  }

  ${Mosaic.ImageOnly} ${Mosaic.Content},
  ${Mosaic.TextOnly} ${Mosaic.Content} {
    @media ${DEVICE_MD_UP} {
      padding-top: 3.7rem;
    }
  }
`;

const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?w=${width}&h=${Math.ceil(width * 0.75)}&q=${
    quality || 90
  }&fit=fill&f=faces&fm=jpg`;
};

type Props = SectionMosaic & {
  className?: string;
};

const MosaicSection = ({
  mosaicTitle,
  splitDescription,
  textDescription,
  visualDescription,
  splitImage,
  visualImages,
  mosaicDisplayStyle,
  backgroundColor,
  className,
}: Props) => {
  const galleryImages = visualImages?.reduce((images, image) => {
    return [
      ...images,
      {
        src: image.url,
        alt: image.title,
        youtubeId: image.youtubeId,
      },
    ];
  }, []);

  const [viewerOpen, setViewerOpen] = useState(null);

  const openViewer = (index) => setViewerOpen(index);
  const closeViewer = () => setViewerOpen(null);

  return (
    <StyledSection className={className} backgroundColor={backgroundColor}>
      <Section.Container>
        {mosaicTitle && (
          <Section.Header>
            <H2>{mosaicTitle}</H2>
          </Section.Header>
        )}
        <Mosaic.Wrapper
          mirror={mosaicDisplayStyle === SectionMosaicDisplayStyle.MIRROR}
        >
          <Mosaic.ImageOnly onClick={openViewer}>
            {visualImages?.length && (
              <Image
                src={visualImages[0].url}
                alt={visualImages[0].title}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                loader={imageLoader}
              />
            )}
            <Mosaic.Content>
              <RichTextRenderer text={visualDescription} />
            </Mosaic.Content>
            {visualImages?.length && visualImages[0].youtubeId && (
              <PlayButton aria-label="play" />
            )}
          </Mosaic.ImageOnly>
          <Mosaic.TextOnly>
            <Mosaic.Content>
              <RichTextRenderer text={textDescription} />
            </Mosaic.Content>
          </Mosaic.TextOnly>
          <Mosaic.TextImage>
            <Mosaic.ImageWrapper>
              {splitImage && (
                <Image
                  src={splitImage.url}
                  alt={splitImage.title}
                  layout="fill"
                  loader={imageLoader}
                />
              )}
            </Mosaic.ImageWrapper>
            <Mosaic.Content>
              <RichTextRenderer text={splitDescription} />
            </Mosaic.Content>
          </Mosaic.TextImage>
        </Mosaic.Wrapper>
        {viewerOpen !== null && galleryImages && (
          <Photoviewer.Container images={galleryImages} onClose={closeViewer}>
            <Photoviewer.Header>
              <Photoviewer.ButtonClose />
              <Photoviewer.Index />
            </Photoviewer.Header>
            <Photoviewer.ButtonPrevious />
            <Photoviewer.Photo>
              <Image
                layout="fill"
                objectFit="contain"
                objectPosition="center"
                src=""
              />
            </Photoviewer.Photo>
            <Photoviewer.ButtonNext />
          </Photoviewer.Container>
        )}
      </Section.Container>
    </StyledSection>
  );
};

export default MosaicSection;
