import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { getBlocks } from '../../actions/contentActions'
import { getIdOrSlugFromPath } from '../../modules/content/utilities'
import { DISPLAY_DATE_WITH_DAY } from '../../constants/dates'

class NewsDetailsBlock extends Component {
  componentDidMount = () => {
    this.props.loadBlocks(this.props.contentId)
  }

  render = () => {
    // eslint-disable-next-line camelcase
    const { content, created_at } = this.props
    // eslint-disable-next-line camelcase
    const date = content && content.published_at ? content.published_at : created_at

    return (
      <div className='news-details'>
        {content && (
          <p>
            <span className='news-date'>{moment(date).format(DISPLAY_DATE_WITH_DAY)}</span>
            {content && content.attributes.author && (
              <span>{`door ${content.attributes.author}`}</span>
            )}
          </p>
        )}
      </div>
    )
  }
}

NewsDetailsBlock.propTypes = {
  created_at: PropTypes.string,
  loadBlocks: PropTypes.func.isRequired,
  content: PropTypes.object,
  contentId: PropTypes.number
}

const mapState = (state, ownProps) => {
  // The passed identifier can be either the ID or the Slug (string).
  let contentId = Object.keys(state.content).find(i => i === getIdOrSlugFromPath(ownProps.contentId) || state.content[i].slug === getIdOrSlugFromPath(ownProps.contentId))
  if (!contentId && !ownProps.isFetching) {
    contentId = Object.keys(state.content).find(i => state.content[i].slug === '404')
  }

  const content = state.content[contentId]

  return {
    content,
    entities: state.entities.blocks,
    children: (content) ? content.blocks.map(id => state.entities.blocks[id]).filter(b => b.parentId === undefined) : [],
    isFetching: (content) ? content.isFetching : true
    // options: Object.keys(state.entities.options)
    //   .filter(o => state.entities.options[o].attribute.name === ownProps.properties.attribute.split('.')[1])
    //   .map(o => state.entities.options[o]),
  }
}

const mapDispatch = dispatch => ({
  loadBlocks: id => dispatch(getBlocks(id))
})

export default connect(
  mapState,
  mapDispatch
)(NewsDetailsBlock)
