const RightArrow = (props) => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 0L0.589996 1.41L5.17 6L0.589996 10.59L2 12L8 6L2 0Z"
      fill="#BFBFBF"
    />
  </svg>
);

export default RightArrow;
