import styled from "styled-components/macro";
import Input from "./Input";

export default styled.div`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.margin.md};

  ${Input} {
    position: relative;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
    border-right: none;
  }
`;
