import * as actions from './actions'

const initialState = {
  errors: null,
  isFetching: false,
  facebook: null,
  linkedin: null
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_FACEBOOK_DATA: {
      if (action.userData.status === 'unknown') {
        return {
          ...state,
          isFetching: false
        }
      }
      return {
        ...state,
        facebook: action.userData,
        isFetching: false
      }
    }
    case actions.SET_LINKEDIN_DATA: {
      return {
        ...state,
        linkedin: action.userData,
        isFetching: false
      }
    }
    case actions.FETCH_SOCIAL_DATA: {
      return {
        ...state,
        isFetching: true
      }
    }
    default: {
      return state
    }
  }
}

export default user
