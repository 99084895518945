import ReactSelect, {
  Props as ReactSelectProps,
  StylesConfig,
} from "react-select";
import ReactAsyncSelect, {
  Props as ReactAsyncSelectProps,
} from "react-select/async";

import { getTheme } from "../../styles/theme";
import { inputHeight } from "../../utils/inputUtils";

const theme = getTheme();

export type SelectOption = {
  value?: string | number;
  label: string;
};

type SelectProps = ReactSelectProps<SelectOption> & {
  error?: boolean;
  touched?: boolean;
  backgroundColor?: string;
  value: any;
  field: any;
  onChange?: any;
  onBlur?: any;
  styles?: any;
  size?: string;
};

type AsyncSelectProps = ReactAsyncSelectProps<SelectOption> & {
  error?: boolean;
  touched?: boolean;
  backgroundColor?: string;
  styles?: any;
  size?: string;
};

const styles = (props: SelectProps | AsyncSelectProps): StylesConfig => ({
  container: (style) => ({
    ...style,
    width: "100%",
  }),
  control: (style, _state) => ({
    ...style,
    // border: touched && error ? `2px solid ${theme.borderColor.error}` : 'none',
    border: `1px solid ${theme.color.dark}`,
    borderRadius: "0",
    height: inputHeight(props.size),
    minHeight: inputHeight(props.size),
  }),
  indicatorSeparator: (style) => ({
    ...style,
    backgroundColor: "transparent",
  }),
});

export const AsyncSelect: React.FC<AsyncSelectProps> = (props) => (
  <ReactAsyncSelect {...props} styles={props.styles || styles(props)} />
);

export const Select: React.FC<SelectProps> = (props) => (
  <ReactSelect
    noOptionsMessage={() => "No options"}
    {...props}
    styles={props.styles || styles(props)}
    theme={(selectTheme) => ({
      ...selectTheme,
      colors: {
        ...selectTheme.colors,
        primary: theme.color.neutralDark,
        primary50: theme.color.neutralMedium,
        primary25: theme.color.neutralLight,
        neutral50: theme.color.neutralMedium,
      },
    })}
  />
);

export default Select;
