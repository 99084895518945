import trans from "counterpart";
import moment from "moment";
import { withRouter } from "next/router";
import PropTypes from "prop-types";
import qs from "query-string";
import { useState } from "react";
import Modal from "../../../../components/modals/Modal";
import ModalBody from "../../../../components/modals/ModalBody";
import ModalFooter from "../../../../components/modals/ModalFooter";
import Link from "../../../../components/typography/Link";
import { INTERNAL_DATE } from "../../../../constants/dates";
import { isBookableOnArrivalDate } from "../../utilities";

const BookButton = ({
  arrivalDate,
  className,
  disabled,
  router,
  to,
  toFallback,
  panic,
  accommodationKindId,
}) => {
  const [modal, setModal] = useState(false);

  const toggleModal = (e) => {
    e.preventDefault();
    const now = moment();

    if (!isBookableOnArrivalDate(now, arrivalDate, accommodationKindId )) {
      return setModal(true);
    }

    return router.push(to);
  };

  return (
    <>
      {!panic && (
        <>
          {disabled && (
            <button
              type="button"
              className={`btn btn-booking ${className}`}
              disabled={disabled}
            >
              <span>{trans("newyse.accommodation.book")}</span>{" "}
              <i className="material-icons">arrow_forward</i>
            </button>
          )}
          {!disabled && (
            <>
              <a
                href={to}
                className={`btn btn-booking ${className}`}
                onClick={toggleModal}
              >
                <span>{trans("newyse.accommodation.book")}</span>{" "}
                <i className="material-icons">arrow_forward</i>
              </a>
              <Modal
                isOpen={modal}
                toggle={() => {
                  setModal(false);
                }}
              >
                <ModalBody>
                  {trans("newyse.accommodation.booking_offline_warning")}
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    className="btn btn-green btn-block"
                    onClick={() => {
                      setModal(false);
                    }}
                  >
                    {trans("button.close")}
                  </button>
                </ModalFooter>
              </Modal>
            </>
          )}
        </>
      )}
      {panic && (
        <Link to={toFallback} className={`btn btn-green ${className}`}>
          {trans("newyse.accommodation.book")}
        </Link>
      )}
    </>
  );
};

BookButton.propTypes = {
  arrivalDate: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  router: PropTypes.object.isRequired,
  panic: PropTypes.bool,
  to: PropTypes.string.isRequired,
  toFallback: PropTypes.string.isRequired,
};

export default withRouter(BookButton);
