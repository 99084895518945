import React, { Component } from 'react'
import Nav from 'reactstrap/lib/Nav'
import NavItem from 'reactstrap/lib/NavItem'
import NavLink from 'reactstrap/lib/NavLink'
import NavbarToggler from 'reactstrap/lib/NavbarToggler'
import Collapse from 'reactstrap/lib/Collapse'
import trans from 'counterpart'

class NavigationBlock extends Component {
  state = { isOpen: false }

  handleClick = () => {
    document.getElementsByTagName('html')[0].className = ''
    document.body.className = ''
    this.setState({
      isOpen: false
    })
  }

  toggle = () => {
    if (!this.state.isOpen) {
      document.getElementsByTagName('html')[0].className = 'responsive-wrapper'
      document.body.className = 'responsive-wrapper'
    } else {
      document.getElementsByTagName('html')[0].className = ''
      document.body.className = ''
    }
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }))
  }

  render = () => (
    <nav className='navbar navbar-toggleable-sm' {...this.props.manageTags}>
      <div className='container px-0'>
        <NavbarToggler onClick={this.toggle} className='hidden-md-down'>
          <i className='material-icons'>&#xE5D2;</i>
        </NavbarToggler>
        <Collapse isOpen={this.state.isOpen} navbar className='mr-auto'>
          <Nav navbar>
            <NavItem>
              <NavLink href='/veelgestelde-vragen' target='_blank'>{trans('nav.faq')}</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <span className='navbar-text'>
          <span>
            <strong>{trans('nav.help')}</strong>
            &nbsp;
            <br className='hidden-md-up' />
            {trans('nav.opening_hours')}
          </span>
        </span>
      </div>
    </nav>
  );
}

export default NavigationBlock
