import { ApolloQueryResult } from "@apollo/client";
import moment from "moment";
import TYPES from "../../../../../constants/types";
import {
  ActivityEvent,
  ActivityEventNormalized,
  Schedule,
} from "../../../outputTypes/ActivityEvent";
import SectionEvents from "../../../outputTypes/SectionEvents";
import { fetchWithGraphQL } from "../../api";

// GraphQl
import fetchEvents from "../../queries/fetchEvents";

const buildActivityEvent = (
  activityEvent: ActivityEvent,
  schedule: Schedule
): ActivityEventNormalized => {
  return {
    id: `${activityEvent.sys.id}-${schedule.sys.id}`,
    type: activityEvent.type,
    title: activityEvent.title || null,
    description: activityEvent.description || null,
    park: activityEvent.park || null,
    location: activityEvent.location || null,
    ageGroup: activityEvent.ageGroup || null,
    openTime: schedule.openTime,
    closeTime: schedule.closeTime,
    exceptions: schedule.exceptions || null,
    startDate: schedule.startDate || null,
    endDate: schedule.endDate || null,
  };
};

const normalizeEvents = async (section) => {
  const eventSchedule = section.topic?.activityEventSchedule;

  const normalizedSection: SectionEvents = {
    type: TYPES.SectionEvents,
    id: "",
    weekdays: [],
    specialDates: {},
    eventByDayOfWeek: {},
    text: section.text,
    title: eventSchedule?.title || "",
  };

  if (!eventSchedule) return normalizedSection;

  const { items: initialActivityEvents, total } =
    eventSchedule.activityEventCollection;

  let activityEvents = initialActivityEvents;

  if (activityEvents?.length < total) {
    try {
      const times = Math.ceil(total / activityEvents?.length);
      const remainingEventsProvider: Array<Promise<ApolloQueryResult<any>>> =
        [];
      for (let i = 1; i < times; i++) {
        remainingEventsProvider.push(
          fetchWithGraphQL({
            query: fetchEvents,
            variables: {
              id: eventSchedule.sys.id,
              skip: activityEvents?.length * i,
            },
            preview: false,
          })
        );
      }
      const remainingEvents = await Promise.all(remainingEventsProvider);
      const activityChunks = remainingEvents.map(
        (chunk) =>
          chunk.data.activityEventSchedule.activityEventCollection.items
      );
      activityEvents = [...activityEvents, ...[].concat(...activityChunks)];
    } catch (error) {
      console.error("Error fetching remaining Activity Events", error);
      throw new Error(`Remaining Activity Events not properly fetched.`);
    }
  }

  // Loop all activities, then loop all schedules
  activityEvents.forEach((activityEvent) => {
    // Loop recurrent events
    activityEvent.recurrentScheduleCollection.items.forEach((schedule) => {
      const { weekday } = schedule;

      // Weekdays array is needed to later check with weekdays have events
      if (!normalizedSection.weekdays.includes(weekday))
        normalizedSection.weekdays.push(weekday);

      // Recurrent events array organized by weekdays, to easier map them later
      const dayOfWeek = weekday.toLowerCase();
      if (!normalizedSection.eventByDayOfWeek[dayOfWeek])
        normalizedSection.eventByDayOfWeek[dayOfWeek] = [];
      normalizedSection.eventByDayOfWeek[weekday.toLowerCase()].push(
        buildActivityEvent(activityEvent, schedule)
      );
    });
    // Loop special date events
    activityEvent.specialScheduleCollection.items.forEach((specialSchedule) => {
      const { date } = specialSchedule;
      const dayOfYear = moment(date).dayOfYear();

      // Special date events array organized by `dayOfYear`, so it's easier to access them later
      if (!normalizedSection.specialDates[dayOfYear])
        normalizedSection.specialDates[dayOfYear] = [];
      normalizedSection.specialDates[moment(date).dayOfYear()].push(
        buildActivityEvent(activityEvent, specialSchedule)
      );
    });
  });

  return normalizedSection;
};

export default normalizeEvents;
