import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import classnames from 'classnames'
import HelpText from '../../../components/HelpText'
import { groupValidateClasses, inputValidateClasses } from '../utilities'
import { getAddress } from '../../reservation/actions'

class SelectField extends React.Component {
  // Define the context types to make sure the component receives them
  componentDidMount = () => {
    const { input, triggerInitialChange } = this.props
    // Since we cannot rely on the form's initialValues due to the dynamic nature of our block structure
    // we manually trigger a change on the initial load of this select field.
    if (this.getOptions().length > 0 && input && input.value === '') {
      triggerInitialChange(this.props.meta.form, input.name, this.getOptions()[0].key)
    }
  }

  /**
   * Transform the options to an array with objects that have both a key and a value property
   */
  getOptions = () => {
    const { options } = this.props

    if (Array.isArray(options) && options.length && options[0] && options[0].key) {
      return options
    }

    return Object.keys(options).map(key => ({
      key,
      value: options[key]
    }))
  }

  render = () => {
    const { input, label, name, placeholder, required, helpText, meta: { touched, error } } = this.props

    const className = classnames(label ? 'col-lg-8' : 'col-lg-12', {
      'has-help-text': !!helpText
    })

    return (
      <div className={groupValidateClasses(touched, error)}>
        {label && (
          <label className={`control-label col-lg-4${required ? ' required' : ''}`}>{label}</label>
        )}
        <div className={className}>
          <select
            id={name}
            name={name}
            {...input}
            className={`object-select ${inputValidateClasses(touched, error)}`}
            onChange={(event) => {
              input.onChange(event)
              if (input.name === 'country') {
                this.props.getAddress({ country: event.target.value })
              }
            }}
          >
            {(placeholder && (
              <option value=''>{placeholder}</option>
            ))}
            {this.getOptions().map(opt => (
              <option value={opt.key} key={opt.key}>{opt.value}</option>
            ))}
          </select>
          {touched && error && <div className='form-control-feedback'>{error}</div>}
          <HelpText value={helpText} />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  triggerInitialChange: (form, field, value) => dispatch(change(form, field, value)),
  getAddress: data => dispatch(getAddress(data))
})

SelectField.propTypes = {
  _reduxForm: PropTypes.object,
  helpText: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  name: PropTypes.string,
  // Options can be passed as an key,value object,
  // or passed as an array with objects that have a key and a value property
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sortOptions: PropTypes.func,
  triggerInitialChange: PropTypes.func,
  getAddress: PropTypes.func
}

export default connect(
  null,
  mapDispatchToProps
)(SelectField)
