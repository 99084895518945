// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from "styled-components";
import { darken, lighten, desaturate } from "polished";
import { BaseTheme } from "../../styles/theme";
import { contrastColor } from "../../utils/colorUtils";

type Variant = "contained" | "outlined" | "text";
type Size = "small" | "medium" | "large";
type ColorType = keyof BaseTheme["color"];

export type Props = {
  variant?: Variant;
  colorType?: ColorType;
  size?: Size;
  borderRadius?: string;
  borderWidth?: string;
  disabled?: boolean;
  className?: string;
  onClick?: (e: any) => void;
};

const fontSize = {
  small: "1rem",
  medium: "1.2rem",
  large: "1.25rem",
};

type PaddingProps = { theme: BaseTheme; size: "small" | "medium" | "large" };

const padding =
  () =>
  ({ theme, size }: PaddingProps) => {
    const sizes = {
      small: `0.5rem 1.4rem`,
      medium: `1rem 2rem`,
      large: `1.2rem 4rem`,
    };
    return sizes[size];
  };

type ColorTypes =
  | "textColor"
  | "backgroundColor"
  | "border"
  | "hoverText"
  | "hoverBackground"
  | "disabledBackground";

type ColorProps = {
  theme: BaseTheme;
  colorType: keyof BaseTheme["color"];
  borderWidth: string;
  variant: string;
};

const color =
  (type: ColorTypes) =>
  ({ theme, colorType, borderWidth, variant }: ColorProps): string => {
    const themes = {
      contained: {
        textColor:
          theme.button.textColor?.[colorType] ??
          contrastColor(theme.color[colorType]),
        backgroundColor: theme.color[colorType],
        border: `${borderWidth} solid ${theme.color[colorType]}`,
        hoverText: contrastColor(darken(0.1, theme.color[colorType])),
        hoverBackground: darken(0.1, theme.color[colorType]),
        disabledBackground: darken(0.1, theme.color[colorType]),
      },
      outlined: {
        textColor: theme.color[colorType],
        border: `${borderWidth} solid ${theme.color[colorType]}`,
        hoverText: contrastColor(darken(0.1, theme.color[colorType])),
        hoverBackground: theme.color[colorType],
        disabledBorder: `${borderWidth} solid ${desaturate(
          0.3,
          lighten(0.3, theme.color[colorType])
        )}`,
        disabledTextColor: desaturate(
          0.3,
          lighten(0.3, theme.color[colorType])
        ),
        disabledHoverBackground: "transparent",
      },
      text: {
        textColor: theme.color[colorType],
        backgroundColor: "transparent",
        border: "none",
        hoverText: contrastColor(darken(0.1, theme.color[colorType])),
        hoverBackground: darken(0.1, theme.color[colorType]),
        disabledBackground: "white",
      },
    };

    return themes[variant][type];
  };

const Button = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  border-radius: ${({ theme }) => theme.button.radius};
  font-size: ${({ size }) => fontSize[size]};
  font-family: ${({ theme }) => theme.font.default};
  text-decoration: none;
  padding: ${padding()};
  text-align: center;
  column-gap: 8px;
  color: ${(props) => color("textColor")(props)};
  background-color: ${(props) => color("backgroundColor")(props)};
  border: ${(props) => color("border")(props)};
  transition: all 300ms;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  cursor: pointer;

  & svg {
    stroke: ${(props) => color("textColor")(props)};
  }

  &:visited {
    color: ${(props) => color("textColor")(props)};
  }

  &:hover {
    color: #fff;
    text-decoration: none;
    color: ${(props) => color("hoverText")(props)};
    background-color: ${(props) => color("hoverBackground")(props)};
    border-color: ${(props) => color("hoverBackground")(props)};

    & svg {
      stroke: ${(props) => color("hoverText")(props)};
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: ${(props) => color("disabledTextColor")(props)};
    border: ${(props) => color("disabledBorder")(props)};
  }

  &:disabled:hover {
    background-color: ${(props) => color("disabledHoverBackground")(props)};
  }

  &:focus {
    border: ${({ borderWidth, theme }) =>
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${borderWidth} solid ${theme.color.focus}`};
  }
`;

Button.defaultProps = {
  variant: "contained",
  colorType: "primary",
  borderRadius: "0",
  borderWidth: "2px",
  size: "medium",
};

export default Button;
