import BaseSection from "./BaseSection";
import TYPES from "../../../constants/types";
import RichText from "./RichText";

export enum SectionLeadDisplayStyle {
  DEFAULT = "DEFAULT",
  HORIZONTAL = "HORIZONTAL",
  HORIZONTAL_MIRROR = "HORIZONTAL_MIRROR",
}

type SectionLead = BaseSection<TYPES.SectionLead> & {
  title?: string;
  heading: string;
  displayStyle: SectionLeadDisplayStyle;
  headingElement: string;
  description?: RichText;
};

export default SectionLead;
