import styled from "styled-components";
import { ReactComponent as CheckIcon } from "@fortawesome/fontawesome-free/svgs/solid/check.svg";

export default styled(CheckIcon)`
  fill: ${({ theme }) => theme.color.secondary};
  position: relative;
  width: 1em;
  height: 1em;
  top: 0.125em;
`;
