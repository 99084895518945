import axios from 'axios'

export const newCancellationToken = () => axios.CancelToken.source()
export const isCancellationError = err => axios.isCancel(err)

const getHeaders = config => ({
  'Content-Type': 'application/json',
  ...config.headers
})

export const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_BASE_URL}/api`,
  timeout: process.env.NODE_ENV === 'production' ? 20000 : 50000
})

export const get = config => api({
  method: 'GET',
  // withCredentials: true,
  ...config,
  headers: getHeaders(config)
})

export const put = config => api({
  method: 'PUT',
  ...config,
  headers: getHeaders(config)
})

export const post = config => api({
  method: 'POST',
  ...config,
  headers: getHeaders(config)
})
