import React from 'react'
import PropTypes from 'prop-types'

const DatePickerNav = ({ value }) => (
  <button
    type='button'
    className='btn-navigation'
  >
    <li className='material-icons'>{value}</li>
  </button>
)

DatePickerNav.propTypes = {
  value: PropTypes.string
}

export default DatePickerNav
