import { gql } from "@apollo/client";

// Data
import article from "../data/article";
import link from "../data/link";
import asset from "../data/asset";

const query = gql`
  fragment ArticleOverview on SectionArticleOverview {
    type: __typename
    sys {
      id
    }
    title
    backgroundColor
    pageSize
    articles: articlesCollection(limit: $limit, skip: $skip) {
      total
      items {
        ...Article
      }
    }
  }

  # Data
  ${article}
  ${link}
  ${asset}
`;

export default query;
