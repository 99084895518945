import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types";
import {
  P,
  H3,
  H2,
  H1,
  UnorderedList,
  OrderedList,
  ListItem,
} from "@libema/design-system";
import { TYPES, RichText, LinkDisplayStyle } from "@libema/content-sdk";
import Gallery from "../blocks/Gallery";
import Link from "../elements/Link";
import { useRouter } from "next/router";

const RTELink = styled.div`
  display: inline-block;

  & a {
    display: inline-flex;
  }
`;

const DangerouslySetHtmlContent = ({ html, ...rest }) => {
  const divRef = useRef(null);

  useEffect(() => {
    if (!html || !divRef.current) return;

    const slotHtml = document.createRange().createContextualFragment(html);
    (divRef.current as any).innerHTML = "";
    (divRef.current as any).appendChild(slotHtml);
  }, [html]);

  return <span {...rest} ref={divRef}></span>;
};

const renderOptions = (links, locale) => {
  const assetMap = new Map();
  const entryMap = new Map();

  if (links) {
    links.assets.hyperlink?.forEach((asset) => {
      assetMap.set(asset.sys.id, asset);
    });

    links.assets.block?.forEach((asset) => {
      assetMap.set(asset.id, asset);
    });

    links.entries.block?.forEach((entry) => {
      entryMap.set(entry.id, entry);
    });

    links.entries.inline?.forEach((entry) => {
      entryMap.set(entry.id, entry);
    });

    links.entries.hyperlink?.forEach((entry) => {
      entryMap.set(entry.id, entry);
    });
  }

  return {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
      [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
      [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
      [BLOCKS.PARAGRAPH]: (node, children) => <P>{children}</P>,
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedList>{children}</OrderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        if (!assetMap) {
          return;
        }

        const asset = assetMap.get(node.data?.target.sys?.id);

        return <Gallery images={[asset]} />;
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        if (!entryMap) {
          return;
        }

        const entry = entryMap.get(node.data.target.sys.id);

        if (entry?.type === TYPES.Link) {
          return (
            <Link href={entry.url} locale={locale}>
              {children}
            </Link>
          );
        }
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        if (!entryMap) {
          return;
        }

        const entry = entryMap.get(node.data.target.sys.id);

        switch (entry?.type) {
          case TYPES.Link:
            return (
              <RTELink>
                <Link
                  href={entry.url}
                  as={entry.displayStyle}
                  buttonProps={{
                    size: "small",
                  }}
                  locale={locale}
                >
                  {entry.label}
                </Link>
              </RTELink>
            );
          case TYPES.InlineScriptBlock:
            return <DangerouslySetHtmlContent html={entry.script} />;
          default: {
            return null;
          }
        }
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        if (!entryMap) {
          return;
        }

        const asset = assetMap.get(node.data.target.sys.id);

        if (!asset) {
          return null;
        }

        return (
          <Link
            as={LinkDisplayStyle.BUTTON}
            href={asset.url}
            locale={locale}
            target="_blank"
          >
            {children}
          </Link>
        );
      },
    },
  };
};

export type RichTextRendererProps = {
  text?: RichText;
  // This property is used for rich text which is fetched from the Contentful REST API
  textDocument?: Document;
};

const RichTextRenderer: React.FC<RichTextRendererProps> = ({
  text,
  textDocument,
}): React.ReactElement | null => {
  const { locale = "nl" } = useRouter();
  if (text?.json) {
    return (
      <>
        {documentToReactComponents(
          text.json,
          renderOptions(text.links, locale)
        )}
      </>
    );
  }

  if (textDocument) {
    return <>{documentToReactComponents(textDocument)}</>;
  }

  return null;
};

export default RichTextRenderer;
