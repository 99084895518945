import { postPreferences } from '../../utilities/api/preferencesApi'

export const persistPreferences = (store) => (next) => (action) => {
  const keysToUpdate = ['subjects', 'arrival', 'voucher']
  const hasWindow = typeof window !== 'undefined'
  const currentState = store.getState().preferences

  const result = next(action)
  const nextState = store.getState().preferences
  // Previously, two objects were directly compared to each other
  // This will always result in a true case, wherein the application believes the state has changed while it actually hasnt
  // Therefore, we first stringify the content and compare the strings.
  // @TODO: Figure out why the redux is not immutable or why the object is getting updated and remove JSON.stringify
  const hasUpdatedFields = keysToUpdate.some((key) => JSON.stringify(nextState[key]) !== JSON.stringify(currentState[key]))

  if (hasWindow && hasUpdatedFields) {
    const newPreferences = {}

    keysToUpdate.forEach((key) => {
      if (JSON.stringify(nextState[key]) !== JSON.stringify(currentState[key])) {
        newPreferences[key] = nextState[key]
      }
    })
    postPreferences(newPreferences)
  }

  return result
}
