import { createSelector } from 'reselect'

const getDates = id => state => (state.calendar[id] ? state.calendar[id].dates : {})

export const getEventsByIdAndDate = (id, selectedDate) => createSelector(
  getDates(id),
  dates => dates[selectedDate]
    ? dates[selectedDate].events
    : {}
)

export const groupEvents = (id, selectedDate) => createSelector(
  getEventsByIdAndDate(id, selectedDate),
  events => Object.values(events).reduce((accumulator, event) => {
    if (!event.type) return accumulator
    if (event.type === 'legenda') return accumulator
    if (!accumulator[event.type]) {
      accumulator[event.type] = [event]
    } else {
      accumulator[event.type].push(event)
    }
    return accumulator
  }, {})
)
