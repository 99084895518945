import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import trans from 'counterpart'
import Modal from '../../../components/modals/Modal'
import ModalBody from '../../../components/modals/ModalBody'
import TravelCompanyForm from './TravelCompanyForm'
import { subjectsInReservationSelector } from '../../reservation/selectors'

class TravelCompanySummary extends Component {
  state = {
    modal: false
  }

  toggle = () => this.setState(prevState => ({
    modal: !prevState.modal
  }));

  render () {
    const { subjects, properties } = this.props
    return (
      <>
        {properties.template === 'receipt' && (
          <div>
            <div className='row mb-0 receipt-company'>
              <div className='col-4'>{trans('label.travel_company')}</div>
              <div className='col-8 col-md-7 offset-md-1'>
                {subjects.map(subject => (
                  <span key={`subject-${subject.id}`} className='text-company text-lowercase'>
                    {`${subject.quantity} x ${subject.singular_name}`}
                  </span>
                ))}
                <span onClick={this.toggle} className='underline'>
                  <strong>
                    {trans('label.edit')}
                  </strong>
                </span>
              </div>
            </div>
          </div>
        )}
        {properties.template !== 'receipt' && (
          <div>
            <h4 className='h5 text-info text-lato mb-1'>{trans('label.travel_company')}</h4>
            <ul className='list-unstyled mb-0'>
              {subjects.map(subject => (
                <li key={`subject-${subject.id}`}>
                  <span>
                    {`${subject.quantity} x ${subject.singular_name}`}
                  </span>
                </li>
              ))}
            </ul>
            <span onClick={this.toggle} className='underline'><strong>{trans('label.edit')}</strong></span>
          </div>
        )}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className='modal-tc booking-modal'>
          <ModalBody>
            <h3 className='text-center text-heading-font mb-2'>{trans('label.travel_company_edit')}</h3>
            <TravelCompanyForm toggle={this.toggle} />
          </ModalBody>
        </Modal>
      </>
    )
  }
}

TravelCompanySummary.propTypes = {
  subjects: PropTypes.array,
  properties: PropTypes.oneOfType([PropTypes.array, PropTypes.object]) // Might be of type array when empty
}

const mapStateToProps = state => ({
  subjects: subjectsInReservationSelector(state)
})

export default connect(
  mapStateToProps
)(TravelCompanySummary)
