import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Image from '../../media/components/Image'
import { PreferenceType } from '../../../constants/newyse'

const PropertyPreference = ({ id, count, custom_description: customDescription, display_name: displayName, image }) => (
  <div className='col-lg-6'>
    <div className={`card bg-faded mb-2${(count > 0) ? '' : ' disabled'}`}>
      <label htmlFor={`preference-${id}`} className='card-block p-0 mb-0' style={{ cursor: 'pointer' }}>
        <div className='row'>
          <div className='col-4 pr-0'>
            {image && (
              <Image
                file={image.reference}
                filter='thumbnail_square'
                attr={{
                  alt: image.name,
                  className: 'img-fluid'
                }}
              />
            )}
            {!image && (
              <img
                src='//placehold.it/200x200'
                className='img-fluid'
                alt='property'
              />
            )}
          </div>
          <div className='col-8 p-1'>
            <h5 className='text-preference-info text-lato pr-3'>{displayName}</h5>
            <p>{customDescription}</p>
          </div>
        </div>
        {count > 0 && (
          <Field
            name={`preference-${PreferenceType.AMENITY}-${id}`}
            id={`preference-${id}`}
            component='input'
            type='checkbox'
            className='hidden-xs-up'
          />
        )}
        <span />
      </label>
    </div>
  </div>
)

PropertyPreference.propTypes = {
  id: PropTypes.number,
  display_name: PropTypes.string,
  image: PropTypes.object,
  custom_description: PropTypes.string,
  count: PropTypes.number
}

export default PropertyPreference
