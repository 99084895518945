import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import trans from 'counterpart'
import { connect } from 'react-redux'
import { groupEvents } from '../selectors'
import { OPENING_HOURS } from '../constants/constants'
import { DISPLAY_DATE_WITH_DAY } from '../../../constants/dates'

class EventsBlock extends Component {
  state = {
    initialized: false
  }

  componentDidMount = () => {
    this.setState({ initialized: true })
  }

  render = () => {
    const { initialized } = this.state
    const { events, id, program, locale, selectedDate } = this.props

    if (!initialized) return null

    return (
      <div className='list-events'>
        {program && (
          <div className='row'>
            <div className='col-md-6 col-lg-12'>
              <h3>{trans(OPENING_HOURS[id])}</h3>
              <div className='opening-hours'>
                <div>{selectedDate.locale(`${locale}`).format(DISPLAY_DATE_WITH_DAY)}</div>
                {program.closed && (
                  <div>{trans('calendar.closed')}</div>
                )}
                {!program.closed && (
                  <>
                    {`${trans('calendar.open_from')} `}
                    <strong>{`${program.opening_time} `}</strong>
                    {`${trans('calendar.open_till')} `}
                    <strong>{program.closing_time}</strong>
                  </>
                )}
              </div>
            </div>
            <div className='col-md-5 offset-md-1 offset-lg-0 col-lg-12'>
              {/* Filter out any groups that do not have open events */}
              {Object.keys(events).filter(event => events[event].find(e => e.open)).map(event => (
                <div key={event}>
                  {id === '1000' && events[event].length > 0 && (
                    <>
                      <h4>{event}</h4>
                      <ul className='mb-0'>
                        {events[event].map(e => e.open && (
                          <li key={e.name}>
                            <a href={e.link}><strong>{e.name}</strong></a>
                            {e.description && (
                              <ul>
                                <li>{e.description}</li>
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <button
          type='button'
          className='btn scroll-top'
          onClick={() => window.scrollTo({ top: 800, left: 0, behaviour: 'smooth' })}
        >
          <i className='material-icons'>
            keyboard_arrow_up
          </i>
        </button>
      </div>
    )
  }
}

EventsBlock.propTypes = {
  events: PropTypes.object,
  id: PropTypes.string,
  locale: PropTypes.string,
  program: PropTypes.object,
  selectedDate: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  events: groupEvents(ownProps.id, ownProps.selectedDate.format('YYYY-MM-DD'))(state),
  program: state.calendar[ownProps.id]
    ? state.calendar[ownProps.id].dates[ownProps.selectedDate.format('YYYY-MM-DD')]
    : null,
  locale: state.preferences.locale
})

export default connect(mapStateToProps)(EventsBlock)
