// eslint-disable-next-line
// @ts-nocheck
import BaseCheckIcon from "@fortawesome/fontawesome-free/svgs/solid/check.svg";
import styled from "styled-components";

const CheckIcon = styled(BaseCheckIcon)`
  fill: ${({ theme }) => theme.color.neutralMedium};
  position: relative;
  width: 1em;
  height: 1em;
`;

export default CheckIcon;
