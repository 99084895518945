import {
  Alert,
  Button,
  Form,
  FormGroup,
  deviceUtils,
} from "@libema/design-system";
import { captureException } from "@sentry/nextjs";
import trans from "counterpart";
import { Field, Formik } from "formik";
import React from "react";
import styled from "styled-components";
import { postSubscriptions } from "../../utilities/api/subscriptionApi";

const SubmitButton = styled(Button)`
  text-transform: ${() =>
    process.env.NEXT_PUBLIC_APP === "beeksebergen" ? "uppercase" : "none"};
  font-size: 1rem;
`;

const SubscribeForm = styled(Form.Form)`
  ${Form.Input} {
    font-size: 1rem;
    padding: ${({ theme }) => theme.padding.sm}
      ${({ theme }) => theme.padding.md};
  }
`;

const SubscribeFormContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.lg};
  ${SubmitButton} {
    width: 100%;
  }

  @media ${deviceUtils.DEVICE_MD_UP} {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    ${FormGroup} {
      flex-grow: 1;
      margin-right: ${({ theme }) => theme.margin.md};
    }
    ${SubmitButton} {
      min-width: 110px;
      width: auto;
    }
  }
`;

const SubscribeBlock = () => {
  const [isSuccessful, setIsSuccessful] = React.useState(false);

  const handleOnSubmit = async (values, formikHelpers) => {
    setIsSuccessful(false);

    try {
      await postSubscriptions(values);
      setIsSuccessful(true);
    } catch (error) {
      captureException(error);
      const hasConflictError = error?.response?.status === 409;
      const message = hasConflictError
        ? trans("alert.newsletter_subscription_conflict")
        : trans("alert.newsletter_subscription_unsuccessful");

      formikHelpers.setSubmitting(false);
      formikHelpers.setFieldError("email", message);
    }
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={handleOnSubmit}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = trans("form.field_mandatory");
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = trans("form.field_email_invalid");
        }

        return errors;
      }}
    >
      {({ isSubmitting, values }) => (
        <SubscribeForm>
          {isSuccessful && (
            <Alert type="success">
              {trans("alert.newsletter_subscription_successful", {
                email: values.email,
              })}
            </Alert>
          )}
          {!isSuccessful && (
            <SubscribeFormContainer>
              <FormGroup>
                <Field
                  component={Form.Formik.TextField}
                  name="email"
                  type="email"
                  placeholder={trans("subscribe.placeholder")}
                />
                <Form.Formik.ErrorMessage name="email" />
              </FormGroup>
              <SubmitButton
                type="submit"
                className="btn-subscribe-submit"
                disabled={isSubmitting}
                size="small"
              >
                {trans("subscribe.sign_up")}
              </SubmitButton>
            </SubscribeFormContainer>
          )}
        </SubscribeForm>
      )}
    </Formik>
  );
};

export default SubscribeBlock;
