import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { formValueSelector, FormName } from 'redux-form'
import classnames from 'classnames'
import HelpText from '../../../components/HelpText'
import { groupValidateClasses, inputValidateClasses } from '../utilities'
import { getAddress } from '../../reservation/actions'
import { isIE, isSafari } from '../../../utilities/common'

const TextField = (props) => {
  const { addressError, input, label, required, helpText, meta: { touched, error } } = props
  let { placeholder, type } = props

  if (input.name === 'company_name' && props.isCompany !== '1') {
    // Hide the company name field is the customer is not booking as a company
    return null
  }

  // Neither Internet Explorer nor Safari support the "date" type property value. Force it to show as text with a placeholder instead.
  if (type === 'date' && !placeholder && (isSafari || isIE)) {
    placeholder = 'yyyy-mm-dd'
    type = 'text'
  }

  const className = classnames(label ? 'col-lg-8' : 'col-lg-12', {
    'has-help-text': !!helpText
  })

  return (
    <FormName>
      {({ form }) => (
        <div className={`${groupValidateClasses(touched, error)} row`}>
          {label && (
            <label className={`control-label col-lg-4${required ? ' required' : ''}`}>{label}</label>
          )}
          <div className={className}>
            <input
              id={input.name}
              type={type || 'text'}
              {...input}
              className={inputValidateClasses(touched, error)}
              placeholder={placeholder}
              onChange={(event) => {
                input.onChange(event)
                if (form === 'customer' && (input.name === 'zipcode' || input.name === 'streetnumber_suffix')) {
                  props.getAddress({ postcode: event.target.value })
                }
              }}
            />
            {addressError && <div className='form-control-feedback'>Adres niet gevonden</div>}
            {touched && error && <div className='form-control-feedback'>{error}</div>}
            <HelpText value={helpText} />
          </div>
        </div>
      )}
    </FormName>
  )
}

TextField.propTypes = {
  addressError: PropTypes.string,
  block: PropTypes.object,
  getAddress: PropTypes.func,
  helpText: PropTypes.string,
  input: PropTypes.object,
  // This defines if the customer selected `yes` on the is_company field
  isCompany: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string
}

const mapStateToProps = (state, ownProps) => ({
  addressError: (ownProps.input.name === 'zipcode' && state.reservation.customer) ? state.reservation.customer.addressError : null,
  isCompany: formValueSelector('customer')(state, 'is_company')
})

const mapDispatchToProps = dispatch => ({
  getAddress: data => dispatch(getAddress(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextField)
