import qs from "query-string";
import moment from "moment";
import trans from "counterpart";
import Router from "next/router";
import { buildParams } from "../modules/preferences/utilities";
import { setFilter } from "./filterActions";
import { INTERNAL_DATE } from "../constants/dates";
import {
  PREFERENCE_KINDS,
  PREFERENCE_SHOW_SPECIALS,
  PREFERENCE_SPECIAL,
  PREFERENCE_SUBJECTS,
  PREFERENCE_VOUCHER,
} from "../constants/preferences";
import {
  ID_DIERENBOS,
  ID_SAFARIRESORT,
  ID_SAFARIRESORT_HOTEL,
} from "../modules/booking/constants";
import { newCancellationToken, isCancellationError } from "../utilities/api";
import { getOffers } from "../utilities/api/offerApi";
import { APP_DIERENBOS } from "../constants/apps";
import { getParams } from "../selectors/preferenceSelectors";
import voucherUtils from "../utilities/voucherUtils";
import { setWithExpiry } from "../utilities/localStorage";

export const CLEAR_REFINE_PREFERENCES = "preference/CLEAR_REFINE_PREFERENCES";
export const MERGE_PREFERENCES = "preference/MERGE_PREFERENCES";
export const SET_ARRIVAL_PREFERENCE = "preference/SET_ARRIVAL_PREFERENCE";
export const SET_PREFERENCE = "preference/SET_PREFERENCE";

export const setPreference = (name, value) => ({
  type: SET_PREFERENCE,
  payload: {
    name,
    value,
  },
});

/**
 * @param {string?} value
 */
export const setVoucher = (value) => {
  const voucher = setWithExpiry("voucher", value);
  if (!voucher) {
    voucherUtils.deleteVoucherCode();
  }
  return setPreference(PREFERENCE_VOUCHER, voucher);
};

let cancellationToken = null;

export const fetchOffers =
  (focus = {}, ignoreFields = []) =>
  (dispatch, getState) => {
    const params = buildParams(getParams(focus)(getState()));

    if (cancellationToken) {
      cancellationToken.cancel(
        "Cancelling offer fetch in favor of new request"
      );
    }

    params.locale = trans("locale");
    if (cancellationToken)
      cancellationToken.cancel(
        "Cancelling offer fetch in favor of new request"
      );

    cancellationToken = newCancellationToken();
    if (process.env.NEXT_PUBLIC_APP === APP_DIERENBOS) {
      params.resorts = [ID_DIERENBOS];
    }

    ignoreFields.forEach((k) => (params[k] = undefined));

    if (params.show_with_open_specials) {
      dispatch(
        setPreference(PREFERENCE_SHOW_SPECIALS, params.show_with_open_specials)
      );
    }

    if (params.special) {
      dispatch(setPreference(PREFERENCE_SPECIAL, params.special));
    } else {
      dispatch(setPreference(PREFERENCE_SPECIAL, null));
    }

    return getOffers(params, cancellationToken.token)
      .then(({ data }) => {
        cancellationToken = null;
        // If a departure date is available, the arrivals & departures are filled in a seperate function.
        if (!params.departure && !data.panic) {
          dispatch(
            setFilter(
              "arrivals",
              (data.arrivals || []).map((date) =>
                moment(date).format(INTERNAL_DATE)
              )
            )
          );
          dispatch(
            setFilter(
              "departures",
              (data.departures || []).map((date) =>
                moment(date).format(INTERNAL_DATE)
              )
            )
          );
        }

        dispatch(
          setFilter(
            "properties",
            Array.isArray(data.properties) ? {} : data.properties || {}
          )
        );
        dispatch(
          setFilter(
            "resorts",
            Array.isArray(data.resorts)
              ? {}
              : {
                  ...data.resorts,
                  // Merge SAFARI_RESORT and SAFARI HOTEL count
                  ...(data.resorts[ID_SAFARIRESORT] && {
                    [ID_SAFARIRESORT]:
                      data.resorts[ID_SAFARIRESORT] +
                      (data.resorts[ID_SAFARIRESORT_HOTEL] || 0),
                  }),
                } || {}
          )
        );
        dispatch(
          setFilter("kinds", Array.isArray(data.kinds) ? {} : data.kinds || {})
        );

        const state = getState();
        if (
          data.results &&
          data.results.length &&
          data.results[0].arrival_date
        ) {
          if (state.preferences.arrival !== data.results[0].arrival_date) {
            // Update the arrival if the arrival date in the result has changed (e.g. because of a re-fetch on the backend)
            dispatch(setPreference("arrival", data.results[0].arrival_date));
          }
          // This is being disabled for now, since it introduced a bug where the first day after the arrival day was not clickable as departure
          // if (params.resource) {
          //   // Set the departure date preference when searching availability for a single item
          //   dispatch(setPreference('departure', data.results[0].departure_date));
          // }
        }

        if ((data.params || {}).kinds) {
          dispatch(
            setPreference(
              PREFERENCE_KINDS,
              data.params.kinds.map((kind) => parseInt(kind, 10))
            )
          );
        }
        // I have disabled this for now, since this overrides the params set by state or GET query
        // Can someone tell me why this is built-in?
        // if ((data.params || {}).subjects) {
        //   dispatch(
        //     setPreference(
        //       PREFERENCE_SUBJECTS,
        //       data.params.subjects.reduce((subjectObject, subject) => {
        //         const parts = subject.split('-')
        //         subjectObject[parts[0]] = parts[1]
        //         return subjectObject
        //       }, {})
        //     )
        //   )
        // }

        return data;
      })
      .catch((err) => {
        if (!isCancellationError(err)) {
          cancellationToken = null;
          throw err;
        }
      });
  };

let arrivalsAndDeparturesCancellationToken = null;

export const fetchArrivalsAndDepartures =
  (focus = {}) =>
  (dispatch, getState) => {
    const params = buildParams(getParams(focus)(getState()));
    if (!params.departure) return null;
    delete params.departure;
    if (process.env.NEXT_PUBLIC_APP === APP_DIERENBOS) params.resorts = [22];
    if (arrivalsAndDeparturesCancellationToken)
      arrivalsAndDeparturesCancellationToken.cancel(
        "Cancelling offer fetch in favor of new request"
      );
    arrivalsAndDeparturesCancellationToken = newCancellationToken();

    return getOffers(params, arrivalsAndDeparturesCancellationToken.token)
      .then(({ data }) => {
        arrivalsAndDeparturesCancellationToken = null;
        dispatch(setFilter("arrivals", data.arrivals));
        dispatch(setFilter("departures", data.departures));
        return data;
      })
      .catch((err) => {
        if (!isCancellationError(err)) {
          arrivalsAndDeparturesCancellationToken = null;
        }
      });
  };

export const clearRefinePreferences = () => ({
  type: CLEAR_REFINE_PREFERENCES,
});

export const setArrivalPreference = (arrival) => ({
  type: SET_ARRIVAL_PREFERENCE,
  payload: arrival,
});

export const mergePreferences = (preferences) => ({
  type: MERGE_PREFERENCES,
  payload: preferences,
});

/**
 * Updates the url with search parameters
 */
export const updateQueryString = (property, value) => (dispatch, getState) => {
  const urlParams = qs.parse(window.location.search);
  const params = {
    ...urlParams,
    ...getState().preferences,
  };

  if (property && value) params[property] = value;

  if (params.subjects) {
    params.subjects = Object.keys(params.subjects)
      .filter((subjectId) => params.subjects[subjectId] > 0)
      .map((subjectId) => `${subjectId}-${params.subjects[subjectId]}`);
  }

  Object.keys(params).forEach((key) => !params[key] && delete params[key]);

  delete params.display;
  delete params.locale;

  const queryString = qs.stringify(params, { arrayFormat: "bracket" });
  const [pathname] = Router.asPath.split("?");

  Router.replace(`${pathname}?${queryString}`, undefined, { shallow: true });
};
