import styled from "styled-components";
import { Col, Row } from "@libema/design-system";
import { LeftColumn, RightColumn } from "./EventSchedule";

export const StyledSeparator = styled(Row)`
  > ${Col} {
    display: flex;
    align-items: center;
    margin-top: -1rem;

    > div:last-of-type {
      height: 2px;
      background-color: ${({ theme }) => theme.color.grey_light_100};
    }
  }
`;

const Separator = styled.div`
  border-radius: 50%;
  box-shadow: 0px 0px 0px 3px #fff,
    0px 0px 0px 5px ${({ theme }) => theme.color.grey_light_100};
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.color.grey_light_100};
  margin-left: -4px;
`;

const ActivityEventSeparator = () => {
  return (
    <StyledSeparator>
      <Col col={12}>
        <LeftColumn />
        <Separator />
        <RightColumn />
      </Col>
    </StyledSeparator>
  );
};

export default ActivityEventSeparator;
