import React, { Component } from 'react'
import { connect } from 'react-redux'
import trans from 'counterpart'
import PropTypes from 'prop-types'
import moment from 'moment'
import { CarouselItem } from 'reactstrap'
import PropertyGroups from './PropertyGroups'
import CompareResourceDetailsPopover from './CompareResourceDetailsPopover'
import Carousel from '../../../components/Carousel'
import Image from '../../media/components/Image'
import { getBookingUrl } from '../../../utilities/preferenceUtilities'
import { hasAdults } from '../../../selectors/preferenceSelectors'
import { getSubjects } from '../../preferences/selectors'
import { APP_BEEKSEBERGEN } from '../../../constants/apps'
import Link from '../../../components/typography/Link'
import BookButton from '../../booking/components/search-and-book/BookButton'
import { ID_SAFARIRESORT, ID_SAFARIRESORT_HOTEL } from '../../booking/constants'

class AccommodationType extends Component {
  state = {
    collapsed: false
  }

  toggle = () => this.setState(prevState => ({
    collapsed: !prevState.collapsed
  }));

  render = () => {
    const { accommodationType, hasAdultSelected, properties, result, remove, subjects } = this.props

    const offer = result || {}

    const isSafariResort = [ID_SAFARIRESORT, ID_SAFARIRESORT_HOTEL].includes(
      accommodationType.resort.id
    );

    return (
      <article key={accommodationType.id} className={`result ${!offer.arrival_date ? 'row-disabled' : ''}`}>
        <div className='result-inner'>
          <div className='result-left'>
            <h4 className='mb-3'>
              <Link to={accommodationType.slug}>
                {accommodationType.display_name || accommodationType.name}
              </Link>
            </h4>
            <span
              role='button'
              tabIndex={0}
              className='material-icons compare-remove'
              onClick={() => remove(accommodationType.id)}
            >
              cancel
            </span>
            <div
              id={`thumb-${accommodationType.id}`}
              className='carousel slide'
              data-interval='false'
              data-ride='carousel'
            >
              <Carousel>
                {accommodationType.images.map(image => (
                  <CarouselItem key={image.id}>
                    <Link to={accommodationType.slug}>
                      <Image
                        file={image.reference}
                        filter='thumbnail_square'
                        attr={{
                          className: 'img-responsive',
                          alt: image.alt
                        }}
                      />
                    </Link>
                  </CarouselItem>
                ))}
              </Carousel>
              {process.env.NEXT_PUBLIC_APP === APP_BEEKSEBERGEN && isSafariResort
                  && (
                <label className='label-srbb label-compare bg-red-light text-white font-weight-bold'>
                  <i className='libema-icons'>&#xe910;</i>
                  {` ${trans('resort.safari_resort')}`}
                </label>
              )}
              {process.env.NEXT_PUBLIC_APP === APP_BEEKSEBERGEN && !isSafariResort
                && (
                <label className='label-vpbb label-compare bg-orange-light text-white font-weight-bold'>
                  <i className='libema-icons'>&#xe911;</i>
                  {` ${trans('resort.lake_resort')}`}
                </label>
              )}
              {result && offer.arrival_date && (
                <div className='price-holder'>
                  <div className='price-tag compact'>
                    <span className='price'>
                      &euro;
                      {` ${offer.all_in_price},-`}
                    </span>
                    {offer.all_in_price < offer.from_price && (
                      <s>
                        &euro;
                        {` ${offer.from_price},-`}
                      </s>
                    )}
                    {offer.special_name && (
                      <p className='text-discount'>{offer.special_name}</p>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className='extra-info bg-beige-light p-3'>
              {(!offer.arrival_date) && (
                <div className='price-tag compact'>
                  <div className='price-tag compact zero-top'>
                    {trans('label.not_available_in_period')}
                  </div>
                </div>
              )}

              {offer.arrival_date && (
                <CompareResourceDetailsPopover accommodationType={accommodationType} />
              )}

              {offer.arrival_date && (
                <div className='pricing pb-0 mb-0'>
                  <div className='date bg-white'>
                    <span>
                      {`${moment(offer.arrival_date).format('LL')},`}
                      <br />
                      {`${offer.nights} ${trans('label.nights')}, ${offer.nights + 1} ${trans('label.days')}`}
                    </span>
                  </div>
                  {hasAdultSelected && (
                    <BookButton
                      arrivalDate={offer.arrival_date}
                      panic={false}
                      to={getBookingUrl(result, subjects)}
                      toFallback={trans('routes.booking_url_fallback')}
                      accommodationKindId={offer.kind_id}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='p-3'>
          <div className='description'>
            <div className={`paragraph paragraph-sm ${this.state.collapsed ? 'in' : ''}`}>
              {accommodationType.description}
            </div>
            <a className='btn btn-white btn-more collapsed' onClick={this.toggle}>
              {!this.state.collapsed && <span>{trans('label.read_more')}</span>}
              {this.state.collapsed && <span>{trans('label.read_less')}</span>}
            </a>
          </div>
          <PropertyGroups
            accommodationType={accommodationType}
            properties={properties}
          />
          <div className='p-3 bg-beige-light'>
            {accommodationType.map && (
              <Image
                file={accommodationType.map.reference}
                filter='sm_43'
                attr={{
                  className: 'img-responsive'
                }}
              />
            )}
          </div>
        </div>
      </article>
    )
  }
}

AccommodationType.propTypes = {
  accommodationType: PropTypes.object,
  hasAdultSelected: PropTypes.bool,
  properties: PropTypes.array,
  result: PropTypes.object,
  remove: PropTypes.func.isRequired,
  subjects: PropTypes.object
}

const mapState = state => ({
  hasAdultSelected: hasAdults(state),
  subjects: getSubjects(state)
})

export default connect(mapState)(AccommodationType)
