import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createWrapper, HYDRATE } from "next-redux-wrapper";
import {
  reservationEvents,
  persistReservationState,
} from "../modules/reservation/middleware";
import combinedReducer from "./reducers";
import { persistPreferences } from "../modules/preferences/middleware";

let store;

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    // Rehydration is a little bit wonky atm, so only merge content back in to keep right
    // reservation state at least. Content is the only thing that is being requested by
    // getServerSideProps now. See also https://github.com/kirill-konshin/next-redux-wrapper/issues/280
    const reservation =
      state.reservation.criteria.subjects.length > 0
        ? state.reservation
        : action.payload.reservation;
    const nextState = {
      ...state, // use previous state
      ...action.payload,
      preferences: {
        ...state.preferences,
        locale: action.payload.preferences.locale,
      },
      reservation: {
        ...reservation,
        objects: {
          ...state.reservation,
        },
        sections: {
          ...state.reservation.sections,
          ...action.payload.reservation.sections,
        },
      },
    };

    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

const initStore = () => {
  return createStore(
    reducer,
    bindMiddleware([
      thunkMiddleware,
      reservationEvents,
      persistReservationState,
      persistPreferences,
    ])
  );
};

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export const wrapper = createWrapper(initStore);
