import { H3, P } from "@libema/design-system";
import { TYPES } from "@libema/content-sdk";
import { useRouter } from "next/router";

// Local Components
import ActivityEventCard from "../Events/ActivityEventCard";
import DetailsCard from "../DetailsCard/DetailsCard";
import ProgramItemSeparator from "./ProgramItemSeparator";
import { PlanningContainer, PlanningHeader } from "./style";

const ItemComponent = ({ item, locale }) => {
  switch (item.type) {
    case TYPES.Activity:
      return (
        <DetailsCard
          {...item}
          cardName="Activity"
          locale={locale}
          park={item.location?.park}
          location={item.location}
        />
      );
    case TYPES.ProgramItemSeparator:
      return <ProgramItemSeparator {...item} />;
    case TYPES.ProgramItemExtra:
      return <ActivityEventCard event={item} />;
    default:
      return null;
  }
};

const DayPlanning = ({ title, subtitle, items }) => {
  const { locale } = useRouter();

  return (
    <PlanningContainer>
      <PlanningHeader>
        <H3>{title}</H3>
        {subtitle && <P>{subtitle}</P>}
      </PlanningHeader>
      {items.map((item) => (
        <ItemComponent key={item.id} item={item} locale={locale || "nl"} />
      ))}
    </PlanningContainer>
  );
};

export default DayPlanning;
