// types
import normalizeModelTypename from "../../../../utils/normalizeModelTypename";
import TYPES from "../../../../constants/types";
import Link from "../../outputTypes/Link";
import InlineScriptBlock from "../../outputTypes/InlineScriptBlock";
import Image from "../../outputTypes/Image";
import { linkTargetMapper } from "../../sdk/normalizer";

// Utils

export const normalizeLink = (link): Link => {
  const url = link.url || link.content?.slug || "/";
  const target = link.target ? linkTargetMapper[link.target] : "_self";

  return {
    id: link.sys.id,
    type: TYPES.Link,
    label: link.label || "",
    url,
    displayStyle: link.displayStyle,
    target,
    phoneNumber: link.phoneNumber,
  };
};

export const normalizeInlineScriptBlock = (scriptBlock): InlineScriptBlock => {
  return {
    id: scriptBlock.sys.id,
    type: TYPES.InlineScriptBlock,
    script: scriptBlock.script,
  };
};

export const normalizeInlineAssets = (inline) => {
  switch (inline.type.toLocaleLowerCase()) {
    case TYPES.InlineScriptBlock:
      return normalizeInlineScriptBlock(inline);
    case TYPES.Link:
      return normalizeLink(inline);
    default:
      return null;
  }
};

const YOUTUBE_REGEX = /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;

export const normalizeAsset = (asset): Image => {
  const youtubeUrls = asset.description?.match(YOUTUBE_REGEX);

  return {
    id: asset.sys.id,
    title: asset.title,
    url: asset.url,
    description: asset.description || "",
    contentType: asset.contentType || null,
    ...(youtubeUrls?.length && { youtubeId: youtubeUrls[1] }),
  };
};

export const normalizeRichText = (richText) => {
  const links = richText?.links;
  const normalizedAssets = links?.assets?.block?.map(normalizeAsset) || [];
  const normalizedInline = richText?.links?.entries?.inline.map(
    normalizeInlineAssets
  );
  const normalizedBlocks = links?.entries?.block?.map(normalizeLink) || [];
  const normalizedLinks = links?.entries?.hyperlink?.map(normalizeLink) || [];

  return {
    ...richText,
    ...(richText?.links && {
      links: {
        entries: {
          inline: normalizedInline,
          block: normalizedBlocks,
          hyperlink: normalizedLinks,
        },
        assets: {
          ...richText?.links.assets,
          block: normalizedAssets,
        },
      },
    }),
  };
};

const normalizePriceTable = (priceTable) => {
  if (!priceTable) {
    return null;
  }

  const { pricesCollection, conditionsCollection } = priceTable;
  return {
    prices: pricesCollection?.items?.map((price) => price) || [],
    conditions:
      conditionsCollection?.items?.map(({ icon, condition }) => ({
        icon,
        text: normalizeRichText(condition),
      })) || [],
  };
};

const normalizePlanActivity = ({
  sys,
  mainImage,
  cta,
  detailPage,
  categories,
  priceTable,
  ...activity
}) => ({
  ...activity,
  id: sys.id,
  type: TYPES.Activity,
  mainImage: normalizeAsset(mainImage),
  detailPage: detailPage && {
    slug: detailPage?.slug,
  },
  categories: categories.items.map((cat) => ({
    id: cat.sys.id,
    title: cat.title,
  })),
  priceTable: normalizePriceTable(priceTable),
});

const planNormalizers = {
  [TYPES.Activity]: normalizePlanActivity,
  [TYPES.ProgramItemSeparator]: (separator) => ({
    id: separator.sys.id,
    type: TYPES.ProgramItemSeparator,
    dayPart: separator.dayPart,
    description: separator.description,
    ctaButton: normalizeLink(separator.ctaButton),
  }),
  [TYPES.ProgramItemExtra]: (extra) => ({
    id: extra.sys.id,
    type: TYPES.ProgramItemExtra,
    title: extra.title,
    image: normalizeAsset(extra.image),
    location: extra.location,
  }),
};

export const normalizePlan = (plan) => ({
  id: plan.sys.id,
  type: TYPES.ProgramPlanning,
  title: plan.title,
  subtitle: plan.subtitle,
  items: plan.items.items.map((item) =>
    planNormalizers[normalizeModelTypename(item.type)](item)
  ),
});

export const normalizeLabeledIcon = (labeledIcon) => ({
  id: labeledIcon.sys.id,
  type: TYPES.LabeledIcon,
  label: labeledIcon.label,
  icon: labeledIcon.icon || null,
});

export const normalizeMultipleLocations = (locations: any) =>
  locations.map((location) => {
    return {
      name: location.fields.name,
      park: {
        name: location.fields.park?.fields.name,
      },
    };
  });
