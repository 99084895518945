import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '../Tabs'
import Accordion from '../Accordion'

const TabNavBlock = (props) => {
  switch (props.properties.template) {
    case 'accordion':
      return <Accordion {...props} />
    default:
      return <Tabs {...props} />
  }
}

TabNavBlock.propTypes = {
  properties: PropTypes.object
}

export default TabNavBlock
