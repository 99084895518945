/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";

type UseCancelReservation = {
  currentPage: string;
  pages: string[];
  canShow: boolean;
};

const useCancelReservation = ({
  currentPage,
  pages,
  canShow,
}: UseCancelReservation) => {
  const router = useRouter();

  // References
  const previousUrl = useRef<string>();

  // State
  const [confirmed, setConfirmed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dialogError, setDialogError] = useState(false);

  /**
   * Show a warning modal before navigating back to the
   * previous url.
   */
  useEffect(() => {
    // Only display warning dialog for allowed pages.
    if (!pages.includes(currentPage)) return;

    router.beforePopState(({ as, options }) => {
      // Check whether it's allowed to show the dialog
      if (!canShow) return true;

      setShowModal(true);
      if (!options?.shallow) previousUrl.current = as;
      return false;
    });

    return () => {
      router.beforePopState(() => {
        return true;
      });
    };
  }, [currentPage, pages, canShow, router]);

  /**
   * On confirmation we route either to the previous page or
   * we force to stay on the current page.
   */
  useEffect(() => {
    if (confirmed && previousUrl.current) {
      router.push(previousUrl.current);
      previousUrl.current = undefined;
    }

    return () => {
      setConfirmed(false);
    };
  }, [confirmed, router]);

  return { showModal, setShowModal, setConfirmed, dialogError, setDialogError };
};

export default useCancelReservation;
