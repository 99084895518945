import { FC, useEffect } from "react";
import styled from "styled-components";

import { SectionEvents } from "@libema/content-sdk";
import {
  Col,
  Container,
  DEVICE_LG_DOWN,
  DEVICE_LG_UP,
  Row,
  Section,
} from "@libema/design-system";

import RichTextRenderer from "../renderer/RichTextRenderer";
import { RichText } from "../blocks";
import WeekRow from "../blocks/Events/WeekRow";
import ParksRow from "../blocks/Events/ParksRow";
import EventSchedule from "../blocks/Events/EventSchedule";
import useEvents from "../blocks/Events/useEvents";

const StyledSection = styled(Section.Wrapper)`
  ${Container} {
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    @media ${DEVICE_LG_UP} {
      max-width: 1140px;
    }

    @media ${DEVICE_LG_DOWN} {
      flex-direction: column;
    }

    > ${Col}:first-of-type {
      padding-right: 0;
    }

    > ${Col}:last-of-type {
      margin-top: 2rem;
      padding: 0 55px;

      @media ${DEVICE_LG_UP} {
        margin-top: 0;
      }
    }
  }
`;

const Events: FC<SectionEvents> = ({
  title,
  eventByDayOfWeek,
  specialDates,
  text,
  weekdays,
}) => {
  const {
    selectedDay,
    setSelectedDay,
    selectedPark,
    onSelectPark,
    filterEvents,
    events,
    parks,
  } = useEvents();

  useEffect(() => {
    filterEvents(eventByDayOfWeek, specialDates);
  }, [eventByDayOfWeek, specialDates, selectedDay, selectedPark, filterEvents]);

  return eventByDayOfWeek && specialDates ? (
    <StyledSection>
      <Container>
        <Col xs={12} lg={6}>
          <WeekRow
            recurrentEvents={eventByDayOfWeek}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            specialDates={specialDates}
          />
          <ParksRow
            parks={parks}
            selectedPark={selectedPark}
            onSelect={onSelectPark}
          />
          <EventSchedule title={title} events={events} />
        </Col>
        <Col lg={6}>
          <RichText>
            <RichTextRenderer text={text} />
          </RichText>
        </Col>
      </Container>
    </StyledSection>
  ) : null;
};

export default Events;
