const en = {
  /**
   * ==================================================================
   * 404 Page
   * ==================================================================
   */
  "page.404.title": "Page not found | Klimrijk",
  "page.404.meta.description": "Page not found",
  "page.404.description":
    "This page does not exist (anymore), or there is a typo in the address.",
  "page.404.subtitle": "What to do?",
  "page.404.action.1": "Check if the address is spelled correctly",
  "page.404.button.home": "Go back to the homepage",

  /**
   * ==================================================================
   * 500 Page
   * ==================================================================
   */
  "page.500.title": "Ooops! It seems like something went wrong.",
  "page.500.description":
    "We will try to solve this issue as soon as possible.",
  "page.500.button": "Go back to the homepage",

  /**
   * ==================================================================
   * Article
   * ==================================================================
   */
  "page.article.published.in": "Published in",

  /**
   * ==================================================================
   * NewsLetter Subscribe form
   * ==================================================================
   */
  "newsletter.placeholder.email": "Your email address",
  "newsletter.button.subscribe": "Sign in",
  "newsletter.success":
    "Thank you for registering. You will receive an e-mail shortly to confirm your registration.",
  "newsletter.validator.email_invalid": "Invalid e-mail address",
  "newsletter.validator.required": "This is a mandatory field",

  /**
   * ==================================================================
   * Activity
   * ==================================================================
   */
  "activity.duration":
    "{count, plural, one {Duration # minute} other {Duration # minutes}}",
  "activity.min.age": "{minAge}+ years old",
  "activity.min.height": "Minimum {minHeight}m",
  "activity.min.persons":
    "{count, plural, one {From # person} other {From # persons}}",
  "activity.more.link": "More information",
  "activity.price.from": "from",
  "activity.price.adult": "Price adults",
  "activity.price.child": "Price adults",
  "activity.filter.button": "Filter",
  "activity.filter.age": "Age",
  "activity.filter.duration": "Duration",
  "activity.filter.age.4.8": "4 - 8 years old",
  "activity.filter.age.9.12": "9 - 12 years old",
  "activity.filter.age.13.16": "13 - 16 years old",
  "activity.filter.age.min.16": "From 16 years old",
  "activity.filter.duration.max.20": "< 20 min",
  "activity.filter.duration.20.40": "20 - 40 min",
  "activity.filter.duration.41.60": "41 - 60 min",
  "activity.filter.duration.min.60": "60 min (or more)",
  "activity.sort.relevance": "Recommended",
  "activity.sort.price_asc": "Price asc.",
  "activity.sort.price_desc": "Price desc.",
  "activity.price.free.lowercase": "free",
  "activity.open": "Open: {weekdays}",
  "activity.open_all_week": "Open all week",

  /**
   * ==================================================================
   * Program
   * =================================================================
   */
  "program.share-button": "Share program",

  /**
   *  ==================================================================
   *  Filters
   *  ==================================================================
   */
  "filter.title": "Filters",
  "filter.buttons.clear": "Clear selection",
  "filter.buttons.close_modal": "View {count} results",
  "filter.error.not_supported": "Filter {type} not supported",
  "filter.searchbox.placeholder": "Search...",
  "filter.loadbutton.load_more": "Load more",
  // Weekday filters
  "filter.maandag": "Monday",
  "filter.dinsdag": "Tuesday",
  "filter.woensdag": "Wednesday",
  "filter.donderdag": "Thursday",
  "filter.vrijdag": "Friday",
  "filter.zaterdag": "Saturday",
  "filter.zondag": "Sunday",

  /**
   * ==================================================================
   * Language Switcher
   * ==================================================================
   */
  "language-switcher.nl": "Nederlands",
  "language-switcher.en": "English",

  /**
   * ==================================================================
   * Footer
   * ==================================================================
   */
  "footer.title":
    "You'll have the greatest time at Klimrijk, which is part of Libéma.",
  "footer.title.social": "Follow us on Social Media:",
  "footer.newsletter.title": "Stay up to date",
  "footer.newsletter.description":
    "Get the latest news and the best promotions via our newsletter. ",

  /**
   * ==================================================================
   * FormSection
   * ==================================================================
   */
  "formsection.button.send": "Send",

  /**
   * ==================================================================
   * ArticleOverviewSection
   * ==================================================================
   */
  "article.overview.section.pagination.previous": "Previous",
  "article.overview.section.pagination.next": "Next",

  /**
   *  ==================================================================
   *  Eventschedule
   *  ==================================================================
   */
  "event.schedule.header.time": "Time",
  "event.schedule.header.activities": "Activities",
};
export default en;
