import React from "react";
import { Field } from "formik";
import styled from "styled-components";
import moment from "moment";
import { FormattedDate } from "react-intl";
import Label from "../form/Label";
import FormGroup from "../form/FormGroup";
import CheckboxField from "../form/fields/CheckboxField";
import { DEVICE_LG_UP } from "../../utils/deviceUtils";

const Dates = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const DateFormGroup = styled(FormGroup)`
  padding-right: 1.5rem;
  @media ${DEVICE_LG_UP} {
    width: 50%;
  }
`;

type AdditionDateFieldProps = {
  name: string;
  dates?: string[];
};

export const AdditionDateField: React.FC<AdditionDateFieldProps> = ({
  name,
  dates,
}) => (
  <Dates>
    {(dates || []).map((date, index) => (
      <DateFormGroup inline key={index}>
        <Field
          component={CheckboxField}
          id={`${name}-${date}`}
          name={name}
          value={date}
        />
        <Label htmlFor={`${name}-${date}`}>
          {moment(date).format("dddd")}{" "}
          <FormattedDate value={moment(date).format("YYYY/MM/DD")} />
        </Label>
      </DateFormGroup>
    ))}
  </Dates>
);

export default AdditionDateField;
