import React, { } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

const CheckboxField = (props) => {
  const { input, block: { name, properties }, meta: { touched, error } } = props

  return (
    <div className={(touched && error) ? 'form-check has-danger' : 'form-check'}>
      <label htmlFor={name} className='form-check-label col-form-label'>
        <input
          {...input}
          type='checkbox'
          id={name}
          name={name}
          className='form-check-input'
        />
        <span dangerouslySetInnerHTML={{ __html: properties.inputLabel }} />
      </label>
      {touched && error && <div className='form-control-feedback'>{error}</div>}
    </div>
  )
}

CheckboxField.propTypes = {
  input: PropTypes.object,
  block: PropTypes.object,
  meta: PropTypes.object
}

const CheckboxFieldBlock = (props) => {
  const { block, name } = props

  return <Field name={name} component={CheckboxField} block={block} />
}

CheckboxFieldBlock.propTypes = {
  block: PropTypes.object,
  name: PropTypes.string
}

export default CheckboxFieldBlock
