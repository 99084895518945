import React from "react";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import InfoIcon from "../icons/InfoIcon";
import P from "../typography/P";
import Row from "../layout/Row";
import Col from "../layout/Col";
import RadioField from "../form/fields/RadioField";
import Label from "../form/Label";
import Translate from "../typography/Translate";
import H3 from "../typography/H3";

const AdditionEssentialContainer = styled.div`
  background-color: #f3f4fa;
  margin-bottom: ${({ theme }) => theme.margin.md};
`;

const RadioContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Thumbnail = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Description = styled(P)`
  flex-grow: 1;
`;

const Title = styled(H3)`
  padding-right: ${({ theme }) => theme.padding.lg};
`;

const RightCorner = styled.div`
  position: absolute;
  right: 1.5rem;
  top: ${({ theme }) => theme.padding.sm};

  svg {
    fill: #b1b1b1;
  }
`;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
`;

const RadioLabel = styled(Label)`
  margin: 0.5rem 1rem 0.5rem 0.5rem;
  font-weight: 700;
`;

export type AdditionEssentialProps = {
  additionId: number;
  image?: string;
  title: string;
  description: string;
  shortDescription?: string;
  onSubmit: () => void;
  priceLabel: string;
  quantity: number;
};

const AdditionEssential: React.FC<AdditionEssentialProps> = ({
  additionId,
  image,
  title,
  description,
  shortDescription,
  onSubmit,
  priceLabel,
  quantity,
}) => {
  return (
    <AdditionEssentialContainer>
      <Row>
        <Col col={3}>
          <Thumbnail src={image} />
        </Col>
        <Col col={9}>
          <RightCorner>
            {description && <InfoIcon content={description} placement="top" />}
          </RightCorner>
          <Summary>
            <Title>{title}</Title>
            {shortDescription && <Description>{shortDescription}</Description>}
            <Footer>
              <span>{priceLabel}</span>
              <Formik initialValues={{ quantity: 0 }} onSubmit={onSubmit}>
                {({ submitForm }) => (
                  <Form>
                    <RadioContainer>
                      <Field
                        name="quantity"
                        value={quantity}
                        id={`addition-${additionId}-1`}
                        component={RadioField}
                        size="small"
                        onChange={submitForm}
                      />
                      <RadioLabel htmlFor={`addition-${additionId}-0`}>
                        <Translate id="label.yes" />
                      </RadioLabel>
                      <Field
                        name="quantity"
                        value={0}
                        id={`addition-${additionId}-0`}
                        component={RadioField}
                        size="small"
                        onChange={submitForm}
                      />
                      <RadioLabel htmlFor={`addition-${additionId}-0`}>
                        <Translate id="label.no" />
                      </RadioLabel>
                    </RadioContainer>
                  </Form>
                )}
              </Formik>
            </Footer>
          </Summary>
        </Col>
      </Row>
    </AdditionEssentialContainer>
  );
};

export default AdditionEssential;
