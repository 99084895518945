import { gql } from "@apollo/client";

import asset from "../data/asset";
import link from "../data/link";

const fragment = gql`
  fragment Lead on SectionLead {
    sys {
      id
    }
    description {
      json
      links {
        entries {
          inline {
            ...Link
          }
          block {
            ...Link
          }
        }
        assets {
          hyperlink {
            ...Asset
          }
          block {
            ...Asset
          }
        }
      }
    }
    title
    heading
    displayStyle
    headingElement
    backgroundColor
  }
  ${asset}
  ${link}
`;

export default fragment;
