import { useMemo } from "react";
import moment from "moment";

/**
 * Hide/Show flexible arrival/departure dates based on the toggle button
 */
const useChangeoverDaysFilter = ({
  showFlexibleBookingDays,
  availableDates,
  showLegend,
  changeoverDays,
  changeoverExceptionDates,
}) => {
  const dates = useMemo(() => {
    if (!showLegend) return availableDates;
    return availableDates.filter((date) => {
      if (changeoverExceptionDates?.includes(date)) {
        return true;
      }

      const weekdayNumber = moment(date).weekday();
      const isRegularChangeoverDay = changeoverDays.includes(
        weekdayNumber.toString()
      );

      if (!isRegularChangeoverDay && !showFlexibleBookingDays) {
        return false;
      }
      return true;
    });
  }, [
    showFlexibleBookingDays,
    availableDates,
    showLegend,
    changeoverDays,
    changeoverExceptionDates,
  ]);

  return dates;
};

export default useChangeoverDaysFilter;
