import BaseSection from "./BaseSection";
import Image from "./Image";
import Video from "./Video";
import TYPES from "../../../constants/types";
import RichText from "./RichText";

export enum SectionTeaserDisplayStyle {
  MEDIA = "MEDIA",
  MEDIA_MIRROR = "MEDIA_MIRROR",
  TEXT = "TEXT",
  TEXT_VERTICAL = "TEXT_VERTICAL",
}

type SectionTeaser = BaseSection<TYPES.SectionTeaser> & {
  title?: string;
  displayStyle: SectionTeaserDisplayStyle;
  media?: Image | Video;
  description: RichText;
  descriptionSecondary?: RichText;
};

export default SectionTeaser;
