import * as locales from "../locales";

/**
 * Merge common locales and brand specific locales
 */
const getTranslations = (customLocales) => ({
  nl: {
    ...locales.nl,
    ...customLocales.nl,
  },
  en: {
    ...locales.en,
    ...customLocales.en,
  },
  fr: {
    ...locales.fr,
    ...customLocales.fr,
  },
  de: {
    ...locales.de,
    ...customLocales.de,
  },
});

export default getTranslations;
