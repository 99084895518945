import { FC, ElementType } from "react";
import { SxProps, Theme } from "@mui/material/styles";

// Components
import Box from "@mui/material/Box";

type Props = {
  color?: string;
  size?: number;
  sx?: SxProps<Theme>;
  component?: ElementType<any>;
};

const Dot: FC<Props> = ({
  sx,
  color = "secondary.main",
  size = 8,
  component = "span",
}) => (
  <Box
    component={component}
    sx={{
      display: "inherit",
      width: `${size}px !important`,
      backgroundColor: color,
      borderRadius: "50%",
      height: `${size}px`,
      flexShrink: 0,
      ...sx,
    }}
  />
);

export default Dot;
