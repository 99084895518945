import React from 'react'
import PropTypes from 'prop-types'

const PriceTagAvailable = props => (
  <div className='price-tag compact'>
    <span className='price'>{`${props.all_in_price},-`}</span>
    {props.all_in_price < props.from_price && (
      <s>{`${props.from_price},-`}</s>
    )}
    {props.special_name && (
      <p className='text-discount' style={{ wordBreak: 'break-word' }}>
        {props.special_name}
      </p>
    )}
  </div>
)

PriceTagAvailable.propTypes = {
  all_in_price: PropTypes.number,
  from_price: PropTypes.number,
  special_name: PropTypes.string
}

export default PriceTagAvailable
