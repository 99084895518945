export default {
  // Buttons
  "button.subscribe": "Enregistrement",

  // Errors
  "error.general": "Une erreur est survenue. Veuillez réessayer plus tard.",

  // Form labels
  "placeholder.email": "Votre adresse e-mail",

  // Titles
  "title.newsletter_form": "Des courriers au goût d'aventure?",
  "title.libema_footer":
    "Venez vivre les plus belles aventures à {account}, du groupe",

  "success.newsletter_subscription":
    "Merci pour votre inscription. À titre de confirmation, nous vous avons envoyé un courriel de vérification.",

  // Form validators
  "validator.email_invalid": "Adresse mail non valide",
  "validator.required": "Ce champ est obligatoire",
};
