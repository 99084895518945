import React from "react";
import { SelectOption, Select } from "../Select";
import { FieldProps } from "formik";

type SelectFieldProps = {
  options: SelectOption[];
  defaultValue?: SelectOption;
} & FieldProps;

const SelectField: React.FC<SelectFieldProps> = (props) => {
  const {
    field,
    form: { setFieldTouched, setFieldValue },
    defaultValue,
  } = props;

  if (defaultValue && !field.value) {
    setFieldValue(field.name, defaultValue.value);
  }

  return (
    <Select
      {...props}
      value={props.options.find((option) => option.value === field.value)}
      field={field}
      onChange={(selected) => {
        if (!selected) {
          return setFieldValue(field.name, null);
        } else if (Array.isArray(selected)) {
          return setFieldValue(
            field.name,
            selected.map((option: SelectOption) => option.value)
          );
        }

        return setFieldValue(field.name, (selected as SelectOption).value);
      }}
      onBlur={() => setFieldTouched(field.name, true)}
      // error={errors[field.name]}
    />
  );
};

export default SelectField;
