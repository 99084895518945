import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'
import { Alert } from '@libema/design-system'
const STATE_KEY = 'AlertBlockState'
const TIME_TO_BE_HIDDEN = 86400000 // 1 day

const simpleTimeState = {
  getAll () {
    try {
      return JSON.parse(window.localStorage.getItem(STATE_KEY)) || {}
    } catch {
      return {}
    }
  },
  lastTimeHidden (id) {
    return this.getAll()[id]
  },
  setHidden (id) {
    const allAlertsState = this.getAll()
    allAlertsState[id] = Date.now()
    window.localStorage.setItem(STATE_KEY, JSON.stringify(allAlertsState))
  }
}

const StyledAlert = styled(Alert)`
  &.fixed-top {
    position: absolute;
    top: 0;
    z-index: 9999;
    left: 0;
    width: 100%;
  }
`

const HtmlContent = styled.div`
  & p:last-of-type {
    margin-bottom: 0;
  }

  & a {
    font-weight: bold;
    text-decoration: underline;
  }
`
function AlertBlock ({ value, properties, block }) {
  const [showAlert, setShowAlert] = useState(false)
  const type = typeof properties.styles === 'string' && properties.styles?.replace('danger', 'error')
  const isFixed = properties.extra_classes?.includes('fixed-top')

  useEffect(() => {
    const lastTimeHidden = simpleTimeState.lastTimeHidden(block.id)
    setShowAlert(!lastTimeHidden || lastTimeHidden - TIME_TO_BE_HIDDEN > Date.now())
  }, [])

  if (!showAlert) {
    return null
  }

  const handleAlertClose = () => {
    simpleTimeState.setHidden(block.id)
  }

  const className = classNames(properties.extra_classes, {
    'alert-primary': type === 'primary' || type === undefined
  })

  return (
    <StyledAlert
      key={block.id}
      id={properties.id}
      canBeClosed={properties.closable || isFixed}
      onClose={handleAlertClose}
      className={className}
      type={type}
    >
      <HtmlContent dangerouslySetInnerHTML={{ __html: value }} />
    </StyledAlert>
  )
}

AlertBlock.propTypes = {
  properties: PropTypes.shape({
    closable: PropTypes.bool.isRequired,
    id: PropTypes.string,
    extra_classes: PropTypes.string,
    styles: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.string), PropTypes.string])
  }),
  block: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  value: PropTypes.string.isRequired
}

export default AlertBlock
