import isEqual from 'lodash.isequal'
import * as t from './actionTypes'
import * as actions from '../../redux/actions'

const initialContentState = {
  currentSection: null,
  postedSection: null,
  submitSection: null,
  blocks: [],
  hiddenBlocks: [],
  isFetching: true,
  slug: null,
  title: null,
  shortTitle: null,
  description: null,
  attributes: null
}

const blocks = (state = initialContentState, action) => {
  switch (action.type) {
    case t.SET_CONTENT: {
      const updatedState = {
        ...state,
        ...action.content
      }
      return isEqual(updatedState, state) ? state : updatedState
    }
    case actions.SET_BLOCKS: {
      const keys = Object.keys(action.response.entities.blocks)
      const updatedState = {
        ...state,
        ...action.response.result,
        blocks: keys.map(key => parseInt(key, 10)),
        isFetching: false
      }
      return isEqual(updatedState, state) ? state : updatedState
    }
    case actions.HIDE_BLOCKS: {
      return {
        ...state,
        hiddenBlocks: action.hiddenBlocks
      }
    }
    case actions.HIDE_BLOCK: {
      return {
        ...state,
        hiddenBlocks: state.hiddenBlocks.concat(action.hiddenBlock)
      }
    }
    case actions.SHOW_BLOCK: {
      const { hiddenBlocks } = state
      hiddenBlocks.splice(hiddenBlocks.indexOf(action.block), 1)
      return {
        ...state,
        hiddenBlocks
      }
    }
    case actions.SET_INITIAL_FORMUI_STATE: {
      return {
        ...state,
        sections: action.sections,
        currentSection: action.currentSection
      }
    }
    case actions.POST_SUBMIT_SECTION: {
      return {
        ...state,
        submitSection: null,
        currentSection: action.next
      }
    }
    case actions.NEXT_SECTION: {
      return {
        ...state,
        submitSection: state.currentSection
      }
    }
    case actions.CLEAR_SUBMIT_SECTION: {
      return {
        ...state,
        submitSection: null
      }
    }
    // case actions.PREV_SECTION: {
    //   const sections = findByType(action.content, action.entities, 'FormSectionBlock');
    //   const idx = sections.findIndex(s => s.id === state.currentSection);

    //   // changeSectionUrl(idx);

    //   return {
    //     ...state,
    //     currentSection: sections[idx - 1].id,
    //   };
    // }
    case actions.SET_CURRENT_SECTION: {
      return {
        ...state,
        currentSection: action.currentSection
      }
    }
    default: {
      return state
    }
  }
}

const content = (state = {}, action) => {
  switch (action.type) {
    case t.SET_CONTENTS: {
      const contents = {}
      action.contents.forEach((c) => {
        contents[c.id] = blocks(state[c.id], {
          type: t.SET_CONTENT,
          content: c
        })
      })
      const updatedState = {
        ...contents,
        ...state
      }
      const stateEqual = isEqual(updatedState, state)
      return stateEqual ? state : updatedState
    }
    case t.SET_CONTENT: {
      return Object.assign({}, state, {
        [action.content.id]: blocks(state[action.content.id], action)
      })
    }

    // TODO: Move to a separate reducer or save the content array under a key
    // case t.SET_MODE: {
    //   if (state.manageMode !== 'manage') {
    //     return {
    //       ...state,
    //       manageMode: action.manageMode,
    //       manageType: action.manageType,
    //     };
    //   }
    //   return state;
    // }
    case actions.SET_BLOCKS:
    case actions.HIDE_BLOCKS:
    case actions.HIDE_BLOCK:
    case actions.SHOW_BLOCK:
    case actions.SET_INITIAL_FORMUI_STATE:
    case actions.POST_SUBMIT_SECTION:
    case actions.NEXT_SECTION:
    case actions.CLEAR_SUBMIT_SECTION:
    case actions.PREV_SECTION:
    case actions.SET_CURRENT_SECTION: {
      return Object.assign({}, state, {
        [action.content]: blocks(state[action.content], action)
      })
    }
    default: {
      return state
    }
  }
}

export default content
