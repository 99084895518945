import React from "react";
import { FieldProps } from "formik";
import Checkbox from "../Checkbox";

type CheckboxFieldProps = {
  value?: boolean | any[];
  onChange?: (newValue: boolean | any[]) => void;
} & FieldProps;

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  field,
  form: { setFieldValue, setFieldTouched, errors, touched },
  value,
  onChange,
  ...restProps
}) => (
  <Checkbox
    {...restProps}
    error={
      typeof touched[field.name] !== "undefined" &&
      typeof errors[field.name] !== "undefined"
    }
    checked={
      Array.isArray(field.value) ? field.value.includes(value) : field.value
    }
    onChange={(newValue) => {
      setFieldTouched(field.name);
      if (!Array.isArray(field.value)) {
        setFieldValue(field.name, newValue);
      } else {
        if (!field.value.includes(value)) {
          setFieldValue(field.name, [...field.value, value]);
        } else if (field.value.includes(value)) {
          field.value.splice(field.value.indexOf(value), 1);
          setFieldValue(field.name, [...field.value]);
        }
      }
      onChange && onChange(newValue);
    }}
  />
);

export default CheckboxField;
