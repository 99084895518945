import { gql } from "@apollo/client";

const fragment = gql`
  fragment Link on Link {
    sys {
      id
    }
    type: __typename
    label
    url
    content {
      slug
    }
    displayStyle
    target
    phoneNumber
  }
`;

export default fragment;
