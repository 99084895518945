import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import trans from 'counterpart'
import { resourceInReservationSelector } from '../selectors'
import { getUserAgent } from '../../../selectors/environmentSelectors'
import { isMobile } from '../../../utilities/deviceUtilities'
import ReceiptContext from '../../../contexts/ReceiptContext'
import ReceiptListing from './ReceiptListing'
import reservationSelectors from '../../../selectors/reservationSelectors'

class ReceiptWrapper extends Component {
  state = {
    collapsed: false
  }

  handleToggle = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }))
  }

  render = () => {
    const { placeholder, resource, totalPrice } = this.props

    return (
      <>
        <div
          className={`receipt-holder${this.state.collapsed ? ' toggled' : ''} hidden-md-up`}
        >
          <div
            role='button'
            tabIndex='0'
            className='btn btn-block btn-primary btn-specifications hidden-md-up row mx-0'
            onClick={this.handleToggle}
          >
            <div className='float-left text-left'>
              {resource && <div className='margin-bottom-1'><i>{resource.display_name}</i></div>}
              <div>{trans('label.specifications')}</div>
            </div>
            {!this.state.collapsed && <i className='material-icons md-36'>keyboard_arrow_up</i>}
            {this.state.collapsed && <i id='down-arrow' className='material-icons md-36'>keyboard_arrow_down</i>}
            <div className='float-right text-right'>
              <div className='margin-bottom-1'><i>{trans('pricing.total')}</i></div>
              <div>
                {`€ ${totalPrice.toFixed(2)}`}
              </div>
            </div>
          </div>
          {this.state.collapsed && (
            <ReceiptContext.Provider value={{ toggle: this.handleToggle }}>
              <div>
                {placeholder(0)}
              </div>
            </ReceiptContext.Provider>
          )}
        </div>
        <div className='hidden-md-down'>{placeholder(0)}</div>
        <ReceiptListing />
      </>
    )
  }
}

ReceiptWrapper.propTypes = {
  placeholder: PropTypes.func.isRequired,
  resource: PropTypes.object,
  totalPrice: PropTypes.number
}

const mapState = state => ({
  isMobile: isMobile(getUserAgent(state)),
  resource: resourceInReservationSelector(state),
  totalPrice: reservationSelectors.getTotalPrice(state)
})

export default connect(mapState)(ReceiptWrapper)
