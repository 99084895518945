import { gql } from "@apollo/client";

import asset from "../data/asset";
import link from "../data/link";

const fragment = gql`
  fragment Hero on SectionHero {
    sys {
      id
    }
    media {
      ...Asset
    }
    videoThumbnail {
      ...Asset
    }
    content: title {
      json
    }
    heroDisplayStyle: displayStyle
    buttons: buttonsCollection(limit: 3) {
      items {
        ...Link
      }
    }
  }

  ${asset}
  ${link}
`;

export default fragment;
