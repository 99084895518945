import { gql } from "@apollo/client";

import asset from "../data/asset";
import link from "../data/link";

const query = gql`
  fragment ActivityOverview on SectionActivityOverview {
    type: __typename
    sys {
      id
    }
    systemTagFilter
    title
    subtitle
    description {
      json
      links {
        entries {
          inline {
            ...Link
          }
          block {
            ...Link
          }
        }
        assets {
          block {
            ...Asset
          }
        }
      }
    }
    pageSize
    activities: activitiesCollection(limit: 25) {
      items {
        sys {
          id
        }
      }
    }
    filters: filtersCollection(limit: 10) {
      items {
        ... on Filter {
          type: __typename
          sys {
            id
          }
          title
          filterType
          attribute
          sortType
        }
      }
    }
    backgroundColor
    displayMode
  }

  ${asset}
  ${link}
`;

export default query;
