import React, { Component } from 'react'
import PropTypes from 'prop-types'
import trans from 'counterpart'
import { connect } from 'react-redux'
import { setCompareProperty, removeResourceFromCompare, addResourceToCompare } from '../actions'
import { getAccommodationsById } from '../selectors'
import { createCompareUrl } from '../utils'
import { MAX_COMPARE } from '../constants'
import Link from '../../../components/typography/Link'

class CompareResourcesBar extends Component {
  state = {
    initialized: false,
    showDropdown: false
  }

  componentDidMount = () => this.setState({ initialized: true })

  componentWillUpdate = (newProps) => {
    const { setHasIdInList } = this.props
    if (newProps.hasIdInList) {
      setTimeout(() => setHasIdInList(false), 3000)
    }
    return true
  }

  getCompareUrl = () => {
    const { locale, compareListIds } = this.props
    let slug = ''
    if (process.env.NEXT_PUBLIC_APP === 'dierenbos') {
      switch (locale) {
        case 'en':
          slug = '/en/compare'
          break
        case 'fr':
          slug = '/fr/comparer'
          break
        case 'de':
          slug = '/de/vergleichen'
          break
        default:
          slug = '/vergelijken'
          break
      }
    } else {
      switch (locale) {
        case 'en':
          slug = '/en/stay-the-night/accommodations/compare'
          break
        case 'fr':
          slug = '/fr/sejourner/hebergement/comparer'
          break
        case 'de':
          slug = '/de/ubernachten/unterkunfte/vergleichen'
          break
        default:
          slug = '/overnachten/accommodaties/vergelijk'
          break
      }
    }

    return `${slug}?${createCompareUrl({ resources: compareListIds })}`
  }

  addToCompareResources = (id) => {
    const { addToCompare, compareListIds, setHasIdInList } = this.props
    if (compareListIds.indexOf(id) > -1) {
      return setHasIdInList(true)
    }

    return addToCompare(id)
  }

  showDropdown = () => this.setState(prevState => ({
    showDropdown: !prevState.showDropdown
  }))

  render = () => {
    const { initialized } = this.state
    const { compareListIds, accommodations, removeFromCompareList, hasMaxCompare, hasIdInList, allAccommodations } = this.props
    if (!initialized || compareListIds.length === 0) return null

    return (
      <>
        <section className='compare-bar fixed-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='compare-results'>
                <div className='holder'>
                  {(compareListIds.length < MAX_COMPARE) && (
                    <div className='compare-item compare-select pull-right'>
                      <div className='dropup'>
                        <button
                          className='btn btn-link btn-block dropdown-toggle'
                          type='button'
                          id='compareSelect'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                          onClick={this.showDropdown}
                        >
                          <span className='material-icons'>
                            add_box
                          </span>
                          {trans('label.add')}
                        </button>
                        {this.state.showDropdown && (
                          <ul className='dropdown-menu show' aria-labelledby='compareSelect'>
                            {allAccommodations && Object.values(allAccommodations).map(accommodation => (
                              <li key={accommodation.id} onClick={() => this.addToCompareResources(accommodation.id)}>
                                {accommodation.display_name || accommodation.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  )}
                  {compareListIds.map((id, index) => (
                    <div key={id} className='compare-item'>
                      <div className='row'>
                        <div className='compare-image'>
                          {accommodations[id].images.length > 0 && <img src={accommodations[id].images[0].image_medium} alt='' className='img-responsive' />}
                        </div>
                        <div className='compare-content'>
                          <h3>
                            <Link to={`/${accommodations[id].slug}`}>
                              {accommodations[id].display_name || accommodations[id].name}
                            </Link>
                          </h3>
                        </div>
                        <span
                          tabIndex={index}
                          role='button'
                          className='remove'
                          onClick={() => removeFromCompareList(id)}
                        >
                          <span className='material-icons'>close</span>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='compare-button'>
                <Link className='btn btn-primary-color btn-block' to={this.getCompareUrl()}>{trans('newyse.compare.label')}</Link>
              </div>
            </div>
          </div>
        </section>
        {hasMaxCompare && (
          <div className='alert alert-warning alert-compare'>
            <div className='container'>
              {`${trans('newyse.compare.max')}.`}
              <a href='{{getCompareUrl()}}' className='alert-link'>{trans('newyse.compare.start')}</a>
            </div>
          </div>
        )}
        {hasIdInList && (
          <div className='alert alert-warning alert-compare'>
            <div className='container'>
              {trans('newyse.compare.duplicate')}
            </div>
          </div>
        )}
      </>
    )
  }
}

CompareResourcesBar.propTypes = {
  addToCompare: PropTypes.func,
  allAccommodations: PropTypes.object,
  compareListIds: PropTypes.array,
  setHasIdInList: PropTypes.func,
  accommodations: PropTypes.object,
  locale: PropTypes.string,
  hasMaxCompare: PropTypes.bool,
  hasIdInList: PropTypes.bool,
  removeFromCompareList: PropTypes.func
}

const mapStateToProps = state => ({
  locale: state.preferences.locale,
  compareListIds: state.compare.compareListIds,
  hasIdInList: state.compare.hasIdInList,
  hasMaxCompare: state.compare.hasMaxCompare,
  allAccommodations: state.entities.accommodationtypes,
  accommodations: getAccommodationsById(state)
})

const mapDispatchToProps = dispatch => ({
  addToCompare: id => dispatch(addResourceToCompare(id)),
  removeFromCompareList: id => dispatch(removeResourceFromCompare(id)),
  setHasIdInList: bool => dispatch(setCompareProperty('hasIdInList', bool))
})

export default connect(mapStateToProps, mapDispatchToProps)(CompareResourcesBar)
