import React from 'react'
import PropTypes from 'prop-types'
import trans from 'counterpart'
import moment from 'moment'
import Image from '../modules/media/components/Image'
import { DISPLAY_DATE } from '../constants/dates'

// const elements = document.getElementsByClassName('js-collection-block');

// Object.keys(elements).forEach(i => {
//     const element = elements[i];

//     moment.locale(`${element ? element.getAttribute('locale') : 'nl'}`);
// });

const CollectionItemNews = (props) => {
  const { content, itemKey, isFiltered } = props
  let vpWidth = 0
  if (typeof document !== 'undefined') {
    vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  }

  if (!content) return <div />

  return (
    <div className={`card ${vpWidth > 767 ? 'card-landscape' : 'card-portrait'} ${itemKey === 0 && !isFiltered ? 'image-right first-result card-taupe' : 'card-beige-sand image-left'}`}>
      {content.medias.length > 0 && (
        <div className='card-media'>
          <a href={`/${content.slug}`}>
            <Image
              file={content.medias[0].reference}
              filter='md_43'
              attr={{
                className: 'card-img-top img-fluid',
                alt: content.title
              }}
            />
          </a>
        </div>
      )}
      <div className='card-block'>
        <h3 className='card-title mb-4'>
          <a href={`/${content.slug}`}>
            {content.title}
          </a>
        </h3>
        <h6 className='card-subtitle mb-1'>
          {moment(content.publish_at).format(DISPLAY_DATE)}
          {(content.attributes.author) && `${trans('label.by')} ${content.attributes.author}`}
        </h6>
        {vpWidth > 991 && (
          <p className='card-text mb-3'>{content.description}</p>
        )}

        <a href={`/${content.slug}`} className={`btn ${itemKey === 0 && !isFiltered ? 'btn-white' : 'btn-primary'}`}>{trans('label.read_more')}</a>
      </div>
    </div>
  )
}

CollectionItemNews.propTypes = {
  content: PropTypes.object,
  itemKey: PropTypes.number,
  isFiltered: PropTypes.bool
}

export default CollectionItemNews
