import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import qs from "query-string";
import trans from "counterpart";
import throttle from "lodash.throttle";
import { Popover } from "reactstrap";
import { Box } from "@mui/material";
import { DEVICE_LG_DOWN, DEVICE_LG_UP, ResortLabel } from "@libema/design-system";
import { getPreferences } from "../../preferences/selectors";
import Filters from "./Filters";
import {
  getPreference,
  hasAdults,
} from "../../../selectors/preferenceSelectors";
import {
  PREFERENCE_SUBJECTS,
  PREFERENCE_ARRIVAL,
  PREFERENCE_VOUCHER,
  PREFERENCE_SPECIAL,
} from "../../../constants/preferences";
import { getBookingUrl } from "../../../utilities/preferenceUtilities";
import {
  fetchOffers,
  setArrivalPreference,
  fetchArrivalsAndDepartures,
} from "../../../actions/preferenceActions";
import { getLocaleFromSlug } from "../../../utilities/localeUtilities";
import BookButton from "./search-and-book/BookButton";
import Link from "../../../components/typography/Link";
import analyticsUtils from "../../../utilities/analyticsUtils";
import { ID_SAFARIRESORT } from "../constants";
import { getResortVariation } from "../utilities";

const ThreeSixtyViewButton = styled.a`
  display: block;
  width: 100%;
  img {
    width: 100%;
  }
`;

const StyledResortLabel = styled(Box)`
  display: flex;
  > div {
    position: relative;
    flex: 1;
    @media ${DEVICE_LG_DOWN} {
      > div {
        justify-content: center;
        > *,
        > .MuiTypography-root {
          flex: none;
          font-size: 1rem;
          margin-top: 3px;
        }
      }
    }
    @media ${DEVICE_LG_UP} {
      position: absolute;
      right: -120px;
      left: auto;
      z-index: 1;
    }
  }
`;

class AccommodationInfoBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      panic: false,
      popoverOpen: false,
      popoverOpenAllInDesktop: false,
      popoverOpenAllInMobile: false,
      error: null,
      results: [],
      stickyGallery: false,
      stickyGalleryOpen: false,
    };
    this.throttled = throttle(this.scrollEventHandler, 150, { leading: true });
  }

  componentDidMount = () => {
    this.fetchResults().then((data) => {
      if (data && !data.results.length) {
        const closestArrival = [...data.arrivals]
          .sort((a, b) => new Date(a) - new Date(b))
          .shift();
        this.props.setArrivalDate(closestArrival);
        this.fetchResults();
      }
    });
    window.addEventListener("scroll", this.throttled);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.throttled);
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.arrivalDate !== this.props.arrivalDate &&
      !this.state.isFetching
    ) {
      // Start fetching results if the arrival was changed due to another component (e.g. the price table)
      this.fetchResults();
    }
  };

  trackAccommodationView() {
    const { all_in_price, arrival_date, resource_id, departure_date } =
      this.getResult() || {};

    if (resource_id != null && all_in_price !== null) {
      analyticsUtils.handleAccommodationView({
        id: resource_id,
        price: all_in_price,
        startDate: arrival_date,
        endDate: departure_date,
      });
    }
  }

  handlePreferenceToggle = () =>
    this.setState((prevState) => ({
      stickyGalleryOpen: !prevState.stickyGalleryOpen,
    }));

  scrollEventHandler = () => {
    const { stickyGallery } = this.state;
    if (window.innerWidth > 991) {
      if (stickyGallery && window.pageYOffset < 585) {
        this.setState({ stickyGallery: false });
        this.setState({ stickyGalleryOpen: false });
      }
      if (!stickyGallery && window.pageYOffset >= 585) {
        this.setState({ stickyGallery: true });
      }
    }
  };

  fetchResults = () => {
    this.setState({ isFetching: true });
    const queryString = qs.parse(window.location.search);
    const focus = {
      resource: this.props.accommodation_type.id,
      allowRefetch: true,
      group_by: "resource",
      sort: "best_match",
      locale: trans("locale"),
      voucher: this.props.voucher || queryString.voucher_code,
      special: queryString.special,
    };

    const fetchOffersPromise = this.props
      .fetchOffers(focus)
      .then((data) => {
        if (!data) {
          return this.setState({ isFetching: false });
        }
        this.setState({
          results: data.results,
          isFetching: false,
          panic: data.panic,
        });
        this.trackAccommodationView();
        return data;
      })
      .finally(() => {
        this.setState({ isFetching: false });
      });

    this.props.fetchArrivalsAndDepartures(focus);

    return fetchOffersPromise;
  };

  toggle = () =>
    this.setState((prevState) => ({
      popoverOpen: !prevState.popoverOpen,
    }));

  toggleAllInDesktop = () =>
    this.setState((prevState) => ({
      popoverOpenAllInDesktop: !prevState.popoverOpenAllInDesktop,
    }));

  toggleAllInMobile = () =>
    this.setState((prevState) => ({
      popoverOpenAllInMobile: !prevState.popoverOpenAllInMobile,
    }));

  getResult = () => {
    const {
      results,
      panic,
      accommodation_type: accommodationType,
    } = this.state;
    const result =
      (results && results.length && results[0]) ||
      (panic && results.find((e) => e.resource_id === accommodationType.id));
    if (!result || (!panic && !result.arrival_date)) {
      return null;
    }

    return result;
  };

  getCompareRoute = () => {
    if (process.env.NEXT_PUBLIC_APP === "dierenbos") {
      return trans("routes.compare_dierenbos");
    }

    return trans("routes.compare");
  };

  getBookingRoute = () => {
    const { subjectPreference, voucher, special } = this.props;
    const result = this.getResult();

    const additionalParams = {};
    if (voucher) {
      additionalParams.voucher_code = voucher;
      if (special) additionalParams.special = special;
    }

    return getBookingUrl(
      result,
      subjectPreference,
      undefined,
      additionalParams
    );
  };

  render() {
    const locale =
      typeof window !== "undefined"
        ? getLocaleFromSlug(window.location.pathname)
        : "nl";
    const { accommodation_type, hasAdults, manageTags } = this.props; // eslint-disable-line camelcase
    const { stickyGalleryOpen, panic } = this.state;
    const result = this.getResult();
    const resortVariation = getResortVariation(accommodation_type.resort.id);

    // <div className={`gallery-holder ${stickyGallery ? 'affix' : 'affix-top'}`} {...manageTags}></div>
    return (
      <>
        {accommodation_type.label === 1 && (
          <div className="accommodation-label-new" />
        )}
        {accommodation_type.label === 2 && (
          <div className="accommodation-label-restyled" />
        )}
        <div className="gallery-holder" {...manageTags}>
          <div className="container">
            <div className="gallery-preferences">
              <header className="gallery-header">
                <h1>
                  {accommodation_type.display_name || accommodation_type.name}
                </h1>
              </header>
              {resortVariation && (
                <StyledResortLabel>
                  <ResortLabel {...resortVariation} />
                </StyledResortLabel>
              )}
              {!this.state.error && (
                <div
                  className={`gallery-body ${stickyGalleryOpen ? "show" : ""}`}
                >
                  <div className="row">
                    <div className="filters filters-accommodation" id="filters">
                      <div className="row">
                        <Filters
                          columnClass="col-12 pr-0"
                          petsAllowed={accommodation_type.max_pets > 0}
                          focus={this.props.focus}
                          results={this.state.results}
                          onChange={this.fetchResults}
                        />
                      </div>
                    </div>
                    {result && (
                      <div className="preference-data">
                        <div className="price-holder">
                          <div className="price-tag compact">
                            <span className="price">
                              {(Number.isFinite(result.all_in_price) &&
                                `${result.all_in_price},-`) ||
                                (panic &&
                                  trans("label.from_price", {
                                    price: result.from_price
                                      ? `${result.from_price},-`
                                      : "...,-",
                                  }))}
                            </span>
                            {result.all_in_price < result.from_price && (
                              <s>{`${result.from_price},-`}</s>
                            )}
                            {result.special_name && (
                              <p className="text-discount">
                                {result.special_name}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="details-included details-included-desktop small my-3">
                          <div
                            className="popover-included"
                            id="popoverOpenAllInDesktop"
                            onClick={this.toggleAllInDesktop}
                          >
                            {trans("stay.including")}{" "}
                            <span className="material-icons md-18">help</span>
                          </div>
                          <Popover
                            popperClassName="popover popover-modal"
                            placement="bottom"
                            target="popoverOpenAllInDesktop"
                            isOpen={this.state.popoverOpenAllInDesktop}
                            toggle={this.toggleAllInDesktop}
                          >
                            <div
                              className="popover-content"
                              dangerouslySetInnerHTML={{
                                __html:
                                  accommodation_type.all_in_device_description,
                              }}
                            />
                            <div
                              className="popover-close"
                              onClick={this.toggleAllInDesktop}
                            >
                              <i className="material-icons">&#xE5CD;</i>
                            </div>
                          </Popover>
                        </div>

                        <div className="details-included details-included-mobile small my-3">
                          <div
                            className="popover-included"
                            id="popoverOpenAllInMobile"
                            onClick={this.toggleAllInDesktop}
                          >
                            {trans("stay.including")}{" "}
                            <span className="material-icons md-18">help</span>
                          </div>
                          <Popover
                            popperClassName="popover popover-modal"
                            placement="bottom"
                            target="popoverOpenAllInMobile"
                            isOpen={this.state.popoverOpenAllInMobile}
                            toggle={this.toggleAllInMobile}
                          >
                            <div
                              className="popover-content"
                              dangerouslySetInnerHTML={{
                                __html:
                                  accommodation_type.all_in_device_description,
                              }}
                            />
                            <div
                              className="popover-close"
                              onClick={this.toggleAllInMobile}
                            >
                              <i className="material-icons">&#xE5CD;</i>
                            </div>
                          </Popover>
                        </div>
                        <div className="btn-booking-wrapper">
                          <BookButton
                            arrivalDate={result.arrival_date}
                            disabled={!hasAdults}
                            panic={panic}
                            to={this.getBookingRoute()}
                            toFallback={trans("routes.booking_url_fallback")}
                            accommodationKindId={result.kind_id}
                          />
                        </div>
                      </div>
                    )}
                    {!result && (
                      <div className="preference-data disabled">
                        <div className="price-holder">
                          <div className="price-tag compact">
                            {trans("label.not_available_in_period")}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`preference-toggle ${
                      stickyGalleryOpen ? "preference-toggle-open" : ""
                    }`}
                    onClick={this.handlePreferenceToggle}
                    tabIndex="0"
                    role="button"
                  >
                    <span />
                  </div>
                </div>
              )}
              {this.state.error && (
                <div className="alert alert-danger">
                  {trans("error.newyse.general")}
                </div>
              )}
              <div className="gallery-footer p-t-0">
                <div className="inner">
                  {(![
                    1484051, 1484049, 1716044, 1588045, 1484048, 2344043,
                  ].includes(accommodation_type.id) ||
                    locale !== "nl") && (
                    <div className="row">
                      <div className="col-md-6 pr-0">
                        <Link
                          to={`${this.getCompareRoute()}?resources[]=${
                            this.props.accommodation_type.id
                          }`}
                          className="btn btn-link btn-block btn-compare"
                        >
                          {trans("label.compare_type")}
                        </Link>
                      </div>
                      {accommodation_type.resort.id === ID_SAFARIRESORT && (
                        <div className="col-md-6 pr-0">
                          <a
                            className="btn btn-link btn-eurosign hidden-sm-down"
                            tabIndex="0"
                            href="#price-table"
                          >
                            {trans("share.compare")}
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                  {[
                    1484051, 1484049, 1716044, 1588045, 1484048, 2344043,
                  ].includes(accommodation_type.id) &&
                    locale === "nl" && (
                      <div className="row">
                        <div className="col-md-6 pr-0">
                          <Link
                            to={`${this.getCompareRoute()}?resources[]=${
                              this.props.accommodation_type.id
                            }`}
                            className="btn btn-link btn-block btn-compare"
                          >
                            {trans("label.compare_type")}
                          </Link>
                          {accommodation_type.resort.id === ID_SAFARIRESORT && (
                            <a
                              className="btn btn-link btn-eurosign hidden-sm-down"
                              tabIndex="0"
                              href="#price-table"
                            >
                              {trans("share.compare")}
                            </a>
                          )}
                        </div>
                        <div className="col-md-6 pr-0">
                          <ThreeSixtyViewButton tabIndex="0" href="#ts-view">
                            <img
                              src="/img/button-acco-360@3x.png"
                              alt="360 view"
                            />
                          </ThreeSixtyViewButton>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <ul className="gallery-nav list-unstyled list-inline">
              <li>
                <a href={`#${trans("label.photos").toLowerCase()}`}>
                  {trans("label.photos")}
                </a>
              </li>
              <li>
                <a href={`#${trans("label.description").toLowerCase()}`}>
                  {trans("label.description")}
                </a>
              </li>
              <li>
                <a href={`#${trans("label.facilities").toLowerCase()}`}>
                  {trans("label.facilities")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

AccommodationInfoBlock.propTypes = {
  accommodation_type: PropTypes.object,
  arrivalDate: PropTypes.string,
  hasAdults: PropTypes.bool,
  fetchArrivalsAndDepartures: PropTypes.func,
  fetchOffers: PropTypes.func.isRequired,
  focus: PropTypes.object,
  setArrivalDate: PropTypes.func.isRequired,
  subjectPreference: PropTypes.object,
  voucher: PropTypes.string,
  special: PropTypes.string,
};

const mapStateToProps = (state) => ({
  arrivalDate: getPreference(PREFERENCE_ARRIVAL)(state),
  preferences: getPreferences(state),
  subjectPreference: getPreference(PREFERENCE_SUBJECTS)(state),
  voucher: getPreference(PREFERENCE_VOUCHER)(state),
  special: getPreference(PREFERENCE_SPECIAL)(state),
  hasAdults: hasAdults(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchOffers: (focus) => dispatch(fetchOffers(focus)),
  fetchArrivalsAndDepartures: (focus) =>
    dispatch(fetchArrivalsAndDepartures(focus)),
  setArrivalDate: (date) => dispatch(setArrivalPreference(date)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccommodationInfoBlock);
