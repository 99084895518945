import { gql } from "@apollo/client";

const fragment = gql`
  fragment Instagram on SectionInstagram {
    sys {
      id
    }
    title
    urlList
    backgroundColor
  }
`;

export default fragment;
