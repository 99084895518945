import React from 'react'
import PropTypes from 'prop-types'
import cls from 'classnames'
import { withRouter } from 'next/router'
import * as localStorageManager from "../../utilities/localStorage";

const THREE_HOURS_IN_MS = 10800000;
class PopupBlock extends React.Component {
  state = { open: true }

  ref = React.createRef()

  componentDidMount = () => {
    if (!this.shouldShowAsNonBlockingWarning()) {
      document.addEventListener('mousedown', this.handleClick)
      document.getElementsByTagName('html')[0].classList.add('lock')
    }
  }

  componentWillUnmount = () => document.removeEventListener('mousedown', this.handleClick)

  handleClick = (e) => {
    if (this.ref.current.contains(e.target)) {
      return null
    }
    this.close()
  }

  handleClose = () => {
    this.close()
  }

  close = () => {
    const { name } = this.props;
    const html = document.getElementsByTagName('html')[0]
    if (html.classList.contains('lock')) {
      html.classList.remove('lock')
    }
    // Remember that the user did close the popup before.
    if(this.shouldShowAsNonBlockingWarning()) {
      localStorageManager.setWithExpiry(`${name}-closed-before`, 'closed-by-user', THREE_HOURS_IN_MS);
    }
    return this.setState({ open: false })
  }

  handleNavigate = () => {
    const {
      properties: { ctaUrl },
      router
    } = this.props
    return router.push(`${ctaUrl}`)
  }

  shouldShowAsNonBlockingWarning = () => {
    const { name } = this.props
    return name && name.startsWith('non-blocking-warning')
  }

  render() {
    const { open } = this.state
    const { name } = this.props
    const { text, buttonText, title } = this.props.properties
    const showAsNonBlockingWarning = this.shouldShowAsNonBlockingWarning()

    if(showAsNonBlockingWarning && localStorageManager.getWithExpiry(`${name}-closed-before`)) return null
    if (!open) return null
    return (
      <div
        className={cls('js-popup-block-inner', {
          'non-blocking-warning': showAsNonBlockingWarning
        })}
      >
        <div ref={this.ref} className='js-popup-holder'>
          <div className='title h5'>
            {title}
          </div>
          <button type='button' className='btn btn-absolute btn-square text-center text-white btn-close' onClick={this.handleClose}>
            <i className='material-icons'>close</i>
          </button>
          <div className='html-holder' dangerouslySetInnerHTML={{ __html: text }} />
          <button
            type='button'
            onClick={this.handleNavigate}
            className={cls('btn btn-lg h3', {
              'btn-green float-right': !showAsNonBlockingWarning,
              'btn-orange': showAsNonBlockingWarning
            })}
          >
            {buttonText}
          </button>
        </div>
      </div>
    )
  }
}

PopupBlock.propTypes = {
  properties: PropTypes.object,
  router: PropTypes.object,
  name: PropTypes.string
}

export default withRouter(PopupBlock)
