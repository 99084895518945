import React from 'react'
import PropTypes from 'prop-types'
import * as BlockMap from './BlockMap'

class Collection extends React.Component {
  static propTypes = {
    collection: PropTypes.array,
    template: PropTypes.string,
    manageTags: PropTypes.object
  };

  /**
   * Renders a single item based on its template.
   * This template should be a React Component registered in the BlockMap.
   */
  renderItem (item, i) {
    if (!item) {
      return null
    }

    const { template } = this.props
    if (!{}.hasOwnProperty.call(BlockMap, template)) {
      console.warn(`Collection template ${template} is not registered`)
      return null
    }

    const element = BlockMap[template]

    return React.createElement(element, { content: item, itemKey: i })
  }

  render () {
    const { collection, template, manageTags } = this.props
    return (
      <div {...manageTags} className={`${template || ''}`}>
        {collection && collection.map((item, i) => (
          <div className='collection-item mb-3' key={item.id}>
            {this.renderItem(item, i)}
          </div>
        ))}
      </div>
    )
  }
}

export default Collection
