export const groupValidateClasses = (touched, error) => {
  const baseClass = 'form-group row'

  if (touched && error) {
    return `${baseClass} has-danger`
  }
  if (touched && !error) {
    return `${baseClass} has-success`
  }

  return baseClass
}

export const inputValidateClasses = (touched, error) => {
  const baseClass = 'form-control'

  if (touched && error) {
    return `${baseClass} form-control-danger`
  }
  if (touched && !error) {
    return `${baseClass} form-control-success`
  }

  return baseClass
}
