import * as React from "react";
import { ErrorMessage as FormikErrorMessage } from "formik";
import styled from "styled-components/macro";
import { AlertType } from "../../Alert";
import Small from "../../typography/Small";

const ErrorComponent = styled(Small)`
  color: ${({ theme }) => theme.color.error};
`;

type ErrorMessageProps = {
  alert?: boolean;
  alertType?: AlertType;
  name: string;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ name }) => {
  return <FormikErrorMessage name={name} component={ErrorComponent} />;
};

export default ErrorMessage;
