export default {
  // Buttons
  "button.subscribe": "Aanmelden",

  // Errors
  "error.general": "Er is een fout opgetreden. Probeer het later opnieuw.",

  // Form labels
  "placeholder.email": "Jouw e-mailadres",

  // Titles
  "title.newsletter_form": "Avontuur in je mailbox?",
  "title.libema_footer":
    "De mooiste tijd beleef je bij {account}, onderdeel van Libéma",

  "success.newsletter_subscription":
    "Bedankt voor je aanmelding. Ter bevestiging hebben we jou een verificatie e-mail gestuurd.",

  // Form validators
  "validator.email_invalid": "Ongeldig e-mail adres",
  "validator.required": "Dit veld is verplicht",
};
