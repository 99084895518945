import { Button, Translate } from "@libema/design-system";
import { connectCurrentRefinements } from "react-instantsearch-dom";

const ClearRefinementsConnected = connectCurrentRefinements(
  ({ items, refine }) => {
    const onClick = () => refine(items);
    return (
      <Button
        onClick={onClick}
        disabled={!items.length}
        variant="outlined"
        size="small"
        type="button"
      >
        <Translate id="filter.buttons.clear" />
      </Button>
    );
  }
);

export default ClearRefinementsConnected;
