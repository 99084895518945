import Cookies from 'js-cookie'

export const findBlocksInTree = (tree, type) => {
  let matches = []
  if (Array.isArray(tree)) {
    tree.forEach((block) => {
      if (block.type === type) {
        matches.push({ ...block })
      }
      if (block.children) {
        matches = matches.concat(findBlocksInTree(block.children, type))
      }
    })
  }

  return matches
}

/**
 * Places all parts, separated by a comma in an array and removes all whitespaces
 *
 * @param {string} audienceString
 */
const parseAudiences = audienceString => audienceString
  .split(',')
  .map(audience => audience.replace(/\s/g, ''))

/**
 * Determine if the given block should be rendered
 * @param {object} block
 */
export const shouldRender = (block) => {
  // Avoid rendering blocks with prdct enabled on server-side
  if (block.properties && block.properties.prdct_enabled && typeof window === 'undefined') {
    return false
  }

  // Always render the block if prdct is not enabled
  if (!block.properties || !block.properties.prdct_enabled) {
    return true
  }

  const cookieAudiences = Cookies.get('prdct_audience')
  if (!cookieAudiences) {
    return false
  }

  const blockAudiences = parseAudiences(block.properties.prdct_audience)
  // See if any of the block audiences matches any of the audiences in the users' cookie
  const matchingAudience = parseAudiences(cookieAudiences)
    .find(audience => blockAudiences.includes(audience))

  return (matchingAudience !== undefined)
}
