import { createSelector } from 'reselect'
import moment from 'moment'
import { getCriteria, getReservationAdditions } from './reservationSelectors'
import { getSrc } from '../utilities/mediaUtilities'

const empty = {}
export const getAdditions = (state) => Object.values(state.entities.additions || empty)
export const getAdditionGroups = (state) => Object.values(state.entities.additionGroups || empty)
export const getAddition = (state, id) => state.entities.additions[id]
export const getAdditionGroup = (state, id) => state.entities.additionGroups[id]

export const getAllReservationAdditions = createSelector(getReservationAdditions, getAdditions, (newyseAdditions, cmsAdditions) => {
  const additionsWithGroup = cmsAdditions.filter(addition => addition.group)
  const availableAdditions = newyseAdditions.filter((newyseAddition) => additionsWithGroup.findIndex((additionWithGroup) => newyseAddition.additionResourceId === additionWithGroup.id) >= 0)
  return availableAdditions
})

export const getAdditionsByGroup = createSelector(
  getReservationAdditions,
  getAdditions,
  (_state, groupId) => groupId,
  (availableAdditions, additions, groupId) => {
    const additionArray = additions.filter((addition) => addition.group === groupId)
    const possibleAdditions = additionArray.filter((addition) => availableAdditions.findIndex((availableAddition) => availableAddition.additionResourceId === addition.id) >= 0)
    const additionInfo = possibleAdditions.map((addition) => {
      const infoIndex = availableAdditions.findIndex((availableAddition) => availableAddition.additionResourceId === addition.id)
      let startDate = moment(availableAdditions[infoIndex].startDate)
      const endDate = moment(availableAdditions[infoIndex].endDate)
      const dates = []
      while (startDate <= endDate) {
        dates.push(moment(startDate).format('YYYY-MM-DD'))
        startDate = moment(startDate).add(1, 'days')
      }
      return {
        ...addition,
        ...availableAdditions[infoIndex],
        // TODO: The image source should not be defined here. It should be up to the view component to determine the size.
        image: addition.media && getSrc(addition.media.reference, 'sm_11'),
        dates
      }
    })
    return additionInfo
  }
)

export const getProposalAdditionGroups = createSelector(
  getAdditions,
  getReservationAdditions,
  getAdditionGroups,
  (allAdditions, availableAdditions, allGroups) => {
    const availableAdditionsCms = allAdditions.filter((allAddition) => availableAdditions.findIndex((availableAddition) => availableAddition.additionResourceId === allAddition.id) >= 0)
    const groupIds = availableAdditionsCms.map(addition => addition.group)
    const groups = Array.from(new Set(allGroups.filter((group) => groupIds.includes(group.id))))

    const additionsWithGroup = allAdditions.filter(addition => addition.group)
    const availableAdditionsCMS = additionsWithGroup.filter((addition) => availableAdditions.findIndex((availableAddition) => availableAddition.additionResourceId === addition.id) >= 0)

    const groupsWithMarketingGroups = groups.map((group) => {
      const marketingGroups = availableAdditionsCMS
        .filter((addition) => addition.group === group.id && addition.marketing_group)
        .map((addition) => addition.marketing_group)
      return {
        ...group,
        marketingGroups: [...marketingGroups]
      }
    })
    return groupsWithMarketingGroups
  })

export const getEssentialAdditions = createSelector(
  getAdditions,
  getReservationAdditions,
  (allAdditions, availableAdditions) => {
    return allAdditions
      .filter(addition => addition.essential === true)
      .filter((essentialAddition) => availableAdditions.findIndex((availableAddition) => availableAddition.additionResourceId === essentialAddition.id) >= 0)
      .map((essentialAddition) => {
        const availableAddition = availableAdditions.find(addition => addition.additionResourceId === essentialAddition.id)
        return {
          ...essentialAddition,
          ...availableAddition
        }
      })
  }
)

export const getMarketingGroups = createSelector(
  getAllReservationAdditions,
  getAdditions,
  (availableAdditions, cmsAdditions) => {
    const marketingGroups = availableAdditions
      .map((newyseAddition) => {
        const cmsAddition = cmsAdditions.find((addition) => addition.id === newyseAddition.additionResourceId)
        return { ...newyseAddition, ...cmsAddition }
      })
      .filter((addition) => addition.marketing_group)
      .map((addition) => addition.marketing_group)

    // Remove duplicates
    return [...new Set(marketingGroups)]
  })

export const getInitialAdditionGroupValues = createSelector(
  getCriteria,
  getAdditionsByGroup,
  (reservationCriteria, additions) => ({
    additions: additions.reduce((group, addition) => {
      // We gather the criteria that match this specific addition
      const additionsInCriteria = reservationCriteria.additions.filter((a) => a.resource_id === addition.additionResourceId)
      const initialValue = {
        // If any criteria were found, we get the quantity of the first one, because they should all be the same.
        quantity: (additionsInCriteria.length) ? additionsInCriteria[0].quantity : null
      }
      if (addition.stock_type === 'DAY') {
        // The criteria store a separate result for each date. So we merge those dates here and filter out any undefined dates
        initialValue.dates = additionsInCriteria.map((a) => a.start_date).filter(a => a)
      }

      // Maxxton will calculate the price for the entire traveling party. The user shouldn't be able to select the
      // amount of persons manually
      if (addition.perSubject) {
        initialValue.quantity = additionsInCriteria.length > 0 ? 1 : 0;
      }

      return {
        ...group,
        // Each addition is mapped by a unique key that holds the resource id
        [`addition-${addition.additionResourceId}`]: initialValue
      }
    }, {})
  })
)

export const getAdditionGroupHasQuantity = createSelector(
  getCriteria,
  getAdditionsByGroup,
  (reservationCriteria, additions) => additions
    .findIndex((addition) => {
      const additionInCriteria = (reservationCriteria.additions || []).find((a) => a.resource_id === addition.additionResourceId)
      return additionInCriteria && additionInCriteria.quantity > 0
    }) !== -1
)
