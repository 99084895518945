import styled from "styled-components/macro";
import { darken } from "polished";
import { DEVICE_LG_UP } from "../../utils/deviceUtils";

type TabLinkProps = {
  active?: boolean;
};

export default styled.a<TabLinkProps>`
  display: block;
  padding: 0.5em;
  border: 2px solid transparent;
  font-weight: 700;
  font-size: 1.2rem;
  background-color: transparent;
  color: ${({ theme }) => theme.textColor.default};
  cursor: pointer;

  @media ${DEVICE_LG_UP} {
    font-size: 1.5rem;
    padding: 0.5em 1em;
  }

  &:hover {
    text-decoration: none;
  }

  ${({ active, theme }) =>
    active &&
    `
    border: 2px solid ${darken(0.1, theme.color.neutralLight)};
    border-bottom-color: #fff;
  `}
`;
