/**
 * Maps an array of subject options to a query parameter value
 *
 * @param {{ subject_id: string, quantity: string }[]} subjects
 * @returns string[]
 */
const toQueryParam = subjects => subjects.map(subject => `${subject.subject_id}-${subject.quantity}`)

export default {
  toQueryParam
}
