import styled from "styled-components";

const Svg = styled.svg.attrs({
  viewBox: "0 0 19.34 18.131",
  width: "1rem",
})``;

const PreferencesIcon = (props) => (
  <Svg {...props}>
    <path
      d="M19,5.272A3.022,3.022,0,1,1,15.983,2.25,3.022,3.022,0,0,1,19,5.272ZM15.983,7.085A1.813,1.813,0,1,0,14.17,5.272,1.813,1.813,0,0,0,15.983,7.085Zm-5.439,4.231A3.022,3.022,0,1,1,7.522,8.294,3.022,3.022,0,0,1,10.544,11.316ZM7.522,13.129a1.813,1.813,0,1,0-1.813-1.813A1.813,1.813,0,0,0,7.522,13.129ZM19,17.359a3.022,3.022,0,1,1-3.022-3.022A3.022,3.022,0,0,1,19,17.359Zm-3.022,1.813a1.813,1.813,0,1,0-1.813-1.813A1.813,1.813,0,0,0,15.983,19.172Z"
      transform="translate(-2.083 -2.25)"
    />
    <path
      d="M11.483,7.959H0V6.75H11.483Zm7.857,0H16.318V6.75H19.34ZM11.483,20.046H0V18.837H11.483Zm7.857,0H16.318V18.837H19.34ZM7.857,14H19.34V12.794H7.857ZM0,14H3.022V12.794H0Z"
      transform="translate(0 -4.333)"
    />
  </Svg>
);

export default PreferencesIcon;
