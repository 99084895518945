import { gql } from "@apollo/client";

import asset from "./asset";
import link from "./link";

const fragment = gql`
  fragment Activity on Activity {
    sys {
      id
    }
    title
    subtitle
    mainImage {
      ...Asset
    }
    cta {
      ...Link
    }
    cardDetails
    location {
      name
      park {
        name
      }
    }
    weekdays
    minAge
    minHeight
    duration
    minPersons
    lowestPrice
    lowestPriceRegular
    priceUnit
    detailPage {
      slug
    }
    startingTime
    priceTable {
      pricesCollection(limit: 10) {
        items {
          title
          condition
          price
        }
      }
      conditionsCollection(limit: 10) {
        items {
          icon
          condition {
            json
          }
        }
      }
    }
  }
  ${asset}
  ${link}
`;

export default fragment;
