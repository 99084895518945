export const inputHeight = (size: string) => {
  switch (size) {
    case "small":
      return "42px";
    case "large":
      return "57px";
    default:
      return "57px";
  }
};
