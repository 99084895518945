import * as React from "react";
import styled from "styled-components";
import { FaChevronLeft } from "react-icons/fa";

// Types
import {
  Program,
  Page as PageProps,
  SectionHeroDisplayStyle,
} from "@libema/content-sdk";

// Components
import { useWindowSize } from "@libema/design-system";

// Local
import Link from "../elements/Link";
import SectionsRenderer from "../renderer/SectionsRenderer";
import { Hero } from "../sections";
import ProgramDetail from "../blocks/ProgramDetail";

// Style
const HeroContent = styled.div`
  width: 100vw;
  display: flex;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  margin: 1rem;

  &,
  &:hover {
    color: #fff;
    text-decoration: none;
  }

  > svg {
    fill: #fff;
    margin-right: 0.25rem;
    margin-top: 4px;
  }
`;

type Props = PageProps<Program>;

const ProgramLayout: React.FC<Props> = ({
  topic: program,
  sections,
  parentPage,
}) => {
  const { mainImage } = program;
  const { isDesktop } = useWindowSize();

  return (
    <>
      {mainImage && !isDesktop && (
        <Hero
          media={mainImage}
          heroDisplayStyle={SectionHeroDisplayStyle.BREAKOUT}
          withDarkGradient
        >
          {parentPage && (
            <HeroContent>
              <BackLink href={parentPage.slug}>
                <FaChevronLeft />
                {parentPage.title}
              </BackLink>
            </HeroContent>
          )}
        </Hero>
      )}
      <ProgramDetail program={program} />
      <SectionsRenderer sections={sections} />
    </>
  );
};

export default ProgramLayout;
