import { ThemedStyledProps } from "styled-components/macro";
import accounts from "../accounts";
import { lighten } from "polished";

export type ButtonTheme = {
  radius: string;
  textColor: {
    [colorType: string]: string;
  };
};
export type CustomTheme = {
  color_neutral_dark?: string;
  color_neutral_medium?: string;
  color_neutral_light?: string;
  color_primary?: string;
  color_secondary?: string;
  color_info?: string;
  font_default?: string;
  text_color_default?: string;
  text_color_muted?: string;
  text_color_info?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  colors?: any;
  button?: ButtonTheme;
};

export const themeSelector = (theme: string): CustomTheme => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  return accounts[theme].theme || {};
};

const basicColors = {
  info: "#284ea7",
  error: "#b31d2b",
  success: "#28a745",
  warning: "#ffc107",
};

const shades = {
  grey_100: "#F5F3F1",
  grey_200: "#EBE8E4",
  grey_300: "#E3DFD9",
  grey_400: "#D1CFCA",
  grey_500: "#B8B6B2",
  grey_600: "#95928C",
  grey_700: "#7B7872",
  grey_800: "#635E54",
  grey_900: "#4A4742",
  grey_light_100: "#BFBFBF",
  grey_light_200: "#BABABA",
  grey_light_300: "#C4C4C4",
  grey_contrast_text: "white",
  primary_light: "red",
  primary_main: "red",
  primary_dark: "red",
  primary_contrast_text: "white",
  secondary_light: "red",
  secondary_main: "red",
  secondary_dark: "red",
  secondary_contrast_text: "white",
};

const defaultTheme = {
  color: {
    ...shades,
    dark: "#292b2c",
    neutralDark: "#a48a76", // Dark Sand
    neutralMedium: "#a08b79",
    neutralLight: "#f6f1e6", // Sand
    muted: "#8A976F",
    text_color_muted: "#AEA9A7",
    text_light: "#4B4847",
    primary: "#e84d0e", // Orange
    primary_disabled: "#fcc8b3",
    secondary: "#596831", // Green
    tertiary: "#fcce4c", // Yellow
    // tertiary: '#fcce4c',
    info: basicColors.info,
    error: basicColors.error,
    success: basicColors.success,
    warning: basicColors.warning,
    focus: "#6092ff",
    white: "#FFFFFF", // TODO: Normalize and apply color system
  },
  backgroundColor: {
    info: lighten(0.5, basicColors.info),
    error: lighten(0.4, basicColors.error),
    success: lighten(0.4, basicColors.success),
    warning: lighten(0.4, basicColors.warning),
  },
  font: {
    default: '"sofia-pro", "Century Gothic", sans-serif',
  },
  button: {
    radius: "0",
    textColor: {},
  },
  textColor: {
    default: "#292b2c", // Dark (Blackish)
    muted: "#5c6738", // Green
    info: "#284ea7",
    error: "#b31d2b",
    success: "#28a745",
    warning: "#987200",
  },
  margin: {
    sm: "0.5rem",
    md: "1rem",
    lg: "2rem",
  },
  padding: {
    sm: "0.5rem",
    md: "1rem",
    lg: "2rem",
    xl: "3rem",
  },
};

export type BaseTheme = typeof defaultTheme;

export type ThemeProps<P> = ThemedStyledProps<P, BaseTheme>;

declare module "styled-components" {
  // eslint-disable-next-line
  export interface DefaultTheme extends BaseTheme {}
}

export const getTheme = (customTheme: CustomTheme = {}): BaseTheme => {
  return {
    ...defaultTheme,
    color: {
      ...defaultTheme.color,
      ...customTheme?.colors,
      neutralDark:
        customTheme.color_neutral_dark || defaultTheme.color.neutralDark,
      neutralMedium:
        customTheme.color_neutral_medium || defaultTheme.color.neutralMedium,
      neutralLight:
        customTheme.color_neutral_light || defaultTheme.color.neutralLight,
      primary: customTheme.color_primary || defaultTheme.color.primary,
      secondary: customTheme.color_secondary || defaultTheme.color.secondary,
      info: customTheme.color_info || defaultTheme.color.info,
    },
    backgroundColor: {
      ...defaultTheme.backgroundColor,
      info: customTheme.color_info || defaultTheme.backgroundColor.info,
    },
    font: {
      default: customTheme.font_default || defaultTheme.font.default,
    },
    textColor: {
      ...defaultTheme.textColor,
      default: customTheme.text_color_default || defaultTheme.textColor.default,
      muted: customTheme.text_color_muted || defaultTheme.textColor.muted,
      info: customTheme.text_color_info || defaultTheme.textColor.info,
    },
    button: {
      radius: customTheme.button?.radius || defaultTheme.button.radius,
      textColor: {
        ...defaultTheme.button.textColor,
        ...(customTheme.button?.textColor ?? {}),
      },
    },
  };
};
