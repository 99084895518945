import { gql } from "@apollo/client";

import inlineScriptBlock from "../data/inlineScriptBlock";
import asset from "../data/asset";
import link from "../data/link";

const fragment = gql`
  fragment Teaser on SectionTeaser {
    type: __typename
    sys {
      id
    }
    title
    description {
      json
      links {
        entries {
          inline {
            ...Link
            ...InlineScriptBlock
          }
          hyperlink {
            ...Link
          }
          block {
            ...Link
          }
        }
        assets {
          hyperlink {
            ...Asset
          }
          block {
            ...Asset
          }
        }
      }
    }
    descriptionSecondary {
      json
      links {
        entries {
          inline {
            ...Link
            ...InlineScriptBlock
          }
          block {
            ...Link
          }
        }
        assets {
          block {
            ...Asset
          }
        }
      }
    }
    media {
      ...Asset
    }
    displayStyle
    backgroundColor
  }

  ${inlineScriptBlock}
  ${asset}
  ${link}
`;

export default fragment;
