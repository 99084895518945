import { connectRefinementList } from "react-instantsearch-dom";
import FilterCheckBox from "./FilterCheckBox";
import { useIntl } from "react-intl";

const FilterCheckBoxConnected = (props) => {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const { items, refine } = props;

  if (props.sortFunction) {
    items.sort(props.sortFunction);
  }

  const formattedItems = items?.map((item) => {
    const translatedLabel = t(`filter.${item.label.toLowerCase() as string}`);
    return {
      isChecked: item.isRefined,
      value: item.value,
      // If the label hasn't been translated show original label otherwise show
      // translated version.
      label: translatedLabel.startsWith("filter.")
        ? item.label
        : translatedLabel,
      count: item.count,
    };
  });

  const onClick = (value) => (event) => {
    event.preventDefault();
    refine(value);
  };

  return <FilterCheckBox items={formattedItems} onClick={onClick} />;
};

export default connectRefinementList(FilterCheckBoxConnected);
