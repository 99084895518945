// eslint-disable-next-line
// @ts-nocheck
import React from "react";
import { Formik } from "formik";
import styled from "styled-components";
import * as yup from "yup";
import { injectIntl, IntlShape } from "react-intl";
import Form from "../form/Form";
import Button from "../buttons/Button";
import H2 from "../typography/H2";
import P from "../typography/P";
import Translate from "../typography/Translate";
import AdditionGroupFormItem, {
  AdditionGroupFormItemContainer,
} from "./AdditionGroupFormItem";

const Summary = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 440px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

const Thumbnail = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 1rem;
`;

const Title = styled(H2)`
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
`;
const Body = styled.div`
  display: flex;
  padding-bottom: 1rem;

  @media (min-width: 441px) {
    padding: 1.5rem;
  }

  @media (max-width: 440px) {
    flex-direction: column;
    padding-bottom: 1.5rem;

    ${Thumbnail} {
      width: 100%;
      height: auto;
      padding-bottom: 1.5rem;
    }
  }
`;

const AdditionGroupFormItems = styled.div`
  ${AdditionGroupFormItemContainer}:nth-child(odd) {
    background: ${({ theme }) => theme.color.neutralLight};
  }
`;

const Footer = styled.div`
  padding: 1.5rem;
  display: flex;
  flex: row nowrap;
  justify-content: flex-end;
`;

const AdditionHeader = ({ title, description, image }) => (
  <Body>
    <Thumbnail src={image} />
    <Summary>
      <Title>{title}</Title>
      <P>{description}</P>
    </Summary>
  </Body>
);

type Addition = {
  id: number;
  dates?: string[];
  dayProductBookableDates?: string[];
  description?: string;
  shortDescription?: string;
  image?: string;
  minQuantity?: number;
  name?: string;
  price?: number;
  perSubject: boolean;
  priority: number;
  stock_type: "DAY" | "BOOLEAN" | "NORMAL";
};

export type AdditionGroupFormProps = {
  additions: Addition[];
  description: string;
  image: string;
  initialValues: { [name: string]: any };
  intl: IntlShape;
  maxQuantity: number;
  onSubmit: () => void;
  title: string;
};

const buildValidationSchema = (additions: Addition[], intl: IntlShape) =>
  yup.object({
    additions: yup.object(
      additions.reduce((subSchema, addition) => {
        let additionSchema = yup.object({
          quantity: yup
            .number()
            .nullable()
            .min(
              addition.minQuantity || 0,
              intl.formatMessage(
                { id: "validator.min_addition_quantity" },
                { minQuantity: addition.minQuantity || 0 }
              )
            ),
        });

        if (addition.stock_type === "DAY") {
          additionSchema = additionSchema.concat(
            yup.object({
              dates: yup.array().when("quantity", {
                is: (val) => val > 0 && !addition.perSubject,
                then: yup
                  .array()
                  .min(
                    1,
                    intl.formatMessage(
                      { id: "validator.min_addition_dates" },
                      { minQuantity: 1 }
                    )
                  ),
                otherwise: yup.array().optional(),
              }),
            })
          );
        }

        return {
          ...subSchema,
          [`addition-${addition.id}`]: additionSchema,
        };
      }, {})
    ),
  });

const AdditionGroupForm: React.FC<AdditionGroupFormProps> = ({
  onSubmit,
  initialValues,
  title,
  description,
  image,
  additions,
  maxQuantity,
  intl,
}) => {
  const hasQuantity =
    Object.values(initialValues.additions || {}).findIndex(
      (value: any) => value?.quantity > 0
    ) !== -1;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={buildValidationSchema(additions, intl)}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <AdditionHeader
            title={title}
            description={description}
            image={image}
          />
          <AdditionGroupFormItems>
            {(additions || [])
              .sort((additionA, additionB) => {
                if (additionA.priority > additionB.priority) {
                  return 1;
                } else if (additionA.priority < additionB.priority) {
                  return -1;
                }
                return 0;
              })
              .map((addition) => (
                <AdditionGroupFormItem
                  additionId={addition.id}
                  dates={addition.dayProductBookableDates}
                  selectedDates={
                    values.additions[`addition-${addition.id}`]?.dates
                  }
                  description={addition.description}
                  shortDescription={addition.shortDescription}
                  image={addition.image}
                  name={addition.name}
                  price={addition.price}
                  key={addition.id}
                  perSubject={addition.perSubject}
                  quantity={maxQuantity || 16}
                  stockType={addition.stock_type}
                />
              ))}
          </AdditionGroupFormItems>
          <Footer>
            <Button type="submit" disabled={isSubmitting}>
              <Translate id={hasQuantity ? "button.modify" : "button.add"} />
            </Button>
          </Footer>
        </Form>
      )}
    </Formik>
  );
};

export default injectIntl(AdditionGroupForm) as any;
