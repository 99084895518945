import React from 'react'
import PropTypes from 'prop-types'
import Image from '../modules/media/components/Image'

const CollectionItemOverlay = ({ content }) => {
  if (!content) return <div />

  return (
    <>
      <a href={`/${content.slug}`}>
        <div className='card-media'>
          {content.attributes.icon && (
            <div>
              {content.attributes.icon.length > 0 && (
                <ul className='animal-highlights'>
                  {content.attributes.icon.map(icon => (
                    <li className={icon.name} key={icon.name} />
                  ))}
                </ul>
              )}
            </div>
          )}

          {(content.medias.length > 0 && (
            <Image
              file={content.medias[0].reference}
              filter='sm_43'
              attr={{
                className: 'card-img-top img-fluid',
                alt: content.short_title
              }}
            />
          ))}
        </div>
        <div className='card-block'>
          <h3 className='card-header'>{content.short_title ? content.short_title : content.title}</h3>
          <p className='card-text'>{content.attributes.list_description}</p>
          <span className='btn btn-white'>{content.attributes.button_text ? content.attributes.button_text : 'Lees verder'}</span>
        </div>
      </a>
    </>
  )
}

CollectionItemOverlay.propTypes = {
  content: PropTypes.object
}

export default CollectionItemOverlay
