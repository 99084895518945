import styled from "styled-components";
import { Container as BootstrapContainer } from "styled-bootstrap-grid";
import { meetsContrastGuidelines, transparentize } from "polished";
import { DEVICE_LG_UP } from "../../utils/deviceUtils";

/* This component shouldn't be on the design system */
export const BackgroundColor = {
  WHITE: "WHITE",
  LIGHT_GREY: "LIGHT_GREY",
  GREY_100: "GREY_100",
  GREY_200: "GREY_200",
  GREY_300: "GREY_300",
  PRIMARY_LIGHT: "PRIMARY_LIGHT",
  PRIMARY_MAIN: "PRIMARY_MAIN",
  SECONDARY_LIGHT: "SECONDARY_LIGHT",
  SECONDARY_MAIN: "SECONDARY_MAIN",
};

export type WrapperProps = {
  backgroundColor?: string;
};

const contrastColor = (theme, colorPreference, defaultColor): string => {
  const colorPreferenceNorm = colorPreference?.toLowerCase() || "white";
  const guidelines = meetsContrastGuidelines(
    theme.color[colorPreferenceNorm],
    theme.textColor.default
  );
  if (guidelines.AA) {
    return defaultColor;
  }
  const colorName: string = colorPreferenceNorm.split("_")[0];
  return theme.color[`${colorName}_contrast_text`];
};

const transparentColor = (amount, color) => {
  return color === "inherit" ? color : transparentize(amount, color);
};

export const Header = styled.header`
  text-align: center;
  margin-bottom: 2rem;

  > h1,
  h2,
  h3,
  span {
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
  }
`;

export const Wrapper = styled.section<WrapperProps>`
  background-color: ${({ theme, backgroundColor }) =>
    theme.color[backgroundColor?.toLowerCase() || "white"]};
  padding: 3rem 0;
  color: ${({ theme, backgroundColor }) =>
    contrastColor(theme, backgroundColor, theme.textColor.default)};

  @media ${DEVICE_LG_UP} {
    padding: 4rem 0;
  }

  ${Header} {
    color: ${({ theme, backgroundColor }) =>
      transparentColor(
        0.15,
        contrastColor(theme, backgroundColor, theme.color.grey_700)
      )};
  }
`;
export const Container = BootstrapContainer;
