import axios from "axios";
import { post, get } from "./";

export const postReservationProposal = (data) =>
  post({
    url: "/v2/proposals",
    data,
  });

export const postReservationConfirmation = (data) =>
  post({
    url: "/v2/reservations",
    data,
  });

export const postPayment = (data) =>
  post({
    url: `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/payments`,
    data,
  });

export const getPaymentMethods = ({ brand }) =>
  get({
    url: `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v2/paymentmethods?brand=${brand}`,
  });

export const postReservationState = (data) =>
  axios.post("/api/reservations/state", data);

export const cancelReservation = (reservationId) =>
  axios.post("/api/reservations/cancel", {
    reservationId,
  });

export const getReservationState = () => {
  return axios.get("/api/reservations/state").catch((err) => {
    if (process.env.NODE_ENV !== "production") {
      console.error(err);
    }
  });
};
