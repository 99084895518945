import React from 'react'
import PropTypes from 'prop-types'
import trans from 'counterpart'
import DateInput from './DateInput'
import { groupValidateClasses, inputValidateClasses } from '../../../modules/form/utilities'

const DateField = (props) => {
  const { input, meta: { touched, error }, placeholder } = props

  return (
    <div className={groupValidateClasses(touched, error)}>
      <div className='col-lg-12'>
        <DateInput
          {...input}
          className={inputValidateClasses(touched, error)}
          placeholder={placeholder}
          value={input.value}
        />
        {touched && error && (
          <div className='form-control-feedback'>{trans('form.field_mandatory')}</div>
        )}
      </div>
    </div>
  )
}

DateField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string
}

export default DateField
