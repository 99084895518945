import React from 'react'
import PropTypes from 'prop-types'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import CircularProgress from 'material-ui/CircularProgress'
import { APP_BEEKSEBERGEN } from '../constants/apps'
/**
 * Set actual user agent for SSR:
 * https://stackoverflow.com/questions/41595912/material-ui-how-to-properly-set-useragent-for-server-side-rendering/43958563#43958563
 */
const ProgressIndicator = ({ isHidden }) => (
  <div className={`content-item-loader py-3 ${isHidden && 'hidden-xs-up'} ${process.env.NEXT_PUBLIC_APP === APP_BEEKSEBERGEN ? 'bb' : 'db'}`}>
    <div>
      <MuiThemeProvider muiTheme={getMuiTheme({ userAgent: (typeof navigator !== 'undefined' && navigator.userAgent) || 'all' })}>
        <CircularProgress color='#fff' size={120} />
      </MuiThemeProvider>
    </div>
  </div>
)

ProgressIndicator.propTypes = {
  isHidden: PropTypes.bool
}

export default ProgressIndicator
