import { Section } from "@libema/content-sdk";
import * as React from "react";
import SectionsRenderer from "../renderer/SectionsRenderer";

type Props = {
  sections: Section[];
};

const LandingPageLayout: React.FC<Props> = ({ sections }) => (
  <SectionsRenderer sections={sections} />
);

export default LandingPageLayout;
