import { FC } from "react";

// Components
import { P, Form, FilterOptions, FilterRadioItem } from "@libema/design-system";

type FilterCheckboxItemProps = {
  label: string;
  value: any; // TODO: any
  count: number;
  isChecked: boolean;
};

type Props = {
  items: FilterCheckboxItemProps[];
  // TODO: (value: string[]) => void ?
  onClick: (value: any) => any;
};

const {
  Fields: { Checkbox },
} = Form;

const FilterCheckBox: FC<Props> = ({ items, onClick }) => (
  <FilterOptions.List>
    {items?.map((item) => {
      const { label, value, count, isChecked } = item;
      return (
        <FilterRadioItem.ListItem
          key={value}
          onClick={onClick(value)}
          active={isChecked}
        >
          <FilterRadioItem.Content>
            <P>
              {label}{" "}
              <FilterRadioItem.CountLabel>({count})</FilterRadioItem.CountLabel>
            </P>
          </FilterRadioItem.Content>
          <Checkbox checked={isChecked} onChange={() => value} />
        </FilterRadioItem.ListItem>
      );
    })}
  </FilterOptions.List>
);

export default FilterCheckBox;
