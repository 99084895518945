import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Modal from 'reactstrap/lib/Modal' // Imports like this reduces build size
import ModalBody from 'reactstrap/lib/ModalBody'
import Image from '../../modules/media/components/Image'

class ImageBlock extends Component {
  state = { modal: false }

  handleToggle = () => this.setState((prevState) => ({ modal: !prevState.modal }))

  getImageFilter = () => {
    const { media, properties } = this.props

    if (media.reference.endsWith('.svg')) return 'full_size'
    if (properties.filter) return properties.filter
    // Note; Shouldn't the fallback actually be `full_size`??
    // return 'md_43';
    return 'full_size'
  }

  render = () => {
    const { media, properties } = this.props

    if (!media) return null

    return (
      <div className={`${properties.enlarge ? 'img-enlarge' : ''}`} onClick={() => this.setState({ modal: true })} {...this.props.manageTags}>
        <Image
          file={media.reference}
          filter={this.getImageFilter()}
          className={`${properties.styles && properties.styles.join(' ')} ${properties.extra_classes || ''}`}
          attr={{
            alt: media.name,
            onClick: () => this.setState({ modal: true })
          }}
        />

        {properties.enlarge && (
          <>
            <span className='material-icons'>fullscreen</span>
            <Modal isOpen={this.state.modal} toggle={this.handleToggle} className='modal-lg'>
              <ModalBody className='p-0'>
                <button type='button' className='btn btn-close' onClick={this.handleToggle}>
                  <span className='material-icons'>close</span>
                </button>
                <Image
                  file={media.reference}
                  filter={properties.enlarge_filter ? properties.enlarge_filter : 'lg_43'}
                  attr={{
                    alt: media.name,
                    className: 'img-fluid'
                  }}
                />
              </ModalBody>
            </Modal>
          </>
        )}
      </div>
    )
  }
}

ImageBlock.propTypes = {
  id: PropTypes.number,
  manageTags: PropTypes.object,
  media: PropTypes.object,
  properties: PropTypes.object,
  placeholder: PropTypes.func.isRequired
}

export default ImageBlock
