import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'next/router'
import PropTypes from 'prop-types'
import trans from 'counterpart'
import Scrollchor from 'react-scrollchor'
import Image from '../../media/components/Image'
import { setCompareProperty, returnResourcesAfterRemoval, returnResourcesAfterAdd } from '../actions'
import { MAX_COMPARE } from '../constants'
import { getAccommodationTypes } from '../../../selectors/entitySelectors'
import { ID_HOLIDAYPARK, ID_SAFARIRESORT } from '../../booking/constants'

class ComparableResources extends Component {
  getAccommodationsByResortId = (id) => {
    const { accommodationTypes } = this.props
    return Object.values(accommodationTypes).filter(accommodation => accommodation.resort.id === id)
  }

  addToCompareResources = (id) => {
    const { addToCompareList, compareListIds, setHasMaxCompare } = this.props
    if (compareListIds.length === MAX_COMPARE) {
      setHasMaxCompare(true)
      setTimeout(() => {
        setHasMaxCompare(false)
      }, 3000)
      return
    }
    const newResources = addToCompareList(id)

    this.updateUrl(newResources)
  }

  removeFromCompareResources = (id) => {
    const { removeFromCompareList } = this.props
    const newResources = removeFromCompareList(id)
    this.updateUrl(newResources)
  }

  updateUrl = (newResources) => {
    const { router } = this.props
    router.push({
      pathname: `/${router.query.slug.join('/')}`,
      query: { 'resources[]': newResources }
    }, undefined, { shallow: true })
  }

  handleOnClick = (id) => {
    const { compareListIds } = this.props
    if (compareListIds.indexOf(id) > -1) {
      return this.removeFromCompareResources(id)
    }
    return this.addToCompareResources(id)
  }

  render () {
    const { compareListIds } = this.props
    return (
      <div id='comparable-resources' className='pt-5'>
        <h2 className='text-center'>{trans('label.choose_accommodation_to_compare')}</h2>
        {process.env.NEXT_PUBLIC_APP === 'beeksebergen' && (
          <>
            <h3 className='mb-4 font-weight-normal text-center'>{trans('resort.safari_resort')}</h3>
            <div className='section-content thumbnails-md thumbnails-info'>
              <div className='row card-group card-group-md card-group-compare'>
                {this.getAccommodationsByResortId(ID_SAFARIRESORT).map(accommodation => (
                  <div className='card card-white' key={accommodation.id}>
                    <Scrollchor to='#compared-resources'>
                      <div className='compare' onClick={() => this.handleOnClick(accommodation.id)}>
                        <div className='card-media'>
                          <div className='card-media'>
                            {accommodation.images && accommodation.images.length > 0 && (
                              <Image
                                file={accommodation.images[0].reference}
                                filter='sm_43'
                                attr={{
                                  className: 'card-img img-fluid',
                                  alt: accommodation.name
                                }}
                              />
                            )}
                            {compareListIds.includes(accommodation.id) && (
                              <div className='result-selected in'><span /></div>
                            )}
                          </div>
                        </div>
                        <div className='card-block'>
                          <span className='card-header'>
                            <h4>{accommodation.display_name || accommodation.name}</h4>
                          </span>
                        </div>
                      </div>
                    </Scrollchor>
                  </div>
                ))}
              </div>
            </div>
            <h3 className='mb-4 font-weight-normal text-center'>{trans('resort.lake_resort')}</h3>
            <div className='section-content thumbnails-md thumbnails-info'>
              <div className='row card-group card-group-md card-group-compare'>
                {this.getAccommodationsByResortId(ID_HOLIDAYPARK).map(accommodation => (
                  <div className='card card-white' key={accommodation.id}>
                    <Scrollchor to='#compared-resources'>
                      <div className='compare' onClick={() => this.handleOnClick(accommodation.id)}>
                        <div className='card-media'>
                          {accommodation.images && accommodation.images.length > 0 && (
                            <Image
                              file={accommodation.images[0].reference}
                              filter='sm_43'
                              attr={{
                                className: 'card-img img-fluid',
                                alt: accommodation.name
                              }}
                            />
                          )}
                          {compareListIds.includes(accommodation.id) && (
                            <div className='result-selected in'><span /></div>
                          )}
                        </div>
                        <div className='card-block'>
                          <span className='card-header'>
                            <h4>{accommodation.display_name || accommodation.name}</h4>
                          </span>
                        </div>
                      </div>
                    </Scrollchor>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {process.env.NEXT_PUBLIC_APP === 'dierenbos' && (
          <>
            <div className='section-content thumbnails-md thumbnails-info'>
              <div className='row card-group card-group-md card-group-compare'>
                {this.getAccommodationsByResortId(22).map(accommodation => (
                  <div className='card card-white' key={accommodation.id}>
                    <Scrollchor to='#compared-resources'>
                      <div className='compare' onClick={() => this.handleOnClick(accommodation.id)}>
                        <div className='card-media'>
                          <div className='card-media'>
                            {accommodation.images && accommodation.images.length > 0 && (
                              <Image
                                file={accommodation.images[0].reference}
                                filter='sm_43'
                                attr={{
                                  className: 'card-img img-fluid',
                                  alt: accommodation.name
                                }}
                              />
                            )}
                            {compareListIds.includes(accommodation.id) && (
                              <div className='result-selected in'><span /></div>
                            )}
                          </div>
                        </div>
                        <div className='card-block'>
                          <span className='card-header'>
                            <h4>{accommodation.display_name || accommodation.name}</h4>
                          </span>
                        </div>
                      </div>
                    </Scrollchor>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

ComparableResources.propTypes = {
  accommodationTypes: PropTypes.object,
  addToCompareList: PropTypes.func,
  compareListIds: PropTypes.array,
  router: PropTypes.object.isRequired,
  removeFromCompareList: PropTypes.func,
  setHasMaxCompare: PropTypes.func
}

const mapStateToProps = state => ({
  accommodationTypes: getAccommodationTypes(state),
  compareListIds: state.compare.compareListIds
})

const mapDispatchToProps = dispatch => ({
  addToCompareList: id => dispatch(returnResourcesAfterAdd(id)),
  removeFromCompareList: id => dispatch(returnResourcesAfterRemoval(id)),
  setHasMaxCompare: bool => dispatch(setCompareProperty('hasMaxCompare', bool)),
  setHasIdInList: bool => dispatch(setCompareProperty('hasIdInList', bool))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ComparableResources))
