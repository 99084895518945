import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { InstantSearch, SearchBox, Configure } from "react-instantsearch-dom";
import { useRouter } from "next/router";

// Types
import { SectionProgramOverview, Filter } from "@libema/content-sdk";

// Components
import {
  Button,
  Col,
  FilterGroup,
  P,
  Translate,
  H1,
} from "@libema/design-system";
import useUrlRouting from "../../hooks/useUrlRouting";
import {
  ClearRefinementsConnected,
  FilterButtonConnected,
  FilterModalCloseButtonConnected,
  FilterPanel,
  Hits,
} from "../blocks";
import { FilterProvider } from "../blocks/Filters";
import * as Filters from "../blocks/Filters";
import * as Styles from "./style";
import RichTextRenderer from "../renderer/RichTextRenderer";
import {
  DEFAULT_PAGE_SIZE,
  getProgramIndexName,
  getSearchClient,
} from "../sectionUtils";
import DetailsCard from "../blocks/DetailsCard/DetailsCard";
import styled from "styled-components";

const searchClient = getSearchClient();

const StyledProgramOverview = styled(Styles.StyledSection)`
  ${Button} {
    align-self: flex-start;
  }
`;

const ProgramOverview: FC<SectionProgramOverview> = ({
  systemTagFilter,
  filters,
  backgroundColor,
  pageSize = DEFAULT_PAGE_SIZE,
  title,
  subtitle,
  description,
  programs,
}) => {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const { locale = "nl" } = useRouter();
  const programIndexName = getProgramIndexName(locale);

  // TODO: disabled for now since it's breaking the search box
  // const [searchState, onSearchStateChange, createURL] = useUrlRouting();

  // Only show activities added to the activities list on Contentful. If none provided,
  // show all activities from algolia.
  const programIdFilter = useMemo(() => {
    if (programs?.length === 0) return null;
    return programs.map(({ id }) => `id:${id}`).join(" OR ");
  }, [programs]);

  return (
    <FilterProvider.Provider>
      <StyledProgramOverview backgroundColor={backgroundColor}>
        <div className="container">
          <InstantSearch
            indexName={programIndexName}
            searchClient={searchClient}
            // searchState={searchState}
            // onSearchStateChange={onSearchStateChange}
            // createURL={createURL}
          >
            {systemTagFilter != null ? (
              <Configure
                hitsPerPage={pageSize}
                filters={`systemTags:${systemTagFilter} ${
                  programIdFilter ? "AND " + programIdFilter : ""
                }`}
              />
            ) : (
              <Configure hitsPerPage={8} />
            )}

            <FilterPanel>
              <div>
                {filters?.map((filter) => {
                  const { id, title, filterType } = filter;
                  const Filter = Filters[filterType] as React.FC<Filter> | null;

                  if (!Filter)
                    return (
                      <P key={`filter-${filterType}-${id}-not-suported`}>
                        <Translate
                          id="filter.error.not_supported"
                          values={{ filterType }}
                        />
                      </P>
                    );
                  return (
                    <Styles.FilterFieldset key={id}>
                      <FilterGroup.Legend>{title}</FilterGroup.Legend>
                      <Filter {...filter} />
                    </Styles.FilterFieldset>
                  );
                })}
                <ClearRefinementsConnected />
                <FilterModalCloseButtonConnected />
              </div>
            </FilterPanel>

            <Styles.MainPanel>
              <Col>
                <H1>{title}</H1>
                {subtitle && <P>{subtitle}</P>}
                <RichTextRenderer text={description} />
              </Col>
              <Styles.FilterHeader>
                <div className="search">
                  <Styles.StyledSearchBox>
                    <SearchBox
                      translations={{
                        placeholder: t("filter.searchbox.placeholder"),
                      }}
                    />
                  </Styles.StyledSearchBox>
                </div>
                <div className="options">
                  <div className="fullscren-mode" />
                  <div className="sort" />
                  <FilterButtonConnected />
                </div>
              </Styles.FilterHeader>
              <Hits displayMode="Grid">
                {({ hits }) => {
                  let sortedPrograms = hits;

                  // sort programs in the same order they are defined in the CMS
                  if (programIdFilter && sortedPrograms.length > 0) {
                    sortedPrograms = programs
                      .map((program) => hits.find((p) => p.id === program.id))
                      .filter((p) => p);
                  }

                  return sortedPrograms.map((program) => (
                    <Col key={program.id} md={6} xl={4}>
                      <DetailsCard
                        {...program}
                        locale={locale}
                        cardName="Program"
                        isVertical
                        location={program.location}
                      />
                    </Col>
                  ));
                }}
              </Hits>
            </Styles.MainPanel>
          </InstantSearch>
        </div>
      </StyledProgramOverview>
    </FilterProvider.Provider>
  );
};

export default ProgramOverview;
