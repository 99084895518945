import { FC, useEffect, useState } from "react";
import Button, { Props as ButtonProps } from "./Button";

type Props = {
  className?: string;
  url: string;
  text?: string;
  title?: string;
  onSuccess?: () => void;
  onError?: (reason) => void;
  buttonProps?: ButtonProps;
};

const noop = () => undefined;

const ShareButton: FC<Props> = ({
  children,
  url,
  text,
  title,
  onSuccess = noop,
  onError = noop,
  buttonProps,
  className,
}) => {
  const [navigator, setNavigator] = useState<Navigator | undefined>(undefined);

  const toShare = { title, text, url };

  useEffect(() => {
    if (!navigator && window?.navigator) setNavigator(window.navigator);
  }, [navigator]);

  const onShare = () => {
    if (navigator?.share) {
      navigator.share(toShare).then(onSuccess).catch(onError);
    }
  };

  if (!navigator?.share) {
    return null;
  }

  return (
    <Button {...buttonProps} className={className} onClick={onShare}>
      {children}
    </Button>
  );
};

export default ShareButton;
