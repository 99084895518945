import trans from 'counterpart'
import rcsValidate from '../../utilities/validate'

const validate = (values, props) => {
  const errors = rcsValidate(values, props)

  // Validate the email confirmation
  if (values.email_2 !== values.email) {
    errors.email_2 = trans('form.field_email_2_error')
  }

  return errors
}

export default validate
