import React, { useState, useCallback } from "react";
import { captureException } from "@sentry/nextjs";
import { useRouter } from "next/router";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import axios from "axios";
import styled from "styled-components";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

// Types
import { SectionForm, FormField, FormFieldType } from "@libema/content-sdk";

// Components
import {
  Section,
  H2,
  Container,
  Form,
  Button,
  Translate,
  AlertType,
  Alert,
} from "@libema/design-system";
import RichTextRenderer from "../renderer/RichTextRenderer";
import RichText from "../blocks/RichText";

const StyledAlert = styled(Alert)`
  margin-bottom: 1rem;
`;

const StyledRichText = styled(RichText)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RadioList = styled.div`
  display: flex;
  align-items: center;
`;

const RadioLabel = styled.label`
  margin-left: 0.5rem;
`;

const getQueryParameter = (name): string | null => {
  if (typeof window === "undefined") {
    return null;
  }

  const urlParams = new URLSearchParams((window as Window).location.search);
  return urlParams.get(name);
};

const HiddenInputGroup = ({ field }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const selligentParameter = getQueryParameter(field.fieldName);

  return (
    <Form.Input
      {...register(field.fieldName, { required: field.required })}
      {...(selligentParameter ? { value: selligentParameter } : {})}
      type={field.fieldType === FormFieldType.SELLIGENT_ID ? "hidden" : "text"}
      error={errors[field.fieldName]}
      placeholder={field.placeholder}
    />
  );
};

const RadioGroup = ({ field }) => {
  const { register } = useFormContext();

  return (
    <Form.Group>
      <Form.Label required={field.required}>{field.label}</Form.Label>
      {field.values?.map((value) => (
        <RadioList>
          <input
            {...register(field.fieldName, { required: field.required })}
            type="radio"
            id={value}
            name={field.fieldName}
            value={value}
            placeholder={field.placeholder}
          />
          <RadioLabel htmlFor={value}>{value}</RadioLabel>
        </RadioList>
      ))}
    </Form.Group>
  );
};

const DefaultGroup = ({ field }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const FieldComponent = FIELD_TYPES[field.fieldType];

  return (
    <Form.Group>
      <Form.Label required={field.required}>{field.label}</Form.Label>
      <FieldComponent
        {...register(field.fieldName, { required: field.required })}
        error={errors[field.fieldName]}
        placeholder={field.placeholder}
      />
    </Form.Group>
  );
};

const FIELD_TYPES = {
  [FormFieldType.TEXT]: Form.Input,
  [FormFieldType.EMAIL]: Form.Email,
  [FormFieldType.TEL]: Form.Tel,
  [FormFieldType.TEXTAREA]: Form.TextArea,
};

const GROUP_TYPES = {
  [FormFieldType.TEXT]: DefaultGroup,
  [FormFieldType.EMAIL]: DefaultGroup,
  [FormFieldType.TEL]: DefaultGroup,
  [FormFieldType.TEXTAREA]: DefaultGroup,
  [FormFieldType.CHECKBOX]: DefaultGroup,
  [FormFieldType.RADIO]: RadioGroup,
  [FormFieldType.SELLIGENT_ID]: HiddenInputGroup,
};

enum SubmitStatus {
  READY,
  SUCCESS,
  ERROR,
}

const FormSection: React.FC<SectionForm> = ({
  title,
  destination,
  recaptcha,
  successText,
  errorText,
  submitButtonText,
  backgroundColor,
  fields,
}) => {
  const methods = useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>(
    SubmitStatus.READY
  );

  const verifyRecaptcha = useCallback(
    async (token: string) =>
      await axios
        .post("/api/form/verify-recaptcha", {
          token,
        })
        .then((res) => res.data),
    []
  );

  const submitForm = useCallback(
    async (data): Promise<void> => {
      return await axios
        .post(`/api/form/${destination}`, data)
        .then(() => setSubmitStatus(SubmitStatus.SUCCESS))
        .catch(() => setSubmitStatus(SubmitStatus.ERROR));
    },
    [destination]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        if (recaptcha) {
          if (!executeRecaptcha) return;

          // Action name must be A-Za-z_ only
          executeRecaptcha(`Submit_Form_${destination}`)
            .then(async (token) => {
              const verified = await verifyRecaptcha(token);
              if (verified) {
                return await submitForm(data);
              } else {
                return setSubmitStatus(SubmitStatus.ERROR);
              }
            })
            .catch((err) => err);
        } else {
          return submitForm(data);
        }
      } catch (e) {
        captureException(e);
        setSubmitStatus(SubmitStatus.ERROR);
      }
    },
    [executeRecaptcha, submitForm, destination, recaptcha, verifyRecaptcha]
  );

  return (
    <Section.Wrapper backgroundColor={backgroundColor}>
      {title && (
        <Section.Header>
          <H2>{title}</H2>
        </Section.Header>
      )}
      <Container>
        {submitStatus === SubmitStatus.SUCCESS && (
          <StyledRichText>
            <RichTextRenderer text={successText} />
          </StyledRichText>
        )}
        {submitStatus !== SubmitStatus.SUCCESS && (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {fields.map((field) => {
                const FieldGroup = GROUP_TYPES[field.fieldType];
                return <FieldGroup key={field.fieldName} field={field} />;
              })}
              {submitStatus === SubmitStatus.ERROR && (
                <StyledAlert type={AlertType.ERROR}>
                  {errorText && <RichTextRenderer text={errorText} />}
                  {!errorText && <Translate id="error.general" />}
                </StyledAlert>
              )}
              <Button colorType="primary" type="submit">
                {submitButtonText || <Translate id="formsection.button.send" />}
              </Button>
            </form>
          </FormProvider>
        )}
      </Container>
    </Section.Wrapper>
  );
};

const FormWrapper: React.FC<SectionForm> = (props) => {
  const { locale = "nl" } = useRouter();
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      language={locale}
    >
      <FormSection {...props} />
    </GoogleReCaptchaProvider>
  );
};

export default FormWrapper;
