import { captureException } from "@sentry/nextjs";
import trans from "counterpart";
import { withRouter } from "next/router";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, getFormSyncErrors, reduxForm } from "redux-form";
import {
  getForm,
  postFormPost,
  postMaileonForm,
} from "../../../utilities/api/formApi";
import { postSubscriptions } from "../../../utilities/api/subscriptionApi";
import validate from "../../reservation/validate";
import CheckListField from "./CheckListField";
import RadioButtonsField from "./RadioButtonsField";
import SelectField from "./SelectField";
import TextField from "./TextField";
import TextareaField from "./TextareaField";

const isRequired = (value) =>
  value || typeof value === "number"
    ? undefined
    : trans("form.field_mandatory");
const isValidEmail = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ? trans("form.field_email_invalid")
    : undefined;
const isHiddenField = (field) => {
  return field.title && /^hidden$/i.test(field.title);
};

class FormBlock extends Component {
  state = {
    fields: {},
    message: null,
  };

  componentDidMount = () => {
    if (this.isRemoteForm()) {
      getForm(this.props.block.form.id).then((response) => {
        const formData = response.data;
        this.setState({
          fields: formData,
        });
        this.setHiddenValues(formData);
      });
    }
    if (this.props.form === "customer" && this.props.customer) {
      this.props.initialize(this.props.customer);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      !prevProps.invalid &&
      this.props.invalid &&
      this.props.form === "customer" &&
      this.props.triggerSubmit
    ) {
      this.props.touch(
        this.props.form,
        ...Object.keys(this.props.formSyncErrors)
      );
    }
  };

  setHiddenValues = (formFields) => {
    Object.keys(formFields).forEach((fieldName) => {
      const field = formFields[fieldName];
      if (isHiddenField(field)) {
        const hiddenValue = field.attr?.help_text;
        this.props.change(fieldName, hiddenValue);
      }
    });
  };

  getCSRFToken = () =>
    this.state.fields._token && this.state.fields._token.value;

  isRemoteForm = () => this.props.block.form && this.props.block.form.id;
  isMaileonTransactionForm = () => this.state.fields["type"] != null;
  isMaileonNewsletterForm = () => this.state.fields["nieuwsbrief"] != null;

  prepareFormData = (values) => {
    return {
      ...Object.keys(values).reduce(
        (prev, cur) => ({
          ...prev,
          [cur]: {
            [Object.keys(this.state.fields[cur].properties)[0]]: values[cur],
          },
        }),
        {}
      ),
      _token: this.getCSRFToken(),
    };
  };

  handleMaileonTransaction = async (values) => {
    const response = await postMaileonForm(values);
    this.setState({ message: "Success" });
  };

  handleMaileonNewsletter = async (values) => {
    await postSubscriptions(values);
    this.setState({ message: "Success" });
  };

  submitForm = async (values) => {
    this.setState({ message: null });

    try {
      if (this.isMaileonTransactionForm()) {
        await this.handleMaileonTransaction(values);
      } else if (this.isMaileonNewsletterForm()) {
        await this.handleMaileonNewsletter(values);
      } else if (this.isRemoteForm()) {
        const formId = this.props.block.form.id;
        const formData = this.prepareFormData(values);

        const {
          data: { message, confirmation_page: confirmationPage },
        } = await postFormPost(formId, formData);

        if (confirmationPage) {
          this.props.router.push(confirmationPage);
        } else {
          this.setState({ message: message ?? "Success" });
        }

        return true;
      }
    } catch (err) {
      const hasConflictError = err?.response?.status === 409;
      if (hasConflictError) {
        this.setState({ message: "Conflict" });
      } else this.setState({ message: "Error" });
      captureException(err);
    }
  };

  renderField = (name) => {
    const field = this.state.fields[name];
    const required = typeof field.required !== "undefined";
    const helpText = field.attr?.help_text;
    if (name === "_token") return null;

    if (field.properties && field.properties.options) {
      const widget = field.properties.options.widget;
      let propertiesPath = field.properties.options;

      if (field.properties.options.items) {
        propertiesPath = field.properties.options.items;
      }

      switch (widget) {
        case "choice-expanded":
          return (
            <Field
              key={name}
              name={name}
              component={RadioButtonsField}
              label={field.title}
              validate={required ? isRequired : undefined}
              required={required}
              helpText={helpText}
              options={propertiesPath.enum.map((id, i) => ({
                key: id,
                value: propertiesPath.enum_titles[i],
              }))}
            />
          );
        case "choice-multiple-expanded":
          return (
            <Field
              key={name}
              name={name}
              component={CheckListField}
              label={field.title}
              validate={required ? isRequired : undefined}
              required={required}
              helpText={helpText}
              options={propertiesPath.enum.map((id, i) => ({
                key: id,
                value: propertiesPath.enum_titles[i],
              }))}
            />
          );
        default:
          return (
            <Field
              key={name}
              name={name}
              component={SelectField}
              label={propertiesPath.title}
              validate={required ? isRequired : undefined}
              required={required}
              helpText={helpText}
              options={propertiesPath.enum.map((id, i) => ({
                key: id,
                value: propertiesPath.enum_titles[i],
              }))}
            />
          );
      }
    }

    if (field.properties && field.properties.value) {
      let widget = field.properties.value.widget;
      if (/hidden/i.test(field.title)) {
        widget = "hidden";
      }

      switch (widget) {
        case "hidden": {
          return (
            <Field key={name} name={name} component="text" type="hidden" />
          );
        }
        case "textarea": {
          return (
            <Field
              key={name}
              name={name}
              component={TextareaField}
              label={field.title}
              validate={required ? isRequired : undefined}
              required={required}
              helpText={helpText}
            />
          );
        }
        default: {
          const validators = [];
          if (required) validators.push(isRequired);
          if (field.properties.value.widget === "email")
            validators.push(isValidEmail);
          return (
            <Field
              key={name}
              name={name}
              type={field.properties.value.widget}
              component={TextField}
              label={field.title}
              validate={validators}
              required={required}
              helpText={helpText}
            />
          );
        }
      }
    }

    return (
      <Field
        key={name}
        name={name}
        component={TextField}
        label={field.title}
        validate={required ? isRequired : undefined}
        required={required}
        helpText={helpText}
      />
    );
  };

  render() {
    const { pristine, placeholder, handleSubmit, submitting, manageTags } =
      this.props;

    if (!this.isRemoteForm()) {
      return <form onSubmit={handleSubmit}>{placeholder(0)}</form>;
    }

    return (
      <section className="form-holder p-t-sm p-b-lg" {...manageTags}>
        <div className="container">
          <Form
            onSubmit={handleSubmit(this.submitForm)}
            className="contact-form form-horizontal"
          >
            {Object.keys(this.state.fields).map((field) =>
              this.renderField(field)
            )}
            <div className="form-group">
              <div className="col-md-12 pointer-events">
                <span className="req">
                  <bdi>*</bdi>
                  {trans("form.fields_required")}
                </span>
                <button
                  type="submit"
                  id="form-send"
                  className={`btn btn-send btn-lg btn-primary-color btn-submit ${
                    pristine || submitting || this.state.message === "Success"
                      ? "disabled"
                      : ""
                  }`}
                  disabled={
                    pristine || submitting || this.state.message === "Success"
                  }
                >
                  {trans("button.submit")}
                </button>
              </div>
            </div>
          </Form>
          {this.state.message === "Success" && (
            <div className="alert alert-success">
              {trans("alert.successful")}
            </div>
          )}
          {this.state.message === "Conflict" && (
            <div className="alert alert-success">
              {trans("alert.newsletter_subscription_conflict")}
            </div>
          )}
          {this.state.message === "Error" && (
            <div className="alert alert-danger">
              {trans("alert.newsletter_subscription_unsuccessful")}
            </div>
          )}
        </div>
      </section>
    );
  }
}

FormBlock.propTypes = {
  block: PropTypes.object,
  handleSubmit: PropTypes.func,
  router: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  placeholder: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  touch: PropTypes.func,
  triggerSubmit: PropTypes.bool,
  formSyncErrors: PropTypes.object,
  invalid: PropTypes.bool,
  form: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
  form: ownProps.name || "default",
  formSyncErrors: getFormSyncErrors(ownProps.name || "default")(state),
  customer: state.reservation.criteria.customer,
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate,
    // initialValues: { newsletter_optin: true },
    onSubmit: () => {
      // This prop needs to be added to support remote validation by triggering redux-form's `submit` action.
      // TODO: Allow defining a custom onSubmit action from CMS.
    },
  })
)(withRouter(FormBlock));
