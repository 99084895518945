
const handleSectionView = (section) => {
  let pageUrl;
  let pageTitle;
  switch (section.name) {
    case "stay_section": {
      pageUrl = `${window.location.origin}/boeking/je-verblijf${window.location.search}`;
      pageTitle = "Boeking - Je verblijf";
      break;
    }
    case "addition_section": {
      pageUrl = `${window.location.origin}/boeking/bij-te-boeken-extras${window.location.search}`;
      pageTitle = "Boeking - Bij te boeken extras";
      break;
    }
    case "personal_details_section": {
      pageUrl = `${window.location.origin}/boeking/persoonsgegevens${window.location.search}`;
      pageTitle = "Boeking - Persoonsgegevens";
      break;
    }
    default: {
      break;
    }
  }
  if (pageUrl && pageTitle) {
    window.dataLayer.push({
      event: "virtual_page_view",
      pageUrl,
      pageTitle,
    });
  }
};

const handlePaymentConfirmationView = () => {
  window.dataLayer.push({
    event: "virtual_page_view",
    pageUrl: `${window.location.origin}/boeking/betalingsbevestiging${window.location.search}`,
    pageTitle: "Boeking - Bevestiging",
  });
};

const handleReservationPaymentView = () => {
  window.dataLayer.push({
    event: "virtual_page_view",
    pageUrl: `${window.location.origin}/boeking/betaling${window.location.search}`,
    pageTitle: "Boeking - Betaling",
  });
};

const handleAccommodationView = ({ id, startDate, endDate, price }) => {
  if (!id && price == null) {
    return;
  }

  window.dataLayer.push({
    event: "view_item",
    value: price,
    items: [
      {
        id,
        start_date: startDate,
        end_date: endDate,
        google_business_vertical: "hotel_rental",
      },
    ],
  });
};

export default {
  handlePaymentConfirmationView,
  handleReservationPaymentView,
  handleSectionView,
  handleAccommodationView,
};
