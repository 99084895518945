import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import NextLink from 'next/link'
import { getRedirects } from '../../selectors/entitySelectors'

const matchRedirect = (redirects = {}, to) => {
  if (!to || !to.startsWith('/')) {
    return null
  }

  let slug = to
  if (to.includes('?')) {
    slug = to.substr(0, to.indexOf('?'))
  }

  return redirects[slug]
}

const Link = ({ to, children, dispatch, locale, ...restProps }) => {
  if (to && to.startsWith('http')) {
    // If the url starts with `http` we use an `<a>` element for external routing
    return (
      <a href={to} {...restProps}>
        {children}
      </a>
    )
  }

  const { className, target, disabled } = restProps

  let prefix = ''
  // If the route is internal and the url is not prefixed with a slash, we add it here
  if (to && !to.startsWith('/')) prefix = '/'

  return (
    <NextLink
      // hrefLang={hrefLang || trans('locale')}
      target={target}
      href={`${prefix}${to}`}
      passHref
      {...(locale ? { locale } : null)}
    >
      {/*
        We set an <a> element inside the Link component to be able to pass classnames.
        See https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-custom-component-that-wraps-an-a-tag
      */}
      <a className={`${className} ${disabled ? 'disabled' : ''}`}>
        {children}
      </a>
    </NextLink>
  )
}

Link.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  hrefLang: PropTypes.string,
  target: PropTypes.string,
  to: PropTypes.string,
  disabled: PropTypes.bool
}

const mapState = (state, ownProps) => {
  const redirects = getRedirects(state)
  const redirect = matchRedirect(redirects, ownProps.to)

  return {
    to: redirect ? redirect.target : ownProps.to
  }
}

export default connect(mapState)(Link)
