import { connectCurrentRefinements } from "react-instantsearch-dom";
import * as FilterProvider from "../../blocks/Filters/FilterProvider";
import FilterButton from "./FilterButton";

const FilterButtonConnected = ({ items }) => {
  const { openFilterModal } = FilterProvider.useFilters();
  const count = items.length;

  return <FilterButton onClick={openFilterModal} count={count} />;
};

export default connectCurrentRefinements(FilterButtonConnected);
