import BaseSection from "./BaseSection";
import RichText from "./RichText";
import TYPES from "../../../constants/types";

export enum FormFieldType {
  TEXT = "TEXT",
  EMAIL = "EMAIL",
  TEL = "TEL",
  TEXTAREA = "TEXTAREA",
  RADIO = "RADIO",
  SELECT = "SELECT",
  CHECKBOX = "CHECKBOX",
  SELLIGENT_ID = "SELLIGENT_ID",
}

export enum FormType {
  SELLIGENT = "SELLIGENT",
}

export type FormField = {
  fieldType: FormFieldType;
  fieldName: string;
  label: string;
  required: boolean;
  values?: string[];
  placeholder?: string;
};

type SectionForm = BaseSection<TYPES.SectionForm> & {
  formType: FormType;
  title?: string;
  destination: string;
  successText?: RichText;
  errorText?: RichText;
  submitButtonText?: string;
  fields: FormField[];
  recaptcha: boolean;
};

export default SectionForm;
