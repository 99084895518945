import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const ColumnBlock = ({ block, properties, placeholder, manageTags }) => {
  let spanClasses = []
  if (properties && properties.spans) {
    Object.keys(properties.spans).forEach((size) => {
      Object.keys(properties.spans[size]).forEach((col) => {
        if (spanClasses[col] === undefined) {
          spanClasses[col] = []
        }
        spanClasses[col].push(`col-${size}-${properties.spans[size][col]}`)
      })
    })
  } else {
    spanClasses = [...Array(block.column_count).keys()].fill(`col-xs-${12 / block.column_count}`)
  }

  const gutterClasses = []
  if (properties && properties.gutters) {
    Object.keys(properties.gutters).forEach((size) => {
      Object.keys(properties.gutters[size]).forEach((col) => {
        if (gutterClasses[col] === undefined) {
          gutterClasses[col] = []
        }

        gutterClasses[col].push(`px-${size}-${properties.gutters[size][col]}`)
      })
    })
  }

  const offsetClasses = []
  if (properties && properties.offsets) {
    Object.keys(properties.offsets).forEach((size) => {
      Object.keys(properties.offsets[size]).forEach((col) => {
        if (offsetClasses[col] === undefined) {
          offsetClasses[col] = []
        }

        offsetClasses[col].push(`offset-${size}-${properties.offsets[size][col]}`)
      })
    })
  }

  const columns = [...Array(block.column_count)].map((_, index) => ({
    className: classNames(spanClasses[index], gutterClasses[index] || [], offsetClasses[index] || [])
  }))

  // &anchor added col-sm-12 as default because Speelland had className not passing through correctly.
  return (
    <div
      {...manageTags}
      id={block.properties.id}
      className={`row ${block.properties.extra_classes ? block.properties.extra_classes : ''} ${block.properties.styles ? `${block.properties.styles.join(' ')}` : ''}`}
    >
      {columns.map((column, i) => (
        <div
          key={i} // eslint-disable-line react/no-array-index-key
          className={column.className || 'col-sm-12'}
        >
          {placeholder(i)}
        </div>
      ))}
    </div>
  )
}

ColumnBlock.propTypes = {
  block: PropTypes.object.isRequired,
  manageTags: PropTypes.object,
  placeholder: PropTypes.func.isRequired
}

export default ColumnBlock
