import styled from "styled-components";

export const Item = styled.details`
  border-top: 2px solid ${({ theme }) => theme.color.neutralMedium};

  &:last-child {
    border-bottom: 2px solid ${({ theme }) => theme.color.neutralMedium};
  }

  summary:after {
    content: "";
    position: absolute;
    right: 1rem;
    top: 1.2rem;
    width: 1rem;
    height: 1rem;
    border: solid ${({ theme }) => theme.color.neutralMedium};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  summary:hover:after {
    border-color: ${({ theme }) => theme.color.neutralDark};
  }

  &[open] summary:after {
    transform: rotate(225deg);
  }
`;

export const Header = styled.summary`
  position: relative;
  display: flex;
  padding: ${({ theme }) => `${theme.padding.md} 4rem ${theme.padding.md} 0`};
  cursor: pointer;
  color: ${({ theme }) => theme.color.secondary};

  h1,
  h2,
  h3,
  h4,
  p,
  span {
    margin: 0;
    font-weight: 600;
    font-size: 1.3rem;
  }

  &::-webkit-details-marker {
    display: none;
  }
`;

export const Body = styled.div`
  text-align: left;
  padding: ${({ theme }) => ` 0 0 ${theme.padding.md} 0`};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export default Wrapper;
