import { actionTypes, updateSyncErrors } from 'redux-form'
import { HYDRATE } from 'next-redux-wrapper'
import trans from 'counterpart'
import * as actions from '../../redux/actions'
import * as t from './actionTypes'
import { createReservationProposal, initializeSections, getAvailableProperties, setReservationCriteria } from './actions'
import { createAddition, updateAdditions, updatePreferences } from './utilities'
import analyticsUtils from '../../utilities/analyticsUtils'
import { postReservationState } from '../../utilities/api/reservationApi'

/**
 * Update the reservation proposal with the updated addition preferences
 * @deprecated. Use the AdditionOverviewBlock instead
 */
const handleAdditionChange = (action, store) => {
  const field = action.meta.field.split('-')
  if (field[0] !== 'addition') {
    return
  }

  const { reservation: { criteria } } = store.getState()
  const data = { ...criteria }
  data.additions = updateAdditions(createAddition(field[1], action.payload, criteria), data.additions)

  store.dispatch(setReservationCriteria({ additions: data.additions }))

  createReservationProposal(data)(store.dispatch, store.getState)
}

/**
 * Update the reservation proposal with the updated preference preferences
 *
 * @param {object} action
 * @param {object} store
 */
const handlePreferenceChange = (action, store) => {
  const [field, type, id] = action.meta.field.split('-')
  if (field !== 'preference') {
    return
  }

  store.dispatch(updateSyncErrors(action.meta.form, { [action.meta.field]: null }))

  const { reservation: { criteria } } = store.getState()
  criteria.preferences = updatePreferences(id, type, action.payload, criteria.preferences)

  getAvailableProperties(criteria)(store.dispatch, store.getState)
  createReservationProposal(criteria, { throwError: true })(store.dispatch, store.getState).catch(() => {
    store.dispatch(
      updateSyncErrors(action.meta.form, {
        [action.meta.field]: trans('error.form.preference_not_available')
      })
    )
  })
}

// Executed when a form value changes
const handleFormChanges = (action, store) => {
  switch (action.meta.form.split('-')[0]) {
    case 'addition':
      handleAdditionChange(action, store)
      break
    case 'preferences':
      handlePreferenceChange(action, store)
      break
    default:
      break
  }
}

export const reservationEvents = store => next => (action) => {
  if (action.type === actionTypes.CHANGE) {
    handleFormChanges(action, store)
  }

  if (action.type === actions.POST_LOAD_CONTENT) {
    initializeSections(action.content)(store.dispatch, store.getState)
  }

  if (action.type === t.SET_CURRENT_SECTION) {
    analyticsUtils.handleSectionView(action.current)
  }
  if (action.type === t.SET_NEXT_SECTION) {
    const next = store.getState().reservation.sections.next
    if (next) {
      analyticsUtils.handleSectionView(next)
    }
  }

  return next(action)
}

export const persistReservationState = store => next => (action) => {
  const currentReservation = store.getState().reservation
  const result = next(action)
  const nextReservation = store.getState().reservation

  if (currentReservation === nextReservation || action.type === HYDRATE || typeof window === 'undefined') {
    return result
  }

  postReservationState(nextReservation)

  return result
}
