import * as React from "react";
import styled, { css } from "styled-components";
import { DEVICE_SM_DOWN, DEVICE_SM_UP } from "../../utils/deviceUtils";
import PlayButton from "../Button/PlayButton";

export const Image = ({ src, alt }) => {
  return <img src={src} alt={alt} />;
};

export const Count = styled.div`
  display: flex;
  position: absolute;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem;
  line-height: 2rem;
`;

export const Main = styled.div`
  position: relative;
  cursor: pointer;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media ${DEVICE_SM_UP} {
    ${Count} {
      display: none;
    }
  }

  ${Count} {
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 1.6rem;
    background: radial-gradient(
      70% 90% at 90% 108%,
      rgba(0, 0, 0, 0.2) 64%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  & > button {
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
  }
`;

export const Item = styled.div`
  background-color: ${({ theme }) => theme.color.neutralLight};
  margin: 0.5rem 0.5rem 0 0;
  cursor: pointer;
  flex: 1;

  &:last-child {
    margin-right: 0;
    position: relative;

    ${Count} {
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const Strip = styled.div`
  display: flex;
  flex-direction: row;

  @media ${DEVICE_SM_DOWN} {
    display: none;
  }
`;

type ContainerProps = {
  stripPosition: "bottom" | "right";
};

const stripStyles = {
  right: css`
    flex-direction: row;

    ${Main} {
      height: auto;
      width: 100%;
    }

    ${Strip} {
      flex-direction: column;
      max-width: 255px;
      width: 30%;
    }

    ${Item} {
      margin: 0 0 0.5rem 0.5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  `,

  // default
  bottom: undefined,
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  ${({ stripPosition = "bottom" }) => stripStyles[stripPosition]}
`;

export type GalleryImage = {
  src: string;
  alt: string;
  youtubeId?: string;
};

type ImageComponentProps = {
  src: string;
  alt: string;
};

export type GalleryProps = {
  images: GalleryImage[];
  onClick: (imageIndex: number) => void;
  imageComponent?: React.ComponentType<ImageComponentProps>;
  stripPosition?: ContainerProps["stripPosition"];
};

export const Gallery: React.FC<GalleryProps> = ({
  images,
  onClick,
  imageComponent,
  stripPosition = "bottom",
}) => {
  const ImageComponent = imageComponent || Image;

  return (
    <Container stripPosition={stripPosition}>
      <Main onClick={() => onClick(0)}>
        <ImageComponent src={images[0].src} alt={images[0].alt} />
        <Count>1/{images.length}</Count>
        {images[0].youtubeId && <PlayButton />}
      </Main>
      <Strip>
        {images.slice(1, 5).map(({ src, alt, youtubeId }, idx) => (
          <Item key={idx} onClick={() => onClick(idx + 1)}>
            <ImageComponent src={src} alt={alt} />
            {idx === 3 && images.length > 5 && (
              <Count>+{images.length - 5}</Count>
            )}
          </Item>
        ))}
      </Strip>
    </Container>
  );
};

export default Gallery;
