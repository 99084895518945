import { gql } from "@apollo/client";

import activity from "./activity";
import asset from "./asset";
import link from "./link";

const fragment = gql`
  fragment Program on Program {
    sys {
      id
    }
    heading
    title
    subtitle
    introduction {
      json
    }
    mainImage {
      ...Asset
    }
    location: locationCollection(limit: 5) {
      items {
        sys {
          id
        }
        name
        park {
          sys {
            id
          }
          name
        }
      }
    }
    cta {
      ...Link
    }
    cardDetails
    planning: planningCollection(limit: 7) {
      items {
        sys {
          id
        }
        title
        subtitle
        items: itemsCollection(limit: 10) {
          items {
            type: __typename
            ...ProgramActivity
            ...ItemSeparator
            ...ItemExtra
          }
        }
      }
    }
    introduction {
      json
      links {
        entries {
          inline {
            ...Link
          }
          block {
            ...Link
          }
          hyperlink {
            ...Link
          }
        }
        assets {
          block {
            ...Asset
          }
        }
      }
    }
    gallery: galleryCollection(limit: 10) {
      items {
        sys {
          id
        }
        url
        title
        description
      }
    }
    programDuration
    price
    weekDays
    programMinAge: minAge {
      sys {
        id
      }
      label
      icon
    }
    activityTypes: activityTypesCollection(limit: 5) {
      items {
        sys {
          id
        }
        label
        icon
      }
    }
    detailPage {
      slug
    }
  }

  fragment ProgramActivity on Activity {
    ...Activity
    location {
      name
      park {
        name
      }
    }
    categories: categoriesCollection(limit: 10) {
      items {
        ... on Category {
          sys {
            id
          }
          title
        }
      }
    }
  }

  fragment ItemSeparator on ProgramItemSeparator {
    sys {
      id
    }
    dayPart
    description {
      json
    }
    ctaButton {
      ...Link
    }
  }

  fragment ItemExtra on ProgramItemExtra {
    sys {
      id
    }
    title
    image {
      ...Asset
    }
    location {
      name
      park {
        name
      }
    }
  }

  ${asset}
  ${link}
  ${activity}
`;

export default fragment;
