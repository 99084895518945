import { normalize, schema } from 'normalizr'
import { get } from '../../utilities/api'
import { setEntities } from '../../actions/entityActions'

const optionSchema = new schema.Entity('options')

export const getAttributeOptions = attribute => dispatch => get({ url: `/attributes/${attribute}/options` })
  .then(({ data }) => {
    const normalized = normalize(data, [optionSchema])
    dispatch(setEntities(normalized.entities))
  })
