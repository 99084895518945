import styled from "styled-components/macro";
import { useRouter } from "next/router";

// Components
import { LanguageSwitcher, Link } from "@libema/core";

const StyledHeader = styled.header`
  background-color: ${({ theme }) => theme.color.secondary};
  padding: 16px;

  img {
    max-width: 120px;
  }
`;

const Header = ({ availableLocales, canonicalUrls }) => {
  const { locale = "nl" } = useRouter();

  return (
    <StyledHeader>
      <Link href="/" locale={locale}>
        <img src="/images/logo-mobile.svg" alt="Beeksebergen logo" />
      </Link>
      <LanguageSwitcher
        locales={availableLocales}
        canonicalUrls={canonicalUrls}
      />
    </StyledHeader>
  );
};

export default Header;
