import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import trans from 'counterpart'

const ReservationError = ({ errors }) => {
  if (!errors || Object.keys(errors).length < 1) {
    return null
  }

  return (
    <div className='alert alert-danger'>
      <ul>
        {Object.keys(errors).map(key => (
          <li key={key}>{errors[key]}</li>
        ))}
        <li>{trans('error.help')}</li>
      </ul>
    </div>
  )
}

const mapStateToProps = state => ({
  errors: state.reservation.errors
})

ReservationError.propTypes = {
  errors: PropTypes.array
}

export default connect(mapStateToProps)(ReservationError)
