import { totalBillableSubjects } from '../modules/reservation/selectors'

const setActualAdditionQuantity = (state, additions) => additions
  .filter(a => parseInt(a.quantity, 10) !== 0)
  .map(a => ({
    ...a,
    quantity: a.quantity === 'total'
      ? totalBillableSubjects(state)
      : a.quantity
  }))

export default {
  setActualAdditionQuantity
}
