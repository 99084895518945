import React from 'react'
import PropTypes from 'prop-types'

const ContainerBlock = ({ block, placeholder, manageTags }) => {
  const className = `${block.properties.container_size
        ? `container-${block.properties.container_size}` : 'container'}  
        ${block.properties.extra_classes ? block.properties.extra_classes : ''} ${block.properties.styles ? `${block.properties.styles.join(' ')}` : ''}`

  return (
    <div id={block.properties.id} className={className} {...block.props} {...manageTags}>
      {placeholder(0)}
    </div>
  )
}

ContainerBlock.propTypes = {
  block: PropTypes.object.isRequired,
  placeholder: PropTypes.func.isRequired,
  manageTags: PropTypes.object
}

export default ContainerBlock
