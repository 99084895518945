import { gql } from "@apollo/client";

const fragment = gql`
  fragment InlineScriptBlock on InlineScriptBlock {
    sys {
      id
    }
    type: __typename
    name
    script
  }
`;

export default fragment;
