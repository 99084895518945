import React, { Component } from 'react'
import { connect } from 'react-redux'
import throttle from 'lodash.throttle'
import PropTypes from 'prop-types'
import trans from 'counterpart'
import RefineFiltersPropertyHolder from '../search-and-book/RefineFiltersPropertyHolder'
import { getPreferences, getSelectedPropertyGroups, numberOfFiltersSelected } from '../../../preferences/selectors'
import { setPreference, clearRefinePreferences } from '../../../../actions/preferenceActions'
import { getResorts, getKinds, getAccommodationTypes } from '../../../../selectors/entitySelectors'
import { getPreference } from '../../../../selectors/preferenceSelectors'
import { getFilter } from '../../../../selectors/filterSelectors'
import { APP_BEEKSEBERGEN } from '../../../../constants/apps'
import { ID_SAFARIRESORT } from '../../constants'

class RefineFiltersPicker extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dropdown: false,
      isMobile: false
    }
    this.throttled = throttle(this.resizeHandler, 150, { leading: true })
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
    window.addEventListener('resize', this.throttled)
    this.resizeHandler()
  };

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside)
    window.removeEventListener('resize', this.throttled)
  };

  getAccommodationTypesWithoutCamping = () => {
    const { kinds } = this.props
    return Object.keys(kinds).filter(i => parseInt(i, 10) !== 5).map(i => kinds[i])
  }

  getAccommodationKindCamping = () => {
    const { kinds } = this.props
    return Object.keys(kinds).filter(i => parseInt(i, 10) === 5).map(i => kinds[i])
  }

  handleClickOutside = (event) => {
    if (this.picker && this.state.dropdown && !this.picker.contains(event.target)) {
      this.toggleDropdown()
    }
  }

  // This component is used as a ref, to use this function outside of this component
  open = () => this.setState({ dropdown: true });

  // This component is used as a ref, to use this function outside of this component
  close = () => this.setState({ dropdown: false });

  toggleDropdown = () => this.setState(prevState => ({ dropdown: !prevState.dropdown }));

  toggleCheckbox = (preferenceProperty, propertyId, selected) => {
    let values = [...this.props.preferences[preferenceProperty]]
    if (selected) {
      values = [...values, propertyId]
    } else {
      values.splice(values.indexOf(propertyId), 1)
    }

    this.props.updateSelected(preferenceProperty, values)
    this.props.onChange(preferenceProperty, values)
  }

  formatPropertyCount = count => (count > 0 ? ` (${count})` : '');

  resetFilters = () => {
    this.props.resetFilters()
    this.props.onChange('kinds', [])
  }

  resizeHandler = () => {
    const { isMobile } = this.state
    if (!isMobile && window.innerWidth < 800) {
      this.setState({ isMobile: true })
    }
    if (isMobile && window.innerWidth >= 800) {
      this.setState({ isMobile: false })
    }
  }

  getPropertyGroupsWithProperties = () => this.props.propertyGroups
    .filter((propertyGroup) => {
      const propertyCount = propertyGroup.properties.filter(propertyId => this.props.filterableProperties.includes(propertyId))

      return propertyCount.length > 0
    })

  render = () => {
    const { dropdown, isMobile } = this.state
    const { filterableProperties, selectedKinds, resorts, resortCounts, selectedResorts, numberOfFiltersSelected } = this.props

    return (
      <div ref={(ref) => { this.picker = ref }}>
        <div className={`refine-selection form-dropdown dropdown form-refine ${dropdown ? 'show' : ''}`}>
          <div className={`form-dropdown dropdown ${dropdown ? 'show' : ''}`}>
            <div className={`refine-dropdown-toggle ${dropdown ? 'after-override' : ''}`} onClick={this.toggleDropdown}>
              <div className='dropdown-filter'>
                <div className='dropdown-heading'>{trans('label.refine_selection')}</div>
                <div className='dropdown-value'>
                  <span className='form_refine_label form-dropdown-label'>
                    {`${numberOfFiltersSelected} ${trans('label.filters_active')}`}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {dropdown && (
            <div className='refine-holder dropdown-menu'>
              <div className='row row-stay'>
                {process.env.NEXT_PUBLIC_APP === APP_BEEKSEBERGEN && (
                  <div className='col-lg-3 mb-md-3'>
                    <h3 className='h6 mb-3 f-l-0'>{trans('refine.park')}</h3>
                    <div className='row mx-0' style={{ display: 'block' }}>
                      {Object.values(resorts).map(resort => (
                        <div key={resort.id} className='form-check'>
                          <span className={`d-flex text-${parseInt(resort.id, 10)}`}>
                            <input
                              id={resort.id}
                              name={resort.id}
                              type='checkbox'
                              className='custom-control-input m-l-0'
                              checked={selectedResorts.includes(resort.id)}
                              onChange={() => this.toggleCheckbox('resorts', resort.id, !selectedResorts.includes(resort.id))}
                              disabled={resortCounts[resort.id] < 1}
                            />
                            <span className='custom-control-indicator' />
                            <span className='custom-control-description'>
                              <label>
                                <strong>
                                  {`${resort.name}${this.formatPropertyCount(resortCounts[resort.id])}`}
                                </strong>
                              </label>
                            </span>
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className='col-lg-6 mb-md-3'>
                  <h3 className='h6 mb-3 f-l-0'>{trans('refine.accommodation')}</h3>
                  <div className='row col-wrap mx-0'>
                    {this.getAccommodationTypesWithoutCamping().map(kind => (
                      <div key={kind.id} className='form-check col-md-4'>
                        <span className='d-flex no-property-holder'>
                          <input
                            name={kind.id}
                            id={kind.id}
                            type='checkbox'
                            className='custom-control-input'
                            checked={selectedKinds && selectedKinds.includes(kind.id)}
                            onChange={() => this.toggleCheckbox('kinds', kind.id, !selectedKinds.includes(kind.id))}
                          />
                          <span className='custom-control-indicator' />
                          <span className='custom-control-description'>
                            <label htmlFor={kind.id}>
                              {kind.display_name ? kind.display_name : kind.name}
                            </label>
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='col-lg-3 mb-md-3'>
                  <h3 className='h6 mb-3 f-l-0'>{trans('refine.camping')}</h3>
                  <div className='row col-wrap mx-0'>
                    {this.getAccommodationKindCamping().map(kind => (
                      <div key={kind.id} className='form-check col-md-4'>
                        <span className='d-flex'>
                          <input
                            name={kind.id}
                            id={kind.id}
                            type='checkbox'
                            className='custom-control-input'
                            checked={selectedKinds.includes(kind.id)}
                            onChange={() => this.toggleCheckbox('kinds', kind.id, !selectedKinds.includes(kind.id))}
                          />
                          <span className='custom-control-indicator' />
                          <span className='custom-control-description'>
                            <label htmlFor={kind.id}>
                              {kind.display_name ? kind.display_name : kind.name}
                            </label>
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <hr className='hidden-sm-down' />
              <div id='accordion-refine' className='row row-properties' role='tablist' aria-multiselectable='true'>
                {this.getPropertyGroupsWithProperties().map((propertyGroup, index) => (
                  <RefineFiltersPropertyHolder
                    filterableProperties={filterableProperties}
                    propertyGroup={propertyGroup}
                    index={index}
                    key={propertyGroup.id}
                    toggleCheckbox={this.toggleCheckbox}
                    isMobile={isMobile}
                  />
                ))}
              </div>
              <div className='row row-buttons'>
                <div className='col text-md-right'>
                  <button
                    type='button'
                    className='btn btn-black hide-extra-button'
                    onClick={this.resetFilters}
                  >
                    {trans('refine.empty_filters')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-green ml-2 hide-extra-button'
                    onClick={() => this.toggleDropdown()}
                  >
                    {trans('refine.apply')}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

RefineFiltersPicker.propTypes = {
  filterableProperties: PropTypes.array,
  kinds: PropTypes.object,
  // kindCounts: PropTypes.object,
  numberOfFiltersSelected: PropTypes.number,
  preferences: PropTypes.object,
  propertyGroups: PropTypes.array,
  resetFilters: PropTypes.func,
  resorts: PropTypes.object,
  resortCounts: PropTypes.object,
  selectedKinds: PropTypes.array,
  selectedResorts: PropTypes.array,
  onChange: PropTypes.func,
  updateSelected: PropTypes.func
}

const mapStateToProps = state => ({
  kinds: getKinds(state),
  // kindCounts: getFilter('kinds')(state),
  preferences: getPreferences(state),
  accommodations: getAccommodationTypes(state),
  propertyGroups: getSelectedPropertyGroups(state),
  resorts: getResorts(state),
  resortCounts: getFilter('resorts')(state),
  numberOfFiltersSelected: numberOfFiltersSelected(state),
  selectedKinds: getPreference('kinds')(state),
  selectedResorts: getPreference('resorts')(state)
})

const mapDispatchToProps = dispatch => ({
  updateSelected: (preferenceProperty, payload) => dispatch(setPreference(preferenceProperty, payload)),
  resetFilters: () => dispatch(clearRefinePreferences())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  // We need to pass the forwardRef param to make sure we can use a ref on the actual component
  // See https://react-redux.js.org/api/connect#forwardref-boolean
  { forwardRef: true }
)(RefineFiltersPicker)
