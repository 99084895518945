import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import trans from 'counterpart'
import { CarouselItem } from 'reactstrap'
import Carousel from '../Carousel'
import Link from '../typography/Link'
import TabsContext from '../../contexts/TabsContext'
import Image from '../../modules/media/components/Image'
import { fetchAccommodationTypes } from '../../modules/booking/actions'
import { getAccommodationTypes } from '../../selectors/entitySelectors'

class PriceListBlock extends Component {
  componentDidMount = () => {
    if (!this.props.accommodationTypes || !Object.keys(this.props.accommodationTypes).length) {
      this.props.getAccommodations()
    }
  }

  getAccommodationTypes = () => {
    const { accommodationTypes, properties } = this.props

    if (!accommodationTypes || !Object.keys(accommodationTypes).length) return []

    const preferences = Array.isArray(properties.preferences) ? properties.preferences : Object.values(properties.preferences)
    return preferences
      .map(preference => ({
        ...preference,
        ...accommodationTypes[preference.resource]
      }))
      .filter(a => a.id)
  }

  render = () => (
    <TabsContext.Consumer>
      {tabContext => (
        <>
          {/* The PriceListBlock is not always placed in a tab context, so if no tabContext is provided, we'll have to always display the block */}
          {(!tabContext || (tabContext && tabContext.myIndex === tabContext.activeTab)) && (
            <div className='card-group card-group-md'>
              {this.getAccommodationTypes().map(accommodationType => (
                <div className='card' key={accommodationType.id}>
                  <div className='card-media'>
                    <Carousel>
                      {accommodationType.images && accommodationType.images.map(image => (
                        <CarouselItem key={image.id}>
                          <Link to={accommodationType.slug}>
                            <Image
                              file={image.reference}
                              filter='thumbnail_md'
                              attr={{
                                className: 'img-responsive',
                                alt: image.alt
                              }}
                            />
                          </Link>
                        </CarouselItem>
                      ))}
                    </Carousel>
                  </div>
                  <div className='card-block price-list'>
                    <h4 className='card-header'>
                      <Link to={`/${accommodationType.slug}`}>{accommodationType.display_name || accommodationType.name}</Link>
                    </h4>
                    {accommodationType.usp && (
                      <div>
                        <div className='card-usp' dangerouslySetInnerHTML={{ __html: accommodationType.usp }} />
                        <hr className='hr-usp' />
                      </div>
                    )}
                    <ul className='list-unstyled clearfix'>
                      {accommodationType.properties && accommodationType.properties.filter(p => p.visible_on_index).map(property => (
                        <li key={property.property.id} className={`property property-${property.property.id}`}>
                          <span className='material-icons'>{property.property.icon}</span>
                          {property.value && `${property.value}${property.property.display_name}`}
                          {!property.value && property.property.display_name}
                        </li>
                      ))}
                    </ul>
                    <Link to={`/${accommodationType.slug}`} className='btn btn-black' role='button'>{trans('label.discover_more')}</Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </TabsContext.Consumer>
  );
}

PriceListBlock.propTypes = {
  accommodationTypes: PropTypes.object,
  getAccommodations: PropTypes.func.isRequired,
  properties: PropTypes.object
}

const mapState = state => ({
  accommodationTypes: getAccommodationTypes(state)
})

const mapDispatch = dispatch => ({
  getAccommodations: () => dispatch(fetchAccommodationTypes())
})

export default connect(mapState, mapDispatch)(PriceListBlock)
