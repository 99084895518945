import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import trans from "counterpart";

// Components
import { CancelReservationModal } from "@libema/design-system";

// Redux
import reservationSelectors from "../../selectors/reservationSelectors";
import { getCurrentContent } from "../../selectors/contentSelectors";

// Api
import { cancelReservation } from "../../utilities/api/reservationApi";
import { APP_DIERENBOS, APP_BEEKSEBERGEN } from "../../constants/apps";

const APP = process.env.NEXT_PUBLIC_APP || APP_BEEKSEBERGEN;

// Only show cancel reservation waring modal on one of the following pages.
const PAYMENT_PAGES = [
  "boeking/betaling",
  "en/booking/payment",
  "de/buchung/zahlung",
  "fr/reservation/paiement",
];

const POLICY_LINKS = {
  [APP_DIERENBOS]: {
    nl: "/annuleringsvoorwaarden",
    de: "/de/stornierungsbedingungen",
  },
  [APP_BEEKSEBERGEN]: {
    nl: "/overnachten/annuleringsvoorwaarden",
    de: "/de/ubernachten/stornierungsbedingungen",
    fr: "/fr/sejourner/politique-dannulation",
    en: "/en/stay-the-night/cancellation-policy",
  },
};

export const paymentPageVisitedState = {
  isSSR: () => typeof window === "undefined",
  getKey: (reservationId: string) =>
    `jstack.payment.page.visited-${reservationId}`,
  hasVisited: function (reservationId) {
    if (this.isSSR()) return false;
    const key = this.getKey(reservationId);
    return Boolean(localStorage?.getItem(key));
  },
  setVisited: function (reservationId) {
    if (this.isSSR()) return;
    const key = this.getKey(reservationId);
    localStorage?.setItem(key, "true");
  },
};

const WarnCancelReservationModal = ({
  currentPage,
  reservationId,
  reservationNumber,
  locale,
}) => {
  const policyLink = POLICY_LINKS[APP][locale] || "/";
  return (
    <CancelReservationModal
      currentPage={currentPage}
      pages={PAYMENT_PAGES}
      canShow={
        reservationId != null &&
        !paymentPageVisitedState.hasVisited(reservationId)
      }
      onCancelReservation={cancelReservation}
      reservationId={reservationId}
      reservationNumber={reservationNumber}
      policyLink={policyLink}
      translations={trans("cancelReservation")}
    />
  );
};

WarnCancelReservationModal.propTypes = {
  currentPage: PropTypes.string,
  reservationId: PropTypes.number,
  reservationNumber: PropTypes.string,
};

const mapStateToProps = (state) => {
  const content = getCurrentContent(state);
  return {
    locale: state.preferences.locale,
    currentPage: content ? content.slug : null,
    reservationId: reservationSelectors.getReservationId(state),
    reservationNumber: reservationSelectors.getReservationNumber(state),
  };
};

export default connect(mapStateToProps)(WarnCancelReservationModal);
