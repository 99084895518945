import * as actionTypes from './actionTypes'

export const addResourcesToCompare = payload => ({
  type: actionTypes.SET_COMPARE_PROPERTY,
  property: 'compareListIds',
  payload
})

export const resetCompareList = () => ({
  type: actionTypes.RESET_COMPARE
})

export const addResourceToCompare = payload => ({
  type: actionTypes.ADD_TO_COMPARE,
  payload
})

export const removeResourceFromCompare = payload => ({
  type: actionTypes.REMOVE_FROM_COMPARE,
  payload
})

export const setCompareProperty = (property, payload) => ({
  type: actionTypes.SET_COMPARE_PROPERTY,
  property,
  payload
})

export const mapResourcesToState = (resources = []) => (dispatch) => {
  // Temporary solution
  dispatch(setCompareProperty('compareListIds', resources.map(r => parseInt(r, 10))))
}

/**
 * Temp solution because I don't know how else to get back the updated state from Redux. The dispatch returns the object.
 * @param {*} payload
 */
export const returnResourcesAfterRemoval = payload => (dispatch, getState) => {
  dispatch(removeResourceFromCompare(payload))
  return getState().compare.compareListIds
}

export const returnResourcesAfterAdd = payload => (dispatch, getState) => {
  dispatch(addResourceToCompare(payload))
  return getState().compare.compareListIds
}
