import styled from "styled-components";

export const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 50%;
`;

const Award = styled.div`
  text-align: center;
  padding: 0 8px !important;
`;

export default Award;
