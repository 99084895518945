import styled from "styled-components/macro";

export type OptionGroupProps = {
  inline?: boolean;
};

export default styled.div<OptionGroupProps>`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
  margin-bottom: ${({ theme }) => theme.margin.sm};

  *:first-child {
    margin-right: 1rem;
  }
`;
