import merge from "lodash.merge";
import isEqual from "lodash.isequal";
import { SET_ENTITIES, UPDATE_ENTITY } from "../actions/entityActions";
import {
  ID_HOLIDAYPARK,
  ID_SAFARIRESORT,
  ID_SAFARIRESORT_HOTEL,
} from "../modules/booking/constants";

/**
 * Entities reducer
 *
 * This reducer is meant to hold more-or-less static data retrieved from API's.
 */
export const initialState = {
  accommodationtypes: {},
  blocks: {},
  kinds: {},
  objects: {},
  offers: {},
  options: {},
  properties: {},
  propertygroups: {},
  // TODO: Fetch these from the API, to support multilanguage
  resorts: {
    [ID_SAFARIRESORT]: {
      selected: false,
      count: 0,
      id: ID_SAFARIRESORT,
      name: "Safari Resort",
      fullName: "Safari Resort Beekse Bergen",
    },
    [ID_HOLIDAYPARK]: {
      selected: false,
      count: 0,
      id: ID_HOLIDAYPARK,
      name: "Lake Resort",
      fullName: "Lake Resort Beekse Bergen",
    },
    [ID_SAFARIRESORT_HOTEL]: {
      selected: false,
      count: 0,
      id: ID_SAFARIRESORT_HOTEL,
      name: "Safari Hotel",
      fullName: "Safari Hotel Beekse Bergen",
    },
  },
  subjects: {},
};

const object = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ENTITY: {
      if (typeof action.payload.propertyOrObject === "object") {
        const updatedState = {
          ...state,
          ...action.payload.propertyOrObject,
        };

        return isEqual(updatedState, state) ? state : updatedState;
      }

      state[action.payload.propertyOrObject] = action.payload.value;

      return state;
    }
    default: {
      return state;
    }
  }
};

const entity = (state = {}, action) =>
  Object.assign({}, state, {
    [action.payload.id]: object(state[action.payload.id], action),
  });

const entityReducer = (state = initialState, action) => {
  // Updates an entity state in response to any action with response.entities.
  if (action.response && action.response.entities) {
    return merge({}, state, action.response.entities);
  }

  switch (action.type) {
    case SET_ENTITIES: {
      return merge({}, state, action.payload);
    }
    case UPDATE_ENTITY: {
      return Object.assign({}, state, {
        [action.payload.entity]: entity(state[action.payload.entity], action),
      });
    }
    default: {
      return state;
    }
  }
};

export default entityReducer;
