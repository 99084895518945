// eslint-disable-next-line
// @ts-nocheck
import styled from "styled-components/macro";
import { darken } from "polished";

export type ButtonProps = {
  fullWidth?: boolean;
  size?: string;
};

export default styled.button<ButtonProps>`
  border: 0;
  border-radius: ${({ theme }) => theme.button?.borderRadius};
  background-color: ${({ theme }) => theme.color.primary};
  width: ${({ fullWidth }) => (fullWidth === true ? "100%" : "auto")};
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.font.default};
  text-decoration: none;
  padding: ${({ theme, size }) =>
    `${theme.padding[size === "small" ? "sm" : "md"]} ${
      theme.padding[size === "small" ? "md" : "lg"]
    }`};
  &:visited {
    color: #fff;
  }
  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: ${({ theme }) => darken(0.1, theme.color.primary)};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => darken(0.2, theme.color.neutralLight)};
  }
`;
