import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getIdOrSlugFromPath } from '../../modules/content/utilities'

const TagCloudBlock = ({ content }) => (
  <div className='tag-cloud'>
    {content?.attributes?.category && content.attributes.category.map(option => (
      <div key={option.name} className='badge badge-default mr-2'>
        {option.name}
      </div>
    ))}
  </div>
)

TagCloudBlock.propTypes = {
  content: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  contentId: PropTypes.number
}

const mapState = (state, ownProps) => {
  // The passed identifier can be either the ID or the Slug (string).
  let contentId = Object.keys(state.content).find(i => i === getIdOrSlugFromPath(ownProps.contentId) || state.content[i].slug === getIdOrSlugFromPath(ownProps.contentId))
  if (!contentId && !ownProps.isFetching) {
    contentId = Object.keys(state.content).find(i => state.content[i].slug === '404')
  }

  const content = state.content[contentId]

  return {
    content,
    entities: state.entities.blocks,
    children: (content) ? content.blocks.map(id => state.entities.blocks[id]).filter(b => b.parentId === undefined) : [],
    isFetching: (content) ? content.isFetching : true
  }
}

export default connect(mapState)(TagCloudBlock)
