export const PreferenceType = {
  // A property
  AMENITY: "AMENITY",
  // A specific object
  UNIT: "UNIT",
};

export const ReservationStatus = {
  CANCEL: "CANCEL",
  DECLINE: "DECLINE",
  CHECKED_IN: "CHECKED_IN",
  CONFIRM: "CONFIRM",
  CHECKED_OUT: "CHECKED_OUT",
};
