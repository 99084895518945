import { useMemo, useState } from "react";
import * as React from "react";
import styled from "styled-components";

// Types
import { FrequentlyAskedQuestion, SectionFAQ } from "@libema/content-sdk";

// Components
import {
  Section,
  H3,
  H2,
  H1,
  Accordion,
  Container,
  Row,
  Col,
  Button,
  DEVICE_MD_DOWN,
} from "@libema/design-system";
import RichTextRenderer from "../renderer/RichTextRenderer";

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  ${Button} {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const AccordionWrapper = styled(Accordion.Wrapper)`
  @media ${DEVICE_MD_DOWN} {
    margin-bottom: 2rem;
  }
`;

const useCategories = (questions) => {
  const categories = useMemo(() => {
    return questions?.reduce((categories: string[], currentQuestion) => {
      currentQuestion.categories?.forEach((category) => {
        if (!categories.includes(category.title)) {
          categories.push(category.title);
        }
      });

      return categories;
    }, []);
  }, [questions]);

  return [categories];
};

const FAQSection: React.FC<SectionFAQ> = ({
  title,
  questions,
  description,
  backgroundColor,
}) => {
  const [categories] = useCategories(questions);

  const [selectedCategory, setSelectedCategory] = useState<string>();

  // Listeners
  const onCategoryClick = (category: string) => () => {
    if (selectedCategory === category) {
      setSelectedCategory(undefined);
    } else {
      setSelectedCategory(category);
    }
  };

  // Derived State
  const filteredQuestions = useMemo(() => {
    if (!selectedCategory) return questions;
    return questions.filter((question: FrequentlyAskedQuestion) =>
      question.categories?.some(
        (category) => category.title === selectedCategory
      )
    );
  }, [questions, selectedCategory]);

  return (
    <Section.Wrapper backgroundColor={backgroundColor}>
      {title && (
        <Section.Header>
          <H2>{title}</H2>
        </Section.Header>
      )}
      <Container>
        <Row>
          <Col md={description ? 9 : 12}>
            <ButtonGroup>
              {categories?.map((category: string) => {
                return (
                  <Button
                    key={category}
                    variant={
                      selectedCategory === category ? "contained" : "outlined"
                    }
                    size="small"
                    colorType="secondary"
                    type="button"
                    onClick={onCategoryClick(category)}
                  >
                    {category}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col md={description ? 9 : 12}>
            <AccordionWrapper>
              {filteredQuestions?.map(({ id, question, answer }) => (
                <Accordion.Item key={id}>
                  <Accordion.Header>
                    <H3>{question}</H3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <RichTextRenderer text={answer} />
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </AccordionWrapper>
          </Col>
          {description && (
            <Col md={3}>
              <aside>
                <RichTextRenderer text={description} />
              </aside>
            </Col>
          )}
        </Row>
      </Container>
    </Section.Wrapper>
  );
};

export default FAQSection;
