import { gql } from "@apollo/client";

// Data models
import program from "../data/program";

const fragment = gql`
  fragment ProgramTopic on Program {
    ...Program
  }

  ${program}
`;

export default fragment;
