/* eslint-disable no-void */
import { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";

// Types
import { SectionOpeningHours } from "@libema/content-sdk";

// Components
import { Section, H2, H3, Row, Col, DayPicker } from "@libema/design-system";
import { FormattedDate } from "react-intl";

const DayContainer = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  padding: 2rem;

  em {
    font-weight: bold;
    font-style: normal;
  }
`;

const WEEKDAYS = {
  1: "MONDAY",
  2: "TUESDAY",
  3: "WEDNESDAY",
  4: "THURSDAY",
  5: "FRIDAY",
  6: "SATURDAY",
  7: "SUNDAY",
};

type TimeOfDay = {
  hours: number;
  minutes?: number;
  seconds?: number;
  nanos?: number;
};

type Period = {
  openDay: string;
  closeDay: string;
  openTime: TimeOfDay;
  closeTime: TimeOfDay;
};

type SpecialPeriod = {
  startDate: {
    day: number;
    month: number;
    year: number;
  };
  openTime: TimeOfDay;
  closeTime: TimeOfDay;
  closed?: boolean;
};

type OpeningHoursInfo = {
  regularHours: {
    periods: Period[];
  };
  specialHours: {
    specialHourPeriods: SpecialPeriod[];
  };
};

type DateInfo = {
  isOpen: boolean;
  openTime: string | undefined;
  closeTime: string | undefined;
};

type AvailableDates = {
  [date: string]: DateInfo;
};

const OpeningHours = ({ title, backgroundColor }: SectionOpeningHours) => {
  const [openinghours, setOpeningHours] = useState<OpeningHoursInfo>();
  const [availableDates, setAvailableDates] = useState<AvailableDates>();
  const [focusDate, setFocusDate] = useState(moment());
  const [focusMonth, setFocusMonth] = useState(moment());
  const [dateInfo, setDateInfo] = useState<DateInfo>();

  useEffect(() => {
    const fetchOpeningHours = async () => {
      const response = await fetch("/api/openinghours");
      const openinghours = await response.json();
      setOpeningHours(openinghours);
    };

    void fetchOpeningHours();
  }, []);

  const handleMonthChange = async (date) => {
    setFocusMonth(date);
  };

  const handleDateChange = async (date) => {
    setFocusDate(date);
  };

  useEffect(() => {
    const infoAtDate = (date: moment.Moment): DateInfo => {
      let info: DateInfo = {
        isOpen: false,
        openTime: undefined,
        closeTime: undefined,
      };

      if (!openinghours) {
        return info;
      }

      // check regular hours with dotw
      const day = WEEKDAYS[date.isoWeekday()];

      const regularHours = openinghours.regularHours.periods.find(
        (period) => period.openDay === day
      );

      if (regularHours) {
        const { openTime, closeTime } = regularHours;
        info = {
          isOpen: true,
          openTime: `${openTime.hours}:${openTime.minutes || "00"}`,
          closeTime: `${closeTime.hours}:${closeTime.minutes || "00"}`,
        };
      }

      // check exceptions in special hours
      const specialHours = openinghours.specialHours.specialHourPeriods.find(
        (p) => {
          const dayFormatted =
            p.startDate.day >= 10 ? p.startDate.day : `0${p.startDate.day}`;
          const monthFormatted =
            p.startDate.month >= 10
              ? p.startDate.month
              : `0${p.startDate.month}`;

          const specialHoursDate = moment(
            `${p.startDate.year}-${monthFormatted}-${dayFormatted}`
          ).format("YYYY-MM-DD");

          return specialHoursDate === date.format("YYYY-MM-DD");
        }
      );

      if (specialHours) {
        const { openTime, closeTime } = specialHours;

        info = {
          ...specialHours,
          openTime: `${openTime?.hours}:${openTime?.minutes || "00"}`,
          closeTime: `${closeTime?.hours}:${closeTime?.minutes || "00"}`,
          isOpen: !specialHours.closed,
        };
      }

      return info;
    };

    const plotAvailableDates = () => {
      let dates = {};
      // We plot 3 months to account for pagination delay
      const cursor = moment(focusMonth).startOf("month").subtract(1, "month");
      const end = moment(cursor).add(3, "month");
      const days = end.diff(cursor, "days");
      for (let i = 0; i < days; i++) {
        dates = {
          ...dates,
          [cursor.format("YYYY-MM-DD")]: infoAtDate(cursor),
        };
        cursor.add(1, "day");
      }

      setAvailableDates(dates);
    };

    if (openinghours) {
      plotAvailableDates();
      setDateInfo(infoAtDate(focusDate));
    }
  }, [focusDate, focusMonth, openinghours]);

  return (
    <Section.Wrapper backgroundColor={backgroundColor}>
      {title && (
        <Section.Header>
          <H2>{title}</H2>
        </Section.Header>
      )}
      <Section.Container>
        <Row>
          <Col lg={8}>
            {openinghours && availableDates && (
              <DayPicker
                availableDates={Object.keys(availableDates).reduce(
                  (dates, key) => ({
                    ...dates,
                    [key]: availableDates[key].isOpen,
                  }),
                  {}
                )}
                onDateChange={handleDateChange}
                onMonthChange={handleMonthChange}
              />
            )}
          </Col>
          <Col lg={4}>
            {dateInfo?.isOpen && (
              <DayContainer>
                <H3>
                  <FormattedDate
                    value={focusDate.toDate()}
                    weekday="long"
                    day="numeric"
                    month="long"
                  />
                </H3>
                <p>
                  Open van <em>{dateInfo?.openTime}</em> tot{" "}
                  <em>{dateInfo?.closeTime}</em>
                </p>
              </DayContainer>
            )}
          </Col>
        </Row>
      </Section.Container>
    </Section.Wrapper>
  );
};

export default OpeningHours;
