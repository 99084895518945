/* eslint-disable */

const highlightArea = (btn, popover, area) => {
  $(btn).click(function () {
      $('.section-hh').addClass('section-active');
      $('.highlight-layer, .popover').fadeOut(250, 'linear');
      $(popover).addClass('active').fadeIn(250, 'linear');
      $(area).fadeIn(250, 'linear');
  });

  $('.section-hh').click(function (e) {
      if (e.target.className == "btn-pointer")
          return;

      //For descendants of menu_content being clicked, remove this check if you do not want to put constraint on descendants.
      if ($(e.target).closest('.btn-pointer').length)
          return;

      var $trigger = $('.popover');
      if ($trigger !== e.target && !$trigger.has(e.target).length) {
          $('.section-active').removeClass('section-active');
          $trigger.removeClass('active').fadeOut(250, 'linear');
          $(area).fadeOut(250, 'linear');
      }
  });
}

export const initializeMap = () => {

  $('.btn-pointer').click(function (e) {
      e.preventDefault();
  });

  highlightArea('.btn-sp', '.spbb-popover', '.hl-sp');
  highlightArea('.btn-sl', '.slbb-popover', '.hl-sl');
  highlightArea('.btn-ec', '.ecbb-popover', '.hl-ec');
  highlightArea('.btn-vp', '.vpbb-popover', '.hl-vp');
  highlightArea('.btn-sr', '.srbb-popover', '.hl-sr');
  highlightArea('.btn-sh', '.shbb-popover', '.hl-sh');
}

if (typeof window !== 'undefined' && $('.section-hh').length) {
  $('.btn-sp').off();
  $('.btn-sl').off();
  $('.btn-ec').off();
  $('.btn-vp').off();
  $('.btn-sr').off();
  $('.btn-sh').off();
  initializeMap();
}
