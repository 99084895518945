import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// Dirty temporary fix for complexity limit error. We're moving to Storyblok anyway.
export const SECTION_LIMIT = process.env.NEXT_PUBLIC_APP === "klimrijk" ? 8 : 5;

export const getClient = (preview = false) => {
  const CONTENTFUL_SPACE_ID = process.env.CONTENTFUL_SPACE_ID;
  const CONTENTFUL_ENV = process.env.CONTENTFUL_ENV ?? "master";
  const CONTENTFUL_TOKEN = preview
    ? process.env.CONTENTFUL_PREVIEW_TOKEN
    : process.env.CONTENTFUL_ACCESS_TOKEN;

  if (!CONTENTFUL_SPACE_ID) {
    throw new Error("Missing CONTENTFUL_SPACE_ID as environment variable");
  }

  if (!CONTENTFUL_TOKEN) {
    throw new Error("Missing CONTENTFUL_TOKEN as environment variable");
  }

  // eslint-disable-next-line @typescript-eslint/promise-function-async
  const customFetch = (uri, options) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const body = `${options.body}   `
      .replace(/#.*\n/g, "")
      .replace(/\\n|\n/g, "")
      .replace(/\s+/g, " ")
      .trim();
    return fetch(uri, { ...options, body });
  };

  const httpLink = createHttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/${CONTENTFUL_SPACE_ID}/environments/${CONTENTFUL_ENV}`,
    fetch: customFetch,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "Content-Type": "application/json",
        Authorization: `Bearer ${CONTENTFUL_TOKEN}`,
      },
    };
  });

  /* TODO: singleton client */
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};

export const fetchWithGraphQL = async ({
  query,
  variables = {},
  preview = false,
}) => {
  return await getClient(preview).query({
    query,
    variables,
  });
};
