/**
 * DEPRECATED. Use `preferenceActions.js` instead.
 *
 * Using this file is deprecated. All logic should be moved to `preferenceActions.js`.
 */
import { setPreference } from '../../actions/preferenceActions'

export const setPreferences = (values, object) => (dispatch) => {
  dispatch({ type: 'preferences', values, object })
}

export const setSubjectQuantity = (id, quantity) => (dispatch, getState) => {
  const { preferences: { subjects } } = getState()

  return dispatch(setPreference('subjects', {
    ...subjects,
    [id]: quantity
  }))
}
