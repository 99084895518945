export const PREFERENCE_ARRIVAL = 'arrival'
export const PREFERENCE_DEPARTURE = 'departure'
export const PREFERENCE_KINDS = 'kinds'
export const PREFERENCE_LOCALE = 'locale'
export const PREFERENCE_PROPERTIES = 'properties'
export const PREFERENCE_RESORTS = 'resorts'
export const PREFERENCE_SUBJECTS = 'subjects'
export const PREFERENCE_DIRECTION = 'direction'
export const PREFERENCE_SORT = 'sort'
export const PREFERENCE_VOUCHER = 'voucher'
export const PREFERENCE_SHOW_SPECIALS = 'show_with_open_specials'
export const PREFERENCE_SPECIAL = 'special'
