import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, reset, Field } from "redux-form";
import trans from "counterpart";
import Image from "../../media/components/Image";
import AdditionForm from "./AdditionForm";
import Modal from "../../../components/modals/Modal";
import ModalBody from "../../../components/modals/ModalBody";
import ModalFooter from "../../../components/modals/ModalFooter";
import { totalBillableSubjects } from "../../reservation/selectors";
import reservationSelectors from "../../../selectors/reservationSelectors";
import styled from "styled-components";
import { deviceUtils } from "@libema/design-system";
import { ID_HOLIDAYPARK } from "../constants";

const StyledModalBody = styled(ModalBody)`
  @media ${deviceUtils.DEVICE_SM_DOWN} {
    &.modal-body {
      padding: 0 !important;
    }
  }
`;

/**
 * @deprecated Use the AdditionOverviewBlock instead
 */
class AdditionBlock extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  hasActiveCategory() {
    const { activeCategory, properties } = this.props;

    const categories = [];
    if (properties.categories) {
      properties.categories.forEach((cat) => {
        categories.push(cat.key);
      });
    }

    if (activeCategory && categories.indexOf(activeCategory) === -1) {
      return false;
    }

    return true;
  }

  render() {
    const {
      media,
      resourceAdditions,
      properties,
      selectedAddition,
      resortId,
      billableSubjects,
      manageTags,
    } = this.props;
    let templateBackground;

    if (resortId === ID_HOLIDAYPARK) {
      templateBackground = " bg-orange-light text-white";
    } else {
      templateBackground = " bg-red-light text-white";
    }

    // If the current block has no available additions at all, hide the component
    if (!resourceAdditions || resourceAdditions.length < 1) {
      return <div />;
    }

    if (!this.hasActiveCategory() && properties.template !== "mandatory") {
      return <div />;
    }

    return (
      <div
        className={properties.template === "lg" ? "col-lg-12" : "col-lg-6"}
        {...manageTags}
      >
        <div className={`card card-${properties.template} bg-faded mb-2`}>
          <div
            className={`card-block p-0${
              properties.template === "lg" ? templateBackground : ""
            }`}
          >
            <div className="row">
              <div
                className={
                  properties.template === "lg" ? "col-6" : "col-4 pr-0"
                }
              >
                {media && (
                  <Image
                    file={media.reference}
                    filter={properties.template === "lg" ? "md_32" : "sm_11"}
                    attr={{ alt: media.name, className: "img-fluid" }}
                  />
                )}
              </div>
              <div
                className={
                  properties.template === "lg"
                    ? "card-details col-6 pl-1 pr-3 py-2"
                    : "col-8 padding-1"
                }
              >
                <div dangerouslySetInnerHTML={{ __html: this.props.value }} />
                {properties.template === "lg" && (
                  <div className="card-foot clearfix mt-1 pr-2">
                    <span className="text-white h4 font-normal">
                      {properties.label}
                    </span>
                    <button
                      className="btn btn-outline float-right card-link"
                      onClick={this.toggle}
                      type="button"
                    >
                      <strong>
                        {trans("button.more_info")}
                        <i
                          className={`${
                            properties.template === "lg"
                              ? "hidden-xs-up"
                              : "material-icons md-18"
                          }`}
                        >
                          keyboard_arrow_right
                        </i>
                      </strong>
                    </button>
                  </div>
                )}
              </div>
              {selectedAddition.length > 0 && (
                <span
                  className={`material-icons ${
                    properties.template === "lg"
                      ? "selected-lg text-white"
                      : "selected-sm text-success"
                  }`}
                >
                  check
                </span>
              )}
            </div>
          </div>
          {properties.template !== "lg" && (
            <div className="card-footer">
              <span className="float-left">{properties.label}</span>
              {properties.template !== "mandatory" && (
                <span className="float-right card-link" onClick={this.toggle}>
                  <strong>
                    {trans("button.more_info")}
                    <i
                      className={`${
                        properties.template === "lg"
                          ? "hidden-xs-up"
                          : "material-icons md-18"
                      }`}
                    >
                      keyboard_arrow_right
                    </i>
                  </strong>
                </span>
              )}
              {properties.template === "mandatory" &&
                properties.amount !== "flex" && (
                  <div className="float-right text-right choice btn-group paw-radio-group">
                    <label
                      htmlFor={`addition-${resourceAdditions[0].id}-radio-1`}
                      className="custom-control custom-radio"
                    >
                      <Field
                        name={`addition-${resourceAdditions[0].id}`}
                        id={`addition-${resourceAdditions[0].id}-radio-1`}
                        component="input"
                        type="radio"
                        className="custom-control-input"
                        value={properties.amount.toString()}
                      />
                      <span className="custom-control-indicator" />
                      <span className="custom-control-description">
                        {trans("label.yes")}
                      </span>
                    </label>
                    <label
                      htmlFor={`addition-${resourceAdditions[0].id}-radio-2`}
                      className="custom-control custom-radio"
                    >
                      <Field
                        name={`addition-${resourceAdditions[0].id}`}
                        id={`addition-${resourceAdditions[0].id}-radio-2`}
                        component="input"
                        type="radio"
                        className="custom-control-input"
                        value="0"
                      />
                      <span className="custom-control-indicator" />
                      <span className="custom-control-description">
                        {trans("label.no")}
                      </span>
                    </label>
                  </div>
                )}
            </div>
          )}
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <StyledModalBody className="px-0">
            <AdditionForm addition={this.props} />
          </StyledModalBody>
          <ModalFooter>
            <button
              type="submit"
              onClick={this.toggle}
              className="btn-addition btn-lg btn-reset-lh h3 float-right mt-1"
            >
              <strong>{trans("button.edit")}</strong>
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  form: `addition-${ownProps.id}`,
  // Filter resourceAdditions to only use the available ones for the current reservation
  resourceAdditions: (ownProps.resource_additions || [])
    .filter(
      (a) =>
        (state.reservation.additions || []).findIndex(
          (addition) => addition.additionResourceId === parseInt(a.id, 10)
        ) !== -1
    )
    .map((a) => {
      const proposalAddition = (state.reservation.additions || []).find(
        (addition) => addition.additionResourceId === parseInt(a.id, 10)
      );
      return { ...a, price: proposalAddition.price };
    }),
  activeCategory: state.reservation.filters.addition_category,
  resortId: reservationSelectors.getResortId(state),
  selectedAddition:
    state.form[`addition-${ownProps.id}`] &&
    state.form[`addition-${ownProps.id}`].values
      ? Object.keys(state.form[`addition-${ownProps.id}`].values).filter(
          (i) =>
            parseInt(state.form[`addition-${ownProps.id}`].values[i], 10) > 0
        )
      : [],
  billableSubjects: totalBillableSubjects(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetForm: () => dispatch(reset(`addition-${ownProps.id}`)),
});

AdditionBlock.propTypes = {
  activeCategory: PropTypes.string,
  billableSubjects: PropTypes.number,
  media: PropTypes.object,
  properties: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  resourceAdditions: PropTypes.array,
  value: PropTypes.string,
  resortId: PropTypes.number,
  selectedAddition: PropTypes.array,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    destroyOnUnmount: false,
    enableReinitialize: true,
  })
)(AdditionBlock);
