import { get } from './'

export const isWebChatAvailable = () =>
  get({ url: '/chat/status' }).then(
    ({ data }) => ({
      successful: true,
      available: parseInt(data['Logged On'], 10) > 0
    }),
    () => ({
      successful: false,
      available: false
    })
  )
