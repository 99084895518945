export const SET_ALTERNATE_LANG_URLS = "contentful/SET_ALTERNATE_LANG_URLS";
export const CLEAR_ALTERNATE_LANG_URLS = "contentful/CLEAR_ALTERNATE_LANG_URLS";

export const setAlternateLangUrls = (slug, alternateLangUrls) => ({
  type: SET_ALTERNATE_LANG_URLS,
  payload: {
    slug,
    alternateLangUrls,
  },
});

export const clearAlternateLangUrls = () => ({
  type: CLEAR_ALTERNATE_LANG_URLS,
});
