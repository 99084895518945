import { gql } from "@apollo/client";

import activity from "../data/activity";
import activityEvent from "../data/activityEvent";
import activityEventSeparator from "../data/activityEventSeparator";

const fragment = gql`
  fragment ActivityTopic on Activity {
    ...Activity
    description {
      json
      links {
        entries {
          inline {
            ...Link
          }
          block {
            ...Link
          }
        }
        assets {
          block {
            ...Asset
          }
        }
      }
    }
    longDescription {
      json
      links {
        entries {
          inline {
            ...Link
          }
          block {
            ...Link
          }
        }
        assets {
          block {
            ...Asset
          }
        }
      }
    }
    categories: categoriesCollection(limit: 10) {
      items {
        type: __typename
        ... on Category {
          sys {
            id
          }
          title
          linkedFrom {
            activities: activityCollection {
              items {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
    images: imagesCollection(limit: 10) {
      type: __typename
      items {
        ...Asset
      }
    }
    startingTime
    priceTable {
      pricesCollection(limit: 10) {
        items {
          title
          condition
          price
        }
      }
      conditionsCollection(limit: 10) {
        items {
          icon
          condition {
            json
          }
        }
      }
    }
    activityEventSchedule {
      sys {
        id
      }
      title
      activityEventCollection(limit: 10) {
        items {
          type: __typename
          ...ActivityEvent
          ...ActivityEventSeparator
        }
      }
    }
  }

  ${activity}
  ${activityEvent}
  ${activityEventSeparator}
`;

export default fragment;
