import styled from "styled-components";
import { ReactComponent as InfoIcon } from "@fortawesome/fontawesome-free/svgs/solid/info-circle.svg";

export default styled(InfoIcon)`
  fill: ${({ theme }) => theme.color.secondary};
  position: relative;
  width: 1em;
  height: 1em;
  top: 0.125em;
`;
