import { Icon } from "@iconify/react";
import { PriceTable } from "@libema/content-sdk";
import {
  Col,
  Currency,
  DEVICE_SM,
  DEVICE_SM_DOWN,
  Row,
  P,
} from "@libema/design-system";
import RichText from "../RichText";
import RichTextRenderer from "../../renderer/RichTextRenderer";
import styled from "styled-components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

type PriceTableContentProps = {
  priceTable?: PriceTable;
  icons?: string;
};

const PriceCondition = styled(Col).attrs({
  col: 5,
})`
  @media ${DEVICE_SM_DOWN} {
    display: none;
  }
`;

const PriceTableCondition = styled(Row)`
  > span:first-of-type {
    margin-left: 0.8rem;
    flex: 0.1;
  }

  > span:last-of-type {
    flex: 0.9;
  }

  @media ${DEVICE_SM_DOWN} {
    ${RichText} {
      > ${P} {
        font-size: 0.7rem !important;
      }
    }
  }
`;

const PriceTableContent = ({ priceTable, icons }: PriceTableContentProps) => {
  const { width } = useWindowDimensions();
  const mobile = width <= DEVICE_SM;

  return priceTable?.prices && priceTable?.conditions ? (
    <div style={{ maxWidth: `${width - 30}px` }}>
      {priceTable.prices.map(({ title, condition, price }) => (
        <Row key={`price-table-item-${title}`} className="price-table-item">
          <Col col={mobile ? 8 : 4}>
            <b>{title}</b>
          </Col>
          <PriceCondition>{condition}</PriceCondition>
          <Col col={mobile ? 4 : 3}>
            <Currency
              currency="EUR"
              size="small"
              showSymbol
              showDecimalSeparator
              price={price}
            />
          </Col>
        </Row>
      ))}
      {priceTable.conditions.map(({ text, icon }, idx: number) => (
        <PriceTableCondition
          key={`price-table-condition-${idx}`}
          className="price-table-condition"
        >
          <span>
            {icon && (
              <Icon
                icon={icons ? icons[icon] : ""}
                className={icon.toLowerCase()}
                width="24"
                height="24"
              />
            )}
          </span>
          <span>
            <RichText>
              <RichTextRenderer text={text} />
            </RichText>
          </span>
        </PriceTableCondition>
      ))}
    </div>
  ) : null;
};

export default PriceTableContent;
