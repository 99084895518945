import React from 'react'
import PropTypes from 'prop-types'

const Loader = ({ isShown, label }) => {
  if (!isShown) {
    return null
  }

  return (
    <div className='loading-block transparent padded text-center'>
      <span className='loading loading-spin'>
        {label}
        &hellip;
      </span>
    </div>
  )
}

Loader.propTypes = {
  isShown: PropTypes.bool,
  label: PropTypes.string
}

export default Loader
