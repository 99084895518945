import styled, { css } from "styled-components";

import { Z_INDEX } from "./constants";

const BackgroundImage = styled.div<{
  withDarkGradient?: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${Z_INDEX.background};

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  ${({ withDarkGradient }) =>
    withDarkGradient &&
    css`
      :after {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.35) 0%,
          rgba(0, 0, 0, 0) 60%,
          rgba(81, 28, 28, 0.35) 100%
        );
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
      }
    `};
`;

export default BackgroundImage;
