import { createSelector } from 'reselect'

export const getAccommodationTypes = state => state.entities.accommodationtypes
export const getBlocks = state => state.entities.blocks
export const getKinds = state => state.entities.kinds
export const getProperties = state => state.entities.properties
export const getProperty = id => state => state.entities.properties[id]
export const getPropertyGroups = state => state.entities.propertygroups
export const getRedirects = state => state.entities.redirects
export const getResorts = state => state.entities.resorts
export const getSubjects = state => state.entities.subjects

export const getBlocksOfType = type => createSelector(
  getBlocks,
  allBlocks => Object.values(allBlocks).filter(b => b.type === type).sort((a, b) => a.sort - b.sort)
)

export const isFirstBlockOfType = (id, type) => createSelector(
  getBlocksOfType(type),
  (blocks) => {
    if (blocks.length && id === blocks[0].id) {
      return true
    }
    return false
  }
)

export const getAccommodationPopularity = createSelector(
  getAccommodationTypes,
  accommodations => Object.values(accommodations)
    .reduce((acc, accommodation) => ({
      ...acc,
      [accommodation.id]: accommodation.score
    }), {})
)

export default {
  getAccommodationTypes
}
