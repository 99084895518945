import { gql } from "@apollo/client";

const fragment = gql`
  fragment ActivityEvent on ActivityEvent {
    sys {
      id
    }
    title
    description {
      json
    }
    location {
      name
      park {
        name
      }
    }
    ageGroup {
      sys {
        id
      }
      label
      icon
    }
    recurrentScheduleCollection(limit: 20) {
      items {
        sys {
          id
        }
        weekday
        openTime
        closeTime
        exceptions
        startDate
        endDate
      }
    }
    specialScheduleCollection(limit: 10) {
      items {
        sys {
          id
        }
        date
        openTime
        closeTime
      }
    }
  }
`;

export default fragment;
