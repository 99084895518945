import React from 'react'
import PropTypes from 'prop-types'
import trans from 'counterpart'

const PriceTagUnavailable = ({ message, moreInfoLink }) => (
  <div className='row-disabled'>
    <div className='price-tag compact'>
      {message ? (
        <span dangerouslySetInnerHTML={{ __html: message }} />
      ) : (
        <span>
          {`${trans('newyse.accommodation.unavailable')} `}
          <a
            href={moreInfoLink}
            style={{ textDecoration: 'underline' }}
            dangerouslySetInnerHTML={{ __html: `${trans('newyse.accommodation.check')}` }}
          />
          {` ${trans('newyse.accommodation.unavailable2')}`}
        </span>
      )}
    </div>
  </div>
)

PriceTagUnavailable.propTypes = {
  message: PropTypes.string,
  moreInfoLink: PropTypes.string
}

export default PriceTagUnavailable
