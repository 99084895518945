export const getMediaUrl = (filepath) =>
  `https://${process.env.NEXT_PUBLIC_MEDIA_HOST}${filepath}`;

export const getSrc = (file, filter) => {
  const ext = file.split(".").pop();
  // Return the original file when it is not part of the allowed resizable file extensions,
  // or the `original` filter is passed.
  if (!["jpg", "jpeg", "png", "webp"].includes(ext) || filter === "original") {
    return getMediaUrl(`/originals/${file}`);
  }

  return getMediaUrl(`/cache/${filter}/${file}`);
};

export const backgroundImage = (file, filter, options = "") =>
  `url('${getSrc(file, filter)}') ${options}`;
