import axios from "axios";
import * as Sentry from "@sentry/nextjs";

export const postPreferences = (preferences) => {
  // Running in the CMS preview iframe
  if (
    window &&
    window.location !== window.parent.location &&
    window.parent.location.pathname.includes("/admin/designer/")
  ) {
    return Promise.resolve(false);
  }

  return axios.post("/api/preferences", preferences).catch((err) => {
    if (process.env.NODE_ENV !== "production") {
      console.error(err);
    }
    // Post to persist user preferences failed - no action taken as it is storing unimportant user info
  });
};

export const getPreferences = () => {
  return axios.get("/api/preferences").catch((err) => {
    if (process.env.NODE_ENV !== "production") {
      console.error(err);
    } else {
      Sentry.captureException(err);
    }
  });
};
