import { InputHTMLAttributes } from "react";
import * as React from "react";
import styled from "styled-components/macro";

type CheckMarkProps = {
  error?: boolean;
};

const CheckMark = styled.span<CheckMarkProps>`
  display: block;
  position: relative;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2px solid
    ${({ theme, error }) => (error ? theme.color.error : theme.color.grey_500)};
  z-index: 0;

  :after {
    content: "";
    position: absolute;
    display: none;
    left: 8px;
    top: 3px;
    width: 7px;
    height: 12px;
    border: solid ${({ theme }) => theme.color.dark};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const CheckboxWrapper = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 1.1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 25px;
    width: 25px;
    z-index: 1;
  }

  :hover input + ${CheckMark} {
    background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked + ${CheckMark} {
    background-color: #fff;
    border: 2px solid ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => theme.color.secondary};
  }

  input:focus + ${CheckMark} {
    border-color: ${({ theme }) => theme.color.secondary};
  }

  /* Show the checkmark when checked */
  input:checked + ${CheckMark}:after {
    display: block;
  }
`;

type CheckboxProps = {
  name?: string;
  id?: string;
  checked?: boolean;
  error?: boolean;
  onChange: (value: boolean) => void;
};

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  error,
  onChange,
  ...restProps
}) => (
  <CheckboxWrapper>
    <input
      {...restProps}
      type="checkbox"
      checked={checked}
      onChange={() => onChange(!checked)}
    />
    <CheckMark error={error} />
  </CheckboxWrapper>
);

export default Checkbox;
