import { get } from './'

export const getContent = (slug) =>
  get({
    url: `/content${slug}`
  })

export const getContentsSearch = (params) =>
  get({
    url: '/content/search',
    params
  })
