import React, { } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import HelpText from '../../../components/HelpText'
// import { groupValidateClasses, inputValidateClasses } from '../utilities';
import SelectField from './SelectField'
import RadioChoiceField from './RadioChoiceField'

const ChoiceOptionButton = props => (
  <label className={`btn btn-secondary btn-lg ${props.input.value === props.option.key ? 'active' : ''}`}>
    <input
      {...props.input}
      type='radio'
      value={props.option.key}
      id={`${props.input.name}-${props.option.key}`}
      checked={props.input.value === props.option.key}
      onChange={event => props.input.onChange(event)}
    />
    {props.option.value}
  </label>
)

ChoiceOptionButton.propTypes = {
  input: PropTypes.object,
  option: PropTypes.object
}

const ChoiceFieldBlock = ({ block, properties }) => {
  let element
  if (properties.type === 'button') {
    element = ChoiceOptionButton
  } else if (properties.type === 'radiobutton') {
    element = RadioChoiceField
  } else if (properties.type === 'select') {
    element = SelectField
  }

  const options = properties.options.reduce((a, b) => {
    a[b.key] = b.value
    return a
  }, [])

  return (
    <div className={properties.type !== 'select' ? 'form-group' : ''}>
      {element === SelectField && <Field name={block.name} component={element} options={options} />}
      {element === RadioChoiceField && <Field name={block.name} component={element} options={properties.options} />}
      {element !== SelectField && element !== RadioChoiceField && (
        <div className='input-group'>
          <div className='btn-group paw-radio-group'>
            {properties.options.map(option => (
              <Field key={option} name={block.name} component={element} option={option} />
            ))}
          </div>
        </div>
      )}
      <HelpText value={properties.helpText} />
    </div>
  )
}

ChoiceFieldBlock.propTypes = {
  block: PropTypes.object,
  properties: PropTypes.object
}

export default ChoiceFieldBlock
