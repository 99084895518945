import { FC } from "react";
import { captureException } from "@sentry/nextjs";

// Types
import { BaseModel, Section } from "@libema/content-sdk";

// Sections
import * as sectionComponents from "../sections";

// Providers
import { useComponents } from "../../providers/ComponentProvider";

type SectionsProps = {
  sections: Section[];
};

type SectionProps = {
  content: Section;
};

const SectionsRenderer: FC<SectionsProps> = ({ sections = [] }) => {
  return (
    <>
      {sections.map((section) => {
        const { id, type } = section;
        const sectionKey: string = id !== null ? id : type;
        if (id === null) {
          captureException(`section ${sectionKey} not found`);
          return <p>{`section ${sectionKey} not found`}</p>;
        }
        return <SectionRenderer key={sectionKey} content={section} />;
      })}
    </>
  );
};

const SectionRenderer: FC<SectionProps> = ({ content }) => {
  const { type } = content as BaseModel;
  const { customSections } = useComponents();

  const sectionName = type?.replace(/^section/, "");
  const mergedSectionComponents = {
    ...sectionComponents,
    ...customSections,
  };

  const SectionComponent = mergedSectionComponents[sectionName];

  if (!SectionComponent) {
    // eslint-disable-next-line  @typescript-eslint/restrict-template-expressions
    console.warn(`${sectionName} section not found`);
    return null;
  }
  return <SectionComponent {...content} />;
};

export default SectionsRenderer;
