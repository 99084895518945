import styled from "styled-components/macro";
import { ReactComponent as PlayIcon } from "@fortawesome/fontawesome-free/svgs/solid/play.svg";

const Button = styled.button`
  background-color: rgba(255, 255, 255, 0.5);
  color: ${({ theme }) => theme.textColor.default};
  font-weight: bold;
  border-radius: 50%;
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

const Icon = styled(PlayIcon)`
  fill: ${({ theme }) => theme.textColor.default};
  position: relative;
  width: 3rem;
  height: 3rem;
  margin-left: 0.75rem;
`;

const PlayButton = (props) => (
  <Button {...props}>
    <Icon />
  </Button>
);

export default PlayButton;
