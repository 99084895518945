import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import trans from 'counterpart'
import HelpText from '../../../components/HelpText'
import { groupValidateClasses, inputValidateClasses } from '../utilities'

const EmailField = ({ input, block, meta: { touched, error }, block: { properties } }) => (
  <div className={groupValidateClasses(touched, error)}>
    <div className='col-lg-12'>
      <input
        type='email'
        {...input}
        id={block.name}
        className={inputValidateClasses(touched, error)}
        name={block.name}
        placeholder={properties.label}
        onChange={event => input.onChange(event)}
      />
      {touched && error && <div className='form-control-feedback'>{error}</div>}
      <HelpText value={properties.helpText} />
    </div>
  </div>
)

EmailField.propTypes = {
  input: PropTypes.object,
  block: PropTypes.object,
  meta: PropTypes.object
}

const isValidEmail = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
  ? trans('form.field_email_invalid')
  : undefined

const EmailFieldBlock = ({ block }) => {
  const validators = [isValidEmail]
  return (
    <Field
      name={block.name}
      component={EmailField}
      block={block}
      validate={validators}
    />
  )
}

EmailFieldBlock.propTypes = {
  block: PropTypes.object
}

export default EmailFieldBlock
