import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

const CollectionFilterText = ({ displayName, name, placeholder }) => (
  <div className='collection-filter-text'>
    {displayName && (
      <label
        htmlFor={`collection-filter-${name}`}
        className='collection-filter-label'
      >
        {displayName}
      </label>
    )}
    <div className='collection-filter-widget'>
      <Field
        id={`collection-filter-${name}`}
        name={name}
        component='input'
        type='text'
        className='form-control'
        placeholder={placeholder}
      />
    </div>
  </div>
)

CollectionFilterText.propTypes = {
  displayName: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string
}

export default CollectionFilterText
