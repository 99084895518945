import React, { FC, AnchorHTMLAttributes } from "react";
import NextLink, { LinkProps } from "next/link";
import { Button, ButtonProps } from "@libema/design-system";
import { LinkDisplayStyle } from "@libema/content-sdk";

type Props = LinkProps & {
  onClick?: (e: any) => void;
  className?: string;
  href?: string;
  anchorProps?: AnchorHTMLAttributes<HTMLAnchorElement | HTMLButtonElement>;
  buttonProps?: ButtonProps;
  as?: LinkDisplayStyle;
  children?: React.ReactNode;
  target?: string;
  phoneNumber?: string;
};

type ButtonPhoneProps = {
  children?: React.ReactNode;
  phoneNumber: string;
  buttonProps?: ButtonProps;
  className?: string;
};

type ButtonLinkProps = {
  onClick?: (e: any) => void;
  className?: string;
  href?: string;
  buttonProps?: ButtonProps;
  children?: React.ReactNode;
  target?: string;
};

type AnchorProps = {
  onClick?: (e: any) => void;
  className?: string;
  href?: string;
  anchorProps?: AnchorHTMLAttributes<HTMLAnchorElement | HTMLButtonElement>;
  children?: React.ReactNode;
  target?: string;
};

type HTMLButtonAnchorElement = HTMLButtonElement & HTMLAnchorElement;

const ButtonPhone = React.forwardRef<HTMLButtonAnchorElement, ButtonPhoneProps>(
  ({ children, phoneNumber, buttonProps = {}, className }, ref) => {
    const parsedPhone = phoneNumber.replace(/(\d|\+)|[^]/g, "$1");
    return (
      <Button
        as="a"
        {...buttonProps}
        ref={ref}
        className={className}
        onClick={(e) => {
          e.preventDefault(e);
          window.open(`tel:${parsedPhone}`);
        }}
      >
        {children}
      </Button>
    );
  }
);

const ButtonLink = React.forwardRef<HTMLButtonAnchorElement, ButtonLinkProps>(
  ({ onClick, href, target, buttonProps = {}, children, className }, ref) => {
    return (
      <Button
        as="a"
        {...buttonProps}
        href={href}
        target={target}
        onClick={onClick}
        ref={ref}
        className={className}
      >
        {children}
      </Button>
    );
  }
);

const Anchor = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  ({ onClick, href, target, anchorProps = {}, children, className }, ref) => {
    return (
      <a
        {...anchorProps}
        href={href}
        target={target}
        onClick={onClick}
        ref={ref}
        className={className}
      >
        {children}
      </a>
    );
  }
);

const Link: FC<Props> = ({
  className,
  children,
  as: asProp,
  target,
  phoneNumber,
  onClick,
  locale = "nl",
  anchorProps = {},
  buttonProps = {},
  ...props
}) => {
  const LinkElement = asProp === LinkDisplayStyle.BUTTON ? ButtonLink : Anchor;
  const isPhoneLink = asProp === LinkDisplayStyle.BUTTON_PHONE;

  return isPhoneLink && phoneNumber ? (
    <ButtonPhone
      phoneNumber={phoneNumber}
      buttonProps={buttonProps}
      className={className}
    >
      {children}
    </ButtonPhone>
  ) : (
    <NextLink {...props} passHref locale={locale}>
      <LinkElement
        target={target}
        anchorProps={anchorProps}
        buttonProps={buttonProps}
        className={className}
        onClick={onClick}
      >
        {children}
      </LinkElement>
    </NextLink>
  );
};

export default Link;
