import React, { } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import TextareaField from './TextareaField'

const TextareaFieldBlock = (props) => {
  const { name, properties } = props

  return <Field name={name} component={TextareaField} placeholder={properties.placeholder} />
}

TextareaFieldBlock.propTypes = {
  name: PropTypes.string,
  properties: PropTypes.object
}

export default TextareaFieldBlock
