import styled from "styled-components/macro";
import Label from "./Label";

export type FormGroupProps = {
  inline?: boolean;
};

export default styled.div<FormGroupProps>`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
  margin-bottom: 1rem;
  align-items: ${({ inline }) => (inline ? "center" : "stretch")};

  > *:first-child {
    margin-right: 1rem;
  }

  ${Label} {
    ${({ inline }) => inline && "margin-bottom: 0;"}
    min-width: 100px;
  }
`;
