import * as React from "react";
import styled from "styled-components";
import { lighten } from "polished";
import { ReactComponent as InstagramIcon } from "@fortawesome/fontawesome-free/svgs/brands/instagram.svg";
import { ReactComponent as FacebookIcon } from "@fortawesome/fontawesome-free/svgs/brands/facebook-f.svg";

const IconLink = styled.a`
  display: inline-block;
  margin-right: ${({ theme }) => theme.margin.sm};
  width: 60px;
  height: 50px;
  border: 1px solid ${({ theme }) => lighten(0.5, theme.textColor.default)};
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: ${({ theme }) => theme.textColor.default};
    height: 30px;
  }
  &:hover {
    svg {
      fill: ${({ theme }) => lighten(0.2, theme.textColor.default)};
    }
  }
`;

export type SocialIconProps = {
  platform: "instagram" | "facebook";
  href: string;
  className?: string;
  label?: string;
};

const SocialIcon: React.FC<SocialIconProps> = ({
  platform,
  href,
  className,
  label,
}) => (
  <IconLink href={href} className={className} aria-label={label}>
    {platform === "instagram" && <InstagramIcon title={label} />}
    {platform === "facebook" && <FacebookIcon title={label} />}
  </IconLink>
);

export default SocialIcon;
