import React from 'react'
import PropTypes from 'prop-types'
// import Media from 'react-media';
import * as common from '../../utilities/common'

const CardGroupBlock = (props) => {
  const { id, properties, cplaceholder, entities, manageTags } = props

  let isMosaic = false
  let wrappedChilds = []
  let childs = common.objectToArray(entities).filter(child => child.parentId === id)

  if (properties.preset.indexOf('mosaic') > -1 && childs.length > 1) {
    isMosaic = true
    wrappedChilds = childs.slice(1)
    childs = childs.slice(0, 1)
  }

  return (
    <div className={`card-group ${properties.preset}`} {...manageTags}>
      {cplaceholder(null, 0, null, childs)}
      {isMosaic && (
        <div className='card-wrap'>
          {cplaceholder(null, 0, null, wrappedChilds)}
        </div>
      )}
    </div>
  )
}

CardGroupBlock.propTypes = {
  id: PropTypes.number,
  properties: PropTypes.object,
  cplaceholder: PropTypes.func,
  entities: PropTypes.object
}

export default CardGroupBlock
