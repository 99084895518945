import React from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'reactstrap'

const sortTabs = (tabs) => {
  let iTabs = tabs
  if (!Array.isArray(tabs)) {
    iTabs = Object.values(tabs)
  }
  return iTabs.sort((aTab, bTab) => {
    const a = parseInt(aTab.sort, 10)
    const b = parseInt(bTab.sort, 10)
    if (a > b) return -1
    if (a < b) return 1
    return 0
  })
}

class Accordion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeTab: 0
    }
    this.sortedTabs = sortTabs(props.properties.tabs)
  }

  handleClick = (activeTab) => {
    if (this.state.activeTab !== activeTab) {
      return this.setState({ activeTab })
    }

    return this.setState({ activeTab: null })
  }

  render () {
    const { id, placeholder, manageTags } = this.props
    const { activeTab } = this.state
    return (
      <div
        className='accordion'
        id={`accordion-${id}`}
        role='tablist'
        aria-multiselectable='true'
        {...manageTags}
      >
        {this.sortedTabs.map((tab, i) => (
          <div key={tab.key} className='card panel'>
            <div className='panel-heading' role='tab' id={`heading-${id}-${tab.key}`} onClick={() => this.handleClick(i)}>
              <div className='panel-title'>
                <a
                  aria-expanded='true'
                  aria-controls={`accordion-${id}-${tab.key}`}
                  className={i === activeTab ? '' : 'collapsed'}
                >
                  {tab.label}
                </a>
              </div>
            </div>
            <Collapse isOpen={i === activeTab}>
              <div className='panel-body'>
                {placeholder(tab.key)}
              </div>
            </Collapse>
          </div>
        ))}
      </div>
    )
  }
}

Accordion.propTypes = {
  id: PropTypes.number,
  placeholder: PropTypes.func,
  properties: PropTypes.object
}

export default Accordion
