import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { backgroundImage } from '../../utilities/mediaUtilities'
import JumbotronVideoBlock from './JumbotronVideoBlock'
import SearchForm from '../SearchForm'
import TicketBar from '../TicketBar'
import RichText from '../../modules/content/components/RichText'

const JumbotronBlock = (props) => {
  const { value, properties, media, manageTags } = props
  const jumbotronStyling = {}
  let classNames
  jumbotronStyling.backgroundImage = `${media && media.reference ? backgroundImage(media.reference, 'jumbotron') : 'none'}`
  if (properties.orientation) {
    jumbotronStyling.backgroundPosition = properties.orientation
  }

  if (media && media.content_type && media.content_type.includes('image')) {
    classNames = 'jumbotron'
    if (properties.extra_classes) {
      // extra_classes is an array if more than one extra class is present, otherwise it is a string
      if (properties.extra_classes instanceof Array) {
        classNames = classNames.concat(' ', properties.extra_classes.join(' '))
      } else {
        classNames = classNames.concat(' ', properties.extra_classes)
      }
    }

    // styles sometimes returns as an object and sometimes as array.
    if (properties.styles instanceof Array) {
      classNames = classNames.concat(' ', properties.styles.join(' '))
    } else {
      classNames = classNames.concat(' ', Object.values(properties.styles).map(style => style).join(' '))
    }

    classNames = classNames.concat(' ', 'has-img')
  }

  return (
    <div style={{ position: 'relative' }} {...manageTags}>
      {/* if content_type is a video, render the jumbrotron with video capabilities. */}
      {/* media.content_type.includes('video') && <JumbotronVideoBlock {...props} /> */}
      {media && media.content_type && media.content_type.includes('video') && (
        <JumbotronVideoBlock
          {...props}
          jumbotronStyling={jumbotronStyling}
          classNames={classNames}
        />
      )}
      {media && media.content_type && media.content_type.includes('image') && (
        <div
          className={classNames}
          id={`${properties.id ? properties.id : ''}`}
          style={jumbotronStyling}
        >
          <RichText className='jumbotron-caption' value={value} />
        </div>
      )}
      {properties.template === 'search_form' && (
        <div className='affix-form'>
          <SearchForm
            focus={props.properties.focus}
            focusPriority={props.properties.focus_priority}
          />
        </div>
      )}
      {properties.template === 'ticket_bar' && <TicketBar {...props.properties} />}
    </div>
  )
}

JumbotronBlock.propTypes = {
  block: PropTypes.object.isRequired,
  media: PropTypes.object,
  properties: PropTypes.object,
  value: PropTypes.string
}

const mapStateToProps = (state, ownProps) => ({
  attributes: state.content[ownProps.block.contentId] ? state.content[ownProps.block.contentId].attributes : []
})

export default connect(
  mapStateToProps
)(JumbotronBlock)
