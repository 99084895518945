import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, reduxForm, change } from 'redux-form'
import qs from 'query-string'
import CollectionFilterText from './CollectionFilterText'
import CollectionFilterButtons from './CollectionFilterButtons'
import CollectionFilterChecklist from './CollectionFilterChecklist'

/**
 * Checks if the filter name matches a query param.
 * If so, it passes the value to the filter
 *
 * @param {object} props
 */
const getInitialValues = (props) => {
  const values = {}
  if (typeof window === 'undefined') return values
  const urlParams = qs.parse(window.location.search)
  props.filters.forEach((filter) => {
    if (urlParams[filter.name]) {
      values[filter.name] = urlParams[filter.name]
    }
  })

  return values
}

/**
 * Creates a form with all the collection filters
 */
class CollectionFilter extends Component {
  componentDidMount = () => {
    const values = getInitialValues(this.props)
    Object.keys(values).forEach((field) => {
      this.props.updateField(field, values[field])
    })
  }

  renderFilter = (filter) => {
    switch (filter.type) {
      case 'buttons': {
        return <CollectionFilterButtons {...filter} />
      }
      case 'checklist': {
        return <CollectionFilterChecklist {...filter} />
      }
      case 'text':
      default: {
        return <CollectionFilterText {...filter} />
      }
    }
  }

  render = () => {
    const { handleSubmit, filters } = this.props

    return (
      <div className='collection-filter'>
        <Form
          onSubmit={handleSubmit}
          onKeyPress={(e) => {
            // Avoid submitting form when hitting the "Enter" keyboard key.
            // Apparently redux-form triggers this automatically when a form has only one field
            if (e.key === 'Enter') e.preventDefault()
          }}
        >
          {filters && filters.map(filter => (
            <div className='form-group' key={filter.name}>
              {this.renderFilter(filter)}
            </div>
          ))}
        </Form>
      </div>
    )
  }
}

CollectionFilter.propTypes = {
  handleSubmit: PropTypes.func,
  filters: PropTypes.array,
  updateField: PropTypes.func.isRequired
}

export default connect(
  (state, ownProps) => ({
    initialValues: getInitialValues(ownProps)
  }),
  (dispatch, ownProps) => ({
    updateField: (field, value) => dispatch(change(ownProps.form, field, value))
  })
)(reduxForm({
  // Form name should be passed by parent component
  destroyOnUnmount: false
})(CollectionFilter))
