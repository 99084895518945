import { combineReducers } from "redux";
import { reducer as reduxForm } from "redux-form";
import content from "../modules/content/reducer";
import social from "./social";
import environment from "./environmentReducer";
import reservation from "../modules/reservation/reducer";
import calendar from "../modules/calendar/reducer";
import compare from "../modules/compare/reducer";

import entities from "./entityReducer";
import preferences from "./preferenceReducer";
import filters from "./filterReducer";
import contentful from "./contentfulReducer";

const combinedReducers = {
  content,
  entities,
  filters,
  preferences,
  environment,
  social,
  form: reduxForm,
  reservation,
  calendar,
  compare,
  contentful,
};

const reducer = combineReducers(combinedReducers);

export default reducer;
