import { gql } from "@apollo/client";

const fragment = gql`
  fragment ActivityEventSeparator on ActivityEventSeparator {
    sys {
      id
    }
    recurrentScheduleCollection(limit: 10) {
      items {
        sys {
          id
        }
        weekday
        openTime
        closeTime
        exceptions
      }
    }
    specialScheduleCollection(limit: 10) {
      items {
        sys {
          id
        }
        date
        openTime
        closeTime
      }
    }
  }
`;

export default fragment;
