/**
 * DEPRECATED. Use `entitySelector.js` instead.
 *
 * Using this file is deprecated. All logic should be moved to `entitySelector.js`.
 */

import { createSelector } from 'reselect'
import { getSubjects } from '../../selectors/entitySelectors'

export const getObjects = state => state.entities.objects
export const resourceAdditionSelector = state => state.reservation.additions || []
export const criteriaSelector = state => state.reservation.criteria

export const subjectFormFieldSelector = createSelector(
  getSubjects,
  criteriaSelector,
  (subjects, criteria) => Object.keys(subjects).reduce((acc, subject) => {
    const match = criteria.subjects.find(cs => cs.subject_id === subject)
    acc[`subject-${subject}`] = (match && match.quantity) || 0
    return acc
  }, {})
)
