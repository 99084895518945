import { createContext, useContext, FC } from "react";

// Types
import { Header, Footer, Section } from "@libema/content-sdk";
import { NewsletterFormProps } from "@libema/design-system";

type FooterProps = Footer & {
  onNewsletterSubscribe?: NewsletterFormProps["onSubmit"];
};

type ContextType = {
  customSections: Record<string, FC<Section>>;
  customHeader: FC<Header>;
  customFooter: FC<FooterProps>;
};

const Context = createContext<ContextType>({
  customSections: {},
  customHeader: () => <p>Header</p>,
  customFooter: () => <p>Footer</p>,
});

export const useComponents = () => {
  if (Context == null) {
    throw new Error("Wrap useComponents inside a <ComponentProvider> element");
  }
  return useContext(Context);
};

export const ComponentProvider = ({
  customSections,
  customHeader,
  customFooter,
  ...props
}) => (
  <Context.Provider
    value={{ customSections, customHeader, customFooter }}
    {...props}
  />
);
