import { FC, HTMLAttributes } from "react";
import styled from "styled-components/macro";
import Container from "../../layout/Container";
import {
  DEVICE_LG_DOWN,
  DEVICE_LG_UP,
  DEVICE_SM_UP,
} from "../../../utils/deviceUtils";

export const SubMenuWrapper = styled.nav`
  display: none;
  background-color: ${({ theme }) => theme.textColor.default};
  padding-bottom: 16px;
  font-size: 20px;
  font-weight: 40px;
  color: #fff;
  z-index: 999;
  text-align: left;

  a {
    color: white;
    text-decoration: none;

    &:hover {
      color: white;
      text-decoration: underline;
    }

    &:visited {
      color: white;
      text-decoration: underline;
    }

    &:active {
      color: ${({ theme }) => theme.color.primary_main};
      text-decoration: underline;
    }
  }

  li {
    margin-bottom: 8px;
    padding: 0 16px 8px;
  }

  @media ${DEVICE_SM_UP} {
    ul {
      display: grid;
      grid-template-columns: auto auto;
    }
  }

  @media ${DEVICE_LG_DOWN} {
    ${Container} {
      max-width: 100%;
    }
  }

  @media ${DEVICE_LG_UP} {
    position: absolute;
    left: 0;
    width: 100%;
    padding-top: 42px;
    padding-bottom: 42px;

    ${Container} {
      display: grid;
      grid-template-columns: auto 288px;
      grid-column-gap: 16px;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-auto-rows: max-content;
    }
  }
`;

type SubMenuProps = HTMLAttributes<HTMLElement>;

const SubMenu: FC<SubMenuProps> = ({ children, ...props }) => {
  return (
    <SubMenuWrapper {...props}>
      <Container>{children}</Container>
    </SubMenuWrapper>
  );
};

export default SubMenu;
