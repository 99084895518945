import * as React from "react";
import styled from "styled-components";

// Types
import {
  Page,
  SectionHeroDisplayStyle,
  Activity,
  TYPES,
} from "@libema/content-sdk";

// Components
import {
  H1,
  H2,
  H3,
  P,
  Section,
  DEVICE_LG_UP,
  DEVICE_LG_DOWN,
} from "@libema/design-system";

// Local Components
import ActivityDetail from "../blocks/ActivityDetail/ActivityDetail";
import Gallery from "../blocks/Gallery";
// Utils
import SectionsRenderer from "../renderer/SectionsRenderer";
import RichTextRenderer from "../renderer/RichTextRenderer";
import RichText from "../blocks/RichText";

// Style
const StyledSection = styled(Section.Wrapper)`
  & h2:not(:first-child) {
    margin-top: 24px;
  }
`;

const Container = styled(Section.Container)`
  display: flex;
  flex-direction: row;
  margin: 0 auto;

  @media ${DEVICE_LG_UP} {
    max-width: 1140px;
  }

  @media ${DEVICE_LG_DOWN} {
    flex-direction: column;
    margin: 0 auto;
  }
`;

const RichTextStyle = styled(RichText)`
  padding: 0 55px;

  ${P},
  ${H2},
  ${H3} {
    color: black;
  }

  @media ${DEVICE_LG_DOWN} {
    flex: 0 0 100%;
    margin-top: 2rem;
    padding: 0;
  }
`;

export const GalleryStyle = styled.div`
  flex: 0 0 50%;

  @media ${DEVICE_LG_DOWN} {
    flex: 0 0 100%;
    padding: 0;
  }
`;

type Props = Page<Activity>;

const ActivityLayout: React.FC<Props> = ({ topic: activity, sections }) => {
  const { images, longDescription, subtitle, mainImage } = activity;

  return (
    <>
      {mainImage && (
        <SectionsRenderer
          sections={[
            {
              id: "ActivityLayout0",
              media: mainImage,
              children: <H1>{activity.title}</H1>,
              heroDisplayStyle: SectionHeroDisplayStyle.SHORT,
              withDarkGradient: true,
              type: TYPES.SectionHero,
            },
          ]}
        />
      )}
      <ActivityDetail
        activity={activity}
        backgroundColor={Section.BackgroundColor.WHITE}
        dayFormat="dddd"
      />
      <StyledSection backgroundColor={Section.BackgroundColor.GREY_100}>
        {subtitle && (
          <Section.Header>
            <H2>{subtitle}</H2>
          </Section.Header>
        )}
        <Container>
          <GalleryStyle>
            {images?.length > 0 && <Gallery images={images} />}
          </GalleryStyle>
          <RichTextStyle>
            {longDescription && <RichTextRenderer text={longDescription} />}
          </RichTextStyle>
        </Container>
      </StyledSection>

      <SectionsRenderer sections={sections} />
    </>
  );
};

export default ActivityLayout;
