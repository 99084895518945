import React, { Component } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import { Carousel as BootstrapCarousel, CarouselIndicators } from 'reactstrap'
import CarouselControls from './CarouselControls'

const Polyfills = dynamic(() => import('./CarouselPolyfill'), { ssr: false })

class Carousel extends Component {
  state = {
    activeIndex: 0
  }

  next = () => {
    const { children } = this.props

    this.setState(prevState => ({
      activeIndex: prevState.activeIndex === children.length - 1 ? 0 : prevState.activeIndex + 1
    }))
  }

  previous = () => {
    const { children } = this.props

    this.setState(prevState => ({
      activeIndex: prevState.activeIndex === 0 ? children.length - 1 : prevState.activeIndex - 1
    }))
  }

  handleIndicatorClick = index => this.setState({ activeIndex: index });

  render = () => {
    const { className, children, showIndicators } = this.props

    return (
      <BootstrapCarousel
        activeIndex={this.state.activeIndex}
        className={className}
        next={this.next}
        previous={this.previous}
        interval={false}
      >
        {showIndicators === true ? (
          <CarouselIndicators items={children} activeIndex={this.state.activeIndex} onClickHandler={this.handleIndicatorClick} />
        ) : null}
        {children}
        {children.length > 1 && (
          <CarouselControls next={this.next} prev={this.previous} />
        )}
        <Polyfills />
      </BootstrapCarousel>
    )
  }
}

Carousel.propTypes = {
  children: PropTypes.array.isRequired,
  className: PropTypes.string,
  showIndicators: PropTypes.bool
}

export default Carousel
