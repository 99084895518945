import React, { Component } from "react";
import PropTypes from "prop-types";
import trans from "counterpart";
import { connect } from "react-redux";
import { withRouter } from "next/router";
import { getContentsSearch } from "../../utilities/api/contentApi";
import Image from "../../modules/media/components/Image";
import Link from "../typography/Link";

class SearchResultsBlock extends Component {
  state = {
    isLoading: true,
    results: [],
    term: "",
    // total: 0
  };

  componentDidMount = () => {
    const { router, locale } = this.props;
    const params = router.query;

    this.setState({ term: params.search });

    this.search(params.search, locale);
  };

  componentDidUpdate = (prevProps) => {
    const { router, locale } = this.props;
    const params = router.query;

    if (
      params.search != null &&
      prevProps.router.query.search !== params.search
    ) {
      this.setState({ term: params.search });
      this.search(params.search, locale);
    }
  };

  search = (search, locale) => {
    getContentsSearch({ search, locale }).then(({ data }) =>
      this.setState({
        isLoading: false,
        results: data.results || [],
      })
    );
  };

  getFilename = (url) => url.substring(url.lastIndexOf("/") + 1);

  render = () => {
    const { manageTags } = this.props;
    const { isLoading, results, term } = this.state;

    return (
      <div className={manageTags}>
        <form
          method="get"
          action={trans("routes.search")}
          role="search"
          className="search-form"
        >
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder={trans("search.placeholder")}
              name="search"
              id="search"
              value={this.state.term}
              onChange={(event) => this.setState({ term: event.target.value })}
            />
            <span className="input-group-btn">
              <button type="submit" className="btn">
                <i className="material-icons">&#xE8B6;</i>
              </button>
            </span>
          </div>
        </form>
        <ul className="media-list search-list">
          {this.state.results &&
            this.state.results.length > 0 &&
            this.state.results.map((result) => (
              <li key={result.slug} className="media">
                {result.coverImage && (
                  <div className="media-img pl-0 pr-md-3 pr-sm-0 pr-xs-0">
                    <Link to={result.slug}>
                      <Image
                        className="media-object img-responsive"
                        file={this.getFilename(result.coverImage)}
                        filter="thumbnail_md"
                        attr={{
                          className: "img-responsive",
                          alt: result.title,
                        }}
                      />
                    </Link>
                  </div>
                )}
                <div
                  className={`media-body ${
                    result.coverImage ? "mt-md-0 mt-xs-3" : "col-sm-12"
                  }`}
                >
                  <Link to={result.slug}>
                    <>
                      <h2 className="media-title">{result.title}</h2>
                      <p className="media-description mb-md-3 mb-xs-0">
                        {result.description
                          ? result.description.slice(0, 300)
                          : ""}
                      </p>
                    </>
                  </Link>
                </div>
              </li>
            ))}
          {!isLoading && term && term !== "" && results.length < 1 && (
            <li className="media">
              {`${trans("search.no_results")} "${term}", ${trans(
                "search.try_again"
              )}`}
            </li>
          )}
        </ul>
      </div>
    );
  };
}

SearchResultsBlock.propTypes = {
  router: PropTypes.object.isRequired,
  locale: PropTypes.string,
  manageTags: PropTypes.string,
};

const mapState = (state) => ({
  locale: state.preferences.locale,
});

export default withRouter(connect(mapState)(SearchResultsBlock));
