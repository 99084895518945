// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";

// Components
import { Formik, Field } from "formik";
import { useIntl } from "react-intl";
import Button from "../Button";
import FormGroup from "../form/FormGroup";
import Alert, { AlertType } from "../Alert";
import ErrorMessage from "../form/formik/ErrorMessage";
import { TextField } from "../form/formik";
import Translate from "../typography/Translate";
import { Form, FieldContainer } from "./NewsletterForm.styles";

type NewsletterFormValues = {
  email: string;
};

type NewsletterFormErrors = {
  email?: string;
};

export type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: NewsletterFormValues) => Promise<any>;
};

export const NewsletterForm: React.FC<Props> = ({ onSubmit }) => {
  const intl = useIntl();
  const [isSuccessful, setIsSuccessful] = React.useState(false);

  return (
    <Formik<NewsletterFormValues>
      initialValues={{ email: "" }}
      onSubmit={(values, formikHelpers) => {
        setIsSuccessful(false);
        onSubmit(values)
          .then(() => {
            setIsSuccessful(true);
          })
          .catch(() => {
            formikHelpers.setSubmitting(false);
            formikHelpers.setFieldError(
              "email",
              intl.formatMessage({ id: "error.general" })
            );
          });
      }}
      validate={(values: NewsletterFormValues) => {
        const errors: NewsletterFormErrors = {};
        if (!values.email) {
          errors.email = intl.formatMessage({
            id: "newsletter.validator.required",
          });
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = intl.formatMessage({
            id: "newsletter.validator.email_invalid",
          });
        }

        return errors;
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          {isSuccessful && (
            <Alert type={AlertType.SUCCESS}>
              <Translate
                id="newsletter.success"
                values={{ email: values.email }}
              />
            </Alert>
          )}
          {!isSuccessful && (
            <FieldContainer>
              <FormGroup>
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  placeholder={intl.formatMessage({
                    id: "newsletter.placeholder.email",
                  })}
                />
                <ErrorMessage name="email" />
              </FormGroup>
              <Button
                colorType="secondary"
                type="submit"
                disabled={isSubmitting}
                size="small"
              >
                <Translate id="newsletter.button.subscribe" />
              </Button>
            </FieldContainer>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default NewsletterForm;
