import { gql } from "@apollo/client";

const fragment = gql`
  fragment FormField on FormField {
    sys {
      id
    }
    fieldType
    fieldName
    label
    required
    placeholder
    values
  }
`;

export default fragment;
