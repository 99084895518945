import React from "react";
import { connect } from "react-redux";
import { AdditionGroupForm } from "@libema/design-system-legacy";
import { updateAdditionCriteria } from "../../modules/reservation/actions";
import {
  getAdditionsByGroup,
  getAdditionGroup,
  getInitialAdditionGroupValues,
} from "../../selectors/additionSelectors";
import { getSrc } from "../../utilities/mediaUtilities";

const AdditionGroupFormContainer = ({
  additions,
  additionGroup,
  handleAdditionChange,
  initialValues,
  onSubmitted,
}) => {
  const handleSubmit = (values, { setSubmitting }) => {
    handleAdditionChange(values);
    setSubmitting(false);
    onSubmitted();
  };

  const NEXT_PUBLIC_MAX_ADDITION_QUANTITY =
    process.env.NEXT_PUBLIC_MAX_ADDITION_QUANTITY;

  return (
    <AdditionGroupForm
      onSubmit={handleSubmit}
      title={additionGroup.name}
      image={
        additionGroup.media && getSrc(additionGroup.media.reference, "sm_11")
      }
      description={additionGroup.description}
      additions={additions}
      initialValues={initialValues}
      subjects={parseInt(NEXT_PUBLIC_MAX_ADDITION_QUANTITY, 10)}
      maxQuantity={parseInt(NEXT_PUBLIC_MAX_ADDITION_QUANTITY, 10)}
    />
  );
};

const mapState = (state, { id }) => ({
  additions: getAdditionsByGroup(state, id),
  additionGroup: getAdditionGroup(state, id),
  initialValues: getInitialAdditionGroupValues(state, id),
});

const mapDispatch = (dispatch) => ({
  handleAdditionChange: (values) => dispatch(updateAdditionCriteria(values)),
});

export default connect(mapState, mapDispatch)(AdditionGroupFormContainer);
