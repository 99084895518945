import { FC } from "react";
import styled from "styled-components";

// Types
import { SectionGallery } from "@libema/content-sdk";

// Components
import {
  Section,
  DEVICE_LG_DOWN,
  DEVICE_LG_UP,
  H2,
} from "@libema/design-system";

// Local Components
import Gallery from "../blocks/Gallery";

// Utils
import RichTextRenderer from "../renderer/RichTextRenderer";
import RichText, { RichTextProps } from "../blocks/RichText";

const StyledSection = styled(Section.Wrapper)`
  & h2:not(:first-child) {
    margin-top: 24px;
  }
`;

const Container = styled(Section.Container)`
  display: flex;
  flex-direction: row;
  margin: 0 auto;

  @media ${DEVICE_LG_UP} {
    max-width: 1140px;
  }

  @media ${DEVICE_LG_DOWN} {
    flex-direction: column;
    margin: 0 auto;
  }
`;

const RichTextStyle = styled(RichText)<RichTextProps>`
  flex: 0 0 50%;
  padding: 0 1rem;

  @media ${DEVICE_LG_DOWN} {
    flex: 0 0 100%;
    margin-top: 2rem;
  }
`;

export const GalleryStyle = styled.div`
  flex: 0 0 50%;

  @media ${DEVICE_LG_DOWN} {
    flex: 0 0 100%;
    padding: 0;
  }
`;

type Props = SectionGallery;

const GallerySection: FC<Props> = ({
  title,
  images,
  description,
  backgroundColor,
}) => {
  return (
    <StyledSection backgroundColor={backgroundColor}>
      {title && (
        <Section.Header>
          <H2>{title}</H2>
        </Section.Header>
      )}
      <Container>
        <GalleryStyle>
          <Gallery images={images} />
        </GalleryStyle>
        {description && (
          <RichTextStyle backgroundColor={backgroundColor}>
            <RichTextRenderer text={description} />
          </RichTextStyle>
        )}
      </Container>
    </StyledSection>
  );
};

export default GallerySection;
