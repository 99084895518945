import styled from "styled-components";
import { ImageLoaderProps } from "next/image";

// Types
import {
  SectionTeaser,
  SectionTeaserDisplayStyle,
  Video,
} from "@libema/content-sdk";

// Components
import {
  Section,
  DEVICE_LG_DOWN,
  DEVICE_LG_UP,
  H2,
  DEVICE_MD_UP,
} from "@libema/design-system";
import RichTextRenderer from "../renderer/RichTextRenderer";
import RichText from "../blocks/RichText";
import SafeWebpImage from "../elements/SafeWebpImage";
import useSection from "../sectionUtils";
import VideoPlayer from "../blocks/VideoPlayer/VideoPlayer";

type SectionProps = Pick<SectionTeaser, "displayStyle" | "backgroundColor">;

const isTextStyle = (displayStyle: SectionTeaserDisplayStyle): boolean =>
  displayStyle === SectionTeaserDisplayStyle.TEXT ||
  displayStyle === SectionTeaserDisplayStyle.TEXT_VERTICAL;

const Visual = styled.div``;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 66%;
`;

const SectionHeader = styled(Section.Header)`
  padding-top: 3rem;

  @media ${DEVICE_LG_UP} {
    padding-top: 4rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICE_LG_UP} {
    flex-direction: row;
  }
`;

const VideoContainer = styled(Section.Container)<SectionProps>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media ${DEVICE_LG_UP} {
    max-width: 1140px;
  }

  @media ${DEVICE_LG_UP} {
    flex-direction: ${(props: SectionProps) =>
      props.displayStyle === SectionTeaserDisplayStyle.MEDIA_MIRROR
        ? "row-reverse"
        : "row"};
  }
`;

const SectionWrapper = styled(Section.Wrapper)<SectionProps>`
  &&& {
    padding: 0;

    @media ${DEVICE_LG_UP} {
      padding: 0;
    }
  }

  ${RichText} {
    flex: 1;
    padding: 3rem 1.5rem;

    @media ${DEVICE_LG_UP} {
      max-width: 570px;
    }

    @media ${DEVICE_LG_DOWN} {
      padding: 1rem 1.5rem;
    }

    @media ${DEVICE_LG_UP} {
      padding: 4rem 55px;
    }
  }
`;

const SectionVisual = styled(SectionWrapper)<SectionProps>`
  ${Visual} {
    display: flex;
    align-items: center;
    flex: 0 0 100%;

    @media ${DEVICE_LG_UP} {
      flex: 0 0 50%;
      padding: 0;
    }
  }

  ${({ displayStyle }) =>
    displayStyle === SectionTeaserDisplayStyle.MEDIA_MIRROR &&
    `
    ${Container} {
      flex-direction: row-reverse;

      ${Visual} {
        @media ${DEVICE_LG_UP} {
          padding: 0 0 0 0;
        }
      }
    }
  `}

  ${Container} {
    @media ${DEVICE_LG_DOWN} {
      flex-direction: column;
    }
  }

  ${Visual} {
    @media ${DEVICE_LG_UP} {
      padding: 0 0 0 0;
    }
  }

  ${RichText} {
    @media ${DEVICE_LG_UP} {
      align-items: center;
    }
  }
`;

const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?w=${width}&h=${Math.ceil(width * 0.66)}&q=${
    quality || 90
  }&fit=fill&f=faces&fm=webp`;
};

const TeaserVisual = ({
  title,
  description,
  media,
  displayStyle,
  backgroundColor,
}: SectionTeaser) => {
  const { isVideo } = useSection({ media });

  return (
    <SectionVisual
      displayStyle={displayStyle}
      backgroundColor={backgroundColor}
    >
      {title && (
        <SectionHeader>
          <H2>{title}</H2>
        </SectionHeader>
      )}
      {isVideo ? (
        <VideoContainer displayStyle={displayStyle}>
          <Visual>
            {media ? <VideoPlayer media={media as Video} /> : null}
          </Visual>
          <RichText backgroundColor={backgroundColor}>
            <RichTextRenderer text={description} />
          </RichText>
        </VideoContainer>
      ) : (
        <Container>
          <Visual>
            {media ? (
              <ImageContainer>
                <SafeWebpImage
                  layout="fill"
                  objectFit="cover"
                  sizes={`${DEVICE_LG_UP} 1200px, ${DEVICE_MD_UP} 700px, 300px`}
                  quality={90}
                  src={media.url}
                  alt={media.title}
                  loader={imageLoader}
                />
              </ImageContainer>
            ) : null}
          </Visual>
          <RichText backgroundColor={backgroundColor}>
            <RichTextRenderer text={description} />
          </RichText>
        </Container>
      )}
    </SectionVisual>
  );
};

const SectionText = styled(SectionWrapper)<SectionProps>`
  ${Container} {
    @media ${DEVICE_LG_UP} {
      justify-content: center;
      max-width: 1140px;
      margin: 0 auto;
    }
  }

  ${({ displayStyle }) =>
    displayStyle === SectionTeaserDisplayStyle.TEXT_VERTICAL &&
    `
  ${Container} {
    flex-direction: column;
    align-items: center;
  }

  ${RichText} {
    max-width: 100%;
  }
  ${RichText} + ${RichText} {
    padding-top: 0;
  }
`}
`;

const TeaserText = ({
  title,
  description,
  descriptionSecondary,
  displayStyle,
  backgroundColor,
}: SectionTeaser) => {
  return (
    <SectionText displayStyle={displayStyle} backgroundColor={backgroundColor}>
      {title && (
        <SectionHeader>
          <H2>{title}</H2>
        </SectionHeader>
      )}
      <Container>
        <RichText backgroundColor={backgroundColor}>
          <RichTextRenderer text={description} />
        </RichText>
        <RichText backgroundColor={backgroundColor}>
          <RichTextRenderer text={descriptionSecondary} />
        </RichText>
      </Container>
    </SectionText>
  );
};

const Teaser = ({ displayStyle, ...restProps }: SectionTeaser) => {
  const TeaserComponent = isTextStyle(displayStyle) ? TeaserText : TeaserVisual;

  return <TeaserComponent displayStyle={displayStyle} {...restProps} />;
};

export default Teaser;
