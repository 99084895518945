import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { getAttributeOptions } from '../../cms/actions'

class CollectionFilterButtons extends Component {
  componentDidMount = () => this.props.loadOptions(this.props.name);

  render () {
    const { displayName, options } = this.props

    return (
      <div className='collection-filter-buttons mt-4'>
        <label className='collection-filter-category'>{displayName}</label>
        {options.map(option => (
          <div className='form-check' key={option.id}>
            <Field
              name={`options[${option.id}]`}
              id={option.display_name}
              component='input'
              type='checkbox'
              className='form-check-input'
            />
            <label htmlFor={option.display_name} className='btn btn-outline-primary btn-sm'>
              {option.display_name}
            </label>
          </div>
        ))}
      </div>
    )
  }
}

CollectionFilterButtons.propTypes = {
  displayName: PropTypes.string,
  loadOptions: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array
}

const mapStateToProps = (state, ownProps) => ({
  options: state.entities.options
    ? Object.keys(state.entities.options)
      .filter(o => state.entities.options[o].attribute.name === ownProps.name.split('.')[1])
      .map(o => state.entities.options[o])
    : []
})

export default connect(
  mapStateToProps,
  dispatch => ({
    loadOptions: (name) => {
      switch (name.split('.')[0]) {
        case 'attribute': {
          dispatch(getAttributeOptions(name.split('.')[1]))
          break
        }
        default: {
          break
        }
      }
    }
  })
)(CollectionFilterButtons)
