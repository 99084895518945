import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'

/**
 * Note; the className of this was added as `text-right align-center-label`, while when used in
 * a DataviewBlock, the className was defined as `col-form-label` (e.g. on /boeking).
 */
const InputLabel = ({ isCompany, properties }) => {
  if (properties.inputName === 'company_name' && isCompany !== '1') {
    return null
  }

  return (
    <label htmlFor={properties.inputName} className='col-form-label' style={{ display: 'block' }}>
      {`${properties.displayName || ''} ${properties.required ? ' *' : ''}`}
    </label>
  )
}

InputLabel.propTypes = {
  isCompany: PropTypes.string,
  properties: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

const mapState = state => ({
  isCompany: formValueSelector('customer')(state, 'is_company')
})

export default connect(mapState)(InputLabel)
