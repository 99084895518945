import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import throttle from "lodash.throttle";
import moment from "moment";
import trans from "counterpart";
import {
  get,
  newCancellationToken,
  isCancellationError,
} from "../../../utilities/api";
import ArrivalDatePicker from "./pickers/ArrivalDatePicker";
import DepartureDatePicker from "./pickers/DepartureDatePicker";
import SubjectPicker from "./pickers/SubjectPicker";
import PriceTableCell from "./PriceTableCell";
import { INTERNAL_DATE } from "../../../constants/dates";
import { getSubjectsWithQuantity } from "../../preferences/selectors";
import { ChevronRight, ChevronLeft } from "../../../components/icons/Chevron";
import {
  getPreference,
  hasAdults,
} from "../../../selectors/preferenceSelectors";
import {
  getNights,
  findAvailableNight,
  findNextDate,
  calculateNextValidDate,
  calculateNextValidDate2,
} from "../utilities";
import { getBookingUrl } from "../../../utilities/preferenceUtilities";
import { stringifyObjectProperties } from "../../compare/utils";
import BookButton from "./search-and-book/BookButton";
import { darken } from "polished";
import {
  PREFERENCE_SPECIAL,
  PREFERENCE_VOUCHER,
} from "../../../constants/preferences";
import qs from "query-string";
import { APP_BEEKSEBERGEN } from "../../../constants/apps";
import { BEEKSEBERGEN_ARRIVAL_DAY_EXCEPTIONS } from "../../../constants/filters";
import { isBeekseBergen } from "../../../utilities/common";

const ROW_HEIGHT_NORMAL = 65;
const ROW_HEIGHT_SMALL = 76.5;
const DATE_FORMAT = "YYYY-MM-DD";

const sortByDate = (a, b) => {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

const HorizontalBar = styled.hr`
  margin-top: 0.65rem;
  margin-bottom: 0.65rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const PriceTableContent = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome scrollbar transparent */
  }
  scrollbar-width: none; /* Firefox scrollbar transparent */
  -ms-overflow-style: none; /* IE scrollbar transparent */
  position: relative;
  max-height: ${(props) =>
    (props.isSmallScreen ? ROW_HEIGHT_SMALL : ROW_HEIGHT_NORMAL) * 4}px;
  height: 100%;
  display: flex;
`;

const PriceTableContentLoader = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: white;
  opacity: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PriceRowColumn = styled.div.attrs((props) => ({
  className: props.bootstrapClass,
}))`
  list-style: none;
  padding: 0 !important;
  > :nth-child(odd) {
    background-color: ${(props) =>
      darken(0.03, props.theme.color.neutralLight)};
    padding: 0 15px;
    span {
      padding: 0 15px;
    }
  }
  > :nth-child(even) {
    background-color: ${(props) => props.theme.color.neutralLight};
    padding: 0 15px;
    span {
      padding: 0 15px;
    }
  }
`;

const PriceRow = styled.div`
  height: ${(props) =>
    props.isSmallScreen ? ROW_HEIGHT_SMALL : ROW_HEIGHT_NORMAL}px;
  display: flex;
  position: relative;
  border-bottom: ${(props) => props.theme.color.neutralLight};
`;

const PriceTableHeader = styled.h5`
  font-weight: 600;
  padding-bottom: 5px;
  color: black;
  margin-bottom: 0;
`;

const ScrollButton = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;
  z-index: 5;
  cursor: pointer;
  border: ${(props) => props.theme.color.neutralLight};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transform: rotate(90deg);
  right: 20px;
  background: ${(props) => props.theme.color.neutralLight};
`;

const ScrollButtonUp = styled(ScrollButton)`
  top: 76px;
`;

const ScrollButtonDown = styled(ScrollButton)`
  bottom: 5px;
`;

class PriceTableBlock extends Component {
  constructor(props) {
    super(props);
    this.cancellationToken = null;
    this.state = {
      isSmallScreen: false,
      openDeparture: false,
      arrivals: [],
      columns: [],
      departures: [],
      filters: {
        // eslint-disable-next-line no-nested-ternary
        arrival: props.focus
          ? props.focus.arrival
          : props.arrival
          ? props.arrival
          : null,
      },
      isLoading: true,
      pendingRequests: [],
      results: {},
      rows: [2, 3, 4, 7],
      selected: null,
      panic: false,
    };

    this.ref = React.createRef();
    this.departurePicker = React.createRef();
    this.throttled = throttle(this.resizeHandler, 150, { leading: true });
  }

  componentDidMount = () => {
    this.handleInitialData(this.props.arrival).then(() =>
      this.setState({
        selected: this.getFirstAvailableResult(),
        isLoading: false,
      })
    );
    window.addEventListener("resize", this.throttled);
    this.resizeHandler();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevState.selected && prevProps.arrival !== this.props.arrival) {
      this.setState({ selected: this.getFirstAvailableResult() });
    }

    if (
      stringifyObjectProperties(prevProps.selectedSubjects) !==
      stringifyObjectProperties(this.props.selectedSubjects)
    ) {
      this.handleInitialData(this.state.filters.arrival);
    }

    if (prevProps.arrival !== this.props.arrival) {
      if (this.state.columns.length === 0) {
        this.setState(
          {
            loading: true,
          },
          () =>
            this.handleInitialData(this.props.arrival).then(() =>
              this.setState({
                selected: this.getFirstAvailableResult(),
                isLoading: false,
              })
            )
        );
      } else {
        console.log("changearrival date: ", this.props.arrival);
        this.changeArrivalDate(moment(this.props.arrival), () =>
          this.navigateToSpecificDay(this.props.arrival)
        );
      }
    }

    if (prevProps.departure !== this.props.departure) {
      this.changeDepartureDate(moment(this.props.departure));
      this.setState({ selected: this.getFirstAvailableResult() });
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.throttled);
  };

  getFirstAvailableResult = () => {
    const { results } = this.state;
    const { arrival, departure } = this.props;
    const currentResults = results[moment(arrival).format(DATE_FORMAT)];
    if (!currentResults) {
      return null;
    }
    let nights;
    if (departure) {
      nights = getNights(arrival, departure);
    } else {
      nights = findAvailableNight(currentResults, [...this.state.rows]);
    }
    return this.getResult(moment(arrival), nights);
  };

  getResult = (date, nights) => {
    const { results } = this.state;
    if (
      !results[date.format(DATE_FORMAT)] ||
      !results[date.format(DATE_FORMAT)][nights]
    ) {
      return null;
    }
    return results[date.format(DATE_FORMAT)][nights];
  };

  changeArrivalDate = (date, callback) => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          arrival: date.format(DATE_FORMAT),
          departure: undefined,
        },
      }),
      () => (callback ? callback() : this.handleAdditionalData(moment()))
    );
  };

  changeDepartureDate = (date) => {
    const arrival = moment(this.state.filters.arrival, DATE_FORMAT);
    const nights = date.diff(arrival, "days");
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        departure: date.format(DATE_FORMAT),
      },
      selected: this.getResult(arrival, nights),
    }));
  };

  toggleFilters = (type, bool) => {
    this.setState({ [type]: bool });
  };

  fetchResults = (date) => {
    if (this.cancellationToken) {
      // Cancel the existing request if it is defined
      this.cancellationToken.cancel(
        "Cancel search. State changed with new values."
      );
    }
    // Generate a new cancellation token, so we can cancel the current request when a new call is triggered.
    this.cancellationToken = newCancellationToken();

    const {
      block: { accommodation_type },
      selectedSubjects,
    } = this.props; // eslint-disable-line camelcase

    const rangeStart = moment.isMoment(date) ? date : moment(date);

    this.setState((prevState) => ({
      pendingRequests: [
        ...prevState.pendingRequests,
        rangeStart.format(DATE_FORMAT),
      ],
    }));
    const queryParams = qs.parse(window.location.search);

    console.log(rangeStart.format(DATE_FORMAT));

    return get({
      url: "/offers/range",
      params: {
        range_start: rangeStart.format(DATE_FORMAT),
        resource: accommodation_type.id,
        group_by: "price",
        sort: "arrival",
        direction: "asc",
        // alternative_periods: 1,
        ...this.state.filters,
        arrival: rangeStart.format(DATE_FORMAT),
        voucher_code:
          queryParams.voucher || this.props.voucher || queryParams.voucher_code,
        show_with_open_specials: queryParams.show_with_open_specials,
        special: queryParams.special,
        departure: undefined,
        subjects: Object.keys(selectedSubjects).map(
          (s) => `${s}-${selectedSubjects[s]}`
        ),
      },
      cancelToken: this.cancellationToken.token,
    })
      .then(({ data }) => {
        this.cancellationToken = null;
        this.setState((prevState) => ({
          pendingRequests: prevState.pendingRequests.filter(
            (date) => date !== rangeStart.format(DATE_FORMAT)
          ),
        }));
        const map = data.results.reduce(
          (map, item) => ({
            ...map,
            [item.arrival_date]: {
              ...map[item.arrival_date],
              [item.nights]: item,
            },
          }),
          {}
        );
        return {
          results: map,
          data,
        };
      })
      .catch((err) => {
        // Clear the cancellation token
        this.setState((prevState) => ({
          pendingRequests: prevState.pendingRequests.filter(
            (date) => date !== rangeStart.format(DATE_FORMAT)
          ),
        }));
        if (!isCancellationError(err)) {
          this.cancellationToken = null;
          console.error("Could not fetch search results");
        }
        return null;
      });
  };

  generateColumns = (startDate, items, direction = null, keepIndex) => {
    const formattedStartDate = startDate.format(INTERNAL_DATE);

    items.sort(sortByDate);

    let dateIndex = items.findIndex((item) => item === formattedStartDate);

    if (!keepIndex) {
      if (direction !== null) {
        dateIndex = dateIndex + direction; // eslint-disable-line operator-assignment
      } else {
        // Make sure initial loads position the startDate in the center
        dateIndex -= 2;
        if (dateIndex < 5) {
          dateIndex = 0;
        }
      }

      if (dateIndex > items.length - 5) {
        dateIndex = items.length - 5;
      }

      if (dateIndex < 0) {
        dateIndex = 0;
      }
    }

    const slice = items.slice(dateIndex, dateIndex + 5);

    return slice.map((item) => moment(item));
  };

  generateRows = (map) => {
    const allNights = Object.keys(map).reduce(
      (rows, arrival) =>
        Object.keys(map[arrival]).reduce(
          (brows, nights) => brows.concat(nights),
          rows
        ),
      []
    );

    return [...new Set(allNights)]
      .map((n) => parseInt(n, 10))
      .sort((a, b) => a - b);
  };

  navigateToSpecificDay = (nextDate) => {
    const nextValidDate = calculateNextValidDate(this.state.arrivals, nextDate);
    this.fetchNextResult(nextValidDate);
    this.setState((prevState) => ({
      columns: this.generateColumns(
        moment(nextValidDate),
        prevState.arrivals.slice(nextValidDate, prevState.arrivals.length),
        null,
        true
      ),
    }));
  };

  nextDay = () =>
    this.setState((prevState) => ({
      columns: this.generateColumns(
        prevState.columns[0],
        Object.keys(prevState.results),
        1
      ),
    }));

  previousDay = () =>
    this.setState((prevState) => ({
      columns: this.generateColumns(
        prevState.columns[0],
        Object.keys(prevState.results),
        -1
      ),
    }));

  handleClick = (up) => {
    const thisRef = this.ref.current;
    const scrollHeight = this.state.isSmallScreen
      ? ROW_HEIGHT_SMALL
      : ROW_HEIGHT_NORMAL;
    if (up) {
      thisRef.scrollTop -= scrollHeight;
    } else {
      thisRef.scrollTop += scrollHeight;
    }
  };

  resizeHandler = () => {
    const { isSmallScreen } = this.state;
    if (!isSmallScreen && window.innerWidth < 1200) {
      this.setState({ isSmallScreen: true });
    }
    if (isSmallScreen && window.innerWidth >= 1200) {
      this.setState({ isSmallScreen: false });
    }
  };

  handleInitialData = (date) => {
    return this.fetchResults(date).then((response) => {
      if (!response) return null;
      const { results, data } = response;
      if (data.panic) return this.setState({ panic: true });
      return new Promise((resolve) =>
        this.setState(
          {
            arrivals: data.arrivals.sort(sortByDate),
            results,
            departures: data.departures,
            columns: this.generateColumns(
              moment(data.params.arrival, DATE_FORMAT),
              Object.keys(results)
            ),
            rows: this.generateRows(results),
          },
          () => resolve()
        )
      );
    });
  };

  handleAdditionalData = (date) => {
    return this.fetchResults(date).then((response) => {
      if (!response) return null;
      return this.setState(
        (prevState) => ({
          results: {
            ...prevState.results,
            ...response.results,
          },
          arrivals: [
            ...new Set([...prevState.arrivals, ...response.data.arrivals]),
          ],
        }),
        () => this.setState({ selected: this.getFirstAvailableResult() })
      );
    });
  };

  fetchNextResult = (nextArrivalDate, forceFetchArrivalDate) => {
    this.handleAdditionalData(forceFetchArrivalDate || nextArrivalDate);
    this.setState((prevState) => ({
      results: {
        ...prevState.results,
        [nextArrivalDate]: {},
      },
    }));
  };

  nextResult = () => {
    if (!this.state.columns[this.state.columns.length - 1]) return null;
    const lastRenderedColumnDate = this.state.columns[
      this.state.columns.length - 1
    ]
      .clone()
      .format(DATE_FORMAT);
    // Check in arrivals if there is a next date available.
    const nextArrivalDate = findNextDate(
      this.state.arrivals,
      lastRenderedColumnDate
    );
    if (!nextArrivalDate) return null;
    return this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          arrival: nextArrivalDate,
        },
      }),
      () => {
        if (!this.state.results[nextArrivalDate]) {
          // Fetch from first column te ensure that every column is filled. Starting from the last would mean the preceeding ones aren't filled
          // in case the user presses the arrow multiple times (due to cancelling previous requests)
          this.fetchNextResult(
            nextArrivalDate,
            this.state.columns[0].format(DATE_FORMAT)
          );
        }
        this.nextDay();
      }
    );
  };

  previousResult = () => {
    const index = this.state.arrivals.findIndex(
      (date) => this.state.columns[0].format(DATE_FORMAT) === date
    );
    if (index === 0) return null;
    const previousArrivalDate = this.state.arrivals[index - 1];
    return this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          arrival: previousArrivalDate,
        },
      }),
      () => {
        if (!this.state.results[previousArrivalDate]) {
          this.fetchNextResult(previousArrivalDate);
        }
        this.previousDay();
      }
    );
  };

  getBookingRoute = () => {
    const { selected } = this.state;
    const { selectedSubjects, voucher, special } = this.props;

    const additionalParams = {};
    if (voucher) {
      additionalParams.voucher_code = voucher;
      if (special) additionalParams.special = special;
    }

    return getBookingUrl(
      selected,
      selectedSubjects,
      undefined,
      additionalParams
    );
  };

  render() {
    const { selected, isSmallScreen, panic } = this.state;
    const { hasAdults, subjects } = this.props;

    if (panic) return null;

    return (
      <div id="price-table" className="price-hidden">
        <div className="price-filters row">
          <div className="col-4">
            <SubjectPicker />
          </div>
          <div className="col-4">
            <ArrivalDatePicker
              date={this.state.filters.arrival}
              availableDates={Object.keys(this.state.results)}
              showInfoDialog={isBeekseBergen}
            />
          </div>
          <div className="col-4">
            <DepartureDatePicker
              picker={this.departurePicker}
              open={this.state.openDeparture}
              arrival={this.state.filters.arrival}
              date={this.state.filters.departure}
              showInfoDialog={isBeekseBergen}
              availableDates={this.state.departures}
            />
          </div>
        </div>

        {this.state.isLoading && (
          <div className="loading-block transparent padded text-center">
            <span className="loading loading-spin">
              {trans("label.loading_holidays")}
              &hellip;
            </span>
          </div>
        )}

        {!this.state.isLoading && (
          <div className="price-table container">
            <div className="row">
              <div
                className="price-grid col-9"
                style={{ position: "relative" }}
              >
                {Object.keys(this.state.results).length > 0 && (
                  <>
                    <ScrollButtonUp onClick={() => this.handleClick(true)}>
                      <ChevronLeft />
                    </ScrollButtonUp>
                    <ScrollButtonDown onClick={() => this.handleClick()}>
                      <ChevronRight />
                    </ScrollButtonDown>
                  </>
                )}
                <div className="price-header">
                  <div className="col-2 pl-0">
                    <i
                      onClick={this.previousResult}
                      className="material-icons left-arrow"
                    >
                      chevron_left
                    </i>
                  </div>

                  <div className="col-9 d-flex">
                    {this.state.columns.map((date) => (
                      <div
                        key={date.format(DATE_FORMAT)}
                        className={`header-cell ${
                          date.isSame(
                            moment(this.props.arrival, DATE_FORMAT),
                            "day"
                          )
                            ? "active-column"
                            : ""
                        }`}
                      >
                        {date.format("dddd")}
                        <br />
                        {date.format("DD-MM-YYYY")}
                      </div>
                    ))}
                  </div>

                  <div className="col-1 pr-0">
                    <i
                      onClick={this.nextResult}
                      className="material-icons right-arrow"
                    >
                      chevron_right
                    </i>
                  </div>
                </div>
                {Object.keys(this.state.results).length > 0 ? (
                  <PriceTableContent
                    ref={this.ref}
                    isSmallScreen={isSmallScreen}
                  >
                    {!this.state.isLoading &&
                      this.state.pendingRequests.length > 0 && (
                        <PriceTableContentLoader>
                          <span className="loading loading-spin" />
                        </PriceTableContentLoader>
                      )}
                    <PriceRowColumn bootstrapClass="col-2">
                      {this.state.rows.map((nights) => (
                        <div
                          key={nights}
                          className="price-cell"
                          style={{
                            height: isSmallScreen
                              ? ROW_HEIGHT_SMALL
                              : ROW_HEIGHT_NORMAL,
                          }}
                        >
                          <span>{`${nights} nachten`}</span>
                        </div>
                      ))}
                    </PriceRowColumn>
                    <PriceRowColumn bootstrapClass="col-10">
                      {this.state.rows.map((nights) => (
                        <PriceRow key={nights} isSmallScreen={isSmallScreen}>
                          {this.state.columns.map((date) => (
                            <PriceTableCell
                              key={`${date.format(DATE_FORMAT)}-${nights}`}
                              data={this.getResult(date, nights)}
                              onClick={() =>
                                this.setState({
                                  selected: this.getResult(date, nights),
                                })
                              }
                              active={
                                selected &&
                                date.isSame(
                                  moment(selected.arrival_date, DATE_FORMAT),
                                  "day"
                                ) &&
                                nights === selected.nights
                              }
                              activeColumn={date.isSame(
                                moment(this.props.arrival, DATE_FORMAT),
                                "day"
                              )}
                            />
                          ))}
                        </PriceRow>
                      ))}
                    </PriceRowColumn>
                  </PriceTableContent>
                ) : (
                  <div className="price-content no-price-found">
                    <div
                      className="center-message"
                      dangerouslySetInnerHTML={{
                        __html: trans("search.no_results"),
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="price-receipt col-3">
                <PriceTableHeader>
                  {this.props.block.accommodation_type.display_name ||
                    this.props.block.accommodation_type.name}
                </PriceTableHeader>
                {!selected && <span>{trans("label.select_stay")}</span>}
                {selected && (
                  <div>
                    <span className="date-receipt">{`${trans(
                      "stay.arrival"
                    )}: `}</span>
                    <span>
                      {selected.arrival_date ? selected.arrival_date : "-"}
                    </span>
                    <br />
                    <span className="date-receipt">{`${trans(
                      "stay.departure"
                    )}: `}</span>
                    <span>
                      {selected.departure_date ? selected.departure_date : "-"}
                    </span>
                    <br />
                    <HorizontalBar />
                    <span>
                      {`${trans("label.travel_company")}: `}
                      <br />
                      {subjects
                        .filter((subject) => subject.quantity > 0)
                        .map((subject) => (
                          <span
                            className="text-company text-lowercase"
                            key={subject.id}
                          >
                            {`${subject.quantity} x ${subject.singular_name}`}
                          </span>
                        ))}
                    </span>
                    <HorizontalBar />
                    {selected.special_name && (
                      <div>
                        <span>{`${trans("label.discount")}: ${
                          selected.special_name
                        }`}</span>
                        <br />
                      </div>
                    )}
                    {selected.from_price > selected.all_in_price ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{`${trans("label.from")}:`}</span>
                        <span className="old-price">
                          {` € ${selected.from_price} `}
                          <br />
                        </span>
                        <strong style={{ paddingBottom: "15px" }}>{`${trans(
                          "label.for"
                        )}: €${selected.all_in_price},-`}</strong>
                        <br />
                      </div>
                    ) : (
                      <div>
                        <strong>{`${trans("label.total")}: €${
                          selected.all_in_price
                        },-`}</strong>
                        <br />
                        <br />
                      </div>
                    )}
                    <BookButton
                      arrivalDate={selected.arrival_date}
                      disabled={!hasAdults}
                      accommodationKindId={selected.kind_id}
                      to={this.getBookingRoute()}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PriceTableBlock.propTypes = {
  arrival: PropTypes.string,
  hasAdults: PropTypes.bool,
  block: PropTypes.object,
  departure: PropTypes.string,
  focus: PropTypes.object,
  selectedSubjects: PropTypes.object,
  subjects: PropTypes.array,
  voucher: PropTypes.object,
  special: PropTypes.string,
};

const mapState = (state) => ({
  hasAdults: hasAdults(state),
  subjects: getSubjectsWithQuantity(state),
  selectedSubjects: getPreference("subjects")(state),
  arrival: getPreference("arrival")(state),
  departure: getPreference("departure")(state),
  voucher: getPreference(PREFERENCE_VOUCHER)(state),
  special: getPreference(PREFERENCE_SPECIAL)(state),
});

export default connect(mapState)(PriceTableBlock);
