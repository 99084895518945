import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'react-dates/initialize'
import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController'
import moment from 'moment'

const NextMonthButton = props => !props.isHidden && (
  <button type='button' className='btn btn-navigation override'><i className='material-icons'>keyboard_arrow_right</i></button>
)

const PreviousMonthButton = props => !props.isHidden && (
  <button type='button' className='btn btn-navigation override'><i className='material-icons'>keyboard_arrow_left</i></button>
)

const DATE_FORMAT = 'YYYY-MM-DD'

const dateToInt = date => parseInt(date.format('YYYYMM'), 10)
class DatePicker extends Component {
  state = {
    hidePrevious: true
  }

  componentDidMount = () => {
    if (dateToInt(moment(this.props.start)) > dateToInt(moment())) {
      this.setState({ hidePrevious: false })
    }
  }

  changeMonth = (date) => {
    this.props.onMonthChange(date)
    if (dateToInt(date) === dateToInt(moment())) {
      return this.setState({ hidePrevious: true })
    }
    if (this.state.hidePrevious) {
      return this.setState({ hidePrevious: false })
    }
    return null
  };

  onDateChange = date => this.props.onDateClick(date);

  isInStay = (day) => {
    if (!this.props.start || !this.props.date || !this.props.end) {
      return false
    }

    const start = moment(this.props.start, DATE_FORMAT)
    const end = moment(this.props.end, DATE_FORMAT)

    return day.isBetween(start, end) || day.isSame(moment(this.props.end), 'day')
  }

  isSelected = day => day.format(DATE_FORMAT) === this.props.start || day.format(DATE_FORMAT) === this.props.end;

  isHighlighted = day => day.isSame(moment(this.props.date), 'day');

  render = () => {
    // We pass a new instance of the isBlocked function each time to force an update after new availableDates are passed.
    const isBlocked = day => !this.props.availableDates.includes(day.format(DATE_FORMAT))
    return (
      <div className='row'>
        <div className='col-12'>
          <div className='date-picker-small'>
            <DayPickerSingleDateController
              onPrevMonthClick={date => this.changeMonth(date)}
              onNextMonthClick={date => this.changeMonth(date)}
              renderWeekHeaderElement={this.props.renderWeekHeaderElement}
              numberOfMonths={1}
              enableOutsideDays
              daySize={50}
              verticalHeight={50}
              firstDayOfWeek={1}
              focused
              transitionDuration={0}
              initialVisibleMonth={() => moment(this.props.start)}
              navNext={<NextMonthButton />}
              navPrev={<PreviousMonthButton isHidden={this.state.hidePrevious} />}
              onDateChange={date => this.onDateChange(date)}
              isDayHighlighted={this.isInStay}
              isDayBlocked={isBlocked}
              isOutsideRange={this.isSelected}
            />
          </div>
        </div>
      </div>
    )
  }
}

DatePicker.defaultProps = {
  availableDates: [],
  onDateClick: () => {},
  onMonthChange: () => {}
}

DatePicker.propTypes = {
  end: PropTypes.string,
  // Optionally pass the start of a range
  start: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  availableDates: PropTypes.array,
  onDateClick: PropTypes.func,
  onMonthChange: PropTypes.func
}

export default DatePicker
