import React, { } from 'react'
import PropTypes from 'prop-types'

const CheckButtonField = ({ input, meta: { touched, error }, label, checkedLabel, actualValue, booking }) => (
  <div className={(touched && error) ? 'btn-group has-danger' : 'btn-group'}>
    <button
      {...input}
      type='button'
      className={`${booking ? 'btn-booking' : 'btn-primary'}`}
      // input.value is either an empty string or '0' or 1 (so string, string, number)
      onClick={() => input.onChange((input.value === '' || input.value === '0') ? actualValue : '0')}
    >
      {input.value && input.value !== '0' ? checkedLabel : label}
    </button>
    {touched && error && <div className='form-control-feedback'>{error}</div>}
  </div>
)

CheckButtonField.propTypes = {
  booking: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  checkedLabel: PropTypes.string,
  meta: PropTypes.object,
  actualValue: PropTypes.string
}

export default CheckButtonField
