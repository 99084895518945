import { post } from "./";

export const postNewsletterSubscription = (id, data) =>
  post({
    url: "/newsletters/subscribe",
    params: { ID: id },
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
