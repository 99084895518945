import { FC, ReactEventHandler, useState } from "react";
import classnames from "classnames";
import styled, { css } from "styled-components/macro";
import { ReactComponent as ArrowDown } from "@fortawesome/fontawesome-free/svgs/solid/angle-down.svg";
import {
  DEVICE_LG_DOWN,
  DEVICE_LG_UP,
  DEVICE_SM_DOWN,
  DEVICE_XL_DOWN,
  isTouchDevice,
} from "../../../utils/deviceUtils";
import { useHeaderContext, MenuItemProvider } from "../context";
import SubMenu, { SubMenuWrapper } from "./SubMenu";
import Button from "../../Button";
import scrollToElement from "../../../utils/scrollToElement";

const activeMenu = css`
  background-color: ${({ theme }) => theme.textColor.default};

  > span {
    div {
      color: ${({ theme }) => theme.color.white};
      z-index: 1;
      position: relative;
    }

    svg {
      fill: ${({ theme }) => theme.color.primary};
      transform: rotateX(180deg);
      @media ${DEVICE_LG_UP} {
        transform: translateX(50%) rotateX(180deg);
      }
    }
  }

  ${SubMenuWrapper} {
    display: block;
  }
`;

const Wrapper = styled.li`
  > span,
  > a:not(${Button}) {
    padding: 16px 16px 32px;
    margin-top: 8px;
  }

  > span {
    display: grid;
    grid-template-columns: 1fr auto;
    svg {
      fill: ${({ theme }) => theme.color.neutralMedium};
      height: 20px;
    }
  }

  a:not(${Button}) {
    display: inline-block;
  }

  &.main-menu__item--opened {
    ${activeMenu}
  }

  &.main-menu__item--cta {
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &:not(.main-menu__item--cta) {
    display: none;
  }

  &.main-menu__item--visible {
    display: block;
  }

  > a,
  > span {
    text-transform: uppercase;
    align-items: center;
    font-weight: 900;
    font-size: 1.5rem;

    @media ${DEVICE_LG_UP} and ${DEVICE_XL_DOWN} {
      font-size: 1.25rem;
    }
  }

  > a:not(${Button}),
  > span {
    color: ${({ theme }) => theme.color.grey_100};
  }

  > span {
    cursor: default;
  }

  > a:not(${Button}) {
    cursor: pointer;
    text-decoration: none;
    :hover {
      text-decoration: none;
    }
    &,
    &:hover,
    &:visited {
      color: white;
    }
  }

  @media ${DEVICE_LG_UP} {
    > span {
      display: block;
      position: relative;
      svg {
        position: absolute;
        width: 100%;
        display: block;
        transform: translateX(50%);
        left: -50%;
      }
    }

    &:not(.main-menu__item--cta) {
      display: block;
    }

    &.main-menu__item--cta {
      margin: 0;
    }
  }

  @media ${DEVICE_SM_DOWN} {
    &.main-menu__item--cta {
      width: 100%;

      a,
      button {
        width: 100%;
      }
    }
  }

  @media ${DEVICE_LG_DOWN} {
    &:not(.main-menu__item--cta) {
      order: 1;
      width: 100%;
    }
    &.main-menu__item--cta {
      padding: 22px 16px;
      order: 0;
    }
  }
`;

type MainMenuItemProps = {
  className?: string;
};

type MainMenuItemWithSubMenuProps = MainMenuItemProps & {
  label: string;
};

type MainMenuCTAProps = {
  className?: string;
};

const MainMenuItemContent: FC<MainMenuItemWithSubMenuProps> = ({
  label,
  children,
  className,
  ...props
}) => {
  const { isMobileMenuOpened, setOpenedMenu, openedMenu } = useHeaderContext();

  const isOpenedMenu = openedMenu === label;

  const classes = classnames(className, "has-dropdown", {
    "main-menu__item--visible": isMobileMenuOpened,
    "main-menu__item--opened": isOpenedMenu,
  });

  const handleTouchShowSubMenu: ReactEventHandler<HTMLElement> = (e) => {
    setOpenedMenu(isOpenedMenu ? undefined : label);

    if (!isOpenedMenu) {
      scrollToElement(e.currentTarget);
    }
  };

  const handleHideSubMenu: ReactEventHandler<HTMLElement> = (e) => {
    if (!isTouchDevice()) {
      setOpenedMenu(undefined);
    }
  };

  return (
    <Wrapper
      {...props}
      className={classes}
      tabIndex={0}
      onMouseLeave={handleHideSubMenu}
    >
      <span
        onClick={(e) => isTouchDevice() && handleTouchShowSubMenu(e)}
        onMouseEnter={(e) => !isTouchDevice() && handleTouchShowSubMenu(e)}
      >
        <div>{label}</div>
        <ArrowDown />
      </span>
      <SubMenu aria-label={`${label}: Sub menu`}>{children}</SubMenu>
    </Wrapper>
  );
};

export const MainMenuItemWithSubMenu: FC<MainMenuItemWithSubMenuProps> = (
  props
) => {
  return (
    <MenuItemProvider>
      <MainMenuItemContent {...props} />
    </MenuItemProvider>
  );
};

export const MainMenuCTA: FC<MainMenuCTAProps> = ({ className, ...props }) => {
  const classes = classnames("main-menu__item--cta", className);

  return <Wrapper {...props} className={classes} />;
};

// TODO: Do we want to focus on accessibility?
const MainMenuItem: FC<MainMenuItemProps> = ({
  children,
  className,
  ...props
}) => {
  const { isMobileMenuOpened } = useHeaderContext();

  const classes = classnames(className, "has-dropdown", {
    "main-menu__item--visible": isMobileMenuOpened,
  });

  return (
    <Wrapper {...props} className={classes} tabIndex={0}>
      {children}
    </Wrapper>
  );
};

export default MainMenuItem;
