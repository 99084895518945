export const RESERVATION_PROPOSAL_START = 'reservation/RESERVATION_PROPOSAL_START'
export const RESERVATION_PROPOSAL_SUCCESS = 'reservation/RESERVATION_PROPOSAL_SUCCESS'
export const RESERVATION_RESTORE_SESSION = 'reservation/RESERVATION_RESTORE_SESSION'
export const RESERVATION_CONFIRMATION_START = 'reservation/RESERVATION_CONFIRMATION_START'
export const RESERVATION_CONFIRMATION_SUCCESS = 'reservation/RESERVATION_CONFIRMATION_SUCCESS'
export const RESERVATION_CONFIRMATION_FAILURE = 'reservation/RESERVATION_CONFIRMATION_FAILURE'
export const RESERVATION_API_ERROR = 'reservation/RESERVATION_API_ERROR'
export const RESERVATION_INITIAL_FETCH = 'reservation/RESERVATION_INITIAL_FETCH'
export const CLEAR_RESERVATION = 'reservation/CLEAR_RESERVATION'
export const SET_RESERVATION_CRITERIA = 'reservation/SET_RESERVATION_CRITERIA'
export const SET_CUSTOMER = 'reservation/SET_CUSTOMER'
export const SET_FILTERS = 'reservation/SET_FILTERS'
export const SET_ADDITION_CATEGORY = 'reservation/SET_ADDITION_CATEGORY'
export const SET_OFFER = 'reservation/SET_OFFER'
export const UPDATE_PROPERTY_COUNTS = 'reservation/UPDATE_PROPERTY_COUNTS'
export const UPDATE_VOUCHER_CRITERIA = 'reservation/UPDATE_VOUCHER_CRITERIA'
export const ADD_ADDITION = 'reservation/ADD_ADDITION'
export const REMOVE_ADDITION = 'reservation/REMOVE_ADDITION'
export const CUSTOMER_ADDRESS_ERROR = 'reservation/CUSTOMER_ADDRESS_ERROR'
export const CLEAR_CUSTOMER_ADDRESS_ERROR = 'reservation/CLEAR_CUSTOMER_ADDRESS_ERROR'

export const SET_INITIAL_SECTIONS = 'reservation/SET_INITIAL_SECTIONS'
export const SET_CURRENT_SECTION = 'reservation/SET_CURRENT_SECTION'
export const SET_NEXT_SECTION = 'reservation/SET_NEXT_SECTION'
export const NEXT_SECTION = 'reservation/NEXT_SECTION'
export const PREVIOUS_SECTION = 'reservation/PREVIOUS_SECTION'

export const SET_SECTION = 'reservation/SET_SECTION'
