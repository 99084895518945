import styled from "styled-components";

import { DEVICE_LG_UP, DEVICE_MD_UP } from "../../utils/deviceUtils";

const Hero = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 300px;

  @media ${DEVICE_MD_UP} {
    min-height: 360px;
  }
  @media ${DEVICE_LG_UP} {
    min-height: 440px;
  }

  h1,
  h2,
  h3,
  h4 {
    i {
      font-style: normal;
      font-weight: inherit;
      color: ${({ theme }) => theme.color.primary};
    }
  }
`;

export default Hero;
