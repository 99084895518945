import { connectInfiniteHits, connectStats } from "react-instantsearch-dom";
import styled from "styled-components";
import { Button, DEVICE_MD, Row, Translate } from "@libema/design-system";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const StyledHits = styled.div`
  flex: 1;

  &.grid ${Row} {
    row-gap: 1rem;
  }

  ${Row} {
    flex: 1;
  }

  .hits-custom-stats {
    margin-top: 1rem;
    text-align: center;
    font-weight: bold;
    color: ${({ theme }) => theme.color.secondary};
  }

  & .load-buttons-container {
    display: flex;
    justify-content: center;
  }
`;

const CustomStats = connectStats(({ nbHits, hits }) => (
  <div className="hits-custom-stats">
    {hits} / {nbHits}
  </div>
));

function Hits({
  hits,
  hasPrevious,
  hasMore,
  refineNext,
  refinePrevious,
  children,
  displayMode,
}) {
  const { width } = useWindowDimensions();

  const isGridView = displayMode === "Grid" || width < DEVICE_MD;

  return (
    <StyledHits className={isGridView ? "grid" : "list"}>
      {hasPrevious && (
        <div className="load-buttons-container">
          <Button
            size="small"
            variant="text"
            colorType="secondary"
            disabled={!hasPrevious}
            onClick={refinePrevious}
          >
            Show previous
          </Button>
        </div>
      )}
      <Row>{children({ hits, isGridView })}</Row>
      <CustomStats hits={hits.length} />
      {hasMore && (
        <div className="load-buttons-container">
          <Button
            size="small"
            colorType="secondary"
            variant="text"
            borderWidth="0"
            disabled={!hasMore}
            onClick={refineNext}
          >
            <Translate id="filter.loadbutton.load_more" />
          </Button>
        </div>
      )}
    </StyledHits>
  );
}

export default connectInfiniteHits(Hits);
